import {
  FormValues,
  InputFieldProps,
  yupSchemaToValidationFunc
} from 'components/Forms';
import * as Yup from 'yup';
import JobType from 'models/JobTypes';
import { QueryParams } from 'store/utils';
import { DateTime } from 'luxon';

export const populateJobTypes = (jobTypes: JobType[]) => {
  return jobTypes.map(jobType => ({
    value: jobType.id,
    label: jobType.name,
    key: jobType.id,
    testId: 'jobTypeValue'
  }));
};

// We don't want to allow the creation of SMETS1 appointments https://ovotech.atlassian.net/browse/FTSC-1893
const unwantedJobTypeIds = [
  '3e8ad79b-1245-4b02-b009-4e4488319f5a',
  'e8c768b4-eb77-4222-b623-f2a1d469bd3d',
  '3c2f6906-5453-4816-9b35-afb89ebef0f9'
];
export const buildInitialFormFields = (
  jobTypes: JobType[]
): InputFieldProps[] => {
  const filteredJobTypes = jobTypes.filter(jobType => {
    return !unwantedJobTypeIds.includes(jobType.id);
  });

  return [
    {
      id: 'postcode',
      label: 'Postcode',
      name: 'postcode',
      qaId: 'addJobPostcode',
      inputType: 'textfield'
    },
    {
      id: 'jobType',
      label: 'Job Type',
      name: 'jobType',
      qaId: 'addJobJobType',
      inputType: 'select',
      options: populateJobTypes(filteredJobTypes)
    }
  ];
};

const initialFormFieldsValidation = Yup.object().shape({
  jobType: Yup.string().required('Required'),
  postcode: Yup.string().required('Required')
});

export const initialFormValidation = yupSchemaToValidationFunc(
  initialFormFieldsValidation
);

export const capacityRequestEndDate = () =>
  DateTime.now()
    .plus({ days: 57 })
    .toISODate();

export const processAddJobFormFields = (
  values: FormValues,
  includeToday: boolean
): FormValues => {
  const date = DateTime.now().plus({ days: includeToday ? 0 : 1 });

  const queryParams: QueryParams = {
    startDate: {
      operator: '=',
      values: [date.toISODate()]
    },
    endDate: {
      operator: '=',
      values: [capacityRequestEndDate()]
    },
    job_type: {
      operator: '=',
      values: [values.jobType as string]
    },
    postcode: {
      operator: '=',
      values: [values.postcode as string]
    }
  };

  return queryParams;
};
