import React from 'react';
import { makeStyles } from '@material-ui/core';
import { AppointmentStatus } from 'models/Appointment';
import Accordion from 'components/Accordion/Accordion';
import { WorkflowSectionData, WorkflowSectionQuestion } from 'models/Workflow';
import { useDispatch } from 'react-redux';
import { setSnackbar, SnackbarStatus } from 'store/slices/snackbar';
import Typography from 'components/Typography';

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 484
  },
  section: {
    padding: 0
  },
  accordionWrapper: {
    '& .MuiAccordion-root:last-child .MuiAccordionSummary-root:not(.Mui-expanded)': {
      // Transparent border to prevent content moving around when expanding the acccordion
      borderBottom: '1px solid transparent'
    },
    padding: theme.spacing(0, 2, 2, 2)
  },
  questionList: {
    width: '100%',
    margin: 0,
    padding: 0,
    '& > div': {
      padding: theme.spacing(0.75, 0),
      borderTop: `1px solid ${theme.palette.divider}`,
      '&:first-of-type': {
        border: 'none'
      }
    }
  },
  column: {
    display: 'flex',
    flexDirection: 'column'
  },
  row: {
    display: 'flex',
    flexDirection: 'row'
  },
  mediaRows: {
    marginTop: theme.spacing(1),
    '& > img': {
      width: 196
    },
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: theme.spacing(1),
    width: '100%'
  },
  question: {
    flexGrow: 1,
    flexBasis: '51%',
    marginRight: theme.spacing(1)
  },
  questionWrapper: {
    padding: theme.spacing(0.75, 0),
    borderBottom: `1px solid ${theme.palette.aegis.semantic.background.canvas}`,
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    '&:last-child': {
      border: 'none'
    }
  },
  questionText: {
    color: theme.palette.common.black
  },
  questionAnswer: {
    color: theme.palette.aegis.semantic.message.dark,
    textAlign: 'justify'
  },
  questionImage: {
    borderRadius: 4
  },
  questionAnswerWithMedia: {
    width: '100%'
  }
}));

export enum PanelMessage {
  NOT_STARTED = 'Job not started',
  IN_PROGRESS = 'Job in progress',
  CANCELLED = 'Job is cancelled',
  EMPTY = 'No workflow data found'
}

const getWorkflowStatus = (status: string) => {
  switch (status) {
    case AppointmentStatus.PENDING:
    case AppointmentStatus.PAUSED:
      return PanelMessage.NOT_STARTED;
    case AppointmentStatus.ON_ROUTE:
    case AppointmentStatus.ACTIVE:
    case AppointmentStatus.ARRIVED:
      return PanelMessage.IN_PROGRESS;
    case AppointmentStatus.CANCELLED:
    case AppointmentStatus.ABORTED:
    case AppointmentStatus.COMPLETED:
      return null;
    default:
      return null;
  }
};

interface WorkflowPaneProps {
  status: string;
  sections: WorkflowSectionData[];
}

interface QuestionProps {
  question: WorkflowSectionQuestion;
  testId: string;
}

const Question = ({ question, testId }: QuestionProps) => {
  const classes = useStyles();

  return (
    <li
      key={question.questionId}
      data-testid={testId}
      className={`${classes.questionWrapper} ${classes.row}`}
    >
      <Typography className={classes.questionText}>{question.text}</Typography>
      {question.answer && (
        <Typography
          component="span"
          className={`${classes.questionAnswer} ${question.media &&
            classes.questionAnswerWithMedia}`}
        >
          {question.answer}
        </Typography>
      )}
      {question.media && (
        <div className={`${classes.row} ${classes.mediaRows}`}>
          {question.media?.map(imageSrc => (
            <img
              key={imageSrc}
              className={classes.questionImage}
              src={imageSrc}
              alt={`Answer for question ${question.text}`}
              aria-describedby={question.questionId}
              data-testid={`${question.questionId}Image`}
            />
          ))}
        </div>
      )}
    </li>
  );
};

export default function WorkflowPane({ status, sections }: WorkflowPaneProps) {
  const classes = useStyles();
  const workflowStatus = getWorkflowStatus(status);
  const dispatch = useDispatch();

  if (workflowStatus !== null) {
    return (
      <div className={classes.wrapper} data-testid="workflowWrapper">
        <Typography>{workflowStatus}</Typography>
      </div>
    );
  }

  if (!sections.length) {
    dispatch(
      setSnackbar({
        title: 'Error',
        message: 'No Workflow Data Found',
        show: true,
        status: SnackbarStatus.Error
      })
    );
    return (
      <div className={classes.wrapper} data-testid="workflowWrapper">
        <Typography>{PanelMessage.EMPTY}</Typography>
      </div>
    );
  }

  return (
    <div data-testid="workflowWrapper" className={classes.accordionWrapper}>
      {sections.map((section, sectionIndex) => (
        <Accordion
          key={section.name}
          id={section.name}
          label={section.name}
          className={classes.section}
          expanded={sections.length === 1}
          testId={`workflowSection-${sectionIndex + 1}`}
        >
          <ul className={classes.questionList}>
            {section.questions.map((question, questionIndex) => (
              <Question
                key={question.questionId}
                question={question}
                testId={`workflowQuestion-${questionIndex + 1}`}
              />
            ))}
          </ul>
        </Accordion>
      ))}
    </div>
  );
}
