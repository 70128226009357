import React, { memo } from 'react';
import { Marker } from 'react-map-gl';
import JobCard, { JobCardViewMode } from 'components/Schedule/JobCard/JobCard';
import { getPostcode, timeToPixels } from 'components/Schedule/scheduleHelpers';
import { JobDetailsFields } from 'models/Schedule';
import { getJobCardStyleType } from 'utils/jobStatus';
import { assignedCardMapHeight } from 'components/Schedule/JobCard/Assigned';
import {
  EngineerPointInfo,
  getHydratedJobDetailsWithAssignedSchedule
} from '../scheduleMapHelpers';
import { SelectEngineerCallback } from '../MapWrapper';
import MarkerContainer from './MarkerContainer';

interface AssignedMarkerProps {
  engineerPoint: EngineerPointInfo;
  handleJobCardOnClick: (value: JobDetailsFields) => void;
  selectedEngineer?: string;
  engineerId: string;
  handleSelectEngineer: SelectEngineerCallback;
  selectedJobCardDetails: JobDetailsFields;
}
const AssignedMarker = ({
  engineerPoint,
  handleJobCardOnClick,
  selectedEngineer,
  engineerId,
  handleSelectEngineer,
  selectedJobCardDetails
}: AssignedMarkerProps) => (
  <MarkerContainer
    isSelected={
      selectedJobCardDetails.isOpen &&
      selectedJobCardDetails.id === engineerPoint.assignment.appointment.id &&
      selectedJobCardDetails.assigned?.engineerId === engineerId
    }
    onClick={() =>
      handleJobCardOnClick(
        getHydratedJobDetailsWithAssignedSchedule(engineerPoint.assignment, {
          engineerId,
          firstName: engineerPoint.firstName,
          lastName: engineerPoint.lastName,
          scheduleStatus: engineerPoint.scheduleStatus
        })
      )
    }
    data-testid={`mapMarkerWrapper_${engineerPoint.assignment.appointment.id}`}
  >
    <Marker
      // Key set here as a hacky way to ensure the Marker (and child) re-renders whenever job updates
      key={JSON.stringify(engineerPoint.assignment)}
      latitude={engineerPoint.assignment.location?.geoLocation.lat}
      longitude={engineerPoint.assignment.location?.geoLocation.long}
      offsetTop={-(assignedCardMapHeight / 2)}
      offsetLeft={-(timeToPixels(engineerPoint.assignment.length) / 2)}
    >
      <JobCard
        jobCardStyleType={getJobCardStyleType(
          engineerPoint.assignment.appointment,
          !engineerPoint.engineerUnavailable
        )}
        hasComplaints={Boolean(
          engineerPoint.assignment.appointment.complaints?.length
        )}
        canDrag={false}
        jobType={engineerPoint.assignment.appointment.jobType}
        type={engineerPoint.assignment.appointment.appointmentType?.type}
        appointmentId={engineerPoint.assignment.appointment.id}
        duration={engineerPoint.assignment.length}
        hideTravelTime
        slot={engineerPoint.assignment.appointment?.slot}
        label={getPostcode(engineerPoint.assignment.location?.postcode)}
        viewMode={JobCardViewMode.MAP}
        assigned={!engineerPoint.engineerUnavailable}
        selectedEngineer={selectedEngineer}
        handleSelectEngineer={handleSelectEngineer}
        engineerId={engineerId}
        selectedJobCardDetails={selectedJobCardDetails}
      />
    </Marker>
  </MarkerContainer>
);

export default memo(AssignedMarker);
