import React from 'react';
import {
  FieldInputValue,
  FormValues,
  mapResponseDataToField
} from 'components/Forms';
import { useDispatch } from 'react-redux';
import { useSkillsData, useTimebandData } from 'store/selectors';
import { addJobType, fetchJobTypes } from 'store/slices/jobTypes';
import FormModal, { FormModalMode } from 'components/FormModal/FormModal';
import { jobTypeDomain } from 'models';
import JobType from 'models/JobTypes';
import { fetchAllTimeBands } from 'store/slices/timeBands';
import { fetchAllSkills } from 'store/slices/skills';
import { getInitialFieldValues } from 'components/Crud';
import { DomainItem } from 'models/Domain';
import {
  getJobTypeInputFieldsProps,
  jobTypesFormValidation
} from '../FormConfig';

interface JobTypeModalProps {
  open: boolean;
  closeModal: () => void;
  cancel: () => void;
  currentJobType?: JobType;
}

function mapDropdownOptionsToIds(options: FieldInputValue[]) {
  return options.map(({ value }) => value);
}

export default function JobTypesModal({
  open,
  closeModal,
  cancel,
  currentJobType
}: JobTypeModalProps) {
  const dispatch = useDispatch();
  const mode = currentJobType ? FormModalMode.EDIT : FormModalMode.ADD;
  const skillsData = useSkillsData();
  const timebandData = useTimebandData();

  const onLoad = () => {
    dispatch(fetchAllSkills());
    dispatch(fetchAllTimeBands());
  };

  const onAddJobType = async (values: FormValues) => {
    await dispatch(
      addJobType(
        {
          ...values,
          requiredSkills: mapDropdownOptionsToIds(
            values.requiredSkills as FieldInputValue[]
          )
        },
        { successMessage: `The new ${jobTypeDomain.singular} has been added` }
      )
    );
    closeModal();
  };

  const refreshJobTypeList = () => {
    dispatch(fetchJobTypes());
  };

  const fields = getJobTypeInputFieldsProps(mode, skillsData, timebandData);

  const processJobTypeData = (item: DomainItem) => {
    return mapResponseDataToField(
      {
        requiredSkills: (jobType: JobType) => jobType.requiredSkills,
        timeBand: (jobType: JobType) => jobType.timeBand.id
      },
      item as JobType
    );
  };

  return (
    <FormModal
      fields={fields}
      initialValues={getInitialFieldValues(
        fields,
        currentJobType,
        processJobTypeData
      )}
      domain={jobTypeDomain}
      open={open}
      currentItem={currentJobType}
      validationFunc={jobTypesFormValidation}
      onLoad={onLoad}
      onAdd={onAddJobType}
      onEdit={() => null}
      onCancel={cancel}
      onSaved={refreshJobTypeList}
    />
  );
}
