import { DateTime, Interval } from 'luxon';
import Adjustment, {
  AdjustmentType,
  AdjustmentPriority
} from 'models/Adjustment';
import Engineer from 'models/Engineers';
import Patch from 'models/Patches';
import Skill from 'models/Skills';
import { adjustmentTypeFilter } from 'utils/adjustments';
import { efficiencyToLabel } from '../utils';
import {
  getAdjustmentChange,
  getEngineerSummariesForInterval
} from './engineerSummaries';

export const NOT_WORKING_LABEL = 'Not working';

export enum CalendarSpanType {
  DefaultValue = 'defautValue',
  ShiftPatternAdjustment = 'shiftPatternAdjustment',
  ShiftPatternAdjustmentAbsent = 'shiftPatternAdjustmentAbsent',
  PatchAdjustment = 'patchAdjustment',
  EfficiencyAdjustment = 'efficiencyAdjustment',
  SkillsAdjustment = 'skillsAdjustment',
  GroupedPatchAdjustment = 'groupedPatchAdjustment',
  GroupedEfficiencyAdjustment = 'groupedEfficiencyAdjustment'
}

export interface CalendarSpan {
  spanStartDay: DateTime;
  spanEndDay: DateTime;
  adjustmentStartDay?: DateTime;
  adjustmentEndDay?: DateTime;
  value: string;
  type: CalendarSpanType;
  adjustmentType?: AdjustmentType;
  adjustmentId?: string;
  adjustmentGroupId?: string;
}

export interface EngineerCalendarAdjustmentsSummary {
  date: DateTime;
  patch: string;
  efficiency: number;
  skills: string[] | Skill[];
  startTime?: string;
  endTime?: string;
  isWorking: boolean;
  adjustments: Adjustment[];
  areAdjustmentsChangesGrouped?: boolean;
}

/**
 * Compute a span's absolute width in pixels for given layout parameters and number of week days
 *
 * @param startDay - start day as an integer
 * @param endDay - end day as an integer
 * @param cellWidth - cell width in pixels
 * @param cellGap - cell gap in pixels
 * @returns `number`
 */
const computeSpanWidth = (
  startDay: number,
  endDay: number,
  cellWidth: number,
  cellGap: number
) => cellWidth * (endDay - startDay + 1) - cellGap;

/**
 * Compute a `CalendarSpan` absolute width in pixels for given layout parameters
 *
 * @param span - the span to calculate width for
 * @param cellWidth - cell width in pixels
 * @param cellGap - cell gap in pixels
 * @returns `number`
 */
export const computeCalendarSpanWidth = (
  span: CalendarSpan,
  cellWidth: number,
  cellGap: number
) =>
  computeSpanWidth(
    span.spanStartDay.weekday,
    span.spanEndDay.weekday,
    cellWidth,
    cellGap
  );

/**
 * Compute the absolute width in pixels of the default schedule container
 * based on the default shift pattern provided for an Engineer
 *
 * @param spans - list of spans
 * @param cellWidth - cell width in pixels
 * @param cellGap - cell gap in pixels
 * @returns `number`
 */
export const computeWorkWeekWidth = (
  spans: CalendarSpan[],
  cellWidth: number,
  cellGap: number
): number => {
  const workDaySpans = spans.filter(s => s.value);
  const firstWorkDay = workDaySpans[0]?.spanStartDay.weekday;
  const lastWorkDay = workDaySpans[workDaySpans.length - 1]?.spanEndDay.weekday;
  return computeSpanWidth(firstWorkDay, lastWorkDay, cellWidth, cellGap);
};

/**
 * Create the string for a shift pattern start time and end time
 *
 * @param startTime
 * @param endTime
 * @returns `string`
 */
const shiftPatternTimesToString = (
  startTime: string,
  endTime: string
): string => `${startTime} - ${endTime}`;

/**
 * Look up the name of a patch in a list of patches given its id
 *
 * @param patchId - id of the patch
 * @param patches - list of patches to search
 * @returns `string` - name of the patch
 */
const patchToString = (patchId: string, patches: Patch[]): string | undefined =>
  patches.find(patch => patch.id === patchId)?.name;

/**
 * Convert a list of skill ids to a comma separated string of skill names
 *
 * @param skillIds - ids of the skills
 * @param skillsData - list of skills to search
 * @returns `string` - the skills joined by a comma
 */
const skillsToString = (skillIds: string[], skillsData: Skill[]) => {
  const skillsSum = skillIds.map(skillId => {
    const skillmap = skillsData.find(innerSkill => innerSkill.id === skillId);

    return skillmap?.name;
  });

  return skillsSum.join(', ');
};

/**
 * search a list of adjustments for a given change type
 *
 * @param adjustments list of adjustments to search
 * @param type the adjustment type to search for
 * @returns boolean whether a change of the given type was found or not
 */
const adjustmentsContainChangeType = (
  adjustments: Adjustment[],
  type: AdjustmentType
) => getAdjustmentChange(adjustments, type) !== null;

/**
 * Removes engineer summaries from the end of an array where the engineer is not working.
 * Used for showing the blank space at the end of a week in the engineers calendar
 * @param {EngineerCalendarAdjustmentsSummary[]} summaries summaries to filter
 * @returns {EngineerCalendarAdjustmentsSummary[]} the filtered summaries
 */
const filterNotWorkingAtEndOfWeek = (
  summaries: EngineerCalendarAdjustmentsSummary[]
) =>
  summaries.reduceRight((accumulator, summary) => {
    if (
      accumulator.length === 0 &&
      !summary.isWorking &&
      !adjustmentsContainChangeType(summary.adjustments, AdjustmentType.Shift)
    ) {
      return accumulator;
    }
    return [summary, ...accumulator];
  }, new Array<EngineerCalendarAdjustmentsSummary>());

/**
 * get the engineer summaries for an engineer for each day in a given time interval
 * and filter the not working summaries from the end of the interval
 * @param adjustments the adjustment to apply
 * @param interval the time interval
 * @param engineer the engineer to generate summaries for
 * @returns a list of summaries
 */
export const getFilteredSummaries = (
  adjustments: Adjustment[],
  interval: Interval,
  engineer: Engineer
): EngineerCalendarAdjustmentsSummary[] =>
  filterNotWorkingAtEndOfWeek(
    getEngineerSummariesForInterval(adjustments, interval, engineer)
  );

/**
 * Computes the calendar span type for a given day of the week.
 * The calendar span type is used to set different CSS classes to the calendar span.
 * The type is computed in a different way base on the schedule type
 * (Shift, Patch or Efficiency)
 *
 * @param summary - the summary to get the span type for
 * @param type - the adjustment type
 * @returns `CalendarSpanType`
 */
const getSpanType = (
  summary: EngineerCalendarAdjustmentsSummary,
  type: AdjustmentType
) => {
  // Do any of the temporary adjustments contain both a shift change and a change of the current change type (patch or efficiency)
  const isChangeGroupedWithShift = summary.adjustments.some(
    adjustment =>
      adjustment.priority === AdjustmentPriority.Temporary &&
      adjustmentsContainChangeType([adjustment], AdjustmentType.Shift) &&
      adjustmentsContainChangeType([adjustment], type)
  );

  if (adjustmentsContainChangeType(summary.adjustments, type)) {
    switch (type) {
      case AdjustmentType.Efficiency:
        return isChangeGroupedWithShift
          ? CalendarSpanType.GroupedEfficiencyAdjustment
          : CalendarSpanType.EfficiencyAdjustment;
      case AdjustmentType.Patch:
        return isChangeGroupedWithShift
          ? CalendarSpanType.GroupedPatchAdjustment
          : CalendarSpanType.PatchAdjustment;
      case AdjustmentType.Skills:
        return CalendarSpanType.SkillsAdjustment;
      case AdjustmentType.Shift:
        return summary.isWorking
          ? CalendarSpanType.ShiftPatternAdjustment
          : CalendarSpanType.ShiftPatternAdjustmentAbsent;
      default:
        throw new Error(`Unexpected adjustment type in calendar: ${type}`);
    }
  }

  return CalendarSpanType.DefaultValue;
};

/**
 * Gets the value to be displayed in a calendar span from an engineer summary
 * The value is computed in a different way base on the schedule type
 * (Shift, Patch, Skills or Efficiency)
 * @param summary - the engineer summary
 * @param type - the adjustment type - determines which value from the summary is used
 * @param patches - list of patches, used to transform the patch id to a patch name
 * @returns `string` - the formatted value
 */
const getSpanValue = (
  summary: EngineerCalendarAdjustmentsSummary,
  type: AdjustmentType,
  patches: Patch[],
  skills: Skill[]
): string => {
  switch (type) {
    case AdjustmentType.Efficiency:
      return efficiencyToLabel(summary.efficiency);
    case AdjustmentType.Skills:
      return skillsToString(summary.skills as string[], skills);
    case AdjustmentType.Patch:
      return patchToString(summary.patch, patches) ?? 'Unknown patch';
    case AdjustmentType.Shift:
      if (!summary.isWorking) {
        return NOT_WORKING_LABEL;
      }
      return shiftPatternTimesToString(
        summary.startTime ?? '',
        summary.endTime ?? ''
      );
    default:
      throw Error(`Unexpected adjustment type in calendar view: ${type}`);
  }
};

/**
 * Generates an array of `CalendarSpan` objects from a list of `EngineerCalendarAdjustmentsSummary`
 * The calendar spans are then used to visualize the various
 * schedule types on the engineer calendar.
 *
 * @param summaries - the list of summaries
 * @param type - the type of adjustment spans to generate
 * @param patches - list of `PatchModel` used to map patch ids to a human readable patch name
 * @returns `CalendarSpan[]`
 */
export const summariesToSpans = (
  summaries: EngineerCalendarAdjustmentsSummary[],
  type: AdjustmentType,
  patches: Patch[] = [],
  skills: Skill[] = []
): CalendarSpan[] => {
  const dates = summaries.map(summary => summary.date);

  const spans: CalendarSpan[] = [];

  dates.forEach(date => {
    const summary = summaries.find(s => s.date.equals(date)) ?? null;

    if (summary == null) {
      return;
    }

    // Take only the adjustment that matches the type we want to generate spans for
    const adjustment = summary.adjustments.find(adjustmentTypeFilter(type));

    const currentSpanValue = getSpanValue(summary, type, patches, skills);

    const currentSpanType = getSpanType(summary, type);

    const lastSpan = spans[spans.length - 1];

    const currentSlotDifferentFromPrevious =
      lastSpan?.value !== currentSpanValue ||
      lastSpan?.type !== currentSpanType ||
      (adjustment?.groupId &&
        lastSpan?.adjustmentGroupId !== adjustment?.groupId) ||
      (!adjustment?.groupId && lastSpan?.adjustmentId !== adjustment?.id);

    const startNewSpan = spans.length === 0 || currentSlotDifferentFromPrevious;

    if (startNewSpan) {
      spans.push({
        spanStartDay: date,
        spanEndDay: date,
        adjustmentStartDay:
          adjustment && DateTime.fromISO(adjustment.startDate),
        adjustmentEndDay: adjustment && DateTime.fromISO(adjustment.endDate),
        value: currentSpanValue,
        type: currentSpanType,
        adjustmentType: type,
        adjustmentId: adjustment?.id,
        adjustmentGroupId: adjustment?.groupId
      });
    } else {
      lastSpan.spanEndDay = date;
    }
  });

  return spans;
};
