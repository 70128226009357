import { ThunkAction, Action } from '@reduxjs/toolkit';
import { FormValues } from 'components/Forms';
import { SubmissionErrors } from 'final-form';

export const formSubmitHandler = (
  apiRequestThunk: (
    values: FormValues
  ) => ThunkAction<void, any, null, Action<string>>,
  presubmitCallback?: (val: FormValues) => FormValues
  /* eslint-disable consistent-return */
) => async (fieldValues: FormValues): Promise<SubmissionErrors | undefined> => {
  const processedFields = presubmitCallback
    ? presubmitCallback(fieldValues)
    : fieldValues;
  const apiErr = await apiRequestThunk(processedFields);
  if (apiErr) {
    return { FORM_ERROR: apiErr };
  }
};
