/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CapacityUtilisation } from 'models/Capacity';
import { AppThunk } from 'store';
import {
  paramsSerializer,
  createFilterQuery,
  QueryParams,
  createUrl,
  handleThunkError,
  PaginationOptions,
  ApiQueryParams
} from 'store/utils/';
import {
  initialStateSlice,
  startLoading,
  loadingFailed,
  getPaginationReducers,
  StateSlice
} from 'store/slices';
import { capacityUtilisationDomain } from 'models';
import http from 'api/http';
import { SortByOptions } from 'components/Crud';
import { SMETS2_DUEL_FUEL_INSTALLATION_JOB_TYPE_ID } from 'constants/capacity';

const capacityUtilisation = createSlice({
  name: capacityUtilisationDomain.plural,
  initialState: initialStateSlice,
  reducers: {
    ...startLoading<CapacityUtilisation>(),
    ...loadingFailed<CapacityUtilisation>(),
    ...getPaginationReducers<CapacityUtilisation>(),
    getCapacityUtilisationSuccess(
      state,
      { payload }: PayloadAction<StateSlice<CapacityUtilisation>>
    ) {
      state.items = payload.items;
      state.page = payload.page;
      state.isLoading = false;
      state.error = null;
    }
  }
});

export const {
  requestStart,
  requestFail,
  setPagination: setCapacityUtilisationPagination,
  setTotalItems,
  setTotalPages,
  getCapacityUtilisationSuccess
} = capacityUtilisation.actions;

export default capacityUtilisation.reducer;

export function fetchCapacityUtilisation(
  query: QueryParams | undefined,
  paginationOptions?: PaginationOptions,
  parentId?: string,
  sortBy?: SortByOptions,
  queryParams?: ApiQueryParams
): AppThunk {
  return async (dispatch, getState) => {
    const {
      capacityUtilisation: { page }
    } = getState();

    try {
      const params = {
        ...(query ? { params: createFilterQuery(query) } : {}),
        startDate: queryParams?.startDate,
        endDate: queryParams?.endDate,
        jobTypeId: SMETS2_DUEL_FUEL_INSTALLATION_JOB_TYPE_ID,
        pageIndex: paginationOptions?.pageIndex || page.pageIndex,
        pageSize: paginationOptions?.pageSize || page.pageSize
      };

      dispatch(requestStart());

      const { data } = await http.get<StateSlice<CapacityUtilisation>>(
        createUrl(capacityUtilisationDomain),
        {
          params,
          paramsSerializer
        }
      );

      dispatch(getCapacityUtilisationSuccess(data));

      return null;
    } catch (error) {
      handleThunkError({
        error,
        requestFail,
        dispatch,
        domainType: capacityUtilisationDomain.type
      });

      return null;
    }
  };
}
