import { FormValues, FieldInputValue } from '../../components/Forms/utils';

// This is only needed to convert the ID in the dropdown into the array needed by the API.
// This can disappear once the dropdown lets you select multiple skills as it'll already be an array.
// TODO: Move this to a common folder
const processSkills = (skills: FieldInputValue[]): string[] =>
  skills.map(({ value }) => value);

export const processFormFields = ({
  name,
  defaultLength,
  requiredSkills,
  associatedWorkflow,
  installationType,
  timeBand
}: FormValues) => {
  return {
    name,
    defaultLength,
    requiredSkills: processSkills(requiredSkills as FieldInputValue[]),
    associatedWorkflow: associatedWorkflow || null,
    installationType,
    timeBand
  };
};
