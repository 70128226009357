/* eslint-disable react/no-unescaped-entities */
import React, { ReactElement } from 'react';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { makeStyles } from '@material-ui/core';
import { AxiosError } from 'axios';

import Button from 'components/Button';

const useStyles = makeStyles(
  () => ({
    container: {
      maxWidth: 480,
      fontSize: 18,
      textAlign: 'center',
      margin: 'auto'
    }
  }),
  { name: 'Sentry' }
);

const FallbackComponent = () => {
  const classes = useStyles();
  const reload = () => window.location.reload();

  return (
    <div className={classes.container}>
      <p>
        There's a technical problem that we're fixing, please try to reload the
        app. If this doesn't work, please try again later.
      </p>
      <Button type="button" onClick={reload} color="primary">
        Reload the app
      </Button>
    </div>
  );
};

export const ErrorBoundary = ({ children }: { children: ReactElement }) => {
  return (
    <Sentry.ErrorBoundary fallback={<FallbackComponent />} showDialog>
      {children}
    </Sentry.ErrorBoundary>
  );
};

export type AxiosErrorWithMessage = AxiosError<{ message: string }, any>;

export const initialiseSentry = () => {
  if (
    process.env.REACT_APP_ENABLE_SENTRY === 'true' &&
    // Don't send errors from devs running `yarn uat`
    process.env.NODE_ENV === 'production'
  ) {
    Sentry.init({
      environment: process.env.REACT_APP_ENVIRONMENT,
      dsn: process.env.REACT_APP_SENTRY_DSN,
      integrations: [new Integrations.BrowserTracing()],
      tracesSampleRate: Number(
        process.env.REACT_APP_SENTRY_TRACING_SAMPLE_RATE
      ),
      normalizeDepth: 10,
      maxBreadcrumbs: 20,
      // Remove the default redux.state context tag sent by Sentry. Already included in the 'additional data' field
      beforeSend: (event, hint) => {
        const exception = hint?.originalException as
          | AxiosErrorWithMessage
          | undefined;

        if (exception?.isAxiosError) {
          if (exception.response?.status === 401) {
            return null;
          }

          return {
            ...event,
            fingerprint: [
              String(exception.response?.status),
              String(exception.response?.data?.message)
            ],
            contexts: undefined
          };
        }

        return { ...event, contexts: undefined };
      }
    });
  }
};
