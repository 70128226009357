import React from 'react';
import {
  Chip as MuiChip,
  ChipProps,
  Avatar,
  makeStyles
} from '@material-ui/core';

export interface ChipComponentProps extends ChipProps {
  name: string;
  label: string;
  category: { id: string; name: string };
}
export interface ChipClasses {
  avatarClasses: {
    entech: string;
    fieldForce: string;
  };
  chipClasses: {
    entech: string;
    fieldForce: string;
  };
}

// TODO: Would be nice to get this info from the API
const entechSkills = [
  'CEV Charge master',
  'ETP Smart Charger',
  'V2G',
  'HES',
  'VCharge Heat Dynamo',
  'Commercial'
];

const fieldForceSkills = ['Elec', 'Gas', 'SMETS 1', 'SMETS 2'];

const chipUseStyles = makeStyles(({ palette }) => ({
  entech: {
    backgroundColor: palette.entech.main,
    color: palette.getContrastText(palette.entech.main)
  },
  fieldForce: {
    backgroundColor: palette.fieldForce.main,
    color: palette.getContrastText(palette.fieldForce.main)
  }
}));

const avatarUseStyles = makeStyles(({ palette }) => ({
  entech: {
    backgroundColor: palette.entech.avatar.main,
    color: `${palette.getContrastText(palette.entech.main)} !important`
  },
  fieldForce: {
    backgroundColor: palette.fieldForce.avatar.main,
    color: `${palette.getContrastText(palette.fieldForce.main)} !important`
  }
}));

export const getCategoryTeam = (
  category: { id: string; name: string },
  { avatarClasses, chipClasses }: ChipClasses
): Partial<ChipProps> => {
  const skillCategory = category.name;

  if (entechSkills.includes(skillCategory)) {
    return {
      className: chipClasses.entech,
      avatar: (
        <Avatar alt="Entech" className={avatarClasses.entech}>
          ET
        </Avatar>
      )
    };
  }
  if (fieldForceSkills.includes(skillCategory)) {
    return {
      className: `${chipClasses.fieldForce} MuiAutocomplete-tag`,
      avatar: (
        <Avatar alt="Field Force" className={avatarClasses.fieldForce}>
          FF
        </Avatar>
      )
    };
  }

  return {};
};

const SkillChip = ({ label, category, ...props }: ChipComponentProps) => {
  const chipClasses = chipUseStyles();
  const avatarClasses = avatarUseStyles();
  const skillTeamProps = getCategoryTeam(category, {
    chipClasses,
    avatarClasses
  });
  return <MuiChip label={label} {...props} {...skillTeamProps} />;
};

export default SkillChip;
