import React, { useState, cloneElement } from 'react';
import { Link } from 'react-router-dom';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  makeStyles,
  IconButton,
  Theme
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { PaginationComponent } from 'components/Pagination';
import { CrudCurriedFn } from 'components/Crud';
import CustomButton from 'components/Button';
import { onlyUnique } from 'utils';
import { Domain, DomainItem } from 'models/Domain';
import { QueryParams, ApiQueryParams } from 'store/utils';
import { CapacityUtilisation } from 'models/Capacity';
import Typography from 'components/Typography';
import { ContainerSizeProp } from '@material-ui/core/styles/makeStyles';
import ListTableHeaderCell from './ListTableHeaderCell';
import { SortByOptions } from '../utils';

interface HasResultsProp extends ContainerSizeProp {
  hasResults?: boolean;
}

type TextOverflow = {
  shouldTextOverflow?: boolean;
};

export const useTableStyles = makeStyles<Theme, HasResultsProp & TextOverflow>(
  theme => ({
    tableContainer: {
      display: 'flex',
      flexDirection: 'column',
      // The element fills the remaining space
      // theme.spacing(18) is total vertical padding of the listContainer
      maxHeight:
        window.innerHeight -
        (Number(theme.mixins.toolbar.minHeight) + theme.spacing(18)),
      minWidth: 651,
      width: ({ containerSize = 'large' }) => theme.containers[containerSize],
      margin: '0 auto',
      backgroundColor: theme.palette.common.white,
      borderRadius: 4,
      boxShadow:
        '0px 2px 4px rgba(0, 0, 0, 0.14), 0px 3px 4px rgba(0, 0, 0, 0.12), 0px 1px 5px rgba(0, 0, 0, 0.2)'
    },
    table: {
      '& .MuiTableCell-root:first-child': {
        paddingLeft: theme.spacing(2)
      },
      backgroundColor: theme.palette.common.white,
      boxShadow: 'none',
      tableLayout: 'fixed',
      borderRadius: ({ hasResults }) => (!hasResults ? 4 : 0)
    },
    tableRow: {
      '&:hover': {
        backgroundColor: theme.palette.background.paper
      },
      '&:last-child td': {
        borderBottom: 'none'
      }
    },
    tableCell: {
      height: 52,
      padding: theme.spacing(0, 2),
      color: theme.palette.common.black,
      borderBottom: `1px solid ${theme.palette.table.border}`,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: ({ shouldTextOverflow = true }) =>
        shouldTextOverflow ? 'ellipsis' : 'unset'
    },
    tableHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: theme.spacing(3, 2, 2),
      alignItems: 'center'
    },
    tableHeaderBorder: {
      borderBottomColor: theme.palette.table.border
    },
    tableHeaderCellLabel: {
      color: theme.palette.common.black
    },
    tableRowActionButton: {
      padding: 0,
      color: theme.palette.text.secondary,
      '&:first-child': {
        marginRight: 22
      }
    },
    tableHead: {
      zIndex: 1,
      position: 'relative'
    },
    tableHeaderButton: {
      margin: 0,
      padding: theme.spacing(1, 2),
      minWidth: 'auto',
      minHeight: 'auto'
    },
    tableHeaderButtonLink: {
      textDecoration: 'none'
    },
    noResultsRow: {
      height: 52
    },
    noResultsMessage: {
      textAlign: 'center',
      textTransform: 'none'
    }
  })
);

interface ListTableProps {
  columnsWidth: number[];
  children: JSX.Element[];
  domainItems: Array<DomainItem | CapacityUtilisation>;
  label: string;
  headers?: string[];
  rowHovered?: number | boolean;
  setRowHovered?: (newRowHovered: boolean | number) => void;
  actionsColumn?: boolean;
}

interface OldListTableProps extends ListTableProps {
  domain: Domain;
  children: JSX.Element[];
  title?: string;
  headerComponent?: JSX.Element;
  headerButtons?: TableButton[];
  showHeaderButtons?: boolean;
  filter?: QueryParams;
  sortBy?: SortByOptions;
  queryParams?: ApiQueryParams;
}

interface TableActionsProps {
  onEdit: CrudCurriedFn;
  onDelete: CrudCurriedFn;
  domainItem: DomainItem;
  domain: Domain;
  overrideUrl?: string;
}

interface TableButton {
  label: string;
  url?: string;
  openModal?: () => void;
}

export const useTableRowActionsState = () => {
  const [rowHovered, setRowHovered] = useState<boolean | number>(false);
  return { rowHovered, setRowHovered };
};

export function NewListTable({
  label,
  columnsWidth,
  rowHovered,
  setRowHovered,
  domainItems,
  headers,
  children,
  actionsColumn = true
}: ListTableProps) {
  const hasResults = children.length > 0;
  const classes = useTableStyles({ hasResults });

  const handleMouseEnter = (index: number) => () => {
    if (setRowHovered && rowHovered !== index) {
      setRowHovered(index);
    }
  };

  const handleMouseLeave = () => () => {
    if (setRowHovered && rowHovered !== false) {
      setRowHovered(false);
    }
  };

  let tableHeaderLabels;

  if (headers) {
    tableHeaderLabels = headers;
  } else {
    const headerKeys = domainItems.map(domainItem =>
      Object.keys(domainItem).filter(key => key !== 'id')
    );

    tableHeaderLabels = onlyUnique(headerKeys.flat());
  }

  return (
    <TableContainer className={classes.table} data-testid="listTable">
      <Table stickyHeader className={classes.table} aria-label={label}>
        <TableHead className={classes.tableHead}>
          <TableRow>
            {tableHeaderLabels.map((headerLabel, index) => (
              <ListTableHeaderCell
                key={headerLabel}
                width={columnsWidth[index]}
              >
                {headerLabel}
              </ListTableHeaderCell>
            ))}
            {actionsColumn ? (
              <TableCell className={classes.tableHeaderBorder}>
                <Typography variant="srOnly">Actions</Typography>
              </TableCell>
            ) : null}
          </TableRow>
        </TableHead>
        <TableBody>
          {hasResults ? (
            children.map((child, index) =>
              cloneElement(child, {
                onMouseEnter: handleMouseEnter(index),
                onMouseLeave: handleMouseLeave()
              })
            )
          ) : (
            <TableRow
              className={classes.noResultsRow}
              data-testid="noResultsRow"
            >
              <TableCell
                colSpan={tableHeaderLabels.length + 1}
                className={classes.noResultsMessage}
              >
                <Typography component="span">No results found</Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ListTable(props: OldListTableProps) {
  const {
    label,
    columnsWidth,
    rowHovered,
    setRowHovered,
    domainItems,
    headers,
    children,
    title,
    domain,
    headerComponent,
    headerButtons,
    showHeaderButtons = true,
    filter,
    sortBy,
    queryParams
  } = props;

  const hasResults = children.length > 0;
  const classes = useTableStyles({ hasResults });

  const modalButton = (button: TableButton) => (
    <CustomButton
      key={button.label}
      color="primary"
      className={classes.tableHeaderButton}
      data-testid={`${domain.type}TableButton`}
      size="medium"
      onClick={button.openModal}
    >
      {button.label}
    </CustomButton>
  );

  const nonModalButton = (button: TableButton) => (
    <Link
      key={button.url}
      to={button.url}
      className={classes.tableHeaderButtonLink}
    >
      <CustomButton
        color="primary"
        className={classes.tableHeaderButton}
        data-testid={`${domain.type}TableButton`}
        size="medium"
      >
        {button.label}
      </CustomButton>
    </Link>
  );

  const renderTableHeaderButtons = (buttons: TableButton[]) =>
    buttons.map(button => {
      return button.openModal ? modalButton(button) : nonModalButton(button);
    });

  let headerButtonsElements;

  if (showHeaderButtons) {
    if (headerButtons) {
      headerButtonsElements = renderTableHeaderButtons(headerButtons);
    } else {
      headerButtonsElements = renderTableHeaderButtons([
        {
          url: `/dashboard/${domain.urlPath}/create`,
          label: `New ${domain.singular}`
        }
      ]);
    }
  }

  return (
    <>
      <div className={classes.tableHeader}>
        <Typography variant="h1" data-testid="readDomainPageTitle">
          {title}
        </Typography>
        <div>{headerButtonsElements}</div>
      </div>
      {headerComponent}

      <NewListTable
        {...{
          label,
          columnsWidth,
          rowHovered,
          setRowHovered,
          domainItems,
          headers,
          children
        }}
      />

      {hasResults && (
        <PaginationComponent
          domain={domain}
          filter={filter}
          sortBy={sortBy}
          queryParams={queryParams}
        />
      )}
    </>
  );
}

export function TableActions({
  onEdit,
  onDelete,
  domainItem,
  domain,
  overrideUrl
}: TableActionsProps) {
  const classes = useTableStyles({ shouldTextOverflow: false });

  return (
    <TableCell align="right" className={classes.tableCell}>
      {onEdit && (
        <IconButton
          className={classes.tableRowActionButton}
          aria-label={`Edit ${domain.label}`}
          onClick={onEdit(domainItem, overrideUrl)}
          data-testid="tableRowEdit"
        >
          <EditIcon />
        </IconButton>
      )}
      {onDelete && (
        <IconButton
          className={classes.tableRowActionButton}
          aria-label={`Delete ${domain.label}`}
          onClick={onDelete(domainItem)}
          data-testid="tableRowDelete"
        >
          <DeleteIcon />
        </IconButton>
      )}
    </TableCell>
  );
}
