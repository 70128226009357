import React, { memo } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core';
import {
  EngineerSchedule,
  JobDetailsFields,
  ScheduleAssignmentEvent,
  ScheduleAssignmentType
} from 'models/Schedule';
import { AppointmentSlot } from 'models/Appointment';
import { getPostcode } from 'components/Schedule/scheduleHelpers';
import { timelineStart } from 'views/Schedule/ScheduleView';
import { getJobCardStyleType } from 'utils/jobStatus';
import { defaultSlotHighlight } from '../TimelineHeader';
import JobCard, { JobCardViewMode } from '../JobCard/JobCard';
import { EngineerInfo } from '../EngineerInfo/EngineerInfo';
import UnassignedJobsContainer, {
  UnassignedJobsLayout
} from '../UnassignedJobsContainer/UnassignedJobsContainer';
import { getHydratedJobDetailsWithAssignedSchedule } from '../Map/scheduleMapHelpers';
import { DragHoverCallback } from '../EngineerList/EngineerList';

interface UnavailableEngineerRowProps {
  engineerSchedule: EngineerSchedule;
  onChange: (change: ScheduleAssignmentEvent) => void;
  onDragHover: DragHoverCallback;
  onJobCardSelect: (slot: AppointmentSlot) => void;
  handleJobCardOnClick: (value: JobDetailsFields) => void;
  selectedJobCardDetails: JobDetailsFields;
}

const useStyles = makeStyles<Theme, { jobCount: number }>(
  theme =>
    createStyles({
      container: {
        display: 'flex',
        width: '100%',
        height: 52,
        background: ({ jobCount }) =>
          jobCount
            ? theme.palette.schedule.timeline.unassignedRow.background
            : 'transparent'
      },

      engineerInfoContainer: {
        display: 'flex',
        width: ({ jobCount }) =>
          jobCount ? timelineStart - theme.spacing(1) : timelineStart,
        alignItems: 'center',
        marginLeft: theme.spacing(1)
      },

      jobsWrapper: {
        display: 'flex',
        alignItems: 'center'
      }
    }),
  { name: 'UnavailableEngineerRow' }
);

const UnavailableEngineerRow = (props: UnavailableEngineerRowProps) => {
  const { engineerSchedule, onDragHover } = props;

  // Minus two because of 'START' and 'END' job types
  const jobCount =
    engineerSchedule.assignments.length - 2 < 0
      ? 0
      : engineerSchedule.assignments.length - 2;

  const classes = useStyles({ jobCount });

  const renderAssignments = () =>
    engineerSchedule.assignments
      .filter(assignment => assignment.type === ScheduleAssignmentType.Job)
      .map(assignment => (
        <div
          data-testid="unavailableEngineerJobCard"
          onDragStart={() =>
            'slot' in assignment.appointment
              ? props.onJobCardSelect(assignment.appointment.slot)
              : () => null
          }
          onDrag={() =>
            'slot' in assignment.appointment
              ? props.onJobCardSelect(assignment.appointment.slot)
              : () => null
          }
          onDragEnd={() =>
            'slot' in assignment.appointment
              ? props.onJobCardSelect(defaultSlotHighlight)
              : () => null
          }
          onClick={() => {
            onDragHover(engineerSchedule.engineer.id);
            if ('slot' in assignment.appointment) {
              props.onJobCardSelect(assignment.appointment.slot);
            }
            props.handleJobCardOnClick(
              getHydratedJobDetailsWithAssignedSchedule(assignment, {
                engineerId: engineerSchedule.engineer.id,
                firstName: engineerSchedule.engineer.firstName,
                lastName: engineerSchedule.engineer.lastName,
                scheduleStatus: engineerSchedule.status
              })
            );
          }}
          data-slot={assignment.appointment.slot}
          key={assignment.appointment.id}
        >
          <JobCard
            jobCardStyleType={getJobCardStyleType(
              assignment.appointment,
              false
            )}
            jobType={assignment.appointment.jobType}
            appointmentId={assignment.appointment.id}
            engineerId={engineerSchedule.engineer.id}
            duration={assignment.length}
            slot={assignment.appointment?.slot}
            label={getPostcode(assignment.location?.postcode)}
            viewMode={JobCardViewMode.TIMELINE}
            assigned={false}
            onDrop={item =>
              props.onChange({
                action: 'remove',
                engineerId: engineerSchedule.engineer.id,
                id: item.appointmentId,
                type: ScheduleAssignmentType.Job
              })
            }
            selectedJobCardDetails={props.selectedJobCardDetails}
          />
        </div>
      ));

  return (
    <div data-testid="unavailableEngineerRow" className={classes.container}>
      <EngineerInfo
        firstName={engineerSchedule.engineer.firstName}
        lastName={engineerSchedule.engineer.lastName}
        vehicleType={engineerSchedule.engineer.vehicleType}
        jobCount={jobCount}
        travelTime={0}
        unavailableReason={engineerSchedule.unavailableReason}
        scheduleStatus={engineerSchedule.status}
        engineerId={engineerSchedule.engineer.id}
        containerStyles={classes.engineerInfoContainer}
      />
      <div className={classes.jobsWrapper}>
        {engineerSchedule.assignments && (
          <UnassignedJobsContainer layout={UnassignedJobsLayout.ONE_ROW}>
            {renderAssignments()}
          </UnassignedJobsContainer>
        )}
      </div>
    </div>
  );
};

export default memo(UnavailableEngineerRow);
