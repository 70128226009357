import React from 'react';
import {
  Accordion as MUIAccordion,
  AccordionSummary,
  AccordionDetails
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Typography from 'components/Typography';

interface AccordionComponentProps {
  label: string;
  id: string;
  children: React.ReactNode | React.ReactNode[];
  icon?: React.ReactNode;
  className?: string;
  expanded?: boolean;
  testId?: string;
}

const useStyles = makeStyles(theme => ({
  accordion: {
    boxShadow: 'none',
    '&.Mui-expanded': {
      margin: 0
    }
  },
  summary: {
    height: 53,
    padding: 0,
    backgroundColor: theme.palette.common.white,
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  summaryContent: {
    display: 'flex',
    alignItems: 'center'
  },
  expanded: {
    '&.Mui-expanded': {
      minHeight: 53
    }
  },
  labelContainer: {
    display: 'grid',
    gridAutoFlow: 'column',
    alignItems: 'center',
    gridGap: 12
  },
  arrow: {
    color: theme.palette.text.hint,
    marginRight: -13
  },
  details: {
    padding: 0,
    backgroundColor: theme.palette.common.white
  }
}));

export default function Accordion({
  label,
  icon,
  id,
  children,
  className,
  expanded,
  testId
}: AccordionComponentProps) {
  const classes = useStyles();

  return (
    <MUIAccordion
      className={
        className ? `${className} ${classes.accordion}` : classes.accordion
      }
      defaultExpanded={expanded || false}
      data-testid={testId}
    >
      <AccordionSummary
        className={classes.summary}
        classes={{
          content: classes.summaryContent,
          expandIcon: classes.arrow,
          root: classes.expanded
        }}
        expandIcon={<ArrowDropDownIcon />}
        aria-controls={`${id}-content`}
        id={id}
      >
        <div className={classes.labelContainer}>
          {icon}
          <Typography variant="h3" component="h2">
            {label}
          </Typography>
        </div>
      </AccordionSummary>
      <AccordionDetails className={classes.details}>
        {children}
      </AccordionDetails>
    </MUIAccordion>
  );
}
