import * as Yup from 'yup';

import {
  getDefaultInputFieldsProps,
  mapSkillsToAutocompleteValues,
  FormFieldConfig,
  patchDataToSelect,
  shiftPatternDataToSelect,
  teamDataToSelect,
  yupSchemaToValidationFunc,
  SkillOption
} from 'components/Forms';
import { vehicleOptions } from 'utils/formValues';
import Skill from 'models/Skills';
import Team from 'models/Team';
import Patch from 'models/Patches';
import ShiftPattern from 'models/ShiftPattern';
import { Filter, FilterFieldType } from 'components/FilterSearch/FilterSearch';

import {
  getRequiredErrorMessage,
  NUMBERS_ONLY_FIELD_ERROR_MESSAGE
} from 'constants/validation';
import { camelToSentenceCase } from 'utils';
import { skillDomain } from 'models';
import { getEfficiencyOptions } from './utils';

export function engineerFormFields(
  type: 'add' | 'edit',
  skills: Skill[] | null,
  patches: Patch[] | null,
  shiftPatterns: ShiftPattern[] | null,
  teams: Team[] | null
) {
  const fields: FormFieldConfig[] = [
    {
      required: true,
      name: 'firstName',
      autoFocus: true,
      qaId: `${type}EngineerFirstName`,
      inputType: 'textfield'
    },
    {
      required: true,
      name: 'lastName',
      qaId: `${type}EngineerLastName`,
      inputType: 'textfield'
    },
    {
      required: true,
      name: 'phoneNumber',
      qaId: `${type}EngineerPhoneNumber`,
      inputType: 'textfield'
    },
    {
      required: true,
      name: 'emailAddress',
      qaId: `${type}EngineerEmailAddress`,
      inputType: 'textfield'
    },
    {
      required: true,
      name: 'postcode',
      qaId: `${type}EngineerPostcode`,
      inputType: 'textfield'
    },
    {
      name: 'team',
      qaId: `${type}EngineerTeam`,
      inputType: 'select',
      options: teams && teams.map(teamDataToSelect)
    },
    {
      required: true,
      name: 'skills',
      qaId: `${type}EngineerSkills`,
      inputType: 'multiselect',
      options: skills && mapSkillsToAutocompleteValues(skills),
      optionRenderer: SkillOption,
      preprocess: items => items && mapSkillsToAutocompleteValues(items)
    },
    {
      required: true,
      name: 'company',
      qaId: `${type}EngineerCompany`,
      inputType: 'textfield'
    },
    {
      name: 'vehicleType',
      qaId: `${type}EngineerVehicleType`,
      inputType: 'select',
      options: vehicleOptions
    },
    {
      required: true,
      name: 'byBox',
      qaId: `${type}EngineerByBox`,
      inputType: 'textfield'
    },
    {
      required: true,
      name: 'defaultPatch',
      qaId: `${type}EngineerDefaultPatch`,
      inputType: 'select',
      options: patches && patches.map(patchDataToSelect)
    },
    {
      required: true,
      name: 'defaultShiftPattern',
      qaId: `${type}EngineerDefaultShiftPattern`,
      inputType: 'select',
      options: shiftPatterns && shiftPatterns.map(shiftPatternDataToSelect)
    },
    {
      required: true,
      name: 'defaultEfficiency',
      qaId: `${type}EngineerDefaultEfficiency`,
      inputType: 'select',
      options: getEfficiencyOptions()
    }
  ];

  return getDefaultInputFieldsProps(fields);
}

export const engineerSearchFields = getDefaultInputFieldsProps([
  {
    required: true,
    name: 'lastName',
    label: 'Engineer last name',
    qaId: `searchEngineer`,
    inputType: 'textfield'
  }
]);

export const validationSchema = Yup.object().shape({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  phoneNumber: Yup.number().required('Required'),
  emailAddress: Yup.string().required('Required'),
  postcode: Yup.string().required('Required'),
  company: Yup.string().required('Required'),
  skills: Yup.array().required('Required'),
  byBox: Yup.string().required('Required'),
  defaultPatch: Yup.string().required('Required'),
  defaultShiftPattern: Yup.string().required('Required'),
  defaultEfficiency: Yup.string().required('Required')
});

export enum EngineersFieldNames {
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  PHONE_NUMBER = 'phoneNumber',
  EMAIL_ADDRESS = 'emailAddress',
  POSTCODE = 'postcode',
  TEAM = 'team',
  SKILLS = 'skills',
  COMPANY = 'company',
  VEHICLE_TYPE = 'vehicleType',
  BYBOX = 'byBox',
  DEFAULT_PATCH = 'defaultPatch',
  DEFAULT_SHIFT_PATTERN = 'defaultShiftPattern',
  DEFAULT_EFFICIENCY = 'defaultEfficiency'
}

const emailValidationMessage = getRequiredErrorMessage(
  camelToSentenceCase(EngineersFieldNames.EMAIL_ADDRESS).toLowerCase()
);

const newValidationSchema = Yup.object().shape({
  [EngineersFieldNames.FIRST_NAME]: Yup.string().required(
    getRequiredErrorMessage(
      camelToSentenceCase(EngineersFieldNames.FIRST_NAME).toLowerCase()
    )
  ),
  [EngineersFieldNames.LAST_NAME]: Yup.string().required(
    getRequiredErrorMessage(
      camelToSentenceCase(EngineersFieldNames.LAST_NAME).toLowerCase()
    )
  ),
  [EngineersFieldNames.PHONE_NUMBER]: Yup.number()
    // This is needed because the default value for a field is an empty string,
    // therefore Yup will think it is a type error and display the wrong message
    .transform((value, originalValue) =>
      originalValue === '' ? undefined : value
    )
    .required(
      getRequiredErrorMessage(
        camelToSentenceCase(EngineersFieldNames.PHONE_NUMBER).toLowerCase()
      )
    )
    .typeError(NUMBERS_ONLY_FIELD_ERROR_MESSAGE),
  [EngineersFieldNames.EMAIL_ADDRESS]: Yup.string()
    .email(emailValidationMessage)
    .required(emailValidationMessage),
  [EngineersFieldNames.POSTCODE]: Yup.string().required(
    getRequiredErrorMessage(
      camelToSentenceCase(EngineersFieldNames.POSTCODE).toLowerCase()
    )
  ),
  [EngineersFieldNames.TEAM]: Yup.string(),
  [EngineersFieldNames.SKILLS]: Yup.array().required(
    getRequiredErrorMessage(skillDomain.singular)
  ),
  [EngineersFieldNames.COMPANY]: Yup.string().required(
    getRequiredErrorMessage(
      camelToSentenceCase(EngineersFieldNames.COMPANY).toLowerCase()
    )
  ),
  [EngineersFieldNames.VEHICLE_TYPE]: Yup.string(),
  [EngineersFieldNames.BYBOX]: Yup.string().required(
    getRequiredErrorMessage(
      camelToSentenceCase(EngineersFieldNames.BYBOX).toLowerCase()
    )
  ),
  [EngineersFieldNames.DEFAULT_PATCH]: Yup.string().required(
    getRequiredErrorMessage(
      camelToSentenceCase(EngineersFieldNames.DEFAULT_PATCH).toLowerCase()
    )
  ),
  [EngineersFieldNames.DEFAULT_SHIFT_PATTERN]: Yup.string().required(
    getRequiredErrorMessage(
      camelToSentenceCase(
        EngineersFieldNames.DEFAULT_SHIFT_PATTERN
      ).toLowerCase()
    )
  ),
  [EngineersFieldNames.DEFAULT_EFFICIENCY]: Yup.string().required(
    getRequiredErrorMessage(
      camelToSentenceCase(EngineersFieldNames.DEFAULT_EFFICIENCY).toLowerCase()
    )
  )
});

export const engineersFormValidation = yupSchemaToValidationFunc(
  newValidationSchema
);

export const engineerFilters: Filter[] = [
  {
    id: 'firstName',
    queryString: 'firstName',
    name: 'First Name',
    operator: '%',
    fieldType: FilterFieldType.TEXT
  },
  {
    id: 'lastName',
    queryString: 'lastName',
    name: 'Last Name',
    operator: '%',
    fieldType: FilterFieldType.TEXT
  },
  {
    id: 'postcode',
    queryString: 'postcode',
    name: 'Postcode',
    operator: '%',
    fieldType: FilterFieldType.TEXT
  }
];
