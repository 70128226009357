import React from 'react';
import MUISnackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';
import ErrorIcon from '@material-ui/icons/Error';
import DoneIcon from '@material-ui/icons/Done';
import { useDispatch, useSelector } from 'react-redux';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { AlertTitle } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core';
import { getSnackbarSlice, RootState } from 'store';
import {
  Snackbar as SnackbarState,
  SnackbarStatus
} from 'store/slices/snackbar';
import Typography from 'components/Typography';

const useStyles = makeStyles(theme => ({
  snackbar: {
    padding: theme.spacing(1, 1, 1, 2),
    color: theme.palette.common.white,
    backgroundColor: (props: AlertProps) => {
      if (props.severity === SnackbarStatus.Success) {
        return theme.palette.aegis.semantic.primary.base;
      }
      if (props.severity === SnackbarStatus.Error) {
        return theme.palette.aegis.semantic.error.base;
      }
      if (props.severity === SnackbarStatus.Warning) {
        return theme.palette.aegis.semantic.message.black;
      }
      if (props.severity === SnackbarStatus.Info) {
        return theme.palette.aegis.semantic.message.black;
      }
      return theme.palette.aegis.semantic.primary.base;
    }
  },
  iconWrapper: {
    alignItems: 'center'
  },
  dismissButton: {
    padding: theme.spacing(1, 2)
  }
}));

function Alert(props: AlertProps) {
  const classes = useStyles(props);

  return (
    <MuiAlert
      elevation={6}
      variant="filled"
      className={classes.snackbar}
      classes={{
        icon: classes.iconWrapper
      }}
      {...props}
    />
  );
}

export default function Snackbar() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { selector, actions } = getSnackbarSlice();
  const { title, message, show, status, redirectAction } = useSelector<
    RootState,
    SnackbarState
  >(selector);

  const autoHideDurationInMs = 6000;

  const handleClose = () => {
    dispatch(actions.clearSnackbar());
  };

  const handleRedirectAction = () => {
    return window.open(
      `${window.location.origin}/${redirectAction?.url}`,
      redirectAction?.newTab ? '_blank' : '_top'
    );
  };

  const alertActionButton = (
    <Button
      data-testid="snackbar-action"
      color="inherit"
      size="small"
      className={classes.dismissButton}
      onClick={redirectAction ? handleRedirectAction : handleClose}
    >
      {redirectAction ? redirectAction.title : 'Dismiss'}
    </Button>
  );
  return (
    <MUISnackbar
      open={show}
      autoHideDuration={autoHideDurationInMs}
      onClose={handleClose}
    >
      <Alert
        data-qa-id="snackbar"
        data-testid="snackbar"
        action={alertActionButton}
        severity={status == null ? SnackbarStatus.Info : status}
        iconMapping={{
          success: <DoneIcon fontSize="inherit" />,
          error: <ErrorIcon fontSize="inherit" />,
          warning: <DoneIcon fontSize="inherit" />,
          info: <DoneIcon fontSize="inherit" />
        }}
      >
        {!title ? null : (
          <AlertTitle>
            <Typography variant="h3" component="div">
              {title}
            </Typography>
          </AlertTitle>
        )}
        <Typography>{message}</Typography>
      </Alert>
    </MUISnackbar>
  );
}
