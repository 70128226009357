/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import SkillCategory from 'models/SkillCategory';
import { DomainItem } from 'models/Domain';
import { AppThunk } from 'store';
import { FormValues } from 'components/Forms';
import {
  initialStateSlice,
  startLoading,
  loadingFailed,
  StateSlice,
  getPaginationReducers
} from 'store/slices';
import { skillCategoryDomain } from 'models';
import {
  fetchThunk,
  deleteItemThunk,
  addItemThunk,
  editItemThunk,
  fetchAllThunk
} from 'store/utils/';

export interface SkillCategoriesStateSlice extends StateSlice<SkillCategory> {
  allItems?: SkillCategory[];
}

const initialSkillCategoriesStateSlice: SkillCategoriesStateSlice = {
  ...initialStateSlice,
  allItems: []
};
const skillCategories = createSlice({
  name: skillCategoryDomain.plural,
  initialState: initialSkillCategoriesStateSlice,
  reducers: {
    ...startLoading<SkillCategory>(),
    ...loadingFailed<SkillCategory>(),
    ...getPaginationReducers<SkillCategory>(),
    getSkillCategoriesSuccess(
      state,
      { payload }: PayloadAction<StateSlice<SkillCategory>>
    ) {
      const { items, page } = payload;

      state.items = items;
      state.page = page;
      state.isLoading = false;
      state.error = null;
    },
    addSkillCategorySuccess(
      state,
      { payload: addedSkill }: PayloadAction<SkillCategory>
    ) {
      // We could check here if we already have a skill with the same name
      // in the store, but I'll leave that up to the API to decide
      //
      // We could also either push the new item into state or dispatch `fetchSkills`
      // after `addSkillCategorySuccess` in the thunk to refresh the state
      if (state.items != null) {
        state.items.push(addedSkill);
      }

      state.isLoading = false;
      state.error = null;
    },
    editSkillCategorySuccess(
      state,
      { payload: editedSkillCategory }: PayloadAction<SkillCategory>
    ) {
      if (state.items != null) {
        state.items = state.items.filter(
          skillCategory => skillCategory.id !== editedSkillCategory.id
        );
        state.items.push(editedSkillCategory);
      }

      state.isLoading = false;
      state.error = null;
    },
    deleteSkillCategorySuccess(
      state,
      { payload: deletedSkillCategoryId }: PayloadAction<string>
    ) {
      if (state.items != null) {
        state.items = state.items.filter(
          skillCategory => skillCategory.id !== deletedSkillCategoryId
        );
      }

      state.isLoading = false;
      state.error = null;
    }
  }
});

export const {
  requestStart,
  requestFail,
  setPagination: setSkillCategoriesPagination,
  getSkillCategoriesSuccess,
  addSkillCategorySuccess,
  editSkillCategorySuccess,
  deleteSkillCategorySuccess
} = skillCategories.actions;

export default skillCategories.reducer;

export function fetchSkillCategories(): AppThunk {
  return fetchThunk<StateSlice<SkillCategory>>({
    domain: skillCategoryDomain,
    requestStart,
    requestFail,
    requestSuccess: getSkillCategoriesSuccess
  });
}

export function fetchAllSkillCategories(): AppThunk {
  return fetchAllThunk(skillCategoryDomain, {
    start: requestStart,
    success: getSkillCategoriesSuccess,
    fail: requestFail
  });
}

export function addSkillCategory(item: FormValues): AppThunk {
  return addItemThunk<SkillCategory>({
    domain: skillCategoryDomain,
    requestStart,
    requestFail,
    requestSuccess: addSkillCategorySuccess,
    item
  });
}

export function editSkillCategory(item: FormValues, itemId: string): AppThunk {
  return editItemThunk<SkillCategory>({
    domain: skillCategoryDomain,
    requestStart,
    requestFail,
    requestSuccess: editSkillCategorySuccess,
    itemId,
    item
  });
}

export function deleteSkillCategory(domainItem: DomainItem): AppThunk {
  return deleteItemThunk<SkillCategory>({
    domain: skillCategoryDomain,
    requestStart,
    requestFail,
    requestSuccess: deleteSkillCategorySuccess,
    itemId: domainItem.id
  });
}
