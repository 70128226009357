import React, { memo } from 'react';

import { makeStyles, createStyles } from '@material-ui/core';
import Typography from 'components/Typography';

interface ErrorDisplayProps {
  status: null | { message: string };
}

const useStyles = makeStyles(theme =>
  createStyles({
    errorDisplay: {
      color: theme.palette.error.main
    }
  })
);

const ErrorDisplay: React.FunctionComponent<ErrorDisplayProps> = ({
  status
}) => {
  const classes = useStyles();

  return status == null || (status != null && status.message == null) ? null : (
    <Typography
      className={classes.errorDisplay}
      data-qa-id="errorDisplay"
      data-testid="errorDisplay"
    >
      {status.message}
    </Typography>
  );
};

export default memo(ErrorDisplay);
