import { IconType, JobSheetData } from 'models/JobDetails';
import React from 'react';

import { Link, List, ListItem } from '@material-ui/core';
import Typography from 'components/Typography';
import { SummaryPropertyType } from 'views/Jobs/AddJobModal/SummaryProperty';
import AccessibleIcon from '@material-ui/icons/Accessible';
import InfoIcon from '@material-ui/icons/Info';
import { useStyles } from './JobDetailsSheet';

interface JobDetailsPanelProps {
  data: JobSheetData[];
}

const Icon = ({ type }: { type: IconType }) => {
  if (type === IconType.VULNERABLE) {
    return <AccessibleIcon className="vulnerable" />;
  }
  if (type === IconType.INFO) {
    return <InfoIcon className="info" />;
  }
  return null;
};

export const JobDetailsPanel = ({ data }: JobDetailsPanelProps) => {
  const classes = useStyles();

  const MultilineRowValue = ({
    value,
    onClick,
    onClickRowValueIndex
  }: {
    value: string[];
    onClick?: () => void;
    onClickRowValueIndex?: number;
  }): JSX.Element => {
    if (onClick) {
      return (
        <Typography component="div">
          {value.map((current, index) => {
            const showRowLink =
              onClickRowValueIndex === undefined ||
              onClickRowValueIndex === index;
            return (
              <React.Fragment key={current}>
                <div
                  className={
                    showRowLink ? classes.rowValueOnClick : classes.rowValue
                  }
                  onClick={showRowLink ? onClick : undefined}
                  data-testid={
                    showRowLink
                      ? 'rowValueMultilineOnClick'
                      : 'rowValueMultiline'
                  }
                >
                  {current}
                </div>
              </React.Fragment>
            );
          })}
        </Typography>
      );
    }

    return (
      <Typography component="div" className={classes.rowValue}>
        {value.map(current => (
          <React.Fragment key={current}>
            <div data-testid="rowValueMultiline">{current}</div>
          </React.Fragment>
        ))}
      </Typography>
    );
  };

  const RowValue = ({
    type,
    value,
    onClick,
    onClickRowValueIndex
  }: {
    type: string;
    value: string | string[];
    onClick?: () => void;
    onClickRowValueIndex?: number;
  }): JSX.Element => {
    if (type === 'textarea' && Array.isArray(value)) {
      return (
        <MultilineRowValue
          value={value}
          onClick={onClick}
          onClickRowValueIndex={onClickRowValueIndex}
        />
      );
    }

    if (onClick) {
      return (
        <div onClick={onClick}>
          <Typography
            className={classes.rowValueOnClick}
            data-testid="rowValueOnClick"
          >
            {value}
          </Typography>
        </div>
      );
    }

    if (type === SummaryPropertyType.LINK) {
      return (
        <Typography align="right" variant="body" data-testid="rowValue">
          <Link
            href={value as string}
            className={classes.linkUrl}
            target="_blank"
          >
            {value}
          </Link>
        </Typography>
      );
    }

    return (
      <Typography className={classes.rowValue} data-testid="rowValue">
        {value}
      </Typography>
    );
  };

  return (
    <div className={classes.jobDetailsPanel}>
      {data.map((section: JobSheetData) => (
        <List key={section.label} dense className={classes.rowContainer}>
          <ListItem className={`${classes.sectionLabel} ${classes.divider}`}>
            <Typography variant="h2" data-testid="sectionTitle">
              {section.label}
            </Typography>
          </ListItem>
          <List dense className={classes.rowContainer}>
            {section.rows.map(row => (
              <ListItem
                key={`${row.value}${row.label}`}
                className={`${classes.divider} ${
                  row.type === 'textarea' ? classes.textAreaRow : classes.row
                }`}
              >
                <Typography className={classes.rowLabel} data-testid="rowLabel">
                  {row.label}
                  <span className={classes.labelIcon}>
                    {typeof row.icon !== 'undefined' && row.value !== '-' && (
                      <Icon type={row.icon} />
                    )}
                  </span>
                </Typography>
                <RowValue
                  onClick={row.onClick}
                  type={row.type}
                  value={row.value}
                  onClickRowValueIndex={row.onClickRowValueIndex}
                />
              </ListItem>
            ))}
          </List>
        </List>
      ))}
    </div>
  );
};
