import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core';
import {
  CreateJobFormValues,
  CustomerVulnerability,
  EVFormChannel
} from 'models/Appointment';
import { DATE_WITH_SLASH_FORMAT } from 'constants/dates';
import Typography from 'components/Typography';
import { formatVulnerabilities } from 'components/JobDetailsSheet/jobsHelper';
import { FormRefProp } from 'components/Modal';
import { useAddJobModalStyles } from './AddJobModal';
import SummaryProperty, {
  SummaryPropertyOrientation,
  SummaryPropertyType
} from './SummaryProperty';
import { VulnerabilitiesFieldNames } from './JobFormConfig';

interface SummaryProps extends FormRefProp {
  appointment: CreateJobFormValues;
}

const useStyles = makeStyles(theme =>
  createStyles({
    formSectionHeading: {
      margin: theme.spacing(2.5, -1, 0)
    },
    summaryPropTitle: {
      color: theme.palette.common.black
    },
    summaryPropValue: {
      whiteSpace: 'pre-line'
    }
  })
);

export default function Summary({ appointment }: SummaryProps) {
  const addJobModalClasses = useAddJobModalStyles();
  const classes = useStyles();
  const evForm = Boolean(appointment.jobType.name.startsWith('EV'));

  const accountDetailsFieldsSmart = [
    { label: 'Account number', value: appointment.accountNumber },
    { label: 'Brand', value: appointment.brand },
    { label: 'Payment type', value: appointment.paymentType },
    { label: 'MPAN', value: appointment.mpan },
    { label: 'MPRN', value: appointment.mprn },
    { label: 'Electricity MSN', value: appointment.electricityMsn },
    { label: 'Gas MSN', value: appointment.gasMsn },
    {
      label: 'Booking notes',
      value: appointment.bookingNotes,
      orientation: SummaryPropertyOrientation.BELOW
    }
  ];

  const accountDetailsFieldsEV = [
    { label: 'Zoho account n.', value: appointment?.zohoAccountId },
    {
      label: 'Channel',
      value: Object.fromEntries(Object.entries(EVFormChannel))[
        appointment.channel as string
      ]
    },
    {
      label: 'Jumptech URL',
      value: appointment?.jumptechURL,
      type: SummaryPropertyType.LINK
    },
    { label: 'MPAN', value: appointment.mpan },
    {
      label: 'Booking notes',
      value: appointment.bookingNotes,
      orientation: SummaryPropertyOrientation.BELOW
    }
  ];

  const vulnerabilities =
    appointment?.customerHasVulnerabilities == 'true'
      ? Object.fromEntries(
          Object.entries(appointment).filter(
            ([name]) =>
              Object.values(VulnerabilitiesFieldNames).includes(
                name as VulnerabilitiesFieldNames
              ) && name !== 'customerHasVulnerabilities'
          )
        )
      : null;

  const formattedVulnerabilities = formatVulnerabilities(
    vulnerabilities as CustomerVulnerability
  );

  const vulnerabilitiesList: string =
    typeof formattedVulnerabilities === 'string'
      ? (formattedVulnerabilities as string)
      : (formattedVulnerabilities as string[]).join('\n');

  const dataFields: {
    label: string;
    value?: string;
    type?: SummaryPropertyType;
    orientation?: SummaryPropertyOrientation;
  }[][] = [
    [
      { label: 'Postcode', value: appointment.postcode.toLocaleUpperCase() },
      { label: 'Job type', value: appointment.jobType.name },
      ...(evForm
        ? []
        : [
            { label: 'Appointment type', value: appointment.appointmentType },
            { label: 'Fuel type', value: appointment.fuelType }
          ]),
      {
        label: 'Date',
        value: appointment.date.toFormat(DATE_WITH_SLASH_FORMAT)
      },
      { label: 'Time slot', value: appointment.timeSlot }
    ],
    [
      {
        label: 'Name',
        value: `${appointment.firstName} ${appointment.lastName}`
      },
      { label: 'Phone number', value: appointment.phoneNumber },
      { label: 'Secondary number', value: appointment.secondaryNumber },
      {
        label: 'Address',
        value: `${appointment.addressLine1}
          ${
            appointment.addressLine2 !== undefined
              ? appointment.addressLine2
              : ''
          }
          ${
            appointment.addressLine3 !== undefined
              ? appointment.addressLine3
              : ''
          }
          ${appointment.city}`
          .split('\n')
          .map(s => s.trim())
          .filter(Boolean)
          .join('\n'),
        orientation: SummaryPropertyOrientation.BELOW
      },
      {
        label: 'Vulnerabilities',
        value: vulnerabilitiesList,
        orientation:
          vulnerabilities && Object.keys(vulnerabilities).length > 1
            ? SummaryPropertyOrientation.BELOW
            : SummaryPropertyOrientation.SIDE
      }
    ],
    evForm ? accountDetailsFieldsEV : accountDetailsFieldsSmart
  ];

  const [jobSection, customerSection, accountSection] = dataFields;

  return (
    <>
      <div className={addJobModalClasses.infoContainer}>
        {jobSection.map(({ label, value, orientation, type }) => (
          <SummaryProperty
            key={label}
            name={label}
            orientation={orientation}
            value={value}
            type={type}
          />
        ))}
      </div>
      <Typography variant="h2" className={classes.formSectionHeading}>
        Customer details
      </Typography>
      <div className={addJobModalClasses.infoContainer}>
        {customerSection.map(({ label, value, orientation, type }) => (
          <SummaryProperty
            key={label}
            name={label}
            orientation={orientation}
            value={value}
            type={type}
          />
        ))}
      </div>{' '}
      <Typography variant="h2" className={classes.formSectionHeading}>
        Account details
      </Typography>
      <div className={addJobModalClasses.infoContainer}>
        {accountSection.map(({ label, value, orientation, type }) => (
          <SummaryProperty
            key={label}
            name={label}
            orientation={orientation}
            value={value}
            type={type}
          />
        ))}
      </div>
    </>
  );
}
