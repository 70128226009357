/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'store';
import { FormValues } from 'components/Forms';
import JobType from 'models/JobTypes';
import { DomainItem } from 'models/Domain';
import {
  StateSlice,
  initialStateSlice,
  startLoading,
  loadingFailed,
  getPaginationReducers
} from 'store/slices';
import { jobTypeDomain } from 'models';
import {
  fetchThunk,
  fetchItemThunk,
  deleteItemThunk,
  addItemThunk,
  editItemThunk,
  fetchAllThunk,
  AddItemThunkOptions
} from 'store/utils/';

export interface JobTypesStateSlice extends StateSlice<JobType> {
  allItems?: JobType[];
}

const initialJobTypesStateSlice: JobTypesStateSlice = {
  ...initialStateSlice,
  allItems: []
};

const sortByJobName = (job: JobType, nextJob: JobType) =>
  job.name.localeCompare(nextJob.name);

const jobTypes = createSlice({
  name: jobTypeDomain.plural,
  initialState: initialJobTypesStateSlice,
  reducers: {
    ...startLoading<JobType>(),
    ...loadingFailed<JobType>(),
    ...getPaginationReducers<JobType>(),
    getJobTypesSuccess(state, { payload }: PayloadAction<StateSlice<JobType>>) {
      const { items, page } = payload;

      state.items = items.sort(sortByJobName);
      state.page = page;
      state.isLoading = false;
      state.error = null;
    },
    getAllJobTypesSuccess(
      state,
      { payload }: PayloadAction<StateSlice<JobType>>
    ) {
      const { items } = payload;

      state.allItems = items;
    },
    getJobTypeSuccess(state, { payload: jobTypeItem }: PayloadAction<JobType>) {
      if (state.items != null) {
        state.items = state.items.filter(
          jobtype => jobtype.id !== jobTypeItem.id
        );
        state.items.push(jobTypeItem);
        state.items.sort(sortByJobName);
      }
      state.isLoading = false;
      state.error = null;
    },
    addJobTypeSuccess(
      state,
      { payload: addedJobType }: PayloadAction<JobType>
    ) {
      // We could check here if we already have a job type with the same id
      // in the store, but I'll leave that up to the API to decide
      //
      // We could also either push the new item into state or dispatch `fetchJobTypes`
      // after `addJobTypeSuccess` in the thunk to refresh the state
      if (state.items != null) {
        state.items.push(addedJobType);
        state.items.sort(sortByJobName);
      }

      state.isLoading = false;
      state.error = null;
    },
    editJobTypeSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    deleteJobTypeSuccess(
      state,
      { payload: deletedJobTypeId }: PayloadAction<string>
    ) {
      if (state.items != null) {
        state.items = state.items.filter(
          jobType => jobType.id !== deletedJobTypeId
        );
      }

      state.isLoading = false;
      state.error = null;
    }
  }
});

export const {
  requestStart,
  requestFail,
  setPagination: setJobTypesPagination,
  getJobTypesSuccess,
  getAllJobTypesSuccess,
  addJobTypeSuccess,
  editJobTypeSuccess,
  deleteJobTypeSuccess,
  getJobTypeSuccess
} = jobTypes.actions;

export default jobTypes.reducer;

export function fetchJobTypes(): AppThunk {
  return fetchThunk<StateSlice<JobType>>({
    domain: jobTypeDomain,
    requestStart,
    requestFail,
    requestSuccess: getJobTypesSuccess
  });
}

export function fetchAllJobTypes(): AppThunk {
  return fetchAllThunk(jobTypeDomain, {
    start: requestStart,
    success: getAllJobTypesSuccess,
    fail: requestFail
  });
}

export function fetchJobType(id: string): AppThunk {
  return fetchItemThunk<JobType>(
    jobTypeDomain,
    requestStart,
    requestFail,
    getJobTypeSuccess,
    id
  );
}

export function addJobType(
  item: FormValues,
  options?: Partial<AddItemThunkOptions<JobType>>
): AppThunk {
  return addItemThunk<JobType>({
    domain: jobTypeDomain,
    requestStart,
    requestFail,
    requestSuccess: addJobTypeSuccess,
    item,
    ...options
  });
}

export function editJobType(item: FormValues, itemId: string): AppThunk {
  return editItemThunk<JobType>({
    domain: jobTypeDomain,
    requestStart,
    requestFail,
    requestSuccess: editJobTypeSuccess,
    itemId,
    item
  });
}

export function deleteJobType(domainItem: DomainItem): AppThunk {
  return deleteItemThunk<JobType>({
    domain: jobTypeDomain,
    requestStart,
    requestFail,
    requestSuccess: deleteJobTypeSuccess,
    itemId: domainItem.id
  });
}
