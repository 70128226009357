import React, { useEffect } from 'react';
import {
  EditView,
  getInitialFieldValues,
  renderErrorOrLoading
} from 'components/Crud';
import useStyles from 'components/Crud/styles';
import { appointmentDomain } from 'models';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { mergeErrors } from 'utils/utils';
import { mapRequestDataToField } from 'components/Forms/utils/dataMap';
import Appointment, {
  OldAppointmentReasonToNewAppointmentReasonMap
} from 'models/Appointment';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useParams } from 'react-router-dom';
import { useAppointmentsData } from 'store/selectors';
import { engineerSlice, EngineersSelector } from './store';
import {
  validationSchema,
  appointmentFormFields,
  processFormFields
} from './formConfig';

function EditAppointmentView() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const flags = useFlags();
  const { id: appointmentId } = useParams<{ id: string }>();

  const processAppointmentData = mapRequestDataToField({
    engineer: (appointment: Appointment) => appointment.engineers,
    date: (appointment: Appointment) => appointment.slot.date,
    startTime: ({ slot: { date, startTime } }: Appointment) =>
      `${date}T${startTime}`,
    endTime: ({ slot: { date, endTime } }: Appointment) => `${date}T${endTime}`,
    reason: (appointment: Appointment) => appointment.reason,
    postcode: (appointment: Appointment) => appointment.address?.postcode,
    notes: (appointment: Appointment) =>
      appointment.notes
        .reduce((acc, { note }) => [...acc, note], [] as string[])
        .join('\n')
  });

  const engineerSelector = useSelector<RootState, EngineersSelector>(
    engineerSlice.selector
  );

  // TODO - temporary high page size values until FFT-612 is completed
  useEffect(() => {
    if (engineerSlice.actions.changePagination) {
      dispatch(
        engineerSlice.actions.changePagination({
          pageIndex: 0,
          pageSize: 10000
        })
      );
    }
  }, []);

  const editAppointmentFields = appointmentFormFields(
    'edit',
    engineerSelector.items,
    flags?.aegisEnableNewAppointmentAndAdjustmentReasons
  );

  const appointmentData = useAppointmentsData().find(
    appointment => appointment.id === appointmentId
  );
  const initialValues = getInitialFieldValues(
    editAppointmentFields,
    appointmentData,
    processAppointmentData
  );

  return (
    renderErrorOrLoading(
      engineerSelector.isLoading,
      'editEngineerSpinner',
      mergeErrors([engineerSelector.error]),
      classes
    ) || (
      <EditView
        domain={appointmentDomain}
        fields={editAppointmentFields}
        initialValues={{
          ...initialValues,
          reason:
            OldAppointmentReasonToNewAppointmentReasonMap[
              initialValues.reason as string
            ]
        }}
        validationSchema={validationSchema}
        presubmitCallback={processFormFields}
        processDomainData={processAppointmentData}
      />
    )
  );
}

export default EditAppointmentView;
