import {
  FieldInputValue,
  FormFieldType,
  InputFieldProps,
  yupSchemaToValidationFunc
} from 'components/Forms';
import * as Yup from 'yup';
import Capacity, { CapacitySlot } from 'models/Capacity';
import {
  Brand,
  CustomerAppointmentType,
  EVFormChannel,
  FuelType,
  PaymentType
} from 'models/Appointment';
import { DateTime } from 'luxon';
import {
  MPAN_LENGTH_ERROR_MESSAGE,
  MPAN_NUMBERS_ERROR_MESSAGE,
  MPAN_ZERO_START_ERROR_MESSAGE,
  MPRN_MAX_LENGTH_ERROR_MESSAGE,
  MPRN_MIN_LENGTH_ERROR_MESSAGE,
  MPRN_NUMBERS_ERROR_MESSAGE,
  MPRN_ZERO_START_ERROR_MESSAGE,
  REQUIRED_FIELD_ERROR_MESSAGE
} from 'constants/validation';
import { capitalize } from '@material-ui/core';

export const renderedSlotOptions = (slots: CapacitySlot[]) =>
  slots.map(slot => {
    const timeRange = `${slot.startTime}-${slot.endTime}`;
    return {
      value: timeRange,
      label: timeRange,
      key: timeRange
    } as FieldInputValue;
  });

export const disableDate = (
  date: DateTime,
  availableDates: string[]
): boolean => !availableDates.includes(date.toISODate());

export const minimalField = (
  id: string,
  label: string,
  inputType: FormFieldType = 'textfield'
) => {
  return {
    id,
    label,
    name: id,
    qaId: `addJob${capitalize(id)}`,
    inputType
  };
};

export const buildJobFormFields = (
  capacityData: Capacity[],
  selectedDate: DateTime,
  setSelectedDate: (date: DateTime) => void,
  evForm: boolean
): InputFieldProps[][] => {
  const dates = capacityData.reduce((newDates: string[], data: Capacity) => {
    return data.capacity.slots.length > 0 ? [...newDates, data.date] : newDates;
  }, []);

  const selectedCapacity = selectedDate
    ? capacityData.find(currentCapacity => {
        return currentCapacity.date === selectedDate.toISODate();
      })
    : null;

  const changeCurrentCapcity = (date: DateTime) => {
    setSelectedDate(date);
  };

  const accountDetailsFieldsSmart = [
    minimalField('accountNumber', 'Account number'),
    {
      ...minimalField('brand', 'Brand', 'select'),
      options: Object.values(Brand).map(selectedBrand => ({
        value: selectedBrand,
        label: selectedBrand,
        key: selectedBrand
      }))
    },
    {
      ...minimalField('paymentType', 'Payment type', 'select'),
      options: Object.values(PaymentType).map(type => ({
        value: type,
        label: type,
        key: type
      }))
    },
    minimalField('mpan', 'MPAN'),
    minimalField('mprn', 'MPRN'),
    minimalField('electricityMsn', 'Electricity MSN'),
    minimalField('gasMsn', 'Gas MSN'),
    minimalField('bookingNotes', 'Booking notes')
  ];

  const accountDetailsFieldsEV = [
    minimalField('zohoAccountId', 'Zoho account n.'),
    {
      ...minimalField('channel', 'Channel', 'select'),
      options: Object.entries(EVFormChannel).map(
        ([channelId, channelDisplayName]) => ({
          value: channelId,
          label: channelDisplayName,
          key: channelId
        })
      )
    },
    {
      id: 'jumptechURL',
      label: 'Jumptech URL',
      name: 'jumptechURL',
      qaId: `addJobJumptechURL`,
      inputType: 'textfield' as FormFieldType,
      conditional: {
        when: 'channel',
        isIn: ['OVO', 'INDRA', 'HYPERVOLT']
      }
    },
    minimalField('mpan', 'MPAN'),
    minimalField('bookingNotes', 'Booking notes')
  ];
  // We don't want to allow the creation of SMETS1 appointments https://ovotech.atlassian.net/browse/FTSC-1893
  const filteredValues = Object.values(CustomerAppointmentType).filter(
    appointmentType => appointmentType !== CustomerAppointmentType.SMETS1
  );

  return [
    [
      ...(evForm
        ? []
        : [
            {
              ...minimalField('appointmentType', 'Appointment type', 'select'),
              options: filteredValues.map(appointmentType => ({
                value: appointmentType,
                label: appointmentType,
                key: appointmentType
              }))
            },
            {
              ...minimalField('fuelType', 'Fuel type', 'select'),
              options: Object.values(FuelType).map(fuelType => ({
                value: fuelType,
                label: fuelType,
                key: fuelType
              }))
            }
          ]),
      {
        ...minimalField('date', 'Date', 'date'),
        shouldDisableDate: (date: DateTime) => disableDate(date, dates),
        handleChange: changeCurrentCapcity
      },
      {
        ...minimalField('timeSlot', 'Time slot', 'select'),
        options: selectedCapacity
          ? renderedSlotOptions(selectedCapacity?.capacity.slots)
          : []
      }
    ],
    [
      minimalField('firstName', 'First name'),
      minimalField('lastName', 'Last name'),
      minimalField('phoneNumber', 'Phone number'),
      minimalField('secondaryNumber', 'Secondary number'),
      minimalField('addressLine1', 'Address line 1'),
      minimalField('addressLine2', 'Address line 2'),
      minimalField('addressLine3', 'Address line 3'),
      minimalField('city', 'City')
    ],
    evForm ? accountDetailsFieldsEV : accountDetailsFieldsSmart
  ];
};

export enum VulnerabilitiesFieldNames {
  CustomerHasVulnerabilities = 'customerHasVulnerabilities',
  IsOver65 = 'isOver65',
  HasDisability = 'hasDisability',
  HasHearingDifficulties = 'hasHearingDifficulties',
  HasSpeechDifficulties = 'hasSpeechDifficulties',
  HasSmellDifficulties = 'hasSmellDifficulties',
  IsBlindOrPartiallySighted = 'isBlindOrPartiallySighted',
  HasSeriousIllness = 'hasSeriousIllness',
  SpeaksOtherLanguage = 'speaksOtherLanguage',
  IsReliantOnMedicalEquipment = 'isReliantOnMedicalEquipment',
  HasYoungChildren = 'hasYoungChildren',
  HasOtherVulnerability = 'hasOtherVulnerability',
  OtherVulnerability = 'other'
}

export const vulnerabilitiesField = {
  ...minimalField(
    VulnerabilitiesFieldNames.CustomerHasVulnerabilities,
    'Does this customer have any vulnerabilities?',
    'radio'
  ),
  options: [
    { value: 'true', label: 'Yes', key: 'true' },
    { value: 'false', label: 'No', key: 'false' }
  ]
};

export const otherVulnerabilityField = minimalField('other', 'Comments');

export const vulnerabilitiesLabelMap = {
  [VulnerabilitiesFieldNames.IsOver65]: 'Customer is over 65 years old',
  [VulnerabilitiesFieldNames.HasDisability]:
    'Customer has a physical disability',
  [VulnerabilitiesFieldNames.HasHearingDifficulties]:
    'Customer has hearing difficulties',
  [VulnerabilitiesFieldNames.HasSpeechDifficulties]:
    'Customer has speech difficulties',
  [VulnerabilitiesFieldNames.HasSmellDifficulties]:
    'Customer has a poor sense of smell',
  [VulnerabilitiesFieldNames.IsBlindOrPartiallySighted]:
    'Customer is blind or partially sighted',
  [VulnerabilitiesFieldNames.HasSeriousIllness]:
    'Customer has a serious illness',
  [VulnerabilitiesFieldNames.SpeaksOtherLanguage]:
    'Customer prefers to speak in a language other than English',
  [VulnerabilitiesFieldNames.IsReliantOnMedicalEquipment]:
    'Customer relies on medical equipment',
  [VulnerabilitiesFieldNames.HasYoungChildren]: 'Customer has young children',
  [VulnerabilitiesFieldNames.HasOtherVulnerability]: 'Other'
};

const vulnerabiltiesFields = {
  [VulnerabilitiesFieldNames.CustomerHasVulnerabilities]: Yup.string()
    .oneOf(['true', 'false'])
    .required(REQUIRED_FIELD_ERROR_MESSAGE),
  [VulnerabilitiesFieldNames.OtherVulnerability]: Yup.string().when(
    'hasOtherVulnerability',
    {
      is: true,
      then: Yup.string().required(REQUIRED_FIELD_ERROR_MESSAGE)
    }
  )
};

const customerDetailsFieldsValidation = Yup.object().shape({
  firstName: Yup.string().required(REQUIRED_FIELD_ERROR_MESSAGE),
  lastName: Yup.string().required(REQUIRED_FIELD_ERROR_MESSAGE),
  phoneNumber: Yup.number()
    .typeError('Must be a number')
    .required(REQUIRED_FIELD_ERROR_MESSAGE),
  secondaryNumber: Yup.number().typeError('Must be a number'),
  addressLine1: Yup.string().required(REQUIRED_FIELD_ERROR_MESSAGE),
  city: Yup.string().required(REQUIRED_FIELD_ERROR_MESSAGE)
});

const accountDetailsFieldsValidationSmart = Yup.object().shape({
  mpan: Yup.string()
    .transform(val => val.replace(/ /g, ''))
    .length(13, MPAN_LENGTH_ERROR_MESSAGE)
    .matches(/^[^0].*$/, MPAN_ZERO_START_ERROR_MESSAGE)
    .matches(/^\d+$/, MPAN_NUMBERS_ERROR_MESSAGE),
  mprn: Yup.string()
    .transform(val => val.replace(/ /g, ''))
    .min(6, MPRN_MIN_LENGTH_ERROR_MESSAGE)
    .max(10, MPRN_MAX_LENGTH_ERROR_MESSAGE)
    .matches(/^[^0].*$/, MPRN_ZERO_START_ERROR_MESSAGE)
    .matches(/^\d+$/, MPRN_NUMBERS_ERROR_MESSAGE),
  accountNumber: Yup.string().required(REQUIRED_FIELD_ERROR_MESSAGE),
  brand: Yup.string().required(REQUIRED_FIELD_ERROR_MESSAGE),
  paymentType: Yup.string().required(REQUIRED_FIELD_ERROR_MESSAGE)
});
export const jumptechUrlRegex = /^.*https?:\/\/ovo-drive\.jumptech\.co\.uk\/project\/(?<jumptechProjectId>[a-fA-F0-9-]+).*$/;
const accountDetailsFieldsValidationEV = Yup.object().shape({
  mpan: Yup.string()
    .transform(val => val.replace(/ /g, ''))
    .length(13, MPAN_LENGTH_ERROR_MESSAGE)
    .matches(/^[^0].*$/, MPAN_ZERO_START_ERROR_MESSAGE)
    .matches(/^\d+$/, MPAN_NUMBERS_ERROR_MESSAGE),
  channel: Yup.string().required(REQUIRED_FIELD_ERROR_MESSAGE),
  jumptechURL: Yup.string().when('channel', {
    is: (channel: any) => ['OVO', 'INDRA', 'HYPERVOLT'].includes(channel),
    then: Yup.string().matches(
      jumptechUrlRegex,
      'Please enter a valid Jumptech URL (https://ovo-drive.jumptech.co.uk/project/project-id)'
    )
  })
});

const appointmentDetailsFieldsValidationSmart = Yup.object().shape({
  date: Yup.string()
    .required(REQUIRED_FIELD_ERROR_MESSAGE)
    .nullable(),
  timeSlot: Yup.string().required(REQUIRED_FIELD_ERROR_MESSAGE),
  appointmentType: Yup.string().required(REQUIRED_FIELD_ERROR_MESSAGE),
  fuelType: Yup.string().required(REQUIRED_FIELD_ERROR_MESSAGE)
});

const appointmentDetailsFieldsValidationEV = Yup.object().shape({
  date: Yup.string().required(REQUIRED_FIELD_ERROR_MESSAGE),
  timeSlot: Yup.string().required(REQUIRED_FIELD_ERROR_MESSAGE)
});

const addJobFormFieldsValidationSmart = appointmentDetailsFieldsValidationSmart
  .concat(customerDetailsFieldsValidation)
  .concat(accountDetailsFieldsValidationSmart);

const addJobFormFieldsValidationEV = appointmentDetailsFieldsValidationEV
  .concat(customerDetailsFieldsValidation)
  .concat(accountDetailsFieldsValidationEV);

export const addJobFormValidation = (
  evForm: boolean,
  aegisEnableCustomerVulnerabilitiesOnCreateJobForm: boolean
) => {
  const vulnerabilitiesSchema = Yup.object()
    .shape({
      ...(aegisEnableCustomerVulnerabilitiesOnCreateJobForm
        ? vulnerabiltiesFields
        : {})
    })
    .test('vulnerabilitiesAreValid', function validateVulnerabilitiesTest({
      customerHasVulnerabilities,
      isOver65,
      hasDisability,
      hasHearingDifficulties,
      hasSpeechDifficulties,
      hasSmellDifficulties,
      isBlindOrPartiallySighted,
      hasSeriousIllness,
      speaksOtherLanguage,
      isReliantOnMedicalEquipment,
      hasYoungChildren,
      hasOtherVulnerability
    }) {
      if (!aegisEnableCustomerVulnerabilitiesOnCreateJobForm) {
        return true;
      }
      if (
        customerHasVulnerabilities === 'true' &&
        !isOver65 &&
        !hasDisability &&
        !hasHearingDifficulties &&
        !hasSpeechDifficulties &&
        !hasSmellDifficulties &&
        !isBlindOrPartiallySighted &&
        !hasSeriousIllness &&
        !speaksOtherLanguage &&
        !isReliantOnMedicalEquipment &&
        !hasYoungChildren &&
        !hasOtherVulnerability
      ) {
        return this.createError({
          message: 'At least one of these fields is required',
          path: 'vulnerabilitiesGroup'
        });
      }
      return true;
    });

  const validationSchema = evForm
    ? addJobFormFieldsValidationEV
    : addJobFormFieldsValidationSmart;

  return yupSchemaToValidationFunc(
    vulnerabilitiesSchema.concat(validationSchema)
  );
};
