import { filterById, filterByValue, getFilterQueryParams } from 'utils/utils';
import {
  AppliedFilter,
  Filter,
  FilterValue
} from 'components/FilterSearch/FilterSearch';
import { QueryParams } from 'store/utils';
import { useQueryStringState } from './use-query-string-state';

/**
 * This hook performs the logic required to use the filter search chips.
 * It also uses query string state, so any filters in the url are also applied.
 * As long as filters are applied, the hook will perform a search everytime the filters change
 * @param {boolean} filters - The available filters for the search
 * @param {(params: QueryParams) => void} onSearch - Override the default action dispatched by `searchWithFilters`
 * @param {(filterValues: FilterValue[], searchWithFilters: () => void) => void} onLoad - Override the default `useEffect`
 */
export default function useFilterSearchChips(
  filters: Filter[],
  onSearch: (params: QueryParams) => void,
  onLoad: (filterValues: FilterValue[], searchWithFilters: () => void) => void
) {
  const [filterValues, setFilterValues] = useQueryStringState<FilterValue[]>(
    []
  );

  const appliedFilters = filterValues
    .map(({ label, value, id }) => {
      const filter = filters.find(filterById(id));
      return filter
        ? {
            filter,
            label,
            value: value || ''
          }
        : null;
    })
    .filter(filterByValue) as AppliedFilter[];

  const params = getFilterQueryParams(appliedFilters);

  const searchWithFilters = () => {
    onSearch(params);
  };

  onLoad(filterValues, searchWithFilters);

  const onFilterValueInput = (id: string, value: string, label?: string) => {
    if (value) {
      const newFilterValues: FilterValue[] = [
        ...filterValues.filter(f => f.id !== id),
        { id, value, label }
      ];
      setFilterValues(newFilterValues);
    }
  };

  const onFilterRemove = (engineerFilter: Filter) => {
    setFilterValues(filterValues.filter(f => f.id !== engineerFilter.id));
  };

  return {
    appliedFilters,
    filterValues,
    onFilterValueInput,
    onFilterRemove,
    params
  };
}
