import React, { memo } from 'react';
import { TextField, InputLabel, TextFieldProps } from '@material-ui/core';
import { FieldMetaState } from 'react-final-form';
import { formStyles } from './styles';
import { InputFieldProps } from './utils';
import InputFieldWrapper from './InputFieldWrapper';

type InputProps = TextFieldProps & {
  field: InputFieldProps;
  meta: FieldMetaState<string | number>;
  wrapField?: boolean;
};

const Input = ({ field, meta, wrapField = true, ...props }: InputProps) => {
  const classes = formStyles();

  const helperText = meta.touched && meta.error ? meta.error : field.helperText;
  const fieldId = field.id || field.name;

  if (wrapField) {
    return (
      <InputFieldWrapper>
        <InputLabel htmlFor={fieldId}>{field.label}</InputLabel>
        <TextField
          size="small"
          error={Boolean(meta.touched && meta.error)}
          {...props}
          name={field.name}
          id={fieldId}
          data-qa-id={field.qaId}
          variant="outlined"
          fullWidth
          InputLabelProps={{ shrink: false }}
          data-testid={field.qaId}
          className={field.className || classes.field}
          helperText={helperText}
        />
      </InputFieldWrapper>
    );
  }
  return (
    <>
      <InputLabel htmlFor={fieldId}>{field.label}</InputLabel>
      <TextField
        size="small"
        error={Boolean(meta.touched && meta.error)}
        {...props}
        name={field.name}
        id={fieldId}
        data-qa-id={field.qaId}
        variant="outlined"
        fullWidth
        InputLabelProps={{ shrink: false }}
        data-testid={field.qaId}
        className={field.className || classes.field}
        helperText={helperText}
      />
    </>
  );
};

export default memo(Input);
