import http from 'api/http';
import { engineerSummaryDomain } from 'models';
import { EngineerSummary } from 'models/Engineers';

export async function fetchEngineerSummary(
  engineerId: string,
  startDate: string,
  endDate: string
) {
  return http.post<EngineerSummary[]>(engineerSummaryDomain.apiPath, {
    startDate,
    endDate,
    engineers: [engineerId],
    patches: []
  });
}
