import React, { memo, ReactElement } from 'react';

import { makeStyles, Theme } from '@material-ui/core';
import { getTimeDiff, timeToPixels } from 'components/Schedule/scheduleHelpers';
import {
  scheduleStartTime,
  engineerEndTime,
  engineerStartTime
} from 'constants/schedule';

interface Slot {
  start: number;
  end: number;
  jobs: React.ReactNode[];
}

export enum UnassignedJobsLayout {
  ONE_ROW = 'ONE_ROW',
  TWO_ROWS = 'TWO_ROWS'
}

const dayDuration = getTimeDiff(engineerStartTime, engineerEndTime);
const slotDuration = dayDuration / 2.5;
const slotOffset = slotDuration / 2;

const useStyles = makeStyles<Theme, { layout: UnassignedJobsLayout }>(() => ({
  slotsContainer: {
    marginLeft: () =>
      timeToPixels(getTimeDiff(scheduleStartTime, engineerStartTime)),
    display: 'grid',
    gridTemplateColumns: `repeat(5, ${timeToPixels(slotOffset)}px)`
  },
  slot: {
    textAlign: ({ layout }) =>
      layout === UnassignedJobsLayout.TWO_ROWS ? 'center' : 'start',
    '& > *': {
      zIndex: 0,
      display: 'inline-flex',
      verticalAlign: 'middle'
    }
  }
}));

interface UnassignedJobsContainerProps {
  children: ReactElement | ReactElement[];
  layout: UnassignedJobsLayout;
}

// Maps the provided children elements to one of four predefined slots
const mapJobsToSlots = (elements: React.ReactNode): Slot[] => {
  const slots: Slot[] = [...Array(4)].map((value, index) => ({
    start: slotOffset * index,
    end: slotOffset * index + slotDuration,
    jobs: []
  }));

  React.Children.map(elements, (element: React.ReactNode) => {
    if (React.isValidElement(element)) {
      const slot = element.props['data-slot'];

      const centerTime =
        getTimeDiff(engineerStartTime, slot.startTime) +
        (getTimeDiff(engineerStartTime, slot.endTime) -
          getTimeDiff(engineerStartTime, slot.startTime)) /
          2;

      let slotFound: Slot = slots[0];
      slots.forEach(item => {
        if (centerTime > item.start && centerTime < item.end) {
          slotFound = item;
        }
      });

      slotFound.jobs.push(element);
    }
  });
  return slots;
};

const getCols = (layout: UnassignedJobsLayout) => {
  if (layout === UnassignedJobsLayout.TWO_ROWS) {
    return [
      [1, 1],
      [2, 2],
      [1, 3],
      [2, 4]
    ];
  }
  return [
    [1, 1],
    [1, 2],
    [1, 3],
    [1, 4]
  ];
};

const UnassignedJobsContainer: React.FunctionComponent<UnassignedJobsContainerProps> = ({
  children,
  layout
}) => {
  const classes = useStyles({ layout });

  const colsOrder = getCols(layout);

  const slots = mapJobsToSlots(children);

  const rowOffset = (index: number) => {
    if (index === 1 && !slots[0].jobs.length && !slots[2].jobs.length) {
      return -1;
    }
    if (index === 3 && !slots[2].jobs.length && !slots[3].jobs.length) {
      return -1;
    }
    return 0;
  };

  return (
    <div className={classes.slotsContainer}>
      {slots.map((slot, index) => {
        return (
          <div
            className={classes.slot}
            style={{
              gridColumnStart: colsOrder[index][1],
              gridColumnEnd: colsOrder[index][1] + 2,
              gridRowStart: colsOrder[index][0] + rowOffset(index),
              gridRowEnd: colsOrder[index][0] + 1 + rowOffset(index)
            }}
            key={slot.start}
          >
            {slot.jobs}
          </div>
        );
      })}
    </div>
  );
};

export default memo(UnassignedJobsContainer);
