import React, { memo } from 'react';
import {
  makeStyles,
  createTheme,
  ThemeProvider,
  Theme,
  createStyles,
  ThemeOptions
} from '@material-ui/core';
import defaultTheme from 'styles/defaultTheme';
import Typography from 'components/Typography';
import { getOpacity, MapElementType } from 'utils/mapElements';

export enum EngineerIconSize {
  NORMAL = 'NORMAL',
  SMALL = 'SMALL',
  SMALLEST = 'SMALLEST'
}

interface EngineerIconProps {
  firstName: string;
  lastName: string;
  size?: EngineerIconSize;
  border?: boolean;
  engineerId?: string;
  selectedEngineer?: string;
}

const useStyles = makeStyles<
  Theme,
  {
    size: EngineerIconSize;
    border: boolean;
    opacity: number;
  }
>(theme =>
  createStyles({
    engineerIcon: {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.common.black,
      margin: 0,
      borderRadius: '50%',
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
      boxSizing: 'content-box',
      opacity: ({ opacity }) => opacity,
      border: ({ border }) =>
        border ? `1px solid ${theme.palette.common.white}` : ''
    },
    normal: {
      height: 32,
      width: 32
    },
    small: {
      height: 24,
      width: 24
    },
    smallest: {
      height: 20,

      width: 20
    },
    engineerInitials: {
      color: theme.palette.common.white,
      fontSize: ({ size }) => (size === EngineerIconSize.NORMAL ? 14 : 9)
    }
  })
);

const engineerIconThemeOptions: ThemeOptions = {
  ...defaultTheme,
  typography: {
    ...defaultTheme.typography,
    body1: {
      fontSize: 14,
      fontWeight: 600
    }
  }
};

const engineerIconTheme = createTheme(engineerIconThemeOptions);

function EngineerIcon({
  firstName,
  lastName,
  size = EngineerIconSize.NORMAL,
  border = false,
  selectedEngineer,
  engineerId
}: EngineerIconProps) {
  const opacity = getOpacity(MapElementType.HOME, selectedEngineer, engineerId);
  const classes = useStyles({
    size,
    border,
    opacity
  });

  return (
    <ThemeProvider theme={engineerIconTheme}>
      <div
        className={`${classes.engineerIcon} ${classes[size.toLowerCase()]}`}
        data-testid="engineerIcon"
      >
        <Typography
          variant="micro"
          className={classes.engineerInitials}
          data-testid="engineerInitials"
        >
          {firstName[0]?.toUpperCase()}
          {lastName[0]?.toUpperCase()}
        </Typography>
      </div>
    </ThemeProvider>
  );
}

export default memo(EngineerIcon);
