import React from 'react';
import { Field, FieldProps } from 'react-final-form';
import {
  Date,
  Time,
  Select,
  Input,
  Autocomplete,
  Checkbox
} from 'components/Forms';
import { Radio } from '@material-ui/core';
import { DateTime } from 'luxon';
import { InputFieldProps, FormFieldType } from './utils';

interface FormFieldProps extends Partial<FieldProps<any, any>> {
  field: InputFieldProps;
  className?: string;
}

const dateTimeValidationFunc = (
  value: any,
  inputType: FormFieldType
): string | undefined => {
  if (DateTime.isDateTime(value) && !value.isValid) {
    return `This ${inputType} entry is invalid, please fix.`;
  }
  return undefined;
};

const FormField = ({ field, ...props }: FormFieldProps) => {
  if (!field.inputType) {
    return null;
  }

  return (
    <Field<string>
      name={field.name}
      key={field.qaId}
      type={
        ['checkbox', 'radio'].includes(field.inputType)
          ? field.inputType
          : undefined
      }
      validate={value =>
        dateTimeValidationFunc(value, field.inputType as FormFieldType)
      }
      data-testid={field.qaId}
      {...props}
    >
      {({ input, meta }) => {
        if (field.inputType === 'select') {
          return <Select field={field} {...input} meta={meta} />;
        }

        if (field.inputType === 'multiselect' && field.optionRenderer) {
          return (
            <Autocomplete
              multiple
              optionRenderer={field.optionRenderer}
              field={field}
              input={input}
              meta={meta}
            />
          );
        }

        if (field.inputType === 'date') {
          return <Date field={field} {...input} meta={meta} />;
        }

        if (field.inputType === 'time') {
          return <Time field={field} {...input} meta={meta} />;
        }

        if (field.inputType === 'checkbox') {
          return <Checkbox field={field} {...input} meta={meta} />;
        }

        if (field.inputType === 'textarea') {
          return (
            <Input field={field} {...input} meta={meta} multiline minRows={5} />
          );
        }

        if (field.inputType === 'radio') {
          return <Radio {...input} />;
        }

        return <Input field={field} {...input} meta={meta} {...props} />;
      }}
    </Field>
  );
};

export default FormField;
