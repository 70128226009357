/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'store';

export interface ReadySlice {
  key: string;
  isReady: boolean;
}

export type ReadyState = {
  [key: string]: boolean;
};

export interface AppState {
  ready: ReadyState;
}

export const initialState: AppState = {
  ready: {
    keycloak: false
  }
};

const app = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setReadiness(state, { payload }: PayloadAction<ReadySlice>) {
      const { key, isReady } = payload;
      state.ready[key] = isReady;
    }
  }
});

export const { setReadiness } = app.actions;

export default app.reducer;

export function updateReadiness(key: string, isReady: boolean): AppThunk {
  return async dispatch => {
    dispatch(setReadiness({ key, isReady }));
  };
}
