import {
  InputFieldProps,
  mapSkillsToAutocompleteValues,
  SkillOption
} from 'components/Forms';
import { AdjustmentType, DayOfWeek } from 'models/Adjustment';
import AdjustmentGroup from 'models/AdjustmentGroup';
import Skill from 'models/Skills';
import { getAdjustmentChange, getAdjustmentForDay } from 'utils/adjustments';
import { skillsReasonOptions } from 'views/Engineers/Adjustments/AdjustmentFormConfig';
import * as Yup from 'yup';
import {
  adjustmentCommonFieldsInitialValues,
  adjustmentCommonFieldsValidationSchema,
  adjustmentWeekdayKeys,
  getAdjustmentCommonFields
} from './adjustmentFormCommonConfig';

export const getDayFieldName = (day: string) => `skillsChange${day}`;

const getSkillsFieldForWeekday = (day: string): InputFieldProps => {
  const fieldName = getDayFieldName(day);
  return {
    id: fieldName,
    required: true,
    name: fieldName,
    qaId: fieldName,
    inputType: 'multiselect',
    optionRenderer: SkillOption,
    options: [],
    preprocess: mapSkillsToAutocompleteValues
  };
};

export const skillsFormCommonFields = getAdjustmentCommonFields(
  skillsReasonOptions()
);

export const skillsFormWeekdayFields = Object.fromEntries(
  adjustmentWeekdayKeys.map(day => [day, getSkillsFieldForWeekday(day)])
);

export const skillsFormValidationSchema = Yup.object().shape(
  adjustmentCommonFieldsValidationSchema
);

const getWeekdaySkillsFromGroup = (
  day: DayOfWeek,
  adjustmentGroup: AdjustmentGroup
) => {
  const adjustment = getAdjustmentForDay(day, adjustmentGroup.adjustments);

  return adjustment
    ? getAdjustmentChange(adjustment, AdjustmentType.Skills)?.change
    : undefined;
};

export const skillsFormWeekdaysInitialValues = (
  adjustmentGroup: AdjustmentGroup | undefined
) => {
  const fieldNameSkillsPairs = adjustmentWeekdayKeys.map(day => {
    // TODO Fix weekday types: FTSC-1391
    const adjustmentGroupSkills = adjustmentGroup
      ? getWeekdaySkillsFromGroup(
          day.toUpperCase() as DayOfWeek,
          adjustmentGroup
        )
      : null;

    const adjustmentGroupSkillsToAutocomplete = (adjustmentGroupSkills as Skill[])?.map(
      skill => ({
        label: skill.name,
        value: skill.id,
        category: skill.category
      })
    );
    return [getDayFieldName(day), adjustmentGroupSkillsToAutocomplete || []];
  });

  return Object.fromEntries(fieldNameSkillsPairs);
};

export const skillsFormInitialValues = (adjustmentGroup?: AdjustmentGroup) => {
  const skillsDaysInitialValues = skillsFormWeekdaysInitialValues(
    adjustmentGroup
  );

  return {
    ...adjustmentCommonFieldsInitialValues(adjustmentGroup?.adjustments[0]),
    ...skillsDaysInitialValues
  };
};
