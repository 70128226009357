import React, { useEffect, useState, useRef } from 'react';

import { makeStyles, Typography } from '@material-ui/core';
import { JobDetailsResponse } from 'models/JobDetails';
import { Form, FormValues } from 'components/Forms';
import { FormApiWithRestart } from 'components/Forms/Form';
import { useDispatch } from 'react-redux';
import { getDomainSlice } from 'store';
import { appointmentDomain } from 'models';
import { formSubmitHandler } from 'components/Crud/utils';
import Modal from 'components/Modal';
import { SecondaryButton, SubmitButton } from 'components/Forms/Buttons';
import { patchAppointment } from 'store/slices/appointments';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  editJobFormFields,
  editJobFormValidation,
  processEditJobFormFields
} from './EditJobFormConfig';
import { dashIfFalsy, formatAddressSingleLine } from '../jobsHelper';

interface EditJobProps {
  open: boolean;
  closeModal: () => void;
  reloadJobDetails: () => void;
  job: JobDetailsResponse;
}

const useStyles = makeStyles(theme => ({
  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2)
  },
  infoField: {
    height: 36,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  infoFieldAddress: {
    alignItems: 'initial',
    height: 'fit-content',
    borderBottom: 'none',
    paddingTop: 7
  },
  infoLabel: {
    color: theme.palette.common.black
  }
}));

export default function EditJobModal({
  open,
  closeModal,
  reloadJobDetails,
  job
}: EditJobProps) {
  const classes = useStyles();
  const dispatch = useDispatch();

  // This setup is needed in order to catch the event after a successful editing
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);
  const formRef = useRef<FormApiWithRestart>();
  const flags = useFlags();

  useEffect(() => {
    if (success && !isSubmitting) {
      reloadJobDetails();
      setTimeout(() => {
        closeModal();
      }, 1000);
    }
  }, [success, isSubmitting]);

  const { actions } = getDomainSlice(appointmentDomain);

  const apiCallback = (data: FormValues) =>
    dispatch(
      flags.aegisEnablePatchAppointmentNotesAndSlot
        ? patchAppointment(data, job.id)
        : actions.edit(data, job.id)
    );

  const resetFormOnCancel = (): void => {
    formRef.current?.restart();
    closeModal();
  };

  const initialValues: FormValues = {
    slot: `${job.slot.startTime}-${job.slot.endTime}`,
    notes: job.notes.map(currentNote => currentNote.note).join('\n')
  };

  return (
    <Modal
      title="Edit Job"
      testId="editJobModal"
      open={open}
      onClose={closeModal}
      modalActions={
        <>
          <SecondaryButton
            onClick={() => resetFormOnCancel()}
            testId="editJobFormCancel"
          >
            Cancel
          </SecondaryButton>
          <SubmitButton
            onClick={() => formRef.current?.submit()}
            testId="editJobFormSave"
          >
            Save
          </SubmitButton>
        </>
      }
    >
      <div className={classes.infoContainer}>
        <div className={classes.infoField}>
          <Typography variant="body1" className={classes.infoLabel}>
            Name
          </Typography>
          <Typography variant="body1" data-testid="editJobName">
            {dashIfFalsy(
              job.contactDetails &&
                `${job.contactDetails?.firstName} ${job.contactDetails?.lastName}`
            )}
          </Typography>
        </div>
        <div className={classes.infoField}>
          <Typography variant="body1" className={classes.infoLabel}>
            Phone number
          </Typography>
          <Typography variant="body1" data-testid="editJobPhoneNumber">
            {dashIfFalsy(job.contactDetails?.primaryPhoneNumber)}
          </Typography>
        </div>
        <div className={classes.infoField}>
          <Typography variant="body1" className={classes.infoLabel}>
            Secondary number
          </Typography>
          <Typography variant="body1" data-testid="editJobSecondaryNumber">
            {dashIfFalsy(job.contactDetails?.secondaryPhoneNumber)}
          </Typography>
        </div>
        <div className={classes.infoFieldAddress}>
          <Typography variant="body1" className={classes.infoLabel}>
            Address
          </Typography>
          <Typography variant="body1" data-testid="editJobAddress">
            {formatAddressSingleLine(job?.address)}
          </Typography>
        </div>
      </div>
      <Form
        validationFunc={editJobFormValidation}
        fields={editJobFormFields}
        onSubmit={formSubmitHandler(apiCallback, processEditJobFormFields(job))}
        initialValues={initialValues}
        goBackToPreviousPage={false}
      >
        {({ form, submitting, submitSucceeded, submitErrors }) => {
          setSuccess(!submitErrors && submitSucceeded);
          setIsSubmitting(submitting);

          formRef.current = form;
        }}
      </Form>
    </Modal>
  );
}
