import React, { useState, useEffect } from 'react';
import { Status } from 'components/Schedule/EngineerInfo/PublishStatusIcon';
import { updateEngineerAssignments } from 'components/Schedule/scheduleHelpers';
import TimelineHeader, {
  defaultSlotHighlight
} from 'components/Schedule/TimelineHeader';
import { scheduleStartTime, scheduleEndTime } from 'constants/schedule';
import { scheduleDomain } from 'models';
import { AppointmentSlot } from 'models/Appointment';
import {
  JobDetailsFields,
  AssignJobToEngineerPayload,
  ScheduleAssignmentEvent,
  Schedule
} from 'models/Schedule';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useDispatch } from 'react-redux';
import { getDomainSlice } from 'store';
import { setSnackbar, SnackbarStatus } from 'store/slices/snackbar';
import { makeStyles, Theme, Grid } from '@material-ui/core';
import CurrentTimeIndicator from 'components/Schedule/CurrentTimeIndicator';
import EngineerList from 'components/Schedule/EngineerList/EngineerList';
import { CustomDragLayer } from 'components/Schedule/JobCard/CustomDragLayer';
import PublishFooter from 'components/Schedule/PublishFooter/PublishFooter';
import UnassignedJobs, {
  spinnerSize as unassignedJobsSpinnerHeight
} from 'components/Schedule/UnassignedJobs/UnassignedJobs';
import { DndProvider } from 'react-dnd';
import { getHydratedJobDetails } from 'components/Schedule/Map/scheduleMapHelpers';
import MapWrapper from 'components/Schedule/Map/MapWrapper';
import swimlane from 'assets/images/swimlane.gif';
import { DateTime } from 'luxon';
import { editSchedule } from 'store/slices/schedule';

export const timelineStart = 177;
export const timelineEnd = 597;

const useStyles = makeStyles(
  (theme: Theme) => ({
    container: {
      height: `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`,
      flexWrap: 'nowrap'
    },
    scrollable: {
      scrollbarWidth: 'none',
      '-ms-overflow-style': 'none',
      '&::-webkit-scrollbar': {
        display: 'none'
      }
    },
    scheduleCardContainer: {
      height: '100%',
      minWidth: 651,
      padding: theme.spacing(3, 1.5, 0),
      width: 651,
      display: 'flex',
      flexDirection: 'column',
      zIndex: 1,
      boxShadow:
        '0px 2px 4px rgba(0, 0, 0, 0.14), 0px 3px 4px rgba(0, 0, 0, 0.12), 0px 1px 5px rgba(0, 0, 0, 0.2)'
    },
    scheduleCard: {
      marginTop: theme.mixins.toolbar.minHeight,
      position: 'relative',
      height: `calc(100% - ${theme.mixins.toolbar.minHeight}px)`,
      backgroundImage: `url(${swimlane})`,
      backgroundRepeat: 'repeat-y',
      backgroundPositionX: timelineStart,
      backgroundColor: theme.palette.common.white
    },
    cardContent: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      padding: 0
    },
    unassignedCardsContainer: {
      maxHeight: '35vh',
      zIndex: 1,
      minHeight: unassignedJobsSpinnerHeight + theme.spacing(4),
      borderRadius: theme.spacing(0.5),
      margin: theme.spacing(1.5, 0, 1.5),
      overflowX: 'auto',
      background: theme.palette.schedule.timeline.unassignedRow.background,
      '&::-webkit-scrollbar': {
        display: 'none'
      }
    },
    assignedCardsContainer: {
      flexGrow: 1,
      overflowX: 'auto',
      '&::-webkit-scrollbar': {
        display: 'none'
      }
    },
    separator: {
      background: theme.palette.common.white,
      height: 1,
      border: 'none',
      margin: theme.spacing(0, 1)
    },
    timelineBottom: {
      paddingTop: 0
    }
  }),
  { name: 'ScheduleView' }
);

export type OnAssignmentAddCallback = (
  engineerUpdate: AssignJobToEngineerPayload
) => void;

interface ScheduleViewProps {
  scheduleData: Schedule;
  areas: string[];
  date: string;
}

const defaultJobCardDetails = {
  id: '',
  isOpen: false,
  appointmentType: '',
  postcode: '',
  timeSlot: '',
  atRisk: false,
  jobTypeId: ''
};

export function ScheduleView(props: ScheduleViewProps) {
  const { scheduleData, areas, date } = props;

  const classes = useStyles();
  const { actions } = getDomainSlice(scheduleDomain);
  const dispatch = useDispatch();

  const [selectedTimeSlot, setSelectedTimeSlot] = useState<AppointmentSlot>(
    defaultSlotHighlight
  );
  const [selectedEngineer, setSelectedEngineer] = useState<string | undefined>(
    undefined
  );
  const [selectedUnassigned, setSelectedUnassigned] = useState<string>('');
  const [selectedJobCardDetails, setSelectedJobCardDetails] = useState<
    JobDetailsFields
  >(defaultJobCardDetails);
  const [schedulesCount, setSchedulesCount] = useState<{
    unpublishedCount: number;
    publishingCount: number;
  }>({
    unpublishedCount: 0,
    publishingCount: 0
  });

  const availableEngineers = scheduleData.schedules.filter(
    engineer => engineer.unavailableReason === null
  );

  const unavailableEngineers = scheduleData.schedules.filter(
    engineer => engineer.unavailableReason !== null
  );

  const unpublishedEngineerScheduleIds = availableEngineers.reduce(
    (acc: string[], engineerSchedule) => {
      if (engineerSchedule.status === Status.UNPUBLISHED) {
        return [...acc, engineerSchedule.engineer.id];
      }
      return acc;
    },
    []
  );

  const publishEngineerSchedule = async (engineerIds: string[]) => {
    if (actions.custom) {
      await dispatch(actions.custom.publish(engineerIds, date));
      dispatch(actions.get.list(date, areas, true));
    }
  };

  const onAssignmentAdd: OnAssignmentAddCallback = (
    assignEngineerPayload: AssignJobToEngineerPayload
  ) => dispatch(editSchedule(assignEngineerPayload));

  const allJobsInSchedules = [
    ...scheduleData.unassignedAppointments,
    ...scheduleData.schedules.flatMap(schedule => schedule.assignments)
  ];

  const updateJobCard = () => {
    const selectedJob = allJobsInSchedules.find(
      job => job.appointment && job.appointment?.id == selectedJobCardDetails.id
    );

    if (selectedJob) {
      setSelectedJobCardDetails({
        ...selectedJobCardDetails,
        ...getHydratedJobDetails(selectedJob)
      });
    }
  };

  useEffect(() => {
    if (selectedJobCardDetails.id) {
      updateJobCard();
    }
    setSchedulesCount({
      publishingCount: unpublishedEngineerScheduleIds.length,
      unpublishedCount: unpublishedEngineerScheduleIds.length
    });
  }, [scheduleData]);

  const publishAllSchedules = async () => {
    if (actions.custom) {
      try {
        await dispatch(
          actions.custom.publish(unpublishedEngineerScheduleIds, date)
        );
        setSchedulesCount({
          ...schedulesCount,
          publishingCount: 0
        });
      } catch (error) {
        dispatch(
          setSnackbar({
            message: error.message,
            show: true,
            status: SnackbarStatus.Error
          })
        );
      }
      dispatch(actions.get.list(date, areas, true));
    }
  };

  const setTimeSlot = (job: JobDetailsFields) => {
    setSelectedTimeSlot(
      allJobsInSchedules.find(
        assignment =>
          assignment.appointment?.id && assignment.appointment?.id === job.id
      )?.appointment.slot || defaultSlotHighlight
    );
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <CustomDragLayer />
      <Grid container className={classes.container}>
        <Grid item>
          <div
            className={classes.scheduleCardContainer}
            data-testid="scheduleContainer"
          >
            <div className={classes.scheduleCard} data-testid="scheduleCard">
              <div
                className={`${classes.scrollable} ${classes.cardContent}`}
                data-testid="scheduleCardContent"
              >
                {DateTime.now().toISODate() === scheduleData.date && (
                  <CurrentTimeIndicator />
                )}
                <TimelineHeader
                  startTime={scheduleStartTime}
                  endTime={scheduleEndTime}
                  slot={selectedTimeSlot}
                />
                <div
                  data-testid="unassignedCardsContainer"
                  className={classes.unassignedCardsContainer}
                >
                  <UnassignedJobs
                    jobs={scheduleData?.unassignedAppointments}
                    onChange={(event: ScheduleAssignmentEvent) =>
                      updateEngineerAssignments(event, onAssignmentAdd)
                    }
                    onJobCardSelect={setSelectedTimeSlot}
                    onUnassignedHover={(
                      unassignedJobId: string,
                      newSelectedRoute?: string
                    ) => {
                      setSelectedUnassigned(unassignedJobId);
                      setSelectedEngineer(newSelectedRoute);
                    }}
                    handleJobCardOnClick={setSelectedJobCardDetails}
                    selectedJobCardDetails={selectedJobCardDetails}
                  />
                  {!unavailableEngineers.length || (
                    <hr className={classes.separator} />
                  )}
                  <EngineerList
                    engineersSchedules={unavailableEngineers}
                    onAssignmentAdd={onAssignmentAdd}
                    onDragHover={setSelectedEngineer}
                    onJobCardSelect={setSelectedTimeSlot}
                    handleJobCardOnClick={setSelectedJobCardDetails}
                    className={classes.scrollable}
                    selectedJobCardDetails={selectedJobCardDetails}
                  />
                </div>
                <div
                  data-testid="assignedCardsContainer"
                  className={`${classes.scrollable} ${classes.assignedCardsContainer}`}
                >
                  <EngineerList
                    engineersSchedules={availableEngineers}
                    onAssignmentAdd={onAssignmentAdd}
                    onDragHover={setSelectedEngineer}
                    onJobCardSelect={setSelectedTimeSlot}
                    onPublish={engineerId => {
                      publishEngineerSchedule([engineerId]);
                    }}
                    handleJobCardOnClick={setSelectedJobCardDetails}
                    className={classes.scrollable}
                    selectedJobCardDetails={selectedJobCardDetails}
                    selectedEngineer={selectedEngineer}
                  />
                </div>
                <TimelineHeader
                  verticalAlign="top"
                  startTime={scheduleStartTime}
                  endTime={scheduleEndTime}
                  slot={selectedTimeSlot}
                  className={classes.timelineBottom}
                />
                <PublishFooter
                  unpublishedCount={schedulesCount.unpublishedCount}
                  publishingCount={schedulesCount.publishingCount}
                  onPublish={publishAllSchedules}
                />
              </div>
            </div>
          </div>
        </Grid>
        <MapWrapper
          engineersSchedules={scheduleData.schedules}
          unassignedAppointments={scheduleData.unassignedAppointments}
          selectedUnassigned={selectedUnassigned}
          selectedEngineer={selectedEngineer}
          handleSelectEngineer={setSelectedEngineer}
          handleJobCardOnClick={job => {
            setSelectedJobCardDetails(job);
            setTimeSlot(job);
          }}
          selectedJobCardDetails={selectedJobCardDetails}
          requestFreshSchedules={() => {
            dispatch(actions.get.list(date, areas, true));
          }}
        />
      </Grid>
    </DndProvider>
  );
}
