import React from 'react';

import Button from 'components/Button';
import Modal, { ModalProps } from 'components/Modal';
import Typography from 'components/Typography';

interface DeleteConfirmationModalProps extends ModalProps {
  onDelete: () => void;
  onCancel: () => void;
}

export default function DeleteConfirmationModal({
  onDelete,
  onCancel,
  testId,
  children,
  ...props
}: DeleteConfirmationModalProps) {
  return (
    <Modal
      testId={testId}
      modalActions={
        <>
          <Button
            color="secondary"
            size="small"
            onClick={onCancel}
            data-testid={`${testId}CancelButton`}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            size="small"
            onClick={onDelete}
            data-testid={`${testId}DeleteButton`}
            isErrorButton
          >
            Delete
          </Button>
        </>
      }
      {...props}
    >
      <Typography
        data-testid={`${testId}Text`}
        style={{ whiteSpace: 'pre-wrap' }}
      >
        {children}
      </Typography>
    </Modal>
  );
}
