import React, { useEffect } from 'react';
import { EditView, renderErrorOrLoading } from 'components/Crud';
import useStyles from 'components/Crud/styles';
import { engineerDomain } from 'models';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { mergeErrors } from 'utils/utils';
import { DomainItem } from 'models/Domain';
import { mapResponseDataToField } from 'components/Forms/utils/dataMap';
import Engineer from 'models/Engineers';
import { engineerFormFields, validationSchema } from './FormConfig';
import { isAnyLoading, processFormFields } from './utils';
import {
  skillsSlice,
  patchesSlice,
  shiftPatternsSlice,
  teamsSlice,
  SkillsSelector,
  PatchesSelector,
  ShiftPatternsSelector,
  TeamsSelector
} from './store';

function EditEngineerView() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const skillsSelector = useSelector<RootState, SkillsSelector>(
    skillsSlice.selector
  );
  const patchesSelector = useSelector<RootState, PatchesSelector>(
    patchesSlice.selector
  );
  const shiftPatternsSelector = useSelector<RootState, ShiftPatternsSelector>(
    shiftPatternsSlice.selector
  );
  const teamsSelector = useSelector<RootState, TeamsSelector>(
    teamsSlice.selector
  );
  const isLoading = isAnyLoading(
    skillsSelector,
    patchesSelector,
    shiftPatternsSelector,
    teamsSelector
  );

  const processEngineerData = (item: DomainItem) => {
    return mapResponseDataToField(
      {
        team: (engineer: Engineer) => (engineer.team ? engineer.team.id : ''),
        defaultPatch: (engineer: Engineer) => engineer.defaultPatch.id,
        defaultShiftPattern: (engineer: Engineer) =>
          engineer.defaultShiftPattern.id,
        vehicleType: (engineer: Engineer) => engineer.vehicleType,
        skills: (engineer: Engineer) => engineer.skills
      },
      item as Engineer
    );
  };

  // TODO - temporary high page size values until FFT-612 is completed
  useEffect(() => {
    if (skillsSlice.actions.changePagination) {
      dispatch(
        skillsSlice.actions.changePagination({ pageIndex: 0, pageSize: 10000 })
      );
    }

    if (patchesSlice.actions.changePagination) {
      dispatch(
        patchesSlice.actions.changePagination({ pageIndex: 0, pageSize: 10000 })
      );
    }
    if (shiftPatternsSlice.actions.changePagination) {
      dispatch(
        shiftPatternsSlice.actions.changePagination({
          pageIndex: 0,
          pageSize: 10000
        })
      );
    }
    if (teamsSlice.actions.changePagination) {
      dispatch(
        teamsSlice.actions.changePagination({ pageIndex: 0, pageSize: 10000 })
      );
    }
  }, []);

  return (
    // TODO: handle error case for fetching of the engineer data
    renderErrorOrLoading(
      isLoading,
      'editEngineerSpinner',
      mergeErrors([
        skillsSelector.error,
        patchesSelector.error,
        shiftPatternsSelector.error,
        teamsSelector.error
      ]),
      classes
    ) || (
      <EditView
        domain={engineerDomain}
        fields={engineerFormFields(
          'edit',
          skillsSelector.items,
          patchesSelector.items,
          shiftPatternsSelector.items,
          teamsSelector.items
        )}
        validationSchema={validationSchema}
        presubmitCallback={processFormFields}
        processDomainData={processEngineerData}
      />
    )
  );
}

export default EditEngineerView;
