import { NavigationRoute } from 'views/Dashboard/routes';
import EditSkillView from 'views/Skills/EditSkill';
import AddSkillView from 'views/Skills/AddSkill';
import { skillDomain } from 'models';

const skillsRoutes: NavigationRoute[] = [
  {
    to: `/dashboard/${skillDomain.urlPath}/:id/edit`,
    component: EditSkillView
  },
  {
    to: `/dashboard/${skillDomain.urlPath}/create`,
    component: AddSkillView
  }
];

export default skillsRoutes;
