import {
  adjustmentDomain,
  adjustmentGroupDomain,
  patchDomain,
  shiftPatternDomain,
  skillDomain,
  teamDomain
} from 'models';
import { getDomainSlice } from 'store';

export const skillsSlice = getDomainSlice(skillDomain);
export const patchesSlice = getDomainSlice(patchDomain);
export const shiftPatternsSlice = getDomainSlice(shiftPatternDomain);
export const teamsSlice = getDomainSlice(teamDomain);
export const adjustmentsSlice = getDomainSlice(adjustmentDomain);
export const adjustmentGroupsSlice = getDomainSlice(adjustmentGroupDomain);

export type SkillsSelector = ReturnType<typeof skillsSlice.selector>;
export type PatchesSelector = ReturnType<typeof patchesSlice.selector>;
export type ShiftPatternsSelector = ReturnType<
  typeof shiftPatternsSlice.selector
>;
export type TeamsSelector = ReturnType<typeof teamsSlice.selector>;
