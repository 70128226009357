import React from 'react';

import {
  Form,
  FormValues,
  yupSchemaToValidationFunc,
  TimetableForm
} from 'components/Forms';
import { CrudViewProps, formSubmitHandler } from 'components/Crud/utils';
import { useDispatch } from 'react-redux';
import { getDomainSlice } from 'store';
import Typography from 'components/Typography';
import Button from 'components/Button';
import { FormRefProp } from 'components/Modal';
import { FormApiWithRestart } from 'components/Forms/Form';

interface AddAdjustmentProps extends CrudViewProps {
  parentId: string;
  timetable?: boolean;
}

const AddAdjustment = ({
  domain,
  fields,
  title,
  validationSchema,
  presubmitCallback,
  parentId,
  timetable = false,
  formRef
}: AddAdjustmentProps & FormRefProp) => {
  const dispatch = useDispatch();

  const { actions } = getDomainSlice(domain);

  if (fields == null) {
    return null;
  }

  const validationFunc = yupSchemaToValidationFunc(validationSchema);
  const apiCallback = (data: FormValues) =>
    dispatch(actions.add(data, parentId));

  return (
    <>
      {title ? (
        <Typography data-qa-id="createAdjustmentTitle">{title}</Typography>
      ) : null}
      <Form
        validationFunc={validationFunc}
        fields={fields}
        onSubmit={formSubmitHandler(apiCallback, presubmitCallback)}
        goBackToPreviousPage={false}
        initialValues={{ differentStartLocation: 'false' }}
      >
        {({ form }) => {
          // eslint-disable-next-line no-param-reassign
          formRef.current = form;

          return timetable ? <TimetableForm /> : undefined;
        }}
      </Form>
    </>
  );
};

interface AddAdjustmentButtonsProps {
  onCancel: () => void;
  onSave: FormApiWithRestart['submit'] | undefined;
}

export function AddAdjustmentButtons({
  onCancel,
  onSave
}: AddAdjustmentButtonsProps) {
  return (
    <>
      <Button
        color="secondary"
        size="small"
        onClick={() => onCancel()}
        data-testid="addAdjustmentCancelBtn"
      >
        Cancel
      </Button>
      <Button
        color="primary"
        size="small"
        onClick={() => {
          if (onSave) {
            onSave();
          }
        }}
        data-testid="addAdjustmentSubmitBtn"
      >
        Save
      </Button>
    </>
  );
}

export default AddAdjustment;
