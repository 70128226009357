/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DomainItem } from 'models/Domain';
import TimeBand from 'models/TimeBand';
import { AppThunk } from 'store';
import {
  initialStateSlice,
  startLoading,
  loadingFailed,
  StateSlice,
  getPaginationReducers
} from 'store/slices';
import { timeBandDomain } from 'models';
import { FormValues } from 'components/Forms';
import {
  fetchThunk,
  deleteItemThunk,
  addItemThunk,
  editItemThunk,
  fetchAllThunk
} from 'store/utils/';

const timeBands = createSlice({
  name: timeBandDomain.plural,
  initialState: initialStateSlice,
  reducers: {
    ...startLoading<TimeBand>(),
    ...loadingFailed<TimeBand>(),
    ...getPaginationReducers<TimeBand>(),
    getTimeBandSuccess(
      state,
      { payload }: PayloadAction<StateSlice<TimeBand>>
    ) {
      const { items, page } = payload;

      state.items = items;
      state.page = page;
      state.isLoading = false;
      state.error = null;
    },
    addTimeBandSuccess(
      state,
      { payload: addedTimeBand }: PayloadAction<TimeBand>
    ) {
      if (state.items != null) {
        state.items.push(addedTimeBand);
      }

      state.isLoading = false;
      state.error = null;
    },
    editTimeBandSuccess(
      state,
      { payload: editedTimeBand }: PayloadAction<TimeBand>
    ) {
      if (state.items != null) {
        state.items = state.items.filter(
          timeBand => timeBand.id !== editedTimeBand.id
        );
        state.items.push(editedTimeBand);
      }

      state.isLoading = false;
      state.error = null;
    },
    deleteTimeBandSuccess(
      state,
      { payload: deletedTimeBandId }: PayloadAction<string>
    ) {
      if (state.items != null) {
        state.items = state.items.filter(
          timeBand => timeBand.id !== deletedTimeBandId
        );
      }

      state.isLoading = false;
      state.error = null;
    }
  }
});

export const {
  requestStart,
  requestFail,
  setPagination: setTimeBandPagination,
  getTimeBandSuccess,
  addTimeBandSuccess,
  editTimeBandSuccess,
  deleteTimeBandSuccess
} = timeBands.actions;

export default timeBands.reducer;

export function fetchTimeBands(): AppThunk {
  return fetchThunk<StateSlice<TimeBand>>({
    domain: timeBandDomain,
    requestStart,
    requestFail,
    requestSuccess: getTimeBandSuccess
  });
}

export function fetchAllTimeBands(): AppThunk {
  return fetchAllThunk(timeBandDomain, {
    start: requestStart,
    success: getTimeBandSuccess,
    fail: requestFail
  });
}

export function addTimeBand(item: FormValues): AppThunk {
  return addItemThunk<TimeBand>({
    domain: timeBandDomain,
    requestStart,
    requestFail,
    requestSuccess: addTimeBandSuccess,
    item
  });
}

export function editTimeBand(item: FormValues, itemId: string): AppThunk {
  return editItemThunk<TimeBand>({
    domain: timeBandDomain,
    requestStart,
    requestFail,
    requestSuccess: editTimeBandSuccess,
    itemId,
    item
  });
}

export function deleteTimeBand(domainItem: DomainItem): AppThunk {
  return deleteItemThunk<TimeBand>({
    domain: timeBandDomain,
    requestStart,
    requestFail,
    requestSuccess: deleteTimeBandSuccess,
    itemId: domainItem.id
  });
}
