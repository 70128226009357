import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ErrorBoundary, initialiseSentry } from 'Sentry';
import LuxonUtils from '@date-io/luxon';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { HoldingPage } from 'views/HoldingPage';
import store from './store';
import theme from './styles/defaultTheme';
import App from './App';

import './index.css';

interface MinimalHoldingPageFlag {
  enabled: boolean;
}

interface EnabledHoldingPageFlag extends MinimalHoldingPageFlag {
  text: string;
  link?: string;
}

initialiseSentry();

const WrappedApp = () => {
  const { aegisEnableHoldingPage: holdingPageFlag } = useFlags();

  return (
    <Provider store={store}>
      <BrowserRouter>
        <MuiThemeProvider theme={createTheme(theme)}>
          <MuiPickersUtilsProvider utils={LuxonUtils}>
            <ErrorBoundary>
              {holdingPageFlag?.enabled ? (
                <HoldingPage {...(holdingPageFlag as EnabledHoldingPageFlag)} />
              ) : (
                <App />
              )}
            </ErrorBoundary>
          </MuiPickersUtilsProvider>
        </MuiThemeProvider>
      </BrowserRouter>
    </Provider>
  );
};

export { WrappedApp };
