import Adjustment, { AdjustmentType } from 'models/Adjustment';
import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState
} from 'react';
import { getPrimaryAdjustmentType } from 'utils/adjustments';
import { DeleteTemporaryAdjustmentModal } from './EditEngineerScheduleModal/DeleteTemporaryAdjustmentModal';
import { AdjustmentFormType } from './EditEngineerScheduleModal/selectAdjustmentChangeTypeConfig';
import SelectAdjustmentChangeTypeModal from './EditEngineerScheduleModal/SelectAdjustmentChangeTypeModal';
import TemporaryEfficiencyAdjustmentModal from './EditEngineerScheduleModal/TemporaryEfficiencyAdjustmentModal';
import TemporaryPatchAdjustmentModal from './EditEngineerScheduleModal/TemporaryPatchAdjustmentModal';
import TemporaryShiftAdjustmentModal from './EditEngineerScheduleModal/TemporaryShiftAdjustmentModal';
import TemporarySkillsAdjustmentModal from './EditEngineerScheduleModal/TemporarySkillsAdjustmentModal';

interface TemporaryAdjustmentFormContextState {
  isOpen: boolean;
  setIsOpen?: Dispatch<SetStateAction<boolean>>;
  adjustment?: Adjustment;
  setAdjustment?: Dispatch<SetStateAction<Adjustment | undefined>>;
}

export const TemporaryAdjustmentFormContext = React.createContext<
  TemporaryAdjustmentFormContextState
>({
  isOpen: false
});

export function TemporaryAdjustmentFormContextProvider({
  children
}: {
  children: ReactNode;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [adjustment, setAdjustment] = useState<Adjustment | undefined>();

  return (
    <TemporaryAdjustmentFormContext.Provider
      value={{
        isOpen,
        adjustment,
        setIsOpen,
        setAdjustment
      }}
    >
      {children}
    </TemporaryAdjustmentFormContext.Provider>
  );
}

export interface TemporaryAdjustmentFormModalProps {
  onPrevious: () => void;
  onDelete: () => void;
}

export interface AdjustmentFormButtonActions {
  onPrevious?: () => void;
  onCancel: () => void;
  onSubmit: () => void;
  showPrevious?: boolean;
}

enum FormStep {
  SelectType,
  Patch,
  Shift,
  Skills,
  Efficiency,
  Delete
}

const formTypeToStep = {
  [AdjustmentFormType.PatchChange]: FormStep.Patch,
  [AdjustmentFormType.ShiftChange]: FormStep.Shift,
  [AdjustmentFormType.EfficiencyChange]: FormStep.Efficiency,
  [AdjustmentFormType.SkillsChange]: FormStep.Skills
};

const adjustmentTypeToFormStep = {
  [AdjustmentType.Shift]: FormStep.Shift,
  [AdjustmentType.Patch]: FormStep.Patch,
  [AdjustmentType.Efficiency]: FormStep.Efficiency,
  [AdjustmentType.Skills]: FormStep.Skills
};

export const AdjustmentFormModals = () => {
  const { adjustment: adjustmentToEdit, isOpen } = useContext(
    TemporaryAdjustmentFormContext
  );
  const [formStep, setFormStep] = useState(FormStep.SelectType);

  const setInitialFormStep = () => {
    const step = adjustmentToEdit
      ? adjustmentTypeToFormStep[getPrimaryAdjustmentType(adjustmentToEdit)]
      : FormStep.SelectType;
    setFormStep(step);
  };

  const handleSelectTypeSubmit = (type: AdjustmentFormType) => {
    const step = formTypeToStep[type];
    setFormStep(step);
  };

  const handleAdjustmentFormPrevious = () => {
    setFormStep(FormStep.SelectType);
  };

  const handleDeleteModalCancel = () => {
    setInitialFormStep();
  };

  const handleDelete = () => {
    setFormStep(FormStep.Delete);
  };

  useEffect(() => {
    if (isOpen) {
      setInitialFormStep();
    }
  }, [isOpen]);

  useEffect(() => setInitialFormStep(), [adjustmentToEdit]);

  switch (formStep) {
    case FormStep.SelectType:
      return (
        <SelectAdjustmentChangeTypeModal onSubmit={handleSelectTypeSubmit} />
      );

    case FormStep.Shift: {
      return (
        <TemporaryShiftAdjustmentModal
          onPrevious={handleAdjustmentFormPrevious}
          onDelete={handleDelete}
        />
      );
    }

    case FormStep.Patch: {
      return (
        <TemporaryPatchAdjustmentModal
          onPrevious={handleAdjustmentFormPrevious}
          onDelete={handleDelete}
        />
      );
    }

    case FormStep.Efficiency: {
      return (
        <TemporaryEfficiencyAdjustmentModal
          onPrevious={handleAdjustmentFormPrevious}
          onDelete={handleDelete}
        />
      );
    }
    case FormStep.Skills: {
      return (
        <TemporarySkillsAdjustmentModal
          onPrevious={handleAdjustmentFormPrevious}
          onDelete={handleDelete}
        />
      );
    }

    case FormStep.Delete:
      return (
        <DeleteTemporaryAdjustmentModal onPrevious={handleDeleteModalCancel} />
      );

    default:
      return null;
  }
};
