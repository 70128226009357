import { NavigationRoute } from 'views/Dashboard/routes';
import ScheduleContainer from 'views/Schedule';
import { scheduleDomain } from 'models';

const scheduleRoutes: NavigationRoute[] = [
  {
    to: `/dashboard/${scheduleDomain.urlPath}`,
    component: ScheduleContainer
  }
];

export default scheduleRoutes;
