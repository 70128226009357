import { makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(theme => ({
  evMarker: {
    position: 'absolute',
    top: 3,
    right: 3,
    width: 6,
    height: 6,
    cursor: 'pointer',
    background: theme.palette.aegis.colour.grey.darkest,
    display: 'inline-block',
    borderRadius: 50
  }
}));

export default function EVMarker() {
  const classes = useStyles();
  return <span className={classes.evMarker} data-testid="evMarker" />;
}
