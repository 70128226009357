import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core';
import { timeToPixels } from '../scheduleHelpers';
import VehicleTypeIcon from './VehicleTypeIcon';

interface Props {
  travelTime: number;
  vehicleType: string | null;
}

const useStyles = makeStyles<Theme, { travelTimeWidth: number }>(
  theme =>
    createStyles({
      container: {
        display: 'flex',
        alignItems: 'center'
      },
      travelLine: {
        marginLeft: 4,
        marginRight: 4,
        height: 4,
        background: theme.palette.aegis.colour.grey.darker,
        width: ({ travelTimeWidth }) => travelTimeWidth
      },
      travelTime: {
        marginLeft: 'auto',
        color: theme.palette.aegis.colour.grey.darker,
        fontSize: 10,
        fontWeight: 600
      },
      evText: {
        fontSize: 12,
        color: theme.palette.aegis.colour.grey.darker
      }
    }),
  { name: 'EngineerTravelTime' }
);

export const EngineerTravelTime = ({ travelTime, vehicleType }: Props) => {
  // if travel time is more than 3h always set the width to 60 pixels
  const travelTimeWidth = travelTime >= 180 ? 60 : timeToPixels(travelTime, 3);
  const classes = useStyles({ travelTimeWidth });

  return (
    <div className={classes.container} data-testid="engineerTravelTime">
      <VehicleTypeIcon vehicleType={vehicleType} />
      <div className={classes.travelLine} data-testid="travelLine" />
      <div className={classes.travelTime} data-testid="travelTime">
        {travelTime}min
      </div>
    </div>
  );
};
