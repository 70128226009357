import React from 'react';

import { makeStyles, Theme } from '@material-ui/core';
import { DateTime } from 'luxon';

import { scheduleStartTime, scheduleEndTime } from 'constants/schedule';
import { timelineEnd, timelineStart } from 'views/Schedule/ScheduleView';
import { scheduleTimezone } from 'constants/dates';
import { getTimeDiff, timeToPixels } from './scheduleHelpers';

const scheduleStartTimeDate = DateTime.fromFormat(
  scheduleStartTime,
  'HH:mm:ss'
);
const scheduleEndTimeDate = DateTime.fromFormat(scheduleEndTime, 'HH:mm:ss');

const useStyles = makeStyles((theme: Theme) => ({
  timeIndicator: {
    position: 'absolute',
    width: 1,
    height: '100%',
    zIndex: 0,
    backgroundColor: theme.palette.schedule.timeline.currentTimeIndicator.color
  }
}));

const CurrentTimeIndicator = () => {
  const classes = useStyles();
  const now = DateTime.now().setZone(scheduleTimezone);

  const getPosition = () => {
    const formattedNow = now.toFormat('HH:mm:ss');

    const timeDifference = getTimeDiff(scheduleStartTime, formattedNow);

    return timelineStart + timeToPixels(timeDifference, 2);
  };

  const [position, setPosition] = React.useState(getPosition());

  React.useEffect(() => {
    // If the current time is before the start of the workday
    if (now < scheduleStartTimeDate) {
      setPosition(timelineStart);
      return;
    }

    // If the current time is after the end of the workday
    if (now > scheduleEndTimeDate) {
      setPosition(timelineEnd);
      return;
    }

    const id = setInterval(() => setPosition(getPosition()), 120000);

    // eslint-disable-next-line consistent-return
    return () => clearInterval(id);
  }, [position]);

  return (
    <div
      data-testid="currentTimeIndicator"
      className={classes.timeIndicator}
      style={{
        left: position
      }}
    />
  );
};

export default CurrentTimeIndicator;
