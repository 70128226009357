import React, { SyntheticEvent, useEffect } from 'react';

import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useDispatch, useSelector } from 'react-redux';
import ErrorDisplay from 'components/ErrorDisplay';
import { DomainItem } from 'models/Domain';
import { useHistory, useRouteMatch, Link } from 'react-router-dom';
import { RootState, getDomainSlice } from 'store';
import Button from 'components/Button';
import Snackbars from 'components/Alerts';
import { camelToTitleCase } from 'utils';
import Typography from 'components/Typography';
import { CrudViewListProps } from '../utils';

import useStyles from '../styles';

function ListView({
  domain,
  title = `View ${domain.plural}`,
  children,
  filter,
  sortBy,
  showPageHeader = true,
  autoLoad = true,
  paginationOptions = { pageIndex: 0, pageSize: 25 },
  dispatchProps,
  loadingDependency = false,
  disableErrorDisplay = false
}: CrudViewListProps) {
  const classes = useStyles();
  const history = useHistory();
  const { url } = useRouteMatch();
  const dispatch = useDispatch();

  const { selector, actions } = getDomainSlice(domain);

  const { items: data, isLoading, error } = useSelector<
    RootState,
    ReturnType<typeof selector>
  >(selector);

  const onEdit = (domainItem: DomainItem, overrideUrl?: string) => (
    event: SyntheticEvent<any>
  ) => {
    event.stopPropagation();
    history.push(`${overrideUrl || url}/${domainItem.id}/edit`, domainItem);
  };

  const onView = (domainItem: DomainItem) => () => {
    history.push(`${url}/${domainItem.id}`, domainItem);
  };

  const onDelete = (domainItem: DomainItem) => (event: SyntheticEvent<any>) => {
    event.stopPropagation();
    dispatch(actions.delete(domainItem));
  };

  const defaultDispatchProps = [filter, paginationOptions, undefined, sortBy];

  useEffect(() => {
    if (autoLoad) {
      dispatch(actions.get.list(...(dispatchProps || defaultDispatchProps)));
    }
  }, [autoLoad]);

  if (children == null) {
    return null;
  }
  return (
    <>
      <Grid
        container
        className={classes.listContainer}
        data-qa-id="scrollIntoView"
        data-testid="listViewContainer"
      >
        {/* TECH DEBT: Remove this flag once all pages have been converted */}
        {showPageHeader && (
          <>
            <Grid item className={classes.pageTitleContainer}>
              <Typography
                className={classes.readTitle}
                variant="h1"
                data-qa-id="readDomainPageTitle"
              >
                {title}
              </Typography>
            </Grid>
            <Grid
              container
              item
              alignItems="flex-start"
              justifyContent="flex-end"
              className={classes.addButtonContainer}
            >
              <Link
                to={`/dashboard/${domain.urlPath}/create`}
                className={classes.addButtonLink}
              >
                <Button
                  className={classes.addButton}
                  color="primary"
                  data-qa-id={`add${domain.type}Button`}
                >
                  {`Create ${camelToTitleCase(domain.type)}`}
                </Button>
              </Link>
            </Grid>
          </>
        )}
        {/* TECH DEBT: FSP-101 - Create page component - https://ovotech.atlassian.net/browse/FSP-101  */}
        <Grid item xs={12}>
          {disableErrorDisplay || !error
            ? null
            : error && <ErrorDisplay status={error.status} />}
          {(isLoading || loadingDependency) && (
            <div className={classes.loadingWrapper}>
              <CircularProgress
                size={48}
                className={classes.spinnerWrapper}
                data-qa-id="readDomainSpinner"
                data-testid="readDomainSpinner"
              />
            </div>
          )}
          {data?.length >= 0 &&
            !isLoading &&
            !loadingDependency &&
            children({
              onEdit,
              onDelete,
              onView
            })}
        </Grid>
      </Grid>
      <Snackbars />
    </>
  );
}

export default ListView;
