/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DomainItem } from 'models/Domain';
import ShiftPattern from 'models/ShiftPattern';
import { AppThunk } from 'store';
import { FormValues } from 'components/Forms';
import {
  initialStateSlice,
  startLoading,
  loadingFailed,
  StateSlice,
  getPaginationReducers
} from 'store/slices';
import { shiftPatternDomain } from 'models';
import {
  fetchThunk,
  deleteItemThunk,
  addItemThunk,
  editItemThunk,
  fetchAllThunk
} from 'store/utils';

const shiftPatterns = createSlice({
  name: shiftPatternDomain.plural,
  initialState: initialStateSlice,
  reducers: {
    ...startLoading<ShiftPattern>(),
    ...loadingFailed<ShiftPattern>(),
    ...getPaginationReducers<ShiftPattern>(),
    getShiftPatternsSuccess(
      state,
      { payload }: PayloadAction<StateSlice<ShiftPattern>>
    ) {
      const { items, page } = payload;

      state.items = items;
      state.page = page;
      state.isLoading = false;
      state.error = null;
    },
    addShiftPatternSuccess(
      state,
      { payload: addedShiftPattern }: PayloadAction<ShiftPattern>
    ) {
      if (state.items != null) {
        state.items.push(addedShiftPattern);
      }

      state.isLoading = false;
      state.error = null;
    },
    editShiftPatternSuccess(
      state,
      { payload: editedShiftPattern }: PayloadAction<ShiftPattern>
    ) {
      if (state.items != null) {
        state.items = state.items.filter(
          shiftPattern => shiftPattern.id !== editedShiftPattern.id
        );
        state.items.push(editedShiftPattern);
      }

      state.isLoading = false;
      state.error = null;
    },
    deleteShiftPatternSuccess(
      state,
      { payload: deletedShiftPatternId }: PayloadAction<string>
    ) {
      if (state.items != null) {
        state.items = state.items.filter(
          shiftPattern => shiftPattern.id !== deletedShiftPatternId
        );
      }

      state.isLoading = false;
      state.error = null;
    }
  }
});

export const {
  requestStart,
  requestFail,
  setPagination: setShiftPatternsPagination,
  getShiftPatternsSuccess,
  addShiftPatternSuccess,
  editShiftPatternSuccess,
  deleteShiftPatternSuccess
} = shiftPatterns.actions;

export default shiftPatterns.reducer;

export function fetchAllShiftPatterns(): AppThunk {
  return fetchAllThunk(shiftPatternDomain, {
    start: requestStart,
    success: getShiftPatternsSuccess,
    fail: requestFail
  });
}

export function fetchShiftPatterns(): AppThunk {
  return fetchThunk<StateSlice<ShiftPattern>>({
    domain: shiftPatternDomain,
    requestStart,
    requestFail,
    requestSuccess: getShiftPatternsSuccess
  });
}

export function addShiftPattern(item: FormValues): AppThunk {
  return addItemThunk<ShiftPattern>({
    domain: shiftPatternDomain,
    requestStart,
    requestFail,
    requestSuccess: addShiftPatternSuccess,
    item
  });
}

export function editShiftPattern(item: FormValues, itemId: string): AppThunk {
  return editItemThunk<ShiftPattern>({
    domain: shiftPatternDomain,
    requestStart,
    requestFail,
    requestSuccess: editShiftPatternSuccess,
    itemId,
    item
  });
}

export function deleteShiftPattern(domainItem: DomainItem): AppThunk {
  return deleteItemThunk<ShiftPattern>({
    domain: shiftPatternDomain,
    requestStart,
    requestFail,
    requestSuccess: deleteShiftPatternSuccess,
    itemId: domainItem.id
  });
}
