import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import { geographicAreaDomain } from 'models';
import { getDomainSlice } from 'store';
import { Schedule as ScheduleModel } from 'models/Schedule';
import Snackbars from 'components/Alerts';
import { renderErrorOrLoading } from 'components/Crud';
import { SelectSchedule } from 'views/Schedule/SelectSchedule';
import Typography from 'components/Typography';
import { geographicAreasListSelector } from 'store/slices/geographicAreas';
import { ScheduleView } from './ScheduleView';
import { ScheduleFilters } from './ScheduleContainer';

const useStyles = makeStyles(
  theme => ({
    container: {
      height: `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`,
      flexWrap: 'nowrap',
      backgroundColor: theme.palette.common.white
    },
    messageContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%'
    },
    selectScheduleWrapper: {
      position: 'absolute',
      width: 618,
      justifyContent: 'space-between',
      display: 'flex',
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      marginTop: theme.spacing(3)
    }
  }),
  { name: 'Schedule' }
);

export interface ScheduleProps {
  scheduleData: ScheduleModel;
  isLoading: boolean;
  areas: string[];
  date: string;
  setFilters: (value: ScheduleFilters) => void;
}

export function Schedule(props: ScheduleProps) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { scheduleData, isLoading, areas, date, setFilters } = props;
  const { actions } = getDomainSlice(geographicAreaDomain);
  const allAreas = useSelector(geographicAreasListSelector);

  useEffect(() => {
    dispatch(actions.get.list());
  }, []);

  function scheduleContents(): React.ReactNode {
    if (!date || areas.length === 0 || (!scheduleData && !isLoading)) {
      return (
        <Typography variant="bodyHeavy" data-testid="selectScheduleViewMessage">
          Find a schedule by choosing a date and area
        </Typography>
      );
    }
    return (
      renderErrorOrLoading(isLoading, 'loadingSpinner', null) || (
        <ScheduleView {...{ scheduleData, areas, date }} />
      )
    );
  }

  return (
    <div className={classes.container}>
      <div className={classes.selectScheduleWrapper}>
        <SelectSchedule {...{ areas, date, setFilters, allAreas }} />
      </div>
      <div className={classes.messageContainer} data-testid="scheduleView">
        {scheduleContents()}
      </div>
      <Snackbars />
    </div>
  );
}
