import { Status as PublishedStatus } from 'components/Schedule/EngineerInfo/PublishStatusIcon';
import { SelectEngineerCallback } from 'components/Schedule/Map/MapWrapper';
import Appointment, {
  AppointmentStatus,
  Complaint,
  CustomerAppointmentType
} from './Appointment';
import Skill from './Skills';

export interface Schedule {
  unassignedAppointments: ScheduleUnassignedAppointment[];
  schedules: EngineerSchedule[];
  date: string;
  areas: string[];
}
export interface EngineerSchedule {
  date: string;
  status: PublishedStatus;
  engineer: EngineerSummary;
  geometry: string;
  assignments: ScheduleAssignment[];
  unavailableReason: string | null;
}
interface Team {
  id: string;
  name: string;
}
export interface EngineerSummary {
  id: string;
  firstName: string;
  lastName: string;
  postcode: string;
  skills: Skill[];
  startTime: string;
  endTime: string;
  byBox: string;
  company: string;
  efficiency: number;
  jobTypes: string[];
  patch: string;
  phoneNumber: string;
  team: Team;
  vehicleType: string;
}
export interface ScheduleAssignment {
  arrivalTime: string;
  departureTime: string;
  driveTimeTo: number;
  type: ScheduleAssignmentType;
  driveDistanceInMeters: number;
  length: number;
  latestStartTime: string | null;
  earliestStartTime: string | null;
  appointment: Appointment;
  location: Location;
}

export interface ScheduleUnassignedAppointment {
  appointment: Appointment;
  length: number;
  location: Location;
}
export interface Location {
  postcode?: string;
  geoLocation: GeoLocation;
}
export interface GeoLocation {
  long: number;
  lat: number;
}

export interface AssignJobToEngineerPayload {
  appointmentId: string;
  engineersToAdd: string[];
  engineersToRemove: string[];
}

export type ScheduleAssignmentEventAction = 'add' | 'remove';

export type ScheduleAssignmentEvent = {
  action: ScheduleAssignmentEventAction;
  data?: any;
  engineerId: string;
  id: string;
  type: ScheduleAssignmentType;
};

export interface JobDetailsProps {
  handleJobCardOnClick: (value: JobDetailsFields) => void;
  selectedJobCardDetails: JobDetailsFields;
  requestFreshSchedules: () => void;
  handleSelectEngineer: SelectEngineerCallback;
}

export interface JobDetailsCustomer {
  firstName: string;
  lastName: string;
}

export interface JobDetailsAssigned {
  engineerId: string;
  firstName: string;
  lastName: string;
  publishedStatus: PublishedStatus;
}

export interface JobDetailsFields {
  id: string;
  isOpen: boolean;
  postcode: string;
  timeSlot: string;
  complaints?: Complaint[];
  customer?: JobDetailsCustomer;
  assigned?: JobDetailsAssigned;
  atRisk: boolean;
  type?: CustomerAppointmentType;
  fuelType?: string;
  jobTypeId: string;
  status?: AppointmentStatus;
}

export enum ScheduleAssignmentType {
  Start = 'START',
  Job = 'JOB',
  End = 'END',
  Fixed = 'FIXED'
}

export interface FindSchedulesRequest {
  date: string;
  geographicAreaIds: string[];
}
