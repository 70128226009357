import { makeStyles } from '@material-ui/core';

export const useAdjustmentFormStyles = makeStyles(
  theme => ({
    weekdaysWrapper: {
      display: 'flex',
      marginBottom: theme.spacing(2),
      gap: theme.spacing(0.5),
      alignItems: 'baseline',
      justifyContent: 'space-between'
    },
    weekdaySelectWrapper: {
      display: 'flex',
      gap: theme.spacing(0.5),
      minWidth: '400px'
    },
    weekdaySelect: {
      width: '100%'
    },
    typography: {
      marginBottom: theme.spacing(1)
    },
    allEmptyErrorContainer: {
      marginBottom: theme.spacing(2)
    },
    skillsWeekdaySelectWrapper: {
      display: 'flex',
      gap: theme.spacing(0.5),
      width: '400px'
    },
    skillsWeekdaySelect: {
      '& [class^="MuiInputBase-root"]': {
        paddingTop: 10,
        paddingBottom: 10,
        minWidth: '400px'
      }
    }
  }),
  { name: 'AdjustmentForm' }
);
