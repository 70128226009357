// EnabledBannerFlag set up to match flag schema in LaunchDarkly, so correct to not use "enabled" field
/* eslint-disable react/no-unused-prop-types */

import React from 'react';
import { makeStyles } from '@material-ui/core';
import Typography from 'components/Typography';
import ErrorIcon from '@material-ui/icons/Error';
import { LinkButton } from 'components/Button';

export interface EnabledBannerFlag {
  enabled: boolean;
  link: string | null;
  text: string;
}

const useStyles = makeStyles(theme => ({
  banner: {
    alignItems: 'center',
    display: 'flex',
    flexGrow: 0,
    backgroundColor: theme.palette.warning.light,
    border: `1px solid ${theme.palette.warning.dark}`,
    borderRadius: theme.spacing(0.5),
    color: theme.palette.warning.dark,
    minWidth: 0,
    gap: theme.spacing(1),
    padding: `0px ${theme.spacing(1)}px 0px ${theme.spacing(1)}px`
  },
  button: {
    color: theme.palette.aegis.semantic.warning.alt,
    '&, &:hover': {
      borderColor: theme.palette.aegis.semantic.warning.alt
    },
    '&:hover': {
      backgroundColor: theme.palette.aegis.semantic.warning.translucent
    },
    '& .MuiTouchRipple-child': {
      backgroundColor: theme.palette.aegis.semantic.warning.alt
    }
  },
  text: {
    lineHeight: `${theme.spacing(5)}px`,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  }
}));

interface IssueBannerProps extends EnabledBannerFlag {
  className?: string;
}

const IssueBanner = ({ link, text, className }: IssueBannerProps) => {
  const classes = useStyles();

  return (
    <div data-testid="issueBanner" className={`${classes.banner} ${className}`}>
      <ErrorIcon />
      <Typography className={classes.text}>{text}</Typography>
      {link ? (
        <LinkButton url={link} className={classes.button} label="Details" />
      ) : null}
    </div>
  );
};

export default IssueBanner;
