import React from 'react';
import CustomButton from './Button';

interface LinkButtonProps {
  url: string;
  className?: string;
  label: string;
}

const LinkButton = ({ url, className, label }: LinkButtonProps) => {
  return (
    <a
      style={{ textDecoration: 'none' }}
      href={url}
      target="_blank"
      rel="noreferrer"
      data-testid="linkButton"
    >
      <CustomButton className={className} size="medium">
        {label}
      </CustomButton>
    </a>
  );
};

export default LinkButton;
