import React, { memo } from 'react';

import { DndProvider, useDrop } from 'react-dnd';
import { makeStyles } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { HTML5Backend } from 'react-dnd-html5-backend';

import {
  ScheduleUnassignedAppointment,
  ScheduleAssignmentEvent,
  ScheduleAssignmentType,
  JobDetailsFields
} from 'models/Schedule';
import JobCard, { JobCardViewMode } from 'components/Schedule/JobCard/JobCard';
import { AppointmentSlot } from 'models/Appointment';
import UnassignedJobsContainer, {
  UnassignedJobsLayout
} from 'components/Schedule/UnassignedJobsContainer/UnassignedJobsContainer';
import { useScheduleError } from 'store/selectors';
import { timelineStart } from 'views/Schedule/ScheduleView';
import { getJobCardStyleType } from 'utils/jobStatus';
import { JobDndItem } from '../EngineerRow/EngineerRow';
import { getHydratedJobDetails } from '../Map/scheduleMapHelpers';
import { defaultSlotHighlight } from '../TimelineHeader';
import { getPostcode } from '../scheduleHelpers';

export const spinnerSize = 40;

const useStyles = makeStyles(
  theme => ({
    container: {
      display: 'flex',
      position: 'relative',
      minHeight: spinnerSize + theme.spacing(4)
    },
    title: {
      fontSize: 14,
      width: timelineStart,
      height: 52,
      lineHeight: '52px',
      textAlign: 'center',
      margin: 0,
      fontWeight: 400
    },
    jobsContainer: {
      display: 'flex'
    },
    spinner: {
      position: 'absolute',
      top: theme.spacing(2),
      right: 0,
      left: 0,
      margin: 'auto'
    }
  }),
  { name: 'UnassignedJobs' }
);

interface UnassignedJobsProps {
  jobs: ScheduleUnassignedAppointment[];
  onJobCardSelect: (slot: AppointmentSlot) => void;
  onUnassignedHover: (id: string, newSelectedRoute?: string) => void;
  handleJobCardOnClick: (value: JobDetailsFields) => void;
  onChange: (change: ScheduleAssignmentEvent) => void;
  selectedJobCardDetails: JobDetailsFields;
}

const UnassignedJobs = ({
  jobs,
  onJobCardSelect,
  onUnassignedHover,
  handleJobCardOnClick,
  onChange,
  selectedJobCardDetails
}: UnassignedJobsProps) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = React.useState(false);
  const error = useScheduleError();

  React.useEffect(() => {
    setIsLoading(false);
  }, [jobs, error]);

  const [{ isOver }, drop] = useDrop({
    accept: 'Job',
    canDrop(item: JobDndItem) {
      const found = jobs?.find(
        assignment => assignment.appointment.id === item.appointmentId
      );
      return !found;
    },
    drop: (item: JobDndItem) => {
      setIsLoading(true);
      onChange({
        action: 'remove',
        engineerId: item.engineerId,
        id: item.appointmentId,
        type: ScheduleAssignmentType.Job
      });
    },
    collect: monitor => ({
      isOver: monitor.isOver() && monitor.canDrop()
    })
  });

  const renderJobCards = (items: ScheduleUnassignedAppointment[]) =>
    items?.map(assignment => {
      return (
        <div
          data-testid="unassignedJobCard"
          data-qa-id="unassignedJobCard"
          onDragStart={() => onJobCardSelect(assignment.appointment.slot)}
          onDragEnd={() => onJobCardSelect(defaultSlotHighlight)}
          key={assignment.appointment.id}
          data-slot={assignment.appointment.slot}
          onClick={() => {
            onJobCardSelect(assignment.appointment.slot);
            onUnassignedHover(assignment.appointment.id, undefined);
            handleJobCardOnClick(getHydratedJobDetails(assignment));
          }}
        >
          <JobCard
            jobCardStyleType={getJobCardStyleType(
              assignment.appointment,
              false
            )}
            hasComplaints={Boolean(assignment.appointment.complaints?.length)}
            appointmentId={assignment.appointment.id}
            duration={assignment.length}
            slot={assignment.appointment.slot}
            label={getPostcode(assignment.location?.postcode)}
            viewMode={JobCardViewMode.TIMELINE}
            jobType={assignment.appointment.jobType}
            onDrop={() => setIsLoading(true)}
            onUnassignedHover={onUnassignedHover}
            assigned={false}
            selectedJobCardDetails={selectedJobCardDetails}
            type={assignment.appointment?.appointmentType?.type}
          />
        </div>
      );
    });

  const unassignedJobsList = renderJobCards(jobs);

  return (
    <DndProvider backend={HTML5Backend}>
      <div
        className={classes.container}
        data-testid="unassignedJobs"
        data-qa-id="unassignedJobs"
        ref={drop}
        style={{
          border: isOver ? '1px dashed gray' : '1px solid transparent'
        }}
      >
        {isLoading ? (
          <CircularProgress
            size={spinnerSize}
            className={classes.spinner}
            color="primary"
            data-testid="unassignedJobsLoading"
            data-qa-id="unassignedJobsLoading"
          />
        ) : (
          <>
            <h2 className={classes.title}>
              Unassigned Jobs ({unassignedJobsList.length})
            </h2>
            <div
              data-testid="unassignedJobsContainer"
              data-qa-id="unassignedJobsContainer"
              className={classes.jobsContainer}
            >
              <UnassignedJobsContainer layout={UnassignedJobsLayout.TWO_ROWS}>
                {unassignedJobsList}
              </UnassignedJobsContainer>
            </div>
          </>
        )}
      </div>
    </DndProvider>
  );
};

export default memo(UnassignedJobs);
