import * as Yup from 'yup';

import {
  InputFieldProps,
  yupSchemaToValidationFunc,
  mapSkillsToAutocompleteValues,
  teamDataToSelect,
  patchDataToSelect,
  shiftPatternDataToSelect,
  SkillOption
} from 'components/Forms';
import Skill from 'models/Skills';
import { vehicleOptions } from 'utils/formValues';
import TeamModel from 'models/Team';
import Patch from 'models/Patches';
import ShiftPattern from 'models/ShiftPattern';
import { REQUIRED_FIELD_ERROR_MESSAGE } from 'constants/validation';
import { getEfficiencyOptions } from '../utils';

const skillsValidationSchema = Yup.array(
  Yup.object().shape({
    id: Yup.string(),
    name: Yup.string(),
    category: Yup.object().shape({
      id: Yup.string(),
      name: Yup.string()
    })
  })
).required('Required');

const editEngineerFormFieldsValidation = Yup.object().shape({
  firstName: Yup.string().required(REQUIRED_FIELD_ERROR_MESSAGE),
  lastName: Yup.string().required(REQUIRED_FIELD_ERROR_MESSAGE),
  phoneNumber: Yup.string().required(REQUIRED_FIELD_ERROR_MESSAGE),
  emailAddress: Yup.string().email('This field must be a valid email address'),
  postcode: Yup.string().required(REQUIRED_FIELD_ERROR_MESSAGE),
  skills: skillsValidationSchema,
  team: Yup.string(),
  company: Yup.string().required(REQUIRED_FIELD_ERROR_MESSAGE),
  vehicleType: Yup.string().nullable(),
  byBox: Yup.string().required(REQUIRED_FIELD_ERROR_MESSAGE),
  defaultPatch: Yup.string().required(REQUIRED_FIELD_ERROR_MESSAGE),
  defaultShiftPattern: Yup.string().required(REQUIRED_FIELD_ERROR_MESSAGE),
  defaultEfficiency: Yup.string().required(REQUIRED_FIELD_ERROR_MESSAGE)
});

export const editEngineerFormValidation = yupSchemaToValidationFunc(
  editEngineerFormFieldsValidation
);

export enum EditEngineerModalFormFieldNames {
  FirstName = 'editEngineerFirstName',
  LastName = 'editEngineerLastName',
  PhoneNumber = 'editEngineerPhoneNumber',
  EmailAddress = 'editEngineerEmailAddress',
  Postcode = 'editEngineerPostcode',
  Skills = 'editEngineerSkills',
  Team = 'editEngineerTeam',
  Company = 'editEngineerCompany',
  VehicleType = 'editEngineerVehicleType',
  ByBox = 'editEngineerByBox',
  DefaultPatch = 'editEngineerDefaultPatch',
  DefaultShiftPattern = 'editEngineerDefaultShiftPattern',
  DefaultEfficiency = 'editEngineerDefaultEfficiency'
}

export const editEngineerPersonalFormFields: InputFieldProps[] = [
  {
    id: EditEngineerModalFormFieldNames.FirstName,
    label: 'First name',
    name: 'firstName',
    qaId: EditEngineerModalFormFieldNames.FirstName,
    inputType: 'textfield'
  },
  {
    id: EditEngineerModalFormFieldNames.LastName,
    label: 'Last name',
    name: 'lastName',
    qaId: EditEngineerModalFormFieldNames.LastName,
    inputType: 'textfield'
  },
  {
    id: EditEngineerModalFormFieldNames.PhoneNumber,
    label: 'Phone number',
    name: 'phoneNumber',
    qaId: EditEngineerModalFormFieldNames.PhoneNumber,
    inputType: 'textfield'
  },
  {
    id: EditEngineerModalFormFieldNames.EmailAddress,
    label: 'Email address',
    name: 'emailAddress',
    qaId: EditEngineerModalFormFieldNames.EmailAddress,
    inputType: 'textfield'
  },
  {
    id: EditEngineerModalFormFieldNames.Postcode,
    label: 'Postcode',
    name: 'postcode',
    qaId: EditEngineerModalFormFieldNames.Postcode,
    inputType: 'textfield'
  }
];

export const getEngineerSkillsField = (skills: Skill[]): InputFieldProps => ({
  id: EditEngineerModalFormFieldNames.Skills,
  label: 'Skills',
  name: 'skills',
  qaId: EditEngineerModalFormFieldNames.Skills,
  inputType: 'multiselect',
  options: skills && mapSkillsToAutocompleteValues(skills),
  optionRenderer: SkillOption,
  preprocess: (items: Skill[]) => items && mapSkillsToAutocompleteValues(items)
});

export const getCompanyDetailsFields = (
  teams: TeamModel[],
  patches: Patch[],
  shiftPatterns: ShiftPattern[]
): InputFieldProps[] => [
  {
    id: EditEngineerModalFormFieldNames.Team,
    label: 'Team',
    name: 'team',
    qaId: EditEngineerModalFormFieldNames.Team,
    inputType: 'select',
    options: teams && teams.map(teamDataToSelect)
  },
  {
    id: EditEngineerModalFormFieldNames.Company,
    label: 'Company',
    name: 'company',
    qaId: EditEngineerModalFormFieldNames.Company,
    inputType: 'textfield'
  },
  {
    id: EditEngineerModalFormFieldNames.VehicleType,
    label: 'Vehicle type',
    name: 'vehicleType',
    qaId: EditEngineerModalFormFieldNames.VehicleType,
    inputType: 'select',
    options: vehicleOptions
  },
  {
    id: EditEngineerModalFormFieldNames.ByBox,
    label: 'ByBox',
    name: 'byBox',
    qaId: EditEngineerModalFormFieldNames.ByBox,
    inputType: 'textfield'
  },
  {
    id: EditEngineerModalFormFieldNames.DefaultPatch,
    label: 'Default patch',
    name: 'defaultPatch',
    qaId: EditEngineerModalFormFieldNames.DefaultPatch,
    inputType: 'select',
    options: patches && patches.map(patchDataToSelect)
  },
  {
    id: EditEngineerModalFormFieldNames.DefaultShiftPattern,
    label: 'Default shift pattern',
    name: 'defaultShiftPattern',
    qaId: EditEngineerModalFormFieldNames.DefaultShiftPattern,
    inputType: 'select',
    options: shiftPatterns && shiftPatterns.map(shiftPatternDataToSelect)
  },
  {
    id: EditEngineerModalFormFieldNames.DefaultEfficiency,
    label: 'Default efficiency',
    name: 'defaultEfficiency',
    qaId: EditEngineerModalFormFieldNames.DefaultEfficiency,
    inputType: 'select',
    options: getEfficiencyOptions()
  }
];
