import React from 'react';
import SearchIcon from '@material-ui/icons/Search';
import {
  InputAdornment,
  makeStyles,
  TextField as MUITextField,
  Theme
} from '@material-ui/core';
import CloseSearchIcon from 'assets/images/clear-search.svg';

interface TextFieldProps {
  value: string;
  onChange: (event: any) => void;
  onEnterPress: (event: any) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  valueField: {
    width: '100%'
  },
  textField: {
    '& input::-webkit-search-cancel-button': {
      backgroundImage: `url(${CloseSearchIcon})`,
      backgroundRepeat: 'no-repeat',
      '-webkit-appearance': 'none',
      height: 14,
      width: 14,
      cursor: 'pointer',
      margin: theme.spacing(1.5, 2, 1.5, 2),
      flexDirection: 'row'
    }
  }
}));

const TextField = ({ value, onChange, onEnterPress }: TextFieldProps) => {
  const classes = useStyles();
  return (
    <MUITextField
      type="search"
      id="filter-text-input"
      inputProps={{ 'data-testid': 'filterValue' }}
      variant="outlined"
      name="filterValue"
      value={value}
      placeholder="Search"
      classes={{ root: `${classes.valueField} ${classes.textField}` }}
      onChange={onChange}
      onKeyPress={onEnterPress}
      // eslint-disable-next-line react/jsx-no-duplicate-props
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        )
      }}
    />
  );
};

export default TextField;
