import { AppThunk } from 'store';
import {
  fetchSchedule,
  requestFail,
  scheduleItemSelector
} from 'store/slices/schedule';
import http from 'api/http';
import { scheduleDomain } from 'models';
import { handleThunkError } from 'store/utils';
import { AssignJobToEngineerPayload } from '../../../models/Schedule';

export function editSchedule(payload?: AssignJobToEngineerPayload): AppThunk {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const { date, areas } = scheduleItemSelector(state);

      await http.post(`${scheduleDomain.apiPath}/${date}/assign`, payload);

      dispatch(fetchSchedule(date, areas, true));
    } catch (error) {
      handleThunkError({
        error,
        requestFail,
        dispatch,
        domainType: scheduleDomain.type,
        alwaysShowApiErrorMessages: true
      });
    }
  };
}
