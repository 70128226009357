import React from 'react';
import { makeStyles, createStyles, Link } from '@material-ui/core';
import Typography from 'components/Typography';
import { useAddJobModalStyles } from './AddJobModal';

export enum SummaryPropertyType {
  LINK = 'link'
}

interface SummaryPropertyProps {
  name: string;
  orientation?: SummaryPropertyOrientation;
  type?: SummaryPropertyType;
  value?: string | Element;
}

export enum SummaryPropertyOrientation {
  BELOW = 'below',
  SIDE = 'side'
}

const useStyles = makeStyles(theme =>
  createStyles({
    formSectionHeading: {
      margin: theme.spacing(2.5, -1, 0)
    },
    info: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      '&:first-of-type': {
        paddingTop: 0
      },
      '&:last-of-type': {
        borderBottom: 0,
        paddingBottom: 0
      }
    },
    summaryPropTitle: {
      color: theme.palette.common.black
    },
    summaryPropValue: {
      whiteSpace: 'pre-line'
    },
    linkUrl: {
      color: theme.palette.aegis.semantic.message.dark
    }
  })
);

export default function SummaryProperty({
  name,
  value,
  type,
  orientation = SummaryPropertyOrientation.SIDE
}: SummaryPropertyProps) {
  const classes = useStyles();
  const addJobModalClasses = useAddJobModalStyles();

  const titleAriaId = `${name}-title`;

  if (orientation === SummaryPropertyOrientation.BELOW) {
    return (
      <div
        className={`${addJobModalClasses.info} ${addJobModalClasses.infoWrap}`}
        data-testid="newSummaryProperty"
      >
        <Typography
          className={classes.summaryPropTitle}
          id={titleAriaId}
          data-testid="summaryPropertyName"
        >
          {name}
        </Typography>
        {value && (
          <Typography
            className={classes.summaryPropValue}
            aria-describedby={titleAriaId}
            data-testid="summaryPropertyValue"
          >
            {value}
          </Typography>
        )}
      </div>
    );
  }

  return (
    <div className={addJobModalClasses.info} data-testid="newSummaryProperty">
      <Typography
        className={classes.summaryPropTitle}
        data-testid="summaryPropertyName"
      >
        {name}
      </Typography>
      {value &&
        (type === SummaryPropertyType.LINK ? (
          <Typography
            align="right"
            variant="body"
            data-testid="summaryPropertyValue"
          >
            <Link
              href={value as string}
              className={classes.linkUrl}
              target="_blank"
            >
              {value}
            </Link>
          </Typography>
        ) : (
          <Typography align="right" data-testid="summaryPropertyValue">
            {value}
          </Typography>
        ))}
    </div>
  );
}
