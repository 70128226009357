import React, { useContext } from 'react';
import {
  MuiThemeProvider,
  Grid,
  makeStyles,
  createTheme,
  useTheme
} from '@material-ui/core';
import Typography from 'components/Typography';
import { DateTime } from 'luxon';
import { scheduleStartTime } from 'constants/schedule';
import defaultTheme from 'styles/defaultTheme';
import {
  EngineerCalendarDatesContext,
  EngineerEvent
} from './EngineerCalendar';
import EngineerCalendarCapacity from './EngineerCalendarCapacity';
import { EngineerCalendarDefaults } from './EngineerCalendarDefaults';
import EngineerCalendarFixedAppointments from './EngineerCalendarFixedAppointments';

const [leftColWidth, cellWidth, cellHeight] = [135, 138, 88];

export const useEngineerCalendarStyles = makeStyles(
  theme => {
    const solidBorder = `1px solid ${theme.palette.aegis.semantic.border.light}`;
    const dashedBorder = `1px dashed ${theme.palette.aegis.semantic.border.light}`;

    return {
      wrapper: {
        borderTop: solidBorder
      },
      container: {
        paddingTop: 107
      },
      cell: {
        '&:last-child': {
          borderRight: 'none'
        },
        display: 'flex',
        width: cellWidth,
        borderRight: solidBorder
      },
      appointmentsDayColumn: {
        position: 'relative',
        flexDirection: 'column',
        paddingTop: theme.spacing(1.5),
        justifyContent: 'flex-start'
      },
      dayColumn: {
        display: 'flex',
        flexWrap: 'nowrap',
        height: '100%'
      },
      leftColCell: {
        width: leftColWidth,
        paddingRight: theme.spacing(1.5),
        borderRight: solidBorder,
        justifyContent: 'end'
      },
      horizontalCell: {
        '&:last-child': {
          borderBottom: dashedBorder
        },
        width: '100%',
        height: cellHeight,
        borderTop: dashedBorder
      },
      rowLabels: {
        color: theme.palette.aegis.semantic.message.dark
      },
      scheduleTimeLabel: {
        position: 'relative'
      },
      scheduleTimeLabelWrapper: {
        '&:not(:last-child)': {
          height: cellHeight
        },
        '&:last-child': {
          // Having to do this negative margin because there is a 1px gap on the last child,
          // despite the cells all being the same height 🤷‍♂️
          marginTop: -1,
          height: 0,
          overflowY: 'visible'
        }
      },
      scheduleTimeLabelAdornment: {
        borderTop: dashedBorder,
        width: 6,
        display: 'block',
        position: 'absolute',
        right: '-11px',
        top: '50%'
      },
      totalCapacity: {
        display: 'flex',
        paddingBottom: 52
      },
      columnHeader: {
        '&:first-child': {
          width: leftColWidth
        },
        '&:last-child': {
          borderRight: 'none'
        },
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(3),
        width: cellWidth,
        borderRight: solidBorder
      },
      adjustmentLabels: {
        lineHeight: `${theme.spacing(4)}px`
      },
      dateLabel: {
        width: 64,
        padding: theme.spacing(1),
        border: `1px solid transparent`
      },
      selectedDay: {
        color: theme.palette.aegis.semantic.info.base
      },
      today: {
        borderColor: theme.palette.aegis.semantic.info.base,
        backgroundColor: theme.palette.aegis.semantic.info.muted,
        borderRadius: theme.spacing(0.5)
      },
      defaultsOverlay: {
        display: 'flex',
        position: 'relative'
      },
      event: {
        position: 'absolute',
        width: '100%',
        minHeight: theme.typography.bodyHeavy.lineHeight,
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        borderRadius: theme.spacing(0.5),
        backgroundColor: theme.palette.aegis.colour.green.lighter,
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column'
      },
      textContainer: {
        width: '100%',
        flexGrow: 0,
        flexShrink: 1,
        flexBasis: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        maxHeight: '60px'
      },
      eventText: {
        color: theme.palette.aegis.colour.green.darkest
      }
    };
  },
  { name: 'EngineerCalendarBody' }
);

function isToday(date: DateTime): boolean {
  return date.toISODate() === DateTime.local().toISODate();
}

export interface EngineerCalendarDay {
  date: DateTime;
  labels: {
    date: string;
    day: string;
  };
}

interface EngineerCalendarBodyProps {
  events?: EngineerEvent[];
}

function EngineerCalendarBody({ events }: EngineerCalendarBodyProps) {
  const { dates } = useContext(EngineerCalendarDatesContext);

  const theme = useTheme();

  const days: EngineerCalendarDay[] = Array.from({ length: 7 }, (_, i) => {
    const currentDate = dates.start.plus({ days: i });
    return {
      date: currentDate,
      labels: {
        date: currentDate.toFormat('d'),
        day: currentDate.toFormat('ccc')
      }
    };
  });

  const classes = useEngineerCalendarStyles(days.length);

  const scheduleTimes = Array.from({ length: 8 }, (_, i) =>
    DateTime.fromISO(scheduleStartTime).plus({ day: 1, hours: i * 2 })
  );

  const engineerCalendarBodyTheme = {
    ...defaultTheme,
    overrides: {
      ...defaultTheme.overrides,
      MuiTypography: {
        root: { color: defaultTheme.palette.aegis.semantic.message.dark }
      }
    }
  };

  return (
    <MuiThemeProvider theme={createTheme(engineerCalendarBodyTheme)}>
      <Grid container className={classes.wrapper}>
        {/* Day column headers */}
        <Grid container>
          <Grid
            container
            justifyContent="center"
            className={classes.columnHeader}
            data-testid="engineerCalendarBodyDayHeader"
          />
          {days.map(({ date, labels }) => (
            <Grid
              container
              justifyContent="center"
              key={`${labels.day}-${labels.date}`}
              className={classes.columnHeader}
            >
              <Grid
                container
                direction="column"
                alignItems="center"
                className={
                  isToday(date)
                    ? `${classes.dateLabel} ${classes.today}`
                    : classes.dateLabel
                }
                data-testid={`engineerCalendarBodyDayWrapper_${labels.day}`}
              >
                <Typography
                  variant="bodyHeavy"
                  align="center"
                  component="div"
                  {...(isToday(date) ? { className: classes.selectedDay } : {})}
                  data-testid={`engineerCalendarBodyDayLabel_${labels.day}`}
                >
                  {labels.day}
                </Typography>
                <Typography
                  variant="h2"
                  align="center"
                  component="div"
                  data-testid={`engineerCalendarBodyDateLabel_${labels.day}`}
                >
                  {labels.date}
                </Typography>
              </Grid>
            </Grid>
          ))}
        </Grid>
        {/* Total capacity */}
        <Grid container>
          <div className={`${classes.leftColCell} ${classes.totalCapacity}`}>
            <Typography className={classes.rowLabels}>
              Total capacity
            </Typography>
          </div>

          <EngineerCalendarCapacity days={days} />
        </Grid>
        {/* Adjustments */}

        <Grid container direction="row">
          <Grid
            container
            direction="column"
            alignItems="flex-end"
            style={{ paddingBottom: 52, gap: theme.spacing(1) }}
            className={`${classes.cell} ${classes.leftColCell}`}
          >
            <Typography
              className={`${classes.adjustmentLabels} ${classes.rowLabels}`}
            >
              Shift pattern
            </Typography>
            <Typography
              className={`${classes.adjustmentLabels} ${classes.rowLabels}`}
            >
              Patch
            </Typography>
            <Typography
              className={`${classes.adjustmentLabels} ${classes.rowLabels}`}
            >
              Efficiency
            </Typography>
            <Typography
              className={`${classes.adjustmentLabels} ${classes.rowLabels}`}
            >
              Skills
            </Typography>
          </Grid>
          <div className={classes.defaultsOverlay}>
            <EngineerCalendarDefaults cellWidth={cellWidth} />
            {days.map(day => (
              <div key={day.labels.day} className={classes.cell}>
                {/* Adjustments go here */}
              </div>
            ))}
          </div>
        </Grid>
        {/* Left time column */}
        <Grid className={`${classes.cell} ${classes.leftColCell}`}>
          <Grid container direction="column" alignItems="flex-end">
            {scheduleTimes.map(time => (
              <div
                key={time.toMillis()}
                className={classes.scheduleTimeLabelWrapper}
              >
                <div className={classes.scheduleTimeLabel}>
                  <Typography className={classes.rowLabels}>
                    {time.toLocaleString(DateTime.TIME_24_SIMPLE)}
                  </Typography>
                  <span
                    className={classes.scheduleTimeLabelAdornment}
                    aria-hidden="true"
                  />
                </div>
              </div>
            ))}
          </Grid>
        </Grid>
        {/* Right time column */}
        <Grid style={{ flexGrow: 1 }}>
          {/* Day columns */}
          <div className={classes.dayColumn}>
            {days.map(({ date, labels }) => {
              return (
                <div
                  key={`${labels.day}-${labels.date}-column`}
                  className={`${classes.cell} ${classes.appointmentsDayColumn}`}
                >
                  {/* Time cells */}
                  <EngineerCalendarFixedAppointments
                    date={date}
                    events={events}
                  />
                  {scheduleTimes
                    .filter(time => time.hour < DateTime.fromISO('20:00').hour)
                    .map(time => (
                      <div
                        key={time.toMillis()}
                        className={classes.horizontalCell}
                        aria-hidden="true"
                      />
                    ))}
                </div>
              );
            })}
          </div>
        </Grid>
      </Grid>
    </MuiThemeProvider>
  );
}

export default EngineerCalendarBody;
