import axios, { AxiosRequestConfig } from 'axios';
import keycloak from 'auth/keycloak';
import { captureMessage, Severity } from '@sentry/react';

const TOKEN_LENGTH = 2; // TODO: have this as a config value

const http = axios.create({
  baseURL: process.env.REACT_APP_API_URL as string,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
});

http.interceptors.request.use((config: AxiosRequestConfig) => {
  // If not logged in, then don't bother trying to add the token to the request
  if (!keycloak.authenticated) {
    return config;
  }

  const { headers, ...otherConfig } = config;

  return keycloak
    .updateToken(TOKEN_LENGTH)
    .then(() => {
      return Promise.resolve({
        ...otherConfig,
        headers: {
          ...headers,
          Authorization: `Bearer ${keycloak.token}`
        }
      });
    })
    .catch(error => {
      captureMessage(
        `Failed to update access token \n ${JSON.stringify(error)}`,
        Severity.Error
      );
      keycloak.logout({
        redirectUri: window.location.origin
      });
      return config;
    });
});

export default http;
