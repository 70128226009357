import React from 'react';
import { formSubmitHandler } from 'components/Crud';
import { Form, FormValues } from 'components/Forms';
import { AppThunk } from 'store';
import JobType from 'models/JobTypes';
import { FormRefProp } from 'components/Modal';
import keycloak, { Role, hasRole } from 'auth/keycloak';
import {
  buildInitialFormFields,
  processAddJobFormFields,
  initialFormValidation
} from './InitialFormConfig';

interface InitialFormProps extends FormRefProp {
  jobTypeData: JobType[];
  initialValues?: {
    postcode: string;
    jobType: string;
  };
  capacityApiCallback: (data: FormValues) => AppThunk;
  setCapacitySuccess: (success: boolean) => void;
  setIsCapacitySubmitting: (isSubmitting: boolean) => void;
}

export default function InitialForm({
  jobTypeData,
  initialValues,
  capacityApiCallback,
  setCapacitySuccess,
  setIsCapacitySubmitting,
  formRef
}: InitialFormProps) {
  const includeToday = hasRole(keycloak, Role.CapacityAllowIncludeToday);

  const processAddJobFormFieldsWithFlag = (values: FormValues) =>
    processAddJobFormFields(values, includeToday);

  return (
    <Form
      validationFunc={initialFormValidation}
      fields={buildInitialFormFields(jobTypeData)}
      initialValues={initialValues}
      onSubmit={formSubmitHandler(
        capacityApiCallback,
        processAddJobFormFieldsWithFlag
      )}
      goBackToPreviousPage={false}
    >
      {({ form, submitting, submitSucceeded, submitErrors }) => {
        setCapacitySuccess(!submitErrors && submitSucceeded);
        setIsCapacitySubmitting(submitting);

        // eslint-disable-next-line no-param-reassign
        formRef.current = form;
      }}
    </Form>
  );
}
