/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit';
import { geographicAreaDomain } from 'models';
import { AppThunk, RootState } from 'store/store';
import { createFilterQuery, QueryParams, handleThunkError } from 'store/utils';
import { GeographicArea, GeographicAreaParent } from 'models/GeographicArea';
import { iteratePagination, getPaginatedApiData } from 'store/utils/pagination';

import {
  StateSlice,
  initialStateSlice,
  getPaginationReducers,
  loadingFailed,
  startLoading
} from '.';

const geographicAreas = createSlice({
  name: geographicAreaDomain.plural,
  initialState: initialStateSlice as StateSlice<GeographicArea>,
  reducers: {
    ...startLoading<GeographicArea>(),
    ...loadingFailed<GeographicArea>(),
    ...getPaginationReducers<GeographicArea>(),
    // TODO: Abstract these out to be generic functions
    getGeographicAreasSuccess(
      state,
      { payload: { items, page } }: PayloadAction<StateSlice<GeographicArea>>
    ) {
      state.items = items;
      state.page = page;
      state.isLoading = false;
      state.error = null;
    }
  }
});

export const {
  requestStart,
  requestFail,
  setPagination: setGeographicAreasPagination,
  getGeographicAreasSuccess
} = geographicAreas.actions;

const getGeographicAreasByCategory = (geographicAreasArr: GeographicArea[]) => {
  const parents = geographicAreasArr.reduce((acc, { id, name, category }) => {
    const categoryName = category.name;
    const categoryIndex = acc.findIndex(
      ({ parentCategoryName }) => parentCategoryName === categoryName
    );

    const childGeographicArea = {
      id,
      name,
      parentName: categoryName
    };

    if (categoryIndex >= 0) {
      acc[categoryIndex].children.push(childGeographicArea);
      acc[categoryIndex].children.sort((first, second) =>
        first.name.localeCompare(second.name)
      );
      return acc;
    }

    return [
      ...acc,
      {
        parentCategoryName: categoryName,
        children: [childGeographicArea]
      }
    ];
  }, [] as GeographicAreaParent[]);

  return parents.sort((first, second) =>
    first.parentCategoryName.localeCompare(second.parentCategoryName)
  );
};

export const geographicAreasItemsSelector = (state: RootState) =>
  (state[geographicAreaDomain.plural as keyof RootState] as StateSlice<
    GeographicArea
  >)?.items;

export const filterParentAreaQueryParams: QueryParams = {
  'parent.id': {
    operator: '!=',
    values: [`null`]
  }
};

export const geographicAreasListSelector = createSelector(
  geographicAreasItemsSelector,
  getGeographicAreasByCategory
);

export function fetchGeographicAreas(
  query: QueryParams = filterParentAreaQueryParams
): AppThunk {
  return async (dispatch, getState) => {
    const filterQueryParams = query
      ? { params: createFilterQuery(query) }
      : undefined;

    try {
      dispatch(requestStart());

      const allGeographicAreas: GeographicArea[] = await iteratePagination<
        GeographicArea
      >(filterQueryParams, getPaginatedApiData(geographicAreaDomain));

      dispatch(
        getGeographicAreasSuccess({
          ...getState().geographicAreas,
          items: allGeographicAreas
        })
      );

      return allGeographicAreas;
    } catch (error) {
      handleThunkError({
        error,
        requestFail,
        domainType: geographicAreaDomain.type,
        dispatch
      });
      return null;
    }
  };
}

export default geographicAreas.reducer;
