import {
  InputFieldProps,
  FieldInputValue,
  yupSchemaToValidationFunc
} from 'components/Forms';
import { getRequiredErrorMessage } from 'constants/validation';
import { AbortCategory } from 'models/AbortCategory';
import * as Yup from 'yup';

const getReasonOptions = (
  abortCategories: AbortCategory[],
  currentCategoryId: string | null
): FieldInputValue[] => {
  if (currentCategoryId === null) {
    return [];
  }

  const currentCategory = abortCategories.find(
    category => category.id === currentCategoryId
  );

  return currentCategory
    ? currentCategory.reasons.map(reason => ({
        value: reason.id,
        label: reason.name,
        key: reason.id
      }))
    : [];
};

export const cancelJobFormfields = (
  abortCategories: AbortCategory[],
  currentCategoryId: string | null,
  setCurrentCategoryId: (category: string | null) => void
): InputFieldProps[] => [
  {
    id: 'category',
    label: 'Cancellation category',
    name: 'category',
    qaId: 'cancelJobCategory',
    inputType: 'select',
    options: abortCategories.map(option => ({
      value: option.id,
      label: option.name,
      key: option.id
    })),
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => {
      setCurrentCategoryId(event.target.value);
    }
  },
  {
    id: 'reason',
    label: 'Cancellation reason',
    name: 'reason',
    qaId: 'cancelJobReason',
    inputType: 'select',
    options: getReasonOptions(abortCategories, currentCategoryId)
  },
  {
    id: 'rebookable',
    label: 'Can the job be rebooked?',
    name: 'rebookable',
    qaId: 'cancelJobRebookable',
    inputType: 'radio',
    options: [
      { value: 'true', label: 'Yes', key: 'true' },
      { value: 'false', label: 'No', key: 'false' }
    ]
  },
  {
    id: 'comments',
    label: 'Comments',
    name: 'comments',
    qaId: 'cancelJobComments',
    inputType: 'textarea'
  }
];

const cancelJobFormFieldsValidation = Yup.object().shape({
  category: Yup.string().required(getRequiredErrorMessage('category')),
  reason: Yup.string().required(getRequiredErrorMessage('reason')),
  rebookable: Yup.string().required(getRequiredErrorMessage('selection'))
});

export const cancelJobFormValidation = yupSchemaToValidationFunc(
  cancelJobFormFieldsValidation
);
