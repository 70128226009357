import React, { memo } from 'react';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import { ThemeProvider, createTheme } from '@material-ui/core';

import { Input } from 'components/Forms';
import { DATE_WITH_SLASH_FORMAT } from 'constants/dates';
import LuxonUtils from '@date-io/luxon';
import { DateTime, Settings } from 'luxon';
import { modalFormInputOverrides } from 'styles/defaultTheme';
import dateTimeTheme, { modalFormDateTimeInputOverrides } from './theme';

Settings.defaultLocale = 'en';

const Date = ({
  field,
  meta,
  value,
  format = DATE_WITH_SLASH_FORMAT,
  ...props
}: any) => {
  return (
    <ThemeProvider
      theme={createTheme({
        ...dateTimeTheme,
        overrides: {
          ...modalFormInputOverrides,
          ...dateTimeTheme.overrides,
          ...modalFormDateTimeInputOverrides
        }
      })}
    >
      <MuiPickersUtilsProvider utils={LuxonUtils}>
        <KeyboardDatePicker
          size="small"
          {...props}
          error={Boolean(meta.touched && meta.error)}
          field={field}
          meta={meta}
          {...(field.shouldDisableDate && {
            shouldDisableDate: field.shouldDisableDate
          })}
          {...(field.handleChange && {
            onChange: (selectedDate: DateTime) => {
              field.handleChange(selectedDate);
              props.onChange(selectedDate);
            }
          })}
          // Passing an empty string (React Final Form default for empty values)
          // does not work with this date picker, it must be `null`
          value={value || null}
          TextFieldComponent={Input}
          format={format}
          variant="inline"
          autoOk
          className="MuiDate"
        />
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
};

export default memo(Date);
