import { FieldInputValue, InputFieldProps } from 'components/Forms';
import { REQUIRED_FIELD_ERROR_MESSAGE } from 'constants/validation';
import Adjustment, { DayOfWeek } from 'models/Adjustment';
import { sanitizeTimeForComparison } from 'views/Appointments/formConfig';
import * as Yup from 'yup';

export const adjustmentWeekdayKeys = Object.keys(DayOfWeek);

export enum AdjustmentFormCommonFieldName {
  Reason = 'adjustmentReason',
  ValidFrom = 'adjustmentValidFrom',
  ValidTo = 'adjustmentValidTo'
}

export const adjustmentCommonFieldsValidationSchema = {
  [AdjustmentFormCommonFieldName.Reason]: Yup.string().required(
    REQUIRED_FIELD_ERROR_MESSAGE
  ),
  [AdjustmentFormCommonFieldName.ValidFrom]: Yup.string()
    .required(REQUIRED_FIELD_ERROR_MESSAGE)
    .nullable(),
  [AdjustmentFormCommonFieldName.ValidTo]: Yup.string()
    .required(REQUIRED_FIELD_ERROR_MESSAGE)
    .when('adjustmentValidFrom', (validFrom: string, schema: any) =>
      schema
        .test({
          test: (validTo: any) => {
            if (validFrom && validTo) {
              return (
                sanitizeTimeForComparison(validFrom).diff(
                  sanitizeTimeForComparison(validTo)
                ).milliseconds <= 0
              );
            }
            return true;
          },
          message: 'Valid To must be after Valid From'
        })
        .nullable()
    )
};

export const adjustmentCommonFieldsInitialValues = (
  adjustment?: Adjustment
) => {
  return {
    [AdjustmentFormCommonFieldName.Reason]: adjustment?.reason || '',
    [AdjustmentFormCommonFieldName.ValidFrom]: adjustment?.startDate || '',
    [AdjustmentFormCommonFieldName.ValidTo]: adjustment?.endDate || ''
  };
};

export const getAdjustmentCommonFields = (
  options: FieldInputValue[]
): InputFieldProps[] => [
  {
    name: AdjustmentFormCommonFieldName.Reason,
    id: AdjustmentFormCommonFieldName.Reason,
    qaId: AdjustmentFormCommonFieldName.Reason,
    inputType: 'select',
    label: 'Reason',
    options
  },
  {
    name: AdjustmentFormCommonFieldName.ValidFrom,
    id: AdjustmentFormCommonFieldName.ValidFrom,
    qaId: AdjustmentFormCommonFieldName.ValidFrom,
    inputType: 'date',
    label: 'Valid from'
  },
  {
    name: AdjustmentFormCommonFieldName.ValidTo,
    id: AdjustmentFormCommonFieldName.ValidTo,
    qaId: AdjustmentFormCommonFieldName.ValidTo,
    inputType: 'date',
    label: 'Valid to'
  }
];
