import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Form, FormValues } from 'components/Forms';
import { FormApiWithRestart } from 'components/Forms/Form';
import { abortCategoriesDomain, appointmentDomain } from 'models';
import {
  AbortCustomerAppointmentRequest,
  CustomerAppointmentType
} from 'models/Appointment';
import { JobDetailsResponse } from 'models/JobDetails';
import { getDomainSlice } from 'store';
import Modal from 'components/Modal';
import { SecondaryButton } from 'components/Forms/Buttons';
import { AbortReasonAction, AbortReasonProduct } from 'models/AbortCategory';
import { selectAbortOrCancelCategories } from 'store/selectors';
import { patchCloseJob } from 'store/endpoints/closeJob';
import { setSnackbar, SnackbarStatus } from 'store/slices/snackbar';
import { DefaultButton } from 'components/Button';
import CustomButton from 'components/Button/Button';
import Typography from 'components/Typography';
import { useParams } from 'react-router-dom';
import { commonErrorHandling } from 'store/utils';

import {
  cancelJobFormfields,
  cancelJobFormValidation
} from './CancelJobFormConfig';

interface JobCancellationModalProps {
  job: JobDetailsResponse;
  open: boolean;
  closeModal: () => void;
  reloadJobDetails: () => void;
}

interface CancelJobModalState {
  step: CancelJobFormStep;
  cancellationRequest?: AbortCustomerAppointmentRequest;
  formValues?: FormValues;
}
interface CancelJobProps
  extends Omit<JobCancellationModalProps, 'reloadJobDetails'> {
  initialFormValues?: FormValues;
  setModalState: React.Dispatch<React.SetStateAction<CancelJobModalState>>;
}

enum CancelJobFormStep {
  Cancel = 'cancel',
  Confirmation = 'confirmation'
}

function CancelJobModal({
  job,
  open,
  initialFormValues,
  closeModal,
  setModalState
}: CancelJobProps) {
  const dispatch = useDispatch();
  const { actions: abortActions } = getDomainSlice(abortCategoriesDomain);

  const jobAppointmentType =
    job.appointmentType?.type === CustomerAppointmentType.EV
      ? AbortReasonProduct.EV
      : // Making a big assumption here. This could also be 'MAINTENANCE'
        AbortReasonProduct.Smart;

  const [currentCategoryId, setCurrentCategoryId] = useState<string | null>(
    null
  );
  const formRef = useRef<FormApiWithRestart>();

  const abortCategoryData = useSelector(
    selectAbortOrCancelCategories(jobAppointmentType, AbortReasonAction.Cancel)
  );

  useEffect(() => {
    if (abortCategoryData.length === 0) {
      dispatch(abortActions.get.list());
    }
  }, [abortCategoryData]);

  useEffect(() => {
    if (initialFormValues?.category) {
      setCurrentCategoryId(initialFormValues?.category as string);
    }
  }, [initialFormValues]);

  const resetFormOnCancel = () => {
    formRef.current?.restart();
    closeModal();
  };

  const handleSubmit = (formValues: FormValues) => {
    const { category, reason, rebookable, comments } = formValues;
    const closeJobRequest = {
      categoryId: category,
      reasonId: reason,
      rebook: rebookable === 'true',
      comments
    } as AbortCustomerAppointmentRequest;

    setModalState(() => ({
      step: CancelJobFormStep.Confirmation,
      cancellationRequest: closeJobRequest,
      formValues
    }));
  };

  return (
    <Modal
      title="Cancel Job"
      testId="cancelJobModal"
      open={open}
      onClose={closeModal}
      modalActions={
        <>
          <SecondaryButton
            onClick={() => resetFormOnCancel()}
            testId="cancelJobGoBackBtn"
          >
            Go back
          </SecondaryButton>
          <DefaultButton
            onClick={() => formRef.current?.submit()}
            testId="cancelJobConfirmBtn"
          >
            Confirm
          </DefaultButton>
        </>
      }
    >
      <Form
        validationFunc={cancelJobFormValidation}
        initialValues={initialFormValues}
        fields={cancelJobFormfields(
          abortCategoryData,
          currentCategoryId,
          setCurrentCategoryId
        )}
        onSubmit={handleSubmit}
        goBackToPreviousPage={false}
      >
        {({ form }) => {
          formRef.current = form;
        }}
      </Form>
    </Modal>
  );
}

export default function JobCancellationModal({
  job,
  open,
  closeModal,
  reloadJobDetails
}: JobCancellationModalProps) {
  const dispatch = useDispatch();
  const { id: jobId } = useParams<{ id: string }>();
  const [modalState, setModalState] = useState<CancelJobModalState>({
    step: CancelJobFormStep.Cancel,
    cancellationRequest: undefined
  });

  const resetFormStep = () =>
    setModalState(state => ({ ...state, step: CancelJobFormStep.Cancel }));

  const handleClose = () => {
    setModalState(() => ({
      step: CancelJobFormStep.Cancel,
      formValues: undefined,
      cancellationRequest: undefined
    }));
    closeModal();
  };

  const submitCancellationRequest = async () => {
    if (modalState.cancellationRequest) {
      try {
        await patchCloseJob(jobId, modalState.cancellationRequest);

        reloadJobDetails();

        dispatch(
          setSnackbar({
            show: true,
            status: SnackbarStatus.Info,
            message: 'Job has been cancelled'
          })
        );

        resetFormStep();
        closeModal();
      } catch (error) {
        commonErrorHandling(error, appointmentDomain.type);

        dispatch(
          setSnackbar({
            show: true,
            status: SnackbarStatus.Error,
            title: 'Error',
            message: 'Job could not be cancelled. Please try again later'
          })
        );
      }
    }
  };

  if (modalState.step !== CancelJobFormStep.Cancel) {
    return (
      <Modal
        title="Cancel Job"
        testId="cancelJobModalConfirmation"
        open={open}
        onClose={handleClose}
        modalActions={
          <>
            <SecondaryButton
              onClick={resetFormStep}
              testId="cancelJobGoBackBtn"
            >
              Go back
            </SecondaryButton>
            <CustomButton
              isErrorButton
              onClick={submitCancellationRequest}
              testId="cancelJobSubmitBtn"
            >
              Cancel job
            </CustomButton>
          </>
        }
      >
        <Typography>Are you sure you want to cancel this job?</Typography>
        <Typography>This action is permanent and cannot be undone.</Typography>
      </Modal>
    );
  }

  return (
    <CancelJobModal
      job={job}
      open={open}
      closeModal={handleClose}
      setModalState={setModalState}
      initialFormValues={modalState.formValues}
    />
  );
}
