import { makeStyles } from '@material-ui/core/styles';

export const boxStyles = makeStyles(theme => ({
  label: {
    color: theme.palette.info.dark,
    marginBottom: theme.spacing(1)
  },
  box: {
    padding: theme.spacing(4),
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.common.white
  }
}));

export const useStyles = makeStyles(theme => ({
  engineerCardContainer: {
    width: theme.containers.large
  },
  engineerContainer: {
    maxWidth: 800
  },
  section: {
    minWidth: theme.containers.large,
    maxWidth: theme.containers.large
  },
  field: {
    marginTop: theme.spacing(1)
  },
  paper: {
    width: '100%',
    padding: theme.spacing(2),
    backgroundColor: theme.palette.common.white
  },
  subheader: {
    color: theme.palette.info.dark,
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2)
  },
  button: {
    margin: theme.spacing(1),
    width: '200px'
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifySelf: 'flex-end'
  },
  adjustmentContainer: {
    margin: '0px'
  },
  profileButtonContainer: {
    marginTop: theme.spacing(1)
  },
  labelText: {
    cursor: 'pointer',
    userSelect: 'none'
  },
  viewAppointmentsButton: {
    marginLeft: 'auto'
  },
  newEngineerProfileSection: {
    padding: theme.spacing(3),
    minWidth: theme.containers.large,
    marginBottom: theme.spacing(1.5)
  }
}));
