import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Snackbar from 'components/Alerts';
import { Container } from '@material-ui/core';
import { useFlags } from 'launchdarkly-react-client-sdk';
import JobAlerts from './JobAlerts/JobAlerts';

const useStyles = makeStyles(
  theme => ({
    dashboardContainer: {
      display: 'flex',
      justifyContent: 'center',
      padding: theme.spacing(9, 0),
      overflow: 'hidden'
    }
  }),
  { name: 'Dashboard' }
);

const DashboardView = () => {
  const classes = useStyles();
  const { aegisDisableDashboardJobAlerts } = useFlags();

  return (
    <Container className={classes.dashboardContainer}>
      {aegisDisableDashboardJobAlerts ? null : <JobAlerts />}
      <Snackbar />
    </Container>
  );
};

export default DashboardView;
