import Engineer from 'models/Engineers';
import { capitalise } from './utils';

const mockoonJunkString = '::%::';

/**
 * This method removes the queryParam junk around the searched term and uses it as a last or first name for
 * the resulting engineer. This is only an issue when using mockoon's mocks and you are searching
 * for an Engineer.
 * @param items
 * @param pageSize
 */
export const cleanupMockoonSearchMocks = (
  items: Engineer[],
  totalItems: number
) => {
  if (process.env.REACT_APP_ENVIRONMENT === 'mockoon' && totalItems === 1) {
    return items.map(item => {
      if (item.lastName.includes(mockoonJunkString)) {
        return {
          ...item,
          lastName: capitalise(
            item.lastName.substring(14, item.lastName.length - 1)
          )
        };
      }
      if (item.firstName.includes(mockoonJunkString)) {
        return {
          ...item,
          firstName: capitalise(
            item.firstName.substring(15, item.firstName.length - 1)
          )
        };
      }
      if (item.postcode.includes(mockoonJunkString)) {
        return {
          ...item,
          postcode: item.postcode
            .substring(14, item.postcode.length - 1)
            .toUpperCase()
        };
      }
      return item;
    });
  }
  return items;
};
