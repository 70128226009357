export enum MapElementType {
  ASSIGNED,
  UNASSIGNED,
  HOME,
  ROUTE
}

const getDefaultOpacity = (
  selectedEngineer?: string,
  engineerId?: string
): number => {
  return selectedEngineer === undefined || selectedEngineer === engineerId
    ? 1
    : 0.3;
};

export const getOpacity = (
  type: MapElementType,
  selectedEngineer?: string,
  engineerId?: string,
  canDrag?: boolean
): number => {
  if (!selectedEngineer) {
    return 1;
  }
  if (type === MapElementType.HOME || type === MapElementType.ROUTE) {
    return getDefaultOpacity(selectedEngineer, engineerId);
  }
  if (canDrag && type === MapElementType.UNASSIGNED) {
    return 1;
  }
  if (!engineerId) {
    return 0.3;
  }

  return getDefaultOpacity(selectedEngineer, engineerId);
};
