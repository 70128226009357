import React, { useEffect } from 'react';
import { CreateView, renderErrorOrLoading } from 'components/Crud';
import useStyles from 'components/Crud/styles';
import { engineerDomain } from 'models';
import { useDispatch, useSelector } from 'react-redux';
import { engineerFormFields, validationSchema } from './FormConfig';
import { isAnyLoading, processFormFields } from './utils';
import {
  skillsSlice,
  patchesSlice,
  shiftPatternsSlice,
  teamsSlice,
  SkillsSelector,
  PatchesSelector,
  ShiftPatternsSelector,
  TeamsSelector
} from './store';
import { RootState } from '../../store';
import { mergeErrors } from '../../utils/utils';

function AddEngineerView() {
  const dispatch = useDispatch();
  const classes = useStyles();

  const skillsSelector = useSelector<RootState, SkillsSelector>(
    skillsSlice.selector
  );
  const patchesSelector = useSelector<RootState, PatchesSelector>(
    patchesSlice.selector
  );
  const shiftPatternsSelector = useSelector<RootState, ShiftPatternsSelector>(
    shiftPatternsSlice.selector
  );
  const teamsSelector = useSelector<RootState, TeamsSelector>(
    teamsSlice.selector
  );
  const isLoading = isAnyLoading(
    skillsSelector,
    patchesSelector,
    shiftPatternsSelector,
    teamsSelector
  );

  // TODO - temporary high page size values until FFT-612 is completed
  useEffect(() => {
    if (skillsSlice.actions.changePagination) {
      dispatch(
        skillsSlice.actions.changePagination({ pageIndex: 0, pageSize: 10000 })
      );
    }
    if (patchesSlice.actions.changePagination) {
      dispatch(
        patchesSlice.actions.changePagination({ pageIndex: 0, pageSize: 10000 })
      );
    }
    if (shiftPatternsSlice.actions.changePagination) {
      dispatch(
        shiftPatternsSlice.actions.changePagination({
          pageIndex: 0,
          pageSize: 10000
        })
      );
    }
    if (teamsSlice.actions.changePagination) {
      dispatch(
        teamsSlice.actions.changePagination({ pageIndex: 0, pageSize: 10000 })
      );
    }
  }, []);

  return (
    renderErrorOrLoading(
      isLoading,
      'addEngineerSpinner',
      mergeErrors([
        skillsSelector.error,
        patchesSelector.error,
        shiftPatternsSelector.error,
        teamsSelector.error
      ]),
      classes
    ) || (
      <CreateView
        domain={engineerDomain}
        fields={engineerFormFields(
          'add',
          skillsSelector.items,
          patchesSelector.items,
          shiftPatternsSelector.items,
          teamsSelector.items
        )}
        validationSchema={validationSchema}
        presubmitCallback={processFormFields}
      />
    )
  );
}

export default AddEngineerView;
