import React, { memo } from 'react';
import { Marker } from 'react-map-gl';
import JobCard, { JobCardViewMode } from 'components/Schedule/JobCard/JobCard';
import {
  ScheduleUnassignedAppointment,
  JobDetailsFields
} from 'models/Schedule';
import { getJobCardStyleType } from 'utils/jobStatus';
import { getPostcode } from 'components/Schedule/scheduleHelpers';
import { unassignedCardHeight } from 'components/Schedule/JobCard/Unassigned';
import { getHydratedJobDetails } from '../scheduleMapHelpers';
import { SelectEngineerCallback } from '../MapWrapper';
import MarkerContainer from './MarkerContainer';

interface UnassignedJobMarkerProps {
  unassignedScheduleAssignment: ScheduleUnassignedAppointment;
  handleJobCardOnClick: (value: JobDetailsFields) => void;
  selectedUnassigned: string;
  selectedEngineer?: string;
  handleSelectEngineer: SelectEngineerCallback;
  selectedJobCardDetails: JobDetailsFields;
}

const UnassignedJobMarker = ({
  unassignedScheduleAssignment,
  handleJobCardOnClick,
  selectedUnassigned,
  selectedEngineer,
  handleSelectEngineer,
  selectedJobCardDetails
}: UnassignedJobMarkerProps): JSX.Element => (
  <MarkerContainer
    isSelected={
      selectedJobCardDetails.isOpen &&
      selectedJobCardDetails.id === unassignedScheduleAssignment.appointment.id
    }
    onClick={() =>
      handleJobCardOnClick(getHydratedJobDetails(unassignedScheduleAssignment))
    }
  >
    <Marker
      // Key set here as a hacky way to ensure the Marker (and child) re-renders whenever job updates
      key={JSON.stringify(unassignedScheduleAssignment.appointment.id)}
      longitude={unassignedScheduleAssignment.location.geoLocation.long}
      latitude={unassignedScheduleAssignment.location.geoLocation.lat}
      offsetTop={-(unassignedCardHeight / 2)}
      offsetLeft={-(unassignedCardHeight / 2)}
    >
      <JobCard
        jobCardStyleType={getJobCardStyleType(
          unassignedScheduleAssignment.appointment,
          false
        )}
        canDrag={false}
        appointmentId={unassignedScheduleAssignment.appointment.id}
        duration={unassignedScheduleAssignment.length}
        hideTravelTime
        slot={unassignedScheduleAssignment.appointment.slot}
        label={getPostcode(unassignedScheduleAssignment.location?.postcode)}
        viewMode={JobCardViewMode.MAP}
        hovered={
          selectedUnassigned === unassignedScheduleAssignment.appointment.id
        }
        assigned={false}
        selectedEngineer={selectedEngineer}
        handleSelectEngineer={handleSelectEngineer}
        selectedJobCardDetails={selectedJobCardDetails}
      />
    </Marker>
  </MarkerContainer>
);

export default memo(UnassignedJobMarker);
