/* eslint-disable react/no-array-index-key */
import React from 'react';
import { usePagination, UsePaginationItem } from '@material-ui/lab/Pagination';
import { makeStyles } from '@material-ui/core/styles';
import { Domain } from 'models/Domain';
import { useDispatch, useSelector } from 'react-redux';
import { getDomainSlice, RootState } from 'store';
import PaginationItem from '@material-ui/lab/PaginationItem';
import { capitalise } from 'utils';
import { QueryParams, ApiQueryParams } from 'store/utils';
import { SortByOptions } from 'components/Crud';

interface PaginationProps {
  domain: Domain;
  filter?: QueryParams;
  sortBy?: SortByOptions;
  queryParams?: ApiQueryParams;
}

const useStyles = makeStyles(
  theme => ({
    container: {
      width: '100%',
      borderTop: `1px solid ${theme.palette.table.border}`,
      justifyContent: 'center',
      position: 'relative',
      margin: 'auto',
      display: 'flex',
      paddingBottom: theme.spacing(3)
    },
    list: {
      listStyle: 'none',
      padding: 0,
      margin: theme.spacing(2, 0, 0, 0),
      display: 'flex',

      '& .Mui-selected': {
        backgroundColor: theme.palette.pagination.selectedItem.background,
        color: theme.palette.pagination.selectedItem.color,

        '&:hover': {
          backgroundColor: theme.palette.pagination.selectedItem.background
        }
      }
    },
    item: {
      height: 24,
      minWidth: 24,
      color: theme.palette.pagination.item.color,
      margin: theme.spacing(0, 1),
      lineHeight: 22,
      overflow: 'hidden'
    },
    dots: {
      lineHeight: '24.5px'
    }
  }),
  { name: 'Pagination' }
);

export const PaginationComponent = (props: PaginationProps) => {
  const { domain, filter, sortBy, queryParams } = props;
  const dispatch = useDispatch();
  const { selector, actions } = getDomainSlice(domain);
  const {
    page: { pageIndex, pageSize, totalItems }
  } = useSelector<RootState, ReturnType<typeof selector>>(selector);
  const classes = useStyles();

  const handleChangePage = (
    event: React.ChangeEvent<unknown> | null,
    pageNumber: number
  ) => {
    if (actions.changePagination) {
      dispatch(
        actions.changePagination({
          pageIndex: pageNumber - 1,
          pageSize,
          filter,
          sortBy,
          queryParams
        })
      );
    }
  };

  // Defaulted `pageCount` to `1` because otherwise no pagination displays if there's no results.
  // This in turn means that a page index of `-1` is sent to the API, which results in a 400 error 🙃
  const pageCount = Math.ceil(totalItems / pageSize) || 1;
  const selectedPage = pageIndex + 1;

  const getTestIdString = (item: UsePaginationItem) =>
    `paginationSelectPage${
      item.type === 'page' ? item.page : capitalise(item.type)
    }`;

  const { items } = usePagination({
    count: pageCount,
    showFirstButton: true,
    showLastButton: true,
    page: selectedPage,
    onChange: handleChangePage
  });

  return (
    <nav className={classes.container}>
      <ul className={classes.list} data-qa-id="paginationButtons">
        {items.map(item => {
          let children = null;
          if (item.type === 'start-ellipsis' || item.type === 'end-ellipsis') {
            children = (
              <span className={(classes.item, classes.dots)}>...</span>
            );
          } else {
            children = (
              <PaginationItem
                className={classes.item}
                {...item}
                data-testid={getTestIdString(item)}
              />
            );
          }

          return <li key={`${item.type}-${item.page}`}>{children}</li>;
        })}
      </ul>
    </nav>
  );
};
