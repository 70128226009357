import React, { useEffect, useRef, useState } from 'react';

import { Audit } from 'models/Audit';
import Table from 'components/Table';
import { makeStyles, TableCell, TableRow } from '@material-ui/core';
import { onlyUnique } from 'utils';
import { commonErrorHandling, QueryParams } from 'store/utils';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { fetchEngineers } from 'store/endpoints/engineers';
import { JobMovementEngineerInfo } from 'models/Engineers';
import { auditEntriesToRows } from './auditHelper';

interface HistoryPaneProps {
  auditData: Audit[];
}

const useStyles = makeStyles(() => ({
  actionCell: {
    textTransform: 'none'
  }
}));

const columnHeaders = ['Date', 'Action', 'Actioned by'];
const columnWidths = [160, 320, 160];

export function HistoryPane({ auditData }: HistoryPaneProps) {
  const { actionCell } = useStyles();
  const flags = useFlags();
  const [jobMovementEngineers, setJobMovementEngineers] = useState<
    JobMovementEngineerInfo[] | undefined
  >();

  const isMounted = useRef(true);

  useEffect(() => {
    const abortController = new AbortController();

    (async () => {
      if (flags.aegisEnableSupportJobMovementAuditRecords) {
        const engineerIds = onlyUnique(
          auditData
            .filter(
              ({ changedField, additionalData }) =>
                changedField === 'scheduleId' && additionalData !== null
            )
            .map(({ additionalData }) => additionalData)
        );

        const fetchAuditEngineersQuery: QueryParams = {
          id: {
            values: engineerIds,
            operator: '='
          }
        };

        try {
          const engineersResponse = await fetchEngineers(
            fetchAuditEngineersQuery,
            abortController.signal
          );

          if (isMounted.current) {
            setJobMovementEngineers(
              engineersResponse.data.items.map(
                ({ id, firstName, lastName }) => ({
                  id,
                  firstName,
                  lastName
                })
              )
            );
          }
        } catch (error) {
          commonErrorHandling(error);
        }
      }
    })();

    return () => {
      abortController.abort();
      isMounted.current = false;
    };
  }, []);

  const rows = auditEntriesToRows(
    auditData,
    jobMovementEngineers,
    flags.aegisEnableSupportJobMovementAuditRecords
  ).map(({ time, action, actionedBy }) => (
    <TableRow key={action + time}>
      <TableCell>{time}</TableCell>
      <TableCell className={actionCell}>{action}</TableCell>
      <TableCell>{actionedBy}</TableCell>
    </TableRow>
  ));

  return (
    <Table
      columnHeaders={columnHeaders}
      columnWidths={columnWidths}
      rows={rows}
    />
  );
}
