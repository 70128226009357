import React from 'react';
import { makeStyles, Grid } from '@material-ui/core';
import { JobSheetData, JobSheetHeaderData } from 'models/JobDetails';
import { Tabs, TabPanels, TabsProvider } from 'components/Tabs/Tabs';
import CustomButton, { DefaultButton } from 'components/Button';
import { AppointmentStatus } from 'models/Appointment';
import { WorkflowSectionData } from 'models/Workflow';
import { Audit } from 'models/Audit';
import AtRiskButton from 'components/AtRiskButton/AtRiskButton';
import Typography from 'components/Typography';
import semanticColours from 'styles/colours';
import OpenScheduleButton from 'components/OpenScheduleButton/OpenScheduleButton';
import JobSheetHeader from './JobSheetHeader/JobSheetHeader';
import WorkflowPane from './WorkflowPane/WorkflowPane';
import { JobDetailsPanel } from './JobDetailsPanel';
import { CanCancelStatus, useCanCancel } from './useCanCancel';
import { HistoryPane } from './HistoryPane/HistoryPane';

export const useStyles = makeStyles(theme => ({
  // TECH DEBT: FSP-101 - Create page component - https://ovotech.atlassian.net/browse/FSP-101
  jobDetailsSheetContainer: {
    position: 'relative',
    width: theme.containers.small,
    boxShadow:
      '0px 0px 2px rgba(0, 0, 0, 0.14), 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2)',
    borderRadius: 4,
    backgroundColor: theme.palette.common.white
  },
  sheetHeaderWrapper: {
    top: 0,
    padding: theme.spacing(2, 2, 0),
    background: theme.palette.common.white,
    zIndex: 10
  },
  detailsContainer: {
    margin: theme.spacing(0, 2, 2, 2)
  },
  rowContainer: {
    padding: 0,
    background: theme.palette.common.white
  },
  title: {
    marginBottom: theme.spacing(2)
  },
  headerButton: {
    margin: theme.spacing(0, 1, 0, 0),
    height: 36,
    minWidth: 'auto',
    boxShadow: `0px 0px 2px rgba(0, 0, 0, 0.14), 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2)`
  },
  jobSheetTabs: {
    margin: theme.spacing(2, -2, 0)
  },
  row: {
    padding: '6px 0',
    display: 'flex',
    justifyContent: 'space-between'
  },
  sectionLabel: {
    margin: 0,
    padding: theme.spacing(1.5, 0, 1.5, 0)
  },
  textAreaRow: {
    padding: 0,
    marginTop: 6,
    marginBottom: 6,
    display: 'inline-block'
  },
  rowLabel: {
    color: theme.palette.common.black,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  rowValue: {
    color: theme.palette.text.secondary,
    maxWidth: '100%'
  },
  rowValueOnClick: {
    color: theme.palette.text.secondary,
    maxWidth: '100%',
    cursor: 'pointer',
    textDecoration: 'underline'
  },
  sheetHeader: {
    display: 'grid',
    gridGap: theme.spacing(1),
    gridAutoColumns: 'min-content',
    gridAutoFlow: 'column',
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(1)
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2)
  },
  editButton: {
    margin: 0,
    width: 100,
    minWidth: 100,
    height: 36,
    minHeight: 36
  },
  jobDetailsPanel: {
    padding: theme.spacing(0, 2, 2, 2)
  },
  divider: {
    borderBottom: `1px solid ${theme.palette.aegis.semantic.background.canvas}`,
    '&:last-child': {
      border: 'none'
    }
  },
  labelIcon: {
    color: semanticColours.message.dark,
    '& > .info': {
      color: semanticColours.message.dark
    },
    '& > .vulnerable': {
      color: semanticColours.warning.base
    }
  },
  linkUrl: {
    color: theme.palette.aegis.semantic.message.dark
  },
  cancelButton: {
    marginRight: theme.spacing(1.5)
  }
}));

export interface JobDetailsSheetProps {
  sheetData: JobSheetData[];
  headerData: JobSheetHeaderData;
  auditData: Audit[];
  openEditModal: () => void;
  openAbortModal: () => void;
  openCancelModal: () => void;
  workflowData: WorkflowSectionData[];
  jobId: string;
  scheduleDate: string;
  patch?: string;
  reloadJobDetails: () => void;
  assignedEngineerId?: string;
}

const hideAbortButtonCases = [
  AppointmentStatus.ABORTED,
  AppointmentStatus.CANCELLED,
  AppointmentStatus.COMPLETED
];

const hideAtRiskButtonCases = hideAbortButtonCases;

export default function JobDetailsSheet(props: JobDetailsSheetProps) {
  const {
    sheetData,
    headerData,
    workflowData,
    auditData,
    openEditModal,
    openAbortModal,
    openCancelModal,
    jobId,
    scheduleDate,
    patch,
    reloadJobDetails,
    assignedEngineerId
  } = props;
  const classes = useStyles();

  const showAtRiskButton =
    !hideAtRiskButtonCases.includes(headerData.status) || headerData.atRisk;

  const canBeCancelledOrAborted = useCanCancel(jobId);

  const canAbort =
    canBeCancelledOrAborted !== CanCancelStatus.UNAVAILABLE
      ? canBeCancelledOrAborted === CanCancelStatus.ABORTABLE
      : false;

  const tabPanels = [
    <JobDetailsPanel key="jobPanel" data={sheetData} />,
    <WorkflowPane
      key="workflowPanel"
      sections={workflowData}
      status={headerData.status}
    />,
    <HistoryPane key="historyPanel" auditData={auditData} />
  ];

  const showCancelButton =
    canBeCancelledOrAborted !== CanCancelStatus.UNAVAILABLE
      ? canBeCancelledOrAborted === CanCancelStatus.CANCELLABLE
      : false;

  return (
    <Grid className={classes.jobDetailsSheetContainer}>
      <TabsProvider
        tabs={[
          { label: 'Details' },
          { label: 'Workflow' },
          { label: 'History' }
        ]}
      >
        <div className={classes.sheetHeaderWrapper}>
          <div className={classes.titleWrapper}>
            <Typography variant="h1">Job Sheet</Typography>
            <CustomButton
              className={classes.editButton}
              color="primary"
              testId="editJobButton"
              size="medium"
              onClick={openEditModal}
            >
              Edit job
            </CustomButton>
          </div>
          {headerData && (
            <JobSheetHeader
              assignedEngineerId={assignedEngineerId}
              headerData={headerData}
            />
          )}
          {canAbort && (
            <DefaultButton
              className={classes.headerButton}
              size="medium"
              onClick={openAbortModal}
              testId="jobDetailsAbortButton"
            >
              Abort
            </DefaultButton>
          )}
          {showCancelButton && (
            <DefaultButton
              className={`${classes.headerButton} ${classes.cancelButton}`}
              size="medium"
              onClick={openCancelModal}
              testId="jobDetailsCancelButton"
            >
              Cancel
            </DefaultButton>
          )}
          {showAtRiskButton && (
            <AtRiskButton
              appointmentId={jobId}
              className={classes.headerButton}
              atRisk={headerData.atRisk}
              data-testid="atRiskButton"
              onSuccess={reloadJobDetails}
              variant="outlined"
              size="medium"
            />
          )}
          {patch && scheduleDate && (
            <OpenScheduleButton
              scheduleDate={scheduleDate}
              patch={patch}
              assignedEngineerId={assignedEngineerId}
              className={classes.headerButton}
              data-testid="openScheduleButton"
              variant="outlined"
              size="medium"
            />
          )}
          <Tabs className={classes.jobSheetTabs} />
        </div>
        <TabPanels>{tabPanels}</TabPanels>
      </TabsProvider>
    </Grid>
  );
}
