import React, { memo, useEffect } from 'react';
import ReactMapGL, { NavigationControl } from 'react-map-gl';
import { makeStyles, Theme } from '@material-ui/core';
import geoViewport from '@mapbox/geo-viewport';
import { MapboxProps } from 'react-map-gl/src/mapbox/mapbox';

const defaultMapBoxProps: MapboxProps = {
  mapStyle: 'mapbox://styles/mapbox/streets-v11?optimize=true',
  zoom: 5,
  width: '100%',
  height: '100%',
  latitude: 51.509865,
  longitude: -0.118092
};

export type Bounds = {
  W: number;
  S: number;
  E: number;
  N: number;
};

interface SimpleMapProps {
  children?: JSX.Element[];
  initialBounds: Bounds;
}

// this is the breakpoint when the map info changes its size and position
const mapInfoBreakpoint = 1532;

const useStyles = makeStyles((theme: Theme) => ({
  mapContainer: {
    height: '100%'
  },
  navigation: {
    position: 'absolute',
    bottom: 45,
    right: 10,
    [theme.breakpoints.up(mapInfoBreakpoint)]: {
      bottom: 30
    }
  }
}));

const SimpleMap = ({ children, initialBounds }: SimpleMapProps) => {
  const classes = useStyles();
  const [viewport, setViewport] = React.useState(defaultMapBoxProps);
  const container = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (container?.current) {
      const initialZoom = geoViewport.viewport(
        [initialBounds.W, initialBounds.S, initialBounds.E, initialBounds.N],
        [container.current.offsetWidth, container.current.offsetHeight],
        1,
        20,
        512,
        true
      );
      setViewport({
        ...viewport,
        latitude: initialZoom.center[1],
        longitude: initialZoom.center[0],
        zoom: initialZoom.zoom - 0.3 // for some padding
      });
    }
  }, [container?.current]);

  return (
    <div
      className={classes.mapContainer}
      data-testid="scheduleMap"
      data-qa-id="scheduleMap"
      ref={container}
    >
      <ReactMapGL
        data-testid="scheduleMapView"
        data-qa-id="scheduleMapView"
        {...viewport}
        onViewportChange={(viewportChange: MapboxProps) =>
          setViewport({ ...defaultMapBoxProps, ...viewportChange })
        }
        width="100%"
        height="100%"
        mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_KEY}
      >
        {children}
        <div className={classes.navigation}>
          <NavigationControl />
        </div>
      </ReactMapGL>
    </div>
  );
};

export default memo(SimpleMap);
