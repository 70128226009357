import * as React from 'react';

const PublishButton = () => {
  return (
    <svg width={15} height={16} viewBox="0 0 15 16" fill="none">
      <path d="M.5 0v2h14V0H.5zm0 10h4v6h6v-6h4l-7-7-7 7z" fill="#555C6B" />
    </svg>
  );
};

export default PublishButton;
