import React from 'react';
import { makeStyles, TableRow, TableRowProps } from '@material-ui/core';
import { useTableStyles } from './ListTable';

interface ListTableRowProps extends TableRowProps {
  'data-testid'?: string;
}

const useStyles = makeStyles(() => ({
  tableRow: {
    cursor: (props: ListTableRowProps) => (props.onClick ? 'pointer' : 'auto')
  }
}));

export default function ListTableRow(props: ListTableRowProps) {
  const tableClasses = useTableStyles();
  const classes = useStyles(props);

  return (
    <TableRow
      {...props}
      hover
      className={`${tableClasses.tableRow} ${classes.tableRow}`}
    >
      {props.children}
    </TableRow>
  );
}
