import React, { memo } from 'react';
import {
  makeStyles,
  FormControl,
  Chip,
  TextField,
  ThemeProvider,
  createTheme
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { mapGeographicAreasChildrenToAutocompleteValues } from 'components/Forms';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';
import { GeographicAreaParent } from 'models/GeographicArea';
import dateTimeTheme from 'components/Forms/DateTime/theme';
import { DATE_WITH_SLASH_FORMAT } from 'constants/dates';
import LuxonUtils from '@date-io/luxon';
import { ScheduleFilters } from './ScheduleContainer';

const dateDropdownWidth = 200;
const areaDropDownWidth = 403;

const useStyles = makeStyles(theme => ({
  date: {
    width: dateDropdownWidth
  },
  dateInput: {
    marginTop: 0,
    marginBottom: 0
  },
  areas: {
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: 0,
    marginLeft: theme.spacing(2),
    backgroundColor: theme.palette.common.white,
    zIndex: 1
  },
  areaInput: {
    marginTop: 0,
    marginBottom: 0,
    maxWidth: `${areaDropDownWidth}px`
  }
}));

interface SelectScheduleProps {
  areas: string[];
  date: string;
  setFilters: (value: ScheduleFilters) => void;
  allAreas: GeographicAreaParent[];
}

export const SelectSchedule = memo(
  ({ date, areas, setFilters, allAreas }: SelectScheduleProps) => {
    const classes = useStyles();

    const areaOptions = mapGeographicAreasChildrenToAutocompleteValues(
      allAreas
    );

    const selectedAreas = areaOptions.filter(option =>
      areas.includes(option.value)
    );

    return (
      <>
        <FormControl className={classes.date} margin="none">
          <ThemeProvider theme={createTheme(dateTimeTheme)}>
            <MuiPickersUtilsProvider utils={LuxonUtils}>
              <KeyboardDatePicker
                inputProps={{
                  'data-testid': 'selectScheduleDate'
                }}
                onChange={value => {
                  if (!value || value.isValid) {
                    setFilters({
                      date: value?.toISODate() || '',
                      areas
                    });
                  }
                }}
                inputVariant="outlined"
                value={date || null}
                format={DATE_WITH_SLASH_FORMAT}
                variant="inline"
                label="Date"
                autoOk
              />
            </MuiPickersUtilsProvider>
          </ThemeProvider>
        </FormControl>
        <FormControl className={classes.areas} margin="none">
          <Autocomplete
            multiple
            limitTags={1}
            renderTags={(value, getTagProps) => {
              return value.map((option, index) => (
                <Chip
                  {...option}
                  {...getTagProps({ index })}
                  key={option.value}
                  label={option.label}
                />
              ));
            }}
            renderInput={params => (
              <TextField
                {...params}
                data-testid="selectScheduleAreas"
                className={classes.areaInput}
                label="Area"
                fullWidth
                margin="normal"
                variant="outlined"
              />
            )}
            filterSelectedOptions
            groupBy={option => String(option.other)}
            getOptionDisabled={() => selectedAreas.length >= 4}
            options={areaOptions}
            getOptionLabel={option => option.label || ''}
            value={selectedAreas}
            onChange={(e, value) => {
              if (typeof value !== 'string') {
                setFilters({ date, areas: value.map(area => area.value) });
              }
            }}
          />
        </FormControl>
      </>
    );
  }
);

SelectSchedule.displayName = 'SelectSchedule';
