import http from 'api/http';
import { Audit } from 'models/Audit';
import { paramsSerializer } from 'store/utils';
import { iteratePagination } from 'store/utils/pagination';
import { Page } from 'models/Page';
import { AUDIT_PATH } from 'constants/audit';

function getAuditData(domainId: string) {
  return function getData(params: Record<string, any>) {
    return http.get<Page<Audit>>(`${AUDIT_PATH}/${domainId}`, {
      params,
      paramsSerializer
    });
  };
}

/**
 * Fetches audit data from the audit service. Doesn't do error handling - this should be done within scope where called
 * @param {AuditDomain} domain - The domain of the entity being interrogated for audit data
 * @param {string} domainId - The id of the entity within it's domain
 */
export async function fetchAuditData(domainId: string): Promise<Audit[]> {
  return iteratePagination<Audit>(undefined, getAuditData(domainId));
}
