import React, { ReactElement } from 'react';
import Adjustment, { AdjustmentType } from 'models/Adjustment';
import ShiftPattern from 'models/ShiftPattern';
import AdjustmentComponent from 'components/Adjustment';
import Timetable from 'components/Timetable';
import Patch from 'models/Patches';
import { CrudCurriedFn } from 'components/Crud';
import Typography from 'components/Typography';

const standardEfficiency = 4;
const efficiencyToPercentage = (efficiency: number): number => efficiency * 100;
const efficiencyToJobsPerDay = (efficiency: number): number =>
  efficiency * standardEfficiency;

function AdjustmentWrapper({
  adjustments,
  type,
  defaultText,
  children,
  onDelete,
  onEdit
}: {
  adjustments: Adjustment[];
  type: string;
  defaultText: string;
  children: (adjustment: Adjustment) => ReactElement;
  onDelete: CrudCurriedFn;
  onEdit?: (id: string, type: string) => void;
}) {
  return adjustments.length > 0 ? (
    <>
      {adjustments.map((adjustment: Adjustment) => (
        <AdjustmentComponent
          qaId={`${type}Adjustment`}
          adjustment={adjustment}
          key={`${type}-adjustment-${adjustment.id}`}
          onDelete={onDelete}
          onEdit={onEdit}
        >
          {children(adjustment)}
        </AdjustmentComponent>
      ))}
    </>
  ) : (
    <Typography paragraph>{defaultText}</Typography>
  );
}

// The adjustment wrapper is for the engineer page only so I haven't put it in a 'component' folder
export const EfficiencyAdjustmentsContainer = ({
  adjustments,
  onDelete,
  onEdit
}: {
  adjustments: Adjustment[];
  onDelete: CrudCurriedFn;
  onEdit?: (id: string, type: string) => void;
}) => (
  <AdjustmentWrapper
    adjustments={adjustments}
    defaultText="No efficiency adjustments"
    type="efficiency"
    onDelete={onDelete}
    onEdit={onEdit}
  >
    {adjustment => {
      const adjustmentChange = adjustment.changes.find(
        el => el.field === AdjustmentType.Efficiency
      )?.change as number;

      const efficiency = `${efficiencyToPercentage(
        adjustmentChange
      )}% - ${efficiencyToJobsPerDay(adjustmentChange)} jobs per day`;
      return <Typography>{`Efficiency: ${efficiency}`}</Typography>;
    }}
  </AdjustmentWrapper>
);
export const PatchAdjustmentsContainer = ({
  adjustments,
  patches,
  onDelete,
  onEdit
}: {
  adjustments: Adjustment[];
  patches: Patch[];
  onDelete: CrudCurriedFn;
  onEdit?: (id: string, type: string) => void;
}) => (
  <AdjustmentWrapper
    adjustments={adjustments}
    defaultText="No patch adjustments"
    type="patch"
    onDelete={onDelete}
    onEdit={onEdit}
  >
    {adjustment => {
      const patch = patches.find(
        (item: Patch) =>
          item.id ===
          adjustment.changes.find(el => el.field === AdjustmentType.Patch)
            ?.change
      );
      const text = patch ? patch.name : 'Loading patch details';
      return <div> {text} </div>;
    }}
  </AdjustmentWrapper>
);

export const ShiftPatternAdjustmentsContainer = ({
  adjustments,
  onDelete,
  onEdit
}: {
  adjustments: Adjustment[];
  onDelete: CrudCurriedFn;
  onEdit?: (id: string, type: string) => void;
}) => (
  <AdjustmentWrapper
    adjustments={adjustments}
    defaultText="No schedule adjustments"
    type="shiftPattern"
    onDelete={onDelete}
    onEdit={onEdit}
  >
    {adjustment => {
      return (
        <Timetable
          color="none"
          nested
          qaId="scheduleAdjustment"
          shiftPattern={adjustment.changes[0].change as ShiftPattern}
        />
      );
    }}
  </AdjustmentWrapper>
);
