import http from 'api/http';

import { appointmentDomain } from 'models';
import { AppointmentCanCancelResponse } from 'models/Appointment';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setSnackbar, SnackbarStatus } from 'store/slices/snackbar';
import { commonErrorHandling } from 'store/utils';

export enum CanCancelStatus {
  CANCELLABLE,
  ABORTABLE,
  UNAVAILABLE
}

export const useCanCancel = (jobId: string) => {
  const [canCancel, setCanCancel] = useState<CanCancelStatus | null>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (canCancel == null) {
      (async () => {
        try {
          const {
            data: { canBeCancelled, canBeAborted }
          } = await http.get<AppointmentCanCancelResponse>(
            `${appointmentDomain.apiPath}/${jobId}/can-cancel`
          );

          if (!canBeCancelled && !canBeAborted) {
            setCanCancel(null);
            return;
          }
          if (canBeCancelled && !canBeAborted) {
            setCanCancel(CanCancelStatus.CANCELLABLE);
          } else {
            setCanCancel(CanCancelStatus.ABORTABLE);
          }
        } catch (error) {
          const errorCode = error.response?.status || '';

          if (errorCode === 403) {
            setCanCancel(CanCancelStatus.UNAVAILABLE);
            return;
          }

          commonErrorHandling(error, appointmentDomain.type);

          dispatch(
            setSnackbar({
              title: `Error ${errorCode}`,
              message: 'Unable to check if appointment can be cancelled',
              show: true,
              status: SnackbarStatus.Error
            })
          );
        }
      })();
    }
  }, [canCancel]);

  return canCancel;
};
