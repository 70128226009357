import { ReactElement, SyntheticEvent } from 'react';
import { Domain, DomainItem } from 'models/Domain';
import * as Yup from 'yup';
import { PaginationOptions, QueryParams } from 'store/utils';
import { FormValues, InputFieldProps } from 'components/Forms';

export interface ListResponse<T> {
  items: T[];
  page: {
    pageIndex: number;
    pageSize: number;
    totalItems: number;
    totalPages: number;
  };
}
export interface CrudViewProps {
  domain: Domain;
  title?: string;
  fields: InputFieldProps[];
  initialValues?: FormValues;
  validationSchema: Yup.ObjectSchema<any, any>;
  children?: (data: DomainItem[] | null) => ReactElement | null;
  presubmitCallback?: (value: FormValues) => FormValues;
  processDomainData?: (item: DomainItem) => FormValues;
}

export enum SortByOrder {
  ASCENDING = 'ASC',
  DESCENDING = 'DESC'
}

export interface SortByOptions {
  field: string;
  order: SortByOrder;
}

export type CrudViewListProps = Omit<
  CrudViewProps,
  'fields' | 'validationSchema' | 'children'
> & {
  children?: ({
    onEdit,
    onDelete,
    onView
  }: {
    onEdit: CrudCurriedFn;
    onDelete: CrudCurriedFn;
    onView: CrudCurriedFn;
  }) => ReactElement | null;
  filter?: QueryParams;
  sortBy?: SortByOptions;
  paginated?: boolean;
  showPageHeader?: boolean;
  autoLoad?: boolean;
  paginationOptions?: PaginationOptions;
  dispatchProps?: any[];
  loadingDependency?: boolean;
  disableErrorDisplay?: boolean;
};

export type CrudViewReadProps = Omit<
  CrudViewProps,
  'fields' | 'validationSchema' | 'children'
> & {
  children?: ({
    onEdit,
    onDelete
  }: {
    onEdit: CrudCurriedFn;
    onDelete: CrudCurriedFn;
  }) => ReactElement | null;
};

export type ApiResponseViewState = null | {
  status: { message: string };
};
export type CrudCurriedFn = (
  domainItem: DomainItem,
  overrideUrl?: string
) => (event: SyntheticEvent<any>) => void;
