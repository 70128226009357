import React from 'react';

import { makeStyles, createStyles, Theme, alpha } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Paper from '@material-ui/core/Paper';
import ListItemText from '@material-ui/core/ListItemText';
import {
  NavLink as RouterLink,
  LinkProps as RouterLinkProps
} from 'react-router-dom';

import navigationSections from 'views/Dashboard/routes';
import Typography from 'components/Typography';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface ListItemLinkProps {
  primary: string;
  to: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    activeLink: {
      textDecoration: 'none',
      backgroundColor: alpha(theme.palette.common.white, 0.3),
      color: theme.palette.common.white,
      '&:hover': {
        backgroundColor: theme.palette.primary.dark
      }
    },
    linkContainer: {
      padding: theme.spacing(0.75, 1),
      marginBottom: 2,
      borderRadius: 3,
      '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.2)
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.common.white, 0.4)
      }
    },
    section: {
      marginBottom: theme.spacing(4),
      '&:first-child': {
        marginBottom: 0
      }
    },
    sectionLabel: {
      marginTop: 30,
      padding: theme.spacing(0, 1, 0.25, 1)
    },
    navBarPaper: {
      width: '100%',
      backgroundColor: 'transparent',
      padding: theme.spacing(6, 2, 0),
      color: theme.palette.aegis.semantic.message.white
    },
    link: {
      margin: 0
    }
  })
);

function ListItemLink({ primary, to }: ListItemLinkProps) {
  const classes = useStyles();

  const RenderLink = React.useMemo(
    () =>
      // eslint-disable-next-line react/display-name
      React.forwardRef<
        HTMLAnchorElement,
        Omit<RouterLinkProps, 'innerRef' | 'to'>
      >((itemProps, ref) => (
        <RouterLink
          to={to}
          {...itemProps}
          innerRef={ref}
          exact={to === '/dashboard'}
          activeClassName={classes.activeLink}
        />
      )),
    [to, classes.activeLink]
  );

  const [open, setOpen] = React.useState(true);
  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <ListItem
        button
        component={RenderLink}
        onClick={handleClick}
        className={classes.linkContainer}
      >
        <ListItemText
          className={classes.link}
          primary={<Typography>{primary}</Typography>}
        />
      </ListItem>
    </>
  );
}

export default function Navbar() {
  const classes = useStyles();
  const flags = useFlags();

  return (
    <Paper elevation={0} className={classes.navBarPaper}>
      <List
        aria-label="main mailbox folders"
        data-qa-id="dashboardNavigation"
        data-testid="dashboardNavigation"
      >
        {navigationSections(
          flags.aegisEnableGenericModalForJobTypeListPage,
          flags.aegisEnableNewEngineerModal,
          flags.aegisEnableHideCapacityPage
        ).map(section => (
          <div key={JSON.stringify(section)} className={classes.section}>
            {section.label && (
              <ListItem className={classes.sectionLabel}>
                <Typography variant="h2">{section.label}</Typography>
              </ListItem>
            )}
            {section.routes.map(({ label, ...props }) =>
              label == null ? null : (
                <li key={label}>
                  <ListItemLink primary={label} {...props} />
                </li>
              )
            )}
          </div>
        ))}
      </List>
    </Paper>
  );
}
