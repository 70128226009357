import { DateTime } from 'luxon';
import { FormValues } from 'components/Forms';
import { API_LOCAL_DATE_TIME_FORMAT } from 'constants/dates';
import { AbortDetails, AppointmentStatus } from 'models/Appointment';
import { Domain, DomainItem } from 'models/Domain';
import Engineer from 'models/Engineers';
import {
  EnrichedResponseNote,
  EnrichedResponseSection,
  JobDetailsResponse
} from 'models/JobDetails';
import Patch from 'models/Patches';
import { EngineerSchedule } from 'models/Schedule';
import { WorkflowSectionData } from 'models/Workflow';
import { StateSlice } from 'store/slices';
import { capitalise, memoize } from 'utils';

export const formatSkillsRequestBody = (data: FormValues) => {
  const { category: categoryId, ...formData } = data;
  return { categoryId, ...formData };
};

export type QueryFilterOperators = '=' | '%' | 'in' | '!=';

export type QueryParams = Record<
  string,
  { values: string[]; operator: QueryFilterOperators }
>;

export type ApiQueryParams = Record<string, string>;

export type PaginationOptions<T = DomainItem> = Pick<
  StateSlice<T>['page'],
  'pageIndex' | 'pageSize'
>;

export const createUrl = (
  domain: Domain,
  parentId?: string | null,
  id?: string
) => {
  const domainUrl = id ? `/${domain.apiPath}/${id}` : `/${domain.apiPath}`;
  let parentUrl;

  if (parentId && domain.parentDomain?.fullPath) {
    parentUrl = `/${domain.parentDomain?.fullPath}/${parentId}`;
  } else if (parentId && domain.parentDomain) {
    parentUrl = `/${domain.parentDomain.apiPath}/${parentId}`;
  } else {
    parentUrl = '';
  }

  return parentUrl + domainUrl;
};

interface SerialisableFilterQuery {
  params?: FilterByQueryParams;
  pageSize?: number;
  pageIndex?: number;
}

export interface FilterByQueryParams {
  filterBy: string[];
}

export const paramsSerializer = (query: SerialisableFilterQuery): string => {
  const { params, ...nonFilterParams } = query;

  const serialisedNonFilterParams = nonFilterParams
    ? Object.entries(nonFilterParams).map(
        ([key, value]: [string, string | number]) => `${key}=${value}`
      )
    : '';

  const serialisedFilterParams =
    params?.filterBy.map(
      (param: string) => `filterBy=${encodeURIComponent(param)}`
    ) || [];

  const requestQueryParams = serialisedFilterParams
    .concat(serialisedNonFilterParams)
    .join('&');

  return requestQueryParams;
};

export const createFilterQuery = (query: QueryParams): FilterByQueryParams => {
  const filters = Object.entries(query).reduce(
    (
      acc: string[],
      [filter, { values, operator }]: [
        string,
        { operator: QueryFilterOperators; values: string[] }
      ]
    ) => {
      const queryString = values.map(
        (value: string) => `${filter}::${operator}::${value}`
      );
      return acc.concat(queryString);
    },
    []
  );

  return { filterBy: filters };
};

export const getPatchesNotInState = (
  patches: string[],
  statePatches: Patch[]
) =>
  patches.reduce((acc: any, curr: any) => {
    if (acc.includes(curr)) {
      return acc;
    }
    const statePatch = statePatches.find((patch: Patch) => patch.id === curr);
    return statePatch ? acc : acc.concat(curr);
  }, []);

// Pure function to sort the engineers by first name
export const sortEngineers = memoize((engineers: Engineer[]) => {
  return [...engineers].sort((current, next) =>
    current.firstName.localeCompare(next.firstName)
  );
});

/**
 * Sorts an array of engineer schedules and returns them in alphabetical order
 * by first name and if the first names are the same, then by last name. This function
 * is also memoised.
 * @param {EngineerSchedule[]} schedules - An array of engineer schedules to be sorted
 */
export const sortScheduleEngineers = memoize((schedules: EngineerSchedule[]) =>
  [...schedules].sort((current, next) => {
    const comparison = current.engineer.firstName.localeCompare(
      next.engineer.firstName
    );

    if (comparison !== 0) {
      return comparison;
    }

    return current.engineer.lastName.localeCompare(next.engineer.lastName);
  })
);

export const getNotesSections = (
  notes: EnrichedResponseNote[]
): WorkflowSectionData[] => {
  if (notes?.length) {
    return [
      {
        name: `Notes (${notes.length})`,
        questions: notes.map((note, index) => {
          return {
            questionId: `Note-${index}`,
            text: DateTime.fromFormat(
              note.createdAt,
              API_LOCAL_DATE_TIME_FORMAT
            ).toFormat('dd MMM yyyy - HH:mm'),
            answer: note.text,
            media: note.media?.map(m => m.url)
          };
        })
      }
    ];
  }
  return [];
};

export const getQuestionsSections = (
  sections: EnrichedResponseSection[]
): WorkflowSectionData[] => {
  if (sections?.length) {
    return sections.map(section => {
      return {
        name: section.description,
        questions: section.questions.map(question => {
          return {
            questionId: question.id,
            text: question.description,
            answer: question.text,
            media: question.media?.map(m => m.url)
          };
        })
      };
    });
  }
  return [];
};

export const getAbortDetailsSections = (
  abortDetails: AbortDetails | undefined,
  jobStatus: AppointmentStatus,
  isWorkflowDataPresent: boolean,
  aegisFetchPreviousAbortData?: boolean
): WorkflowSectionData[] => {
  const abortType =
    jobStatus === AppointmentStatus.CANCELLED ? 'cancellation' : 'abort';

  if (!abortDetails || (aegisFetchPreviousAbortData && isWorkflowDataPresent)) {
    return [];
  }

  return [
    {
      name: `${capitalise(abortType)} Details`,
      questions: [
        {
          questionId: `${abortType}-category`,
          text: `What's the ${abortType} category?`,
          answer: abortDetails.category
        },
        {
          questionId: `${abortType}-reason`,
          text: `What's the reason for the ${abortType}?`,
          answer: abortDetails.reason
        },
        ...(abortDetails.rebook !== null
          ? [
              {
                questionId: `${abortType}-rebookable`,
                text: `Can the job be rebooked?`,
                answer: abortDetails.rebook ? 'Yes' : 'No'
              }
            ]
          : []),
        {
          questionId: `${abortType}-comments`,
          text: `Comments`,
          answer: abortDetails.comments ? abortDetails.comments : 'No comments'
        }
      ]
    }
  ];
};

export const getWorkflowSections = (
  sections: EnrichedResponseSection[],
  notes: EnrichedResponseNote[],
  abortDetails: AbortDetails | undefined,
  jobStatus: AppointmentStatus,
  aegisFetchPreviousAbortData?: boolean
): WorkflowSectionData[] =>
  [
    ...getAbortDetailsSections(
      abortDetails,
      jobStatus,
      sections.length !== 0,
      aegisFetchPreviousAbortData
    ),
    ...getNotesSections(notes),
    ...getQuestionsSections(sections)
  ].filter(s => s.questions.length);

export const workflowData = (
  jobDetailsData: JobDetailsResponse | undefined,
  aegisFetchPreviousAbortData?: boolean
): WorkflowSectionData[] => {
  if (!jobDetailsData) return [];

  const sections = jobDetailsData?.enrichedJob?.data?.sections || [];
  const notes = jobDetailsData?.enrichedJob?.data?.notes || [];
  const abortDetails = jobDetailsData?.abortDetails;
  const jobStatus = jobDetailsData?.status;

  return getWorkflowSections(
    sections,
    notes,
    abortDetails,
    jobStatus,
    aegisFetchPreviousAbortData
  );
};
