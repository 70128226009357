import React from 'react';

import Button, { ButtonProps } from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core';

export type CustomButtonColour = 'primary' | 'secondary' | 'default';

const useButtonStyles = makeStyles(theme => ({
  defaultButton: {
    color: theme.palette.aegis.semantic.background.white,
    backgroundColor: theme.palette.aegis.semantic.message.dark,
    '&:active, &:hover': {
      backgroundColor: theme.palette.aegis.semantic.message.black
    }
  },
  errorButton: {
    color: theme.palette.aegis.semantic.background.white,
    backgroundColor: theme.palette.aegis.semantic.error.base,
    '&:hover': {
      backgroundColor: theme.palette.aegis.semantic.error.alt
    }
  }
}));

export interface CustomButtonProps extends ButtonProps {
  color?: CustomButtonColour;
  children?: React.ReactNode;
  className?: string;
  isErrorButton?: boolean;
  testId?: string;
}

function CustomButton({
  color = 'secondary',
  children,
  className,
  size = 'large',
  isErrorButton,
  testId,
  ...props
}: CustomButtonProps) {
  const classes = useButtonStyles();
  return (
    <Button
      className={`${className} ${isErrorButton ? classes.errorButton : ''}`}
      size={size}
      variant={color !== 'secondary' ? 'contained' : undefined}
      disableElevation={color === 'secondary'}
      color={color}
      data-testid={testId}
      {...props}
    >
      {children}
    </Button>
  );
}

export default CustomButton;

export function DefaultButton({
  testId,
  children,
  className,
  ...props
}: Omit<CustomButtonProps, 'isErrorButton'>) {
  const classes = useButtonStyles();
  return (
    <CustomButton
      color="default"
      {...props}
      className={
        className
          ? `${className} ${classes.defaultButton}`
          : classes.defaultButton
      }
      data-testid={testId}
    >
      {children}
    </CustomButton>
  );
}
