import React from 'react';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogProps,
  makeStyles,
  IconButton,
  Theme,
  DialogActions
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Typography from 'components/Typography';
import { FormApiWithRestart } from 'components/Forms/Form';

export interface FormRefProp {
  formRef: React.MutableRefObject<FormApiWithRestart | undefined>;
}

export interface ModalProps extends DialogProps {
  title: string;
  onClose: () => void;
  testId?: string;
  ariaDescription?: string;
  modalActions?: React.ReactNode;
  additionalButtons?: React.ReactNode;
}

export const useModalStyles = makeStyles<Theme, boolean>(
  theme => ({
    button: {
      padding: theme.spacing(1),
      '& .MuiSvgIcon-root': {
        fontSize: 24
      }
    },
    titleContainer: {
      display: 'flex'
    },
    title: {
      flexGrow: 1
    },
    previousButton: {
      marginRight: 'auto'
    }
  }),
  {
    name: 'Modal'
  }
);

/**
 * This component renders a customised Material UI modal dialog.
 * @param {string} title - The title of the modal
 * @param {boolean} open - Whether the modal should be displayed
 * @param {() => void} onClose - A callback that is invoked when the modal is closed
 * @param {React.ReactNode | React.ReactNode[]} children - The modal content
 * @param {string} [ariaDescription] - The accessible description of the modal
 * @param {string} [`testId`] - The test id for the modal
 * @param {React.ReactNode | React.ReactNode[]} [modalActions] - The modal actions content (buttons)
 * @example
 *  <Modal
      title="Example modal"
      data-testid="exampleModal"
      open={open}
      onClose={onClose}
      modalActions={
        <Button onClick={() => onClose()} data-testid="exampleModalOkBtn">
          Ok
        </Button>
      }
    >
      Some example modal content
    </Modal>
 */
export default function Modal({
  title,
  open,
  onClose,
  children,
  ariaDescription,
  testId,
  modalActions: ModalActions,
  additionalButtons
}: ModalProps) {
  const classes = useModalStyles();

  return (
    <Dialog
      // Required because without it you couldn't focus on the quatrix modal text box
      disableEnforceFocus
      data-testid={testId}
      open={open}
      onClose={onClose}
      aria-labelledby={`${testId}-title`}
      aria-describedby={`${testId}-description`}
    >
      <DialogTitle
        id={`${testId}-title`}
        data-testid={`${testId}Title`}
        disableTypography
        className={classes.titleContainer}
      >
        <Typography variant="h1" className={classes.title}>
          {title}
        </Typography>
        {additionalButtons}
        <IconButton
          aria-label="close"
          onClick={() => onClose()}
          className={classes.button}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      {ariaDescription && (
        <Typography
          id={`${testId}-description`}
          component="span"
          variant="srOnly"
        >
          {ariaDescription}
        </Typography>
      )}
      <DialogContent data-testid={`${testId}Content`}>{children}</DialogContent>
      <DialogActions>
        {typeof ModalActions !== 'function' ? ModalActions : <ModalActions />}
      </DialogActions>
    </Dialog>
  );
}
