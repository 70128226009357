import React, { memo } from 'react';
import { useTheme } from '@material-ui/core';
import polyline from '@mapbox/polyline';
import { Source, Layer, LayerProps } from 'react-map-gl';
import { getOpacity, MapElementType } from 'utils/mapElements';

const getRouteColor = (
  engineerId: string,
  selectedEngineer?: string
): string => {
  if (!selectedEngineer) {
    return useTheme().palette.schedule.map.route;
  }
  if (selectedEngineer === engineerId) {
    return useTheme().palette.schedule.map.selectedRoute;
  }
  return useTheme().palette.schedule.map.deselectedRoute;
};

const getRouteLineWidthInPx = (
  engineerId: string,
  selectedEngineer?: string
): number => (selectedEngineer === engineerId ? 3 : 2);

interface RouteProps {
  engineerId: string;
  geometry: string;
  selectedEngineer?: string;
}

const Route = ({ engineerId, geometry, selectedEngineer }: RouteProps) => {
  const opacity = getOpacity(
    MapElementType.ROUTE,
    selectedEngineer,
    engineerId
  );
  const color = getRouteColor(engineerId, selectedEngineer);
  const lineWidth = getRouteLineWidthInPx(engineerId, selectedEngineer);

  const layerProps: LayerProps = {
    id: `route-${engineerId}`,
    type: 'line',
    paint: {
      'line-color': color,
      'line-width': lineWidth,
      'line-opacity': opacity
    }
  };

  return (
    <div
      key={`route-${engineerId}`}
      data-testid="mapRoute"
      data-qa-id="mapRoute"
      style={{ opacity }}
    >
      <Source
        key={`route-${engineerId}`}
        type="geojson"
        data={polyline.toGeoJSON(geometry)}
      >
        <Layer data-testid="mapRoute" data-qa-id="mapRoute" {...layerProps} />
      </Source>
    </div>
  );
};

export default memo(Route);
