import React, { useState } from 'react';
import ListView from 'components/Crud/views/List';
import { engineerDomain, jobTypeDomain } from 'models';
import { TableCell } from '@material-ui/core';
import { useJobTypesData } from 'store/selectors';
import {
  useTableStyles,
  useTableRowActionsState,
  ListTable,
  TableActions
} from 'components/Crud/ListTable/ListTable';
import { arrayToCsvString } from 'utils';
import ListTableRow from 'components/Crud/ListTable/ListTableRow';
import JobType from 'models/JobTypes';
import { useFlags } from 'launchdarkly-react-client-sdk';
import JobTypesModal from './JobTypesModal/JobTypesModal';

const JobTypesView = () => {
  const jobTypesData = useJobTypesData();
  const classes = useTableStyles();
  const { rowHovered, setRowHovered } = useTableRowActionsState();

  const columnsWidth = [300, 120, 160, 160, 260];
  const headers = ['Name', 'Duration', 'Type', 'Time band', 'Required skills'];

  const flags = useFlags();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentJobType, setCurrentJobType] = useState<JobType>();

  const openModal = () => setIsModalOpen(true);

  const cancelModal = () => {
    setIsModalOpen(false);
    setCurrentJobType(undefined);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setCurrentJobType(undefined);
  };

  return (
    <>
      <ListView domain={jobTypeDomain} showPageHeader={false}>
        {({ onEdit, onDelete }) => (
          <section className={classes.tableContainer}>
            <ListTable
              domain={jobTypeDomain}
              domainItems={jobTypesData}
              columnsWidth={columnsWidth}
              setRowHovered={(row: number | boolean) => setRowHovered(row)}
              rowHovered={rowHovered}
              title={jobTypeDomain.label}
              label={`A table of ${engineerDomain.label} ${jobTypeDomain.plural}`}
              headers={headers}
              headerButtons={
                flags.aegisEnableGenericModalForJobTypeListPage
                  ? [
                      {
                        label: 'New job type',
                        openModal
                      }
                    ]
                  : undefined
              }
            >
              {jobTypesData.map((jobType, index) => (
                <ListTableRow
                  key={jobType.id}
                  data-testid={`tableRow-${jobType.id}`}
                >
                  <TableCell className={classes.tableCell}>
                    {jobType.name}
                  </TableCell>
                  <TableCell className={classes.tableCell} align="right">
                    {jobType.defaultLength}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {jobType.installationType}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {jobType.timeBand.name}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {arrayToCsvString(
                      jobType.requiredSkills.map(skill => skill.name)
                    )}
                  </TableCell>
                  {rowHovered !== index ? (
                    <TableCell className={classes.tableCell}>&nbsp;</TableCell>
                  ) : (
                    <TableActions
                      onEdit={onEdit}
                      onDelete={onDelete}
                      domain={jobTypeDomain}
                      domainItem={jobType}
                    />
                  )}
                </ListTableRow>
              ))}
            </ListTable>
          </section>
        )}
      </ListView>
      {flags?.aegisEnableGenericModalForJobTypeListPage ? (
        <JobTypesModal
          open={isModalOpen}
          closeModal={closeModal}
          currentJobType={currentJobType}
          cancel={cancelModal}
        />
      ) : null}
    </>
  );
};

export default JobTypesView;
