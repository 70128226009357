import GenericDataType from './GenericDataType';

export interface ShiftPatternDay {
  startTime: string;
  endTime: string;
}

export type OptionalShiftPatternDay = ShiftPatternDay | null;

export enum Weekdays {
  Monday = 'monday',
  Tuesday = 'tuesday',
  Wednesday = 'wednesday',
  Thursday = 'thursday',
  Friday = 'friday',
  Saturday = 'saturday',
  Sunday = 'sunday'
}
export default interface ShiftPattern extends GenericDataType {
  name: string;
  [Weekdays.Monday]: OptionalShiftPatternDay;
  [Weekdays.Tuesday]: OptionalShiftPatternDay;
  [Weekdays.Wednesday]: OptionalShiftPatternDay;
  [Weekdays.Thursday]: OptionalShiftPatternDay;
  [Weekdays.Friday]: OptionalShiftPatternDay;
  [Weekdays.Saturday]: OptionalShiftPatternDay;
  [Weekdays.Sunday]: OptionalShiftPatternDay;
}
