import http from 'api/http';
import { engineerDomain } from 'models';
import Engineer from 'models/Engineers';
import { StateSlice } from 'store/slices';
import { createFilterQuery, QueryParams } from 'store/utils';
import { paramsSerializer } from 'store/utils/utils';

export async function fetchEngineers(query: QueryParams, signal: AbortSignal) {
  const params = { params: createFilterQuery(query) };

  return http.get<Omit<StateSlice<Engineer>, 'isLoading' | 'error'>>(
    engineerDomain.apiPath,
    {
      params,
      paramsSerializer,
      signal
    }
  );
}
