import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';
import { makeStyles, ThemeProvider, createTheme } from '@material-ui/core';
import dateTimeTheme from 'components/Forms/DateTime/theme';
import React, { useEffect, useState } from 'react';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { DATE_WITH_SLASH_FORMAT } from 'constants/dates';
import LuxonUtils from '@date-io/luxon';
import { DateTime } from 'luxon';

export const dateRangeSplitString = ' - ';

export const dateRangeToString = (range: DateTime[]): string => {
  const [startDate, endDate] = range.map(date =>
    date.toFormat(DATE_WITH_SLASH_FORMAT)
  );

  if (startDate === endDate) {
    return startDate;
  }

  return [startDate, endDate].join(dateRangeSplitString);
};

export const dateRangeStringToLuxon = (range: string): DateTime[] => {
  const [fromDate, toDate] = range.split(dateRangeSplitString);

  // If the start and end date are the same we will only get one date, and the split
  // will only return one entry. This will break when adding the filters
  return fromDate && toDate
    ? [fromDate, toDate].map(date => {
        return DateTime.fromFormat(date, DATE_WITH_SLASH_FORMAT) as any;
      })
    : [fromDate, fromDate].map(date => {
        return DateTime.fromFormat(date, DATE_WITH_SLASH_FORMAT) as any;
      });
};

interface DateRangeFieldProps {
  filterValue: string;
  onSubmit: (filterValue: string) => void;
}

const useStyles = makeStyles(theme => ({
  datepicker: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(2)
  },
  datepickerfield: {
    margin: 0,
    width: '100%'
  }
}));

const DateRangeField = (props: DateRangeFieldProps) => {
  const { filterValue, onSubmit } = props;
  const [dateRangeStart, dateRangeEnd] = dateRangeStringToLuxon(filterValue);

  const classes = useStyles();

  const [startDate, setStartDate] = useState(
    dateRangeStart?.isValid ? dateRangeStart : null
  );
  const [endDate, setEndDate] = useState(
    dateRangeEnd?.isValid ? dateRangeEnd : null
  );
  const [endDateEntered, setEndDateEntered] = useState(false);

  useEffect(() => {
    if (
      endDateEntered &&
      startDate?.isValid &&
      endDate?.isValid &&
      !(endDate < startDate)
    ) {
      onSubmit(dateRangeToString([startDate, endDate]));
    }
  }, [startDate, endDate, endDateEntered]);

  const endDateIsValid = (inputEndDate: MaterialUiPickersDate): boolean =>
    inputEndDate !== null &&
    inputEndDate.isValid &&
    startDate !== null &&
    startDate <= inputEndDate;

  return (
    <ThemeProvider theme={createTheme(dateTimeTheme)}>
      <MuiPickersUtilsProvider utils={LuxonUtils}>
        <div className={classes.datepicker}>
          <KeyboardDatePicker
            className={classes.datepicker}
            value={startDate}
            format={DATE_WITH_SLASH_FORMAT}
            inputProps={{ 'data-testid': 'filterValue-dateStart' }}
            variant="inline"
            fullWidth
            inputVariant="outlined"
            placeholder="Start Date"
            autoOk
            PopoverProps={{
              anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
              transformOrigin: { horizontal: 'center', vertical: 'top' }
            }}
            onChange={date => {
              if (date?.isValid) {
                setStartDate(date);
              }
            }}
          />
          <KeyboardDatePicker
            className={classes.datepicker}
            autoComplete="false"
            value={endDate}
            format={DATE_WITH_SLASH_FORMAT}
            inputProps={{ 'data-testid': 'filterValue-dateEnd' }}
            variant="inline"
            fullWidth
            inputVariant="outlined"
            placeholder="End Date"
            disabled={startDate == null || !startDate.isValid}
            minDate={startDate || DateTime.fromISO('1900-01-01')}
            autoOk
            PopoverProps={{
              anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
              transformOrigin: { horizontal: 'center', vertical: 'top' }
            }}
            onChange={date => {
              if (endDateIsValid(date)) {
                setEndDate(date);
              }
            }}
            // Hacky solution here - the endTime datepicker will autocomplete to the startDate if the startDate
            // is in the future. This triggers the above onChange immediately when the user opens the calendar, preventing
            // them from selecting the date. The below event listeners and the 'endDateEntered' state boolean condition
            // mean that we wait for the user to input the date or otherwise open and close the calendar and accept
            // the autocompleted date.
            onKeyPress={() => {
              if (endDateIsValid(endDate)) {
                setEndDateEntered(true);
              }
            }}
            onClose={() => {
              setEndDateEntered(true);
            }}
          />
        </div>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
};

export default DateRangeField;
