export const REQUIRED_FIELD_ERROR_MESSAGE = 'This field cannot be left empty';
export const NUMBERS_ONLY_FIELD_ERROR_MESSAGE =
  'This field can only have numbers';
export const MPAN_ZERO_START_ERROR_MESSAGE = 'MPAN must not start with 0';
export const MPAN_LENGTH_ERROR_MESSAGE = 'MPAN must be exactly 13 digits';
export const MPAN_NUMBERS_ERROR_MESSAGE = 'MPAN must contain only numbers';

export const MPRN_ZERO_START_ERROR_MESSAGE = 'MPRN must not start with 0';
export const MPRN_MIN_LENGTH_ERROR_MESSAGE = 'MPRN must be at least 6 digits';
export const MPRN_MAX_LENGTH_ERROR_MESSAGE = 'MPRN must be at most 10 digits';
export const MPRN_NUMBERS_ERROR_MESSAGE = 'MPRN must contain only numbers';

export function getRequiredErrorMessage(fieldName?: string) {
  if (!fieldName) {
    return REQUIRED_FIELD_ERROR_MESSAGE;
  }

  return `Enter a valid ${fieldName}`;
}
