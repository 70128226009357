import { ThemeOptions } from '@material-ui/core';

import defaultTheme, { modalFormInputOverrides } from 'styles/defaultTheme';
import { Overrides } from '@material-ui/core/styles/overrides';

export const modalFormDateTimeInputOverrides: Partial<Overrides> = {
  MuiOutlinedInput: {
    ...defaultTheme.overrides.MuiOutlinedInput,
    inputMarginDense: {
      paddingTop: defaultTheme.spacing(1),
      paddingBottom: defaultTheme.spacing(1)
    },
    notchedOutline: modalFormInputOverrides.MuiOutlinedInput.notchedOutline,
    adornedEnd: {
      // Twelve because the icon has 4px padding
      paddingRight: 12
    }
  },
  MuiInputAdornment: {
    root: {
      '& button[class*="MuiIconButton-root"]': {
        padding: defaultTheme.spacing(0.5)
      }
    }
  },
  MuiSvgIcon: {
    root: {
      width: 20,
      height: 20
    }
  }
};

const dateTimeTheme = {
  ...defaultTheme,
  palette: {
    ...defaultTheme.palette,
    primary: {
      main: defaultTheme.palette.primary.main
    }
  } as Overrides
} as ThemeOptions;

export default dateTimeTheme;
