import React, { memo, ChangeEvent } from 'react';
import { TextField, InputLabel } from '@material-ui/core';

import { FieldMetaState } from 'react-final-form';
import { formStyles } from './styles';
import InputFieldWrapper from './InputFieldWrapper';
import { FieldInputValue, InputFieldProps } from './utils';

interface SelectProps {
  field: InputFieldProps;
  meta: FieldMetaState<string>;
  wrapField?: boolean;
  fullWidth?: boolean;
  [key: string]: any;
}

interface SelectTextFieldAndLabelProps {
  field: InputFieldProps;
  meta: FieldMetaState<string>;
  [key: string]: any;
}

function SelectTextFieldAndLabel({
  field,
  meta,
  ...props
}: SelectTextFieldAndLabelProps) {
  const classes = formStyles();

  const helperText = meta.touched && meta.error ? meta.error : field.helperText;
  const fieldId = field.id || field.name;
  return (
    <>
      {field.label ? (
        <InputLabel htmlFor={fieldId}>{field.label}</InputLabel>
      ) : null}
      <TextField
        id={field.id || field.name}
        error={Boolean(meta.touched && meta.error)}
        select
        size="small"
        variant="outlined"
        margin="none"
        fullWidth
        helperText={helperText}
        name={field.name}
        data-testid={field.qaId}
        data-qa-id={field.qaId}
        SelectProps={{ native: true }}
        // Removes extra margin that was only being applied to `FormTextHelper` children of `Select` elements.
        // (It did not match normal text fields.)
        FormHelperTextProps={{ variant: 'standard' }}
        {...props}
        className={field.className || classes.field}
        {...(field.handleChange && {
          onChangeCapture: (event: ChangeEvent<HTMLInputElement>) => {
            // @ts-ignore - TS doesn't understand the existence check above
            field.handleChange(event);
          }
        })}
      >
        {!field.disablePlaceholder ? (
          <option key="defaultOption">{field.placeholder}</option>
        ) : null}
        {field.options?.map((option: FieldInputValue) => (
          <option
            key={option.value}
            value={option.value}
            data-testid={option.testId}
          >
            {option.label}
          </option>
        ))}
      </TextField>
    </>
  );
}

const Select = ({ field, meta, wrapField = true, ...props }: SelectProps) => {
  if (wrapField) {
    return (
      <InputFieldWrapper>
        <SelectTextFieldAndLabel {...props} field={field} meta={meta} />
      </InputFieldWrapper>
    );
  }
  return <SelectTextFieldAndLabel {...props} field={field} meta={meta} />;
};

export default memo(Select);
