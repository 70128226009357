import { PropTypes } from '@material-ui/core';
import { ReactElement } from 'react';
import { camelToSentenceCase } from 'utils';
import { DateTime } from 'luxon';

export interface FormValues {
  [key: string]: FormValue;
}

export type FormValue =
  | string
  | number
  | string[]
  | number[]
  | FieldInputValue[]
  | AutocompleteInputValue[]
  | { [key: string]: any }
  | DateTime
  | null
  | boolean;

export type FormFieldType =
  | 'select'
  | 'multiselect'
  | 'textfield'
  | 'textarea'
  | 'date'
  | 'time'
  | 'checkbox'
  | 'radio';

export type FormFieldConfig = Pick<
  InputFieldProps,
  | 'required'
  | 'name'
  | 'label'
  | 'autoFocus'
  | 'qaId'
  | 'inputType'
  | 'children'
  | 'preprocess'
  | 'options'
  | 'optionRenderer'
>;

export type MultiselectOption = {
  value: string;
  label: string;
  [key: string]: any;
};

export interface InputFieldProps {
  name: string;
  id: string;
  label?: string;
  qaId: string;
  inputType?: FormFieldType;
  type?:
    | 'text'
    | 'number'
    | 'email'
    | 'telephone'
    | 'date'
    | 'time'
    | 'checkbox'
    | 'radio';
  margin?: PropTypes.Margin;
  required?: boolean;
  autoComplete?: string;
  autoFocus?: boolean;
  value?: string;
  validate?: (value: string) => string | undefined;
  preprocess?: (value: any) => any;
  children?: ReactElement[];
  options?: FieldInputValue[] | null;
  optionRenderer?: (option: MultiselectOption) => JSX.Element;
  helperText?: string;
  disabled?: boolean;
  handleChange?: Function;
  conditional?: {
    when: string;
    is?: string;
    isIn?: string[];
    matchesPredicate?: (value: string) => boolean;
  };
  className?: string;
  readOnly?: boolean;
  shouldDisableDate?: (date: DateTime) => boolean;
  multiSelectInputFields?: MultiSelectInputFieldProps;
  placeholder?: string;
  disablePlaceholder?: true;
}

export interface MultiSelectInputFieldProps {
  group?: boolean;
  maxSelected?: number;
  maxTags?: number;
}

export interface FieldInputValue {
  value: string;
  label: string | undefined;
  [key: string]: any;
  other?: string;
}

export interface AutocompleteInputValue {
  value: string;
  label: string;
  category: {
    id: string;
    name: string;
  };
}

export const getDefaultInputFieldsProps = (
  fields: Pick<
    InputFieldProps,
    | 'required'
    | 'name'
    | 'autoFocus'
    | 'qaId'
    | 'inputType'
    | 'helperText'
    | 'label'
  >[]
): InputFieldProps[] =>
  fields.map(({ name, label, ...fieldProps }) => ({
    ...fieldProps,
    name,
    id: name,
    label: label || camelToSentenceCase(name),
    autoComplete: name
  }));
export interface FormError {
  [key: string]: string;
}
