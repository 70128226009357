import React, { memo, useEffect } from 'react';
import {
  CircularProgress,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography
} from '@material-ui/core';
import { Tabs, TabPanels, TabsProvider } from 'components/Tabs/Tabs';
import { useDispatch, useSelector } from 'react-redux';
import { getDashboardStateSlice, RootState } from 'store';
import { JobAlertsForDay } from 'models/Dashboard';
import { StateSlice } from 'store/slices';
import { ScheduleFilters } from 'views/Schedule/ScheduleContainer';
import { getQueryStringForState } from 'utils/custom-hooks/use-query-string-state';
import { scheduleDomain } from 'models';
import { useHistory } from 'react-router-dom';
import CustomButton from 'components/Button/Button';
import { DateTime } from 'luxon';

const spinnerWidth = 100;
const tableHeaderHeight = 93;
const tabHeight = 48;

const useStyles = makeStyles(theme => {
  const heightAboveTableBody =
    Number(theme.mixins.toolbar.minHeight) + tableHeaderHeight + tabHeight;
  return {
    table: {
      width: '100%'
    },
    tableHeader: {
      backgroundColor: theme.palette.secondary.light,
      height: `${tableHeaderHeight}px`
    },
    tableContainer: {
      maxHeight: `calc(100vh - ${heightAboveTableBody}px - ${theme.spacing(
        18
      )}px)`
    },
    header: {
      padding: theme.spacing(3, 2, 0),
      display: 'flex',
      justifyContent: 'space-between'
    },
    refreshButtonGroup: {
      display: 'flex',
      flexDirection: 'column'
    },
    refreshButton: {
      alignSelf: 'self-end'
    },
    lastUpdatedMessage: {
      color: theme.palette.aegis.semantic.message.dark,
      marginTop: 5
    },
    cellHeader: {
      fontWeight: 600
    },
    tableRows: {
      cursor: 'pointer'
    },
    tableCountCell: {
      width: '20%'
    },
    spinner: {
      position: 'relative',
      left: `50%`,
      marginLeft: `-${spinnerWidth / 2 + theme.spacing(1)}px`,
      padding: theme.spacing(2)
    },
    tabPanel: {
      padding: 0
    },
    jobAlertsPaper: {
      width: theme.containers.small
    }
  };
});

const JobAlerts = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { actions, selector } = getDashboardStateSlice();
  const { items: jobAlerts, isLoading } = useSelector<
    RootState,
    StateSlice<JobAlertsForDay>
  >(selector);

  useEffect(() => {
    dispatch(actions.get.list());
  }, []);

  const refetchAlerts = () => {
    dispatch(actions.get.list());
  };

  function goToSchedule(areaId: string, date: string) {
    const scheduleFilters: ScheduleFilters = { date, areas: [areaId] };
    const queryString = getQueryStringForState(scheduleFilters);
    history.push(`dashboard/${scheduleDomain.urlPath}?${queryString}`);
  }

  const tableFields = ['Area', 'Unassigned', 'At Risk', 'Aborted'];

  const tableCellAlign = (index: number) => (index === 0 ? 'left' : 'right');

  const Header = () => (
    <div data-testid="jobAlertsHeader" className={classes.header}>
      <Typography variant="h1">Job Alerts</Typography>
      <div className={classes.refreshButtonGroup}>
        <CustomButton
          disabled={isLoading}
          color="primary"
          onClick={refetchAlerts}
          className={classes.refreshButton}
          data-testid="refreshButton"
        >
          Refresh
        </CustomButton>
        <Typography className={classes.lastUpdatedMessage}>
          last updated at {DateTime.now().toFormat('HH:mm')}
        </Typography>
      </div>
    </div>
  );

  if (isLoading) {
    return (
      <Paper classes={{ root: classes.jobAlertsPaper }}>
        <Header />
        <CircularProgress
          className={classes.spinner}
          data-testid="jobAlertsLoading"
          size={spinnerWidth}
        />
      </Paper>
    );
  }

  if (jobAlerts.length === 0) {
    return null;
  }

  return (
    <Paper classes={{ root: classes.jobAlertsPaper }}>
      <Header />
      <TabsProvider tabs={jobAlerts.map(alert => ({ label: alert.day }))}>
        <Tabs />
        <TabPanels panelClassName={classes.tabPanel}>
          {jobAlerts.map((jobAlertsForDay, dayIndex) => {
            return (
              <TableContainer
                data-testid={`table${dayIndex}`}
                className={classes.tableContainer}
                key={jobAlertsForDay.day}
              >
                <Table
                  stickyHeader
                  aria-label="Job alerts table"
                  className={classes.table}
                >
                  <TableHead>
                    <TableRow>
                      {tableFields.map((field, headerRowIndex) => (
                        <TableCell
                          key={field}
                          className={classes.cellHeader}
                          align={tableCellAlign(headerRowIndex)}
                        >
                          {field}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {jobAlertsForDay.alerts.map((alert, alertIndex) => {
                      return (
                        <TableRow
                          data-testid={`day${dayIndex}area${alertIndex}`}
                          hover
                          role="checkbox"
                          key={alert.geographicAreaId}
                          className={classes.tableRows}
                          onClick={() =>
                            goToSchedule(
                              alert.geographicAreaId,
                              jobAlertsForDay.date
                            )
                          }
                        >
                          <TableCell align={tableCellAlign(0)}>
                            {alert.geographicAreaName}
                          </TableCell>
                          <TableCell
                            className={classes.tableCountCell}
                            align={tableCellAlign(1)}
                          >
                            {`${alert.unassigned ? alert.unassigned : '-'}`}
                          </TableCell>
                          <TableCell
                            className={classes.tableCountCell}
                            align={tableCellAlign(1)}
                          >
                            {`${alert.atRisk ? alert.atRisk : '-'}`}
                          </TableCell>
                          <TableCell
                            className={classes.tableCountCell}
                            align={tableCellAlign(1)}
                          >
                            {`${alert.aborted ? alert.aborted : '-'}`}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            );
          })}
        </TabPanels>
      </TabsProvider>
    </Paper>
  );
};
export default memo(JobAlerts);
