import React, { ReactElement } from 'react';
import { Table as MUITable, TableRow } from '@material-ui/core';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import { useTableStyles } from 'components/Crud/ListTable/ListTable';
import ListTableHeaderCell from 'components/Crud/ListTable/ListTableHeaderCell';

interface TableProps {
  rows: ReactElement[];
  columnHeaders?: string[];
  columnWidths: number[];
}

export default function Table({
  rows,
  columnHeaders,
  columnWidths
}: TableProps) {
  const classes = useTableStyles();

  return (
    <MUITable className={classes.table}>
      {columnHeaders && columnHeaders.length ? (
        <TableHead className={classes.tableHead}>
          <TableRow>
            {columnHeaders.map((header, index) => (
              <ListTableHeaderCell key={header} width={columnWidths[index]}>
                {header}
              </ListTableHeaderCell>
            ))}
          </TableRow>
        </TableHead>
      ) : null}
      <TableBody>{rows}</TableBody>
    </MUITable>
  );
}
