import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { DateTime } from 'luxon';
import { useHistory } from 'react-router-dom';
import { QueryParams } from 'store/utils';
import { CircularProgress, makeStyles, TableCell } from '@material-ui/core';

import { getDomainSlice } from 'store';
import ListView from 'components/Crud/views/List';
import { useTableStyles, ListTable } from 'components/Crud/ListTable/ListTable';
import { appointmentDomain } from 'models';
import {
  useAppointmentsState,
  useEngineersState,
  usePatchesState
} from 'store/selectors';
import { useFlags } from 'launchdarkly-react-client-sdk';
import ListTableRow from 'components/Crud/ListTable/ListTableRow';
import Snackbars from 'components/Alerts/Snackbars';
import CustomButton from 'components/Button';
import { getUIAppointmentStatus } from 'utils/jobStatus';
import Typography from 'components/Typography';
import { mapEngineersToAutocompleteValues } from 'components/Forms';
import FilterSearch, {
  FilterValue
} from 'components/FilterSearch/FilterSearch';
import { getFilterQueryParams } from 'utils';
import useFilterSearchChips from 'utils/custom-hooks/useFilterSearchChips';
import Engineer from 'models/Engineers';
import Patch from 'models/Patches';
import {
  AppointmentAutocompleteStatus,
  AppointmentCategory
} from 'models/Appointment';
import { fetchAllPatches } from 'store/slices/patches';
import { fetchAllEngineers } from 'store/slices/engineers';
import { useJobTypeData } from 'store/hooks';
import AddJobModal from './AddJobModal/AddJobModal';
import { getJobFilters } from './ListJobsConfig';

const columnsWidthNoJobType = [220, 120, 120, 180, 180, 140];
const headersNoJobType = [
  'Customer',
  'Postcode',
  'Brand',
  'Date',
  'Engineer',
  'Status'
];
const columnsWidth = [220, 110, 90, 170, 180, 130, 180];
const headers = [
  'Customer',
  'Postcode',
  'Brand',
  'Date',
  'Engineer',
  'Status',
  'Job Type'
];

enum ListJobsFilters {
  ACCOUNT_NUMBER = 'accountNumber',
  ENGINEER = 'engineer',
  POSTCODE = 'postcode',
  STATUS = 'status'
}

interface LabelAndValue {
  label: string;
  value: string;
}

const mapPatchesToAutocompleteValues = (patches: Patch[]): LabelAndValue[] =>
  patches
    .filter(item => item.id)
    .map(({ id, name }) => ({
      label: name || '',
      value: id || ''
    }));

const mapAppointmentStatusesToAutocompleteValues = (): LabelAndValue[] =>
  Object.entries(AppointmentAutocompleteStatus)
    .sort((a, b) => a[1].localeCompare(b[1]))
    .map(([key, value]) => ({
      label: value || '',
      value: key || ''
    }));

function getAutocompleteValues(
  selectedFilter: string,
  patches: Patch[],
  engineers: Engineer[]
): LabelAndValue[] {
  if (selectedFilter === ListJobsFilters.ENGINEER) {
    return mapEngineersToAutocompleteValues(engineers);
  }

  if (selectedFilter === ListJobsFilters.STATUS) {
    return mapAppointmentStatusesToAutocompleteValues();
  }
  return mapPatchesToAutocompleteValues(patches);
}

function getAppointmentJobType(jobTypeId: string | undefined): string {
  if (!jobTypeId) {
    return 'None';
  }
  return useJobTypeData(jobTypeId)?.name ?? jobTypeId;
}

const useStyles = makeStyles(
  theme => ({
    container: {
      height: `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`,
      paddingTop: theme.spacing(9)
    },
    messageContainer: {
      height: '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    message: {
      margin: 'auto',
      textAlign: 'center',
      padding: theme.spacing(2)
    },
    spinner: {
      margin: theme.spacing(2),
      alignSelf: 'center'
    }
  }),
  { name: 'ListJobs' }
);

const JobsView = () => {
  const classes = useStyles();
  const tableClasses = useTableStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    aegisEnableJobTypeColumnInJobsView,
    aegisEnableSearchJobsByEngineer,
    aegisEnableReturnOnlyExactMatchesInAppointmentList
  } = useFlags();
  const jobFilters = getJobFilters(
    aegisEnableSearchJobsByEngineer,
    !aegisEnableReturnOnlyExactMatchesInAppointmentList
  );

  const usedColumnsWidth = aegisEnableJobTypeColumnInJobsView
    ? columnsWidth
    : columnsWidthNoJobType;
  const usedHeaders = aegisEnableJobTypeColumnInJobsView
    ? headers
    : headersNoJobType;

  const {
    items: appointmentsData,
    isLoading: appointmentsLoading
  } = useAppointmentsState();

  const {
    items: patchesData,
    isLoading: arePatchesLoading
  } = usePatchesState();

  const { actions: appointmentAction } = getDomainSlice(appointmentDomain);

  const { items: engineers, isLoading: engineersLoading } = useEngineersState();

  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

  const onSearch = (params: QueryParams) => {
    dispatch(
      appointmentAction.get.list({
        category: {
          values: [AppointmentCategory.CUSTOMER],
          operator: '='
        },
        ...params
      })
    );
  };

  const onLoad = (filterValues: FilterValue[], searchWithFilters: () => void) =>
    React.useEffect(() => {
      if (filterValues.length > 0) {
        searchWithFilters();
      }
    }, [filterValues]);

  const {
    appliedFilters,
    onFilterRemove,
    onFilterValueInput,
    filterValues
  } = useFilterSearchChips(jobFilters, onSearch, onLoad);

  useEffect(() => {
    dispatch(fetchAllPatches());
    if (aegisEnableSearchJobsByEngineer) {
      dispatch(fetchAllEngineers());
    }
  }, []);

  useEffect(() => {
    if (
      filterValues.length &&
      appointmentsLoading === false &&
      appointmentsData.length === 1
    ) {
      history.push(`/dashboard/jobs/${appointmentsData[0].id}`);
    }
  }, [appointmentsLoading, appointmentsData.length]);

  const openModal = () => {
    setIsCreateModalOpen(true);
  };

  const closeModal = () => {
    setIsCreateModalOpen(false);
  };

  const InitialSearchForm = () => (
    <div className={classes.container}>
      <div className={`${tableClasses.tableContainer}`}>
        <div className={tableClasses.tableHeader}>
          <Typography variant="h1">Jobs</Typography>
          <CustomButton
            onClick={openModal}
            color="primary"
            className={tableClasses.tableHeaderButton}
            data-testid="jobsTableButtonCreate"
            size="medium"
          >
            New job
          </CustomButton>
        </div>
        {engineersLoading ? (
          <CircularProgress
            className={classes.spinner}
            size={50}
            color="primary"
          />
        ) : (
          <FilterSearch
            filters={jobFilters}
            filterValues={appliedFilters}
            onFilterRemove={onFilterRemove}
            onFilterValueInput={onFilterValueInput}
            optionsCallback={selectedFilter =>
              getAutocompleteValues(selectedFilter, patchesData, engineers)
            }
          />
        )}
      </div>
      <div className={classes.messageContainer}>
        <Typography
          variant="bodyHeavy"
          className={classes.message}
          data-testid="enterSearchParamMesssage"
        >
          Find a job by choosing a search parameter
        </Typography>
      </div>
    </div>
  );

  const SearchResultsTable = () => (
    <>
      <ListView
        domain={appointmentDomain}
        showPageHeader={false}
        autoLoad={false}
        loadingDependency={[
          appointmentsLoading,
          arePatchesLoading,
          engineersLoading
        ].some(Boolean)}
      >
        {({ onView }) => (
          <section className={tableClasses.tableContainer}>
            <ListTable
              domain={appointmentDomain}
              domainItems={appointmentsData}
              columnsWidth={usedColumnsWidth}
              title="Jobs"
              label="A table of Jobs"
              headerButtons={[
                {
                  label: 'New job',
                  openModal
                }
              ]}
              headers={usedHeaders}
              filter={{
                category: {
                  values: [AppointmentCategory.CUSTOMER],
                  operator: '='
                },
                ...getFilterQueryParams(appliedFilters)
              }}
              headerComponent={
                <FilterSearch
                  filters={jobFilters}
                  filterValues={appliedFilters}
                  onFilterRemove={onFilterRemove}
                  onFilterValueInput={onFilterValueInput}
                  optionsCallback={selectedFilter =>
                    getAutocompleteValues(
                      selectedFilter,
                      patchesData,
                      engineers
                    )
                  }
                />
              }
            >
              {appointmentsData.map(appointment => (
                <ListTableRow
                  key={appointment.id}
                  data-testid={`tableRow-${appointment.id}`}
                  onClick={onView(appointment)}
                >
                  <TableCell
                    className={tableClasses.tableCell}
                    data-testid={`tableCell-${appointment.id}-name`}
                  >
                    {`${appointment.customerDetails?.firstName ||
                      ''} ${appointment.customerDetails?.lastName || ''}`}
                  </TableCell>
                  <TableCell
                    className={tableClasses.tableCell}
                    data-testid={`tableCell-${appointment.id}-postcode`}
                  >
                    {appointment.address?.postcode || ''}
                  </TableCell>
                  <TableCell
                    className={tableClasses.tableCell}
                    data-testid={`tableCell-${appointment.id}-brand`}
                  >
                    {appointment.brand}
                  </TableCell>
                  <TableCell
                    className={tableClasses.tableCell}
                    data-testid={`tableCell-${appointment.id}-date`}
                  >
                    {DateTime.fromISO(appointment.slot.date).toFormat(
                      'ccc d LLL yyyy'
                    )}
                  </TableCell>
                  <TableCell
                    className={tableClasses.tableCell}
                    data-testid={`tableCell-${appointment.id}-engineer`}
                  >
                    {appointment.engineers
                      ?.map(({ id, firstName, lastName }) =>
                        firstName && lastName ? `${firstName} ${lastName}` : id
                      )
                      .join(', ')}
                  </TableCell>
                  <TableCell
                    className={tableClasses.tableCell}
                    data-testid={`tableCell-${appointment.id}-status`}
                  >
                    {getUIAppointmentStatus(appointment.status)}
                  </TableCell>
                  {aegisEnableJobTypeColumnInJobsView ? (
                    <TableCell
                      className={tableClasses.tableCell}
                      data-testid={`tableCell-${appointment.id}-job-type`}
                    >
                      {getAppointmentJobType(appointment.jobType)}
                    </TableCell>
                  ) : null}
                  <TableCell className={tableClasses.tableCell}>
                    &nbsp;
                  </TableCell>
                </ListTableRow>
              ))}
            </ListTable>
          </section>
        )}
      </ListView>
    </>
  );

  return (
    <>
      {filterValues.length ? <SearchResultsTable /> : <InitialSearchForm />}
      <AddJobModal open={isCreateModalOpen} closeModal={closeModal} />
      <Snackbars />
    </>
  );
};

export default JobsView;
