export enum AuditDomain {
  APPOINTMENT = 'APPOINTMENT',
  SCHEDULING = 'SCHEDULING'
}

export enum AuditAction {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE'
}

export interface Audit {
  domainId: string;
  domain: AuditDomain;
  action: AuditAction;
  changedField: string | null;
  changedFrom: string | null;
  changedTo: string | null;
  changedBy: {
    username: string | null;
    firstName: string | null;
    lastName: string | null;
  };
  timestamp: number;
  additionalData: string | null;
}

export interface NoteDetails {
  type: string | null;
  note: string | null;
}
