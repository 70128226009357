import * as Yup from 'yup';
import { getDefaultInputFieldsProps, InputFieldProps } from 'components/Forms';
import { FilterFieldType, Filter } from 'components/FilterSearch/FilterSearch';
import { QueryFilterOperators } from 'store/utils';
import {
  validateCsvString,
  arrayToCsvString,
  csvRegEx
} from '../../utils/utils';

export const getPatchInputFieldsProps = (type: 'add' | 'edit') => {
  const fields: Pick<
    InputFieldProps,
    | 'required'
    | 'name'
    | 'autoFocus'
    | 'qaId'
    | 'inputType'
    | 'children'
    | 'preprocess'
    | 'type'
    | 'validate'
  >[] = [
    {
      required: true,
      name: 'name',
      autoFocus: true,
      qaId: `${type}PatchName`,
      inputType: 'textfield'
    },
    {
      required: true,
      name: 'outcodes',
      autoFocus: true,
      qaId: `${type}PatchOutcodes`,
      validate: validateCsvString,
      preprocess: arrayToCsvString,
      inputType: 'textfield'
    }
  ];

  return getDefaultInputFieldsProps(fields);
};

export const validationSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  outcodes: Yup.string()
    .required('Required')
    .matches(csvRegEx, 'Invalid Outcodes Format')
});

export const getPatchFilters = (enableSearchPatchByName: boolean): Filter[] => [
  {
    id: 'outcode',
    queryString: 'outcode',
    name: 'Outcode',
    operator: 'in',
    fieldType: FilterFieldType.TEXT
  },
  ...(enableSearchPatchByName
    ? [
        {
          id: 'name',
          queryString: 'name',
          name: 'Name',
          operator: '%' as QueryFilterOperators,
          fieldType: FilterFieldType.TEXT
        }
      ]
    : [])
];
