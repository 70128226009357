import React from 'react';

import { getDomainSlice } from 'store';
import { useSelector } from 'react-redux';
import Breadcrumbs from './Breadcrumbs';
import { getBreadcrumbs } from './breadcrumbData';
import { DomainBreadcrumbProps } from './BreadcrumbContainer';

const StatefulDomainBreadcrumb = ({
  pathname,
  domain
}: DomainBreadcrumbProps) => {
  const slice = getDomainSlice(domain);
  const { items } = useSelector(slice.selector);

  const breadcrumbs = getBreadcrumbs(domain, pathname, items);

  if (breadcrumbs.length === 0) {
    return null;
  }

  return <Breadcrumbs crumbs={breadcrumbs} />;
};

export default StatefulDomainBreadcrumb;
