import React from 'react';

import { useLocation } from 'react-router-dom';
import * as Domains from 'models/index';
import { Domain } from 'models/Domain';
import StatefulDomainBreadcrumb from './StatefulDomainBreadcrumb';
import StatelessDomainBreadcrumb from './StatelessDomainBreadcrumb';
import { getDomainNameFromPath } from './breadcrumbData';

export interface BreadcrumbsProps {
  pathname: string;
}

export interface DomainBreadcrumbProps extends BreadcrumbsProps {
  domain: Domain;
}

const getCurrentDomain = (path: string): Domain => {
  return Object.values(Domains).filter(
    domain => getDomainNameFromPath(path) === domain.urlPath
  )[0];
};

const BreadcrumbContainer = () => {
  const { pathname } = useLocation();
  const domain = getCurrentDomain(pathname);

  if (!domain) {
    return null;
  }

  // Stateful and stateless domains seperated here because React doesn't
  // like hooks (which are used to get the state) be called conditionally
  if (domain.stateful) {
    return <StatefulDomainBreadcrumb pathname={pathname} domain={domain} />;
  }
  return <StatelessDomainBreadcrumb pathname={pathname} domain={domain} />;
};

export default BreadcrumbContainer;
