import TimeBand from 'models/TimeBand';
import GenericDataType from './GenericDataType';
import Skill from './Skills';

export enum InstallationType {
  INSTALLATION = 'installation',
  NON_INSTALLATION = 'nonInstallation'
}

export default interface JobType extends GenericDataType {
  name: string;
  defaultLength: number;
  requiredSkills: Skill[];
  associatedWorkflow?: string | null;
  installationType: InstallationType;
  timeBand: TimeBand;
  maintenance: boolean;
  extraAttributes: {
    multiMpan?: boolean;
    polyphase?: boolean;
    recoveries?: boolean;
  };
}
