import React from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core';
import Typography from 'components/Typography';

const buttonWidth = '200px';
const useStyles = makeStyles(theme => ({
  paper: {
    width: '100%',
    padding: theme.spacing(4),
    backgroundColor: theme.palette.common.white
  },
  subheader: {
    color: theme.palette.info.dark,
    marginBottom: theme.spacing(2)
  },
  gridContents: {
    width: '100%'
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },
  button: {
    width: buttonWidth,
    margin: 0
  }
}));
const Section = ({
  children,
  title,
  gridProps,
  testId,
  className
}: {
  children: any;
  title?: string;
  gridProps?: any;
  testId?: string;
  className?: string;
}) => {
  const classes = useStyles();
  const titleTestId = testId ? `${testId}Title` : null;

  return (
    <Grid item {...gridProps} {...(className ? { className } : {})}>
      <Paper className={classes.paper} elevation={0}>
        <Grid container>
          <Grid item container xs={12} className={classes.gridContents}>
            <Grid item container xs={12}>
              {title ? (
                <Grid item xs={6} data-testid={titleTestId}>
                  <Typography component="h2" className={classes.subheader}>
                    {title}
                  </Typography>
                </Grid>
              ) : null}
            </Grid>
          </Grid>
          {children}
        </Grid>
      </Paper>
    </Grid>
  );
};
export default Section;
