import * as Yup from 'yup';

import Skill from 'models/Skills';
import TimeBand from 'models/TimeBand';
import {
  getDefaultInputFieldsProps,
  InputFieldProps,
  mapSkillsToAutocompleteValues,
  SkillOption,
  yupSchemaToValidationFunc
} from 'components/Forms';

import {
  getRequiredErrorMessage,
  NUMBERS_ONLY_FIELD_ERROR_MESSAGE
} from 'constants/validation';
import { InstallationType } from 'models/JobTypes';
import { camelToSentenceCase } from 'utils';

export const getJobTypeInputFieldsProps = (
  type: 'add' | 'edit',
  skills: Skill[] | null,
  timeBand: TimeBand[] | null
) => {
  const fields: Pick<
    InputFieldProps,
    | 'required'
    | 'name'
    | 'autoFocus'
    | 'qaId'
    | 'inputType'
    | 'children'
    | 'preprocess'
    | 'type'
    | 'options'
    | 'optionRenderer'
    | 'label'
  >[] = [
    {
      required: true,
      name: 'name',
      autoFocus: true,
      qaId: `${type}JobTypeName`,
      inputType: 'textfield'
    },
    {
      required: true,
      type: 'number',
      name: 'defaultLength',
      qaId: `${type}JobTypeDefaultLength`,
      inputType: 'textfield',
      label: 'Duration'
    },
    {
      required: false,
      name: 'associatedWorkflow',
      qaId: `${type}JobTypeAssociatedWorkflow`,
      inputType: 'textfield'
    },
    {
      required: true,
      name: 'installationType',
      qaId: `${type}JobTypeInstallationType`,
      inputType: 'select',
      options: Object.values(InstallationType).map(installationType => ({
        value: installationType,
        label: camelToSentenceCase(installationType)
      }))
    },
    {
      required: true,
      name: 'timeBand',
      qaId: `${type}JobTypeTimeBand`,
      inputType: 'select',
      options:
        timeBand &&
        timeBand.map(band => ({
          value: band.id,
          label: band.name
        }))
    },
    {
      required: true,
      name: 'requiredSkills',
      qaId: `${type}JobTypeRequiredSkills`,
      inputType: 'multiselect',
      optionRenderer: SkillOption,
      options: skills && mapSkillsToAutocompleteValues(skills),
      preprocess: mapSkillsToAutocompleteValues
    }
  ];

  return getDefaultInputFieldsProps(fields);
};
export const validationSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  defaultLength: Yup.number().required('Required'),
  requiredSkills: Yup.array().required('Required'),
  installationType: Yup.string().required('Required'),
  timeBand: Yup.string().required('Required')
});

export enum JobTypesFieldNames {
  NAME = 'name',
  DEFAULT_LENGTH = 'defaultLength',
  REQUIRED_SKILLS = 'requiredSkills',
  INSTALLATION_TYPE = 'installationType',
  TIMEBAND = 'timeBand'
}

export const newValidationSchema = Yup.object().shape({
  [JobTypesFieldNames.NAME]: Yup.string().required(
    getRequiredErrorMessage(
      camelToSentenceCase(JobTypesFieldNames.NAME).toLowerCase()
    )
  ),
  [JobTypesFieldNames.DEFAULT_LENGTH]: Yup.number()
    // This is needed because the default value for a field is an empty string,
    // therefore Yup will think it is a type error and display the wrong message
    .transform((value, originalValue) =>
      originalValue === '' ? undefined : value
    )
    .required(getRequiredErrorMessage('duration'))
    .typeError(NUMBERS_ONLY_FIELD_ERROR_MESSAGE),
  [JobTypesFieldNames.REQUIRED_SKILLS]: Yup.array()
    .required(getRequiredErrorMessage('required skill'))
    .nullable(),
  [JobTypesFieldNames.INSTALLATION_TYPE]: Yup.string().required(
    getRequiredErrorMessage(
      camelToSentenceCase(JobTypesFieldNames.INSTALLATION_TYPE).toLowerCase()
    )
  ),
  [JobTypesFieldNames.TIMEBAND]: Yup.string().required(
    getRequiredErrorMessage(
      camelToSentenceCase(JobTypesFieldNames.TIMEBAND).toLowerCase()
    )
  )
});

export const jobTypesFormValidation = yupSchemaToValidationFunc(
  newValidationSchema
);
