import React, { ReactNode } from 'react';
import { Grid, Typography, CssBaseline } from '@material-ui/core';

interface PageProps {
  title: string;
  titleQaId: string;
  children: ReactNode;
}

const Page = ({ title, titleQaId, children }: PageProps) => (
  <Grid container spacing={3}>
    <Grid item xs={12}>
      <CssBaseline />
      <Typography variant="h2" data-qa-id={titleQaId} data-testid={titleQaId}>
        {title}
      </Typography>
      {children}
    </Grid>
  </Grid>
);

export default Page;
