import { makeStyles } from '@material-ui/core';

const fieldMaxWidth = '400px';
export const formStyles = makeStyles(theme => ({
  field: {
    maxWidth: fieldMaxWidth
  },
  radio: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1)
  }
}));
