import React, { useState, useRef, useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { Form, FormValues, yupSchemaToValidationFunc } from 'components/Forms';
import Modal from 'components/Modal';
import { patchDomain } from 'models';
import SummaryProperty, {
  SummaryPropertyOrientation
} from 'views/Jobs/AddJobModal/SummaryProperty';
import { useAddJobModalStyles } from 'views/Jobs/AddJobModal/AddJobModal';
import { fetchGeographicAreas } from 'store/slices/geographicAreas';
import Patch from 'models/Patches';
import { setSnackbar, SnackbarStatus } from 'store/slices/snackbar';
import { commonErrorHandling } from 'store/utils';
import { useGeographicAreasData } from 'store/selectors';
import { UpdateGeographicAreaRequest } from 'models/GeographicArea';
import { capitalise, onlyUnique } from 'utils';
import { fetchPatches } from 'store/slices/patches';
import { FormApiWithRestart } from 'components/Forms/Form';
import { editGeographicArea } from 'store/endpoints/geographicArea';
import { addPatch, editPatch } from 'store/endpoints/patch';
import { makeStyles } from '@material-ui/core';
import {
  areaValidationSchema,
  getAreaInputFieldsProps,
  getPatchInputFieldsProps,
  patchValidationSchema
} from './PatchModalConfig';
import PatchModalActions from './PatchModalActions';

export enum PatchModalFormStep {
  Initial,
  Area
}
export enum PatchFormMode {
  New = 'new',
  Edit = 'edit'
}

interface PatchModalProps {
  open: boolean;
  currentPatch?: Patch;
  closeModal: () => void;
}

const useStyles = makeStyles(
  () => ({
    helperTextOverride: {
      '& .MuiFormHelperText-contained': {
        marginLeft: 0
      }
    }
  }),
  { name: 'PatchModal' }
);

export default function PatchModal({
  open,
  currentPatch,
  closeModal
}: PatchModalProps) {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [formStep, setFormStep] = useState(PatchModalFormStep.Initial);
  const [formState, setFormState] = useState<FormValues>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const formRef = useRef<FormApiWithRestart>();
  const areaFormRef = useRef<FormApiWithRestart>();
  const addJobModalClasses = useAddJobModalStyles();
  const geographicAreas = useGeographicAreasData();

  const areasWithPatch = currentPatch
    ? geographicAreas.filter(area => area.patches.includes(currentPatch.id))
    : [];

  const parseFormValues = (values: FormValues) => {
    const name = values.name as string;
    const outcodes = values.outcodes as string;

    return {
      name,
      outcodes: outcodes.includes(',')
        ? outcodes.split(',').map(outcode => outcode.trim())
        : [outcodes]
    };
  };

  const updateModalState = async (formValues: FormValues) => {
    setFormState(formValues);
    setFormStep(PatchModalFormStep.Area);
  };

  const resetModal = () => {
    setFormStep(PatchModalFormStep.Initial);
    setFormState(undefined);
    closeModal();
  };

  const handleAddPatchSubmit = async (formValues: FormValues) => {
    setIsLoading(true);

    try {
      const patchPayload = parseFormValues(formValues);
      const patchId = await addPatch(patchPayload);

      updateModalState({ ...formValues, patchId });
    } catch (error) {
      commonErrorHandling(error, patchDomain.type);

      dispatch(
        setSnackbar({
          title: 'Could not load next step',
          message:
            'There was an internal error and the next stage in the form could not be shown. Wait a moment before trying again.',
          show: true,
          status: SnackbarStatus.Error
        })
      );
    }

    setIsLoading(false);

    return undefined;
  };

  const handleEditPatchSubmit = async (formValues: FormValues) => {
    if (!currentPatch) {
      throw new Error(
        `Tried to edit a patch, but the patch value was ${currentPatch}`
      );
    }

    setIsLoading(true);

    const patchPayload = parseFormValues(formValues);

    try {
      await editPatch(currentPatch.id, patchPayload);

      updateModalState({
        ...formValues,
        patchId: currentPatch.id,
        area: areasWithPatch?.[0]?.id ?? ''
      });

      setIsLoading(false);
    } catch (error) {
      commonErrorHandling(error, patchDomain.type);

      setIsLoading(false);

      resetModal();

      dispatch(
        setSnackbar({
          title: `Area not added to the patch`,
          message:
            'There was an internal error and no area was added to the patch. Try opening the patch and adding an area to it.',
          show: true,
          status: SnackbarStatus.Error
        })
      );
    }

    return undefined;
  };

  const handleAreaSubmit = async (formValues: FormValues) => {
    setIsLoading(true);

    try {
      const selectedArea = geographicAreas.find(
        area => area.id === formValues.area
      );

      if (!selectedArea) {
        throw new Error(
          `Could not find a geographic area with id: ${formValues.area}`
        );
      }

      // remove patch from areas it is currently in
      const requests = areasWithPatch
        .filter(area => area.id != selectedArea.id)
        .map(async area => {
          const editOldAreaPayload: UpdateGeographicAreaRequest = {
            ...area,
            category: area.category.id,
            patches: area?.patches.filter(
              patchId => patchId !== currentPatch?.id
            )
          };

          return editGeographicArea(editOldAreaPayload);
        });

      await Promise.all(requests);

      // add patch to new area
      const editNewAreaPayload: UpdateGeographicAreaRequest = {
        ...selectedArea,
        category: selectedArea.category.id,
        patches: onlyUnique([
          ...(selectedArea?.patches ?? []),
          formState?.patchId
        ])
      };

      await editGeographicArea(editNewAreaPayload);

      setIsLoading(false);

      dispatch(
        setSnackbar({
          message: !currentPatch ? 'New patch created' : 'Patch was updated',
          show: true,
          status: SnackbarStatus.Success
        })
      );
      dispatch(fetchPatches());

      resetModal();
    } catch (error) {
      setIsLoading(false);

      commonErrorHandling(error, patchDomain.type);
      resetModal();

      dispatch(
        setSnackbar({
          title: `Area not added to the patch`,
          message:
            'There was an internal error and no area was added to the patch. Try opening the patch and adding an area to it.',
          show: true,
          status: SnackbarStatus.Error
        })
      );
    }

    return undefined;
  };

  const maxOutcodesCharacterLength = 68;
  const formMode = !currentPatch ? PatchFormMode.New : PatchFormMode.Edit;

  useEffect(() => {
    if (open) {
      dispatch(fetchGeographicAreas());
    }
  }, [open]);

  return (
    <Modal
      title={`${
        !currentPatch
          ? capitalise(PatchFormMode.New)
          : capitalise(PatchFormMode.Edit)
      } Patch`}
      testId={`${formMode}PatchJobModal`}
      open={open}
      onClose={resetModal}
      modalActions={
        <PatchModalActions
          formMode={formMode}
          isLoading={isLoading}
          formStep={formStep}
          formRef={
            formStep === PatchModalFormStep.Initial ? formRef : areaFormRef
          }
          setFormStep={setFormStep}
          resetModal={resetModal}
        />
      }
    >
      {formStep === PatchModalFormStep.Initial ? (
        <Form
          validationFunc={yupSchemaToValidationFunc(patchValidationSchema)}
          fields={getPatchInputFieldsProps(formMode)}
          initialValues={{
            name: currentPatch?.name ?? formState?.name ?? '',
            outcodes:
              currentPatch?.outcodes?.join(', ') ?? formState?.outcodes ?? ''
          }}
          onSubmit={
            !currentPatch ? handleAddPatchSubmit : handleEditPatchSubmit
          }
          goBackToPreviousPage={false}
          className={classes.helperTextOverride}
        >
          {({ form }) => {
            formRef.current = form;
          }}
        </Form>
      ) : (
        <>
          <div
            data-testid="patchModalSummary"
            className={addJobModalClasses.infoContainer}
          >
            {formState && (
              <>
                <SummaryProperty
                  name="Name"
                  value={formState?.name as string}
                />
                <SummaryProperty
                  name="Outcodes"
                  value={formState?.outcodes as string}
                  orientation={
                    (formState?.outcodes as string).length <
                    maxOutcodesCharacterLength
                      ? SummaryPropertyOrientation.SIDE
                      : SummaryPropertyOrientation.BELOW
                  }
                />
              </>
            )}
          </div>
          <Form
            validationFunc={yupSchemaToValidationFunc(areaValidationSchema)}
            fields={getAreaInputFieldsProps(formMode, geographicAreas)}
            initialValues={{
              area: formState?.area ?? ''
            }}
            onSubmit={handleAreaSubmit}
            goBackToPreviousPage={false}
          >
            {({ form }) => {
              areaFormRef.current = form;
            }}
          </Form>
        </>
      )}
    </Modal>
  );
}
