import React from 'react';
import Typography from 'components/Typography';
import { Grid, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(
  theme => ({
    wrapper: {
      padding: theme.spacing(2),
      paddingTop: theme.spacing(1),
      gap: theme.spacing(2),
      justifyContent: 'flex-end'
    },
    key: {
      display: 'flex',
      gap: 8
    },
    keyColour: {
      width: theme.spacing(3),
      height: theme.spacing(3),
      borderRadius: 4
    },
    default: {
      backgroundColor: theme.palette.aegis.colour.grey.lighter
    },
    outOfOffice: {
      backgroundColor: theme.palette.aegis.colour.grey.darker
    },
    event: {
      backgroundColor: theme.palette.aegis.colour.green.lighter
    },
    temporaryShift: {
      backgroundColor: theme.palette.aegis.colour.blue.lighter
    },
    temporaryPatch: {
      backgroundColor: theme.palette.aegis.colour.purple.lightest
    },
    temporaryEfficiency: {
      backgroundColor: theme.palette.aegis.colour.orange.lighter
    }
  }),
  { name: 'EngineerCalendarKey' }
);

function EngineerCalendarKey() {
  const classes = useStyles();

  const keys = [
    {
      label: 'Default schedule',
      className: classes.default,
      testId: 'engineerCalendarKeyColour_defaultSchedule'
    },
    {
      label: 'Out of office',
      className: classes.outOfOffice,
      testId: 'engineerCalendarKeyColour_outOfOffice'
    },
    {
      label: 'Event',
      className: classes.event,
      testId: 'engineerCalendarKeyColour_event'
    },
    {
      label: 'Temporary shift',
      className: classes.temporaryShift,
      testId: 'engineerCalendarKeyColour_temporaryShift'
    },
    {
      label: 'Temporary patch',
      className: classes.temporaryPatch,
      testId: 'engineerCalendarKeyColour_temporaryPatch'
    },
    {
      label: 'Temporary efficiency',
      className: classes.temporaryEfficiency,
      testId: 'engineerCalendarKeyColour_temporaryEfficiency'
    }
  ];

  return (
    <Grid container component="ul" wrap="nowrap" className={classes.wrapper}>
      {Object.values(keys).map(({ label, className, testId }) => (
        <li key={label} className={classes.key}>
          <div
            className={`${classes.keyColour} ${className}`}
            data-testid={testId}
          />
          <Typography>{label}</Typography>
        </li>
      ))}
    </Grid>
  );
}

export default EngineerCalendarKey;
