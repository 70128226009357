import * as React from 'react';
import { Chip, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  chip: {
    marginLeft: theme.spacing(1),
    backgroundColor: theme.palette.success.light,
    color: theme.palette.getContrastText(theme.palette.success.light)
  }
}));

interface SuccessChipProps {
  label: string;
}

export default function SuccessChip(props: SuccessChipProps) {
  const classes = useStyles();
  return <Chip className={classes.chip} {...props} />;
}
