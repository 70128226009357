import React, { memo } from 'react';
import { DateTime } from 'luxon';
import { useTheme } from '@material-ui/core';

import Typography from 'components/Typography';
import { scheduleStartTime } from 'constants/schedule';
import { useEngineerCalendarStyles } from './EngineerCalendarBody';
import { EngineerEvent } from './EngineerCalendar';

interface EngineerCalendarFixedAppointmentsProps {
  date: DateTime;
  events?: EngineerEvent[];
}

function EngineerCalendarFixedAppointments({
  date,
  events
}: EngineerCalendarFixedAppointmentsProps) {
  const engineerCalendarClasses = useEngineerCalendarStyles();
  const theme = useTheme();

  const containerTopMargin = theme.spacing(1.5);
  const hourInVerticalPx = 44;
  const minHoursForAppointmentTimeDisplay = 1.25;

  const currentEvents = events?.filter(
    ({ slot }) => slot.date.toISODate() === date.toISODate()
  );

  return (
    <>
      {currentEvents?.map(({ id, reason, slot }) => {
        const scheduleStartDiff = slot.startTime.diff(
          DateTime.fromISO(`${date.toISODate()}T${scheduleStartTime}`),
          'hours'
        ).hours;

        const appointmentLengthDiff = slot.endTime.diff(slot.startTime, 'hours')
          .hours;

        const eventHeight = appointmentLengthDiff * hourInVerticalPx;

        const shouldDisplayAppointmentTime =
          appointmentLengthDiff >= minHoursForAppointmentTimeDisplay;

        const shortAppointmentTextStyles = {
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden'
        };

        const eventStyles = {
          top: scheduleStartDiff * hourInVerticalPx + containerTopMargin,
          height: eventHeight
        };

        const formattedStartTime = slot.startTime.toLocaleString(
          DateTime.TIME_24_SIMPLE
        );
        const formattedEndTime = slot.endTime.toLocaleString(
          DateTime.TIME_24_SIMPLE
        );
        const title = `${reason}\n${formattedStartTime} - ${formattedEndTime}`;

        return (
          <div
            className={engineerCalendarClasses.event}
            key={`${slot.date.toISODate()}-${id}`}
            style={eventStyles}
          >
            <div
              className={engineerCalendarClasses.textContainer}
              title={title}
              data-testid="engineerCalendarFixedAppointment"
            >
              <Typography
                className={engineerCalendarClasses.eventText}
                variant="bodyHeavy"
                data-testid="engineerCalendarFixedAppointmentReason"
                style={
                  shouldDisplayAppointmentTime
                    ? undefined
                    : shortAppointmentTextStyles
                }
              >
                {reason}
              </Typography>
              {shouldDisplayAppointmentTime ? (
                <Typography
                  className={engineerCalendarClasses.eventText}
                  data-testid="engineerCalendarFixedAppointmentTime"
                >
                  {slot.startTime.toLocaleString(DateTime.TIME_24_SIMPLE)} -{' '}
                  {slot.endTime.toLocaleString(DateTime.TIME_24_SIMPLE)}
                </Typography>
              ) : null}
            </div>
          </div>
        );
      })}
    </>
  );
}

export default memo(EngineerCalendarFixedAppointments);
