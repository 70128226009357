import { DateTime, Interval } from 'luxon';
import { DayOfWeek } from 'models/Adjustment';
import { Weekdays } from 'models/ShiftPattern';
import { SHORT_TIME_FORMAT } from 'constants/dates';

/**
 * Convert a Luxon `Interval` object to an array of `DateTime` objects for the start of each day
 * @param interval
 * @returns `DateTime[]` array of dates
 */
export const intervalToArrayByDay = (interval: Interval): DateTime[] =>
  interval.splitBy({ days: 1 }).map(dayInterval => dayInterval.start);

export const getFirstWeekAsDateArray = (interval: Interval): DateTime[] => {
  const end = DateTime.min(interval.end, interval.start.plus({ days: 6 }));
  return Interval.fromDateTimes(interval.start.startOf('day'), end.endOf('day'))
    .splitBy({ day: 1 })
    .map(d => d.start);
};

/**
 * Convert a Luxon `Interval` object to an array of unique weekday names for each day in the interval
 * @param interval `Interval`
 * @returns `string[]` array of weekday names
 */
export const getWeekDaysFromInterval = (interval: Interval) => {
  const firstWeekDates = getFirstWeekAsDateArray(interval);

  // This was added after a conversation with Alex Lopez, this wasn't caught in refinement as it's somewhat obscure
  // Alex wanted the days to be in the correct chronological order if the selected range contains every week day.
  // Using six days as a value since the range is inclusive.
  const sortedFirstWeekDates =
    interval.length('days') >= 6
      ? firstWeekDates.sort((a, b) => (a.weekday > b.weekday ? 1 : -1))
      : firstWeekDates;

  return sortedFirstWeekDates.map(date => date.weekdayLong);
};

/**
 * @param date
 * @returns correct day from `DayOfWeek` enum which matches the given date
 */
export const dateToDayOfWeek = (date: DateTime) =>
  Object.values(DayOfWeek)[date.weekday - 1];

/**
 * @param date
 * @returns correct day from `Weekdays` enum which matches the given date
 */
export const dateToWeekday = (date: DateTime) =>
  Object.values(Weekdays)[date.weekday - 1];

/**
 * Compares a start and end time to see if the start time is before the end time
 * @param {string} startTime
 * @param {string} endTime
 * @returns {boolean} whether the `startTime` is before the `endTime`
 */
export const compareTime = (startTime: string, endTime: string): boolean => {
  if (!startTime || !endTime) {
    return false;
  }

  const startTimeObj = DateTime.fromFormat(startTime, SHORT_TIME_FORMAT);
  const endTimeObj = DateTime.fromFormat(endTime, SHORT_TIME_FORMAT);

  return endTimeObj > startTimeObj;
};
