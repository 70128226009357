import { NavigationRoute } from 'views/Dashboard/routes';
import { jobTypeDomain } from 'models';
import EditJobTypeView from './EditJobType';
import AddJobTypeView from './AddJobType';

const jobsRoutes = (
  aegisEnableGenericModalForJobTypeListPage: boolean
): NavigationRoute[] => [
  {
    to: `/dashboard/${jobTypeDomain.urlPath}/:id/edit`,
    component: EditJobTypeView
  },
  ...(aegisEnableGenericModalForJobTypeListPage
    ? []
    : [
        {
          to: `/dashboard/${jobTypeDomain.urlPath}/create`,
          component: AddJobTypeView
        }
      ])
];

export default jobsRoutes;
