import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  inputWrapper: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
    '&:last-child': {
      marginBottom: 0
    }
  }
}));

const InputFieldWrapper = ({ children }: { children: React.ReactNode }) => {
  const classes = useStyles();

  return <div className={classes.inputWrapper}>{children}</div>;
};

export default InputFieldWrapper;
