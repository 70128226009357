import React, { memo } from 'react';
import { useTheme } from '@material-ui/core';
import { Layer, LayerProps } from 'react-map-gl';

interface OverlayProps {
  selectedEngineer?: string;
}

const MapOverlay = ({ selectedEngineer }: OverlayProps) => {
  const layerProps: LayerProps = {
    id: `map-overlay`,
    type: 'background',
    paint: {
      'background-color': useTheme().palette.schedule.map.overlay,
      'background-opacity': 0.7
    },
    layout: {
      visibility: selectedEngineer ? 'visible' : 'none'
    }
  };

  return (
    <div
      key="map-overlay"
      data-testid="mapOverlay"
      data-qa-id="mapOverlay"
      style={{}}
    >
      <Layer
        data-testid="map-overlay"
        data-qa-id="map-overlay"
        {...layerProps}
      />
    </div>
  );
};

export default memo(MapOverlay);
