import { FormValues, FormValue } from 'components/Forms';
import { AdjustmentType, Pattern, DayOfWeek } from 'models/Adjustment';
import { DateTime } from 'luxon';

export const getChangeDays = (fields: { [key: string]: any }) => {
  const dayPattern =
    '(Monday|Tuesday|Wednesday|Thursday|Friday|Saturday|Sunday)';
  const timePattern = '(startTime|endTime)';
  const checkBoxPattern = '(checkBox)';
  const changes = Object.entries(fields).reduce((acc, [key, value]) => {
    if (value === null) {
      return acc;
    }
    const dayKeyRegex = key.match(dayPattern);
    const timeKeyRegex = key.match(timePattern);
    const checkBoxRegex = key.match(checkBoxPattern);
    if (checkBoxRegex && checkBoxRegex.length > 0) {
      if (dayKeyRegex && dayKeyRegex.length > 0) {
        const dayKey = dayKeyRegex[0].toUpperCase();
        acc[dayKey] = null;
      }
      return acc;
    }
    if (dayKeyRegex && dayKeyRegex.length > 0) {
      if (timeKeyRegex && timeKeyRegex.length > 0) {
        const dayKey = dayKeyRegex[0].toUpperCase();
        const timeKey = timeKeyRegex[0];

        if (dayKey in acc) {
          acc[dayKey] = {
            ...acc[dayKey],
            [timeKey]: value.toFormat('HH:mm')
          };
        } else {
          acc[dayKey] = { [timeKey]: value.toFormat('HH:mm') };
        }
      }
    }
    return acc;
  }, {} as any);
  return changes;
};

export const getEfficiencyChangeDays = (
  fields: {
    [key in keyof typeof DayOfWeek]: true;
  }
) => Object.keys(fields).map(day => day.toUpperCase());

const getPattern = (pattern: FormValue, change: {} | []) => {
  return pattern?.toString() === Pattern.Daily
    ? {
        type: pattern
      }
    : {
        type: pattern,
        weekdays: Object.keys(change)
      };
};

export const processFormFields = (adjustmentType: AdjustmentType) => (
  values: FormValues
) => {
  const {
    startDate,
    endDate,
    pattern,
    reason,
    efficiency,
    patch,
    differentStartLocation,
    startPostcode,
    ...times
  } = values;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const days = Object.entries(times).filter(([key, value]) => value);
  const filteredDays = Object.fromEntries(days);

  const change = getChangeDays({
    ...filteredDays
  });

  const defaultRequestFields = {
    startDate: DateTime.fromISO(startDate as string).toISODate(),
    endDate: DateTime.fromISO(endDate as string).toISODate(),
    reason
  };

  if (adjustmentType === AdjustmentType.Shift) {
    return {
      ...defaultRequestFields,
      pattern: getPattern(Pattern.Weekly, change),
      changes: [
        {
          field: adjustmentType,
          change
        }
      ]
    };
  }

  if (adjustmentType === AdjustmentType.Patch) {
    let changes: { field: AdjustmentType; change: FormValue }[] = [
      {
        field: adjustmentType,
        change: patch
      }
    ];

    if (
      differentStartLocation &&
      JSON.parse(differentStartLocation as string)
    ) {
      changes = [
        ...changes,
        {
          field: AdjustmentType.Postcode,
          change: startPostcode
        }
      ];
    }

    return {
      ...defaultRequestFields,
      pattern: getPattern(pattern, filteredDays),
      changes
    };
  }

  return {
    ...defaultRequestFields,
    pattern: getPattern(pattern, filteredDays),
    changes: [
      {
        field: adjustmentType,
        change: efficiency
      }
    ]
  };
};
