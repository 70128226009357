/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import Patch from 'models/Patches';
import { DomainItem } from 'models/Domain';
import { patchDomain } from 'models';
import { AppThunk } from 'store';
import { FormValues } from 'components/Forms';
import {
  initialStateSlice,
  startLoading,
  loadingFailed,
  StateSlice,
  getPaginationReducers
} from 'store/slices';
import {
  fetchThunk,
  fetchItemThunk,
  createFilterQuery,
  QueryParams,
  deleteItemThunk,
  addItemThunk,
  editItemThunk,
  commonErrorHandling,
  fetchAllThunk
} from 'store/utils/';
import http from 'api/http';

export const patchByOutcodeUrl = '/patch/outcode/';
const sortPatchByName = (patch: Patch, nextPatch: Patch) =>
  patch.name.localeCompare(nextPatch.name);

const patches = createSlice({
  name: patchDomain.plural,
  initialState: initialStateSlice,
  reducers: {
    ...startLoading<Patch>(),
    ...loadingFailed<Patch>(),
    ...getPaginationReducers<Patch>(),
    getPatchesSuccess(state, { payload }: PayloadAction<StateSlice<Patch>>) {
      const { items, page } = payload;

      state.items = items.sort(sortPatchByName);
      state.page = page;
      state.isLoading = false;
      state.error = null;
    },
    getPatchSuccess(state, { payload: patchItem }: PayloadAction<Patch>) {
      if (state.items != null) {
        state.items = state.items.filter(patch => patch.id !== patchItem.id);
        state.items.push(patchItem);
        state.items.sort(sortPatchByName);
      }
      state.isLoading = false;
      state.error = null;
    },
    addPatchSuccess(state, { payload: addedPatch }: PayloadAction<Patch>) {
      // We could check here if we already have a patch with the same id
      // in the store, but I'll leave that up to the API to decide
      //
      // We could also either push the new item into state or dispatch `fetchPatches`
      // after `addPatchSuccess` in the thunk to refresh the state
      if (state.items != null) {
        state.items.push(addedPatch);
        state.items.sort(sortPatchByName);
      }

      state.isLoading = false;
      state.error = null;
    },

    editPatchSuccess(state, { payload: editedPatch }: PayloadAction<Patch>) {
      if (state.items != null) {
        state.items.filter(patch => patch.id !== editedPatch.id);
        state.items.push(editedPatch);
        state.items.sort(sortPatchByName);
      }

      state.isLoading = false;
      state.error = null;
    },
    deletePatchSuccess(
      state,
      { payload: deletedPatchId }: PayloadAction<string>
    ) {
      if (state.items != null) {
        state.items = state.items.filter(patch => patch.id !== deletedPatchId);
      }

      state.isLoading = false;
      state.error = null;
    }
  }
});

export const {
  requestStart,
  requestFail,
  setPagination: setPatchesPagination,
  getPatchesSuccess,
  getPatchSuccess,
  addPatchSuccess,
  editPatchSuccess,
  deletePatchSuccess
} = patches.actions;

export default patches.reducer;

export function getPatchByOutcode(outcode: string): AppThunk {
  return async dispatch => {
    const url = patchByOutcodeUrl + outcode;
    try {
      dispatch(requestStart());
      const { data } = await http.get<StateSlice<Patch>>(url);
      dispatch(getPatchesSuccess(data));
      return data;
    } catch (error) {
      commonErrorHandling(error, patchDomain.type);
      dispatch(requestFail(error.message));
      return null;
    }
  };
}

export function fetchAllPatches(): AppThunk {
  return fetchAllThunk(patchDomain, {
    start: requestStart,
    success: getPatchesSuccess,
    fail: requestFail
  });
}

export function fetchPatches(query?: QueryParams): AppThunk {
  if (query?.outcode) {
    return getPatchByOutcode(query.outcode.values[0]);
  }
  const queryParams = query ? { params: createFilterQuery(query) } : undefined;
  return fetchThunk<StateSlice<Patch>>({
    domain: patchDomain,
    requestStart,
    requestFail,
    requestSuccess: getPatchesSuccess,
    queryParams
  });
}
export function fetchPatch(id: string): AppThunk {
  return fetchItemThunk<Patch>(
    patchDomain,
    requestStart,
    requestFail,
    getPatchSuccess,
    id
  );
}

export function addPatch(item: FormValues): AppThunk {
  return addItemThunk<Patch>({
    domain: patchDomain,
    requestStart,
    requestFail,
    requestSuccess: addPatchSuccess,
    item
  });
}

export function editPatch(item: FormValues, itemId: string): AppThunk {
  return editItemThunk<Patch>({
    domain: patchDomain,
    requestStart,
    requestFail,
    requestSuccess: editPatchSuccess,
    itemId,
    item
  });
}

export function deletePatch(domainItem: DomainItem): AppThunk {
  return deleteItemThunk<Patch>({
    domain: patchDomain,
    requestStart,
    requestFail,
    requestSuccess: deletePatchSuccess,
    itemId: domainItem.id
  });
}
