import React, { memo, useState, useEffect } from 'react';

import { useHistory } from 'react-router-dom';
import Button from 'components/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckIcon from '@material-ui/icons/Check';
import { makeStyles, Theme } from '@material-ui/core';
import Typography from 'components/Typography';

export const useButtonStyles = makeStyles<Theme, number>(
  theme => ({
    wrapper: {
      display: 'flex',
      gap: theme.spacing(1)
    },
    spinnerWrapper: {
      position: 'relative',
      display: 'block'
    },
    success: {
      backgroundColor: theme.palette.primary.main
    },
    spinner: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: spinnerSize => -(spinnerSize / 2),
      marginLeft: spinnerSize => -(spinnerSize / 2),
      color: theme.palette.aegis.semantic.message.dark
    }
  }),
  { name: 'Button' }
);

interface FormButtonsProps {
  isSubmitting: boolean;
  success: boolean;
  customCancelHandler?: () => void;
}

const FormButtons = ({
  isSubmitting,
  success,
  customCancelHandler
}: FormButtonsProps) => {
  const history = useHistory();
  const classes = useButtonStyles();
  const [buttonSuccessState, setButtonSuccessState] = useState(false);
  const buttonResetTimeout = 2500;

  useEffect(() => {
    let stateResetTimeout: NodeJS.Timeout;
    if (success && !isSubmitting) {
      setButtonSuccessState(true);

      stateResetTimeout = setTimeout(() => {
        setButtonSuccessState(false);
      }, buttonResetTimeout);
    }
    return () => {
      clearTimeout(stateResetTimeout);
    };
  }, [success, isSubmitting]);

  const onCancel = (): void => {
    // for some reason our current lint does not want me to change this to a ternery:)
    if (customCancelHandler) {
      customCancelHandler();
    } else {
      history.goBack();
    }
  };

  const getSubmitButtonClass = () =>
    buttonSuccessState ? classes.success : undefined;

  const getSubmitButtonText = () => {
    if (buttonSuccessState) {
      return 'Saved';
    }
    if (isSubmitting) {
      return 'Saving';
    }
    return 'Save';
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.spinnerWrapper}>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className={getSubmitButtonClass()}
          disabled={isSubmitting}
          startIcon={
            buttonSuccessState && (
              <CheckIcon data-qa-id="createDomainSaveCompleteIcon" />
            )
          }
          data-qa-id="createDomainSave"
          data-testid="createDomainSave"
        >
          {getSubmitButtonText()}
        </Button>
        {isSubmitting && (
          <CircularProgress
            size={24}
            className={classes.spinner}
            data-qa-id="createDomainSpinner"
          />
        )}
      </div>
      <Button
        variant="text"
        onClick={onCancel}
        data-qa-id="createDomainCancel"
        data-testid="createDomainCancel"
      >
        Cancel
      </Button>
    </div>
  );
};

export default memo(FormButtons);

interface ButtonProps {
  children: React.ReactNode;
  onClick?: () => void;
  testId?: string;
  // eslint-disable-next-line react/no-unused-prop-types
  className?: string;
  disabled?: boolean;
}

export function SubmitButton({
  children,
  onClick,
  testId,
  disabled
}: ButtonProps) {
  return (
    <Button
      color="primary"
      size="small"
      onClick={onClick}
      data-testid={testId}
      disabled={disabled}
    >
      {children}
    </Button>
  );
}

export function SecondaryButton({
  children,
  onClick,
  testId,
  className,
  disabled
}: ButtonProps) {
  return (
    <Button
      color="secondary"
      size="small"
      onClick={onClick}
      data-testid={testId}
      className={className}
      disabled={disabled}
    >
      {children}
    </Button>
  );
}

interface AsyncButtonProps extends ButtonProps {
  loading: boolean;
}

export function AsyncSubmitButton({
  children,
  onClick,
  testId,
  disabled,
  loading
}: AsyncButtonProps) {
  const spinnerSize = 20;
  const classes = useButtonStyles(spinnerSize);

  return (
    <Button
      color="primary"
      size="small"
      onClick={onClick}
      data-testid={testId}
      disabled={disabled ?? loading}
    >
      {loading ? (
        <div data-testid="asyncSubmitButtonLoadingWrapper">
          <Typography variant="srOnly">Loading</Typography>
          <CircularProgress
            size={spinnerSize}
            className={classes.spinner}
            data-qa-id="asyncSubmitButtonSpinner"
          />
        </div>
      ) : (
        children
      )}
    </Button>
  );
}
