import { Dispatch, SetStateAction } from 'react';
import { FlyToInterpolator, ViewportProps } from 'react-map-gl';
import { DateTime } from 'luxon';
import Supercluster, { AnyProps, PointFeature } from 'supercluster';
import { GeoJsonProperties } from 'geojson';
import {
  ScheduleAssignmentType,
  GeoLocation,
  EngineerSchedule,
  ScheduleUnassignedAppointment,
  JobDetailsFields,
  ScheduleAssignment
} from 'models/Schedule';
import Appointment from 'models/Appointment';
import { MapboxProps } from 'react-map-gl/src/mapbox/mapbox';
import { Status } from '../EngineerInfo/PublishStatusIcon';

export interface EngineerPointInfo {
  assignment: ScheduleAssignment;
  firstName: string;
  lastName: string;
  scheduleStatus: Status;
  engineerUnavailable: boolean;
}

type Bounds = {
  W: number;
  S: number;
  E: number;
  N: number;
};

export const defaultBounds: Bounds = {
  W: 90,
  S: 180,
  E: -90,
  N: -180
};

export const boundsSet: Record<string, Bounds> = {
  screen: { ...defaultBounds }
};

// [West,South,East,North] or [minimum longitude, minimum latitude, maximum longitude, maximum latitude].
export const calculateBoundingBox = (location: GeoLocation, id: string) => {
  // builds obj with lat/long for every engineer id and 'screen'
  const comparisonExec = (key: string) => {
    if (!boundsSet[id]) {
      boundsSet[id] = { ...defaultBounds };
    }

    if (location.long > boundsSet[key].E) {
      boundsSet[key].E = location.long;
    }
    if (location.long < boundsSet[key].W) {
      boundsSet[key].W = location.long;
    }
    if (location.lat < boundsSet[key].S) {
      boundsSet[key].S = location.lat;
    }
    if (location.lat > boundsSet[key].N) {
      boundsSet[key].N = location.lat;
    }
  };
  comparisonExec(id);
  comparisonExec('screen');
};

export const getPointsFromSchedule = (
  engineerSchedule: EngineerSchedule
): EngineerPointInfo[] => {
  return engineerSchedule.assignments
    .filter(
      assignment =>
        assignment.type === ScheduleAssignmentType.Start ||
        assignment.type === ScheduleAssignmentType.Job
    )
    .map(assignment => {
      return {
        firstName: engineerSchedule.engineer.firstName,
        lastName: engineerSchedule.engineer.lastName,
        scheduleStatus: engineerSchedule.status,
        assignment,
        engineerUnavailable: engineerSchedule.unavailableReason != null
      };
    });
};

export const clusterPointData = (
  engineers: EngineerSchedule[],
  boxes: Record<string, Bounds>
): PointFeature<GeoJsonProperties>[] =>
  engineers.map(engineerSchedule => {
    const engineerPoints = getPointsFromSchedule(engineerSchedule);
    const engineerBoundingBox =
      boxes[engineerSchedule.engineer.id] || defaultBounds; // default value is needed due to undefined from tests
    return {
      type: 'Feature',
      properties: {
        cluster: false,
        engineerPoints,
        engineerId: engineerSchedule.engineer.id,
        geometry: engineerSchedule.geometry,
        firstName: engineerSchedule.engineer.firstName,
        lastName: engineerSchedule.engineer.lastName
      },
      geometry: {
        type: 'Point',
        coordinates: [
          engineerBoundingBox.E +
            (engineerBoundingBox.W - engineerBoundingBox.E) / 2,
          engineerBoundingBox.N +
            (engineerBoundingBox.S - engineerBoundingBox.N) / 2
        ]
      }
    };
  });

// Zoom on cluster click
export const handleClusterZoom = (
  id: number,
  latitude: number,
  longitude: number,
  supercluster: Supercluster<GeoJsonProperties, AnyProps>,
  setViewport: Dispatch<SetStateAction<MapboxProps>>,
  viewport: MapboxProps
) => {
  const expansionZoom = Math.min(supercluster.getClusterExpansionZoom(id), 20);
  setViewport({
    ...viewport,
    latitude,
    longitude,
    zoom: expansionZoom,
    transitionInterpolator: new FlyToInterpolator({
      speed: 2
    }),
    transitionDuration: 'auto'
  } as ViewportProps);
};

interface EngineerScheduleInfo {
  engineerId: string;
  firstName: string;
  lastName: string;
  scheduleStatus: Status;
}

export const getFormattedTime = (appointment: Appointment): string => {
  return `${DateTime.fromFormat(appointment.slot.startTime, 'HH:mm').toFormat(
    'HH:mm'
  )}-${DateTime.fromFormat(appointment.slot.endTime, 'HH:mm').toFormat(
    'HH:mm'
  )}`;
};

export const getHydratedJobDetails = (
  assignment: ScheduleAssignment | ScheduleUnassignedAppointment
): JobDetailsFields => {
  return {
    type: assignment.appointment.appointmentType?.type,
    fuelType: assignment.appointment.appointmentType?.fuelType ?? '',
    jobTypeId: assignment.appointment.jobType ?? '',
    id: assignment.appointment.id ?? '',
    postcode: assignment.location.postcode ?? '',
    timeSlot: assignment.appointment
      ? getFormattedTime(assignment.appointment)
      : '',
    complaints: assignment.appointment.complaints ?? [],
    isOpen: true,
    atRisk: assignment.appointment.atRisk,
    status: assignment.appointment.status,
    customer: assignment.appointment.customerDetails
      ? {
          firstName: assignment.appointment.customerDetails?.firstName,
          lastName: assignment.appointment.customerDetails?.lastName
        }
      : undefined
  };
};

export const getHydratedJobDetailsWithAssignedSchedule = (
  assignment: ScheduleAssignment | ScheduleUnassignedAppointment,
  engineerScheduleInfo: EngineerScheduleInfo
): JobDetailsFields => {
  return {
    ...getHydratedJobDetails(assignment),
    assigned: {
      engineerId: engineerScheduleInfo.engineerId,
      firstName: engineerScheduleInfo.firstName,
      lastName: engineerScheduleInfo.lastName,
      publishedStatus: engineerScheduleInfo.scheduleStatus
    }
  };
};
