import keycloak from 'auth/keycloak';

// As Keycloak is on another domain, we can't simply use the <Redirect /> router element, and instead
// we have to use raw JS to update the window.location. The `return null` is required for React.
const redirectToKeycloakLogin = (from: string | null) => {
  window.location.replace(
    keycloak.createLoginUrl({
      redirectUri: `${window.location.origin}${from || '/'}`
    })
  );
  return null;
};

const determineFrom = (location?: {
  state?: { from?: string };
}): string | null => {
  if (location && location.state && location.state.from) {
    return location.state.from;
  }

  return null;
};

export const RedirectToSignIn = ({
  location
}: {
  location?: { state?: { from?: string } };
}) => {
  const from = determineFrom(location);

  return redirectToKeycloakLogin(from);
};
