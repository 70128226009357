import React from 'react';

import Modal from 'components/Modal';
import CustomButton from 'components/Button';

interface PublishDialogProps {
  children: React.ReactNode;
  onClose: () => void;
  onPublish: () => void;
  open: boolean;
}

const PublishDialog = ({
  children,
  onClose,
  onPublish,
  open
}: PublishDialogProps) => (
  <Modal
    title="Publish Schedule"
    testId="publishModal"
    open={open}
    onClose={onClose}
    modalActions={
      <>
        <CustomButton onClick={onClose} color="secondary">
          Cancel
        </CustomButton>
        <CustomButton
          color="primary"
          size="small"
          onClick={onPublish}
          data-testid="publishModalSubmitBtn"
        >
          Publish
        </CustomButton>
      </>
    }
  >
    {children}
  </Modal>
);

export default PublishDialog;
