import React from 'react';
import { makeStyles } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Main from 'components/Main';

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white
    }
  },
  root: {
    display: 'flex'
  },
  paper: {
    marginTop: theme.spacing(8),
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  spinnerWrapper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }
}));

export const Loading = () => {
  const classes = useStyles();

  return (
    <div className={classes.root} data-testid="appLoading">
      <Main>
        <div className={classes.paper}>
          <CircularProgress
            size={100}
            className={classes.spinnerWrapper}
            color="primary"
          />
        </div>
      </Main>
    </div>
  );
};
