import React from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

import { RedirectToSignIn } from 'views/Auth';
import Dashboard from 'layout/Dashboard/Dashboard';
import keycloak, { isAuthenticated } from '../auth/keycloak';

export const RedirectComponent = () => {
  const location = useLocation();

  return (
    <Redirect
      to={{
        pathname: '/auth',
        state: { from: location.pathname }
      }}
    />
  );
};

export const Router = () => {
  const isLoggedIn = isAuthenticated(keycloak);

  return (
    <Switch>
      <Route path="/auth" component={RedirectToSignIn} exact />
      <Redirect path="/" exact to="/dashboard" />

      <Route
        path="/dashboard"
        render={() => (isLoggedIn ? <Dashboard /> : <RedirectComponent />)}
      />
    </Switch>
  );
};
