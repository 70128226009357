import React, { useState, memo } from 'react';
import {
  makeStyles,
  Theme,
  CircularProgress,
  useTheme
} from '@material-ui/core';
import Button from 'components/Button';
import { usePrevious } from 'utils/custom-hooks/use-previous-hook';
import { useSelector } from 'react-redux';
import { scheduleSelector } from 'store/slices/schedule';
import Typography from 'components/Typography';

interface PublishFooterProps {
  unpublishedCount: number;
  publishingCount: number;
  onPublish: () => void;
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    container: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(1.5, 0),
      zIndex: 1,
      backgroundColor: theme.palette.common.white
    },
    text: {
      marginRight: theme.spacing(1)
    },
    button: {
      margin: 0,
      minWidth: 98,
      minHeight: 36,
      fontSize: 14,
      fontWeight: 600,
      letterSpacing: '1.25px',
      textTransform: 'uppercase',
      '&$disabled': {
        background: theme.palette.aegis.semantic.message.light,
        color: theme.palette.aegis.semantic.message.dark
      }
    },
    spinner: {
      color: theme.palette.common.white
    },
    disabled: {} // it has to be empty and be named "disabled"
  }),
  { name: 'PublishFooter' }
);

function PublishFooter({
  unpublishedCount,
  publishingCount,
  onPublish
}: PublishFooterProps) {
  const classes = useStyles();
  const [publishPressed, setPublishPressed] = useState<number | boolean>(false);
  const previousPublishingCount = usePrevious(publishingCount);
  const { error } = useSelector(scheduleSelector);

  const theme = useTheme();

  const onButtonPress = () => {
    if (!publishPressed) {
      // 'Publish' button state
      setPublishPressed(1);
    } else {
      // 'Confirm' button state
      setPublishPressed(2);
      onPublish();
    }
  };
  const state = (() => {
    // When you publish all schedules successfully without an error this component is being rendered
    if (
      !error &&
      publishingCount === 0 &&
      previousPublishingCount &&
      publishPressed === 2
    ) {
      setTimeout(() => setPublishPressed(false), 4000);
      return {
        text: `${previousPublishingCount} schedules have been published`,
        button: (
          <svg width={19} height={15} viewBox="0 0 19 15" fill="none">
            <path
              d="M6.252 11.252l-4.17-4.17L.667 8.497l5.585 5.585 12-12L16.837.667 6.252 11.252z"
              fill={theme.palette.common.white}
            />
          </svg>
        ),
        buttonGreyout: false,
        buttonDisabled: true
      };
    }
    // this step checks if you want to confirm the publishing
    if (unpublishedCount > 0 && publishingCount > 0 && publishPressed === 1) {
      return {
        text: `Are you sure you would like to publish ${unpublishedCount} schedules?`,
        button: 'Confirm',
        buttonGreyout: false,
        buttonDisabled: false
      };
    }
    // this step confirms publishing and executes it if no errors occure
    if (
      unpublishedCount > 0 &&
      publishingCount > 0 &&
      publishPressed === 2 &&
      error === null
    ) {
      return {
        text: '',
        button: (
          <CircularProgress
            data-testid="publishingProgress"
            classes={{ circle: classes.spinner }}
            size={20}
          />
        ),
        buttonGreyout: false,
        buttonDisabled: true
      };
    }
    // shows the unpublished schedules count
    if (unpublishedCount > 0 && publishingCount > 0 && !publishPressed) {
      return {
        text: `${unpublishedCount} unpublished schedules`,
        button: 'Publish',
        buttonGreyout: false,
        buttonDisabled: false
      };
    }
    // if there are no unpublished schedules
    // unpublishedCount == 0 && publishingCount == 0
    return {
      text: 'No unpublished schedules',
      button: 'Publish',
      buttonGreyout: true,
      buttonDisabled: true
    };
  })();

  return (
    <div className={classes.container}>
      <Typography data-testid="publishText" className={classes.text}>
        {state.text}
      </Typography>
      <Button
        onClick={onButtonPress}
        disabled={state.buttonDisabled}
        classes={{
          root: classes.button,
          disabled: state.buttonGreyout ? classes.disabled : ''
        }}
        color="primary"
        data-testid="publishButton"
      >
        {state.button}
      </Button>
    </div>
  );
}

const customMemoCheck = (
  prevProps: PublishFooterProps,
  nextProps: PublishFooterProps
) =>
  prevProps.unpublishedCount === nextProps.unpublishedCount &&
  prevProps.publishingCount === nextProps.publishingCount;

export default memo(PublishFooter, customMemoCheck);
