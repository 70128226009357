import * as Yup from 'yup';
import {
  FormValues,
  InputFieldProps,
  yupSchemaToValidationFunc
} from 'components/Forms';
import { AppointmentSlot, Note, NoteType } from 'models/Appointment';
import { JobDetailsResponse } from 'models/JobDetails';

const editJobSlots = [
  '08:00-12:00',
  '10:00-14:00',
  '12:00-16:00',
  '14:00-18:00'
];

const renderedSlotOptions = editJobSlots.map(slot => ({
  value: slot,
  label: slot,
  key: slot
}));

export const editJobFormFields: InputFieldProps[] = [
  {
    id: 'slot',
    label: 'Time slots',
    name: 'slot',
    qaId: 'edit-job-slot',
    inputType: 'select',
    options: renderedSlotOptions
  },
  {
    id: 'notes',
    label: 'Booking notes',
    name: 'notes',
    qaId: 'edit-job-notes',
    inputType: 'textarea'
  }
];

const editJobFormFieldsValidation = Yup.object().shape({
  slot: Yup.string().required('Required')
});

export const editJobFormValidation = yupSchemaToValidationFunc(
  editJobFormFieldsValidation
);

export const processEditJobFormFields = (job: JobDetailsResponse) => (
  values: FormValues
): FormValues => {
  const [startTime, endTime] = (values.slot as string).split('-');
  const slot: AppointmentSlot = {
    date: job.slot.date,
    startTime,
    endTime
  };

  const notes: Note[] = (values.notes as string)
    .trim()
    .split('\n')
    .filter(subString => subString.length > 0)
    .map(note => {
      const foundOriginalNote = job.notes.find(
        current => current.note === note
      );

      return {
        type: foundOriginalNote ? foundOriginalNote.type : NoteType.SCHEDULER,
        note: note.trim()
      };
    });

  return {
    ...job,
    slot,
    notes
  } as FormValues;
};
