import { NavigationRoute } from 'views/Dashboard/routes';
import { appointmentDomain, jobDomain } from 'models';
import JobDetailsPage from 'views/Jobs/JobDetailsPage';
import AddAppointmentView from './AddAppointment';
import EditAppointmentView from './EditAppointment';

const appointmentsRoutes: NavigationRoute[] = [
  {
    to: `/dashboard/${appointmentDomain.urlPath}/create`,
    component: AddAppointmentView
  },
  {
    to: `/dashboard/${appointmentDomain.urlPath}/:id/edit`,
    component: EditAppointmentView
  },
  {
    to: `/dashboard/${jobDomain.urlPath}/:id`,
    component: JobDetailsPage
  }
];

export default appointmentsRoutes;
