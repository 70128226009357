import React from 'react';
import { makeStyles } from '@material-ui/core';
import { JobDetailsChip } from 'components/Chip';
import { Status as PublishedStatus } from 'components/Schedule/EngineerInfo/PublishStatusIcon';
import { JobSheetHeaderData } from 'models/JobDetails';
import { getUIAppointmentStatus } from 'utils/jobStatus';
import { JobChipType } from 'components/Chip/JobDetailsChip';
import { redirectToEngineer } from 'utils';

interface JobSheetHeaderProps {
  headerData: JobSheetHeaderData;
  assignedEngineerId?: string;
}

const useStyles = makeStyles(theme => ({
  headerContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(1),
    marginBottom: theme.spacing(3)
  }
}));

const getEngineerChipProp = (firstName?: string, lastName?: string) => {
  // this check would make the chip display 'UNASSIGNED' when unassigned or fixed appointment
  if (!firstName || !lastName) {
    return null;
  }

  return {
    firstName,
    lastName,
    publishedStatus: PublishedStatus.PUBLISHED
  };
};

export default function JobSheetHeader({
  headerData,
  assignedEngineerId
}: JobSheetHeaderProps) {
  const classes = useStyles();
  const onEngineerChipClick = () => {
    if (assignedEngineerId) {
      redirectToEngineer(assignedEngineerId);
    }
  };

  return (
    <div className={classes.headerContainer}>
      <JobDetailsChip
        onClick={onEngineerChipClick}
        data-testid="jobSheetHeaderChip-assigned"
        type={JobChipType.Assigned}
        value={getEngineerChipProp(
          headerData.engineerFirstName,
          headerData.engineerLastName
        )}
      />
      {headerData.postcode && (
        <JobDetailsChip
          data-testid="jobSheetHeaderChip-postcode"
          type={JobChipType.Postcode}
          value={headerData.postcode}
        />
      )}
      {headerData.slot && (
        <JobDetailsChip
          data-testid="jobSheetHeaderChip-timeSlot"
          type={JobChipType.Timeslot}
          value={headerData.slot}
        />
      )}
      <JobDetailsChip
        data-testid="jobSheetHeaderChip-status"
        type={JobChipType.Status}
        value={getUIAppointmentStatus(headerData.status)}
      />
      {headerData.atRisk && (
        <JobDetailsChip
          data-testid="jobSheetHeaderChip-atRisk"
          type={JobChipType.AtRisk}
          value={null}
        />
      )}
    </div>
  );
}
