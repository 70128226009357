import React from 'react';

import { makeStyles } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';

import { LinkButton } from 'components/Button';
import Typography from 'components/Typography';

interface HoldingPageProps {
  text: string;
  link?: string;
}

const useStyles = makeStyles(
  theme => ({
    background: {
      display: 'flex',
      justifyContent: 'center',
      width: '100vw',
      height: '100vh',
      backgroundColor: theme.palette.common.black
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      height: 'fit-content',
      gap: theme.spacing(1),
      width: theme.containers.small,
      marginTop: '200px',
      minHeight: '200px',
      backgroundColor: theme.palette.common.white,
      borderRadius: theme.spacing(0.5),
      padding: theme.spacing(2)
    },
    header: {
      alignItems: 'center',
      display: 'flex',
      backgroundColor: theme.palette.warning.light,
      border: `1px solid ${theme.palette.warning.dark}`,
      borderRadius: theme.spacing(0.5),
      color: theme.palette.warning.dark,
      gap: theme.spacing(1),
      padding: theme.spacing(2),
      margin: 0
    },
    message: {
      whiteSpace: 'pre-wrap'
    },
    buttonWrapper: {
      alignSelf: 'end',
      marginTop: 'auto'
    },
    button: {
      color: theme.palette.aegis.semantic.warning.alt,
      '&, &:hover': {
        borderColor: theme.palette.aegis.semantic.warning.alt
      },
      '&:hover': {
        backgroundColor: theme.palette.aegis.semantic.warning.translucent
      },
      '& .MuiTouchRipple-child': {
        backgroundColor: theme.palette.aegis.semantic.warning.alt
      }
    }
  }),
  { name: 'HoldingPage' }
);

const HoldingPage = ({ link, text }: HoldingPageProps) => {
  const classes = useStyles();
  return (
    <div className={classes.background}>
      <div className={classes.container}>
        <Typography variant="h2" component="h1" className={classes.header}>
          <ErrorIcon />
          Aegis Unavailable
        </Typography>
        <Typography data-testid="message" className={classes.message}>
          {text}
        </Typography>
        {!link ? null : (
          <div className={classes.buttonWrapper}>
            <LinkButton
              url={link}
              className={classes.button}
              label="More Details"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default HoldingPage;
