import React, { useState, useEffect, useRef } from 'react';
import { MuiThemeProvider, createTheme } from '@material-ui/core';
import { FormApi, SubmissionErrors } from 'final-form';
import { Form, FormValues, InputFieldProps } from 'components/Forms';
import { SecondaryButton, SubmitButton } from 'components/Forms/Buttons';
import Modal from 'components/Modal';
import defaultTheme, { modalFormInputOverrides } from 'styles/defaultTheme';
import Skill from 'models/Skills';
import Appointment from 'models/Appointment';
import JobType from 'models/JobTypes';
import Engineer from 'models/Engineers';
import { Domain } from 'models/Domain';
import { capitalise } from 'utils';

export enum FormModalMode {
  ADD = 'add',
  EDIT = 'edit'
}

export type FormModalItem = Appointment | JobType | Engineer | Skill;
type FormValuesFn = (values: FormValues) => void;
type ReturnVoidFn = () => void;

export interface FormModalProps {
  fields: InputFieldProps[];
  initialValues?: Record<string, any>;
  domain: Domain;
  open: boolean;
  currentItem?: FormModalItem;
  validationFunc: (values: FormValues) => SubmissionErrors;
  onLoad?: ReturnVoidFn;
  onAdd: FormValuesFn;
  onEdit: FormValuesFn;
  onSaved?: ReturnVoidFn;
  onCancel: ReturnVoidFn;
}

/**
 * This component provides a simple generic modal for use with add/edit flows for engineer appointments, job types, engineers and skills
 * @param {InputFieldProps[]} fields - An array of input field properties
 * @param {Record<string, any>} [initialValues] - The initial values for the form if any, this is only used in `EDIT` mode (optional)
 * @param {Domain} domain - The `Domain` object for a given slice object. This is used for labelling
 * @param {boolean} open - Whether the modal is currently being displayed or not
 * @param {FormModalItem} [currentItem] - The domain item currently being edited
 * @param {(values: FormValues) => SubmissionErrors} [validationFunc] - The validation function,
 * created by calling `yupSchemaToValidationFunc`with a valid `Yup` schema (optional)
 * @param {() => void} [onLoad] - A callback that is triggered when the modal mounts
 * @param {() => void | Promise<void>} onAdd - A callback that is triggered when a domain item is sucessfuly added
 * @param {() => void | Promise<void>} onEdit - A callback that is triggered when a domain item is sucessfuly edited
 * @param {() => void} [onSaved] - A callback that is triggered when a domain item is sucessfuly added or edited (optional)
 * @param {() => void} onCancel - A callback that is triggered when the user closes the modal or clicks on the cancel button in the form
 */
export default function FormModal({
  fields,
  initialValues,
  domain,
  open,
  currentItem,
  validationFunc,
  onLoad,
  onAdd,
  onEdit,
  onSaved,
  onCancel
}: FormModalProps) {
  const [success, setSuccess] = useState(false);
  const formRef = useRef<FormApi>();

  const mode = currentItem ? FormModalMode.EDIT : FormModalMode.ADD;

  const itemLabel = capitalise(domain.singular);
  const itemTestIdLabel = capitalise(domain.type);

  useEffect(() => {
    onLoad?.();
  }, []);

  useEffect(() => {
    if (success) {
      onSaved?.();
    }
  }, [success]);

  return (
    <Modal
      title={
        mode === FormModalMode.ADD ? `Add ${itemLabel}` : `Edit ${itemLabel}`
      }
      testId="formModal"
      open={open}
      onClose={onCancel}
      modalActions={
        <>
          <SecondaryButton
            onClick={onCancel}
            testId={`addEdit${itemTestIdLabel}ModalCancel`}
          >
            Cancel
          </SecondaryButton>
          <SubmitButton
            onClick={() => formRef.current?.submit()}
            testId={`addEdit${itemTestIdLabel}ModalSubmit`}
          >
            Save
          </SubmitButton>
        </>
      }
    >
      <MuiThemeProvider
        theme={createTheme({
          ...defaultTheme,
          overrides: { ...defaultTheme.overrides, ...modalFormInputOverrides }
        })}
      >
        <Form
          validationFunc={validationFunc}
          fields={fields}
          initialValues={initialValues}
          onSubmit={mode === FormModalMode.ADD ? onAdd : onEdit}
          goBackToPreviousPage={false}
        >
          {({ form, submitSucceeded, submitErrors }) => {
            setSuccess(!submitErrors && submitSucceeded);

            formRef.current = form;
          }}
        </Form>
      </MuiThemeProvider>
    </Modal>
  );
}
