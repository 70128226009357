import React from 'react';
import { TableCell } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { appointmentDomain, engineerDomain } from 'models';
import ListView from 'components/Crud/views/List';
import { useAppointmentsState } from 'store/selectors';
import {
  useTableStyles,
  ListTable,
  TableActions,
  useTableRowActionsState
} from 'components/Crud/ListTable/ListTable';
import ListTableRow from 'components/Crud/ListTable/ListTableRow';
import { AppointmentCategory } from 'models/Appointment';
import { capitalise } from 'utils';
import { SortByOrder } from 'components/Crud';
import { QueryParams } from 'store/utils';
import { dashIfFalsy } from 'components/JobDetailsSheet/jobsHelper';
import { DateTime } from 'luxon';

const AppointmentsView = () => {
  const { items: appointmentsData } = useAppointmentsState();
  const { id } = useParams<{ id: string }>();
  const classes = useTableStyles();
  const { rowHovered, setRowHovered } = useTableRowActionsState();

  const listEngineerAppointmentsFilter: QueryParams = {
    category: {
      values: [AppointmentCategory.MEETING],
      operator: '='
    },
    engineers: { values: [id], operator: 'in' }
  };
  const listEngineerAppointmentsSortBy = {
    field: 'date',
    order: SortByOrder.DESCENDING
  };

  const columnsWidth = [170, 120, 110, 200, 300, 60];

  if (!id) {
    return null;
  }

  const dispatchProps = [
    listEngineerAppointmentsFilter,
    { pageIndex: 0, pageSize: 25 },
    undefined,
    listEngineerAppointmentsSortBy,
    undefined
  ];

  return (
    <ListView
      domain={appointmentDomain}
      filter={listEngineerAppointmentsFilter}
      sortBy={listEngineerAppointmentsSortBy}
      showPageHeader={false}
      dispatchProps={dispatchProps}
    >
      {({ onEdit, onDelete }) =>
        appointmentsData == null ? null : (
          <section className={classes.tableContainer}>
            <ListTable
              domain={appointmentDomain}
              domainItems={appointmentsData}
              columnsWidth={columnsWidth}
              setRowHovered={(row: number | boolean) => setRowHovered(row)}
              rowHovered={rowHovered}
              title={capitalise(appointmentDomain.plural)}
              label={`A table of ${engineerDomain.label} ${appointmentDomain.plural}`}
              headers={['Date', 'Time', 'Postcode', 'Reason', 'Notes']}
              filter={listEngineerAppointmentsFilter}
              sortBy={listEngineerAppointmentsSortBy}
            >
              {appointmentsData.map((appointment, index) => (
                <ListTableRow
                  key={appointment.id}
                  data-testid={`tableRow-${appointment.id}`}
                >
                  <TableCell className={classes.tableCell}>
                    {dashIfFalsy(
                      DateTime.fromISO(appointment.slot.date).toFormat(
                        'ccc dd LLL yyyy'
                      )
                    )}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {dashIfFalsy(
                      `${appointment.slot.startTime}-${appointment.slot.endTime}`
                    )}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {dashIfFalsy(appointment.address?.postcode)}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {dashIfFalsy(appointment.reason)}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {dashIfFalsy(
                      appointment.notes.reduce((acc, curr) => {
                        if (curr.note === '') {
                          return acc;
                        }
                        return `${acc}\n${curr.note}`;
                      }, '')
                    )}
                  </TableCell>
                  {rowHovered !== index ? (
                    <TableCell className={classes.tableCell}>&nbsp;</TableCell>
                  ) : (
                    <TableActions
                      onEdit={onEdit}
                      onDelete={onDelete}
                      domain={appointmentDomain}
                      domainItem={appointment}
                      overrideUrl={`/dashboard/${appointmentDomain.urlPath}`}
                    />
                  )}
                </ListTableRow>
              ))}
            </ListTable>
          </section>
        )
      }
    </ListView>
  );
};

export default AppointmentsView;
