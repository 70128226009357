import React from 'react';

import { useDragLayer } from 'react-dnd';
import { makeStyles, createStyles } from '@material-ui/core';

import JobCard, { JobCardViewMode } from 'components/Schedule/JobCard/JobCard';
import { JobDndItem } from '../EngineerRow/EngineerRow';

interface Offset {
  x: number;
  y: number;
}

const useStyles = makeStyles(() =>
  createStyles({
    layer: {
      position: 'fixed',
      pointerEvents: 'none',
      zIndex: 100,
      left: 0,
      opacity: 0.5,
      top: 0
    }
  })
);

const computeCardStyle = (initialOffset: Offset, currentOffset: Offset) => {
  if (!initialOffset || !currentOffset) {
    return {
      display: 'none'
    };
  }
  const { x, y } = currentOffset;
  const transform = `translate(${x}px, ${y}px)`;

  return {
    transform,
    WebkitTransform: transform
  };
};

export const CustomDragLayer = () => {
  const classes = useStyles();

  const { isDragging, initialOffset, currentOffset, item } = useDragLayer(
    (monitor: any) => ({
      item: monitor.getItem() as JobDndItem,
      initialOffset: monitor.getInitialSourceClientOffset(),
      currentOffset: monitor.getSourceClientOffset(),
      isDragging: monitor.isDragging()
    })
  );

  if (!isDragging) {
    return null;
  }

  return (
    <div
      style={computeCardStyle(initialOffset, currentOffset)}
      className={classes.layer}
    >
      <JobCard
        appointmentId={item.appointmentId}
        slot={item.slot}
        jobCardStyleType={item.jobCardStyleType}
        duration={item.duration}
        label={item.label}
        jobType={item.jobType}
        driveTimeTo={item.driveTimeTo}
        viewMode={JobCardViewMode.TIMELINE}
        hideTravelTime
        assigned={item.assigned}
      />
    </div>
  );
};
