import React, { memo } from 'react';
import {
  FormControlLabel,
  FormHelperText,
  FormLabel,
  RadioGroup,
  FormControl
} from '@material-ui/core';
import { FieldMetaState } from 'react-final-form';
import { formStyles } from './styles';
import { FieldInputValue, InputFieldProps, FormValue } from './utils/types';
import { Field } from '.';

interface RadioProps {
  field: InputFieldProps;
  meta?: FieldMetaState<FormValue>;
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    value: string
  ) => void;
  value?: string;
}

const Radio = ({ field, meta, onChange, value, ...props }: RadioProps) => {
  const helperText =
    meta?.touched && meta?.error ? meta?.error : field.helperText;
  const classes = formStyles();
  const id = field.id || field.name;
  const testId = field.qaId || id;

  return (
    <div className={classes.radio} data-testid="radioField">
      <FormControl
        component="div"
        error={meta?.touched && Boolean(meta?.error)}
      >
        <FormLabel data-testid={`${id}Label`}>{field.label}</FormLabel>
        <RadioGroup
          id={id}
          {...props}
          aria-label={field.label}
          className={classes.field}
          value={value}
          onChange={onChange}
          data-testid={testId}
        >
          {field.options?.map((option: FieldInputValue) => (
            <FormControlLabel
              control={
                <Field
                  key={field.id}
                  field={field}
                  value={value}
                  name={field.name}
                />
              }
              key={option.key || option.value}
              value={option.value}
              label={option.label}
            />
          ))}
        </RadioGroup>
        <FormHelperText
          error={Boolean(meta?.touched && meta?.error)}
          data-testid={`${testId}-helper-text`}
        >
          {helperText}
        </FormHelperText>
      </FormControl>
    </div>
  );
};

export default memo(Radio);
