import { makeStyles, Paper } from '@material-ui/core';
import { AxiosError, AxiosResponse } from 'axios';
import { DateTime, Info } from 'luxon';
import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { Dispatch as UseDispatch } from 'redux';

import http from 'api/http';
import { SMETS2_DUEL_FUEL_INSTALLATION_JOB_TYPE_ID } from 'constants/capacity';
import { capacityUtilisationDomain, engineerSummaryDomain } from 'models';
import Appointment from 'models/Appointment';
import { CapacityUtilisation } from 'models/Capacity';
import Engineer, { EngineerSummary } from 'models/Engineers';
import { Page } from 'models/Page';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchEngineerSummary } from 'store/endpoints/engineerSummary';
import { useAppointmentsData, useEngineerData } from 'store/selectors';
import {
  commonErrorHandling,
  createFilterQuery,
  displayErrorSnackbar,
  getErrorMessageAndStatus,
  paramsSerializer,
  QueryParams
} from 'store/utils';
import { fetchAllEngineerFixedAppointments } from 'store/slices/appointments';
import { fetchAllSkills } from 'store/slices/skills';
import EngineerCalendarBody from './EngineerCalendarBody';
import EngineerCalendarHeader from './EngineerCalendarHeader';
import EngineerCalendarKey from './EngineerCalendarKey';
import {
  AdjustmentFormModals,
  TemporaryAdjustmentFormContextProvider
} from '../EngineerSchedule/AdjustmentFormModals';

export interface EngineerCalendarDatesState {
  start: DateTime;
  end: DateTime;
}

interface ResponseState<T = any[]> {
  isLoading: boolean;
  data: T | undefined;
  error: boolean;
}

interface EngineerCalendarCapacity {
  date: string;
  bookedSlots?: number;
  totalCapacity?: number;
  utilisation?: number;
  error?: AxiosError | any;
}

export interface EngineerEvent extends Pick<Appointment, 'id' | 'reason'> {
  slot: {
    date: DateTime;
    startTime: DateTime;
    endTime: DateTime;
  };
}
interface EngineerCalendarContext {
  calendarCapacity: ResponseState<EngineerCalendarCapacity[]>;
  setCalendarCapacity?: Dispatch<
    SetStateAction<ResponseState<EngineerCalendarCapacity[]>>
  >;
  engineerData?: Engineer;
  engineerSummaries?: CalendarEngineerSummary[];
}

interface EngineerCalendarDatesContext {
  dates: EngineerCalendarDatesState;
  setDates?: Dispatch<SetStateAction<EngineerCalendarDatesState>>;
}

export interface CalendarEngineerSummary
  extends Pick<EngineerSummary, 'startTime' | 'endTime' | 'date' | 'patch'> {
  weekday: string;
}

const useEngineerCalendarStyles = makeStyles(
  theme => ({
    container: {
      maxWidth: theme.containers.large,
      minWidth: theme.containers.large
    }
  }),
  { name: 'EngineerCalendar' }
);

export const dateDiffUnit = 'week';

export const EngineerCalendarContext = React.createContext<
  EngineerCalendarContext
>({
  calendarCapacity: {
    isLoading: false,
    data: undefined,
    error: false
  },
  engineerData: undefined
});

export const EngineerCalendarDatesContext = React.createContext<
  EngineerCalendarDatesContext
>({
  dates: {
    start: DateTime.now().startOf(dateDiffUnit),
    end: DateTime.now().endOf(dateDiffUnit)
  }
});

function handleError(error: AxiosError | any, dispatch: UseDispatch) {
  const { message, status } = getErrorMessageAndStatus(error);

  displayErrorSnackbar(message, status, dispatch);
  commonErrorHandling(error, engineerSummaryDomain.type);
}

export function EngineerCalendarDatesProvider({
  children
}: {
  children: ReactNode;
}) {
  const [dates, setDates] = useState({
    start: DateTime.now().startOf(dateDiffUnit),
    end: DateTime.now().endOf(dateDiffUnit)
  });

  return (
    <EngineerCalendarDatesContext.Provider value={{ dates, setDates }}>
      {children}
    </EngineerCalendarDatesContext.Provider>
  );
}

function EngineerCalendar() {
  const classes = useEngineerCalendarStyles();
  const dispatch = useDispatch();
  const { dates } = useContext(EngineerCalendarDatesContext);

  const [calendarCapacity, setCalendarCapacity] = useState<
    ResponseState<EngineerCalendarCapacity[]>
  >({
    isLoading: false,
    data: undefined,
    error: false
  });

  const [engineerSummaries, setEngineerSummaries] = useState<
    CalendarEngineerSummary[]
  >([]);

  const [events, setEvents] = useState<EngineerEvent[]>([]);

  const { id: engineerId } = useParams<{ id: string }>();

  /**
   * This method fetches engineer summaries and returns the `date` and `patch` (patch id) for each summary.
   */
  const getEngineerPatches = async (): Promise<CalendarEngineerSummary[]> => {
    const response = await fetchEngineerSummary(
      engineerId,
      dates.start.toISODate(),
      dates.end.toISODate()
    );

    return response?.data?.map(({ date, patch, startTime, endTime }) => {
      const currentWeekdayIndex = DateTime.fromISO(date).weekday;
      const weekdays = Info.weekdays('long');

      return {
        date,
        patch,
        startTime,
        endTime,
        weekday: weekdays[currentWeekdayIndex]
      };
    });
  };

  const buildCapacityRequests = (patches: CalendarEngineerSummary[]) =>
    patches.map(({ date, patch }) => {
      const filter: QueryParams = {
        patch: {
          operator: '=',
          values: [patch]
        }
      };

      const params = {
        ...createFilterQuery(filter),
        startDate: date,
        endDate: date,
        jobTypeId: SMETS2_DUEL_FUEL_INSTALLATION_JOB_TYPE_ID
      };

      const req = http.get<Page<CapacityUtilisation>>(
        capacityUtilisationDomain.apiPath,
        {
          params,
          paramsSerializer
        }
      );

      return { date: DateTime.fromISO(date), req };
    });

  interface PatchCapacityRequestWithDate {
    date: DateTime;
    req: Promise<AxiosResponse<Page<CapacityUtilisation>>>;
  }
  const fetchPatchCapacity = async (
    capacityRequests?: PatchCapacityRequestWithDate[]
  ) => {
    if (!capacityRequests) {
      return undefined;
    }

    const responses = await Promise.all(
      capacityRequests.map(async ({ date, req }) => {
        const response = await req;

        if (response.data.items.length === 0) {
          return {
            date
          };
        }

        const {
          bookedSlots,
          totalCapacity,
          utilisation
        } = response.data.items[0];

        return {
          date,
          bookedSlots,
          totalCapacity,
          utilisation
        };
      })
    );

    const sortedResponses: EngineerCalendarCapacity[] = responses
      .flat()
      .sort((a, b) => a.date.toMillis() - b.date.toMillis())
      .map(({ date, ...r }) => ({ ...r, date: date.toISODate() }));

    return sortedResponses;
  };

  useEffect(() => {
    let fetchPatches = true;

    setCalendarCapacity(state => ({ ...state, isLoading: true }));

    getEngineerPatches()
      .then(patches => {
        setEngineerSummaries(patches);
        const capacityRequests = buildCapacityRequests(patches);

        return fetchPatchCapacity(capacityRequests).then(capacity => {
          // We don't want to update state if the component has been unmounted
          if (fetchPatches) {
            setCalendarCapacity(state => ({
              ...state,
              isLoading: false,
              data: capacity
            }));
          }
        });
      })
      .catch(err => {
        if (fetchPatches) {
          setCalendarCapacity(() => ({
            isLoading: false,
            data: undefined,
            error: true
          }));

          handleError(err, dispatch);
        }
      });

    return () => {
      fetchPatches = false;
    };
  }, [dates.start, dates.end]);

  const engineerData = useEngineerData(engineerId);

  const appointmentsDateMapper = ({
    id,
    reason,
    slot
  }: Pick<Appointment, 'id' | 'reason' | 'slot'>) => {
    const date = DateTime.fromISO(slot.date);
    const startTime = DateTime.fromISO(`${slot.date}T${slot.startTime}`);
    const endTime = DateTime.fromISO(`${slot.date}T${slot.endTime}`);

    return {
      id,
      reason,
      slot: {
        date,
        startTime,
        endTime
      }
    };
  };

  const fixedAppointments = useAppointmentsData();

  const memoizedEngineerAppointments = useMemo(
    () => fixedAppointments.map(appointmentsDateMapper),
    [fixedAppointments]
  );

  useEffect(() => {
    dispatch(fetchAllEngineerFixedAppointments(engineerId));
    dispatch(fetchAllSkills());
  }, []);

  useEffect(() => {
    setEvents(memoizedEngineerAppointments);
  }, [memoizedEngineerAppointments]);

  return (
    <section className={classes.container} data-testid="engineerCalendar">
      <Paper elevation={2}>
        <EngineerCalendarContext.Provider
          value={{
            calendarCapacity,
            setCalendarCapacity,
            engineerData,
            engineerSummaries
          }}
        >
          <TemporaryAdjustmentFormContextProvider>
            <EngineerCalendarHeader />
            <EngineerCalendarBody events={events} />
            <EngineerCalendarKey />
            <AdjustmentFormModals />
          </TemporaryAdjustmentFormContextProvider>
        </EngineerCalendarContext.Provider>
      </Paper>
    </section>
  );
}

export default EngineerCalendar;
