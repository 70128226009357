import React, { useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { ToolbarMinHeight } from 'styles/types';

// scrollTo isn't implemented in JSDOM (test fails), it's a browser thing
Element.prototype.scrollTo = () => null;

const useStyles = makeStyles(theme => ({
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
    padding: `${
      (theme.mixins.toolbar[theme.breakpoints.up('sm')] as ToolbarMinHeight)
        .minHeight
    }px 0 0 0`,
    [theme.breakpoints.only('xs')]: {
      padding: `${theme.mixins.toolbar.minHeight}px 0 0 0`
    }
  }
}));

function Main(props: any) {
  const classes = useStyles();
  const scrollDiv = useRef<HTMLInputElement>(null);
  const { pathname } = useLocation();

  useEffect(() => {
    if (scrollDiv && scrollDiv.current) {
      scrollDiv.current.scrollTo(0, 0);
    }
  }, [pathname]);

  return (
    <main ref={scrollDiv} className={classes.content}>
      {props.children}
    </main>
  );
}

export default Main;
