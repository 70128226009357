import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import keycloak from 'auth/keycloak';

import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { CircularProgress, Paper } from '@material-ui/core';
import { Page } from 'components/Page';
import { useSelector } from 'react-redux';
import Typography from 'components/Typography';
import { AccountInfo } from './AccountInfo';
import { getSlice, RootState } from '../../store';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(2),
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left'
  },
  progress: {
    height: '50px',
    margin: '2em auto 0 auto',
    width: '50px'
  }
}));

export const Account = () => {
  const classes = useStyles();
  const { selector } = getSlice('user');
  const { userProfile } = useSelector<RootState, ReturnType<typeof selector>>(
    selector
  );

  return (
    <Page title="Account Info" titleQaId="accountPageTitle">
      {userProfile ? (
        <Paper className={classes.paper}>
          <Link to="/">
            <Typography>&lt; Back to home</Typography>
          </Link>
          <AccountInfo
            attribute="Name"
            value={`${userProfile.firstName} ${userProfile.lastName}`}
          />
          <AccountInfo attribute="Email" value={userProfile.email || 'None'} />
          <AccountInfo
            attribute="Username"
            value={userProfile.username || 'None'}
          />
          <Button
            type="button"
            variant="contained"
            color="primary"
            onClick={() => keycloak.logout()}
            fullWidth
            data-testid="accountSignOutButton"
          >
            Sign Out
          </Button>
        </Paper>
      ) : (
        <CircularProgress size="10" className={classes.progress} />
      )}
    </Page>
  );
};
