/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import Skill from 'models/Skills';
import { DomainItem } from 'models/Domain';
import { AppThunk } from 'store';
import { FormValues } from 'components/Forms';
import {
  formatSkillsRequestBody,
  fetchItemThunk,
  deleteItemThunk,
  addItemThunk,
  editItemThunk,
  fetchThunk,
  fetchAllThunk
} from 'store/utils/';
import {
  initialStateSlice,
  StateSlice,
  startLoading,
  loadingFailed,
  getPaginationReducers
} from 'store/slices';
import { skillDomain } from 'models';

const sortSkillByName = (skill: Skill, nextSkill: Skill) =>
  skill.name.localeCompare(nextSkill.name);

const skills = createSlice({
  name: skillDomain.plural,
  initialState: initialStateSlice,
  reducers: {
    ...startLoading<Skill>(),
    ...loadingFailed<Skill>(),
    ...getPaginationReducers<Skill>(),
    getSkillsSuccess(state, { payload }: PayloadAction<StateSlice<Skill>>) {
      const { items, page } = payload;

      state.items = items.sort(sortSkillByName);
      state.page = page;
      state.isLoading = false;
      state.error = null;
    },
    getSkillSuccess(state, { payload: skillItem }: PayloadAction<Skill>) {
      if (state.items != null) {
        state.items = state.items.filter(skill => skill.id !== skillItem.id);
        state.items.push(skillItem);
        state.items.sort(sortSkillByName);
      }
      state.isLoading = false;
      state.error = null;
    },
    addSkillSuccess(state, { payload: addedSkill }: PayloadAction<Skill>) {
      // We could check here if we already have a skill with the same name
      // in the store, but I'll leave that up to the API to decide
      //
      // We could also either push the new item into state or dispatch `fetchSkills`
      // after `addSkillSuccess` in the thunk to refresh the state
      if (state.items != null) {
        state.items.push(addedSkill);
        state.items.sort(sortSkillByName);
      }

      state.isLoading = false;
      state.error = null;
    },
    editSkillSuccess(state, { payload: editedSkill }: PayloadAction<Skill>) {
      if (state.items != null) {
        state.items = state.items.filter(skill => skill.id !== editedSkill.id);
        state.items.push(editedSkill);
        state.items.sort(sortSkillByName);
      }

      state.isLoading = false;
      state.error = null;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    newEditSkillSuccess(state, { payload: editedSkill }: PayloadAction<Skill>) {
      state.isLoading = false;
      state.error = null;
    },
    deleteSkillSuccess(
      state,
      { payload: deletedSkillId }: PayloadAction<string>
    ) {
      if (state.items != null) {
        state.items = state.items.filter(skill => skill.id !== deletedSkillId);
      }

      state.isLoading = false;
      state.error = null;
    }
  }
});

export const {
  requestStart,
  requestFail,
  setPagination: setSkillsPagination,
  setTotalItems,
  setTotalPages,
  getSkillSuccess,
  getSkillsSuccess,
  addSkillSuccess,
  editSkillSuccess,
  newEditSkillSuccess,
  deleteSkillSuccess
} = skills.actions;

export default skills.reducer;

export function fetchSkills(): AppThunk {
  return fetchThunk<StateSlice<Skill>>({
    domain: skillDomain,
    requestStart,
    requestFail,
    requestSuccess: getSkillsSuccess
  });
}

export function fetchAllSkills(): AppThunk {
  return fetchAllThunk(skillDomain, {
    start: requestStart,
    success: getSkillsSuccess,
    fail: requestFail
  });
}

export function fetchSkill(id: string): AppThunk {
  return fetchItemThunk<Skill>(
    skillDomain,
    requestStart,
    requestFail,
    getSkillSuccess,
    id
  );
}

export function addSkill(item: FormValues): AppThunk {
  return addItemThunk<Skill>({
    domain: skillDomain,
    requestStart,
    requestFail,
    requestSuccess: addSkillSuccess,
    item,
    formatFormValues: formatSkillsRequestBody
  });
}

export function editSkill(item: FormValues, itemId: string): AppThunk {
  return editItemThunk<Skill>({
    domain: skillDomain,
    requestStart,
    requestFail,
    requestSuccess: editSkillSuccess,
    itemId,
    item,
    formatFormValues: formatSkillsRequestBody
  });
}

export function newAddSkill(item: FormValues): AppThunk {
  return addItemThunk<Skill>({
    domain: skillDomain,
    requestStart,
    requestFail,
    requestSuccess: addSkillSuccess,
    item,
    formatFormValues: formatSkillsRequestBody,
    successMessage: 'The skill has been added'
  });
}

export function newEditSkill(item: FormValues, itemId: string): AppThunk {
  return editItemThunk<Skill>({
    domain: skillDomain,
    requestStart,
    requestFail,
    requestSuccess: newEditSkillSuccess,
    itemId,
    item,
    formatFormValues: formatSkillsRequestBody,
    successMessage: 'The skill has been edited'
  });
}

export function deleteSkill(domainItem: DomainItem): AppThunk {
  return deleteItemThunk<Skill>({
    domain: skillDomain,
    requestStart,
    requestFail,
    requestSuccess: deleteSkillSuccess,
    itemId: domainItem.id
  });
}
