import React, { useState, MutableRefObject, useEffect } from 'react';
import { FormApiWithRestart } from './Form';
import { FormValues, FormError, InputFieldProps } from './utils';
import { Form } from '.';

interface FormPage {
  fields?: InputFieldProps[];
  initialValues?: FormValues;
  validationFn: (values: FormValues) => FormError | undefined;
}

interface MultistepFormProps {
  children: JSX.Element[];
  onSubmit: (values: FormValues) => void;
  formRef: MutableRefObject<FormApiWithRestart | undefined>;
  onNext: (values: FormValues) => void;
  page?: number;
  formPages: FormPage[];
}

/**
 * This component provides a multi-page form wizard.
 * @param {JSX.Element[]} children - The form pages.
 * @param {() => void} onSubmit - A callback that is invoked when the modal is closed.
 * @param {FormValues} [initialValues] - Initial values for the form
 * @param {MutableRefObject<FormApiWithRestart | undefined>} [formRef] - The ref used to access the `form` object.
 This is used by buttons outside the form to trigger a submit.
 * @param {number} [page] - The currently active page index. Defaults to `0`.
 * @param {() => void} [onNext] - A callback that is invoked when the 'Next' button is clicked.
 * @param {FormPage[]} formPages - An array of objects containing form fields config and validation functions for each page.
 */
function MultistepForm({
  children,
  onSubmit,
  formRef,
  page = 0,
  onNext,
  formPages
}: MultistepFormProps) {
  const currentFormPage = formPages[page];

  const [formValues, setFormValues] = useState({});

  useEffect(() => {
    setFormValues(currentFormPage?.initialValues || {});
  }, [currentFormPage?.initialValues]);

  const next = (values: FormValues) => {
    onNext(values);
    setFormValues(values);
  };

  const handleSubmit = (values: FormValues) => {
    const isLastPage = page === React.Children.count(children) - 1;

    if (isLastPage) {
      return onSubmit(values);
    }

    return next(values);
  };

  return (
    <Form
      initialValues={formValues}
      validationFunc={currentFormPage?.validationFn}
      onSubmit={handleSubmit}
      goBackToPreviousPage={false}
      fields={currentFormPage?.fields}
    >
      {({ form }) => {
        // eslint-disable-next-line no-param-reassign
        formRef.current = form;

        return children.filter((_, pageIndex) => pageIndex === page);
      }}
    </Form>
  );
}

interface MultistepFormPageProps {
  children?: JSX.Element | JSX.Element[] | string;
}

function MultistepFormPage({ children }: MultistepFormPageProps) {
  return <>{children}</>;
}

export default Object.assign(MultistepForm, {
  Page: MultistepFormPage
});
