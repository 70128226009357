import http from 'api/http';
import { geographicAreaDomain } from 'models';
import {
  GeographicArea,
  UpdateGeographicAreaRequest
} from 'models/GeographicArea';
import { filterParentAreaQueryParams } from 'store/slices/geographicAreas';
import { createFilterQuery } from 'store/utils';
import { getPaginatedApiData, iteratePagination } from 'store/utils/pagination';

export function editGeographicArea(
  geographicAreaPayload: UpdateGeographicAreaRequest
) {
  return http.put<GeographicArea>(
    `${geographicAreaDomain.apiPath}/${geographicAreaPayload.id}`,
    geographicAreaPayload
  );
}

export function fetchGeographicAreasByPatchId(patchId: string) {
  const filterQueryParams = {
    params: createFilterQuery({
      ...filterParentAreaQueryParams,
      patches: {
        operator: 'in',
        values: [patchId]
      }
    })
  };

  return iteratePagination<GeographicArea>(
    filterQueryParams,
    getPaginatedApiData(geographicAreaDomain)
  );
}
