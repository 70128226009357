import React from 'react';

import { DomainBreadcrumbProps } from './BreadcrumbContainer';
import { getBreadcrumbs } from './breadcrumbData';
import Breadcrumbs from './Breadcrumbs';

const StatelessDomainBreadcrumb = ({
  pathname,
  domain
}: DomainBreadcrumbProps) => {
  const breadcrumbs = getBreadcrumbs(domain, pathname);

  if (breadcrumbs.length === 0) {
    return null;
  }

  return <Breadcrumbs crumbs={breadcrumbs} />;
};

export default StatelessDomainBreadcrumb;
