import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { formSubmitHandler } from 'components/Crud';
import { Form, FormValues } from 'components/Forms';
import { FormApiWithRestart } from 'components/Forms/Form';
import { abortCategoriesDomain, appointmentDomain } from 'models';
import {
  AbortCustomerAppointmentRequest,
  CustomerAppointmentType
} from 'models/Appointment';
import { JobDetailsResponse } from 'models/JobDetails';
import { getDomainSlice } from 'store';
import Modal from 'components/Modal';
import { SubmitButton, SecondaryButton } from 'components/Forms/Buttons';
import { AbortReasonAction, AbortReasonProduct } from 'models/AbortCategory';
import { selectAbortOrCancelCategories } from 'store/selectors';
import {
  abortJobFormfields,
  abortJobFormValidation
} from './AbortJobFormConfig';

interface AbortJobProps {
  job: JobDetailsResponse;
  open: boolean;
  closeModal: () => void;
  reloadJobDetails: () => void;
}

export default function AbortJobModal({
  job,
  open,
  closeModal,
  reloadJobDetails
}: AbortJobProps) {
  const dispatch = useDispatch();
  const { actions: abortActions } = getDomainSlice(abortCategoriesDomain);
  const { actions: appointmentActions } = getDomainSlice(appointmentDomain);

  const jobAppointmentType =
    job.appointmentType?.type === CustomerAppointmentType.EV
      ? AbortReasonProduct.EV
      : // Making a big assumption here. This could also be 'MAINTENANCE'
        AbortReasonProduct.Smart;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);
  const [currentCategoryId, setCurrentCategoryId] = useState<string | null>(
    null
  );
  const formRef = useRef<FormApiWithRestart>();

  const abortCategoryData = useSelector(
    selectAbortOrCancelCategories(jobAppointmentType, AbortReasonAction.Abort)
  );

  useEffect(() => {
    let closeModalTimer: NodeJS.Timeout | undefined;
    if (success && !isSubmitting) {
      reloadJobDetails();
      closeModalTimer = setTimeout(() => {
        closeModal();
      }, 1000);
    }
    return () => {
      if (closeModalTimer) {
        clearTimeout(closeModalTimer);
      }
    };
  }, [success, isSubmitting]);

  useEffect(() => {
    if (abortCategoryData.length === 0) {
      dispatch(abortActions.get.list());
    }
  }, [abortCategoryData]);

  const resetFormOnCancel = () => {
    formRef.current?.restart();
    closeModal();
  };

  const parseFormValues = (
    values: FormValues
  ): AbortCustomerAppointmentRequest => {
    return {
      categoryId: values.category as string,
      comments: values.comments as string,
      reasonId: values.reason as string,
      rebook: Boolean(values.rebookable as string)
    };
  };

  const apiCallback = (values: FormValues) =>
    dispatch(
      appointmentActions.custom?.abortJob(job.id, parseFormValues(values))
    );

  return (
    <Modal
      title="Abort Job"
      testId="abortJobModal"
      open={open}
      onClose={closeModal}
      modalActions={
        <>
          <SecondaryButton
            onClick={() => resetFormOnCancel()}
            testId="abortJobCancelBtn"
          >
            Cancel
          </SecondaryButton>
          <SubmitButton
            onClick={() => formRef.current?.submit()}
            testId="abortJobSubmitBtn"
          >
            Save
          </SubmitButton>
        </>
      }
    >
      <Form
        validationFunc={abortJobFormValidation}
        fields={abortJobFormfields(
          abortCategoryData,
          currentCategoryId,
          setCurrentCategoryId
        )}
        onSubmit={formSubmitHandler(apiCallback)}
        goBackToPreviousPage={false}
      >
        {({ form, submitting, submitSucceeded, submitErrors }) => {
          setSuccess(!submitErrors && submitSucceeded);
          setIsSubmitting(submitting);

          formRef.current = form;
        }}
      </Form>
    </Modal>
  );
}
