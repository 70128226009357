import { API_LOCAL_DATE_TIME_FORMAT, DATE_ISO_SHORT } from 'constants/dates';
import { DateTime } from 'luxon';
import {
  AppointmentAbortDetails,
  Address,
  Complaint,
  ContactDetails,
  CustomerVulnerability,
  Note
} from 'models/Appointment';
import { capitalise } from 'utils';

export const dashIfFalsy = (
  item: string | string[] | undefined | null | false
) => {
  if (item && item !== '') {
    return item;
  }
  return '-';
};

export const formatName = (details: ContactDetails | undefined): string => {
  if (details) {
    const title = details.title && details.title != 'Mx' ? details.title : '';
    return [title, details.firstName, details.lastName]
      .filter(Boolean)
      .join(' ');
  }
  return '-';
};

export function formatNullableAddressLines(addressArr: (string | null)[]) {
  return addressArr
    .filter((value): value is string => Boolean(value))
    .map(value => capitalise(value.toLocaleLowerCase()));
}

export const formatAddressSingleLine = (
  address: Address | undefined
): string => {
  if (address) {
    const {
      addressLineOne,
      addressLineTwo,
      addressLineThree,
      city,
      postcode
    } = address;

    return formatNullableAddressLines([
      addressLineOne,
      addressLineTwo,
      addressLineThree,
      city
    ])
      .concat(postcode.toLocaleUpperCase())
      .join(' ');
  }
  return '-';
};

export const formatAddressMultiline = (
  address: Address | undefined
): string[] | string => {
  if (address) {
    const {
      addressLineOne,
      addressLineTwo,
      addressLineThree,
      city,
      postcode
    } = address;

    return formatNullableAddressLines([
      addressLineOne,
      addressLineTwo,
      addressLineThree,
      city
    ]).concat(postcode.toLocaleUpperCase());
  }
  return '-';
};

export const formatNotes = (notes: Note[]): string | string[] => {
  return dashIfFalsy(notes.length > 0 && notes.map(note => note.note));
};

const vulnerabilityMap: { [key: string]: string } = {
  isOver65: 'Customer is over 65 years old',
  hasDisability: 'Customer has a disability',
  hasHearingDifficulties: 'Customer has hearing difficulties',
  hasSpeechDifficulties: 'Customer has speech difficulties',
  hasSmellDifficulties: 'Customer has a poor sense of smell',
  isBlindOrPartiallySighted: 'Customer is blind or partially sighted',
  hasSeriousIllness: 'Customer has a serious illness',
  speaksOtherLanguage: "Customer doesn't speak English",
  isReliantOnMedicalEquipment: 'Customer is reliant on medical equipment',
  hasYoungChildren: 'Customer has young children'
};

export const formatVulnerabilities = (
  vulnerabilities: CustomerVulnerability | undefined
): string | string[] => {
  const vulnerabilitiesList = Object.entries(vulnerabilities || {})
    .filter(([, value]) => value)
    .map(([key, value]) => {
      if (key === 'other') {
        return `Other: ${value}`;
      }
      return vulnerabilityMap[key] || '';
    });

  if (vulnerabilitiesList.length > 0) {
    return vulnerabilitiesList;
  }
  return '-';
};

export const formatDateFromApiLocalToISOShort = (date: string): string =>
  DateTime.fromFormat(date, API_LOCAL_DATE_TIME_FORMAT).toFormat(
    DATE_ISO_SHORT
  );

export const formatComplaintNotes = (complaint: Complaint): string =>
  `${
    complaint.subject != null ? `${complaint.subject}. ` : ''
  }${complaint.description ?? ''}`;

function sortDescendingByDate(complaints: Complaint[]) {
  return complaints.sort((first, second) => {
    const firstDate = DateTime.fromFormat(
      first.createdDate,
      API_LOCAL_DATE_TIME_FORMAT
    );
    const secondDate = DateTime.fromFormat(
      second.createdDate,
      API_LOCAL_DATE_TIME_FORMAT
    );
    return firstDate > secondDate ? -1 : 1;
  });
}

export function sortByDate(
  abortDetails: AppointmentAbortDetails[]
): AppointmentAbortDetails[] {
  return abortDetails.sort((first, second) => {
    const firstDate = DateTime.fromFormat(
      first.appointmentDate,
      DATE_ISO_SHORT
    );
    const secondDate = DateTime.fromFormat(
      second.appointmentDate,
      DATE_ISO_SHORT
    );
    return firstDate > secondDate ? 1 : -1;
  });
}

export function getMostRecentComplaint(
  complaints?: Complaint[]
): Complaint | null {
  if (complaints?.length) {
    return sortDescendingByDate(complaints)[0];
  }
  return null;
}

export function formatAppointmentAbortDetails(
  details: AppointmentAbortDetails
): string[] {
  return [
    `Reference: ZENO/${details.appointmentId}`,
    `Reason: ${details.abortDetails.reason}`,
    `Comment: ${dashIfFalsy(details.abortDetails.comments)}`
  ];
}
