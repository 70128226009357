import {
  dashIfFalsy,
  formatAddressMultiline,
  formatComplaintNotes,
  formatDateFromApiLocalToISOShort,
  formatName,
  formatNotes,
  formatAppointmentAbortDetails,
  formatVulnerabilities,
  getMostRecentComplaint,
  sortByDate
} from 'components/JobDetailsSheet/jobsHelper';
import {
  CustomerAppointmentDetails,
  CustomerAppointmentType,
  EVAppointmentDetails,
  EVFormChannel,
  MaintenanceDetails
} from 'models/Appointment';
import {
  IconType,
  JobDetailsResponse,
  JobSheetData,
  JobSheetDataRowsOptions,
  JobSheetSectionLabels
} from 'models/JobDetails';
import { redirectToEngineer, redirectToJob } from 'utils';

function getCustomerSectionData(
  job: JobDetailsResponse,
  evForm: boolean
): JobSheetData {
  return {
    label: JobSheetSectionLabels.Customer,
    rows: [
      {
        type: 'text',
        label: 'Name',
        value: formatName(job.contactDetails)
      },
      ...(evForm
        ? []
        : [
            {
              type: 'text',
              label: 'Account Number',
              value: dashIfFalsy(job.accountNumber)
            }
          ]),
      {
        type: 'text',
        label: 'Phone number',
        value: dashIfFalsy(job.contactDetails?.primaryPhoneNumber)
      },
      {
        type: 'text',
        label: 'Secondary number',
        value: dashIfFalsy(job.contactDetails?.secondaryPhoneNumber)
      },
      {
        type: job?.address ? 'textarea' : 'text',
        label: 'Address',
        value: formatAddressMultiline(job.address)
      },
      {
        type: job?.customerDetails?.vulnerability ? 'textarea' : 'text',
        label: 'Vulnerabilities',
        value: formatVulnerabilities(job?.customerDetails?.vulnerability),
        icon: IconType.VULNERABLE
      }
    ]
  };
}

function getBookingSectionData(
  job: JobDetailsResponse,
  evForm: boolean
): JobSheetData {
  const { jumptechProjectId } =
    (job?.appointmentType?.details as EVAppointmentDetails) || {};
  const jumptechURL = jumptechProjectId
    ? `https://ovo-drive.jumptech.co.uk/project/${jumptechProjectId}`
    : null;

  return {
    label: JobSheetSectionLabels.Booking,
    rows: [
      {
        type: 'text',
        label: 'Status',
        value: dashIfFalsy(job.status)
      },
      {
        type: 'text',
        label: 'Reference number',
        value: dashIfFalsy(job.bookingReference)
      },
      {
        type: 'text',
        label: 'Engineer',
        onClick: () =>
          job.assignedEngineer && redirectToEngineer(job.assignedEngineer.id),
        value: dashIfFalsy(
          job.assignedEngineer &&
            `${job.assignedEngineer.firstName} ${job.assignedEngineer.lastName}`
        )
      },
      {
        type: 'text',
        label: 'Date',
        value: dashIfFalsy(job?.slot.date)
      },
      {
        type: 'text',
        label: 'Timeslot',
        value: dashIfFalsy(`${job.slot.startTime}-${job.slot.endTime}`)
      },
      {
        type: 'text',
        label: 'Job type',
        value: dashIfFalsy(job?.jobTypeData.name)
      },
      ...(evForm
        ? [
            {
              type: 'text',
              label: 'Zoho account number',
              value: dashIfFalsy(
                (job?.appointmentType?.details as EVAppointmentDetails)
                  .zohoAccountId
              )
            },
            {
              type: 'text',
              label: 'Channel',
              value: Object.fromEntries(Object.entries(EVFormChannel))[
                (job?.appointmentType?.details as EVAppointmentDetails).channel
              ] as string
            },
            {
              type: jumptechURL ? 'link' : 'text',
              label: 'Jumptech URL',
              value: dashIfFalsy(jumptechURL)
            }
          ]
        : [
            {
              type: 'text',
              label: 'Meter type',
              value: dashIfFalsy(job?.appointmentType?.type)
            },
            {
              type: 'text',
              label: 'Fuel type',
              value: dashIfFalsy(job?.appointmentType?.fuelType)
            },
            {
              type: 'text',
              label: 'Payment type',
              value: dashIfFalsy(job?.appointmentType?.paymentType)
            },
            {
              type: 'text',
              label: 'Brand',
              value: dashIfFalsy(job?.brand)
            }
          ]),
      {
        type: job.notes.length > 0 ? 'textarea' : 'text',
        label: 'Booking notes',
        value: formatNotes(job?.notes),
        icon: IconType.INFO
      }
    ]
  };
}

function getCommonTechnicalData(
  details:
    | CustomerAppointmentDetails
    | MaintenanceDetails
    | EVAppointmentDetails,
  evForm = false,
  displayMultiRateMultiMpanFields = false
): JobSheetDataRowsOptions[] {
  return [
    {
      type: 'text',
      label: `${displayMultiRateMultiMpanFields ? 'Primary ' : ''}MPAN`,
      value: dashIfFalsy(details.mpan)
    },
    ...(displayMultiRateMultiMpanFields
      ? [
          {
            type: 'text',
            label: 'Secondary MPAN',
            value: dashIfFalsy(
              'secondaryMpan' in details ? details.secondaryMpan : undefined
            )
          }
        ]
      : []),
    ...(evForm
      ? []
      : [
          {
            type: 'text',
            label: 'MPRN',
            value: dashIfFalsy((details as CustomerAppointmentDetails).mprn)
          }
        ])
  ];
}

function getElecTariff(
  details: MaintenanceDetails | CustomerAppointmentDetails,
  displayMultiRateMultiMpanFields: boolean
): JobSheetDataRowsOptions[] {
  return displayMultiRateMultiMpanFields
    ? [
        {
          type: 'text',
          label: 'Elec tariff',
          value: dashIfFalsy(details?.elecTariff)
        }
      ]
    : [];
}

function getNonMaintenanceTechnicalSectionData(
  details: CustomerAppointmentDetails,
  evForm: boolean,
  displayMultiRateMultiMpanFields: boolean
): JobSheetData {
  const displayMultiMeterFields =
    displayMultiRateMultiMpanFields && details.secondaryMpan;

  return {
    label: JobSheetSectionLabels.Technical,
    rows: [
      ...getCommonTechnicalData(
        details,
        evForm,
        displayMultiRateMultiMpanFields
      ),
      ...(evForm
        ? []
        : [
            {
              type: 'text',
              label: `Elec MSN${displayMultiMeterFields ? ' (Exchange)' : ''}`,
              value: dashIfFalsy(details.meterTechnicalDetails?.oldElecMsn)
            },
            ...(displayMultiMeterFields
              ? [
                  {
                    type: 'text',
                    label: 'Elec MSN (Remove)',
                    value: dashIfFalsy(
                      details.meterTechnicalDetails?.metersToBeRemoved.find(
                        meter => {
                          if ('mpan' in meter) {
                            return meter.mpan === details.secondaryMpan;
                          }

                          return false;
                        }
                      )?.serialNumber
                    )
                  }
                ]
              : []),
            {
              type: 'text',
              label: 'Gas MSN',
              value: dashIfFalsy(details.meterTechnicalDetails?.oldGasMsn)
            },
            {
              type: 'text',
              label: 'Expected new meter mode',
              value: dashIfFalsy('') // add this when it is present in the response DTO
            },
            {
              type: 'text',
              label: 'Semi-concealed',
              value: dashIfFalsy(details.hasConcealedMeter ? 'Yes' : 'No')
            },
            {
              type: 'text',
              label: 'WAN aux requirement',
              value: dashIfFalsy(
                details.meterTechnicalDetails?.xvali?.auxiliaryEquipmentRequired
              )
            },
            {
              type: 'text',
              label: 'WAN technology',
              value: dashIfFalsy(
                details.meterTechnicalDetails?.xvali?.commsHubType
              )
            },
            {
              type: 'text',
              label: 'CSP region',
              value: dashIfFalsy(
                details.meterTechnicalDetails?.xvali?.cspRegion
              )
            },
            ...getElecTariff(details, displayMultiRateMultiMpanFields)
          ])
    ]
  };
}

function getMaintenanceTechnicalSectionData(
  details: MaintenanceDetails,
  displayMultiRateMultiMpanFields: boolean
): JobSheetData {
  return {
    label: JobSheetSectionLabels.Technical,
    rows: [
      {
        type: 'text',
        label: 'Emergency booking',
        value: dashIfFalsy(details.isEmergency ? 'Yes' : 'No')
      },
      ...getCommonTechnicalData(details),
      {
        type: 'text',
        label: 'Job description',
        value: dashIfFalsy(details.jobDescription)
      },
      {
        type: 'text',
        label: 'Old elec MSN',
        value: dashIfFalsy(details.oldElecMsn)
      },
      {
        type: 'text',
        label: 'Old SSC',
        value: dashIfFalsy(details.oldSsc)
      },
      {
        type: 'text',
        label: 'Old CT Ratio',
        value: dashIfFalsy(details.oldCtRatio)
      },
      {
        type: 'text',
        label: 'Old elec meter location',
        value: dashIfFalsy(details.oldElecMeterLocation)
      },
      {
        type: 'text',
        label: 'Old meter type',
        value: dashIfFalsy(details.oldMeterType)
      },
      {
        type: 'text',
        label: 'Old gas MSN',
        value: dashIfFalsy(details.oldGasMsn)
      },
      {
        type: 'text',
        label: 'Old gas meter location',
        value: dashIfFalsy(details.oldGasMeterLocation)
      },
      {
        type: 'text',
        label: 'New SSC',
        value: dashIfFalsy(details.newSsc)
      },
      {
        type: 'text',
        label: 'Storage heaters / underfloor heating',
        value: dashIfFalsy(details.storageUnderfloorHeating)
      },
      {
        type: 'text',
        label: 'New meter type',
        value: dashIfFalsy(details.newMeterType)
      },
      {
        type: 'text',
        label: 'New payment type',
        value: dashIfFalsy(details.newPaymentType)
      },
      {
        type: 'text',
        label: 'Meter mode',
        value: dashIfFalsy(details.meterMode)
      },
      {
        type: 'text',
        label: 'Energisation status',
        value: dashIfFalsy(details.energisationStatus)
      },
      {
        type: 'text',
        label: 'Meter positioning',
        value: dashIfFalsy(details.meterPositioning)
      },
      {
        type: 'text',
        label: 'Pressure tier',
        value: dashIfFalsy(details.pressureTier)
      },
      ...getElecTariff(details, displayMultiRateMultiMpanFields)
    ]
  };
}

function getTechnicalSectionData(
  job: JobDetailsResponse,
  evForm: boolean,
  displayMultiRateMultiMpanFields: boolean
): JobSheetData[] {
  if (!job.appointmentType?.details) {
    return [];
  }
  if (job.appointmentType.type === CustomerAppointmentType.MAINTENANCE) {
    return [
      getMaintenanceTechnicalSectionData(
        job.appointmentType.details as MaintenanceDetails,
        displayMultiRateMultiMpanFields
      )
    ];
  }
  return [
    getNonMaintenanceTechnicalSectionData(
      job.appointmentType.details as CustomerAppointmentDetails,
      evForm,
      displayMultiRateMultiMpanFields
    )
  ];
}

function getComplaintSectionData(details: JobDetailsResponse): JobSheetData[] {
  const complaint = getMostRecentComplaint(details.complaints?.slice());
  if (!complaint) {
    return [];
  }

  return [
    {
      label: JobSheetSectionLabels.Complaint,
      rows: [
        {
          type: 'text',
          label: 'Origin',
          value: dashIfFalsy(complaint?.origin)
        },
        {
          type: 'text',
          label: 'Severity',
          value: dashIfFalsy(
            complaint?.severity ? String(complaint?.severity) : null
          )
        },
        {
          type: 'text',
          label: 'Category',
          value: dashIfFalsy(complaint?.category)
        },
        {
          type: 'text',
          label: 'Date raised',
          value: dashIfFalsy(
            complaint
              ? formatDateFromApiLocalToISOShort(complaint.createdDate)
              : null
          )
        },
        {
          type: 'textarea',
          label: 'Complaint notes',
          value: dashIfFalsy(formatComplaintNotes(complaint)),
          icon: IconType.INFO
        }
      ]
    }
  ];
}

function getPreviousAbortSectionData(job: JobDetailsResponse): JobSheetData[] {
  const { previousAbortData } = job;
  if (previousAbortData === null || previousAbortData.aborts.length === 0) {
    return [];
  }
  const previousAbortRows = sortByDate([...previousAbortData.aborts])
    .filter(abort => abort.appointmentId !== job.id)
    .map((appointmentAbortDetails, index) => {
      return {
        type: 'textarea',
        label: `Abort ${index + 1}`,
        value: formatAppointmentAbortDetails(appointmentAbortDetails),
        onClick: () => redirectToJob(appointmentAbortDetails.appointmentId),
        onClickRowValueIndex: 0,
        icon: IconType.INFO
      };
    });

  if (previousAbortRows.length === 0) {
    return [];
  }

  return [
    {
      label: JobSheetSectionLabels.PreviousAborts,
      rows: previousAbortRows
    }
  ];
}

export function getJobSheetData(
  job: JobDetailsResponse,
  evForm: boolean,
  displayMultiRateMultiMpanFields: boolean
): JobSheetData[] {
  return [
    getCustomerSectionData(job, evForm),
    ...getComplaintSectionData(job),
    ...getPreviousAbortSectionData(job),
    getBookingSectionData(job, evForm),
    ...getTechnicalSectionData(job, evForm, displayMultiRateMultiMpanFields)
  ];
}
