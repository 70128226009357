/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DomainItem } from 'models/Domain';
import Team from 'models/Team';
import { AppThunk } from 'store';
import { FormValues } from 'components/Forms';
import {
  initialStateSlice,
  startLoading,
  loadingFailed,
  StateSlice,
  getPaginationReducers
} from 'store/slices';
import { teamDomain } from 'models';
import {
  fetchThunk,
  deleteItemThunk,
  addItemThunk,
  editItemThunk,
  fetchAllThunk
} from 'store/utils';

const teams = createSlice({
  name: teamDomain.plural,
  initialState: initialStateSlice,
  reducers: {
    ...startLoading<Team>(),
    ...loadingFailed<Team>(),
    ...getPaginationReducers<Team>(),
    getTeamsSuccess(state, { payload }: PayloadAction<StateSlice<Team>>) {
      const { items, page } = payload;

      state.items = items;
      state.page = page;
      state.isLoading = false;
      state.error = null;
    },
    addTeamSuccess(state, { payload: addedTeam }: PayloadAction<Team>) {
      if (state.items != null) {
        state.items.push(addedTeam);
      }

      state.isLoading = false;
      state.error = null;
    },
    editTeamSuccess(state, { payload: editedTeam }: PayloadAction<Team>) {
      if (state.items != null) {
        state.items = state.items.filter(team => team.id !== editedTeam.id);
        state.items.push(editedTeam);
      }

      state.isLoading = false;
      state.error = null;
    },
    deleteTeamSuccess(
      state,
      { payload: deletedTeamId }: PayloadAction<string>
    ) {
      if (state.items != null) {
        state.items = state.items.filter(team => team.id !== deletedTeamId);
      }

      state.isLoading = false;
      state.error = null;
    }
  }
});

export const {
  requestStart,
  requestFail,
  setPagination: setTeamsPagination,
  getTeamsSuccess,
  addTeamSuccess,
  editTeamSuccess,
  deleteTeamSuccess
} = teams.actions;

export default teams.reducer;

export function fetchAllTeams(): AppThunk {
  return fetchAllThunk(teamDomain, {
    start: requestStart,
    success: getTeamsSuccess,
    fail: requestFail
  });
}

export function fetchTeams(): AppThunk {
  return fetchThunk<StateSlice<Team>>({
    domain: teamDomain,
    requestStart,
    requestFail,
    requestSuccess: getTeamsSuccess
  });
}

export function addTeam(item: FormValues): AppThunk {
  return addItemThunk<Team>({
    domain: teamDomain,
    requestStart,
    requestFail,
    requestSuccess: addTeamSuccess,
    item
  });
}

export function editTeam(item: FormValues, itemId: string): AppThunk {
  return editItemThunk<Team>({
    domain: teamDomain,
    requestStart,
    requestFail,
    requestSuccess: editTeamSuccess,
    itemId,
    item
  });
}

export function deleteTeam(domainItem: DomainItem): AppThunk {
  return deleteItemThunk<Team>({
    domain: teamDomain,
    requestStart,
    requestFail,
    requestSuccess: deleteTeamSuccess,
    itemId: domainItem.id
  });
}
