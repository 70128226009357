import React, { useEffect } from 'react';
import { Box, makeStyles, Theme, Tooltip } from '@material-ui/core';
import { JobDetailsChip } from 'components/Chip';
import CloseIcon from '@material-ui/icons/Close';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import { JobDetailsFields, JobDetailsProps } from 'models/Schedule';
import { JobChipType } from 'components/Chip/JobDetailsChip';
import AtRiskButton from 'components/AtRiskButton/AtRiskButton';
import { AppointmentStatus, CustomerAppointmentType } from 'models/Appointment';
import CustomButton from 'components/Button';
import { openInNewTab } from 'utils/window';
import { useDispatch } from 'react-redux';
import { getDomainSlice } from 'store';
import { jobTypeDomain } from 'models';
import { getUIAppointmentStatus } from 'utils/jobStatus';
import { useJobTypeData } from 'store/hooks';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { capitaliseFirstLetter } from '../../../utils';

interface StyleProps {
  aegisEnableUpdatedJobCardDetails: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>(
  (theme: Theme) => ({
    container: {
      position: 'absolute',
      height: 132,
      top: 14,
      left: 20,
      backgroundColor: theme.palette.common.white,
      boxShadow:
        '0px 2px 4px rgba(0, 0, 0, 0.14), 0px 3px 4px rgba(0, 0, 0, 0.12), 0px 1px 5px rgba(0, 0, 0, 0.2)',
      borderRadius: 4,
      zIndex: 2,
      cursor: 'default'
    },
    button: {
      padding: theme.spacing(0.5, 1),
      minHeight: 'unset'
    },
    detailsButton: {
      color: theme.palette.secondary.main
    },
    chipWrapper: {
      display: 'grid',
      width: 'fit-content',
      gridTemplateColumns: 'auto auto auto',
      gridGap: theme.spacing(1),
      padding: theme.spacing(1),
      paddingBottom: theme.spacing(0),
      marginRight: theme.spacing(2)
    },
    titleWrapper: {
      display: 'grid',
      width: 'fit-parent',
      gridTemplateColumns: 'auto auto',
      justifyContent: 'space-between',
      gridGap: theme.spacing(1),
      padding: theme.spacing(1),
      paddingBottom: theme.spacing(0),
      marginRight: theme.spacing(2)
    },
    clientName: {
      fontSize: 18,
      fontWeight: 600,
      alignSelf: 'center',
      marginLeft: theme.spacing(1)
    },
    closeIcon: {
      position: 'absolute',
      top: 6,
      right: 6,
      width: 18,
      height: 18,
      cursor: 'pointer'
    },
    reportProblemIcon: {
      color: theme.palette.aegis.colour.orange.darker
    },
    tooltip: {
      background: theme.palette.aegis.semantic.message.black,
      borderRadius: theme.spacing(0.5),
      padding: theme.spacing(1),
      marginTop: theme.spacing(1)
    },
    tooltipTypography: {
      color: theme.palette.aegis.semantic.message.white
    }
  }),
  { name: 'JobDetails' }
);

const hideAtRiskButtonCases = [
  AppointmentStatus.ABORTED,
  AppointmentStatus.CANCELLED,
  AppointmentStatus.COMPLETED
];

function getJobType(jobCardDetails: JobDetailsFields) {
  const { actions } = getDomainSlice(jobTypeDomain);
  const dispatch = useDispatch();
  const flags = useFlags();

  const jobType = useJobTypeData(jobCardDetails.jobTypeId);
  const jobTypeName = jobType?.name;

  useEffect(() => {
    if (actions.get.listAll && !jobTypeName) dispatch(actions.get.listAll());
  }, []);

  if (
    jobCardDetails.type === CustomerAppointmentType.EV ||
    (flags.aegisEnableMultiMpanInJobDetails &&
      jobType?.extraAttributes.multiMpan)
  ) {
    return jobTypeName;
  }

  if (jobType?.extraAttributes.polyphase) {
    return 'Polyphase';
  }

  if (jobType?.extraAttributes.recoveries) {
    return 'Recoveries';
  }

  return capitaliseFirstLetter(jobCardDetails.fuelType ?? '');
}

export default function JobDetails(props: JobDetailsProps) {
  const {
    selectedJobCardDetails,
    handleJobCardOnClick,
    handleSelectEngineer
  } = props;

  const jobType = getJobType(selectedJobCardDetails);
  const classes = useStyles();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { isOpen, id, ...details } = selectedJobCardDetails;

  const showAtRiskButton =
    (details.status && !hideAtRiskButtonCases.includes(details.status)) ||
    details.atRisk;

  const showComplaintsIcon =
    (selectedJobCardDetails?.complaints?.length ?? 0) > 0;

  return (
    <div className={classes.container} data-testid="jobDetailsContainer">
      <div className={classes.titleWrapper}>
        <span
          className={classes.clientName}
          data-testid="jobDetails-customerName"
        >
          {selectedJobCardDetails.customer?.firstName}{' '}
          {selectedJobCardDetails.customer?.lastName}
        </span>
        <Box display="flex" alignItems="center">
          {showComplaintsIcon && (
            <Tooltip
              classes={{ tooltip: classes.tooltip }}
              title="Complaint job"
              placement="bottom"
            >
              <Box display="flex" mr={0.5}>
                <ReportProblemIcon
                  className={classes.reportProblemIcon}
                  data-testid="jobCardDetailsComplaintJobIcon"
                />
              </Box>
            </Tooltip>
          )}
          {details.status && (
            <JobDetailsChip
              data-testid="jobDetails-status"
              type={JobChipType.Status}
              value={getUIAppointmentStatus(details.status)}
            />
          )}
        </Box>
      </div>
      <div className={classes.chipWrapper}>
        {details.type && (
          <JobDetailsChip
            data-testid="jobDetails-type"
            type={JobChipType.Type}
            value={jobType}
          />
        )}
        {details.postcode && (
          <JobDetailsChip
            data-testid="jobDetails-postcode"
            type={JobChipType.Postcode}
            value={details.postcode}
          />
        )}
        {details.timeSlot && (
          <JobDetailsChip
            data-testid="jobDetails-timeSlot"
            type={JobChipType.Timeslot}
            value={details.timeSlot}
          />
        )}
      </div>
      <div className={classes.chipWrapper}>
        <JobDetailsChip
          data-testid="jobDetails-assigned"
          type={JobChipType.Assigned}
          value={details.assigned}
        />
        <CustomButton
          data-testid="jobDetailsButton"
          onClick={() => openInNewTab(`/dashboard/jobs/${id}`)}
          className={classes.button}
          size="medium"
        >
          Open full Job
        </CustomButton>
        {showAtRiskButton && (
          <AtRiskButton
            appointmentId={props.selectedJobCardDetails.id}
            atRisk={props.selectedJobCardDetails.atRisk}
            data-testid="atRiskButton"
            onSuccess={props.requestFreshSchedules}
            className={classes.button}
          />
        )}
      </div>
      <CloseIcon
        className={classes.closeIcon}
        onClick={() => {
          handleSelectEngineer(undefined);
          handleJobCardOnClick({ ...selectedJobCardDetails, isOpen: false });
        }}
        data-testid="jobDetailsCardClose"
      />
    </div>
  );
}
