import React from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core';

interface MarkerContainerProps {
  isSelected: boolean;
  children: React.ReactNode | React.ReactNode[];
  onClick: () => void;
}

const useStyles = makeStyles<Theme, { selected: boolean }>(() => {
  return createStyles({
    container: {
      zIndex: ({ selected }) => (selected ? 1 : 0),
      position: ({ selected }) => (selected ? 'relative' : 'static')
    }
  });
});

const MarkerContainer = ({
  isSelected,
  children,
  onClick
}: MarkerContainerProps): JSX.Element => {
  const classes = useStyles({ selected: isSelected });

  return (
    <div
      data-testid="markerContainer"
      className={classes.container}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

export default MarkerContainer;
