import React, { memo } from 'react';
import {
  EngineerSchedule,
  ScheduleUnassignedAppointment,
  JobDetailsFields,
  ScheduleAssignmentType
} from 'models/Schedule';
import { makeStyles } from '@material-ui/core';
import AssignedMarker from './Markers/AssignedMarker';
import EngineerHomeMarker from './Markers/EngineerHomeMarker';
import UnassignedMarker from './Markers/UnassignedMarker';
import Route from './Route';
import SimpleMap, { Bounds } from './SimpleMap';
import { EngineerPointInfo } from './scheduleMapHelpers';
import MapOverlay from './MapOverlay';
import JobDetails from './JobDetails';

export type SelectEngineerCallback = (engineerId?: string) => void;

export interface MapWrapperProps {
  engineersSchedules: EngineerSchedule[];
  unassignedAppointments: ScheduleUnassignedAppointment[];
  selectedUnassigned: string;
  selectedEngineer?: string;
  handleSelectEngineer: SelectEngineerCallback;
  handleJobCardOnClick: (value: JobDetailsFields) => void;
  selectedJobCardDetails: JobDetailsFields;
  requestFreshSchedules: () => void;
}

function getJobPointsFromSchedule(
  engineerSchedule: EngineerSchedule
): EngineerPointInfo[] {
  return engineerSchedule.assignments
    .filter(assignment => assignment.type === ScheduleAssignmentType.Job)
    .map(assignment => {
      return {
        firstName: engineerSchedule.engineer.firstName,
        lastName: engineerSchedule.engineer.lastName,
        scheduleStatus: engineerSchedule.status,
        assignment,
        engineerUnavailable: engineerSchedule.unavailableReason != null
      };
    });
}

function getEngineerHomeMarkers(
  engineerSchedules: EngineerSchedule[],
  selectedEngineer?: string
): JSX.Element[] {
  return engineerSchedules.map(schedule => {
    return (
      <EngineerHomeMarker
        key={`home-${schedule.engineer.id}`}
        schedule={schedule}
        engineerId={schedule.engineer.id}
        selectedEngineer={selectedEngineer}
      />
    );
  });
}

function getRoutes(
  engineersSchedules: EngineerSchedule[],
  selectedEngineer?: string
): JSX.Element[] {
  return engineersSchedules
    .filter(e => e.geometry)
    .map((engineerSchedule: EngineerSchedule) => (
      <Route
        key={`route-${engineerSchedule.engineer.id}`}
        engineerId={engineerSchedule.engineer.id}
        geometry={engineerSchedule.geometry}
        selectedEngineer={selectedEngineer}
      />
    ));
}

function getUnassigned(
  unassigned: ScheduleUnassignedAppointment[],
  handleJobCardOnClick: (value: JobDetailsFields) => void,
  selectedUnassigned: string,
  handleSelectEngineer: SelectEngineerCallback,
  selectedJobCardDetails: JobDetailsFields,
  selectedEngineer?: string
): JSX.Element[] {
  return unassigned.map(unassignedScheduleAssignment => {
    return (
      <UnassignedMarker
        key={unassignedScheduleAssignment?.appointment?.id}
        selectedEngineer={selectedEngineer}
        handleSelectEngineer={handleSelectEngineer}
        selectedJobCardDetails={selectedJobCardDetails}
        {...{
          unassignedScheduleAssignment,
          handleJobCardOnClick,
          selectedUnassigned
        }}
      />
    );
  });
}

function getAssignments(
  handleJobCardOnClick: (value: JobDetailsFields) => void,
  engineersSchedules: EngineerSchedule[],
  handleSelectEngineer: SelectEngineerCallback,
  selectedJobCardDetails: JobDetailsFields,
  selectedEngineer?: string
): JSX.Element[] {
  return engineersSchedules.flatMap((engineerSchedule: EngineerSchedule) =>
    getJobPointsFromSchedule(engineerSchedule).map(engineerPoint => {
      return (
        <AssignedMarker
          key={engineerPoint.assignment.appointment.id}
          engineerPoint={engineerPoint}
          handleJobCardOnClick={handleJobCardOnClick}
          engineerId={engineerSchedule.engineer.id}
          selectedEngineer={selectedEngineer}
          handleSelectEngineer={handleSelectEngineer}
          selectedJobCardDetails={selectedJobCardDetails}
        />
      );
    })
  );
}

function getInitialBoundingBox(
  unassignedAppointments: ScheduleUnassignedAppointment[],
  engineersSchedules: EngineerSchedule[]
) {
  let bounds: Bounds = {
    W: 90,
    S: 180,
    E: -90,
    N: -180
  };

  const unassignedLocation = unassignedAppointments.map(
    appointment => appointment.location.geoLocation
  );

  const assignedLocation = engineersSchedules.flatMap(schedule =>
    schedule.assignments
      // Some assignments won't have a location
      .filter(assignment => assignment.location !== null)
      .flatMap(assignment => assignment.location.geoLocation)
  );

  [...unassignedLocation, ...assignedLocation].forEach(geoLocation => {
    bounds = {
      W: geoLocation.long < bounds.W ? geoLocation.long : bounds.W,
      S: geoLocation.lat < bounds.S ? geoLocation.lat : bounds.S,
      E: geoLocation.long > bounds.E ? geoLocation.long : bounds.E,
      N: geoLocation.lat > bounds.N ? geoLocation.lat : bounds.N
    };
  });

  return bounds;
}

const useStyles = makeStyles(theme => ({
  mapWrapper: {
    position: 'relative',
    flexGrow: 1,
    height: '100%',
    backgroundColor: theme.palette.info.light,
    borderRadius: 4,
    boxShadow:
      '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)'
  }
}));

const MapWrapper = ({
  engineersSchedules,
  unassignedAppointments,
  selectedUnassigned,
  selectedEngineer,
  handleJobCardOnClick,
  selectedJobCardDetails,
  requestFreshSchedules,
  handleSelectEngineer
}: MapWrapperProps) => {
  const classes = useStyles();
  const assignmentMarkers = getAssignments(
    handleJobCardOnClick,
    engineersSchedules,
    handleSelectEngineer,
    selectedJobCardDetails,
    selectedEngineer
  );

  const unassignedMarkers = getUnassigned(
    unassignedAppointments,
    handleJobCardOnClick,
    selectedUnassigned,
    handleSelectEngineer,
    selectedJobCardDetails,
    selectedEngineer
  );

  const engineerHomeMarkers = getEngineerHomeMarkers(
    engineersSchedules,
    selectedEngineer
  );

  const engineerRoutes = getRoutes(engineersSchedules, selectedEngineer);

  const mapFeatures = [
    ...assignmentMarkers,
    ...unassignedMarkers,
    ...engineerHomeMarkers,
    ...engineerRoutes
  ];

  const initialBounds = getInitialBoundingBox(
    unassignedAppointments,
    engineersSchedules
  );

  if (mapFeatures.length === 0) {
    return null;
  }

  const mapOverlay = <MapOverlay selectedEngineer={selectedEngineer} />;
  mapFeatures.unshift(mapOverlay);

  return (
    <div className={classes.mapWrapper}>
      {selectedJobCardDetails.isOpen && (
        <JobDetails
          handleJobCardOnClick={handleJobCardOnClick}
          selectedJobCardDetails={selectedJobCardDetails}
          requestFreshSchedules={requestFreshSchedules}
          handleSelectEngineer={handleSelectEngineer}
        />
      )}
      <SimpleMap initialBounds={initialBounds}>{mapFeatures}</SimpleMap>
    </div>
  );
};

export default memo(MapWrapper);
