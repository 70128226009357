import React, { useRef, useState, memo, useEffect } from 'react';
import { makeStyles, createStyles, Theme, alpha } from '@material-ui/core';
import { useDrop, DropTargetMonitor, useDragLayer } from 'react-dnd';
import { throttle } from 'utils';
import CircularProgress from '@material-ui/core/CircularProgress';

import JobCard, {
  JobCardStyleType,
  JobCardViewMode
} from 'components/Schedule/JobCard/JobCard';
import {
  ScheduleAssignmentEvent,
  ScheduleAssignmentType,
  EngineerSchedule,
  JobDetailsFields,
  ScheduleAssignment
} from 'models/Schedule';
import {
  getTimeDiff,
  timeToPixels,
  getCardPosition,
  getPostcode,
  getClosestPosition,
  JobTimeBoundaries,
  getMinutesWaiting
} from 'components/Schedule/scheduleHelpers';
import { scheduleStartTime } from 'constants/schedule';
import { AppointmentSlot } from 'models/Appointment';

import { timelineStart } from 'views/Schedule/ScheduleView';
import Typography from 'components/Typography';
import { getJobCardStyleType } from 'utils/jobStatus';
import { DragHoverCallback } from '../EngineerList/EngineerList';
import { defaultSlotHighlight } from '../TimelineHeader';
import { EngineerInfo } from '../EngineerInfo/EngineerInfo';
import { getHydratedJobDetailsWithAssignedSchedule } from '../Map/scheduleMapHelpers';
import PublishButton from './PublishButton';
import { tunePosition } from '../scheduleHelpers';
import PublishDialog from '../PublishDialog/PublishDialog';

interface EngineerRowProps {
  engineerSchedule: EngineerSchedule;
  isLoading: boolean;
  selectedJobCardDetails: JobDetailsFields;
  selectedEngineer?: string;
  onChange: (change: ScheduleAssignmentEvent) => void;
  onDragHover: DragHoverCallback;
  onPublish?: (engineerID: string) => void;
  onJobCardSelect: (slot: AppointmentSlot) => void;
  handleJobCardOnClick: (value: JobDetailsFields) => void;
}
interface HighlightProps {
  x: number;
  width: number;
  beforeShiftDuration: number;
  afterShiftDuration: number;
  mouseHover: boolean;
  engineerLacksJobType: boolean;
  isEngineerSelected?: boolean;
}
export interface JobDndItem {
  type: string;
  appointmentId: string;
  engineerId: string;
  slot: AppointmentSlot;
  driveTimeTo: number;
  label: string;
  jobType: string;
  duration: number;
  hideTravelTime: boolean;
  assigned: boolean;
  jobCardStyleType: JobCardStyleType;
}

const useStyles = makeStyles<Theme, HighlightProps>(
  theme =>
    createStyles({
      container: {
        width: '100%',
        height: 60,
        display: 'flex',
        position: 'relative',
        padding: theme.spacing(1, 0),
        opacity: ({ engineerLacksJobType }) =>
          engineerLacksJobType ? 0.25 : 1,
        filter: ({ engineerLacksJobType }) =>
          engineerLacksJobType ? `grayscale(1)` : `grayscale(0)`,
        borderRadius: theme.spacing(0.5),
        backgroundColor: ({ mouseHover, isEngineerSelected }) => {
          if (isEngineerSelected) {
            return theme.palette.aegis.colour.grey.lighter;
          }
          if (mouseHover) {
            return theme.palette.schedule.timeline.unassignedRow.background;
          }

          return 'transparent';
        }
      },
      publishButton: {
        position: 'absolute',
        top: 22,
        right: 13,
        cursor: 'pointer'
      },
      loading: {
        position: 'absolute',
        zIndex: 999,
        backgroundColor: '#eaeaea',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0
      },
      engineerInfoContainer: {
        display: 'flex',
        width: timelineStart - theme.spacing(1),
        marginLeft: theme.spacing(1),
        alignItems: 'center'
      },
      timeline: {
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        width: 420,
        height: 44
      },
      cardWrapper: {
        position: 'absolute',
        fontSize: 'initial',
        top: 0
      },
      spinner: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -10,
        marginLeft: -10
      },
      insertionPoint: {
        position: 'absolute',
        zIndex: 99,
        top: 0,
        left: 0,
        width: 2,
        height: 42,
        backgroundColor: theme.palette.schedule.timeline.row.insertionPointer
      },
      rowHightlight: {
        position: 'absolute',
        zIndex: 1,
        top: -1,
        left: ({ x }) => x,
        width: ({ width }) => width,
        height: 44,
        border: `1px dashed ${theme.palette.schedule.timeline.row.highlight}`
      },
      beforeShift: {
        position: 'absolute',
        zIndex: 2,
        left: ({ beforeShiftDuration }) => beforeShiftDuration,
        top: '50%',
        transform: 'translateY(-50%)',
        width: ({ x, beforeShiftDuration }) => x - beforeShiftDuration,
        height: 18,
        background: ({ mouseHover }) => `repeating-linear-gradient(
        90deg, ${theme.palette.schedule.timeline.shiftOvertime.color}
        0px, ${theme.palette.schedule.timeline.shiftOvertime.color}
        2px,
        ${
          mouseHover
            ? theme.palette.schedule.timeline.unassignedRow.background
            : theme.palette.common.white
        } 2px,
        ${
          mouseHover
            ? theme.palette.schedule.timeline.unassignedRow.background
            : theme.palette.common.white
        } 5px
        )`
      },
      shiftTime: {
        position: 'absolute',
        zIndex: 0,
        left: ({ x }) => x,
        top: '50%',
        transform: 'translateY(-50%)',
        width: ({ width }) => width,
        height: 18,
        background: () => {
          const transparent = alpha(theme.palette.common.white, 0);

          return `repeating-linear-gradient(
            90deg,
            ${transparent} 0px,
            ${transparent} 3px,
            ${theme.palette.jobStatus.assigned.background} 3px,
            ${theme.palette.jobStatus.assigned.background} 5px
            )`;
        }
      },
      afterShift: {
        position: 'absolute',
        zIndex: 2,
        left: ({ width, x }) => width + x,
        top: '50%',
        transform: 'translateY(-50%)',
        width: ({ afterShiftDuration }) => afterShiftDuration,
        height: 18,
        background: ({ mouseHover }) => `repeating-linear-gradient(
        90deg,
        ${theme.palette.schedule.timeline.shiftOvertime.color} 0px,
        ${theme.palette.schedule.timeline.shiftOvertime.color} 2px,
        ${
          mouseHover
            ? theme.palette.schedule.timeline.unassignedRow.background
            : theme.palette.common.white
        } 2px,
        ${
          mouseHover
            ? theme.palette.schedule.timeline.unassignedRow.background
            : theme.palette.common.white
        } 5px
        )`
      }
    }),
  { name: 'EngineerRow' }
);

const EngineerRow = ({
  isLoading,
  engineerSchedule,
  selectedJobCardDetails,
  selectedEngineer,
  onChange,
  onDragHover,
  onJobCardSelect,
  onPublish,
  handleJobCardOnClick
}: EngineerRowProps) => {
  const ref = useRef<HTMLInputElement>(null);

  const [insertionPosIndex, setInsertionPosIndex] = useState(-1);
  const [insertionPointX, setInsertionPointX] = useState(0);
  const [mouseHover, setMouseHover] = useState(false);
  const [publishDialogOpen, setPublishDialogOpen] = useState(false);

  const selectedEngineerId = selectedJobCardDetails.assigned?.engineerId;
  const isEngineerSelected =
    selectedJobCardDetails.isOpen &&
    selectedEngineerId === engineerSchedule.engineer.id;

  const { draggedJob } = useDragLayer((monitor: any) => ({
    draggedJob: monitor.getItem() as JobDndItem
  }));

  function sanityCheckIndex(index: number): number {
    if (index <= 0) {
      return 1;
    }
    // The highest index being passed should be that of the last (the END job)
    if (index >= engineerSchedule.assignments.length) {
      return engineerSchedule.assignments.length - 1;
    }
    return index;
  }

  // Calculate the position for row border highlight
  const x = timeToPixels(
    getTimeDiff(scheduleStartTime, engineerSchedule.engineer.startTime)
  );
  const width = timeToPixels(
    getTimeDiff(
      engineerSchedule.engineer.startTime,
      engineerSchedule.engineer.endTime
    )
  );

  // Remove 'START' and 'END' job types
  const jobCount = engineerSchedule.assignments.filter(
    assignment =>
      assignment.type === ScheduleAssignmentType.Job ||
      assignment.type === ScheduleAssignmentType.Fixed
  ).length;

  const beforeShiftDuration = jobCount
    ? getCardPosition(
        scheduleStartTime,
        engineerSchedule.assignments[1].arrivalTime,
        engineerSchedule.assignments[1].driveTimeTo
      )
    : 0;

  const afterShiftDuration = jobCount
    ? timeToPixels(
        getTimeDiff(
          engineerSchedule.engineer.endTime,
          engineerSchedule.assignments[engineerSchedule.assignments.length - 1]
            .departureTime
        )
      )
    : 0;

  const engineerLacksJobType = Boolean(
    draggedJob &&
      !engineerSchedule.engineer.jobTypes.includes(draggedJob.jobType)
  );

  const classes = useStyles({
    width,
    x,
    beforeShiftDuration,
    afterShiftDuration,
    mouseHover,
    engineerLacksJobType,
    isEngineerSelected
  });

  const positions: JobTimeBoundaries[] = [];

  const hover = (item: JobDndItem, monitor: DropTargetMonitor) => {
    if (monitor.canDrop()) {
      const containerX = ref?.current?.getBoundingClientRect().x || 0;
      const mouseX = monitor.getClientOffset()?.x || 0;
      const left = mouseX - containerX;
      const slotStartX = timeToPixels(
        getTimeDiff(scheduleStartTime, item.slot.startTime)
      );
      const slotEndX = timeToPixels(
        getTimeDiff(scheduleStartTime, item.slot.endTime)
      );

      // This is 1 because of START assignments
      let position: JobTimeBoundaries = { index: 1, x: slotStartX };

      if (positions.length) {
        const closestJobCard = getClosestPosition(positions, left);

        position = tunePosition(
          positions,
          slotStartX,
          slotEndX,
          closestJobCard
        );
      }

      setInsertionPosIndex(position.index);
      setInsertionPointX(position.x);

      return;
    }
    setInsertionPosIndex(-1);
    setInsertionPointX(-1);
  };

  const throttledHover = throttle(hover, 300);

  const [{ isOver, canDrop }, drop] = useDrop({
    accept: 'Job',
    canDrop: (item: JobDndItem, monitor) => {
      if (engineerLacksJobType) {
        return false;
      }

      const found = engineerSchedule.assignments.some(
        assignment => assignment.appointment?.id === item.appointmentId
      );

      if (found) {
        return false;
      }

      const slotStart = timeToPixels(
        getTimeDiff(scheduleStartTime, item.slot.startTime)
      );
      const slotEnd = timeToPixels(
        getTimeDiff(scheduleStartTime, item.slot.endTime)
      );

      const containerX = ref?.current?.getBoundingClientRect().x || 0;
      const mouseX = monitor.getClientOffset()?.x || 0;
      const left = mouseX - containerX;

      return left >= slotStart && left <= slotEnd;
    },
    drop: (item: JobDndItem, monitor) => {
      const didDrop = monitor.didDrop();

      if (didDrop) {
        return;
      }

      onChange({
        action: 'add',
        data: {
          position: sanityCheckIndex(insertionPosIndex)
        },
        engineerId: engineerSchedule.engineer.id,
        id: item.appointmentId,
        type: ScheduleAssignmentType.Job
      });
    },
    hover: throttledHover,
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  });

  useEffect(() => {
    if (isOver) {
      onDragHover(engineerSchedule.engineer.id);
    } else {
      onDragHover(undefined);
    }
  }, [isOver]);

  const engineerFullName = `${engineerSchedule.engineer.firstName} ${engineerSchedule.engineer.lastName}`;

  const renderAssignment = (
    assignment: ScheduleAssignment,
    index: number
  ): JSX.Element => {
    const left = getCardPosition(
      engineerSchedule.engineer.startTime,
      assignment.arrivalTime,
      assignment.driveTimeTo
    );

    const minutesWaiting = getMinutesWaiting(
      assignment.arrivalTime,
      assignment.earliestStartTime
    );

    if (assignment.type !== ScheduleAssignmentType.End) {
      if (assignment.type !== ScheduleAssignmentType.Start) {
        positions.push({
          index,
          x:
            left +
            x +
            timeToPixels(assignment.driveTimeTo) +
            timeToPixels(minutesWaiting) +
            timeToPixels(assignment.length),
          type: assignment.type
        });
      } else {
        positions.push({
          // Index should be 0, because this is a START type appointment
          index: 0,
          x: left + x,
          type: assignment.type
        });
      }
    }

    return (
      <div
        key={
          assignment.appointment?.id ||
          engineerSchedule.engineer.id + assignment.type
        }
        className={classes.cardWrapper}
        style={{
          left: left + x
        }}
        onDragStart={() =>
          assignment.appointment && 'slot' in assignment.appointment
            ? onJobCardSelect(assignment.appointment.slot)
            : () => null
        }
        onDrag={() =>
          assignment.appointment && 'slot' in assignment.appointment
            ? onJobCardSelect(assignment.appointment.slot)
            : () => null
        }
        onDragEnd={() =>
          assignment.appointment && 'slot' in assignment.appointment
            ? onJobCardSelect(defaultSlotHighlight)
            : () => null
        }
        onClick={() => {
          onDragHover(engineerSchedule.engineer.id);

          if (assignment.appointment && 'slot' in assignment.appointment) {
            onJobCardSelect(assignment.appointment.slot);
          }

          if (assignment.type === ScheduleAssignmentType.Job) {
            handleJobCardOnClick(
              getHydratedJobDetailsWithAssignedSchedule(assignment, {
                engineerId: engineerSchedule.engineer.id,
                firstName: engineerSchedule.engineer.firstName,
                lastName: engineerSchedule.engineer.lastName,
                scheduleStatus: engineerSchedule.status
              })
            );
          }
        }}
        data-testid="jobCardWrapperClickHandler"
      >
        <JobCard
          index={index}
          jobCardStyleType={getJobCardStyleType(
            assignment.appointment,
            true,
            assignment.type === ScheduleAssignmentType.Fixed
          )}
          hasComplaints={Boolean(
            assignment.appointment
              ? assignment.appointment.complaints?.length
              : null
          )}
          canDrag={assignment.type != ScheduleAssignmentType.Fixed}
          appointmentId={assignment.appointment?.id}
          engineerId={engineerSchedule.engineer.id}
          duration={assignment.length}
          arrivalTime={assignment.arrivalTime}
          earliestStartTime={assignment.earliestStartTime}
          minutesWaiting={minutesWaiting}
          driveTimeTo={assignment.driveTimeTo}
          slot={assignment.appointment?.slot}
          jobType={assignment.appointment?.jobType}
          type={assignment.appointment?.appointmentType?.type}
          label={getPostcode(assignment.location?.postcode)}
          viewMode={JobCardViewMode.TIMELINE}
          assigned
          onDrop={item =>
            onChange({
              action: 'remove',
              engineerId: engineerSchedule.engineer.id,
              id: item.appointmentId,
              type: ScheduleAssignmentType.Job
            })
          }
          selectedJobCardDetails={selectedJobCardDetails}
          selectedEngineer={selectedEngineer}
          isStartOrEndJob={[
            ScheduleAssignmentType.Start,
            ScheduleAssignmentType.End
          ].includes(assignment.type)}
        />
      </div>
    );
  };

  const engineerTravelTime = engineerSchedule.assignments.reduce(
    (acc, assignment) => acc + assignment.driveTimeTo,
    0
  );

  drop(ref);

  return (
    <div
      className={classes.container}
      onMouseEnter={() => setMouseHover(true)}
      onMouseLeave={() => setMouseHover(false)}
      data-qa-id="engineerRow"
      data-testid="engineerRow"
    >
      {isLoading && (
        <div
          className={classes.loading}
          data-testid="engineerRowLoading"
          data-qa-id="engineerRowLoading"
        >
          <CircularProgress
            size={20}
            className={classes.spinner}
            color="primary"
          />
        </div>
      )}
      <EngineerInfo
        firstName={engineerSchedule.engineer.firstName}
        lastName={engineerSchedule.engineer.lastName}
        vehicleType={engineerSchedule.engineer.vehicleType}
        jobCount={jobCount}
        travelTime={engineerTravelTime}
        unavailableReason={engineerSchedule.unavailableReason}
        scheduleStatus={engineerSchedule.status}
        engineerId={engineerSchedule.engineer.id}
        containerStyles={classes.engineerInfoContainer}
      />
      <div ref={ref} className={classes.timeline} data-qa-id="engineerTimeline">
        {isOver && canDrop && (
          <div data-qa-id="rowHighlight" className={classes.rowHighlight} />
        )}
        {isOver && insertionPointX !== -1 && (
          <div
            style={{ left: insertionPointX }}
            className={classes.insertionPoint}
          />
        )}
        <>
          {jobCount === 0 ? null : (
            <div
              data-testid="beforeShiftTime"
              className={classes.beforeShift}
            />
          )}
          <div data-testid="shiftTime" className={classes.shiftTime} />
          <div data-testid="afterShiftTime" className={classes.afterShift} />
        </>
        {engineerSchedule.assignments
          .filter(assignment => assignment.type !== ScheduleAssignmentType.End)
          .map(renderAssignment)}
      </div>
      {!(mouseHover && onPublish) || (
        <div
          onClick={() => {
            setPublishDialogOpen(true);
            setMouseHover(false);
          }}
          className={classes.publishButton}
          data-testid="publishButton"
        >
          <PublishButton />
        </div>
      )}
      {publishDialogOpen && (
        <PublishDialog
          open={publishDialogOpen}
          onClose={() => setPublishDialogOpen(false)}
          onPublish={() => {
            if (onPublish) {
              onPublish(engineerSchedule.engineer.id);
              setPublishDialogOpen(false);
            }
          }}
        >
          <Typography variant="body" component="h3" display="block">
            {`Are you sure you would like to publish ${engineerFullName}'s schedule?`}
            <br />
            They will receive a notification and be able to call customers to
            confirm bookings.
          </Typography>
        </PublishDialog>
      )}
    </div>
  );
};

export default memo(EngineerRow);
