import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getDomainSlice } from 'store';
import { scheduleSelector } from 'store/slices/schedule';
import { useQueryStringState } from 'utils/custom-hooks/use-query-string-state';
import { scheduleDomain } from 'models';
import { Schedule } from './Schedule';

export interface ScheduleFilters {
  areas: string[];
  date: string;
}

function ScheduleContainer() {
  const { actions } = getDomainSlice(scheduleDomain);
  const dispatch = useDispatch();
  const {
    items: [scheduleData],
    isLoading
  } = useSelector(scheduleSelector);

  const [{ areas, date }, setFilters] = useQueryStringState<ScheduleFilters>({
    areas: [],
    date: ''
  });

  useEffect(() => {
    if (date && areas?.length) {
      dispatch(actions.get.list(date, areas));
    }
  }, [areas, date]);

  return (
    <Schedule
      {...{
        date,
        areas,
        setFilters,
        scheduleData,
        isLoading
      }}
    />
  );
}

export default ScheduleContainer;
