import React from 'react';

import {
  List,
  ListItem,
  ListItemText,
  makeStyles,
  alpha,
  Theme,
  createStyles
} from '@material-ui/core';
import { AppointmentSlot } from 'models/Appointment';
import {
  getTimeDiff,
  getTimeLabels,
  timeToPixels
} from 'components/Schedule/scheduleHelpers';
import { scheduleEndTime, scheduleStartTime } from 'constants/schedule';

export interface TimelineHeaderProps {
  endTime: string;
  startTime: string;
  slot: AppointmentSlot;
  className?: string;
  verticalAlign?: 'top' | 'bottom';
}

const times = getTimeLabels(scheduleStartTime, scheduleEndTime);

const useStyles = makeStyles<
  Theme,
  {
    x: number;
    width: number;
    verticalAlign: TimelineHeaderProps['verticalAlign'];
  }
>(theme =>
  createStyles({
    timesListWrapper: {
      position: 'relative'
    },
    timesList: {
      display: 'flex',
      flexDirection: 'row',
      position: 'relative',
      justifyContent: 'flex-end',
      paddingBottom: 0,
      backgroundColor: theme.palette.schedule.timeline.header.background,
      color: theme.palette.schedule.timeline.header.color,
      '& > .MuiListItem-root': {
        '&:first-child, &:last-child': {
          color: alpha(theme.palette.schedule.timeline.header.color, 0.5)
        },
        alignItems: 'flex-start',
        padding: 0,
        width: 60,
        textAlign: 'center'
      }
    },
    listItemText: {
      fontSize: 14,
      lineHeight: '21px'
    },
    highlight: {
      width: ({ width }) => width,
      position: 'absolute',
      backgroundColor: ({ x }) =>
        x > 0
          ? theme.palette.schedule.timeline.header.highlight
          : 'transparent',
      left: ({ x }) => x,
      // There's a 1px difference between the positioning of vertically aligned top vs bottom 🤦🏼‍♂️
      top: ({ verticalAlign }) => (verticalAlign === 'bottom' ? 'auto' : 1),
      bottom: ({ verticalAlign }) => (verticalAlign === 'top' ? 'auto' : 0),
      margin: 'auto',
      height: 2,
      zIndex: 1
    },
    highlightCenter: {
      width: 1,
      height: 2,
      backgroundColor: theme.palette.divider,
      margin: '0 auto'
    }
  })
);

export const defaultSlotHighlight: AppointmentSlot = {
  startTime: '',
  endTime: '',
  date: ''
};

export default function TimelineHeader(props: TimelineHeaderProps) {
  let x = 0;
  let width = 0;
  if (props.slot && props.slot.startTime !== '' && props.slot.endTime !== '') {
    // + 1 for X and - 3 for width in order to position the highlight right between the swimlanes
    x =
      timeToPixels(getTimeDiff(scheduleStartTime, props.slot.startTime)) +
      177 +
      1;
    width = timeToPixels(
      getTimeDiff(props.slot.startTime, props.slot.endTime) - 3
    );
  }

  const classes = useStyles({
    width,
    x,
    verticalAlign: props.verticalAlign || 'bottom'
  });
  return (
    <div
      className={classes.timesListWrapper}
      data-testid="timelineHeaderWrapper"
    >
      <div
        data-testid="timelineHeader"
        data-qa-id="timelineHeader"
        className={classes.highlight}
      >
        <div className={classes.highlightCenter} />
      </div>
      <List
        className={
          props.className
            ? `${props.className} ${classes.timesList}`
            : classes.timesList
        }
      >
        {times.map(time => (
          <ListItem key={time}>
            <ListItemText
              primary={time}
              classes={{ primary: classes.listItemText }}
            />
          </ListItem>
        ))}
      </List>
    </div>
  );
}
