import React from 'react';
import { makeStyles } from '@material-ui/core';
import { capitaliseFirstLetter } from 'utils';
import Typography from 'components/Typography';

const useStyles = makeStyles(theme => ({
  engineerName: {
    color: theme.palette.schedule.timeline.engineerInfo.name,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
}));

interface Props {
  firstName: string;
  lastName: string;
}

export const EngineerName = ({ firstName, lastName }: Props) => {
  const classes = useStyles();
  const name = `${capitaliseFirstLetter(firstName)} ${capitaliseFirstLetter(
    lastName
  )}`.trim();

  return (
    <Typography
      className={classes.engineerName}
      data-testid="engineerName"
      title={name}
    >
      {name}
    </Typography>
  );
};
