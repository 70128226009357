import React from 'react';
import { makeStyles, TableCell, TableRowProps } from '@material-ui/core';
import { useTableStyles } from './ListTable';

interface ListTableHeaderCellProps extends TableRowProps {
  width: number;
}

const useStyles = makeStyles(() => ({
  tableHeaderCell: {
    width: ({ width }: ListTableHeaderCellProps) => width
  }
}));

export default function ListTableHeaderCell({
  width,
  children
}: ListTableHeaderCellProps) {
  const classes = useStyles({ width });
  const tableClasses = useTableStyles();

  return (
    <TableCell
      className={`${classes.tableHeaderCell} ${tableClasses.tableHeaderBorder}`}
    >
      {children}
    </TableCell>
  );
}
