import React from 'react';
import { Theme, createStyles, makeStyles, useTheme } from '@material-ui/core';
import Typography from 'components/Typography';
import { VehicleTypes } from 'models/Engineers';

const style = makeStyles<Theme>(theme =>
  createStyles({
    evText: {
      fontSize: 12,
      color: theme.palette.aegis.colour.grey.darker
    }
  })
);

const EVIcon = () => {
  return (
    <Typography
      variant="micro"
      className={style().evText}
      data-testid="engineerEvIcon"
    >
      EV
    </Typography>
  );
};

const VanIcon = () => {
  const theme = useTheme();

  return (
    <svg
      width="18"
      height="13"
      viewBox="0 0 18 13"
      fill="none"
      data-testid="engineerVanIcon"
    >
      <path
        d="M0.0283203 0.49707H12.3677L17.6105 7.06906V10.5522H0.0283203V0.49707Z"
        fill={theme.palette.aegis.colour.grey.darker}
      />
      <circle
        cx="2.95969"
        cy="10.5522"
        r="2.38034"
        fill={theme.palette.aegis.colour.grey.darker}
      />
      <circle
        cx="14.2947"
        cy="10.5522"
        r="2.38034"
        fill={theme.palette.aegis.colour.grey.darker}
      />
    </svg>
  );
};

interface VehicleIconProps {
  vehicleType: string | null;
}

function VehicleTypeIcon({ vehicleType }: VehicleIconProps) {
  switch (vehicleType || VehicleTypes.None) {
    case VehicleTypes.EV:
    case VehicleTypes.EVRural:
    case VehicleTypes.EVExtraRural:
    case VehicleTypes.EVUrban:
    case VehicleTypes.EVExtraUrban:
      return <EVIcon />;

    case VehicleTypes.Diesel:
    case VehicleTypes.DieselRural:
    case VehicleTypes.DieselExtraRural:
    case VehicleTypes.DieselUrban:
    case VehicleTypes.DieselExtraUrban:
    case VehicleTypes.Hybrid:
    case VehicleTypes.None:
    case VehicleTypes.Petrol:
      return <VanIcon />;
    default:
      return null;
  }
}

export default VehicleTypeIcon;
