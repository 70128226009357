import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core';

import Modal from 'components/Modal';
import Button from 'components/Button';
import Form, { FormApiWithRestart } from 'components/Forms/Form';
import Engineer from 'models/Engineers';
import {
  useSkillsData,
  useTeamsData,
  usePatchesData,
  useShiftPatternsData
} from 'store/selectors';
import { Field, FormValues } from 'components/Forms';
import Typography from 'components/Typography';
import { fetchAllSkills } from 'store/slices/skills';
import { fetchAllTeams } from 'store/slices/teams';
import { fetchAllShiftPatterns } from 'store/slices/shiftPatterns';
import { editEngineerWithRefresh } from 'store/slices/engineers';
import { fetchAllPatches } from 'store/slices/patches';
import {
  editEngineerPersonalFormFields,
  editEngineerFormValidation,
  getEngineerSkillsField,
  getCompanyDetailsFields
} from './editEngineerModalConfig';

interface EditEngineerModalProps {
  engineer: Engineer;
  open: boolean;
  onClose: () => void;
}

const useStyles = makeStyles(theme => ({
  formSectionTitle: {
    margin: theme.spacing(3, 0, 1.5)
  }
}));

function EditEngineerModal({
  engineer,
  open,
  onClose
}: EditEngineerModalProps) {
  const formRef = React.useRef<FormApiWithRestart>();

  const dispatch = useDispatch();

  const skills = useSkillsData();
  const teams = useTeamsData();
  const patches = usePatchesData();
  const shiftPatterns = useShiftPatternsData();

  const classes = useStyles();

  useEffect(() => {
    // Doing this on when open is set to true for the first time, as on mount interferes with the engineer calendar Cypress tests
    if (open) {
      dispatch(fetchAllSkills());
      dispatch(fetchAllTeams());
      dispatch(fetchAllPatches());
      dispatch(fetchAllShiftPatterns());
    }
  }, [open]);

  const initialFormValues = {
    firstName: engineer.firstName,
    lastName: engineer.lastName,
    phoneNumber: engineer.phoneNumber,
    emailAddress: engineer.emailAddress ?? '',
    postcode: engineer.postcode,
    skills: engineer.skills.map(({ id, name, category }) => ({
      category,
      label: name,
      value: id
    })),
    team: '8a5f8ae7-8dbe-4d10-8861-facf34f5d93c',
    company: engineer.company,
    vehicleType: engineer.vehicleType,
    byBox: engineer.byBox,
    defaultPatch: engineer.defaultPatch.id,
    defaultShiftPattern: engineer.defaultShiftPattern.id,
    defaultEfficiency: engineer.defaultEfficiency
  };

  const companyDetailsFields = getCompanyDetailsFields(
    teams,
    patches,
    shiftPatterns
  );

  const onEditEngineerSubmit = (editedEngineerValues: FormValues) => {
    dispatch(editEngineerWithRefresh(editedEngineerValues, engineer.id));
  };

  return (
    <Modal
      title="Edit Engineer"
      testId="editEngineerModal"
      open={open}
      onClose={onClose}
      modalActions={
        <>
          <Button
            color="secondary"
            size="small"
            onClick={onClose}
            data-testid="editEngineerModalCancelBtn"
          >
            Cancel
          </Button>
          <Button
            color="primary"
            size="small"
            onClick={() => formRef.current?.submit()}
            data-testid="editEngineerModalSubmitBtn"
          >
            Save
          </Button>
        </>
      }
    >
      <Form
        validationFunc={editEngineerFormValidation}
        fields={editEngineerPersonalFormFields}
        onSubmit={onEditEngineerSubmit}
        initialValues={initialFormValues}
        goBackToPreviousPage={false}
        keepDirtyOnReinitialize
      >
        {({ form }) => {
          formRef.current = form;

          return (
            <>
              <Typography variant="h2" className={classes.formSectionTitle}>
                Company Details
              </Typography>
              <Field field={getEngineerSkillsField(skills)} />
              {companyDetailsFields.map(field => (
                <Field key={field.id} field={field} />
              ))}
            </>
          );
        }}
      </Form>
    </Modal>
  );
}

export default EditEngineerModal;
