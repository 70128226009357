import ShiftPattern from 'models/ShiftPattern';
import GenericDataType from './GenericDataType';
import Skill from './Skills';

export default interface Adjustment extends GenericDataType {
  reason: string | null;
  changes: AdjustmentChangeType[];
  pattern:
    | DailyAdjustmentPatternType
    | WeeklyAdjustmentPatternType
    | MonthlyAdjustmentPatternType;
  startDate: string;
  endDate: string;
  parentId?: string;
  priority: AdjustmentPriority;
  groupId?: string;
}

export enum DayOfWeek {
  Monday = 'MONDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY',
  Thursday = 'THURSDAY',
  Friday = 'FRIDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY'
}

export enum ChangeType {
  Replace = 'REPLACE',
  Add = 'ADD',
  Subtract = 'SUBTRACT'
}
export enum AdjustmentType {
  Shift = 'SHIFT_PATTERN',
  Patch = 'PATCH',
  Efficiency = 'EFFICIENCY',
  Skills = 'SKILLS',
  JobType = 'JOB_TYPE',
  Postcode = 'POSTCODE'
}
export enum Pattern {
  Daily = 'DAILY',
  Weekly = 'WEEKLY',
  Monthly = 'MONTHLY'
}

export enum ShiftAdjustmentReason {
  AdhocMentoring = 'Adhoc Mentoring',
  AnnualLeave = 'Annual Leave',
  AWOL = 'AWOL',
  BelongingNetwork = 'Belonging Network',
  CompassionateLeave = 'Compassionate Leave',
  DevelopmentDays = 'Development Days',
  EmergencyLeave = 'Emergency Leave',
  EmpoweredPeople = 'Empowered People',
  FlexibleWorkingRequest = 'Flexible working request',
  MobilisationTravel = 'Mobilisation Travel',
  MomentsThatMatter = 'Moments that Matter',
  MyProgressMeeting = 'My Progress Meeting',
  NewAdditionLeave = 'New Addition Leave',
  OffTheToolsNonTechnical = 'Off the tools (non-technical)',
  OffTheToolsTechnical = 'Off the tools (technical)',
  OVOProjectSupport = 'OVO Project Support',
  PersonalAppointment = 'Personal Appointment',
  PhasedReturn = 'Phased return',
  PlannedSickness = 'Planned Sickness',
  ReligiousHoliday = 'Religious Holiday',
  RoutineMentoring = 'Routine Mentoring',
  Sickness = 'Sickness',
  Suspended = 'Suspended',
  TradeUnionAdhoc = 'Trade Union - Adhoc',
  TradeUnionRegularMeeting = 'Trade Union - Regular Meeting',
  Training = 'Training',
  UnpaidLeave = 'Unpaid Leave',
  VehiclePlannedMaintenance = 'Vehicle - planned maintenance',
  VehicleUnplannedMaintenance = 'Vehicle - unplanned maintenance',
  VehicleSwap = 'Vehicle Swap'
}

export enum ScheduleAdjustmentReason {
  AnnualLeave = 'Annual Leave',
  NewStarter = 'New Starter',
  BankHoliday = 'Bank Holiday',
  SickLeave = 'Sick Leave',
  Furlough = 'Furlough',
  SelfIsolating = 'Self-Isolating',
  Mentoring = 'Mentoring',
  Mitigation = 'Mitigation',
  PatchRotation = 'Patch rotation',
  EngineerMobilisation = 'Engineer mobilisation',
  Apprentice = 'Apprentice',
  ParentalLeave = 'Parental Leave',
  SaturdayWorking = 'Saturday working',
  Suspended = 'Suspended',
  FlexiDayOff = 'Flexi Day Off',
  Leaver = 'Leaver',
  Misc = 'Misc',
  Ringfenced = 'Ringfenced',
  SixthDayWorking = '6th Day Working',
  Overtime = 'Overtime',
  PartDayAnnualLeave = 'Part Day Annual Leave',
  Mobilisation = 'Mobilisation',
  PhasedReturn = 'Phased Return',
  FlexibleWorking = 'Flexible Working',
  'EV/Solar' = 'EV/Solar',
  Bybox = 'Bybox',
  CompetencyRemoval = 'Competency removal',
  EvCharging = 'Ev charging',
  FixedAppointmentWorkaround = 'Fixed appointment workaround',
  ReducedWorkloadForPersonalMedicalReasons = 'Reduced workload for personal/medical reasons',
  CompassionateLeave = 'Compassionate Leave',
  MomentsThatMatter = 'Moments That Matter',
  OffTheTools = 'Off The Tools',
  Sickness = 'Sickness',
  Training = 'Training',
  VoidsRC = 'Voids RC',
  ComplaintsRC = 'Complaints RC',
  ReservedCapacity = 'Reserved Capacity',
  Planning = 'Planning',
  EV = 'EV',
  Competency = 'Competency',
  CoverageRotation = 'Coverage Rotation'
}

export const OldShiftAdjustmentReasonToNewShiftAdjustmentReasonMap: Record<
  string,
  string
> = {
  [ScheduleAdjustmentReason.AnnualLeave]: ShiftAdjustmentReason.AnnualLeave,
  [ScheduleAdjustmentReason.CompassionateLeave]:
    ShiftAdjustmentReason.CompassionateLeave,
  [ScheduleAdjustmentReason['EV/Solar']]:
    ShiftAdjustmentReason.OVOProjectSupport,
  [ScheduleAdjustmentReason.FlexibleWorking]:
    ShiftAdjustmentReason.FlexibleWorkingRequest,
  [ScheduleAdjustmentReason.Mentoring]: ShiftAdjustmentReason.AdhocMentoring,
  [ScheduleAdjustmentReason.Mobilisation]:
    ShiftAdjustmentReason.MobilisationTravel,
  [ScheduleAdjustmentReason.MomentsThatMatter]:
    ShiftAdjustmentReason.MomentsThatMatter,
  [ScheduleAdjustmentReason.OffTheTools]:
    ShiftAdjustmentReason.OffTheToolsTechnical,
  [ScheduleAdjustmentReason.Overtime]:
    ShiftAdjustmentReason.FlexibleWorkingRequest,
  [ScheduleAdjustmentReason.PartDayAnnualLeave]:
    ShiftAdjustmentReason.AnnualLeave,
  [ScheduleAdjustmentReason.PhasedReturn]: ShiftAdjustmentReason.PhasedReturn,
  [ScheduleAdjustmentReason.Sickness]: ShiftAdjustmentReason.Sickness,
  [ScheduleAdjustmentReason.SelfIsolating]:
    ShiftAdjustmentReason.PlannedSickness,
  [ScheduleAdjustmentReason.Suspended]: ShiftAdjustmentReason.Suspended,
  [ScheduleAdjustmentReason.Training]: ShiftAdjustmentReason.Training
};

export const OldPatchAdjustmentReasonToNewPatchAdjustmentReasonMap: Record<
  string,
  string
> = {
  [ScheduleAdjustmentReason.EngineerMobilisation]:
    ShiftAdjustmentReason.MobilisationTravel,
  [ScheduleAdjustmentReason.Mitigation]: ScheduleAdjustmentReason.Mitigation,
  [ScheduleAdjustmentReason.PatchRotation]:
    ScheduleAdjustmentReason.PatchRotation,
  [ScheduleAdjustmentReason.SaturdayWorking]:
    ScheduleAdjustmentReason.SaturdayWorking
};

export enum PatchAdjustmentReason {
  PostcodeAdjustment = 'Postcode Adjustment'
}

export enum EfficiencyAdjustmentReason {
  NewStarter = 'New Starter',
  Mentoring = 'Mentoring',
  TeamLeader = 'Team Leader'
}

export const efficiencyMultipliers = [0, 0.25, 0.5, 0.75, 1.0, 1.25];

export type AdjustmentChangeType =
  | ShiftPatternAdjustmentChange
  | PatchAdjustmentChange
  | PostcodeAdjustmentChange
  | EfficiencyAdjustmentChange
  | SkillAdjustmentChange;

export interface ShiftPatternAdjustmentChange {
  field: AdjustmentType.Shift;
  change: ShiftPattern;
}
export interface PatchAdjustmentChange {
  field: AdjustmentType.Patch;
  change: string;
}
export interface PostcodeAdjustmentChange {
  field: AdjustmentType.Postcode;
  change: string;
}
export interface EfficiencyAdjustmentChange {
  field: AdjustmentType.Efficiency;
  change: number;
}
export interface SkillAdjustmentChange {
  field: AdjustmentType.Skills;
  change: string[] | Skill[];
}

export interface DailyAdjustmentPatternType {
  type: Pattern.Daily;
}

export interface WeeklyAdjustmentPatternType {
  type: Pattern.Weekly;
  weekdays: DayOfWeek[];
}

export interface MonthlyAdjustmentPatternType {
  type: Pattern.Monthly;
  days: number[];
}

export enum AdjustmentPriority {
  Permanent = 1,
  Temporary = 2
}
