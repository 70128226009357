import React, { useEffect } from 'react';
import { FormValues } from 'components/Forms';
import { useDispatch } from 'react-redux';
import Skill from 'models/Skills';
import { useSkillCategoriesData } from 'store/selectors';
import { fetchAllSkillCategories } from 'store/slices/skillCategories';
import { fetchSkills, newAddSkill, newEditSkill } from 'store/slices/skills';
import FormModal, { FormModalMode } from 'components/FormModal/FormModal';
import { skillDomain } from 'models';
import {
  getSkillsInputFieldsProps,
  newInitialFormValidation
} from './FormConfig';

interface SkillModalProps {
  open: boolean;
  closeModal: () => void;
  cancel: () => void;
  currentSkill?: Skill;
}

export default function SkillModal({
  open,
  closeModal,
  cancel,
  currentSkill
}: SkillModalProps) {
  const dispatch = useDispatch();
  const mode = currentSkill ? FormModalMode.EDIT : FormModalMode.ADD;
  const skillCategoriesData = useSkillCategoriesData();

  useEffect(() => {
    dispatch(fetchAllSkillCategories());
  }, []);

  const addApiCallback = async (values: FormValues) => {
    await dispatch(newAddSkill(values));
    closeModal();
  };

  const editApiCallback = async (values: FormValues) => {
    if (currentSkill) {
      await dispatch(
        newEditSkill(
          { name: values.name, categoryId: values.category },
          currentSkill.id
        )
      );
      closeModal();
    }
  };

  const refreshSkillList = () => {
    dispatch(fetchSkills());
  };

  return (
    <FormModal
      fields={getSkillsInputFieldsProps(mode, skillCategoriesData)}
      initialValues={
        currentSkill
          ? ({
              category: currentSkill.category.id,
              name: currentSkill.name
            } as FormValues)
          : undefined
      }
      domain={skillDomain}
      open={open}
      currentItem={currentSkill}
      validationFunc={newInitialFormValidation}
      onAdd={addApiCallback}
      onEdit={editApiCallback}
      onCancel={cancel}
      onSaved={refreshSkillList}
    />
  );
}
