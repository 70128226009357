import { DateTime, Info, Interval } from 'luxon';
import { Dispatch, SetStateAction, useState, useEffect } from 'react';
import { getWeekDaysFromInterval } from 'utils/dates';

interface FormDaysOfWeekState {
  dateFrom: DateTime;
  dateTo: DateTime;
}

export function useFormDaysOfWeek(): [
  FormDaysOfWeekState | undefined,
  Dispatch<SetStateAction<any>>,
  string[]
] {
  const [valueFromSpy, setValueFromSpy] = useState<{
    dateFrom: DateTime;
    dateTo: DateTime;
  }>();
  const [daysOfWeek, setDaysOfWeek] = useState(Info.weekdays());

  useEffect(() => {
    if (valueFromSpy?.dateFrom && valueFromSpy?.dateTo) {
      const interval = Interval.fromDateTimes(
        valueFromSpy?.dateFrom,
        valueFromSpy?.dateTo
      );
      if (interval.isValid) {
        const weekdays = getWeekDaysFromInterval(interval);
        setDaysOfWeek(weekdays);
      }
    }
  }, [valueFromSpy]);

  return [valueFromSpy as FormDaysOfWeekState, setValueFromSpy, daysOfWeek];
}

export const formSpyRelevantDays = (
  values: Record<string, any>,
  formDates: FormDaysOfWeekState | undefined,
  setValueFormSpy: Dispatch<SetStateAction<any>>
) => {
  if (values.adjustmentValidFrom && values.adjustmentValidTo) {
    // Try to create a date, if the date is invalid don't call `setValueFormSpy`
    const validFromDate =
      values.adjustmentValidFrom instanceof DateTime
        ? values.adjustmentValidFrom
        : DateTime.fromISO(values.adjustmentValidFrom);
    const validToDate =
      values.adjustmentValidTo instanceof DateTime
        ? values.adjustmentValidTo
        : DateTime.fromISO(values.adjustmentValidTo);

    if (
      validFromDate.isValid &&
      validToDate.isValid &&
      // Have to use `toMillis` here because direct comparison is not reliable
      (formDates?.dateFrom.toMillis() !== validFromDate.toMillis() ||
        formDates?.dateTo.toMillis() !== validToDate.toMillis())
    ) {
      /**
       * There is a bug with react-final-form that triggers the
       * "cannot update a component while rendering a different component" warning in the console.
       *  This is the workaround for it.
       */
      setTimeout(() => {
        setValueFormSpy({
          dateFrom: validFromDate,
          dateTo: validToDate
        });
      }, 0);
    }
  }

  return null;
};
