/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export enum SnackbarStatus {
  Success = 'success',
  Error = 'error',
  Warning = 'warning',
  Info = 'info'
}

export interface Snackbar {
  title?: string;
  message: string | null;
  show: boolean;
  redirectAction?: { title: string; url: string; newTab?: boolean } | null;
  status: SnackbarStatus | null;
}

const snackbar = createSlice({
  name: 'snackbar',
  initialState: {
    message: null,
    show: false,
    status: null,
    redirectAction: null
  },
  reducers: {
    setSnackbar: (state: Snackbar, { payload }: PayloadAction<Snackbar>) => {
      state.title = payload.title;
      state.message = payload.message;
      state.show = payload.show;
      state.status = payload.status;
      state.redirectAction = payload.redirectAction;
    },
    clearSnackbar: (state: Snackbar) => {
      state.message = null;
      state.show = false;
    }
  }
});

export const { clearSnackbar, setSnackbar } = snackbar.actions;

export default snackbar.reducer;
