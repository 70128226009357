import React, {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useEffect,
  useRef,
  useState
} from 'react';
import {
  createStyles,
  createTheme,
  makeStyles,
  MuiThemeProvider
} from '@material-ui/core';
import { FormValues } from 'components/Forms';
import { useDispatch } from 'react-redux';
import { getDomainSlice } from 'store';
import { appointmentDomain, capacityDomain, jobTypeDomain } from 'models';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  AppointmentCategory,
  Brand,
  CreateCustomerAppointmentRequestEV,
  CreateCustomerAppointmentRequestSmart,
  CustomerAppointmentType,
  EVFormChannel,
  FuelType,
  NoteType,
  PaymentType
} from 'models/Appointment';
import { DateTime } from 'luxon';
import Modal, { useModalStyles } from 'components/Modal';
import { FormApiWithRestart } from 'components/Forms/Form';
import { SecondaryButton, SubmitButton } from 'components/Forms/Buttons';
import defaultTheme, { modalFormInputOverrides } from 'styles/defaultTheme';
import { useAllJobTypesData, useCapacityData } from 'store/selectors';
import InitialForm from './InitialForm';
import JobForm from './JobForm';
import Summary from './Summary';
import { VulnerabilitiesFieldNames, jumptechUrlRegex } from './JobFormConfig';

export enum FormStep {
  INITIAL,
  JOB,
  SUMMARY
}

interface AddJobModalProps {
  open: boolean;
  closeModal: () => void;
}

export const useAddJobModalStyles = makeStyles(theme => {
  return createStyles<string, {}>({
    dialogContent: {
      paddingBottom: 0,
      borderTop: `1px solid ${theme.palette.divider}`,
      '&::-webkit-scrollbar': {
        width: 0
      }
    },
    infoContainer: {
      backgroundColor: theme.palette.aegis.semantic.background.canvas,
      padding: theme.spacing(2, 3),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      marginLeft: theme.spacing(-3),
      marginRight: theme.spacing(-3)
    },
    info: {
      '& > [class*="MuiTypography-root"]:first-child': {
        marginRight: theme.spacing(1)
      },
      '& > [class*="MuiTypography-root"]:nth-child(2)': {
        color: theme.palette.aegis.semantic.message.dark
      },
      '&:last-child': {
        marginBottom: 0,
        paddingBottom: 0,
        borderBottom: 'none'
      },
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: theme.spacing(2),
      paddingBottom: theme.spacing(0.5),
      borderBottom: `1px solid ${theme.palette.aegis.semantic.border.dark}`
    },
    infoWrap: {
      flexWrap: 'wrap' as const,
      '& > [class*="MuiTypography-root"]:first-child': {
        flexBasis: '100%',
        margin: theme.spacing(0, 0, 0.5, 0)
      }
    },
    formSectionHeading: {
      margin: theme.spacing(5, 0, 2, 0)
    }
  });
});

const getOptionalFieldValue = (value: string) => value || null;

export const parseFormValues = (
  values: FormValues,
  aegisEnableCustomerVulnerabilitiesOnCreateJobForm: boolean
):
  | CreateCustomerAppointmentRequestSmart
  | CreateCustomerAppointmentRequestEV => {
  const jobType = values.jobType as { id: string; name: string };
  const evForm = Boolean(jobType.name?.startsWith('EV'));
  const slot = {
    startTime: (values.timeSlot as string).split('-')[0].trim(),
    endTime: (values.timeSlot as string).split('-')[1].trim(),
    date: DateTime.fromISO(values.date as string).toISODate()
  };

  const address = {
    addressLineOne: values.addressLine1 as string,
    addressLineTwo: getOptionalFieldValue(values.addressLine2 as string),
    addressLineThree: getOptionalFieldValue(values.addressLine3 as string),
    city: values.city as string,
    postcode: (values.postcode as string).trim()
  };

  const customerDetails = {
    firstName: values.firstName as string,
    lastName: values.lastName as string,
    primaryPhoneNumber: values.phoneNumber as string,
    secondaryPhoneNumber: getOptionalFieldValue(
      values.secondaryNumber as string
    ),
    vulnerability: {}
  };
  const category: AppointmentCategory = AppointmentCategory.CUSTOMER;
  const notes = values.bookingNotes
    ? [
        {
          note: values.bookingNotes as string,
          type: NoteType.SCHEDULER
        }
      ]
    : [];

  const commonData = {
    category,
    slot,
    address,
    customerDetails,
    notes,
    jobType: jobType?.id
  };

  const appointmentDataSmart = {
    ...commonData,
    appointmentType: {
      type: values.appointmentType as CustomerAppointmentType,
      fuelType: values.fuelType as FuelType,
      paymentType: values.paymentType as PaymentType,
      details: {
        mprn: values.mprn as string,
        mpan: values.mpan as string,
        meterTechnicalDetails: {
          oldGasMsn: values.gasMsn as string,
          oldElecMsn: values.electricityMsn as string
        }
      }
    },
    accountNumber: values.accountNumber as string,
    brand: values.brand as Brand
  };

  const appointmentDataEV = {
    ...commonData,
    brand: 'OVO', // The back-end always expects this field, it was decided to hard-code it for EV
    appointmentType: {
      type: CustomerAppointmentType.EV,
      details: {
        channel: values.channel as EVFormChannel,
        jumptechProjectId: values.jumptechURL
          ? (values.jumptechURL as string).match(jumptechUrlRegex)?.groups
              ?.jumptechProjectId
          : '',
        zohoAccountId: values.zohoAccountId as string,
        mpan: values.mpan as string
      }
    }
  };

  const createRequestData:
    | CreateCustomerAppointmentRequestSmart
    | CreateCustomerAppointmentRequestEV = evForm
    ? appointmentDataEV
    : appointmentDataSmart;

  if (!aegisEnableCustomerVulnerabilitiesOnCreateJobForm) {
    return createRequestData;
  }

  const fieldsToExclude: string[] = [
    VulnerabilitiesFieldNames.CustomerHasVulnerabilities,
    VulnerabilitiesFieldNames.HasOtherVulnerability
  ];

  const vulnerabilitiesEntries = Object.values(VulnerabilitiesFieldNames)
    .filter(fieldName => !fieldsToExclude.includes(fieldName))
    .map(fieldName => {
      const formValue =
        fieldName === VulnerabilitiesFieldNames.OtherVulnerability
          ? values[fieldName] ?? null
          : values[fieldName] ?? false;

      return [fieldName, formValue];
    });

  const vulnerabilities = Object.fromEntries(vulnerabilitiesEntries);

  return {
    ...createRequestData,
    customerDetails: {
      ...createRequestData.customerDetails,
      vulnerability:
        values[VulnerabilitiesFieldNames.CustomerHasVulnerabilities] === 'true'
          ? vulnerabilities
          : {}
    }
  };
};

interface AddJobModalActionsProps {
  formStep: FormStep;
  setFormStep: Dispatch<SetStateAction<FormStep>>;
  formRef: MutableRefObject<FormApiWithRestart | undefined>;
  closeModal: () => void;
  resetForm: () => void;
  formValues: FormValues;
}

export function AddJobModalActions({
  formStep,
  setFormStep,
  formRef,
  closeModal,
  resetForm,
  formValues
}: AddJobModalActionsProps) {
  const dispatch = useDispatch();
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const { actions: appointmentActions } = getDomainSlice(appointmentDomain);
  const classes = useModalStyles();
  const { aegisEnableCustomerVulnerabilitiesOnCreateJobForm } = useFlags();

  const clearFormState = () => {
    setFormStep(FormStep.INITIAL);
    resetForm();
  };

  if (formStep === FormStep.INITIAL) {
    return (
      <>
        <SecondaryButton onClick={() => closeModal()} testId="addJobFormCancel">
          Cancel
        </SecondaryButton>
        <SubmitButton
          onClick={() => formRef.current?.submit()}
          testId="addJobFormNext"
        >
          Next
        </SubmitButton>
      </>
    );
  }
  if (formStep === FormStep.JOB) {
    return (
      <>
        <SecondaryButton
          onClick={() => setFormStep(FormStep.INITIAL)}
          testId="addJobFormBack"
          className={classes.previousButton}
        >
          Previous
        </SecondaryButton>
        <SecondaryButton
          onClick={() => clearFormState()}
          testId="addJobFormCancel"
        >
          Cancel
        </SecondaryButton>
        <SubmitButton
          onClick={() => formRef.current?.submit()}
          testId="addJobFormNext"
        >
          Next
        </SubmitButton>
      </>
    );
  }

  return (
    <>
      <SecondaryButton
        onClick={() => setFormStep(FormStep.JOB)}
        testId="addJobFormBack"
        className={classes.previousButton}
      >
        Previous
      </SecondaryButton>
      <SecondaryButton
        onClick={() => clearFormState()}
        testId="addJobFormCancel"
      >
        Cancel
      </SecondaryButton>
      <SubmitButton
        onClick={async () => {
          setIsSubmitDisabled(true);
          const isSuccessfulRequest = await dispatch(
            appointmentActions.custom?.createJob(
              parseFormValues(
                formValues,
                aegisEnableCustomerVulnerabilitiesOnCreateJobForm
              )
            )
          );
          setIsSubmitDisabled(false);
          if (isSuccessfulRequest) {
            closeModal();
            clearFormState();
          }
        }}
        testId="addJobFormSubmit"
        disabled={isSubmitDisabled}
      >
        Save
      </SubmitButton>
    </>
  );
}

export default function AddJobModal({ open, closeModal }: AddJobModalProps) {
  const dispatch = useDispatch();
  const { actions } = getDomainSlice(jobTypeDomain);
  const { actions: capacityActions } = getDomainSlice(capacityDomain);
  const jobTypeData = useAllJobTypesData();
  const capacityData = useCapacityData();

  const [formStep, setFormStep] = useState(FormStep.INITIAL);

  // This setup is needed in order to catch the event after a successful capacity request
  const [isCapacitySubmitting, setIsCapacitySubmitting] = useState(false);
  const [capacitySuccess, setCapacitySuccess] = useState(false);
  const [selectedDate, setSelectedDate] = useState(DateTime.now());

  const [postCode, setPostcode] = useState('');
  const [jobType, setJobType] = useState<{
    id: string | null;
    name: string | null;
  }>({
    id: null,
    name: null
  });

  const [jobFormValues, setJobFormValues] = useState({} as FormValues);
  const formRef = useRef<FormApiWithRestart>();

  useEffect(() => {
    if (capacitySuccess && !isCapacitySubmitting && capacityData.length) {
      setFormStep(FormStep.JOB);
    }
  }, [capacitySuccess, isCapacitySubmitting, capacityData]);

  useEffect(() => {
    if (actions.get.listAll) {
      dispatch(actions.get.listAll());
    }
  }, []);

  // FormValues is acting weird so that's why the type here is any.
  // FormValues type and usage to be addressed in the future.
  const loadInitialDataModal = (data: any) => {
    const currentJobType = jobTypeData?.find((item: { id: string }) => {
      const [jobTypeName] = data.job_type.values;
      return item.id === jobTypeName;
    }) ?? { id: null, name: null };

    const [postcode] = data.postcode.values;

    setPostcode(postcode);
    setJobType(currentJobType);
  };

  const capacityApiCallback = (formData: FormValues) => {
    loadInitialDataModal(formData);
    return dispatch(capacityActions.get.list(formData));
  };

  const resetForm = () => {
    setJobFormValues({});
    setPostcode('');
    setJobType({ id: null, name: null });
  };

  return (
    <Modal
      title="New Job"
      testId="addJobModal"
      open={open}
      onClose={closeModal}
      modalActions={
        <AddJobModalActions
          formStep={formStep}
          setFormStep={setFormStep}
          resetForm={resetForm}
          closeModal={closeModal}
          formRef={formRef}
          formValues={jobFormValues}
        />
      }
    >
      <MuiThemeProvider
        theme={createTheme({
          ...defaultTheme,
          overrides: { ...defaultTheme.overrides, ...modalFormInputOverrides }
        })}
      >
        {formStep === FormStep.INITIAL && (
          <InitialForm
            jobTypeData={jobTypeData || []}
            initialValues={{ postcode: postCode, jobType: jobType.id || '' }}
            capacityApiCallback={capacityApiCallback}
            setCapacitySuccess={setCapacitySuccess}
            setIsCapacitySubmitting={setIsCapacitySubmitting}
            formRef={formRef}
          />
        )}
        {formStep === FormStep.JOB && (
          <JobForm
            capacityData={capacityData}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            postCode={postCode}
            jobTypeData={jobType}
            initialValues={jobFormValues}
            saveJobData={(fieldValues: FormValues) => {
              setJobFormValues(() => ({ ...fieldValues }));
              setFormStep(FormStep.SUMMARY);
            }}
            setCapacitySuccess={setCapacitySuccess}
            setIsCapacitySubmitting={setIsCapacitySubmitting}
            formRef={formRef}
          />
        )}
        {formStep === FormStep.SUMMARY && (
          <Summary appointment={jobFormValues as any} formRef={formRef} />
        )}
      </MuiThemeProvider>
    </Modal>
  );
}
