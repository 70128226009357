import {
  Filter,
  FilterFieldType
} from '../../components/FilterSearch/FilterSearch';

export const getJobFilters: (
  aegisEnableJobsSearchByEngineer: boolean,
  enablePartialMatching: boolean
) => Filter[] = (aegisEnableJobsSearchByEngineer, enablePartialMatching) => [
  {
    id: 'accountNumber',
    queryString: 'accountNumber',
    name: 'Account number',
    operator: enablePartialMatching ? '%' : '=',
    fieldType: FilterFieldType.TEXT
  },
  {
    id: 'date',
    queryString: 'date',
    name: 'Date',
    operator: '=',
    fieldType: FilterFieldType.DATE_RANGE
  },
  {
    id: 'mpan',
    queryString: 'mpan',
    name: 'MPAN',
    operator: enablePartialMatching ? '%' : '=',
    fieldType: FilterFieldType.NUMBER
  },
  {
    id: 'mprn',
    queryString: 'mprn',
    name: 'MPRN',
    operator: enablePartialMatching ? '%' : '=',
    fieldType: FilterFieldType.NUMBER
  },
  {
    id: 'patch',
    queryString: 'patch',
    name: 'Patch',
    operator: '=',
    fieldType: FilterFieldType.AUTOCOMPLETE
  },
  {
    id: 'postcode',
    queryString: 'customer.postcode',
    name: 'Postcode',
    operator: '%',
    fieldType: FilterFieldType.TEXT
  },
  {
    id: 'bookingReference',
    queryString: 'bookingReference',
    name: 'Reference number',
    operator: enablePartialMatching ? '%' : '=',
    fieldType: FilterFieldType.TEXT
  },
  ...(aegisEnableJobsSearchByEngineer
    ? [
        {
          id: 'engineer',
          queryString: 'engineers',
          name: 'Engineer name',
          operator: 'in',
          fieldType: FilterFieldType.AUTOCOMPLETE
        } as Filter
      ]
    : []),
  {
    id: 'status',
    queryString: 'status',
    name: 'Status',
    operator: '=',
    fieldType: FilterFieldType.AUTOCOMPLETE
  } as Filter
];
