import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CreateView, renderErrorOrLoading } from 'components/Crud';
import useStyles from 'components/Crud/styles';
import { appointmentDomain } from 'models';
import { RootState } from 'store';
import { mergeErrors } from 'utils/utils';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  validationSchema,
  processFormFields,
  appointmentFormFields
} from './formConfig';
import { EngineersSelector, engineerSlice } from './store';

function AddAppointmentView() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const flags = useFlags();

  const engineerSelector = useSelector<RootState, EngineersSelector>(
    engineerSlice.selector
  );

  // TODO - temporary high page size values until FFT-612 is completed
  useEffect(() => {
    if (engineerSlice.actions.changePagination) {
      dispatch(
        engineerSlice.actions.changePagination({
          pageIndex: 0,
          pageSize: 10000
        })
      );
    }
  }, []);

  return (
    renderErrorOrLoading(
      engineerSelector.isLoading,
      'addAppointmentSpinner',
      mergeErrors([engineerSelector.error]),
      classes
    ) || (
      <CreateView
        domain={appointmentDomain}
        fields={appointmentFormFields(
          'add',
          engineerSelector.items,
          flags?.aegisEnableNewAppointmentAndAdjustmentReasons
        )}
        validationSchema={validationSchema}
        presubmitCallback={processFormFields}
      />
    )
  );
}

export default AddAppointmentView;
