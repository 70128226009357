import { JobCardStyle } from '@material-ui/core/styles/createPalette';
import {
  JobCardStyleType,
  JobCardViewMode
} from 'components/Schedule/JobCard/JobCard';
import Appointment, {
  AppointmentStatus,
  UIAppointmentStatus
} from 'models/Appointment';
import theme from 'styles/defaultTheme';

export const getUIAppointmentStatus = (status: AppointmentStatus) => {
  switch (status) {
    case AppointmentStatus.ON_ROUTE:
      return UIAppointmentStatus.EN_ROUTE;
    case AppointmentStatus.ACTIVE:
    case AppointmentStatus.ARRIVED:
    case AppointmentStatus.PAUSED:
      return UIAppointmentStatus.IN_PROGRESS;
    case AppointmentStatus.PENDING:
      return UIAppointmentStatus.BOOKED;
    case AppointmentStatus.COMPLETED:
      return UIAppointmentStatus.COMPLETED;
    case AppointmentStatus.CANCELLED:
      return UIAppointmentStatus.CANCELLED;
    case AppointmentStatus.ABORTED:
      return UIAppointmentStatus.ABORTED;
    default:
      return status;
  }
};

export const getJobCardStyle = (type: JobCardStyleType): JobCardStyle => {
  switch (type) {
    case JobCardStyleType.ABORTED:
      return theme.palette.jobStatus.aborted;
    case JobCardStyleType.ASSIGNED:
      return theme.palette.jobStatus.assigned;
    case JobCardStyleType.COMPLETED:
      return theme.palette.jobStatus.completed;
    case JobCardStyleType.CANCELLED:
      return theme.palette.jobStatus.cancelled;
    case JobCardStyleType.IN_PROGRESS:
      return theme.palette.jobStatus.inProgress;
    case JobCardStyleType.BOOKED:
      return theme.palette.jobStatus.booked;
    case JobCardStyleType.EN_ROUTE:
      return theme.palette.jobStatus.enRoute;
    case JobCardStyleType.NON_CUSTOMER:
      return theme.palette.jobStatus.nonCustomer;
    case JobCardStyleType.UNASSIGNED:
      return theme.palette.jobStatus.unassigned;
    case JobCardStyleType.AT_RISK:
      return theme.palette.jobStatus.risk;
    default:
      return theme.palette.jobStatus.assigned;
  }
};

export const getJobCardBorder = (
  jobCardStyleType: JobCardStyleType,
  viewMode: JobCardViewMode
): string => {
  const { teal, grey, blue, green, orange } = theme.palette.aegis.colour;
  const widthStyle = `${
    viewMode === JobCardViewMode.MAP ? '2px' : '1px'
  } solid`;
  switch (jobCardStyleType) {
    case JobCardStyleType.UNASSIGNED:
      return `${widthStyle} ${grey.dark}`;
    case JobCardStyleType.IN_PROGRESS:
      return `${widthStyle} ${teal.darker}`;
    case JobCardStyleType.BOOKED:
      return `${widthStyle} ${blue.base}`;
    case JobCardStyleType.COMPLETED:
      return `${widthStyle} ${green.base}`;
    case JobCardStyleType.CANCELLED:
      return `${widthStyle} ${grey.darkest}`;
    case JobCardStyleType.ABORTED:
      return `${widthStyle} ${grey.darkest}`;
    case JobCardStyleType.AT_RISK:
      return `${widthStyle} ${orange.light}`;
    case JobCardStyleType.EN_ROUTE:
      return `${widthStyle} ${blue.dark}`;
    default:
      return 'none';
  }
};

export const getUiStatusStyle = (status?: UIAppointmentStatus) => {
  switch (status) {
    case UIAppointmentStatus.ABORTED:
      return theme.palette.jobStatus.aborted;
    case UIAppointmentStatus.EN_ROUTE:
      return theme.palette.jobStatus.enRoute;
    case UIAppointmentStatus.BOOKED:
      return theme.palette.jobStatus.booked;
    case UIAppointmentStatus.CANCELLED:
      return theme.palette.jobStatus.cancelled;
    case UIAppointmentStatus.COMPLETED:
      return theme.palette.jobStatus.completed;
    case UIAppointmentStatus.IN_PROGRESS:
      return theme.palette.jobStatus.inProgress;
    default:
      return theme.palette.jobStatus.assigned;
  }
};

const statusToStyle = (status?: AppointmentStatus) => {
  switch (status) {
    case AppointmentStatus.ACTIVE:
    case AppointmentStatus.ARRIVED:
    case AppointmentStatus.PAUSED:
      return JobCardStyleType.IN_PROGRESS;
    case AppointmentStatus.ABORTED:
      return JobCardStyleType.ABORTED;
    case AppointmentStatus.CANCELLED:
      return JobCardStyleType.CANCELLED;
    case AppointmentStatus.COMPLETED:
      return JobCardStyleType.COMPLETED;
    case AppointmentStatus.ON_ROUTE:
      return JobCardStyleType.EN_ROUTE;
    case AppointmentStatus.PENDING:
      return JobCardStyleType.BOOKED;
    default:
      return JobCardStyleType.ASSIGNED;
  }
};

const terminalStatuses = [
  AppointmentStatus.COMPLETED,
  AppointmentStatus.CANCELLED,
  AppointmentStatus.ABORTED
];

export const getJobCardStyleType = (
  appointment: Appointment,
  assigned: boolean,
  fixed = false
) => {
  if (fixed) {
    return JobCardStyleType.NON_CUSTOMER;
  }

  if (terminalStatuses.includes(appointment?.status)) {
    return statusToStyle(appointment?.status);
  }

  if (appointment?.atRisk) {
    return JobCardStyleType.AT_RISK;
  }

  if (!assigned) {
    return JobCardStyleType.UNASSIGNED;
  }

  return statusToStyle(appointment?.status);
};

export const getReportedIconColor = (jobCardStyle: JobCardStyleType) => {
  switch (jobCardStyle) {
    case JobCardStyleType.IN_PROGRESS:
      return theme.palette.aegis.colour.teal.lighter;
    case JobCardStyleType.BOOKED:
      return theme.palette.aegis.colour.blue.base;
    case JobCardStyleType.COMPLETED:
      return theme.palette.aegis.colour.green.dark;
    case JobCardStyleType.ABORTED:
      return theme.palette.aegis.colour.grey.light;
    case JobCardStyleType.AT_RISK:
      return theme.palette.aegis.colour.orange.dark;
    case JobCardStyleType.EN_ROUTE:
      return theme.palette.aegis.colour.blue.darker;
    default:
      return theme.palette.aegis.colour.grey.dark;
  }
};

export const getReportedSectionBorderColor = (
  jobCardStyle: JobCardStyleType
) => {
  switch (jobCardStyle) {
    case JobCardStyleType.IN_PROGRESS:
      return theme.palette.aegis.colour.teal.base;
    case JobCardStyleType.BOOKED:
      return theme.palette.aegis.colour.blue.lighter;
    case JobCardStyleType.COMPLETED:
      return theme.palette.aegis.colour.green.light;
    case JobCardStyleType.ABORTED:
      return theme.palette.aegis.colour.grey.dark;
    case JobCardStyleType.AT_RISK:
      return theme.palette.aegis.colour.orange.light;
    case JobCardStyleType.EN_ROUTE:
      return theme.palette.aegis.colour.blue.base;
    default:
      return theme.palette.aegis.colour.grey.dark;
  }
};
