import React from 'react';

import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment/InputAdornment';
import { makeStyles, TextField } from '@material-ui/core';

interface NumberFieldProps {
  value: string;
  onChange: (event: any) => void;
  onEnterPress: (event: any) => void;
}

const useStyles = makeStyles(() => ({
  valueField: {
    width: '100%'
  },
  numberField: {
    '& input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    }
  }
}));

const NumberField = ({ value, onChange, onEnterPress }: NumberFieldProps) => {
  const classes = useStyles();
  return (
    <TextField
      type="number"
      id="filter-number-input"
      inputProps={{ 'data-testid': 'filterValue' }}
      variant="outlined"
      name="filterValue"
      value={value}
      placeholder="Search"
      classes={{ root: `${classes.valueField} ${classes.numberField}` }}
      onChange={onChange}
      onKeyPress={onEnterPress}
      // eslint-disable-next-line react/jsx-no-duplicate-props
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        )
      }}
    />
  );
};

export default NumberField;
