import React from 'react';
import {
  makeStyles,
  createStyles,
  CircularProgress,
  Theme
} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      margin: '0 auto'
    },
    container: {
      width: 20,
      height: 20,
      background: theme.palette.common.white,
      boxShadow:
        '0px 0px 2px rgba(0, 0, 0, 0.14), 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2)',
      borderRadius: 17,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }
  })
);

const Unpublished = () => {
  const classes = useStyles();
  return (
    <svg
      width={12}
      height={12}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classes.icon}
      data-testid="scheduleUnpublished"
    >
      <path
        // eslint-disable-next-line max-len
        d="M.657 9.115v2.225h2.225l6.561-6.561-2.225-2.225L.657 9.115zm10.506-6.057a.59.59 0 000-.836L9.775.833a.59.59 0 00-.836 0L7.853 1.92l2.225 2.225 1.085-1.086z"
        fill="#DE1D8D"
      />
    </svg>
  );
};

const Publishing = () => {
  const classes = useStyles();
  return (
    <div className={classes.icon} data-testid="schedulePublishing">
      <CircularProgress size={12} color="inherit" />
    </div>
  );
};

const Published = () => {
  const classes = useStyles();
  return (
    <svg
      width={14}
      height={10}
      viewBox="0 0 14 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classes.icon}
      data-testid="schedulePublished"
    >
      <path
        d="M4.697 7.784L1.704 4.79.688 5.806l4.01 4.01L13.311 1.2 12.296.185 4.697 7.784z"
        fill="#555C6B"
      />
    </svg>
  );
};

const Received = () => {
  const classes = useStyles();
  return (
    <svg
      width={16}
      height={10}
      viewBox="0 0 16 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classes.icon}
      data-testid="scheduleReceived"
    >
      <path
        // eslint-disable-next-line max-len
        d="M11.946 1.497l-.933-.933L6.82 4.758l.933.933 4.194-4.194zM14.75.564l-7 7-2.764-2.76-.933.933 3.698 3.698 7.938-7.938-.94-.933zM.31 5.737l3.698 3.698.933-.932-3.692-3.698-.939.932z"
        fill="#555C6B"
      />
    </svg>
  );
};

const Seen = () => {
  const classes = useStyles();
  return (
    <svg
      width={16}
      height={10}
      viewBox="0 0 16 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classes.icon}
      data-testid="scheduleSeen"
    >
      <path
        // eslint-disable-next-line max-len
        d="M11.946 1.497l-.933-.933L6.82 4.758l.933.933 4.194-4.194zM14.75.564l-7 7-2.764-2.76-.933.933 3.698 3.698 7.938-7.938-.94-.933zM.31 5.737l3.698 3.698.933-.932-3.692-3.698-.939.932z"
        fill="#028FC8"
      />
    </svg>
  );
};

export enum Status {
  UNPUBLISHED = 'UNPUBLISHED',
  PUBLISHING = 'PUBLISHING',
  PUBLISHED = 'PUBLISHED',
  SENT = 'SENT',
  RECEIVED = 'RECEIVED',
  SEEN = 'SEEN',
  FAILED_TO_PUBLISH = 'FAILED_TO_PUBLISH'
}

interface PublishStatusIconProps {
  status: Status;
}

function PublishStatusIcon({ status }: PublishStatusIconProps) {
  const classes = useStyles();
  let component;
  switch (status) {
    case Status.UNPUBLISHED:
      component = <Unpublished />;
      break;
    case Status.PUBLISHING:
      component = <Publishing />;
      break;
    case Status.PUBLISHED:
      component = <Published />;
      break;
    case Status.FAILED_TO_PUBLISH:
      component = <Unpublished />;
      break;
    case Status.SENT:
      component = <Published />;
      break;
    case Status.RECEIVED:
      component = <Received />;
      break;
    case Status.SEEN:
      component = <Seen />;
      break;
    default:
      component = <></>;
  }

  return <div className={classes.container}>{component}</div>;
}

export default PublishStatusIcon;
