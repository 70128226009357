import {
  ScheduleAssignmentType,
  ScheduleAssignmentEvent,
  AssignJobToEngineerPayload
} from 'models/Schedule';
import { OnAssignmentAddCallback } from 'views/Schedule/ScheduleView';
import { DateTime } from 'luxon';
import { SHORT_TIME_FORMAT } from 'constants/dates';

export interface JobTimeBoundaries {
  index: number;
  x: number;
  type?: ScheduleAssignmentType;
}

// Return time diff in minutes
export const getTimeDiff = (startTime: string, endTime: string): number => {
  let startTimeObj = DateTime.fromFormat(startTime, 'HH:mm:ss');
  if (startTimeObj.invalidReason === 'unparsable') {
    startTimeObj = DateTime.fromFormat(startTime, SHORT_TIME_FORMAT);
  }
  let endTimeObj = DateTime.fromFormat(endTime, 'HH:mm:ss');
  if (endTimeObj.invalidReason === 'unparsable') {
    endTimeObj = DateTime.fromFormat(endTime, SHORT_TIME_FORMAT);
  }

  return endTimeObj.diff(startTimeObj, 'minutes').minutes;
};

export const timeToPixels = (time: number, pixels = 2) =>
  Math.ceil(time / pixels);

// Return card relative time in minutes since the start of the day
export const getCardRelativeTime = (
  startTime: string,
  arrivalTime: string,
  driveTimeTo: number
): number => getTimeDiff(startTime, arrivalTime) - driveTimeTo;

// Return card position in pixels since start of the day
export const getCardPosition = (
  startTime: string,
  arrivalTime: string,
  driveTimeTo: number
): number =>
  timeToPixels(getCardRelativeTime(startTime, arrivalTime, driveTimeTo));

// Return the first part of postcode
export const getPostcode = (postcode: string | undefined): string =>
  postcode ? postcode.split(' ')[0] : '';

// Return letter from the alphabet corresponding to the card position
export const getLetter = (position: number): string =>
  String.fromCharCode(position + 65);

// Return the duration of the job in minutes
export const getDuration = (
  arrivalTime: string,
  departureTime: string
): number => getTimeDiff(arrivalTime, departureTime);

// Returns the array index with closest value to the provided number
export const getClosestPosition = (array: JobTimeBoundaries[], find: number) =>
  array.reduce((prev, curr) =>
    Math.abs(prev.x - find) < Math.abs(curr.x - find) ? prev : curr
  );

// Returns an array of time slots
export const getTimeLabels = (
  startTime: string,
  endTime: string,
  interval = 120
) => {
  let startTimeDateTime = DateTime.fromFormat(startTime, SHORT_TIME_FORMAT);
  const endTimeDateTime = DateTime.fromFormat(endTime, SHORT_TIME_FORMAT);

  const labels = [];

  while (startTimeDateTime <= endTimeDateTime) {
    // Convert it back to string and push it to the slots array
    labels.push(startTimeDateTime.toFormat(SHORT_TIME_FORMAT));
    // Add the interval
    startTimeDateTime = startTimeDateTime.plus({ minutes: interval });
  }

  return labels;
};

export const getMinutesWaiting = (
  arrivalTime: string,
  // For some reason the data we can get back in UAT contains an `earliestStartTime` of `null`.
  // I *assume* that this is just bad data, since the `earliestStartTime` field isn't nullable in the schema
  earliestStartTime: string | null
) => {
  if (arrivalTime && earliestStartTime) {
    if (
      DateTime.fromFormat(arrivalTime, SHORT_TIME_FORMAT) <
      DateTime.fromFormat(earliestStartTime, SHORT_TIME_FORMAT)
    ) {
      return getTimeDiff(arrivalTime, earliestStartTime);
    }
  }

  return 0;
};

export const tunePosition = (
  positions: JobTimeBoundaries[],
  slotStartX: number,
  slotEndX: number,
  closestJobCard: JobTimeBoundaries
): JobTimeBoundaries => {
  const closestPositionX = closestJobCard.x;

  let tunedPosition: JobTimeBoundaries | undefined = {
    index: closestJobCard.index + 1,
    x: closestJobCard.x
  };

  if (closestJobCard?.type === 'START') {
    tunedPosition = { index: 1, x: slotStartX };
  } else {
    // TODO: Revisit this once https://ovotech.atlassian.net/browse/FTSC-369 is done

    // when closest position is after slot end, find the closest carret position inside the slot
    if (closestPositionX > slotEndX) {
      tunedPosition = positions
        .reverse()
        .find(position => position.x < slotEndX && position.x >= slotStartX);
    }
    // when closest position is before slot start, find the closest carret position inside the slot
    if (closestPositionX < slotStartX) {
      tunedPosition = positions.find(
        position => position.x > slotStartX && position.x < slotEndX
      ) || { index: closestJobCard.index + 1, x: slotStartX };
    }

    if (!tunedPosition || tunedPosition?.x === slotEndX) {
      tunedPosition = {
        index: closestJobCard.index,
        x: slotStartX
      };
    }
  }

  return tunedPosition;
};

export const updateEngineerAssignmentsResolveTime = 500;
let batchQueue: (ScheduleAssignmentEvent | undefined)[] = [];

export const updateEngineerAssignments = async (
  event: ScheduleAssignmentEvent,
  onAssignmentAdd: OnAssignmentAddCallback
) => {
  let batchTimeout;

  // HERE BE DRAGONS, PLEASE DON'T HATE ME 😅
  // This code combines the add and remove events, The only other way I could think of
  // required observables, suggestions are welcome
  batchQueue.push(event);

  // When the `batchPromise` resolves, both events are captured, the assignment request
  // has been triggered, then the timeout is cleared and the queue is reset
  const batchPromise = new Promise<void>(resolve => {
    batchTimeout = setTimeout(() => {
      const defaultRequestBody: AssignJobToEngineerPayload = {
        appointmentId: event.id,
        engineersToAdd: [],
        engineersToRemove: []
      };

      if (batchQueue.length === 0) {
        resolve();
        return;
      }

      const engineerAssignmentsUpdateBody = batchQueue.reduce(
        (requestBody, assignmentEvent) => {
          if (assignmentEvent?.action === 'add') {
            return {
              ...requestBody,
              engineersToAdd: [assignmentEvent.engineerId]
            };
          }

          if (assignmentEvent?.action === 'remove') {
            return {
              ...requestBody,
              engineersToRemove: [assignmentEvent.engineerId]
            };
          }

          return requestBody;
        },
        defaultRequestBody
      );

      onAssignmentAdd(engineerAssignmentsUpdateBody);

      resolve();
    }, updateEngineerAssignmentsResolveTime);
  });

  await batchPromise;

  // Clean up
  clearTimeout(batchTimeout);
  batchQueue = [];
};
