import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import cardImage from 'assets/images/ProfileFiller.jpg';

function AvatarComponent({
  alt,
  size,
  src
}: {
  alt: string;
  size: number;
  src?: string;
}) {
  const useStyles = makeStyles(theme => ({
    root: {
      margin: theme.spacing(1)
    },
    avatar: {
      width: theme.spacing(size),
      height: theme.spacing(size)
    }
  }));
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Avatar alt={alt} src={src || cardImage} className={classes.avatar} />
    </div>
  );
}

export default AvatarComponent;
