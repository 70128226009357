import { captureMessage, Severity } from '@sentry/react';
import Keycloak, { KeycloakInitOptions, KeycloakConfig } from 'keycloak-js';

const isDevelopment = process.env.NODE_ENV === 'development';

const config: KeycloakConfig = {
  url: process.env.REACT_APP_KEYCLOAK_URL as string,
  realm: process.env.REACT_APP_KEYCLOAK_REALM as string,
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID as string
};

const initConfig: KeycloakInitOptions = {
  adapter: 'default',
  onLoad: 'check-sso',
  enableLogging: isDevelopment
};

const keycloak = Keycloak(config);

const isAuthenticated = (keycloakInstance: typeof keycloak) =>
  keycloakInstance.authenticated || false;

enum Role {
  CapacityAllowIncludeToday = 'CAPACITY_ALLOW_INCLUDE_TODAY'
}

const hasRole = (keycloakInstance: typeof keycloak, role: Role): boolean =>
  !!keycloakInstance.realmAccess?.roles.includes(role);

// eslint-disable-next-line @typescript-eslint/no-empty-function
keycloak.onReady = () => {};
keycloak.onAuthRefreshError = () => {
  captureMessage('Error occurred when refreshing token', Severity.Error);
  keycloak.logout({
    redirectUri: window.location.origin
  });
};

export default keycloak;
export { Role, isAuthenticated, hasRole, initConfig };
