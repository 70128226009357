/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppThunk } from 'store';
import {
  initialStateSlice,
  StateSlice,
  startLoading,
  getPaginationReducers
} from 'store/slices';
import { scheduleDomain } from 'models';
import {
  createUrl,
  sortScheduleEngineers,
  handleThunkError
} from 'store/utils';
import { Schedule, FindSchedulesRequest } from 'models/Schedule';
import http from 'api/http';
import { RootState } from 'store/store';

const schedules = createSlice({
  name: scheduleDomain.plural,
  initialState: initialStateSlice as StateSlice<Schedule>,
  reducers: {
    ...startLoading<Schedule>(),
    ...getPaginationReducers<Schedule>(),
    // TODO: Abstract these out to be generic functions
    getScheduleSuccess(
      state,
      { payload }: PayloadAction<StateSlice<Schedule>>
    ) {
      const {
        items: [scheduleData],
        page
      } = payload;

      const sortedEngineerSchedules = sortScheduleEngineers(
        scheduleData.schedules
      );

      state.items = [
        {
          ...scheduleData,
          schedules: sortedEngineerSchedules
        }
      ];
      state.page = page;
      state.isLoading = false;
      state.error = null;
    },
    requestFail(state, { payload }) {
      state.isLoading = false;
      state.error = {
        status: { message: payload.message || payload },
        code: payload.code
      };
    }
  }
});

export const {
  requestStart,
  requestFail,
  getScheduleSuccess
} = schedules.actions;

export default schedules.reducer;

export const scheduleItemsSelector = (state: RootState) =>
  (state[scheduleDomain.plural as keyof RootState] as StateSlice<Schedule>)
    ?.items;

export const scheduleItemSelector = (state: RootState) =>
  (state[scheduleDomain.plural as keyof RootState] as StateSlice<Schedule>)
    ?.items[0];

export const scheduleSelector = (state: RootState) =>
  state[scheduleDomain.plural as keyof RootState] as StateSlice<Schedule>;

export function fetchSchedule(
  date: string,
  areas: string[],
  hideSpinner = false
): AppThunk {
  return async (dispatch, getState) => {
    const state = getState();

    try {
      // When we edit engineer assignment and fetch the whole data again
      // we do not need the page spinner
      if (!hideSpinner) {
        dispatch(requestStart());
      }

      const findSchedulesRequestBody: FindSchedulesRequest = {
        date,
        geographicAreaIds: areas
      };

      const { data: scheduleData } = await http.post<Schedule>(
        `${createUrl(scheduleDomain)}/find/aggregate`,
        findSchedulesRequestBody
      );

      scheduleData.date = date;
      scheduleData.areas = areas;

      // Need until https://ovotech.atlassian.net/browse/FTSC-735 is done
      scheduleData.unassignedAppointments = scheduleData.unassignedAppointments.map(
        unassignedAppointment => {
          return {
            ...unassignedAppointment,
            appointment:
              (unassignedAppointment as any).details ??
              unassignedAppointment.appointment
          };
        }
      );

      const scheduleSliceState = {
        ...state.schedules,
        items: [scheduleData]
      };

      dispatch(getScheduleSuccess(scheduleSliceState));

      return scheduleData;
    } catch (error) {
      handleThunkError({
        error,
        requestFail,
        domainType: scheduleDomain.type,
        displayError: error.response?.status !== 404,
        dispatch
      });

      return null;
    }
  };
}

export function publishEngineerSchedule(
  engineers: string[],
  date: string
): AppThunk {
  return async dispatch => {
    try {
      await http.post(createUrl(scheduleDomain, null, `publish`), {
        date,
        engineers
      });
    } catch (error) {
      handleThunkError({
        error,
        requestFail,
        dispatch,
        domainType: scheduleDomain.type
      });

      throw error;
    }
  };
}
