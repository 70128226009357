import React from 'react';

import { Link as RouterLink } from 'react-router-dom';
import { Breadcrumbs as MUIBreadcrumbs } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import Typography from 'components/Typography';
import { BreadcrumbData } from './breadcrumbData';

const useStyles = makeStyles(theme => ({
  breadcrumbs: {
    marginLeft: theme.spacing(2),
    '& ol': {
      flexWrap: 'nowrap'
    },
    '& p': {
      whiteSpace: 'nowrap'
    }
  },
  link: {
    color: theme.palette.aegis.semantic.message.dark
  },
  separator: {
    margin: theme.spacing(0, 0.5)
  }
}));

interface BreadcrumbsProps {
  crumbs: BreadcrumbData[];
}

const Breadcrumbs = ({ crumbs }: BreadcrumbsProps) => {
  const classes = useStyles();

  return (
    <MUIBreadcrumbs
      separator=">"
      data-testid="breadcrumb"
      aria-label="breadcrumb"
      classes={{ separator: classes.separator }}
      className={classes.breadcrumbs}
    >
      {crumbs.map((breadcrumb, index) => (
        <Typography key={breadcrumb.label} data-testid={`breadcrumb-${index}`}>
          {breadcrumb.to === null ? (
            breadcrumb.label
          ) : (
            <RouterLink
              color="textPrimary"
              className={classes.link}
              to={`/dashboard${breadcrumb.to}`}
              data-testid={`breadcrumbLink-${index}`}
            >
              {breadcrumb.label}
            </RouterLink>
          )}
        </Typography>
      ))}
    </MUIBreadcrumbs>
  );
};

export default Breadcrumbs;
