import http from 'api/http';
import { FormValues } from 'components/Forms';
import { patchDomain } from 'models';
import Patch from 'models/Patches';
import { createUrl } from 'store/utils';

export async function addPatch(data: FormValues) {
  const url = createUrl(patchDomain);
  const result = await http.post(url, data);

  const patchId = result.headers['resource-id'];

  return patchId;
}

export async function editPatch(id: string, data: Omit<Patch, 'id'>) {
  const url = createUrl(patchDomain, undefined, id);
  await http.put(url, data);
}
