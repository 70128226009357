import React from 'react';
import { Chip, Theme, makeStyles } from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import RoomIcon from '@material-ui/icons/Room';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import { getUiStatusStyle } from 'utils/jobStatus';
import theme from 'styles/defaultTheme';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface StyleProps {
  aegisEnableUpdatedJobCardDetails: boolean;
  isClickable: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>({
  chip: props => ({
    marginRight: theme.spacing(1),
    width: props.aegisEnableUpdatedJobCardDetails ? 'fit-content' : undefined,
    border: 'none',
    cursor: props.isClickable ? 'pointer' : 'inherit'
  })
});

export enum JobChipType {
  Timeslot = 'timeSlot',
  Status = 'status',
  Postcode = 'postcode',
  Assigned = 'assigned',
  AtRisk = 'atRisk',
  Type = 'type'
}

interface JobDetailsChipProps {
  type: JobChipType;
  value: any;
  onClick?: () => void;
}

// return the proper avatar icon and label for the chip
const getChipProps = ({ type, value }: JobDetailsChipProps) => {
  if (type === JobChipType.Timeslot) {
    return {
      label: value,
      icon: <AccessTimeIcon />
    };
  }
  if (type === JobChipType.Status) {
    return {
      label: value.toString()
    };
  }
  if (type === JobChipType.Type) {
    return {
      label: value,
      icon: <WorkOutlineIcon data-testid="workIcon" />
    };
  }
  if (type === JobChipType.AtRisk) {
    return {
      label: 'At Risk'
    };
  }
  if (type === JobChipType.Postcode) {
    return {
      label: value,
      icon: <RoomIcon />
    };
  }
  if (type === JobChipType.Assigned) {
    if (value) {
      return {
        label: `${value.firstName} ${value.lastName}`
      };
    }
    return {
      label: 'Unassigned'
    };
  }
  return { label: value };
};

function getChipStyle({ type, value }: JobDetailsChipProps) {
  if (type === JobChipType.Status) {
    return getUiStatusStyle(value);
  }
  if (type === JobChipType.AtRisk) {
    return theme.palette.jobStatus.risk;
  }
  return undefined;
}

export default function JobDetailsChip({
  type,
  value,
  onClick,
  ...props
}: JobDetailsChipProps) {
  const { aegisEnableUpdatedJobCardDetails } = useFlags();

  const classes = useStyles({
    aegisEnableUpdatedJobCardDetails,
    isClickable: !!onClick
  });

  return (
    <Chip
      onClick={onClick}
      className={classes.chip}
      style={getChipStyle({ type, value })}
      {...getChipProps({ type, value })}
      {...props}
    />
  );
}
