import { engineerDomain, jobDetailsDomain, jobDomain } from 'models';
import { Domain, DomainItem } from 'models/Domain';
import { matchPath } from 'react-router-dom';
import { camelToTitleCase } from 'utils/utils';

export type BreadcrumbData = {
  label: string;
  to: string | null;
};

export const getDomainNameFromPath = (path: string): string => {
  return path.split('/')[2];
};

export const getIdFromPath = (path: string): string => {
  return path.split('/')[3];
};

const getDomainItemLabel = (domain: Domain): string => {
  if ([jobDetailsDomain.type, jobDomain.type].includes(domain.type)) {
    return 'Job Sheet';
  }
  return `View ${camelToTitleCase(domain.type)}`;
};

const getStatefulDomainItemLabel = (domain: Domain, item: any): string => {
  if (domain.type === engineerDomain.type && item) {
    return `${item.firstName} ${item.lastName}`;
  }
  return item?.name || getDomainItemLabel(domain);
};

const defaultDomainBreadcrumbPaths = (domain: Domain, itemLabel?: string) => {
  return [
    {
      path: `/dashboard/${domain.urlPath}`,
      crumbs: [{ label: domain.label, to: null }]
    },
    {
      path: `/dashboard/${domain.urlPath}/:id`,
      crumbs: [
        { label: domain.label, to: `/${domain.urlPath}` },
        {
          label: itemLabel || getDomainItemLabel(domain),
          to: null
        }
      ]
    }
  ];
};

const engineerBreadcrumbPaths = (
  domain: Domain,
  id: string,
  engineerName: string
) => {
  return [
    {
      path: `/dashboard/${domain.urlPath}/:id/appointments`,
      crumbs: [
        { label: domain.label, to: `/${domain.urlPath}` },
        {
          label: engineerName,
          to: `/${domain.urlPath}/${id}`
        },
        { label: 'Appointments', to: null }
      ]
    }
  ];
};

const statefulDomainBreadcrumbPaths = (
  domain: Domain,
  path: string,
  items: DomainItem[]
) => {
  const id = getIdFromPath(path);
  const item = items.find(e => e.id === id);
  const itemLabel = getStatefulDomainItemLabel(domain, item);

  return [
    {
      path: `/dashboard/${domain.urlPath}/create`,
      crumbs: [
        { label: domain.label, to: `/${domain.urlPath}` },
        { label: 'New', to: null }
      ]
    },
    {
      path: `/dashboard/${domain.urlPath}/:id/edit`,
      crumbs: [
        { label: domain.label, to: `/${domain.urlPath}` },
        {
          label: itemLabel,
          to: null
        },
        { label: 'Edit', to: null }
      ]
    },
    ...(domain.type === engineerDomain.type
      ? engineerBreadcrumbPaths(domain, id, itemLabel)
      : []),
    // these must come after the create path so that a view path isn't mistaken for a create!
    ...defaultDomainBreadcrumbPaths(domain, itemLabel)
  ];
};

export const getBreadcrumbs = (
  domain: Domain,
  pathname: string,
  items?: DomainItem[]
): BreadcrumbData[] => {
  const breadcrumbPaths =
    domain.stateful && items
      ? statefulDomainBreadcrumbPaths(domain, pathname, items)
      : defaultDomainBreadcrumbPaths(domain);

  const matchingPath = breadcrumbPaths.find(({ path }) =>
    matchPath(pathname, { path, exact: true })
  );

  return matchingPath?.crumbs || [];
};
