import React from 'react';
import * as Yup from 'yup';
import { Chip } from '@material-ui/core';
import { DateTime } from 'luxon';

import {
  getDefaultInputFieldsProps,
  FormFieldConfig,
  FormValues,
  FieldInputValue,
  mapEngineersToAutocompleteValues,
  MultiselectOption
} from 'components/Forms';
import Engineer from 'models/Engineers';
import { AppointmentReason, NewAppointmentReason } from 'models/Appointment';

const EngineerOption = ({ value, label, ...props }: MultiselectOption) => (
  <Chip key={value} label={label} {...props} />
);

export function appointmentFormFields(
  type: 'add' | 'edit',
  engineers: Engineer[] | null,
  aegisEnableNewAppointmentAndAdjustmentReasons: boolean
) {
  const fields: FormFieldConfig[] = [
    {
      required: true,
      name: 'reason',
      qaId: `${type}AppointmentReason`,
      inputType: 'select',
      options: Object.values(
        aegisEnableNewAppointmentAndAdjustmentReasons
          ? NewAppointmentReason
          : AppointmentReason
      ).map(reason => ({
        value: reason,
        label: reason
      }))
    },
    {
      required: true,
      name: 'engineer',
      qaId: `${type}Engineer`,
      inputType: 'multiselect',
      options: engineers && mapEngineersToAutocompleteValues(engineers),
      optionRenderer: EngineerOption,
      preprocess: value =>
        value &&
        mapEngineersToAutocompleteValues(
          value.map(
            (id: string) => engineers && engineers.find(item => item.id === id)
          )
        )
    },
    {
      required: true,
      name: 'date',
      qaId: `${type}AppointmentDate`,
      inputType: 'date'
    },
    {
      required: true,
      name: 'startTime',
      qaId: `${type}AppointmentStartTime`,
      inputType: 'time'
    },
    {
      required: true,
      name: 'endTime',
      qaId: `${type}AppointmentEndTime`,
      inputType: 'time'
    },
    {
      required: false,
      name: 'postcode',
      qaId: `${type}AppointmentPostcode`,
      inputType: 'textfield'
    },
    {
      required: true,
      name: 'notes',
      qaId: `${type}AppointmentNote`,
      inputType: 'textarea'
    }
  ];

  return getDefaultInputFieldsProps(fields);
}

const processEngineers = (engineers: FieldInputValue[]): string[] =>
  engineers.map(({ value }) => value);

export const processFormFields = ({
  engineer,
  startTime,
  endTime,
  date,
  postcode,
  reason,
  notes
}: FormValues) => {
  const fields: any = {
    category: 'MEETING',
    engineers: processEngineers(engineer as FieldInputValue[]),
    slot: {
      startTime: DateTime.fromISO(startTime as string).toFormat('HH:mm'),
      endTime: DateTime.fromISO(endTime as string).toFormat('HH:mm'),
      date: DateTime.fromISO(date as string).toISODate()
    },
    reason,
    address: postcode
      ? {
          addressLineOne: 'Best place ever',
          city: 'Best city ever',
          postcode
        }
      : undefined,
    notes: [
      {
        note: notes,
        type: 'SCHEDULER'
      }
    ]
  };
  return fields;
};

// Needed because the time field returns the date as well, which will reset to current day if set to 12:00
export function sanitizeTimeForComparison(time: string) {
  return DateTime.fromISO(time);
}

export const validationSchema = Yup.object().shape({
  engineer: Yup.array()
    .of(
      Yup.object().shape({
        value: Yup.string()
      })
    )
    .min(1, 'Required')
    .required('Required'),
  date: Yup.string()
    .required('Required')
    .nullable(),
  startTime: Yup.string()
    .required('Required')
    .nullable(),
  endTime: Yup.string()
    .required('Required')
    .nullable()
    .when('startTime', (startTime: string, schema: any) =>
      schema.test({
        test: (endTime: any) => {
          if (startTime && endTime) {
            return (
              sanitizeTimeForComparison(startTime).diff(
                sanitizeTimeForComparison(endTime)
              ).milliseconds < 0
            );
          }
          return true;
        },
        message: 'End Time must be after Start Time'
      })
    ),
  postcode: Yup.string(),
  reason: Yup.string()
    .required('Required')
    .nullable(),
  notes: Yup.string().required('Required')
});
