import React, { memo, useState, ChangeEvent, useEffect } from 'react';
import {
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Theme
} from '@material-ui/core';
import { QueryFilterOperators } from 'store/utils';
import SearchFilterChip from 'components/Chip/SearchFilterChip';
import {
  AutoCompleteField,
  NumberField,
  TextField,
  DateRangeField
} from './FieldInputs';
import { AutoCompleteFieldType } from './FieldInputs/AutoCompleteField';

export interface AutocompleteFilterOptions {
  value: string;
  label: string;
}

export enum FilterFieldType {
  AUTOCOMPLETE,
  DATE_RANGE,
  NUMBER,
  TEXT
}

export interface Filter extends SearchFilterOption {
  queryString: string;
  operator: QueryFilterOperators;
  options?: AutocompleteFilterOptions[];
}

export interface AppliedFilter {
  filter: Filter;
  value: string;
  label?: string;
}

export interface SearchFilterOption {
  id: string;
  name: string;
  fieldType: FilterFieldType;
}

export interface FilterValue {
  id: string;
  value: string;
  label?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  select: {
    flexShrink: 1,
    width: 200,
    margin: 0
  },
  datepicker: {
    margin: 0,
    width: '100%'
  },
  fields: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(2),
    margin: theme.spacing(1.5, 2, 1.5, 2),
    alignItems: 'stretch'
  },
  filterChips: {
    marginTop: theme.spacing(0.5),
    padding: theme.spacing(0, 2)
  },
  valueInput: {
    flexGrow: 2
  },
  menuPaper: {
    maxHeight: '75%'
  },
  margin: {
    display: 'flex',
    flexWrap: 'wrap'
  }
}));

interface FilterSearchProps {
  onFilterValueInput: (filterId: string, value: string, label?: string) => void;
  onFilterRemove: (filter: Filter) => void;
  onFilterChange?: (filter: string) => void;
  filters: SearchFilterOption[];
  filterValues: AppliedFilter[];
  options?: AutocompleteFilterOptions[];
  optionsCallback?: (selectedFilter: string) => AutocompleteFilterOptions[];
}

const FilterSearchForm = (props: FilterSearchProps) => {
  const {
    filters,
    onFilterValueInput,
    options,
    optionsCallback,
    onFilterChange
  } = props;
  const classes = useStyles();
  const [selectedFilter, setSelectedFilter] = useState(filters[0].id);
  const [filterValue, setFilterValue] = useState('');
  const [filterLabel, setFilterLabel] = useState<undefined | string>(undefined);
  const autocompleteOptions = optionsCallback
    ? optionsCallback(selectedFilter)
    : options;

  useEffect(() => {
    if (onFilterChange) {
      onFilterChange(selectedFilter);
    }
  }, [selectedFilter]);

  useEffect(() => {
    if (props.filterValues.length > 0) {
      setSelectedFilter(
        props.filterValues[props.filterValues.length - 1].filter.id
      );
    }
  }, []);

  const onSelectChange = (
    event: ChangeEvent<{
      value: unknown;
    }>
  ) => {
    setSelectedFilter(event.target.value as string);
    setFilterValue('');
  };

  const FilterSelectField = () => {
    return (
      <FormControl className={classes.select} variant="outlined">
        <InputLabel
          htmlFor="filter-select-input"
          data-testid="filter-select-input"
        >
          Search by
        </InputLabel>
        <Select
          id="filter-select-input"
          variant="outlined"
          label="Search by"
          placeholder="Search"
          name="filterId"
          value={selectedFilter}
          className={classes.select}
          MenuProps={{
            classes: { paper: classes.menuPaper },
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center'
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'center'
            },
            getContentAnchorEl: null
          }}
          onChange={onSelectChange}
        >
          {filters.map(filter => (
            <MenuItem
              data-testid={`filterSelectOption-${filter.id}`}
              key={filter.id}
              value={filter.id}
            >
              {filter.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  const handleFilterChange = (event: any) => {
    setFilterValue(event.target.value);
  };

  const handleEnterPressed = (event: any) => {
    if (event.key === 'Enter') {
      onFilterValueInput(selectedFilter, filterValue, filterLabel);
    }
  };

  const FilterValueField = () => {
    const currentFilterType = filters.find(f => f.id === selectedFilter)
      ?.fieldType;
    if (currentFilterType === FilterFieldType.DATE_RANGE) {
      return (
        <DateRangeField
          filterValue={filterValue}
          onSubmit={value => {
            setFilterValue(value);
            onFilterValueInput(selectedFilter, value, value);
          }}
        />
      );
    }
    if (currentFilterType === FilterFieldType.NUMBER) {
      return (
        <NumberField
          value={filterValue}
          onChange={handleFilterChange}
          onEnterPress={handleEnterPressed}
        />
      );
    }
    if (currentFilterType === FilterFieldType.AUTOCOMPLETE) {
      return (
        <AutoCompleteField
          type={AutoCompleteFieldType.SEARCH}
          options={autocompleteOptions}
          onEnterPress={handleEnterPressed}
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          onChange={(event, newValue) => {
            if (newValue) {
              setFilterValue(selectedFilter);
              setFilterLabel(newValue.label);
              onFilterValueInput(
                selectedFilter,
                newValue.value,
                newValue.label
              );
            }
          }}
        />
      );
    }
    return (
      <TextField
        value={filterValue}
        onChange={handleFilterChange}
        onEnterPress={handleEnterPressed}
      />
    );
  };

  return (
    <>
      <div className={classes.fields} data-testid="filterSearch">
        <FilterSelectField />
        <div className={classes.valueInput}>{FilterValueField()}</div>
      </div>
      {props.filterValues.length > 0 && (
        <div className={classes.filterChips}>
          {props.filterValues.map(
            ({ filter: chipFilter, label: chipLabel, value: chipValue }) => {
              const optionValue = chipLabel ?? chipValue;

              return (
                // eslint-disable-next-line react/jsx-key
                <SearchFilterChip
                  key={`${chipFilter.id}-${chipValue}`}
                  value={optionValue}
                  filter={chipFilter}
                  onDelete={props.onFilterRemove}
                />
              );
            }
          )}
        </div>
      )}
    </>
  );
};

export default memo(FilterSearchForm);
