import React, { useEffect } from 'react';
import { useRouteMatch, useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import ErrorDisplay from 'components/ErrorDisplay';
import CircularProgress from '@material-ui/core/CircularProgress';
import { RootState, getDomainSlice } from 'store';
import { DomainItem } from 'models/Domain';
import Snackbars from 'components/Alerts/';
import { MuiThemeProvider, createTheme } from '@material-ui/core';
import defaultTheme, { modalFormInputOverrides } from 'styles/defaultTheme';
import { CrudViewReadProps } from '../utils';
import useStyles from '../styles';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function WrappedRead({ children }: { children: React.ReactNode }) {
  return (
    <MuiThemeProvider
      theme={createTheme({
        ...defaultTheme,
        overrides: {
          ...defaultTheme.overrides,
          ...modalFormInputOverrides
        }
      })}
    >
      {children}
    </MuiThemeProvider>
  );
}

const ReadView = ({ domain, children }: CrudViewReadProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { url } = useRouteMatch();

  const { actions, selector } = getDomainSlice(domain);

  const { id: itemId } = useParams<{ id: string }>();

  const onEdit = (domainItem: DomainItem) => () => {
    history.push(`${url}/edit`, domainItem);
  };

  const onDelete = (domainItem: DomainItem) => () => {
    dispatch(actions.delete(domainItem));
  };
  const { items: listItems, isLoading, error } = useSelector<
    RootState,
    ReturnType<typeof selector>
  >(selector);

  // TODO: Handle scenario when `data` is undefined.
  // At the moment this will just show a 'Request failed with status code 404' error,
  // when `data` is undefined, which isn't user friendly. This also needs tests to
  // cover this scenario.
  const data = listItems.find((item: DomainItem) => item.id === itemId);
  useEffect(() => {
    // For now while none of the other domains have Read view - I have put this here
    // to keep type script happy
    if (actions.get.read && itemId) {
      dispatch(actions.get.read(itemId));
    }
  }, []);

  if (children == null) {
    return null;
  }

  return (
    <WrappedRead>
      <div className={classes.gridParentContainer}>
        <Grid
          container
          className={classes.readContainer}
          justifyContent="center"
          spacing={4}
        >
          {!error ? null : <ErrorDisplay status={error.status} />}
          {!isLoading && !data ? (
            <div data-testid="noDataFromApiMessage">
              Did not get any data back from service.
            </div>
          ) : null}
          <div className={classes.loadingWrapper}>
            {isLoading && (
              <CircularProgress
                size={48}
                className={classes.spinnerWrapper}
                data-testid="readDomainSpinner"
              />
            )}
          </div>
          {children({ onEdit, onDelete })}
          <Snackbars />
        </Grid>
      </div>
    </WrappedRead>
  );
};

export default ReadView;
