import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { KeycloakProfile } from 'keycloak-js';
import { AppThunk } from 'store';

export interface UserState {
  userProfile?: KeycloakProfile;
}

export const initialState: UserState = {};

const user = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserProfile(state, { payload }: PayloadAction<KeycloakProfile>) {
      // eslint-disable-next-line no-param-reassign
      state.userProfile = payload;
    }
  }
});

export const { setUserProfile } = user.actions;

export default user.reducer;

export function updateUserProfile(profile: KeycloakProfile): AppThunk {
  return async dispatch => {
    dispatch(setUserProfile(profile));
  };
}
