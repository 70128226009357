import { AsyncSubmitButton, SecondaryButton } from 'components/Forms/Buttons';
import { useModalStyles } from 'components/Modal';
import React from 'react';
import { PatchFormMode, PatchModalFormStep } from './PatchModal';

interface PatchModalActionsProps {
  formMode: PatchFormMode;
  formStep: PatchModalFormStep;
  formRef: React.MutableRefObject<any | undefined>;
  isLoading?: boolean;
  setFormStep: React.Dispatch<React.SetStateAction<PatchModalFormStep>>;
  resetModal: () => void;
}

export default function PatchModalActions({
  formMode,
  formStep,
  formRef,
  isLoading = false,
  setFormStep,
  resetModal
}: PatchModalActionsProps) {
  const submitForm = () => formRef.current?.submit();
  const classes = useModalStyles();

  if (formStep === PatchModalFormStep.Initial) {
    return (
      <>
        <SecondaryButton
          onClick={resetModal}
          testId={`${formMode}PatchFormCancel`}
        >
          Cancel
        </SecondaryButton>
        <AsyncSubmitButton
          onClick={submitForm}
          testId={`${formMode}PatchFormNext`}
          loading={isLoading}
        >
          Next
        </AsyncSubmitButton>
      </>
    );
  }

  return (
    <>
      <SecondaryButton
        onClick={() => setFormStep(PatchModalFormStep.Initial)}
        testId="addPatchFormBack"
        className={classes.previousButton}
      >
        Previous
      </SecondaryButton>
      <SecondaryButton
        onClick={resetModal}
        testId={`${formMode}PatchFormCancel`}
      >
        Cancel
      </SecondaryButton>
      <AsyncSubmitButton
        onClick={() => formRef.current?.submit()}
        testId={`${formMode}PatchFormSave`}
        loading={isLoading}
      >
        Save
      </AsyncSubmitButton>
    </>
  );
}
