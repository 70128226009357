import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CreateView, renderErrorOrLoading } from 'components/Crud';
import { skillCategoryDomain, skillDomain } from 'models';
import useStyles from 'components/Crud/styles';
import { getDomainSlice, RootState } from 'store';
import { getSkillsInputFieldsProps, validationSchema } from './FormConfig';

function AddSkillView() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { selector, actions } = getDomainSlice(skillCategoryDomain);
  const { items: skillCategoriesData, isLoading, error } = useSelector<
    RootState,
    ReturnType<typeof selector>
  >(selector);

  // TODO - temporary high page size values until FFT-612 is completed
  useEffect(() => {
    if (actions.changePagination) {
      dispatch(actions.changePagination({ pageIndex: 0, pageSize: 10000 }));
    }
  }, []);

  return (
    renderErrorOrLoading(isLoading, 'addEngineerSpinner', error, classes) || (
      <CreateView
        domain={skillDomain}
        fields={getSkillsInputFieldsProps('add', skillCategoriesData)}
        validationSchema={validationSchema}
      />
    )
  );
}

export default AddSkillView;
