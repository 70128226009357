import React from 'react';
import { Theme, makeStyles } from '@material-ui/core';
import Typography from 'components/Typography';
import EngineerIcon from '../EngineerIcon/EngineerIcon';
import { EngineerName } from '../EngineerName/EngineerName';
import { EngineerTravelTime } from './EngineerTravelTime';
import PublishStatusIcon, {
  Status as PublishedStatus
} from './PublishStatusIcon';

export interface StyleProps {
  unavailableReason: string | null;
  jobCount: number;
}
const calcOpacity = ({
  unavailableReason,
  jobCount
}: {
  unavailableReason: string | null;
  jobCount: number;
}) => (unavailableReason && !jobCount ? 0.8 : 1);

const useStyles = makeStyles<Theme, StyleProps>(theme => ({
  reason: {
    color: theme.palette.schedule.timeline.engineerInfo.unavailableReason,
    textTransform: 'uppercase'
  },
  engineerInfo: {
    flexGrow: 1,
    paddingRight: theme.spacing(1),
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  engineerIcon: {
    position: 'relative',
    marginRight: theme.spacing(1),
    opacity: calcOpacity
  },
  engineerName: {
    opacity: calcOpacity
  },
  publishStatus: {
    position: 'absolute',
    top: -8,
    right: -5
  },
  container: {
    cursor: 'pointer'
  }
}));

interface EngineerInfoProps {
  firstName: string;
  lastName: string;
  travelTime: number;
  jobCount: number;
  unavailableReason: string | null;
  scheduleStatus: PublishedStatus;
  vehicleType: string | null;
  containerStyles: string;
  engineerId: string;
}

export const EngineerInfo = (props: EngineerInfoProps) => {
  const {
    firstName,
    lastName,
    travelTime,
    unavailableReason,
    scheduleStatus,
    jobCount,
    vehicleType,
    containerStyles,
    engineerId
  } = props;
  const classes = useStyles({
    unavailableReason,
    jobCount
  });
  const handleEngineerInfoClick = () => {
    window.open(`${window.location.origin}/dashboard/engineers/${engineerId}`);
  };
  return (
    <div
      onClick={handleEngineerInfoClick}
      data-testid="engineerInfoContainer"
      className={`${containerStyles} ${classes.container}`}
    >
      <div className={classes.engineerIcon}>
        <EngineerIcon firstName={firstName} lastName={lastName} />
        {!unavailableReason && (
          <div className={classes.publishStatus}>
            <PublishStatusIcon status={scheduleStatus} />
          </div>
        )}
      </div>
      <div className={classes.engineerInfo}>
        <div className={classes.engineerName}>
          <EngineerName firstName={firstName} lastName={lastName} />
        </div>
        {unavailableReason ? (
          <Typography
            variant="micro"
            data-testid="engineerUnavailable"
            className={classes.reason}
            title={unavailableReason}
          >
            {unavailableReason}
          </Typography>
        ) : (
          <EngineerTravelTime
            travelTime={travelTime}
            vehicleType={vehicleType}
          />
        )}
      </div>
    </div>
  );
};
