import React, { useState, useEffect } from 'react';
import { Autocomplete } from '@material-ui/lab';

import { MultiselectOption } from 'components/Forms';
import { makeStyles } from '@material-ui/core';
import Input from './Input';
import { InputFieldProps } from './utils';
import InputFieldWrapper from './InputFieldWrapper';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
    width: '100%'
  }
}));

export const FormAutocompleteField = ({
  field,
  input,
  meta,
  optionRenderer
}: {
  field: InputFieldProps;
  multiple: boolean;
  input: any;
  meta: any;
  optionRenderer: (option: MultiselectOption) => JSX.Element;
}) => {
  const classes = useStyles();
  const [inputValue, setValue] = useState(input.value || []);

  useEffect(() => {
    if (
      Array.isArray(input.value) &&
      inputValue.length === 0 &&
      input.value.length !== inputValue.length
    ) {
      setValue(input.value || []);
    }
  }, [input.value]);

  if (!field.options) {
    return null;
  }

  const { multiSelectInputFields } = field;

  const maxOptionsSelected = (): boolean => {
    if (multiSelectInputFields?.maxSelected) {
      return inputValue.length >= multiSelectInputFields.maxSelected;
    }
    return false;
  };

  return (
    <InputFieldWrapper>
      <Autocomplete
        className={classes.root}
        placeholder={field.placeholder}
        limitTags={
          multiSelectInputFields?.maxTags
            ? multiSelectInputFields.maxTags
            : undefined
        }
        multiple
        groupBy={
          multiSelectInputFields?.group
            ? option => String(option.other)
            : undefined
        }
        id={field.id || field.name}
        options={field.options}
        getOptionLabel={option => option.label}
        value={inputValue}
        filterSelectedOptions
        getOptionDisabled={maxOptionsSelected}
        getOptionSelected={(option, curr) => option.value === curr.value}
        onChange={(e, value) => {
          // Hooray for stupid hacks 😔
          setValue(value);
          return input.onChange(value);
        }}
        renderTags={(value, getTagProps) => {
          return value.map((option, index) =>
            optionRenderer({ ...option, ...getTagProps({ index }) })
          );
        }}
        renderInput={params => (
          <Input
            field={field}
            meta={meta}
            wrapField={false}
            {...input}
            {...params}
          />
        )}
        debug
      />
    </InputFieldWrapper>
  );
};

export default FormAutocompleteField;
