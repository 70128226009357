import { combineReducers } from 'redux';

import appReducer from './slices/app';
import skillsReducer from './slices/skills';
import appointmentsReducer from './slices/appointments';
import engineersReducer from './slices/engineers';
import patchesReducer from './slices/patches';
import jobTypesReducer from './slices/jobTypes';
import skillCategoriesReducer from './slices/skillCategories';
import shiftPatternsReducer from './slices/shiftPatterns';
import teamsReducer from './slices/teams';
import timeBandsReducer from './slices/timeBands';
import adjustmentReducer from './slices/adjustments';
import scheduleReducer from './slices/schedule';
import dashboardReducer from './slices/dashboard';

import snackbarReducer from './slices/snackbar';
import userReducer from './slices/user';
import geographicAreaReducer from './slices/geographicAreas';
import jobsDetailsReducer from './slices/jobsDetails';
import capacityReducer from './slices/capacity';
import capacityUtilisationReducer from './slices/capacityUtilisation';
import abortReducer from './slices/abortCategories';
import regionsReducer from './slices/regions';

export default combineReducers({
  app: appReducer,
  skills: skillsReducer,
  appointments: appointmentsReducer,
  engineers: engineersReducer,
  patches: patchesReducer,
  jobTypes: jobTypesReducer,
  skillCategories: skillCategoriesReducer,
  shiftPatterns: shiftPatternsReducer,
  teams: teamsReducer,
  timeBands: timeBandsReducer,
  schedules: scheduleReducer,
  adjustments: adjustmentReducer,
  snackbar: snackbarReducer,
  user: userReducer,
  geographicAreas: geographicAreaReducer,
  regions: regionsReducer,
  dashboard: dashboardReducer,
  jobsDetails: jobsDetailsReducer,
  abortCategories: abortReducer,
  capacity: capacityReducer,
  capacityUtilisation: capacityUtilisationReducer
});
