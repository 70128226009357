import {
  abortCategoriesDomain,
  adjustmentDomain,
  appointmentDomain,
  capacityUtilisationDomain,
  engineerDomain,
  geographicAreaDomain,
  jobDetailsDomain,
  jobTypeDomain,
  patchDomain,
  regionsDomain,
  scheduleDomain,
  skillDomain,
  teamDomain,
  shiftPatternDomain,
  capacityDomain,
  skillCategoryDomain,
  timeBandDomain
} from 'models';
import {
  AbortCategory,
  AbortReasonAction,
  AbortReasonProduct
} from 'models/AbortCategory';
import Adjustment from 'models/Adjustment';
import Appointment from 'models/Appointment';
import Capacity, { CapacityUtilisation } from 'models/Capacity';
import Engineer from 'models/Engineers';
import { GeographicArea } from 'models/GeographicArea';
import { JobDetailsResponse } from 'models/JobDetails';
import JobType from 'models/JobTypes';
import Patch from 'models/Patches';
import Region from 'models/Region';
import { Schedule } from 'models/Schedule';
import Skill from 'models/Skills';
import { WorkflowSectionData } from 'models/Workflow';
import { useSelector } from 'react-redux';
import { getDomainSlice } from 'store';
import { cleanupMockoonSearchMocks } from 'utils/mockoon-helper-utils';
import Team from 'models/Team';
import ShiftPattern from 'models/ShiftPattern';
import { createSelector } from '@reduxjs/toolkit';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Timeband from 'models/TimeBand';
import { stateSelector, StateSlice } from './slices';
import { RootState } from './store';
import { workflowData } from './utils';
import { SkillCategoriesStateSlice } from './slices/skillCategories';
import { JobTypesStateSlice } from './slices/jobTypes';

export const useAppointmentsState = () => {
  const { selector } = getDomainSlice(appointmentDomain);
  const state = useSelector<RootState, StateSlice<Appointment>>(selector);
  return state;
};

export const useCapacityUtilisationState = () => {
  const { selector } = getDomainSlice(capacityUtilisationDomain);
  const state = useSelector<RootState, StateSlice<CapacityUtilisation>>(
    selector
  );
  return state;
};

export const useEngineersState = () => {
  const { selector } = getDomainSlice(engineerDomain);
  const state = useSelector<RootState, StateSlice<Engineer>>(selector);
  return state;
};

export const useRegionsState = () => {
  const { selector } = getDomainSlice(regionsDomain);
  const state = useSelector<RootState, StateSlice<Region>>(selector);
  return state;
};

export const usePatchesState = () => {
  const { selector } = getDomainSlice(patchDomain);
  const state = useSelector<RootState, StateSlice<Patch>>(selector);
  return state;
};

export const useGeographicAreaState = () => {
  const { selector } = getDomainSlice(geographicAreaDomain);
  const state = useSelector<RootState, StateSlice<GeographicArea>>(selector);
  return state;
};

export const useScheduleState = () => {
  const scheduleDataSlice = getDomainSlice(scheduleDomain);
  return useSelector<RootState, StateSlice<Schedule>>(
    scheduleDataSlice.selector
  );
};

export const useSkillsState = () => {
  const { selector } = getDomainSlice(skillDomain);
  return useSelector<RootState, StateSlice<Skill>>(selector);
};

export const useTeamsState = () => {
  const { selector } = getDomainSlice(teamDomain);
  return useSelector<RootState, StateSlice<Team>>(selector);
};

export const useShiftPatternsState = () => {
  const { selector } = getDomainSlice(shiftPatternDomain);
  return useSelector<RootState, StateSlice<ShiftPattern>>(selector);
};

export const useJobTypesState = () => {
  const { selector } = getDomainSlice(jobTypeDomain);
  return useSelector<RootState, JobTypesStateSlice>(selector);
};

export const useAppointmentsData = () => {
  const { items } = useAppointmentsState();
  return items;
};

// TODO - https://ovotech.atlassian.net/browse/FT-3886
export const useEngineersData = () => {
  const engineerDataSlice = getDomainSlice(engineerDomain);
  const engineerDataSelector = useSelector<RootState, StateSlice<Engineer>>(
    engineerDataSlice.selector
  );
  const { totalItems } = engineerDataSelector.page;
  return cleanupMockoonSearchMocks(engineerDataSelector.items, totalItems);
};

export const useEngineerData = (engineerId: string) => {
  const engineersData = useEngineersData();
  return engineersData.find(item => item.id === engineerId);
};

export const useJobTypesData = () => {
  const jobTypesDataSlice = getDomainSlice(jobTypeDomain);
  const jobTypesDataSelector = useSelector<RootState, StateSlice<JobType>>(
    jobTypesDataSlice.selector
  );
  return jobTypesDataSelector.items;
};

export const useAllJobTypesData = () => {
  return useJobTypesState().allItems;
};

export const useJobDetailsState = () => {
  const selector = stateSelector<StateSlice<JobDetailsResponse>>(
    jobDetailsDomain.plural as keyof RootState
  );
  return useSelector<RootState, ReturnType<typeof selector>>(selector);
};

export const useJobsDetailsData = () => {
  const useJobsDetailsState = useJobDetailsState();
  return useJobsDetailsState.items;
};

export const useJobDetailsData = (jobDetailsId: string) => {
  const jobsDetailsData = useJobsDetailsData();
  return jobsDetailsData.find(item => item.id === jobDetailsId);
};

export const useWorkflowData = (
  jobDetailsId: string
): WorkflowSectionData[] => {
  const jobDetailsData = useJobDetailsData(jobDetailsId);
  const flags = useFlags();

  return workflowData(jobDetailsData, flags?.aegisFetchPreviousAbortData);
};

export const usePatchesData = () => {
  const patchesDataSlice = getDomainSlice(patchDomain);
  const patchesDataSelector = useSelector<RootState, StateSlice<Patch>>(
    patchesDataSlice.selector
  );
  return patchesDataSelector.items;
};

export const usePatchData = (patchId: string) => {
  const patchesData = usePatchesData();
  return patchesData.find(item => item.id === patchId);
};

export const useGeographicAreasData = () => {
  const geographicAreasDataSlice = getDomainSlice(geographicAreaDomain);
  const geographicAreasDataSelector = useSelector<
    RootState,
    StateSlice<GeographicArea>
  >(geographicAreasDataSlice.selector);
  return geographicAreasDataSelector.items;
};

export const useRegionsData = () => {
  const regionsDataSlice = getDomainSlice(regionsDomain);
  const regionsDataSelector = useSelector<RootState, StateSlice<Region>>(
    regionsDataSlice.selector
  );
  return regionsDataSelector.items;
};

export const useSkillsData = () => {
  const skillsDataSelector = useSkillsState();
  return skillsDataSelector.items;
};

export const useSkillData = (skillId: string) => {
  const skillsData = useSkillsData();
  return skillsData.find(item => item.id === skillId);
};

export const useSkillCategoriesData = () => {
  const skillCategoryDataSlice = getDomainSlice(skillCategoryDomain);
  const skillCategoryDataSelector = useSelector<
    RootState,
    SkillCategoriesStateSlice
  >(skillCategoryDataSlice.selector);
  return skillCategoryDataSelector.items;
};

export const useAbortCategoryData = () => {
  const abortCategoriesSlice = getDomainSlice(abortCategoriesDomain);
  const abortCategoriesState = useSelector<
    RootState,
    StateSlice<AbortCategory>
  >(abortCategoriesSlice.selector);
  return abortCategoriesState.items;
};

export const useTeamsData = () => {
  const teamsDataSelector = useTeamsState();
  return teamsDataSelector.items;
};

export const useShiftPatternsData = () => {
  const { items } = useShiftPatternsState();
  return items;
};

export const useScheduleError = () => {
  const { error } = useScheduleState();
  return error;
};

export const useAdjustmentsState = () => {
  const adjustmentSlice = getDomainSlice(adjustmentDomain);

  const adjustmentsState = useSelector<RootState, StateSlice<Adjustment>>(
    adjustmentSlice.selector
  );

  return adjustmentsState;
};

export const useCapacityData = () => {
  const capacitySlice = getDomainSlice(capacityDomain);

  const { items } = useSelector<RootState, StateSlice<Capacity>>(
    capacitySlice.selector
  );

  return items;
};

export const useTimebandData = () => {
  const timebandSlice = getDomainSlice(timeBandDomain);

  const { items } = useSelector<RootState, StateSlice<Timeband>>(
    timebandSlice.selector
  );

  return items;
};

const selectAbortCategoriesState = (state: RootState) =>
  state.abortCategories.items;

export const selectAbortOrCancelCategories = (
  jobProductType: AbortReasonProduct,
  actionType: AbortReasonAction
) =>
  createSelector(selectAbortCategoriesState, abortCategories => {
    const currentCategories = abortCategories.reduce((acc, curr) => {
      const abortReasonsWithMatchingProductType = curr.reasons.filter(
        reason => {
          // If one or more configurations products match and are abort actions
          return reason.configurations.some(
            ({ product, action }) =>
              product === jobProductType && action === actionType
          );
        }
      );

      const filteredAbortCategories =
        abortReasonsWithMatchingProductType.length > 0
          ? [...acc, { ...curr, reasons: abortReasonsWithMatchingProductType }]
          : acc;

      return filteredAbortCategories;
    }, [] as AbortCategory[]);

    return currentCategories || [];
  });
