import * as React from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import { Palette, PaletteColor } from '@material-ui/core/styles/createPalette';

import { DayOfWeek } from 'models/Adjustment';
import { TimeField } from 'components/Forms';
import Typography from 'components/Typography';

const useStyles = makeStyles(
  (theme: Theme & { palette: Palette & { fieldForce: PaletteColor } }) => ({
    root: {
      paddingTop: theme.spacing(2)
    }
  })
);

const Timetable = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography gutterBottom data-testid="timetable-title">
        Timetable
      </Typography>
      <Typography gutterBottom data-testid="timetable-helper-text">
        Leave the day blank if you do not want to make any changes.
      </Typography>
      {Object.keys(DayOfWeek).map(day => (
        <TimeField label={day} key={day} />
      ))}
    </div>
  );
};

export default Timetable;
