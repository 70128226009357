import * as React from 'react';
import { Chip, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  chip: {
    marginLeft: theme.spacing(1),
    backgroundColor: theme.palette.info.main,
    color: theme.palette.getContrastText(theme.palette.info.main)
  }
}));

interface InfoChipProps {
  label: string;
}

export default function InfoChip(props: InfoChipProps) {
  const classes = useStyles();
  return <Chip className={classes.chip} {...props} />;
}
