import http from 'api/http';
import { appointmentDomain } from 'models';
import { AppointmentResponse, SiteAbortDetails } from 'models/Appointment';
import { createUrl, commonErrorHandling } from 'store/utils';

export async function fetchPreviousAborts(
  appointmentData: AppointmentResponse
): Promise<SiteAbortDetails | null> {
  const details = appointmentData.appointmentType?.details;
  const mpan = details?.mpan ?? null;
  const mprn = details && 'mprn' in details ? details.mprn : null;

  if ((mpan ?? mprn) === null) return null;

  try {
    const { data: previousAbortData } = await http.post<SiteAbortDetails[]>(
      `${createUrl(appointmentDomain)}/abort/search`,
      { sites: [{ mpan, mprn }] }
    );
    return previousAbortData ? previousAbortData[0] : null;
  } catch (error) {
    commonErrorHandling(error, appointmentDomain.type);
    return null;
  }
}
