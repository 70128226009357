/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Region from 'models/Region';
import { AppThunk } from 'store';
import {
  initialStateSlice,
  startLoading,
  loadingFailed,
  StateSlice,
  getPaginationReducers
} from 'store/slices';
import { regionsDomain } from 'models';
import { fetchThunk } from 'store/utils/';

const regions = createSlice({
  name: regionsDomain.plural,
  initialState: initialStateSlice,
  reducers: {
    ...startLoading<Region>(),
    ...loadingFailed<Region>(),
    ...getPaginationReducers<Region>(),
    getRegionsSuccess(state, { payload }: PayloadAction<StateSlice<Region>>) {
      const { items, page } = payload;

      state.items = items;
      state.page = page;
      state.isLoading = false;
      state.error = null;
    }
  }
});

export const {
  requestStart,
  requestFail,
  setPagination: setRegionsPagination,
  getRegionsSuccess
} = regions.actions;

export default regions.reducer;

export function fetchRegions(): AppThunk {
  return fetchThunk<StateSlice<Region>>({
    domain: regionsDomain,
    requestStart,
    requestFail,
    requestSuccess: getRegionsSuccess
  });
}
