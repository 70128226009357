import React, { memo } from 'react';
import { makeStyles, createStyles, Theme, alpha } from '@material-ui/core';
import { ReportProblem } from '@material-ui/icons';
import {
  getJobCardBorder,
  getJobCardStyle,
  getReportedIconColor,
  getReportedSectionBorderColor
} from 'utils/jobStatus';
import { JobCardStyle } from '@material-ui/core/styles/createPalette';
import Typography from 'components/Typography';
import { getOpacity, MapElementType } from 'utils/mapElements';
import { CustomerAppointmentType } from 'models/Appointment';
import { JobCardProps, JobCardStyleType, JobCardViewMode } from './JobCard';
import EVMarker from './EVMarker';

export const unassignedCardHeight = 40;

const getBoxShadowColor = (jobCardStyle: JobCardStyleType, theme: Theme) => {
  switch (jobCardStyle) {
    case JobCardStyleType.UNASSIGNED:
      return theme.palette.aegis.colour.grey.dark;
    case JobCardStyleType.CANCELLED:
    case JobCardStyleType.ABORTED:
      return theme.palette.aegis.colour.grey.darkest;
    case JobCardStyleType.AT_RISK:
      return theme.palette.aegis.colour.orange.light;
    case JobCardStyleType.COMPLETED:
      return theme.palette.aegis.colour.green.lighter;
    default:
      return theme.palette.aegis.colour.grey.dark;
  }
};

const getBoxShadow = (
  theme: Theme,
  viewMode: JobCardViewMode,
  jobCardStyle: JobCardStyleType
) => {
  return `0px 0px 0px ${
    viewMode === JobCardViewMode.TIMELINE
      ? theme.spacing(0.5)
      : theme.spacing(1)
  }px ${alpha(getBoxShadowColor(jobCardStyle, theme), 0.5)}`;
};

interface BoxShadowProps {
  boxShadowEnabled: boolean;
}

const useStyles = makeStyles<
  Theme,
  JobCardProps & JobCardStyle & BoxShadowProps
>(theme => {
  return createStyles({
    label: {
      fill: ({ color }) => color,
      fontSize: 10,
      fontWeight: 600,
      textAlign: 'center',
      margin: 'auto'
    },
    warning: {
      fontSize: theme.typography.fontSize,
      color: ({ jobCardStyleType }) => getReportedIconColor(jobCardStyleType)
    },
    warningContainer: {
      width: '100%',
      height: '50%',
      display: 'flex',
      justifyContent: 'center',
      flex: 1,
      borderBottom: ({ jobCardStyleType }) =>
        `1px solid ${getReportedSectionBorderColor(jobCardStyleType)}`,
      paddingBottom: ({ viewMode }) =>
        viewMode === JobCardViewMode.MAP ? theme.spacing(1) : theme.spacing(2)
    },
    circle: {
      backgroundColor: ({ background }) => background,
      color: ({ color }) => color,
      borderRadius: '3px',
      border: ({ jobCardStyleType, viewMode }) =>
        getJobCardBorder(jobCardStyleType, viewMode),
      height: unassignedCardHeight,
      width: unassignedCardHeight,
      display: 'flex',
      position: 'relative',
      flexDirection: ({ hasComplaints }) =>
        hasComplaints ? 'column' : undefined,
      alignItems: 'center',
      opacity: ({ selectedEngineer, engineerId, canDrag }) =>
        getOpacity(
          MapElementType.UNASSIGNED,
          selectedEngineer,
          engineerId,
          canDrag
        ),
      boxShadow: ({
        boxShadowEnabled,
        appointmentId,
        selectedJobCardDetails,
        jobCardStyleType,
        viewMode
      }) => {
        const shouldPressedStyleShow =
          boxShadowEnabled &&
          selectedJobCardDetails?.isOpen &&
          appointmentId === selectedJobCardDetails?.id;

        return shouldPressedStyleShow
          ? getBoxShadow(theme, viewMode, jobCardStyleType)
          : 'none';
      }
    }
  });
});

const Unassigned = (unassignedProps: JobCardProps) => {
  const { label, jobCardStyleType, hasComplaints } = unassignedProps;

  const jobCardStyle = getJobCardStyle(jobCardStyleType);
  const classes = useStyles({
    ...unassignedProps,
    ...jobCardStyle,
    boxShadowEnabled: true
  });

  return (
    <div className={classes.circle} data-testid="unassignedJobCardBackground">
      {hasComplaints && (
        <div
          className={classes.warningContainer}
          data-testid="warningComplaints"
        >
          <ReportProblem className={classes.warning} />
        </div>
      )}
      <Typography className={classes.label}>{label}</Typography>
      {unassignedProps.type === CustomerAppointmentType.EV && <EVMarker />}
    </div>
  );
};

export default memo(Unassigned);
