import { useRef, useEffect } from 'react';

/**
 * By using the useRef hook, which will only update the value it's storing in ref when ref.current is used, we can
 * use this hook to access the previous value from the redux store, or local state for example. I have also set up
 * an additional optional param that allows a particular value to be provided, which will be ignored and the reference
 * won't update to it.
 */
export const usePrevious = (value: any, ignoredValue?: any): any => {
  const ref = useRef();
  useEffect(() => {
    if (ignoredValue === undefined) {
      ref.current = value;
    }
    if (ignoredValue !== undefined && ignoredValue !== value) {
      ref.current = value;
    }
  });
  return ref.current;
};
