import { Grid, IconButton, makeStyles } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import React, { useContext } from 'react';

import Button from 'components/Button';
import { DateTime } from 'luxon';
import { useHistory, useParams } from 'react-router-dom';
import { useEngineerData } from 'store/selectors';
import Typography from 'components/Typography';
import {
  dateDiffUnit,
  EngineerCalendarContext,
  EngineerCalendarDatesContext
} from './EngineerCalendar';
import { TemporaryAdjustmentFormContext } from '../EngineerSchedule/AdjustmentFormModals';

const useStyles = makeStyles(
  theme => ({
    wrapper: {
      padding: theme.spacing(2, 3),
      '& .MuiIconButton-root': {
        padding: 0
      },
      lineHeight: 10
    },
    title: {
      display: 'flex',
      alignItems: 'center'
    },
    date: {
      width: 128,
      margin: theme.spacing(0, 1.5),
      color: theme.palette.aegis.semantic.message.dark,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    flex: {
      display: 'flex'
    },
    editScheduleButton: {
      display: 'flex',
      justifyContent: 'flex-end'
    },
    todayButton: {
      marginLeft: theme.spacing(4)
    },
    viewAppointmentButton: {
      padding: theme.spacing(1, 2),
      marginRight: theme.spacing(4)
    }
  }),
  { name: 'EngineerCalendarHeader' }
);

function EngineerCalendarHeader() {
  const dateConfig = { [dateDiffUnit]: 1 };
  const classes = useStyles();
  const { setCalendarCapacity } = useContext(EngineerCalendarContext);
  const { dates, setDates } = useContext(EngineerCalendarDatesContext);
  const { setIsOpen, setAdjustment } = useContext(
    TemporaryAdjustmentFormContext
  );

  const history = useHistory();

  const { id: itemId } = useParams<{ id: string }>();
  const engineerData = useEngineerData(itemId);

  const onModalOpen = () => {
    setAdjustment?.(undefined);
    setIsOpen?.(true);
  };

  const resetPatchCapacity = () => {
    if (setCalendarCapacity) {
      setCalendarCapacity(state => ({ ...state, data: undefined }));
    }
  };

  const handlePreviousWeek = () => {
    if (setDates) {
      setDates(currentDates => ({
        start: currentDates.start.minus(dateConfig),
        end: currentDates.end.minus(dateConfig)
      }));

      resetPatchCapacity();
    }
  };

  const handleNextWeek = () => {
    if (setDates) {
      setDates(currentDates => ({
        start: currentDates.start.plus(dateConfig),
        end: currentDates.end.plus(dateConfig)
      }));

      resetPatchCapacity();
    }
  };

  const handleGoToToday = () => {
    setDates?.(() => ({
      start: DateTime.now().startOf(dateDiffUnit),
      end: DateTime.now().endOf(dateDiffUnit)
    }));

    resetPatchCapacity();
  };

  const onViewAppointments = () => {
    history.push(`${engineerData?.id}/appointments`, engineerData);
  };

  const ndashUnicodeChar = '\u2013';
  const endDateLabel =
    dates.end.month !== dates.start.month
      ? dates.end.toFormat('MMM')
      : undefined;

  const dateDisplayText = [
    dates.start.toFormat('MMM'),
    dates.start.toFormat('d'),
    ndashUnicodeChar,
    endDateLabel,
    dates.end.toFormat('d')
  ]
    .filter(value => value != undefined && value != '')
    .join(' ');

  return (
    <>
      <Grid
        container
        component="header"
        justifyContent="center"
        alignItems="center"
        className={classes.wrapper}
      >
        <Grid item xs className={classes.title}>
          <Typography variant="h2">Engineer Schedule</Typography>
          <Button
            className={classes.todayButton}
            onClick={handleGoToToday}
            data-testid="engineerCalendarTodayButton"
          >
            Today
          </Button>
        </Grid>
        <Grid
          item
          className={classes.flex}
          data-testid="engineerCalendarControls"
        >
          <IconButton onClick={handlePreviousWeek} aria-label="Previous week">
            <ChevronLeftIcon />
          </IconButton>
          <Typography
            variant="h3"
            component="div"
            align="center"
            className={classes.date}
            data-testid="engineerCalendarDate"
          >
            {dateDisplayText}
          </Typography>
          <IconButton onClick={handleNextWeek} aria-label="Next week">
            <ChevronRightIcon />
          </IconButton>
        </Grid>
        <Grid item xs className={classes.editScheduleButton}>
          <Button
            color="secondary"
            onClick={onViewAppointments}
            className={classes.viewAppointmentButton}
            data-testid="calendarViewAppointmentsBtn"
          >
            View appointments
          </Button>
          <Button
            type="button"
            color="primary"
            onClick={onModalOpen}
            data-testid="editEngineerScheduleModalBtn"
          >
            Edit schedule
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

export default React.memo(EngineerCalendarHeader);
