import * as Yup from 'yup';

import {
  mapRequestDataToField,
  getDefaultInputFieldsProps,
  InputFieldProps,
  yupSchemaToValidationFunc
} from 'components/Forms';
import SkillCategory from 'models/SkillCategory';
import { getRequiredErrorMessage } from 'constants/validation';

const skillCategoryDataToSelectMap = mapRequestDataToField();

export const getSkillsInputFieldsProps = (
  type: 'add' | 'edit',
  skillCategory?: SkillCategory[]
) => {
  const fields: Pick<
    InputFieldProps,
    | 'required'
    | 'name'
    | 'autoFocus'
    | 'qaId'
    | 'inputType'
    | 'children'
    | 'preprocess'
    | 'options'
  >[] = [
    {
      required: true,
      name: 'name',
      qaId: `${type}SkillName`,
      inputType: 'textfield'
    },
    {
      required: true,
      name: 'category',
      qaId: `${type}SkillCategory`,
      inputType: 'select',
      options:
        skillCategory &&
        skillCategory.map(category => ({
          ...skillCategoryDataToSelectMap(category),
          testId: 'skillCategory'
        })),
      preprocess: (category: SkillCategory) => category.id
    }
  ];

  return getDefaultInputFieldsProps(fields);
};

export const validationSchema = Yup.object().shape({
  category: Yup.string().required('Required'),
  name: Yup.string().required('Required')
});

export const newValidationSchema = Yup.object().shape({
  category: Yup.string().required(getRequiredErrorMessage('category')),
  name: Yup.string().required(getRequiredErrorMessage('name'))
});

export const newInitialFormValidation = yupSchemaToValidationFunc(
  newValidationSchema
);
