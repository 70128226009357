import React from 'react';
import {
  Box,
  makeStyles,
  Tab,
  Tabs as MUITabs,
  TabsProps as MUITabsProps
} from '@material-ui/core';

export interface TabOptions {
  label: string;
  onClick?: (...args: any[]) => any;
}
interface TabsContext {
  selectedTabIndex: number;
  tabs: TabOptions[];
  onTabChange?: (event: React.ChangeEvent<{}>, newValue: number) => void;
}

const TabsContext = React.createContext<TabsContext>({
  selectedTabIndex: 0,
  tabs: []
});

interface TabsProviderProps {
  tabs: TabOptions[];
  children: React.ReactNode | React.ReactNodeArray;
  preSelectedTabIndex?: number;
}

/**
 * This component provides `TabsContext`, which contains the index of the currently selected tab
 * index (`selectedTabIndex`), the tab labels (`tabs`) and a callback to change the selected tab
 * index (`onTabChange`)
 * @param {TabOptions[]} tabs - An array of tab configuration objects
 * @param {React.ReactNode | React.ReactNode[]} children - Descendants of the component which can consume `TabsContext`
 * @param {number} [preSelectedTabIndex=0] - A preselected tab index. Defaults to `0`.
 */
export const TabsProvider = ({
  tabs,
  children,
  preSelectedTabIndex = 0
}: TabsProviderProps) => {
  const [selectedTabIndex, setValue] = React.useState(preSelectedTabIndex);

  const onTabChange = (_: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <TabsContext.Provider value={{ selectedTabIndex, tabs, onTabChange }}>
      {children}
    </TabsContext.Provider>
  );
};

function getFormattedTabLabel(label: string) {
  return label.toLowerCase().replace(' ', '-');
}

const tabProps = (label: string, index: number) => {
  const formattedLabel = getFormattedTabLabel(label);
  return {
    id: `tab-${formattedLabel}-${index}`,
    'aria-controls': `tabpanel-${formattedLabel}-${index}`,
    'data-testid': `tab-${formattedLabel}-${index}`
  };
};

const useStyles = makeStyles(theme => ({
  tabs: {
    position: 'sticky',
    top: 0,
    boxShadow: `inset 0px -2px 0px ${theme.palette.aegis.semantic.background.canvas}`
  },
  tab: {
    padding: theme.spacing(0, 2.5),
    minWidth: 'auto'
  }
}));

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  className?: string;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, index, className } = props;
  const { selectedTabIndex, tabs } = React.useContext(TabsContext);
  const tab = tabs[index];
  const formattedLabel = getFormattedTabLabel(tab.label);

  return (
    <div
      role="tabpanel"
      hidden={selectedTabIndex !== index}
      id={`tabpanel-${formattedLabel}-${index}`}
      aria-labelledby={`tab-${formattedLabel}-${index}`}
      className={className || ''}
    >
      {selectedTabIndex === index && <Box>{children}</Box>}
    </div>
  );
};

interface TabPanelsProps {
  children: React.ReactNodeArray;
  panelClassName?: string;
}

export const TabPanels = ({ children, panelClassName }: TabPanelsProps) => {
  const { tabs } = React.useContext(TabsContext);
  const numChildren = React.Children.count(children);

  if (tabs.length === 0) {
    throw new Error(
      'The `tabs` property of `TabsContext` expects an array of `TabOptions`, but received an empty array.'
    );
  }

  if (!children) {
    throw new Error(
      'The `<TabPanels />` component requires children for tabs to work correctly.'
    );
  }

  if (numChildren !== tabs.length) {
    throw new Error(
      'Tabs require the same number of `TabOptions` and tab panels.\n' +
        `The \`<TabPanels />\` component expected ${tabs.length} children, but received ${numChildren}.`
    );
  }

  return (
    <>
      {children.map((panel, index) => (
        <TabPanel
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          index={index}
          className={panelClassName}
        >
          {panel}
        </TabPanel>
      ))}
    </>
  );
};

interface TabsProps {
  muiProps?: MUITabsProps;
  className?: string;
  tabClassName?: string;
}

export const Tabs = ({ muiProps, className, tabClassName }: TabsProps) => {
  const classes = useStyles();
  const { selectedTabIndex, tabs, onTabChange } = React.useContext(TabsContext);

  return (
    <MUITabs
      textColor="primary"
      value={selectedTabIndex}
      onChange={onTabChange}
      className={className ? `${classes.tabs} ${className}` : classes.tabs}
      variant="standard"
      {...muiProps}
    >
      {tabs.map(({ label, onClick }, index) => (
        <Tab
          key={label}
          label={label}
          {...(onClick ? { onClick } : {})}
          {...tabProps(label, index)}
          className={
            tabClassName ? `${classes.tab} ${tabClassName}` : classes.tab
          }
        />
      ))}
    </MUITabs>
  );
};

export default Tabs;
