import { FORM_ERROR } from 'final-form';
import Yup from 'yup';
import { FormValues, FormError } from './types';

// Converts the yup schema into form validation and returns an object of
// TODO sort out async vs sync validation
export const yupSchemaToValidationFunc = (
  validationSchema: Yup.ObjectSchema<any, any>
  /* eslint-disable consistent-return */
) => (values: FormValues): FormError | undefined => {
  try {
    validationSchema.validateSync(values, {
      abortEarly: false
    });
  } catch (err) {
    const errors = err.inner.reduce(
      (
        formError: FormError,
        innerError: { path: string; message: string }
      ) => ({
        ...formError,
        [innerError.path?.length
          ? innerError.path
          : FORM_ERROR]: innerError.message
      }),
      {}
    );

    return errors;
  }
};
