import React from 'react';

import { useField } from 'react-final-form';
import { Select } from '.';
import { InputFieldProps } from './utils';

export default function TimeTableSelect({ field }: { field: InputFieldProps }) {
  const { input, meta } = useField(field.name);

  return (
    <Select
      {...input}
      field={field}
      meta={meta}
      wrapField={false}
      fullWidth={false}
      className={field.className}
    />
  );
}
