import * as Sentry from '@sentry/react';
import { AxiosError } from 'axios';
import { Domain } from 'models/Domain';
import { API_ERROR_MESSAGE } from 'constants/errors';
import { setSnackbar, SnackbarStatus } from 'store/slices/snackbar';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { AppDispatch } from 'store/store';

/**
 * This function provides the HTTP status code if available (defaults to `500`) and a user friendly error message.
 * @param {AxiosError | any} error - An error thrown by an Axios request or any other error caught by a Redux Thunk
 * @param {boolean} getErrorMessageAndStatus - A flag to ignore default messages and always use what the API returns.
 * @property {string} overrideMessageText - A string to override the message shown. Added due to 422 responses having a different structure.
 * Defaults to `false`
 */
export function getErrorMessageAndStatus(
  error: AxiosError | any,
  alwaysShowApiErrorMessages: HandleThunkErrorConfig['alwaysShowApiErrorMessages'] = false,
  overrideMessageText?: string
) {
  const status = error.response?.status || 500;
  const defaultErrorMessage = API_ERROR_MESSAGE[500];

  if (overrideMessageText) {
    return {
      status,
      message: overrideMessageText
    };
  }

  if (alwaysShowApiErrorMessages) {
    const message =
      error.response?.data.message ||
      API_ERROR_MESSAGE[status] ||
      defaultErrorMessage;

    return {
      status,
      message
    };
  }

  const message =
    API_ERROR_MESSAGE[status] ||
    error.response?.data.message ||
    defaultErrorMessage;

  return {
    status,
    message
  };
}

/**
 * This function sets the snackbar to an error state and opens it to display the error message.
 * @param {AxiosError | any} error - An error thrown by an Axios request or any other error caught by a Redux Thunk
 */
export function displayErrorSnackbar(
  message: string,
  status: number,
  dispatch: AppDispatch
) {
  dispatch(
    setSnackbar({
      title: `Error ${status}`,
      message,
      show: true,
      status: SnackbarStatus.Error
    })
  );
}

/**
 * This function reports errors to Sentry and will reload the page if the error status code is `403`.
 * @param {AxiosError | any} error - An error thrown by an Axios request or any other error caught by a Redux Thunk
 * @param {Domain['type']} domainType - The `type` property from the domain object
 */
export function commonErrorHandling(
  error: AxiosError | any,
  domain?: Domain['type']
) {
  Sentry.withScope(scope => {
    if (domain) {
      scope.setTag('domain', domain);
    }
    Sentry.captureException(error);
  });
}

/**
 * HandleThunkErrorConfig type definition
 * @type {Object} HandleThunkErrorConfig
 * @property {AxiosError | any} error - An error thrown by an Axios request or any other error caught by a Redux Thunk
 * @property {ActionCreatorWithPayload<AxiosError | any>} requestFail - The Redux failure action for the slice
 * @property {Domain['type']} [domainType] - The `type` property from the domain object
 * @property {boolean} [alwaysShowApiErrorMessages=false] - A flag to ignore default messages and always use what the API returns.
 * Defaults to `false`
 * @property {string} [overrideMessageText] - A string to override the message shown. Added due to 422 responses having a
 * different structure.
 * @property {boolean} [displayError=true] - A flag which enables/disables the displaying of an error snackbar. Defaults to `true`
 */
interface HandleThunkErrorConfig {
  error: AxiosError | any;
  requestFail: ActionCreatorWithPayload<AxiosError | any>;
  dispatch: AppDispatch;
  domainType?: Domain['type'];
  alwaysShowApiErrorMessages?: boolean;
  overrideMessageText?: string;
  displayError?: boolean;
}

/**
 * This function dispatches the `requestFail` action for a given Thunk, display an error snackbar and report errors to Sentry.
 * @param {HandleThunkErrorConfig} config - The configuration options for `handleThunkError`
 */
export function handleThunkError({
  error,
  requestFail,
  domainType,
  alwaysShowApiErrorMessages,
  overrideMessageText,
  displayError = true,
  dispatch
}: HandleThunkErrorConfig) {
  const { message, status } = getErrorMessageAndStatus(
    error,
    alwaysShowApiErrorMessages,
    overrideMessageText
  );

  commonErrorHandling(error, domainType);

  if (displayError) {
    displayErrorSnackbar(message, status, dispatch);
  }

  dispatch(requestFail(message));
}
