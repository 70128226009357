export interface AbortCategory {
  id: string;
  name: string;
  reasons: AbortReason[];
}

export interface AbortReason {
  id: string;
  name: string;
  configurations: AbortReasonConfiguration[];
}

export interface AbortReasonConfiguration {
  product: AbortReasonProduct;
  action: AbortReasonAction;
}

export enum AbortReasonProduct {
  'EV' = 'EV',
  'Smart' = 'SMART'
}

export enum AbortReasonAction {
  'Cancel' = 'CANCEL',
  'Abort' = 'ABORT'
}

export interface CloseAppointmentResponse {
  closeAction: AbortReasonAction;
}
