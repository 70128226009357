import * as React from 'react';
import { Chip, makeStyles } from '@material-ui/core';

import { Filter } from 'components/FilterSearch/FilterSearch';

const useStyles = makeStyles(theme => ({
  chip: {
    marginRight: theme.spacing(1)
  }
}));

export interface SearchFilterChipProps {
  filter: Filter;
  value: string;
  onDelete: (filter: Filter) => void;
}

export default function SearchFilterChip({
  value,
  filter,
  onDelete,
  ...props
}: SearchFilterChipProps) {
  const classes = useStyles();

  const handleDelete = () => {
    onDelete(filter);
  };

  return (
    <Chip
      className={classes.chip}
      label={value}
      onDelete={handleDelete}
      data-testid={`searchFilterChip-${filter.id}`}
      {...props}
    />
  );
}
