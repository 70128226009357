import React from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import { getOpacity, MapElementType } from 'utils/mapElements';

export const engineerHomeIconWidth = 38;
export const engineerHomeIconHeight = 38;

interface EngineerHomeIconProps {
  engineerId?: string;
  selectedEngineer?: string;
}

const useStyles = makeStyles<Theme, { opacity: number }>(theme => ({
  engineerHomeIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: engineerHomeIconWidth,
    height: engineerHomeIconHeight,
    background: theme.palette.schedule.map.engineerHomeIcon.background,
    color: theme.palette.common.white,
    borderRadius: '50%',
    opacity: ({ opacity }) => opacity,

    '& svg': {
      fontSize: 25
    }
  }
}));

const EngineerHomeIcon = ({
  engineerId,
  selectedEngineer
}: EngineerHomeIconProps) => {
  const opacity = getOpacity(MapElementType.HOME, selectedEngineer, engineerId);
  const classes = useStyles({ opacity });
  return (
    <div className={classes.engineerHomeIcon} data-testid="engineerHomeIcon">
      <HomeIcon />
    </div>
  );
};

export default EngineerHomeIcon;
