/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import Capacity from 'models/Capacity';
import { AppThunk } from 'store';
import {
  paramsSerializer,
  createFilterQuery,
  QueryParams,
  createUrl,
  handleThunkError
} from 'store/utils/';
import {
  initialStateSlice,
  startLoading,
  loadingFailed,
  getPaginationReducers
} from 'store/slices';
import { capacityDomain } from 'models';
import http from 'api/http';
import { setSnackbar, SnackbarStatus } from './snackbar';

const capacity = createSlice({
  name: capacityDomain.plural,
  initialState: initialStateSlice,
  reducers: {
    ...startLoading<Capacity>(),
    ...loadingFailed<Capacity>(),
    ...getPaginationReducers<Capacity>(),
    getCapacitySuccess(state, { payload }: PayloadAction<Capacity[]>) {
      state.items = payload;
      state.page = initialStateSlice.page;
      state.isLoading = false;
      state.error = null;
    }
  }
});

export const {
  requestStart,
  requestFail,
  setPagination: setCapacityPagination,
  setTotalItems,
  setTotalPages,
  getCapacitySuccess
} = capacity.actions;

export default capacity.reducer;

export function fetchCapacity(query: QueryParams): AppThunk {
  return async dispatch => {
    try {
      dispatch(requestStart());
      const { data: capacityData } = await http.get<Capacity[]>(
        createUrl(capacityDomain),
        {
          params: {
            params: createFilterQuery(query),
            includeTomorrow: true,
            includeOutsideBookingWindow: true
          },
          paramsSerializer
        }
      );

      if (capacityData.length === 0) {
        dispatch(
          setSnackbar({
            title: 'Error',
            message: 'No appointments available.',
            show: true,
            status: SnackbarStatus.Error
          })
        );
      }

      dispatch(getCapacitySuccess(capacityData));

      return null;
    } catch (error) {
      handleThunkError({
        error,
        requestFail,
        dispatch,
        domainType: capacityDomain.type
      });

      return null;
    }
  };
}
