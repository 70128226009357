import * as Yup from 'yup';
import { getDefaultInputFieldsProps, InputFieldProps } from 'components/Forms';
import {
  arrayToCsvString,
  capitalise,
  csvRegEx,
  validateCsvString
} from 'utils';
import { getRequiredErrorMessage } from 'constants/validation';
import { GeographicArea } from 'models/GeographicArea';
import { PatchFormMode } from './PatchModal';

enum PatchModalFieldNames {
  NAME = 'name',
  OUTCODES = 'outcodes',
  AREA = 'area'
}

export const getPatchInputFieldsProps = (type: PatchFormMode) => {
  const fields: Pick<
    InputFieldProps,
    | 'required'
    | 'name'
    | 'autoFocus'
    | 'qaId'
    | 'inputType'
    | 'children'
    | 'preprocess'
    | 'type'
    | 'validate'
  >[] = [
    {
      required: true,
      name: PatchModalFieldNames.NAME,
      autoFocus: true,
      qaId: `${type}Patch${capitalise(PatchModalFieldNames.NAME)}`,
      inputType: 'textfield'
    },
    {
      required: true,
      name: PatchModalFieldNames.OUTCODES,
      autoFocus: true,
      qaId: `${type}Patch${capitalise(PatchModalFieldNames.OUTCODES)}`,
      validate: validateCsvString,
      preprocess: arrayToCsvString,
      inputType: 'textfield'
    }
  ];

  return getDefaultInputFieldsProps(fields);
};

export const getAreaInputFieldsProps = (
  type: PatchFormMode,
  geographicAreas: GeographicArea[]
) => {
  const fields: Pick<
    InputFieldProps,
    | 'required'
    | 'name'
    | 'autoFocus'
    | 'qaId'
    | 'inputType'
    | 'children'
    | 'preprocess'
    | 'type'
    | 'validate'
    | 'options'
  >[] = [
    {
      required: true,
      name: PatchModalFieldNames.AREA,
      autoFocus: true,
      qaId: `${type}Patch${capitalise(PatchModalFieldNames.AREA)}Name`,
      inputType: 'select',
      options: geographicAreas.map(({ id, name }) => ({
        label: name,
        value: id
      }))
    }
  ];

  return getDefaultInputFieldsProps(fields);
};

const outcodeValidationErrorMessage = getRequiredErrorMessage('outcode');

export const patchValidationSchema = Yup.object().shape({
  [PatchModalFieldNames.NAME]: Yup.string().required(
    getRequiredErrorMessage(PatchModalFieldNames.NAME)
  ),
  [PatchModalFieldNames.OUTCODES]: Yup.string()
    .matches(csvRegEx, outcodeValidationErrorMessage)
    .required(outcodeValidationErrorMessage)
});

export const areaValidationSchema = Yup.object().shape({
  [PatchModalFieldNames.AREA]: Yup.string().required(
    `Choose an ${PatchModalFieldNames.AREA}`
  )
});
