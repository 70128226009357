/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["formRef"] }] */

import { makeStyles } from '@material-ui/core';
import React, { useContext, useRef } from 'react';

import Button from 'components/Button';
import {
  Field,
  Form,
  FormValues,
  yupSchemaToValidationFunc
} from 'components/Forms';
import Typography from 'components/Typography';

import { FormApiWithRestart } from 'components/Forms/Form';
import Modal from 'components/Modal';

import {
  AdjustmentFormType,
  adjustmentFormTypeFieldName,
  getAdjustmentFormTypeField,
  selectAdjustmentFormTypeValidationSchema
} from './selectAdjustmentChangeTypeConfig';
import {
  AdjustmentFormButtonActions,
  TemporaryAdjustmentFormContext
} from '../AdjustmentFormModals';

const useStyles = makeStyles(
  theme => ({
    formDescription: {
      marginBottom: theme.spacing(2)
    }
  }),
  { name: 'SelectAdjustmentChangeTypeModal' }
);

export function SelectAdjustmentTypeFormModalButtons({
  onCancel,
  onSubmit
}: AdjustmentFormButtonActions) {
  return (
    <>
      <Button
        key="selectAdjustmentChangeTypeCancel"
        color="secondary"
        size="small"
        onClick={onCancel}
        data-testid="selectAdjustmentChangeTypeCancelButton"
      >
        Cancel
      </Button>

      <Button
        key="selectAdjustmentChangeTypeSubmit"
        color="primary"
        size="small"
        onClick={onSubmit}
        data-testid="selectAdjustmentChangeTypeSubmitButton"
      >
        Next
      </Button>
    </>
  );
}

interface SelectAdjustmentChangeTypeModalProps {
  onSubmit: (type: AdjustmentFormType) => void;
}

export default function SelectAdjustmentChangeTypeModal({
  onSubmit
}: SelectAdjustmentChangeTypeModalProps) {
  const classes = useStyles();
  const formRef = useRef<FormApiWithRestart>();

  const { isOpen, setIsOpen } = useContext(TemporaryAdjustmentFormContext);

  const handleClose = () => {
    setIsOpen?.(false);
  };

  const handleSubmitButtonClick = () => {
    formRef.current?.submit();
  };

  const handleSubmit = (formValues: FormValues) => {
    const type = formValues[adjustmentFormTypeFieldName] as AdjustmentFormType;
    onSubmit(type);
  };

  return (
    <Modal
      title="Edit Schedule"
      testId="selectAdjustmentTypeModal"
      open={isOpen || false}
      onClose={handleClose}
      modalActions={
        <SelectAdjustmentTypeFormModalButtons
          onCancel={handleClose}
          onSubmit={handleSubmitButtonClick}
        />
      }
    >
      <Form
        onSubmit={handleSubmit}
        validationFunc={yupSchemaToValidationFunc(
          selectAdjustmentFormTypeValidationSchema
        )}
        goBackToPreviousPage={false}
      >
        {({ form }) => {
          formRef.current = form;
          return (
            <>
              <Typography className={classes.formDescription}>
                What type of change would you like to make?
              </Typography>
              <Field field={getAdjustmentFormTypeField()} />
            </>
          );
        }}
      </Form>
    </Modal>
  );
}
