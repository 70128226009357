/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["formRef"] }] */
import { IconButton, makeStyles } from '@material-ui/core';
import React, { useContext, useRef } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from 'components/Button';
import { renderErrorOrLoading } from 'components/Crud';
import {
  ConditionalField,
  Field,
  Form,
  FormValues,
  Radio,
  yupSchemaToValidationFunc
} from 'components/Forms';
import TimeTableSelect from 'components/Forms/TimetableSelect';
import Modal, { useModalStyles } from 'components/Modal';
import Typography from 'components/Typography';
import { DateTime } from 'luxon';
import {
  AdjustmentChangeType,
  AdjustmentPriority,
  AdjustmentType,
  DayOfWeek,
  OldShiftAdjustmentReasonToNewShiftAdjustmentReasonMap,
  Pattern,
  WeeklyAdjustmentPatternType
} from 'models/Adjustment';
import ShiftPattern, { OptionalShiftPatternDay } from 'models/ShiftPattern';
import { useDispatch } from 'react-redux';
import { usePatchesState } from 'store/selectors';
import { mergeErrors } from 'utils/utils';
import { EngineerCalendarContext } from 'views/Engineers/EngineerCalendar/EngineerCalendar';
import { adjustmentsSlice } from 'views/Engineers/store';
import { FormApiWithRestart } from 'components/Forms/Form';
import ErrorDisplay from 'components/ErrorDisplay';
import { TemporaryAdjustmentConstants } from 'views/Engineers/utils';
import { FormSpy } from 'react-final-form';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { AdjustmentFormCommonFieldName } from './adjustmentFormCommonConfig';
import { useAdjustmentFormStyles } from './FormStyles';
import {
  getDayEndFieldName,
  getDayStartFieldName,
  ShiftAdjustmentFormFieldName,
  shiftFormCommonFields,
  shiftFormEfficiencyChangeFields,
  shiftFormFieldsValidationSchema,
  shiftFormInitialValues,
  shiftFormPatchChangeFields,
  shiftFormWeekdayFields
} from './shiftChangeFormConfig';
import {
  AdjustmentFormButtonActions,
  TemporaryAdjustmentFormContext,
  TemporaryAdjustmentFormModalProps
} from '../AdjustmentFormModals';
import { formSpyRelevantDays, useFormDaysOfWeek } from './useFormDaysOfWeek';

const useStyles = makeStyles(
  {
    previousButton: {
      marginRight: 'auto'
    }
  },
  { name: 'TemporaryShiftChangeModal' }
);

export function TemporaryShiftFormModalButtons({
  onPrevious,
  onCancel,
  onSubmit,
  showPrevious
}: AdjustmentFormButtonActions) {
  const classes = useStyles();

  return (
    <>
      {showPrevious ? (
        <Button
          key="shiftChangeModalPreviousButton"
          color="secondary"
          size="small"
          onClick={onPrevious}
          data-testid="shiftChangeModalPreviousButton"
          className={classes.previousButton}
        >
          Previous
        </Button>
      ) : null}

      <Button
        key="shiftChangeModalCancelButton"
        color="secondary"
        size="small"
        onClick={onCancel}
        data-testid="shiftChangeModalCancelButton"
      >
        Cancel
      </Button>

      <Button
        key="shiftChangeModalSubmitButton"
        color="primary"
        size="small"
        onClick={onSubmit}
        data-testid="shiftChangeModalSubmitButton"
      >
        Save
      </Button>
    </>
  );
}

export default function TemporaryShiftAdjustmentModal({
  onPrevious,
  onDelete
}: TemporaryAdjustmentFormModalProps) {
  const [formDates, setValueFormSpy, formDaysOfWeek] = useFormDaysOfWeek();

  const { engineerData } = useContext(EngineerCalendarContext);
  const { adjustment: adjustmentToEdit, isOpen, setIsOpen } = useContext(
    TemporaryAdjustmentFormContext
  );
  const dispatch = useDispatch();
  const patchesState = usePatchesState();
  const classes = useAdjustmentFormStyles();
  const modalClasses = useModalStyles();
  const formRef = useRef<FormApiWithRestart>();

  const flags = useFlags();

  const handleClose = () => {
    setIsOpen?.(false);
  };

  const handleSubmitButtonClick = () => {
    formRef.current?.submit();
  };

  const shiftChangeFormSubmitHandler = (fieldValues: FormValues) => {
    const patternType: WeeklyAdjustmentPatternType = {
      type: Pattern.Weekly,
      weekdays: []
    };

    let shiftPatternDays: { [x: string]: OptionalShiftPatternDay }[] = [];

    Object.keys(DayOfWeek).forEach(day => {
      const parsedDayStartFieldName = fieldValues[getDayStartFieldName(day)];
      const parsedDayEndFieldName = fieldValues[getDayEndFieldName(day)];

      const isWorkingDay =
        parsedDayStartFieldName !== TemporaryAdjustmentConstants.NotWorking &&
        parsedDayEndFieldName !== TemporaryAdjustmentConstants.NotWorking;

      if (parsedDayStartFieldName !== TemporaryAdjustmentConstants.Empty) {
        patternType.weekdays = [
          ...patternType.weekdays,
          day.toUpperCase() as DayOfWeek
        ];
        shiftPatternDays = [
          ...shiftPatternDays,
          {
            [day.toUpperCase()]: isWorkingDay
              ? {
                  startTime: parsedDayStartFieldName as string,
                  endTime: parsedDayEndFieldName as string
                }
              : null
          }
        ];
      }
    });

    const shiftPatternToObject = shiftPatternDays.reduce(
      (prev, curr) => ({ ...prev, ...curr }),
      {} as ShiftPattern
    );

    let changes: AdjustmentChangeType[] = [];
    changes = [
      ...changes,
      {
        field: AdjustmentType.Shift,
        change: shiftPatternToObject
      }
    ];

    if (fieldValues[ShiftAdjustmentFormFieldName.AddPatch] === 'true') {
      changes = [
        ...changes,
        {
          field: AdjustmentType.Patch,
          change: fieldValues[
            ShiftAdjustmentFormFieldName.PatchChange
          ] as string
        }
      ];
    }

    if (fieldValues[ShiftAdjustmentFormFieldName.AddEfficiency] === 'true') {
      changes = [
        ...changes,
        {
          field: AdjustmentType.Efficiency,
          change: Number(
            fieldValues[ShiftAdjustmentFormFieldName.EfficiencyChange]
          )
        }
      ];
    }

    const adjustment = {
      reason: fieldValues[AdjustmentFormCommonFieldName.Reason] as string,
      startDate: DateTime.fromISO(
        fieldValues[AdjustmentFormCommonFieldName.ValidFrom] as string
      ).toISODate(),
      endDate: DateTime.fromISO(
        fieldValues[AdjustmentFormCommonFieldName.ValidTo] as string
      ).toISODate(),
      pattern: patternType,
      priority: AdjustmentPriority.Temporary,
      changes
    };

    dispatch(
      !adjustmentToEdit
        ? adjustmentsSlice.actions.add(adjustment, engineerData?.id)
        : adjustmentsSlice.actions.edit(
            adjustment,
            adjustmentToEdit?.id,
            engineerData?.id
          )
    );

    handleClose();
  };

  const [
    patchAdjustmentRadioField,
    patchAdjustmentNewPatchField
  ] = shiftFormPatchChangeFields(patchesState.items);

  const [
    efficiencyAdjustmentRadioField,
    efficiencyAdjustmentNewEfficiencyField
  ] = shiftFormEfficiencyChangeFields;

  const getFieldsForDay = (day: string) => {
    return shiftFormWeekdayFields()[day];
  };

  const initialFormValues = shiftFormInitialValues(adjustmentToEdit);

  return (
    renderErrorOrLoading(
      patchesState.isLoading,
      'editEngineerSpinner',
      mergeErrors([patchesState.error])
    ) || (
      <Modal
        title="Shift Pattern Change"
        testId="shiftAdjustmentFormModal"
        open={isOpen || false}
        onClose={handleClose}
        modalActions={
          <TemporaryShiftFormModalButtons
            onCancel={handleClose}
            onSubmit={handleSubmitButtonClick}
            onPrevious={onPrevious}
            showPrevious={adjustmentToEdit == undefined}
          />
        }
        additionalButtons={
          adjustmentToEdit ? (
            <IconButton
              aria-label="delete"
              onClick={onDelete}
              className={modalClasses.button}
              data-testid="deleteShiftAdjustmentIconButton"
            >
              <DeleteIcon />
            </IconButton>
          ) : null
        }
      >
        <Form
          initialValues={
            !flags?.aegisEnableNewAppointmentAndAdjustmentReasons
              ? initialFormValues
              : {
                  ...initialFormValues,
                  [AdjustmentFormCommonFieldName.Reason]:
                    OldShiftAdjustmentReasonToNewShiftAdjustmentReasonMap[
                      initialFormValues[AdjustmentFormCommonFieldName.Reason]
                    ] ?? initialFormValues[AdjustmentFormCommonFieldName.Reason]
                }
          }
          validationFunc={yupSchemaToValidationFunc(
            shiftFormFieldsValidationSchema
          )}
          onSubmit={shiftChangeFormSubmitHandler}
          goBackToPreviousPage={false}
        >
          {({ form }) => {
            formRef.current = form;
            const formState = form.getState();
            return (
              <>
                <>
                  {shiftFormCommonFields(
                    flags?.aegisEnableNewAppointmentAndAdjustmentReasons
                  ).map(field => (
                    <Field key={field.id} field={field} />
                  ))}
                </>
                <Typography variant="h2" className={classes.typography}>
                  New shift pattern
                </Typography>
                {formState.submitFailed &&
                formState.errors?.atLeastOneFilled ? (
                  <div className={classes.allEmptyErrorContainer}>
                    <ErrorDisplay
                      status={{
                        message: formState.errors?.atLeastOneFilled
                      }}
                    />
                  </div>
                ) : null}
                <div data-testid="shiftWeekdaySelectsWrapper">
                  {formDaysOfWeek.map(day => (
                    <div
                      className={classes.weekdaysWrapper}
                      key={`timetableWrapper-${day}`}
                    >
                      <Typography
                        data-testid={`shiftAdjustmentSelector${day}`}
                        key={day}
                      >
                        {day}
                      </Typography>
                      <div className={classes.weekdaySelectWrapper}>
                        {getFieldsForDay(day).map(field =>
                          field.conditional ? (
                            <ConditionalField
                              key={`conditionalField-${field.id}`}
                              when={field.conditional?.when}
                              matchesPredicate={
                                field.conditional?.matchesPredicate
                              }
                            >
                              <TimeTableSelect
                                key={field.id}
                                field={{
                                  ...field,
                                  className: classes.weekdaySelect
                                }}
                              />
                            </ConditionalField>
                          ) : (
                            <TimeTableSelect
                              key={field.id}
                              field={{
                                ...field,
                                className: classes.weekdaySelect
                              }}
                            />
                          )
                        )}
                      </div>
                    </div>
                  ))}
                </div>
                <FormSpy
                  subscription={{
                    values: true
                  }}
                >
                  {({ values }) => {
                    return formSpyRelevantDays(
                      values,
                      formDates,
                      setValueFormSpy
                    );
                  }}
                </FormSpy>
                <Radio field={patchAdjustmentRadioField} />
                <ConditionalField
                  when={patchAdjustmentRadioField.name}
                  is="true"
                >
                  <Field field={patchAdjustmentNewPatchField} />
                </ConditionalField>
                <Radio field={efficiencyAdjustmentRadioField} />
                <ConditionalField
                  when={efficiencyAdjustmentRadioField.name}
                  is="true"
                >
                  <Field field={efficiencyAdjustmentNewEfficiencyField} />
                </ConditionalField>
              </>
            );
          }}
        </Form>
      </Modal>
    )
  );
}
