import { makeStyles, createStyles } from '@material-ui/core';
import { BUTTON_MIN_HEIGHT } from 'styles/constants';

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'left'
    },
    paper: {
      width: theme.containers.small,
      padding: theme.spacing(3, 2),
      marginTop: theme.spacing(5),
      backgroundColor: theme.palette.common.white
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1)
    },
    loadingWrapper: {
      position: 'relative',
      minHeight: BUTTON_MIN_HEIGHT
    },
    spinnerWrapper: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12
    },
    addButton: {
      margin: 0
    },
    addButtonContainer: {
      width: 'auto'
    },
    addButtonLink: {
      textDecoration: 'none'
    },
    readTitle: {
      display: 'inline-block'
    },
    profileBackground: {
      backgroundColor: theme.palette.secondary.light
    },
    listContainer: {
      padding: theme.spacing(9, 4),
      scrollMargin: `${theme.spacing(13)}px`
    },
    pageTitleContainer: {
      flexGrow: 1,
      paddingRight: theme.spacing(3)
    },
    readContainer: {
      flexDirection: 'column',
      alignItems: 'center',
      padding: theme.spacing(4, 4, 4, 4)
    },
    formContainer: {
      padding: theme.spacing(6)
    },
    searchButton: {
      margin: theme.spacing(0, 2, 0, 0)
    },
    clearSearchButton: {
      margin: theme.spacing(0)
    },
    searchInput: {
      maxWidth: '100%'
    },
    gridParentContainer: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  })
);

export default useStyles;
