import React, { ReactElement } from 'react';
import { Field } from 'react-final-form';

interface ConditionalFieldProps {
  when: string;
  is?: string | boolean;
  isIn?: (string | boolean)[];
  isNotEmpty?: boolean;
  matchesPredicate?: (value: string) => boolean;
  children: ReactElement;
}

const ConditionalField = ({
  when,
  is,
  isIn,
  isNotEmpty,
  matchesPredicate,
  children
}: ConditionalFieldProps) => (
  <Field name={when} subscription={{ value: true }}>
    {({ input: { value } }) => {
      let showChildren = false;
      if (matchesPredicate) {
        showChildren = matchesPredicate(value);
      }
      if (isNotEmpty) {
        showChildren = value !== '';
      } else if (is) {
        showChildren = value === is;
      } else if (isIn) {
        showChildren = isIn.includes(value);
      }
      return showChildren ? children : null;
    }}
  </Field>
);

export default ConditionalField;
