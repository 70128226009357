import React, { useState, useEffect, useRef } from 'react';
import { MuiThemeProvider, createTheme } from '@material-ui/core';
import { FormValues } from 'components/Forms';
import { useDispatch } from 'react-redux';
import Modal from 'components/Modal';
import Form from 'components/Forms/Form';
import { SecondaryButton, SubmitButton } from 'components/Forms/Buttons';
import defaultTheme, { modalFormInputOverrides } from 'styles/defaultTheme';
import Skill from 'models/Skills';
import { FormApi } from 'final-form';
import { useSkillCategoriesData } from 'store/selectors';
import { fetchAllSkillCategories } from 'store/slices/skillCategories';
import { fetchSkills, newAddSkill, newEditSkill } from 'store/slices/skills';
import {
  getSkillsInputFieldsProps,
  newInitialFormValidation
} from './FormConfig';

export enum SkillModalMode {
  ADD = 'add',
  EDIT = 'edit'
}

interface SkillModalProps {
  open: boolean;
  closeModal: () => void;
  cancel: () => void;
  currentSkill?: Skill;
}

export default function SkillModal({
  open,
  closeModal,
  cancel,
  currentSkill
}: SkillModalProps) {
  const dispatch = useDispatch();
  const mode = currentSkill ? SkillModalMode.EDIT : SkillModalMode.ADD;
  const [success, setSuccess] = useState(false);
  const skillCategoriesData = useSkillCategoriesData();

  const formRef = useRef<FormApi>();
  // We are dispatching the thunks directly here, because we couldn't test the snackbars with getDomainSlice
  useEffect(() => {
    dispatch(fetchAllSkillCategories());
  }, []);

  useEffect(() => {
    if (success) {
      dispatch(fetchSkills());
    }
  }, [success]);

  const addApiCallback = (values: FormValues) => {
    closeModal();
    dispatch(newAddSkill(values));
  };

  const editApiCallback = (values: FormValues) => {
    closeModal();
    if (currentSkill) {
      dispatch(
        newEditSkill(
          { name: values.name, categoryId: values.category },
          currentSkill.id
        )
      );
    }
  };

  return (
    <Modal
      title={mode === SkillModalMode.ADD ? 'Add Skill' : 'Edit Skill'}
      testId="addEditSkillModal"
      open={open}
      onClose={cancel}
      modalActions={
        <>
          <SecondaryButton
            onClick={() => cancel()}
            testId="addEditSkillModalCancel"
          >
            Cancel
          </SecondaryButton>
          <SubmitButton
            onClick={() => formRef.current?.submit()}
            testId="addEditSkillModalSubmit"
          >
            Save
          </SubmitButton>
        </>
      }
    >
      <MuiThemeProvider
        theme={createTheme({
          ...defaultTheme,
          overrides: { ...defaultTheme.overrides, ...modalFormInputOverrides }
        })}
      >
        <Form
          validationFunc={newInitialFormValidation}
          fields={getSkillsInputFieldsProps(mode, skillCategoriesData)}
          initialValues={
            currentSkill
              ? ({
                  category: currentSkill.category.id,
                  name: currentSkill.name
                } as FormValues)
              : undefined
          }
          onSubmit={
            mode === SkillModalMode.ADD ? addApiCallback : editApiCallback
          }
          goBackToPreviousPage={false}
        >
          {({ form, submitSucceeded, submitErrors }) => {
            setSuccess(!submitErrors && submitSucceeded);

            formRef.current = form;
          }}
        </Form>
      </MuiThemeProvider>
    </Modal>
  );
}
