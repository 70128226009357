import React, { HTMLAttributes, memo } from 'react';
import { List, ListItem, makeStyles } from '@material-ui/core';
import {
  EngineerSchedule,
  JobDetailsFields,
  ScheduleAssignmentEvent
} from 'models/Schedule';
import EngineerRow from 'components/Schedule/EngineerRow/EngineerRow';
import { OnAssignmentAddCallback } from 'views/Schedule/ScheduleView';
import { AppointmentSlot } from 'models/Appointment';
import UnavailableEngineerRow from '../UnavailableEngineerRow/UnavailableEngineerRow';
import { updateEngineerAssignments } from '../scheduleHelpers';

export type EngineerJobCapacity = 0 | 1 | 2 | 3 | 4 | 5;
export type DragHoverCallback = (engineerId?: string) => void;

interface AssignmentUpdate {
  appointmentId: string;
}

export interface EngineerAssignmentsUpdate {
  engineerId: string;
  assignments: AssignmentUpdate[];
}

interface EngineerListProps extends HTMLAttributes<HTMLUListElement> {
  engineersSchedules: EngineerSchedule[];
  selectedJobCardDetails: JobDetailsFields;
  selectedEngineer?: string;
  onAssignmentAdd: OnAssignmentAddCallback;
  onDragHover: DragHoverCallback;
  onJobCardSelect: (slot: AppointmentSlot) => void;
  handleJobCardOnClick: (jobDetails: JobDetailsFields) => void;
  onPublish?: (engineerId: string) => void;
}

interface EngineerListItemProps extends HTMLAttributes<HTMLLIElement> {
  engineerSchedule: EngineerSchedule;
  selectedJobCardDetails: JobDetailsFields;
  selectedEngineer?: string;
  onAssignmentAdd: OnAssignmentAddCallback;
  onDragHover: DragHoverCallback;
  onJobCardSelect: (slot: AppointmentSlot) => void;
  handleJobCardOnClick: (jobDetails: JobDetailsFields) => void;
  onPublish?: (engineerId: string) => void;
}

const useStyles = makeStyles(
  theme => ({
    engineerList: {
      padding: 0
    },
    engineerSection: {
      '&:not(:first-child)': {
        marginTop: theme.spacing(2)
      }
    },
    engineerListItem: {
      padding: 0,
      borderRadius: theme.spacing(0.5)
    },
    engineerListItemRow: {
      display: 'flex',
      width: '100%'
    },
    jobWrapper: {
      flexGrow: 1
    }
  }),
  { name: 'EngineerList' }
);

// Tracks if an engineer assignments edit is being fired
let isUpdating = false;

function EngineerListItem({
  engineerSchedule,
  selectedJobCardDetails,
  selectedEngineer,
  onDragHover,
  onPublish,
  onJobCardSelect,
  handleJobCardOnClick,
  onAssignmentAdd
}: EngineerListItemProps) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    setIsLoading(false);
  }, [engineerSchedule]);

  const isEngineerAvailable = engineerSchedule.unavailableReason === null;

  const onAssignmentChange = (event: ScheduleAssignmentEvent) => {
    if (isUpdating) {
      return;
    }

    updateEngineerAssignments(event, onAssignmentAdd);

    isUpdating = true;
  };

  return (
    <ListItem
      data-testid={`engineerListItem_${engineerSchedule.engineer.id}`}
      className={classes.engineerListItem}
    >
      <div className={classes.engineerListItemRow}>
        {isEngineerAvailable && (
          <EngineerRow
            isLoading={isLoading}
            engineerSchedule={engineerSchedule}
            onChange={onAssignmentChange}
            onDragHover={onDragHover}
            onPublish={onPublish}
            onJobCardSelect={onJobCardSelect}
            handleJobCardOnClick={handleJobCardOnClick}
            selectedJobCardDetails={selectedJobCardDetails}
            selectedEngineer={selectedEngineer}
          />
        )}
        {!isEngineerAvailable && (
          <UnavailableEngineerRow
            engineerSchedule={engineerSchedule}
            onChange={onAssignmentChange}
            onDragHover={onDragHover}
            onJobCardSelect={onJobCardSelect}
            handleJobCardOnClick={handleJobCardOnClick}
            selectedJobCardDetails={selectedJobCardDetails}
          />
        )}
      </div>
    </ListItem>
  );
}

function EngineerList({
  engineersSchedules,
  selectedJobCardDetails,
  selectedEngineer,
  onAssignmentAdd,
  onDragHover,
  onJobCardSelect,
  handleJobCardOnClick,
  onPublish
}: EngineerListProps) {
  const classes = useStyles();

  React.useEffect(() => {
    isUpdating = false;
  }, [engineersSchedules]);

  if (engineersSchedules === null || engineersSchedules.length === 0) {
    return null;
  }

  return (
    <List className={classes.engineerList}>
      {engineersSchedules.map(engineerSchedule => (
        <EngineerListItem
          key={engineerSchedule.engineer.id}
          engineerSchedule={engineerSchedule}
          className={classes.engineerListItem}
          onAssignmentAdd={onAssignmentAdd}
          onDragHover={onDragHover}
          onJobCardSelect={onJobCardSelect}
          handleJobCardOnClick={handleJobCardOnClick}
          onPublish={onPublish}
          selectedJobCardDetails={selectedJobCardDetails}
          selectedEngineer={selectedEngineer}
        />
      ))}
    </List>
  );
}

export default memo(EngineerList);
