import { AppThunk } from 'store';
import { RootState } from 'store/store';
import http from 'api/http';
import { StateSlice } from 'store/slices';
import { FORM_ERROR } from 'final-form';

import {
  ActionCreatorWithPayload,
  ActionCreatorWithoutPayload
} from '@reduxjs/toolkit';
import { Domain, DomainItem } from 'models/Domain';
import { setSnackbar, SnackbarStatus } from 'store/slices/snackbar';

import {
  FetchThunkOptions,
  AddItemThunkOptions,
  DeleteItemThunkOptions,
  EditItemThunkOptions,
  AddGroupItemThunkOptions
} from './types';
import { createUrl, paramsSerializer } from './utils';
import { handleThunkError } from './errors';
import { iteratePagination, getPaginatedApiData } from './pagination';

export function fetchThunk<T = StateSlice<Domain>>(
  options: FetchThunkOptions<T>
): AppThunk {
  const {
    domain,
    requestStart,
    requestFail,
    requestSuccess,
    parentDomainId,
    queryParams
  } = options;
  return async (dispatch, getState) => {
    const url = createUrl(domain, parentDomainId);
    const state = getState()[domain.plural as keyof RootState];

    const params = {
      ...queryParams,
      pageIndex:
        queryParams?.pageIndex ??
        (state as StateSlice<DomainItem>).page.pageIndex,
      pageSize:
        queryParams?.pageSize ?? (state as StateSlice<DomainItem>).page.pageSize
    };

    try {
      dispatch(requestStart());
      const { data } = await http.get<T>(url, {
        params,
        paramsSerializer
      });
      if (requestSuccess) {
        dispatch(requestSuccess(data));
      }
      return data;
    } catch (error) {
      handleThunkError({
        error,
        requestFail,
        dispatch,
        domainType: domain.type
      });

      return null;
    }
  };
}
export function fetchAllThunk<T = DomainItem>(
  domain: Domain,
  actions: {
    start: ActionCreatorWithoutPayload;
    success: ActionCreatorWithPayload<StateSlice<T>, string>;
    fail: ActionCreatorWithPayload<string, string>;
  }
): AppThunk {
  return async (dispatch, getState) => {
    try {
      dispatch(actions.start);

      const { page } = getState()[
        domain.plural as keyof RootState
      ] as StateSlice<T>;

      const items = await iteratePagination<T>(
        undefined,
        getPaginatedApiData(domain)
      );

      dispatch(
        actions.success({
          items,
          isLoading: false,
          error: null,
          page
        })
      );
    } catch (error) {
      handleThunkError({
        error,
        requestFail: actions.fail,
        dispatch,
        domainType: domain.type
      });
    }
  };
}

// Refactor techdebt ticket created https://ovotech.atlassian.net/browse/FFT-1081
export function fetchItemThunk<T>(
  domain: Domain,
  requestStart: ActionCreatorWithoutPayload<string>,
  requestFail: ActionCreatorWithPayload<string>,
  requestSuccess: ActionCreatorWithPayload<T>,
  id: string,
  parentDomainId?: string
): AppThunk {
  return async dispatch => {
    const url = createUrl(domain, parentDomainId, id);
    try {
      dispatch(requestStart());
      const { data } = await http.get<T>(url);
      dispatch(requestSuccess(data));
    } catch (error) {
      handleThunkError({
        error,
        requestFail,
        dispatch,
        domainType: domain.type
      });
    }
  };
}

export function addGroupItemThunk<DomainItem>(
  options: AddGroupItemThunkOptions<DomainItem>
): AppThunk {
  return async dispatch => {
    const {
      domain,
      requestStart,
      requestFail,
      requestSuccess,
      parentDomainId,
      adjustmentGroup
    } = options;
    try {
      dispatch(requestStart());
      const url = createUrl(domain, parentDomainId);
      const result = await http.post<DomainItem>(url, adjustmentGroup);

      const groupId = result.headers['resource-id'];
      const optionalGroupId = groupId
        ? {
            groupId
          }
        : {};

      const itemsWithGroupId = adjustmentGroup.adjustments.map(adjustment => {
        return {
          ...adjustment,
          groupId
        };
      });

      const itemWithId = {
        adjustments: itemsWithGroupId,
        ...optionalGroupId
      };

      if (requestSuccess) {
        dispatch(requestSuccess((itemWithId as unknown) as DomainItem));
      }

      dispatch(
        setSnackbar({
          message: 'Success: item successfully created',
          show: true,
          status: SnackbarStatus.Success
        })
      );

      return null;
    } catch (error) {
      const message = error.response?.data?.message || error.message;

      handleThunkError({
        error,
        requestFail,
        dispatch,
        domainType: domain.type
      });

      return { [FORM_ERROR]: message };
    }
  };
}

export function addItemThunk<DomainItem>(
  options: AddItemThunkOptions<DomainItem>
): AppThunk {
  return async dispatch => {
    const {
      domain,
      requestStart,
      requestFail,
      requestSuccess,
      parentDomainId,
      item,
      formatFormValues,
      successMessage = 'Success: item successfully created'
    } = options;
    try {
      dispatch(requestStart());
      const url = createUrl(domain, parentDomainId);
      const data = formatFormValues ? formatFormValues(item) : item;

      const result = await http.post<DomainItem>(url, data);

      const itemId = result.headers['resource-id'];
      const optionalId = itemId
        ? {
            id: itemId
          }
        : {};

      const itemWithId = {
        ...item,
        ...optionalId
      };

      if (requestSuccess) {
        dispatch(requestSuccess((itemWithId as unknown) as DomainItem));
      }

      dispatch(
        setSnackbar({
          message: successMessage,
          show: true,
          status: SnackbarStatus.Success
        })
      );

      return null;
    } catch (error) {
      const message = error.response?.data?.message || error.message;

      handleThunkError({
        error,
        requestFail,
        dispatch,
        domainType: domain.type
      });

      return { [FORM_ERROR]: message };
    }
  };
}

export function deleteItemThunk<T>(
  options: DeleteItemThunkOptions<T>
): AppThunk {
  const {
    domain,
    requestStart,
    requestFail,
    requestSuccess,
    parentDomainId,
    itemId,
    deleteMessage = 'Success: item successfully deleted'
  } = options;
  return async dispatch => {
    const url = createUrl(domain, parentDomainId, itemId);
    try {
      dispatch(requestStart());

      await http.delete<DomainItem>(url);

      dispatch(requestSuccess(itemId));
      dispatch(
        setSnackbar({
          message: deleteMessage,
          show: true,
          status: SnackbarStatus.Warning
        })
      );
    } catch (error) {
      handleThunkError({
        error,
        requestFail,
        dispatch,
        domainType: domain.type
      });
    }
  };
}

export function editItemThunk<DomainItem>(
  options: EditItemThunkOptions<DomainItem>
): AppThunk {
  return async dispatch => {
    const {
      domain,
      requestStart,
      requestFail,
      requestSuccess,
      parentDomainId,
      item,
      itemId,
      formatFormValues,
      successMessage = 'Success: item successfully edited'
    } = options;
    try {
      dispatch(requestStart());
      const url = createUrl(domain, parentDomainId, itemId);
      const data = formatFormValues ? formatFormValues(item) : item;

      await http.put<DomainItem>(url, data);

      const itemWithId = {
        ...item,
        id: itemId
      };

      if (requestSuccess) {
        dispatch(requestSuccess((itemWithId as unknown) as DomainItem));
      }
      dispatch(
        setSnackbar({
          message: successMessage,
          show: true,
          status: SnackbarStatus.Success
        })
      );

      return null;
    } catch (error) {
      handleThunkError({
        error,
        requestFail,
        dispatch,
        domainType: domain.type
      });

      return { [FORM_ERROR]: error.message };
    }
  };
}
