import React from 'react';
import { CircularProgress } from '@material-ui/core';
import { DomainItem } from 'models/Domain';
import ErrorDisplay from 'components/ErrorDisplay';
import { FormValues } from 'components/Forms';
import { CrudViewProps } from './types';
import { ErrorStatus } from '../../../utils/utils';

function determineFormValue(
  name: string,
  processDomainData?: CrudViewProps['processDomainData'],
  domainItemValues?: DomainItem
) {
  if (processDomainData) {
    return domainItemValues ? processDomainData(domainItemValues)[name] : '';
  }
  return domainItemValues ? domainItemValues[name as keyof DomainItem] : '';
}

// TODO - we're processing the response data twice here, we should reduce it. See https://ovotech.atlassian.net/browse/FFT-562
export const getInitialFieldValues = (
  fields: CrudViewProps['fields'],
  domainItemValues?: DomainItem,
  processDomainData?: CrudViewProps['processDomainData']
): FormValues =>
  fields.reduce((previousValues, { name, preprocess }) => {
    const value = determineFormValue(name, processDomainData, domainItemValues);

    return {
      ...previousValues,
      [name]: preprocess ? preprocess(value) : value
    };
  }, {});
// TODO - classes should be optional https://ovotech.atlassian.net/browse/FFT-841
export function renderErrorOrLoading(
  isLoading: boolean,
  dataQaId: string,
  errorStatus: ErrorStatus,
  {
    loadingWrapper,
    spinnerWrapper
  }: { loadingWrapper?: string; spinnerWrapper?: string } = {}
) {
  if (errorStatus) {
    return <ErrorDisplay status={errorStatus.status} />;
  }
  if (isLoading) {
    return (
      <div className={loadingWrapper}>
        <CircularProgress
          size={48}
          className={spinnerWrapper}
          data-qa-id={dataQaId}
          data-testid={dataQaId}
        />
      </div>
    );
  }
  return null;
}
