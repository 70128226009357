import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { engineerSummaryDomain, geographicAreaDomain } from 'models';
import { getDomainSlice } from 'store';
import CustomButton from 'components/Button';
import { CustomButtonProps } from 'components/Button/Button';
import {
  useGeographicAreasData,
  useGeographicAreaState
} from 'store/selectors';
import { fetchEngineerSummary } from 'store/endpoints/engineerSummary';
import {
  getErrorMessageAndStatus,
  displayErrorSnackbar,
  commonErrorHandling
} from 'store/utils';

const useStyles = makeStyles(theme => ({
  openScheduleButton: {
    color: theme.palette.aegis.colour.grey.darker,
    '&, &:hover': {
      borderColor: theme.palette.aegis.colour.grey.darker
    },
    '&:hover': {
      backgroundColor: theme.palette.aegis.colour.grey.lighter
    },
    '& .MuiTouchRipple-child': {
      backgroundColor: theme.palette.aegis.colour.grey.darker
    }
  }
}));

interface OpenScheduleButtonProps extends CustomButtonProps {
  scheduleDate: string;
  patch: string;
  assignedEngineerId?: string;
}

export default function OpenScheduleButton({
  scheduleDate,
  patch,
  assignedEngineerId,
  ...props
}: OpenScheduleButtonProps) {
  const [patchToFetch, setPatchToFetch] = useState<string | null>(patch);
  const classes = useStyles();
  const dispatch = useDispatch();
  const { actions } = getDomainSlice(geographicAreaDomain);
  const areas = useGeographicAreasData();
  const { isLoading, error } = useGeographicAreaState();
  const [isSummaryLoading, setIsSummaryLoading] = useState(false);

  useEffect(() => {
    if (assignedEngineerId) {
      const getSummary = async () => {
        setIsSummaryLoading(true);
        try {
          const response = await fetchEngineerSummary(
            assignedEngineerId,
            scheduleDate,
            scheduleDate
          );
          if (response.data[0].patch) {
            setPatchToFetch(response.data[0].patch);
          }
          setIsSummaryLoading(false);
        } catch (summaryError) {
          setIsSummaryLoading(false);
          const { message, status } = getErrorMessageAndStatus(summaryError);

          displayErrorSnackbar(message, status, dispatch);
          commonErrorHandling(summaryError, engineerSummaryDomain.type);
        }
      };
      getSummary();
    }
  }, []);

  useEffect(() => {
    dispatch(
      actions.get.list({
        patches: {
          operator: 'in',
          values: [patchToFetch]
        }
      })
    );
  }, [patchToFetch]);

  const handleOnClick = () => {
    window.open(
      `${window.location.origin}/dashboard/schedule?p=(date~*${scheduleDate}~areas~!*${areas[0].id})~`,
      '_blank'
    );
  };

  return (
    <CustomButton
      disabled={isLoading || !!error || isSummaryLoading || areas.length === 0}
      onClick={handleOnClick}
      {...props}
      className={`${classes.openScheduleButton} ${props.className}`}
      size="medium"
    >
      Open Schedule
    </CustomButton>
  );
}
