import React, { useState } from 'react';
import { TableCell } from '@material-ui/core';
import ListView from 'components/Crud/views/List';
import { skillDomain, engineerDomain } from 'models';
import { useSkillsData } from 'store/selectors';
import {
  ListTable,
  TableActions,
  useTableRowActionsState,
  useTableStyles
} from 'components/Crud/ListTable/ListTable';
import ListTableRow from 'components/Crud/ListTable/ListTableRow';
import Skill from 'models/Skills';
import { useDispatch } from 'react-redux';
import { getDomainSlice } from 'store';
import { useFlags } from 'launchdarkly-react-client-sdk';
import SkillModal from './SkillModal';
import OldSkillModal from './OldSkillModal';

const SkillsView = () => {
  const dispatch = useDispatch();
  const flags = useFlags();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentSkill, setCurrentSkill] = useState<Skill>();
  const skillsData = useSkillsData();
  const classes = useTableStyles({ containerSize: 'medium' });
  const { rowHovered, setRowHovered } = useTableRowActionsState();
  const { actions } = getDomainSlice(skillDomain);
  const columnsWidth = [480, 360];
  const openModal = () => setIsModalOpen(true);

  const openEditModal = (skill: Skill) => {
    openModal();
    setCurrentSkill(skill);
  };

  const cancelModal = () => {
    setIsModalOpen(false);
    setCurrentSkill(undefined);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setCurrentSkill(undefined);
  };

  const oldCloseModal = async () => {
    await dispatch(actions?.get.list());
    setIsModalOpen(false);
    setCurrentSkill(undefined);
  };

  if (!flags.aegisEnableNewEditAddSkillViews) {
    return (
      <ListView domain={skillDomain} showPageHeader={false}>
        {({ onEdit, onDelete }) => (
          <section className={classes.tableContainer}>
            <ListTable
              domain={skillDomain}
              domainItems={skillsData}
              columnsWidth={columnsWidth}
              setRowHovered={(row: number | boolean) => setRowHovered(row)}
              rowHovered={rowHovered}
              title={skillDomain.label}
              label={`A table of ${engineerDomain.label} ${skillDomain.plural}`}
            >
              {skillsData.map((skill, index) => (
                <ListTableRow
                  key={skill.id}
                  data-testid={`tableRow-${skill.id}`}
                >
                  <TableCell className={classes.tableCell}>
                    {skill.name}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {skill.category.name}
                  </TableCell>
                  {rowHovered !== index ? (
                    <TableCell className={classes.tableCell}>&nbsp;</TableCell>
                  ) : (
                    <TableActions
                      onEdit={onEdit}
                      onDelete={onDelete}
                      domain={skillDomain}
                      domainItem={skill}
                    />
                  )}
                </ListTableRow>
              ))}
            </ListTable>
          </section>
        )}
      </ListView>
    );
  }
  return (
    <>
      <ListView disableErrorDisplay domain={skillDomain} showPageHeader={false}>
        {({ onDelete }) => (
          <section className={classes.tableContainer}>
            <ListTable
              domain={skillDomain}
              domainItems={skillsData}
              columnsWidth={columnsWidth}
              setRowHovered={(row: number | boolean) => setRowHovered(row)}
              rowHovered={rowHovered}
              title={skillDomain.label}
              label={`A table of ${engineerDomain.label} ${skillDomain.plural}`}
              headerButtons={[
                {
                  label: 'New Skill',
                  openModal
                }
              ]}
            >
              {skillsData.map((skill, index) => (
                <ListTableRow
                  key={skill.id}
                  data-testid={`tableRow-${skill.id}`}
                >
                  <TableCell className={classes.tableCell}>
                    {skill.name}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {skill.category.name}
                  </TableCell>
                  {rowHovered !== index ? (
                    <TableCell className={classes.tableCell}>&nbsp;</TableCell>
                  ) : (
                    <TableActions
                      onEdit={() => () => openEditModal(skill)}
                      onDelete={onDelete}
                      domain={skillDomain}
                      domainItem={skill}
                    />
                  )}
                </ListTableRow>
              ))}
            </ListTable>
          </section>
        )}
      </ListView>
      {flags?.aegisEnableGenericModalForSkillsListPage ? (
        <SkillModal
          open={isModalOpen}
          closeModal={closeModal}
          currentSkill={currentSkill}
          cancel={cancelModal}
        />
      ) : (
        <OldSkillModal
          open={isModalOpen}
          closeModal={oldCloseModal}
          currentSkill={currentSkill}
          cancel={cancelModal}
        />
      )}
    </>
  );
};

export default SkillsView;
