import React from 'react';
import {
  Typography as MUITypography,
  makeStyles,
  Theme
} from '@material-ui/core';
import { Variant as MuiVariant } from '@material-ui/core/styles/createTypography';

export type Variant =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'bodyHeavy'
  | 'body'
  | 'micro'
  | 'srOnly';
export interface TypographyProps {
  variant?: Variant;
  className?: string;
  children: React.ReactNode;
  component?: React.ElementType;
  [key: string]: any;
}

const useStyles = makeStyles<Theme, {}, Variant | string>(theme => ({
  h1: theme.typography.h1,
  h2: theme.typography.h2,
  h3: theme.typography.h3,
  bodyHeavy: theme.typography.bodyHeavy,
  body: theme.typography.body,
  micro: theme.typography.micro
}));

export default function Typography({
  variant,
  children: text,
  className,
  ...props
}: TypographyProps) {
  const classes = useStyles();
  const onlyScreenReaders = 'srOnly';
  const classesKeys = [...Object.keys(classes), onlyScreenReaders];
  const typographyVariant = variant || 'body';

  if (!classesKeys.includes(typographyVariant)) {
    throw Error(
      `The \`<Typography />\` component only supports variants of ${classesKeys.join(
        ', '
      )}`
    );
  }

  const typographyComponentOptions = ['h1', 'h2', 'h3', onlyScreenReaders];
  const muiVariant = typographyComponentOptions.includes(variant as string)
    ? (variant as MuiVariant)
    : undefined;

  return (
    <MUITypography
      {...props}
      variant={muiVariant}
      className={
        className
          ? `${className} ${classes[typographyVariant]}`
          : classes[typographyVariant]
      }
    >
      {text}
    </MUITypography>
  );
}
