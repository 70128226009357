import React, { ReactNode } from 'react';
import { Form, FormProps } from 'react-final-form';
import { useHistory } from 'react-router-dom';
import { SubmissionErrors, FormApi } from 'final-form';

import ErrorDisplay from 'components/ErrorDisplay';
import { ConditionalField, Field } from 'components/Forms';
import { InputFieldProps, FormValues } from './utils';
import Radio from './Radio';

interface ChildProps {
  submitting: boolean;
  submitSucceeded: boolean;
  submitErrors: SubmissionErrors;
  dirty: boolean;
  form: FormApiWithRestart;
  values: FormValues;
}
interface FormComponentProps extends FormProps {
  validationFunc: (values: FormValues) => SubmissionErrors | undefined;
  onSubmit: (
    fieldValues: FormValues,
    form: FormApi
  ) => Promise<SubmissionErrors | undefined> | void;
  children?: (props: ChildProps) => ReactNode | void;
  autoReset?: boolean;
  goBackToPreviousPage?: boolean;
  fields?: InputFieldProps[];
  initialValues?: FormValues;
  ref?: any;
  className?: string;
}
// Adding this since polyfilling this missing type makes TS complain
// about everything else in the form render props 😩
export interface FormApiWithRestart extends FormApi<FormValues> {
  restart: () => void;
}

const FormComponent = ({
  validationFunc,
  onSubmit,
  children,
  fields,
  initialValues,
  goBackToPreviousPage = true,
  keepDirtyOnReinitialize,
  className
}: FormComponentProps) => {
  const history = useHistory();

  if (!children && !fields) {
    throw new Error(
      'FormComponent expects either children or a fields prop, but neither were passed.'
    );
  }
  return (
    <Form
      onSubmit={onSubmit}
      validate={validationFunc}
      validateOnBlur
      initialValues={initialValues}
      keepDirtyOnReinitialize={keepDirtyOnReinitialize}
      render={({
        submitting,
        handleSubmit,
        submitSucceeded,
        submitErrors,
        form,
        dirty,
        error,
        values
      }) => {
        if (submitSucceeded && goBackToPreviousPage) {
          // @ts-ignore
          setTimeout(() => history.goBack(), 1000);
        }
        return (
          <div>
            <form onSubmit={handleSubmit} className={className}>
              {submitErrors && (
                <ErrorDisplay
                  status={{
                    message: submitErrors.FORM_ERROR['FINAL_FORM/form-error']
                  }}
                />
              )}
              {error && (
                <ErrorDisplay
                  status={{
                    message: error
                  }}
                />
              )}
              {!fields
                ? null
                : fields.map(({ conditional, ...field }) => {
                    const { inputType, options } = field;

                    if (conditional) {
                      return (
                        <ConditionalField
                          key={`${conditional.when}-${field.id}`}
                          {...conditional}
                        >
                          <Field key={field.id} field={field} />
                        </ConditionalField>
                      );
                    }

                    if (inputType === 'radio' && options) {
                      const meta = form.getFieldState(field.name);

                      return <Radio key={field.id} {...{ field, meta }} />;
                    }

                    return <Field key={field.id} field={field} />;
                  })}
              {!children
                ? null
                : children({
                    submitting,
                    submitSucceeded,
                    submitErrors,
                    dirty,
                    form: form as FormApiWithRestart,
                    values
                  })}
            </form>
          </div>
        );
      }}
    />
  );
};

export default FormComponent;
