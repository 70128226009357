/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FormValues } from 'components/Forms';
import { adjustmentDomain, adjustmentGroupDomain } from 'models';
import Adjustment, { AdjustmentType } from 'models/Adjustment';
import AdjustmentGroup from 'models/AdjustmentGroup';
import { DomainItem } from 'models/Domain';
import { AppThunk } from 'store';
import { initialStateSlice, loadingFailed, startLoading } from 'store/slices';
import {
  addGroupItemThunk,
  addItemThunk,
  deleteItemThunk,
  editItemThunk,
  fetchThunk,
  QueryParams
} from 'store/utils/';
import { getPrimaryAdjustmentType } from 'utils/adjustments';

const adjustments = createSlice({
  name: adjustmentDomain.plural,
  initialState: initialStateSlice,
  reducers: {
    ...startLoading<Adjustment>(),
    ...loadingFailed<Adjustment>(),
    getAdjustmentSuccess(state, { payload }: PayloadAction<Adjustment[]>) {
      state.items = payload;
      state.isLoading = false;
      state.error = null;
    },
    addAdjustmentSuccess(
      state,
      { payload: addedAdjustment }: PayloadAction<Adjustment>
    ) {
      state.items.push(addedAdjustment);
      state.isLoading = false;
      state.error = null;
    },
    editAdjustmentSuccess(
      state,
      { payload: editedAdjustment }: PayloadAction<Adjustment>
    ) {
      state.items = state.items.map(adjustment =>
        adjustment.id === editedAdjustment.id ? editedAdjustment : adjustment
      );
      state.isLoading = false;
      state.error = null;
    },
    deleteAdjustmentSuccess(
      state,
      { payload: deletedAdjustmentId }: PayloadAction<string>
    ) {
      state.items = state.items.filter(
        adjustment => adjustment.id !== deletedAdjustmentId
      );
      state.isLoading = false;
      state.error = null;
    },
    addAdjustmentGroupSuccess(
      state,
      { payload: addedAdjustmentGroup }: PayloadAction<AdjustmentGroup>
    ) {
      state.items = [...state.items, ...addedAdjustmentGroup.adjustments];
      state.isLoading = false;
      state.error = null;
    },
    editAdjustmentGroupSuccess(
      state,
      { payload: editedAdjustmentGroup }: PayloadAction<AdjustmentGroup>
    ) {
      state.items = [
        ...state.items.filter(
          adjustment => adjustment.groupId !== editedAdjustmentGroup.id
        ),
        ...editedAdjustmentGroup.adjustments
      ];

      state.isLoading = false;
      state.error = null;
    },
    deleteAdjustmentGroupSuccess(
      state,
      { payload: deletedAdjustmentGroupId }: PayloadAction<string>
    ) {
      state.items = state.items.filter(
        adjustment => adjustment.groupId !== deletedAdjustmentGroupId
      );

      state.isLoading = false;
      state.error = null;
    }
  }
});

export const {
  requestStart,
  requestFail,
  getAdjustmentSuccess,
  addAdjustmentSuccess,
  editAdjustmentSuccess,
  deleteAdjustmentSuccess,
  addAdjustmentGroupSuccess,
  editAdjustmentGroupSuccess,
  deleteAdjustmentGroupSuccess
} = adjustments.actions;

export default adjustments.reducer;

export function fetchAdjustments(
  parentDomainId?: string,
  query?: QueryParams
  // A strange typescript issue with ThunkDispatch, I couldn't work out how to fix it without any
): AppThunk<any> {
  return fetchThunk<Adjustment[]>({
    queryParams: query,
    domain: adjustmentDomain,
    requestStart,
    requestFail,
    requestSuccess: getAdjustmentSuccess,
    parentDomainId
  });
}

export function addAdjustment(item: FormValues, parentId?: string): AppThunk {
  return addItemThunk<Adjustment>({
    domain: adjustmentDomain,
    requestStart,
    requestFail,
    requestSuccess: addAdjustmentSuccess,
    item,
    parentDomainId: parentId
  });
}

export function editAdjustment(
  item: FormValues,
  itemId: string,
  parentId?: string
): AppThunk {
  return editItemThunk<Adjustment>({
    domain: adjustmentDomain,
    requestStart,
    requestFail,
    requestSuccess: editAdjustmentSuccess,
    itemId,
    item,
    parentDomainId: parentId
  });
}

function deleteMessage(adjustment: Adjustment): string {
  const deleteMessages = {
    [AdjustmentType.Shift]: 'Temporary shift pattern change deleted',
    [AdjustmentType.Patch]: 'Temporary patch change deleted',
    [AdjustmentType.Efficiency]: 'Temporary efficiency change deleted',
    [AdjustmentType.Skills]: 'Temporary skills change deleted'
  };

  const adjustmentType = getPrimaryAdjustmentType(adjustment);

  return adjustmentType
    ? deleteMessages[adjustmentType]
    : 'Temporary change deleted';
}

export function deleteAdjustment(
  domainItem: DomainItem,
  parentId?: string
): AppThunk {
  return deleteItemThunk<Adjustment>({
    domain: adjustmentDomain,
    requestStart,
    requestFail,
    requestSuccess: deleteAdjustmentSuccess,
    itemId: domainItem.id,
    parentDomainId: parentId,
    deleteMessage: deleteMessage(domainItem as Adjustment)
  });
}

export function addAdjustmentGroup(
  adjustmentGroup: AdjustmentGroup,
  parentId?: string
): AppThunk {
  return addGroupItemThunk<AdjustmentGroup>({
    domain: adjustmentGroupDomain,
    requestStart,
    requestFail,
    requestSuccess: addAdjustmentGroupSuccess,
    adjustmentGroup,
    parentDomainId: parentId
  });
}

export function editAdjustmentGroup(
  item: FormValues,
  itemId: string,
  parentId: string
): AppThunk {
  return editItemThunk<AdjustmentGroup>({
    domain: adjustmentGroupDomain,
    requestStart,
    requestFail,
    requestSuccess: editAdjustmentGroupSuccess,
    itemId,
    parentDomainId: parentId,
    item
  });
}

export function deleteAdjustmentGroup(
  adjustment: Adjustment,
  parentId: string
): AppThunk {
  if (!adjustment?.groupId) {
    throw Error('Error - Unable to delete adjustment group with no group id');
  }
  return deleteItemThunk<AdjustmentGroup>({
    domain: adjustmentGroupDomain,
    requestStart,
    requestFail,
    requestSuccess: deleteAdjustmentGroupSuccess,
    itemId: adjustment.groupId,
    parentDomainId: parentId
  });
}
