import React, { useState } from 'react';

import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Container from '@material-ui/core/Container';
import MenuIcon from '@material-ui/icons/Menu';
import { Switch, Route, Link as RouterLink } from 'react-router-dom';
import Navbar from 'components/Navbar/Navbar';
import navigationSections, {
  NavigationRoute,
  NavigationSection
} from 'views/Dashboard/routes';
import { Account } from 'views/Account';
import Main from 'components/Main';
import { ToolbarMinHeight } from 'styles/types';
import Avatar from 'components/Avatar';
import profileIcon from 'assets/images/profile-icon.svg';
import BreadcrumbContainer from 'components/Breadcrumb/BreadcrumbContainer';
import Typography from 'components/Typography';
import { useFlags } from 'launchdarkly-react-client-sdk';
import IssueBanner, {
  EnabledBannerFlag
} from 'components/IssueBanner/IssueBanner';

const drawerWidth = 240;

const minTitleWidth = 140;
const minProfileLinkWidth = 48;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  toolbar: {
    paddingLeft: 0,
    paddingRight: 0,
    width: '100%',
    justifyContent: 'space-between',
    gap: theme.spacing(1)
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: theme.palette.common.white,
    color: theme.palette.aegis.semantic.message.black,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    borderRight: 'none',
    padding: `${
      (theme.mixins.toolbar[theme.breakpoints.up('sm')] as ToolbarMinHeight)
        .minHeight
    }px 0 0 0`,
    [theme.breakpoints.only('xs')]: {
      padding: `${theme.mixins.toolbar.minHeight}px 0 0 0`
    },
    backgroundColor: theme.palette.aegis.semantic.message.black,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
    scrollbarWidth: 'none',
    '-ms-overflow-style': 'none',
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: 0,
    [theme.breakpoints.up('sm')]: {
      width: 0
    }
  },
  container: {
    padding: 0,
    height: '100%'
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    flexShrink: 1,
    minWidth: `${minTitleWidth}px`
  },
  profileButton: {
    padding: 0
  },
  profileLink: {
    flexShrink: 0,
    marginRight: theme.spacing(1),
    minWidth: `${minProfileLinkWidth}px`
  },
  appBarTitle: {
    padding: theme.spacing(1.5, 0)
  },
  issueBanner: {
    flexShrink: 0,
    maxWidth: `calc(100vw - ${minTitleWidth +
      minProfileLinkWidth +
      theme.spacing(2)}px)`
  }
}));

const DashboardRoutes = () => {
  const flags = useFlags();
  const dashboardRoutes = navigationSections(
    flags.aegisEnableGenericModalForJobTypeListPage,
    flags.aegisEnableNewEngineerModal,
    flags.aegisEnableHideCapacityPage
  ).reduce((routes: NavigationRoute[], currentSection: NavigationSection) => {
    return [...routes, ...currentSection.routes];
  }, []);

  const dashboardSubRoutes = dashboardRoutes.reduce(
    (prev, { routes }) =>
      routes && !!routes.length ? prev.concat(routes) : prev,
    [] as NavigationRoute[]
  );

  return (
    <Switch>
      {/* These render prop callbacks will be replaced with view components */}
      {[...dashboardRoutes, ...dashboardSubRoutes].map(
        ({ to, ...routeProps }) => (
          <Route key={to} exact path={to} {...routeProps} />
        )
      )}
      <Route path="/dashboard/account" component={Account} />
      <Route render={() => <p>Not found!</p>} />
    </Switch>
  );
};

export default function Dashboard() {
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const { aegisEnableIncidentBanner: incidentBanner } = useFlags();

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="absolute" className={classes.appBar} elevation={3}>
        <Toolbar className={classes.toolbar}>
          <div className={classes.titleContainer}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={() => setOpen(!open)}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              variant="h1"
              data-qa-id="dashboardPageTitle"
              className={classes.appBarTitle}
            >
              Aegis
            </Typography>
            <BreadcrumbContainer />
          </div>
          {incidentBanner?.enabled ? (
            <IssueBanner
              className={classes.issueBanner}
              {...(incidentBanner as EnabledBannerFlag)}
            />
          ) : null}
          <RouterLink to="/dashboard/account" className={classes.profileLink}>
            <IconButton classes={{ root: classes.profileButton }}>
              <Avatar
                src={profileIcon}
                alt="default profile picture"
                size={4}
              />
            </IconButton>
          </RouterLink>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose)
        }}
        open={open}
      >
        <Navbar />
      </Drawer>
      <Main>
        <Container maxWidth={false} className={classes.container}>
          <DashboardRoutes />
        </Container>
      </Main>
    </div>
  );
}
