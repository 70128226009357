import { DomainItem } from 'models/Domain';
import Skill from 'models/Skills';
import Team from 'models/Team';
import Patch from 'models/Patches';
import ShiftPattern from 'models/ShiftPattern';
import Engineer from 'models/Engineers';
import {
  GeographicAreaParent,
  GeographicAreaChild
} from 'models/GeographicArea';
import { FieldInputValue, FormValues, FormValue } from './types';

type DataMap = {
  [key: string]: any;
};

type DomainDataMap<T = DomainItem> = {
  [key: string]: (item: T) => FormValue | T | T[] | Skill[];
};

// This function is used to map request data onto Form fields. Add a dataMap to override defaults
export function mapRequestDataToField(maps?: DataMap) {
  return (item: DomainItem): FieldInputValue => {
    const { id } = item;
    const name = 'name' in item ? item.name : undefined;
    const defaultData = { value: id, label: name };
    return maps
      ? Object.keys(maps).reduce<FieldInputValue>((acc, curr) => {
          acc[curr] = maps[curr](item);
          return acc;
        }, defaultData)
      : defaultData;
  };
}

export function mapResponseDataToField<T extends {} = DomainItem>(
  maps: DomainDataMap<T>,
  item: T
): FormValues {
  const entries = Object.entries(item).map(([key, value]) => {
    return [key, key in maps ? maps[key](item) : value];
  });
  return Object.fromEntries(entries);
}

export const mapSkillsToAutocompleteValues = (skills: Skill[]) =>
  skills &&
  skills.map(({ id, name, category }: Skill) => ({
    label: `${name}, ${category.name}`,
    value: id,
    category
  }));

export const patchDataToSelect = mapRequestDataToField({
  label: (patch: Patch) => patch.name
});

export const shiftPatternDataToSelect = mapRequestDataToField({
  label: (shiftPattern: ShiftPattern) => shiftPattern.name
});

export const teamDataToSelect = mapRequestDataToField({
  label: (team: Team) => team.name
});

export const mapEngineerToAutocompleteValues = ({
  id,
  firstName,
  lastName
}: Engineer) => ({
  label: `${firstName} ${lastName}`,
  value: id
});

export const mapEngineersToAutocompleteValues = (engineers: Engineer[]) =>
  engineers &&
  engineers.filter(item => item).map(mapEngineerToAutocompleteValues);

export const mapGeographicAreasChildren = ({
  id,
  name,
  parentName
}: GeographicAreaChild): FieldInputValue => ({
  label: name,
  value: id,
  other: parentName
});

export const mapGeographicAreasChildrenToAutocompleteValues = (
  list: GeographicAreaParent[]
): FieldInputValue[] =>
  list &&
  list.flatMap(parent => {
    return parent.children.map(mapGeographicAreasChildren);
  });
