import http from 'api/http';
import { appointmentDomain } from 'models';
import { AbortCustomerAppointmentRequest } from 'models/Appointment';
import { CloseAppointmentResponse } from 'models/AbortCategory';
import { AxiosResponse } from 'axios';

/**
 * Sends an `AbortCustomerAppointmentRequest` to the close appointment endpoint
 * @param {string} jobId - The UUID for the job being closed
 * @param {AbortCustomerAppointmentRequest} cancelDetails - An object containing the category, reason, whether
 * the job can be rebooked and any comments
 */
export async function patchCloseJob(
  jobId: string,
  cancelDetails: AbortCustomerAppointmentRequest
): Promise<AxiosResponse<CloseAppointmentResponse>> {
  return http.patch<CloseAppointmentResponse>(
    `${appointmentDomain.apiPath}/${jobId}/close`,
    cancelDetails
  );
}
