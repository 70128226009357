import React from 'react';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { MuiThemeProvider, createTheme } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { getDomainSlice } from 'store';
import {
  yupSchemaToValidationFunc,
  Form,
  FormValues,
  Buttons
} from 'components/Forms';
import { CrudViewProps, formSubmitHandler } from 'components/Crud/utils';
import useStyles from 'components/Crud/styles';
import Snackbars from 'components/Alerts';
import Typography from 'components/Typography';
import defaultTheme, { modalFormInputOverrides } from 'styles/defaultTheme';

function WrappedCreate({ children }: { children: React.ReactNode }) {
  return (
    <MuiThemeProvider
      theme={createTheme({
        ...defaultTheme,
        overrides: {
          ...defaultTheme.overrides,
          ...modalFormInputOverrides
        }
      })}
    >
      {children}
    </MuiThemeProvider>
  );
}

const CreateView = ({
  domain,
  fields,
  title = `New ${domain.type}`,
  validationSchema,
  presubmitCallback
}: CrudViewProps) => {
  // Hooks must be called before early returns
  const classes = useStyles();
  const dispatch = useDispatch();

  const { actions } = getDomainSlice(domain);

  if (fields == null) {
    return null;
  }

  const validationFunc = yupSchemaToValidationFunc(validationSchema);
  const apiCallback = (data: FormValues) => dispatch(actions.add(data));

  return (
    <>
      <Grid container className={classes.formContainer}>
        {/* TECH DEBT: FSP-101 - Create page component - https://ovotech.atlassian.net/browse/FSP-101 */}
        <Grid item xs={12}>
          <Typography variant="h1" data-qa-id="createDomainPageTitle">
            {title}
          </Typography>
          <Paper className={`${classes.root} ${classes.paper}`}>
            <WrappedCreate>
              <Form
                validationFunc={validationFunc}
                fields={fields}
                onSubmit={formSubmitHandler(apiCallback, presubmitCallback)}
              >
                {({ submitting, submitSucceeded, submitErrors }) => (
                  <Buttons
                    isSubmitting={submitting}
                    success={!submitErrors && submitSucceeded}
                  />
                )}
              </Form>
            </WrappedCreate>
          </Paper>
        </Grid>
      </Grid>
      <Snackbars />
    </>
  );
};

export default CreateView;
