import React, { ReactElement } from 'react';
import { Grid, Theme, makeStyles } from '@material-ui/core';
import { Field } from 'components/Forms';
import { Palette, PaletteColor } from '@material-ui/core/styles/createPalette';
import { Field as FinalFormField, useForm } from 'react-final-form';
import Typography from 'components/Typography';
import { InputFieldProps } from './utils';

const useStyles = makeStyles(
  (theme: Theme & { palette: Palette & { fieldForce: PaletteColor } }) => ({
    root: {
      marginTop: theme.spacing(1)
    },
    dayLabel: {
      color: theme.palette.fieldForce.main
    }
  })
);

export const startTimeField = (
  type: 'schedule',
  day: string,
  disabled?: boolean
): InputFieldProps => ({
  qaId: `${type}AdjustmentsStartTime${day}`,
  inputType: 'time',
  name: `startTime${day}`,
  id: `startTime${day}`,
  label: 'Start time',
  autoComplete: 'startTime',
  disabled
});

export const endTimeField = (
  type: 'schedule',
  day: string,
  disabled?: boolean
): InputFieldProps => ({
  qaId: `${type}AdjustmentsEndTime${day}`,
  inputType: 'time',
  name: `endTime${day}`,
  id: `endTime${day}`,
  label: 'End time',
  autoComplete: 'endTime',
  disabled
});

const checkBoxField = (
  type: 'schedule',
  day: string,
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void
): InputFieldProps => ({
  qaId: `${type}AdjustmentUnavailable${day}`,
  inputType: 'checkbox',
  name: `checkBox${day}`,
  id: `checkBox${day}`,
  label: `Not working on ${day}`,
  type: 'checkbox',
  handleChange
});

const DisabledCondition = ({
  when,
  is,
  children
}: {
  when: string;
  is: boolean;
  children: (disabled: boolean) => ReactElement<any>;
}) => (
  <FinalFormField name={when} subscription={{ value: true }}>
    {({ input: { value } }: { input: { value: boolean } }) =>
      value === is ? children(true) : children(false)
    }
  </FinalFormField>
);

const TimeField = ({ label, ...props }: { label: string }) => {
  const classes = useStyles();
  const form = useForm();

  return (
    <Grid spacing={1} container className={classes.root} {...props}>
      <Grid item xs={12}>
        <Typography className={classes.dayLabel} data-testid="timefield-label">
          {label}
        </Typography>
      </Grid>
      <DisabledCondition when={`checkBox${label}`} is>
        {(disabled: boolean) => (
          <>
            <Grid item xs={6}>
              <Field
                key={`${label}-start`}
                field={startTimeField('schedule', label, disabled)}
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                key={`${label}-end`}
                field={endTimeField('schedule', label, disabled)}
              />
            </Grid>
          </>
        )}
      </DisabledCondition>

      <Field
        key={`${label}-checkbox`}
        field={checkBoxField('schedule', label, (event: any) => {
          if (event.target.checked) {
            form.change(`endTime${label}`, undefined);
            form.change(`startTime${label}`, undefined);
          }
        })}
      />
    </Grid>
  );
};

export default TimeField;
