/* eslint-disable no-param-reassign */
import { AppThunk } from 'store';
import { JobAlertsForDay, Alert } from 'models/Dashboard';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { handleThunkError } from 'store/utils';
import http from 'api/http';
import { scheduleDomain } from 'models';
import { DateTime } from 'luxon';
import { DATE_ISO_SHORT } from 'constants/dates';
import { initialStateSlice, loadingFailed, startLoading, StateSlice } from '.';

const dashboard = createSlice({
  name: 'dashboard',
  initialState: initialStateSlice as StateSlice<JobAlertsForDay>,
  reducers: {
    ...startLoading<JobAlertsForDay>(),
    ...loadingFailed<JobAlertsForDay>(),
    // TODO: Abstract these out to be generic functions
    getJobAlertsSuccess(state, { payload }: PayloadAction<JobAlertsForDay[]>) {
      state.items = payload;
      state.isLoading = false;
      state.error = null;
    }
  }
});

export const {
  requestStart,
  requestFail,
  getJobAlertsSuccess
} = dashboard.actions;

export const getFormattedDate = (daysAfterToday: number, format: string) => {
  return DateTime.now()
    .plus({ days: daysAfterToday })
    .toFormat(format);
};

export const fetchJobAlerts = (): AppThunk => {
  return async dispatch => {
    try {
      dispatch(requestStart());

      const dates = [
        getFormattedDate(0, DATE_ISO_SHORT),
        getFormattedDate(1, DATE_ISO_SHORT),
        getFormattedDate(2, DATE_ISO_SHORT)
      ];

      const alerts = await Promise.all(
        dates.map(async date => {
          const { data } = await http.get<Alert[]>(
            `${scheduleDomain.apiPath}/alerts`,
            {
              params: {
                startDate: date,
                endDate: date
              }
            }
          );
          return data;
        })
      );

      const jobAlertDays: JobAlertsForDay[] = [
        {
          day: 'Today',
          date: dates[0],
          alerts: alerts[0]
        },
        {
          day: 'Tomorrow',
          date: dates[1],
          alerts: alerts[1]
        },
        {
          day: getFormattedDate(2, 'EEEE'),
          date: dates[2],
          alerts: alerts[2]
        }
      ];

      dispatch(getJobAlertsSuccess(jobAlertDays));

      return jobAlertDays;
    } catch (error) {
      handleThunkError({ error, requestFail, dispatch });
      return null;
    }
  };
};
export default dashboard.reducer;
