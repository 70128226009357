/* eslint-disable no-param-reassign */
import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit';
import { DomainItem } from 'models/Domain';
import { AppThunk, RootState } from 'store/store';
import { FormValues } from 'components/Forms';
import Appointment, {
  AbortCustomerAppointmentRequest,
  AppointmentCategory,
  AppointmentResponse,
  CreateCustomerAppointmentRequestSmart,
  CreateCustomerAppointmentRequestEV,
  BookingChannel
} from 'models/Appointment';
import {
  initialStateSlice,
  StateSlice,
  startLoading,
  loadingFailed,
  getPaginationReducers
} from 'store/slices';
import {
  appointmentDomain,
  engineerAssignedToJobDomain,
  engineerDomain
} from 'models';

import {
  createFilterQuery,
  QueryParams,
  deleteItemThunk,
  addItemThunk,
  editItemThunk,
  fetchItemThunk,
  createUrl,
  paramsSerializer,
  PaginationOptions,
  handleThunkError,
  commonErrorHandling
} from 'store/utils';
import http from 'api/http';
import Engineer, { EngineersAssigned } from 'models/Engineers';
// Changed to a direct import here, because otherwise importing `SortByOrder` from 'components/Crud' will cause cyclical dependency
// issues with `jest.mock` in test files for various components
import { SortByOptions, SortByOrder } from 'components/Crud/utils/types';
import { DateTime } from 'luxon';
import { AnyAction } from 'redux';
import { onlyUnique } from 'utils/utils';
import { Page } from 'models/Page';
import { getPaginatedApiData, iteratePagination } from 'store/utils/pagination';
import { PostcodeValidationResponse } from 'models/Postcode';
import { setSnackbar, SnackbarStatus } from './snackbar';

const appointments = createSlice({
  name: appointmentDomain.plural,
  initialState: initialStateSlice,
  reducers: {
    ...startLoading<Appointment>(),
    ...loadingFailed<Appointment>(),
    ...getPaginationReducers<Appointment>(),
    getAppointmentsSuccess(
      state,
      { payload }: PayloadAction<StateSlice<Appointment>>
    ) {
      const { items, page } = payload;

      state.items = items;
      state.page = page;
      state.isLoading = false;
      state.error = null;
    },
    getAppointmentSuccess(
      state,
      { payload: appointmentItem }: PayloadAction<Appointment>
    ) {
      if (state.items != null) {
        state.items = state.items.filter(
          appointment => appointment.id !== appointmentItem.id
        );
        state.items.push(appointmentItem);
      }
      state.isLoading = false;
      state.error = null;
    },
    addAppointmentSuccess(
      state,
      { payload: createdAppointment }: PayloadAction<Appointment>
    ) {
      if (state.items != null && createdAppointment != null) {
        state.items.push(createdAppointment);
      }

      state.isLoading = false;
      state.error = null;
    },
    editAppointmentSuccess(
      state,
      { payload: editedAppointment }: PayloadAction<Appointment>
    ) {
      if (state.items != null) {
        state.items = state.items.filter(
          appointment => appointment.id !== editedAppointment.id
        );
        state.items.push(editedAppointment);
      }

      state.isLoading = false;
      state.error = null;
    },
    deleteAppointmentSuccess(
      state,
      { payload: deletedAppointmentId }: PayloadAction<string>
    ) {
      if (state.items != null) {
        state.items = state.items.filter(
          appointment => appointment.id !== deletedAppointmentId
        );
      }

      state.isLoading = false;
      state.error = null;
    }
  }
});

export default appointments.reducer;

export const {
  requestStart,
  requestFail,
  setPagination: setAppointmentsPagination,
  getAppointmentsSuccess,
  getAppointmentSuccess,
  addAppointmentSuccess,
  editAppointmentSuccess,
  deleteAppointmentSuccess
} = appointments.actions;

function getEngineersPageByIds(params: Record<string, any>, body: any) {
  return http.post<Page<Engineer>>(`${engineerDomain.apiPath}/by-ids`, body, {
    params,
    paramsSerializer
  });
}

export async function fetchAssignedEngineer(
  engineerIds: string[]
): Promise<Engineer[]> {
  return iteratePagination<Engineer>(
    undefined,
    getEngineersPageByIds,
    engineerIds
  );
}

async function fetchAppointmentEngineerIds(
  appointmentsData: AppointmentResponse[],
  dispatch: Dispatch<AnyAction>
): Promise<AppointmentResponse[]> {
  const modifiedAppointmentsData = [...appointmentsData];

  await Promise.allSettled(
    modifiedAppointmentsData.map(async appointment => {
      try {
        const response = await http.get<EngineersAssigned>(
          createUrl(engineerAssignedToJobDomain, appointment.id)
        );
        appointment.engineers = response.data.engineers;
      } catch (error) {
        // If the response status is 404 we want to display the job as unassigned
        if (error?.response?.status === 404) {
          appointment.engineers = [];
        } else {
          handleThunkError({
            error,
            requestFail,
            dispatch,
            domainType: engineerAssignedToJobDomain.type
          });
        }
      }
    })
  );
  return modifiedAppointmentsData;
}

async function fetchAppointmentEngineerData(
  engineerIds: string[]
): Promise<Engineer[]> {
  return fetchAssignedEngineer(engineerIds);
}

// TODO: Requires https://ovotech.atlassian.net/browse/FFT-612 handle non-paginated results
export function fetchAllEngineerFixedAppointments(
  engineerId: string
): AppThunk<Promise<StateSlice<Appointment> | null>> {
  const listEngineerAppointmentsFilter: QueryParams = {
    category: {
      values: [AppointmentCategory.MEETING],
      operator: '='
    },
    engineers: { values: [engineerId], operator: 'in' }
  };

  return async dispatch => {
    const params: Record<string, any> = {
      params: createFilterQuery(listEngineerAppointmentsFilter),
      pageIndex: 0,
      pageSize: 25,
      sortBy: `date:${SortByOrder.DESCENDING}`
    };

    try {
      dispatch(requestStart());

      const appointmentsData = await iteratePagination<AppointmentResponse>(
        params,
        getPaginatedApiData(appointmentDomain)
      );

      const appointmentsWithFormattedEngineerIds = appointmentsData.map(
        app => ({
          ...app,
          engineers: app.engineers.map(id => ({ id }))
        })
      );

      const data = {
        page: {
          pageIndex: params.pageIndex,
          pageSize: params.pageSize,
          totalItems: appointmentsData.length,
          totalPages: Math.ceil(appointmentsData.length / params.pageSize)
        },
        isLoading: false,
        error: null,
        items: appointmentsWithFormattedEngineerIds
      };

      dispatch(getAppointmentsSuccess(data));

      return data;
    } catch (error) {
      handleThunkError({
        error,
        requestFail,
        dispatch,
        domainType: appointmentDomain.type,
        displayError: false
      });

      return null;
    }
  };
}

// TODO: Requires https://ovotech.atlassian.net/browse/FFT-612 handle non-paginated results
export function fetchAppointments(
  query?: QueryParams,
  paginationOptions?: PaginationOptions<Appointment>,
  parentId?: string,
  sortBy?: SortByOptions
): AppThunk {
  const queryParams: Record<string, any> | undefined = query
    ? {
        params: createFilterQuery(query),
        ...paginationOptions
      }
    : paginationOptions;

  return async (dispatch, getState) => {
    const appointmentsUrl = createUrl(appointmentDomain);
    const rootState = getState();
    const appointmentsState =
      rootState[appointmentDomain.plural as keyof RootState];

    let params: Record<string, any> = {
      ...queryParams,
      pageIndex:
        paginationOptions?.pageIndex ??
        (appointmentsState as StateSlice<Appointment>).page.pageIndex,
      pageSize:
        paginationOptions?.pageSize ??
        (appointmentsState as StateSlice<Appointment>).page.pageSize
    };

    if (sortBy) {
      params = {
        ...params,
        sortBy: `${sortBy.field}:${sortBy.order}`
      };
    }

    try {
      dispatch(requestStart());

      const appointmentsResponse = await http.get<
        StateSlice<AppointmentResponse>
      >(appointmentsUrl, {
        params,
        paramsSerializer
      });

      const {
        data: { items: appointmentsData }
      } = appointmentsResponse;

      let engineersData: (Engineer | null)[];
      const appointmentsDataWithEngineerIds = await fetchAppointmentEngineerIds(
        appointmentsData,
        dispatch
      );

      const engineerIds = appointmentsDataWithEngineerIds.flatMap(
        ap => ap.engineers
      );

      if (engineerIds.length !== 0) {
        engineersData = await fetchAppointmentEngineerData(
          onlyUnique(engineerIds)
        );
      } else {
        engineersData = [];
      }

      // Take the original appointments response and hydrate the engineer objects
      const appointmentsWithHydratedEngineers = appointmentsDataWithEngineerIds.map(
        appointment => {
          // Look through the engineer ids and find a matching engineer id from an engineer request
          const hydratedEngineers = appointment.engineers.flatMap(
            engineerId => {
              const matchedEngineer = engineersData.find(
                engineer => engineer?.id === engineerId
              );

              if (!matchedEngineer) {
                // Should this throw an error? If the id isn't found then surely it's junk?
                return [{ id: engineerId }];
              }

              const { firstName, lastName } = matchedEngineer;

              return [
                {
                  id: engineerId,
                  firstName,
                  lastName
                }
              ];
            }
          );

          return {
            ...appointment,
            engineers: hydratedEngineers
          };
        }
      );

      const data = {
        ...appointmentsResponse?.data,
        items: appointmentsWithHydratedEngineers
      };

      dispatch(getAppointmentsSuccess(data));

      return data;
    } catch (error) {
      handleThunkError({
        error,
        requestFail,
        dispatch,
        domainType: appointmentDomain.type,
        displayError: false
      });

      return null;
    }
  };
}

export function fetchAppointment(id: string): AppThunk {
  return fetchItemThunk<Appointment>(
    appointmentDomain,
    requestStart,
    requestFail,
    getAppointmentSuccess,
    id
  );
}

// Fallback validation before request sent - form validation doesn't consistently catch invalid slots
// Can't type 'item' as FormValues because that type doesn't have nested fields e.g. startTime
function checkAppointmentSlotEndIsAfterStart(item: any) {
  if (item.slot?.startTime && item.slot?.endTime) {
    const { startTime, endTime } = item.slot;
    return (
      DateTime.fromISO(`2000-01-01T${endTime}Z`) >
      DateTime.fromISO(`2000-01-01T${startTime}Z`)
    );
  }
  return true;
}

// This seems strange but if we leave the fields as they are, we will end up sending
// really large unnecessary fields in the request that the backend will ignore.
function removeFields(item: FormValues): FormValues {
  const alteredItem = { ...item };
  if (alteredItem.auditEntries) {
    alteredItem.auditEntries = null;
  }
  if (alteredItem.jobTypeData) {
    alteredItem.jobTypeData = null;
  }
  if (alteredItem.previousAbortData) {
    alteredItem.previousAbortData = null;
  }
  return alteredItem;
}

function dispatchInvalidTimeslotError(): AppThunk {
  return async dispatch => {
    handleThunkError({
      error: Error('Timeslot invalid'),
      requestFail,
      dispatch,
      domainType: appointmentDomain.type,
      overrideMessageText: 'Appointment Start Time must be before End Time'
    });
  };
}

export function addAppointment(item: FormValues): AppThunk {
  if (checkAppointmentSlotEndIsAfterStart(item)) {
    return addItemThunk<Appointment>({
      domain: appointmentDomain,
      requestStart,
      requestFail,
      item
    });
  }
  return dispatchInvalidTimeslotError();
}

export async function validatePostcode(
  postcodeForValidation: string
): Promise<boolean> {
  const url = `postcode/validate`;

  try {
    const { data: postcodeValidationData } = await http.get<
      PostcodeValidationResponse
    >(url, {
      params: {
        postcode: postcodeForValidation
      },
      paramsSerializer
    });

    if (!postcodeValidationData || !postcodeValidationData.valid) {
      return false;
    }
    return true;
  } catch (error) {
    commonErrorHandling(error, appointmentDomain.type);
    return false;
  }
}

export function createCustomerAppointment(
  appointment:
    | CreateCustomerAppointmentRequestSmart
    | CreateCustomerAppointmentRequestEV
): AppThunk {
  return async dispatch => {
    try {
      const url = createUrl(appointmentDomain);
      dispatch(requestStart());
      if (appointment.address.postcode) {
        const isPostcodeValid = await validatePostcode(
          appointment.address.postcode
        );

        if (!isPostcodeValid) {
          dispatch(
            setSnackbar({
              title: 'Error',
              message: 'The postcode is invalid.',
              show: true,
              status: SnackbarStatus.Error
            })
          );
          return false;
        }
      }
      const appointmentWithBookingChannel = {
        ...appointment,
        bookingChannel: BookingChannel.AEGIS
      };
      const response = await http.post<DomainItem>(
        url,
        appointmentWithBookingChannel
      );
      const jobId = response.headers['resource-id'];

      dispatch(
        setSnackbar({
          message: 'Job created.',
          show: true,
          status: SnackbarStatus.Success,
          redirectAction: {
            title: 'View job',
            url: `dashboard/jobs/${jobId}`,
            newTab: true
          }
        })
      );

      return true;
    } catch (error) {
      dispatch(requestFail(error.message));
      handleThunkError({
        error,
        requestFail,
        dispatch,
        domainType: appointmentDomain.type,
        overrideMessageText: 'Unable to create job.'
      });

      return false;
    }
  };
}

export function abortCustomerAppointment(
  appointmentId: string,
  appointmentAbort: AbortCustomerAppointmentRequest
): AppThunk {
  return async dispatch => {
    try {
      await http.patch(
        `${appointmentDomain.apiPath}/${appointmentId}/abort`,
        appointmentAbort
      );

      dispatch(
        setSnackbar({
          message: 'Job aborted',
          show: true,
          status: SnackbarStatus.Info
        })
      );

      return null;
    } catch (error) {
      handleThunkError({
        error,
        requestFail,
        dispatch,
        domainType: appointmentDomain.type,
        overrideMessageText: 'Unable to abort job.'
      });

      return null;
    }
  };
}

export function editAppointment(item: FormValues, itemId: string): AppThunk {
  if (checkAppointmentSlotEndIsAfterStart(item)) {
    return editItemThunk<Appointment>({
      domain: appointmentDomain,
      requestStart,
      requestFail,
      requestSuccess: editAppointmentSuccess,
      itemId,
      item: removeFields(item)
    });
  }
  return dispatchInvalidTimeslotError();
}

export function patchAppointment(
  { slot, notes }: FormValues,
  appointmentId: string
): AppThunk {
  return async dispatch => {
    try {
      await http.patch(`/${appointmentDomain.apiPath}/${appointmentId}`, {
        slot,
        notes
      });

      dispatch(
        setSnackbar({
          message: 'Success: item successfully edited',
          show: true,
          status: SnackbarStatus.Success
        })
      );

      return null;
    } catch (error) {
      handleThunkError({
        error,
        requestFail,
        dispatch,
        domainType: appointmentDomain.type,
        overrideMessageText: 'Unable to update job.'
      });

      return null;
    }
  };
}

export function deleteAppointment(
  domainItem: DomainItem,
  parentId?: string
): AppThunk {
  return deleteItemThunk<Appointment>({
    domain: appointmentDomain,
    requestStart,
    requestFail,
    requestSuccess: deleteAppointmentSuccess,
    itemId: domainItem.id,
    parentDomainId: parentId
  });
}

export function setAppointmentAtRisk(
  appointmentId: string,
  atRisk: boolean,
  onSuccess: () => void
): AppThunk {
  return async dispatch => {
    try {
      await http.patch(
        `${appointmentDomain.apiPath}/${appointmentId}/at-risk/${atRisk}`
      );

      dispatch(
        setSnackbar({
          message: atRisk
            ? 'At Risk flag added successfully'
            : 'At Risk flag removed successfully',
          show: true,
          status: SnackbarStatus.Success
        })
      );

      onSuccess();

      return null;
    } catch (error) {
      handleThunkError({
        error,
        requestFail,
        dispatch,
        domainType: appointmentDomain.type,
        overrideMessageText: 'Unable to change At Risk flag'
      });

      return null;
    }
  };
}
