import React, { useState } from 'react';
import ListView from 'components/Crud/views/List';
import { engineerDomain } from 'models';
import { QueryParams } from 'store/utils';
import { useEngineersState } from 'store/selectors';
import { TableCell } from '@material-ui/core';
import { getDomainSlice } from 'store';
import {
  useTableStyles,
  useTableRowActionsState,
  ListTable,
  TableActions
} from 'components/Crud/ListTable/ListTable';
import ListTableRow from 'components/Crud/ListTable/ListTableRow';
import FilterSearch, {
  FilterValue
} from 'components/FilterSearch/FilterSearch';
import useFilterSearchChips from 'utils/custom-hooks/useFilterSearchChips';
import { getFilterQueryParams } from 'utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Engineer from 'models/Engineers';
import { engineerFilters } from './FormConfig';
import EngineersModal from './EngineersModal/EngineersModal';

const columnsWidth = [240, 160, 180, 160, 180];
const headers = ['Name', 'Company', 'Team', 'Postcode', 'Phone'];

const EngineersView = () => {
  const tableClasses = useTableStyles();
  const { rowHovered, setRowHovered } = useTableRowActionsState();

  const { items: engineersData } = useEngineersState();
  const dispatch = useDispatch();

  const { actions: engineerActions, selector } = getDomainSlice(engineerDomain);
  const {
    page: { pageIndex, pageSize }
  } = useSelector(selector);

  const onSearch = (params: QueryParams) =>
    dispatch(engineerActions.get.list(params, { pageIndex, pageSize }));

  const onLoad = (filterValues: FilterValue[], searchWithFilters: () => void) =>
    React.useEffect(() => {
      if (filterValues.length > 0) {
        searchWithFilters();
      }
    }, [filterValues]);

  const {
    params,
    filterValues,
    appliedFilters,
    onFilterRemove,
    onFilterValueInput
  } = useFilterSearchChips(engineerFilters, onSearch, onLoad);

  const flags = useFlags();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentEngineer, setCurrentEngineer] = useState<Engineer>();

  const openModal = () => setIsModalOpen(true);

  const cancelModal = () => {
    setIsModalOpen(false);
    setCurrentEngineer(undefined);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setCurrentEngineer(undefined);
  };

  return (
    <>
      <ListView
        domain={engineerDomain}
        showPageHeader={false}
        dispatchProps={[params]}
        autoLoad={Boolean(filterValues.length === 0)}
      >
        {({ onEdit, onDelete, onView }) => (
          <section className={tableClasses.tableContainer}>
            <ListTable
              domain={engineerDomain}
              domainItems={engineersData}
              columnsWidth={columnsWidth}
              setRowHovered={(row: number | boolean) => setRowHovered(row)}
              rowHovered={rowHovered}
              title={engineerDomain.label}
              label={`A table of ${engineerDomain.plural}`}
              headers={headers}
              filter={getFilterQueryParams(appliedFilters)}
              headerComponent={
                <FilterSearch
                  filters={engineerFilters}
                  filterValues={appliedFilters}
                  onFilterRemove={onFilterRemove}
                  onFilterValueInput={onFilterValueInput}
                />
              }
              headerButtons={
                flags.aegisEnableNewEngineerModal
                  ? [
                      {
                        label: `New ${engineerDomain.singular}`,
                        openModal
                      }
                    ]
                  : undefined
              }
            >
              {engineersData.map((engineer, index) => (
                <ListTableRow
                  key={engineer.id}
                  data-testid={`tableRow-${engineer.id}`}
                  onClick={onView(engineer)}
                >
                  <TableCell
                    className={tableClasses.tableCell}
                    data-testid={`tableCell-${engineer.id}-name`}
                  >
                    {`${engineer.firstName} ${engineer.lastName}`}
                  </TableCell>
                  <TableCell
                    className={tableClasses.tableCell}
                    data-testid={`tableCell-${engineer.id}-company`}
                  >
                    {engineer.company}
                  </TableCell>
                  <TableCell
                    className={tableClasses.tableCell}
                    data-testid={`tableCell-${engineer.id}-team`}
                  >
                    {engineer.team?.name}
                  </TableCell>
                  <TableCell
                    className={tableClasses.tableCell}
                    data-testid={`tableCell-${engineer.id}-postcode`}
                  >
                    {engineer.postcode}
                  </TableCell>
                  <TableCell
                    className={tableClasses.tableCell}
                    data-testid={`tableCell-${engineer.id}-phoneNumber`}
                  >
                    {engineer.phoneNumber}
                  </TableCell>
                  {rowHovered !== index ? (
                    <TableCell className={tableClasses.tableCell}>
                      &nbsp;
                    </TableCell>
                  ) : (
                    <TableActions
                      onEdit={onEdit}
                      onDelete={onDelete}
                      domain={engineerDomain}
                      domainItem={engineer}
                    />
                  )}
                </ListTableRow>
              ))}
            </ListTable>
          </section>
        )}
      </ListView>
      {flags?.aegisEnableNewEngineerModal ? (
        <EngineersModal
          open={isModalOpen}
          closeModal={closeModal}
          currentEngineer={currentEngineer}
          cancel={cancelModal}
        />
      ) : null}
    </>
  );
};

export default EngineersView;
