import React, { memo } from 'react';
import {
  FormControlLabel,
  Checkbox as MUICheckbox,
  FormHelperText
} from '@material-ui/core';

const Checkbox = ({ checked, onChange, field, meta, ...props }: any) => {
  const helperText = meta.touched && meta.error ? meta.error : field.helperText;
  const id = field.id || field.name;
  const testId = field.qaId || id;

  return (
    <>
      <FormControlLabel
        label={field.label}
        control={
          <MUICheckbox
            id={id}
            name={field.name}
            color="primary"
            inputProps={{ 'aria-label': 'primary checkbox' }}
            size="small"
            checked={checked}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              if (field.handleChange) {
                field.handleChange(event);
              }
              onChange(event);
            }}
            data-testid={testId}
            {...props}
          />
        }
      />
      <FormHelperText>{helperText}</FormHelperText>
    </>
  );
};

export default memo(Checkbox);
