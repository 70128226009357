import { alpha } from '@material-ui/core';
import createSpacing from '@material-ui/core/styles/createSpacing';
import semanticColours, { colours } from './colours';
import typography from './typography';
import { BUTTON_MIN_HEIGHT } from './constants';

const {
  brand,
  grey,
  white,
  green,
  red,
  teal,
  blue,
  orange,
  yellow,
  purple,
  pink
} = colours;
const {
  error,
  warning,
  info,
  mission,
  message,
  border,
  background
} = semanticColours;

/*
  For our colour pallette to MUI colour palette mapping we are using the following by default:
  | MUI palette | Our palette |
  |-------------|-------------|
  |    dark     |    alt      |
  |    main     |    base     |
  |    light    |    muted    |
  | translucent | translucent | <- New property
*/
const containers = {
  small: 640,
  medium: 960,
  large: 1104,
  max: '100%'
};

const spacing = createSpacing(8);
const appBarMinHeight = 60;

const theme = {
  mixins: {
    toolbar: {
      '@media (min-width:0px) and (orientation: landscape)': {
        minHeight: appBarMinHeight
      },
      '@media (min-width:600px)': {
        minHeight: appBarMinHeight
      },
      minHeight: appBarMinHeight
    }
  },
  palette: {
    // Custom property so colours are the same structure as in Figma
    aegis: {
      colour: {
        brand,
        grey,
        green,
        red,
        orange,
        yellow,
        teal,
        blue,
        purple,
        pink
      },
      semantic: semanticColours
    },
    common: {
      black: grey.darkest,
      white: white.base
    },
    primary: {
      light: green.lightest,
      main: brand.dark,
      dark: green.darker,
      contrastText: white.base
    },
    // No secondary colours in Figma, assumed the same keys with new palette colours (some of these don't make sense)
    secondary: {
      light: grey.base,
      main: teal.base,
      dark: error.base
    },
    // No success colours in Figma, assumed the same keys with new palette colours
    success: {
      light: green.lightest,
      main: green.light,
      dark: green.base
    },
    error: {
      light: error.muted,
      main: red.dark,
      dark: error.alt
    },
    warning: {
      light: warning.muted,
      main: warning.base,
      dark: warning.alt
    },
    info: {
      light: info.muted,
      main: info.base,
      dark: info.alt
    },
    // QUESTION: Can we remove the associated feature?
    entech: {
      main: '#03a9f4',
      avatar: {
        main: alpha(grey.darkest, 0.15),
        text: alpha(grey.darkest, 0.87)
      }
    },
    // QUESTION: Can we remove the associated feature?
    fieldForce: {
      main: '#00695c',
      avatar: {
        main: alpha(grey.darkest, 0.15)
      }
    },
    // Assumed the same key for hint with new palette colours
    text: {
      primary: message.black,
      secondary: message.dark,
      disabled: message.light,
      hint: grey.dark
    },
    // Assumed the same key with new palette colours
    divider: border.light,
    // Assumed the same key for `paper` with new palette colours
    background: {
      paper: background.white,
      default: background.canvas
    },
    drawer: {
      background: green.darkest,
      fontColor: message.white,
      sectionFontColor: grey.base
    },
    // Assumed the same keys with new palette colours
    pagination: {
      item: {
        color: grey.dark
      },
      selectedItem: {
        background: mission.base,
        color: message.white
      }
    },
    // Assumed the same key with new palette colours
    table: {
      border: border.dark
    },
    // Assumed the same keys (muted is now `lightest`) with new palette colours
    jobStatus: {
      assigned: {
        color: teal.darkest,
        background: info.muted
      },
      inProgress: {
        color: message.white,
        background: teal.dark
      },
      booked: {
        color: message.black,
        background: blue.lightest
      },
      completed: {
        color: message.black,
        background: green.lighter
      },
      cancelled: {
        color: message.white,
        background: grey.darker
      },
      aborted: {
        color: message.white,
        background: grey.darker
      },
      unassigned: {
        color: message.black,
        background: message.white
      },
      risk: {
        color: message.black,
        background: orange.lighter
      },
      nonCustomer: {
        color: message.white,
        background: grey.darkest
      },
      enRoute: {
        color: message.black,
        background: blue.light
      }
    },
    schedule: {
      timeline: {
        shiftOvertime: {
          color: error.base
        },
        currentTimeIndicator: {
          color: blue.darkest
        },
        header: {
          color: grey.dark,
          background: background.white,
          highlight: mission.base
        },
        unassignedRow: {
          background: grey.lightest
        },
        row: {
          highlight: alpha(grey.darkest, 0.5),
          insertionPointer: mission.base
        },
        engineerInfo: {
          name: message.black,
          unavailableReason: grey.dark
        }
      },
      map: {
        route: green.dark,
        selectedRoute: teal.dark,
        deselectedRoute: grey.dark,
        overlay: grey.lightest,
        engineerHomeIcon: {
          background: grey.dark
        }
      }
    }
  },
  containers,
  typography,
  spacing,
  overrides: {
    MuiAlert: {
      icon: {
        marginRight: spacing(2)
      },
      filledInfo: {
        backgroundColor: message.black
      },
      filledSuccess: {
        backgroundColor: green.dark
      },
      filledError: {
        backgroundColor: error.base
      }
    },
    MuiDialog: {
      paper: {
        width: containers.small,
        maxWidth: containers.small
      },
      paperWidthSm: {
        maxWidth: containers.small
      }
    },
    MuiDialogTitle: {
      root: {
        padding: spacing(4, 3, 2)
      }
    },
    MuiDialogActions: {
      root: {
        '.MuiDialogContent-root + &': {
          padding: spacing(2, 3, 3)
        },
        padding: spacing(3)
      }
    },
    MuiIconButton: {
      label: {
        '& svg': {
          fill: semanticColours.message.dark
        }
      }
    },
    MuiPaper: {
      // `elevation1` is the default for the `Paper` component
      elevation1: {
        boxShadow:
          '0px 2px 4px rgba(0, 0, 0, 0.14), 0px 3px 4px rgba(0, 0, 0, 0.12), 0px 1px 5px rgba(0, 0, 0, 0.2)'
      },
      elevation3: {
        boxShadow:
          '0px 3px 3px rgba(0, 0, 0, 0.14), 0px 3px 4px rgba(0, 0, 0, 0.12), 0px 1px 8px rgba(0, 0, 0, 0.2)'
      }
    },
    MuiTableRow: {
      root: {
        '&.MuiTableRow-hover:hover': {
          backgroundColor: semanticColours.background.canvas
        }
      }
    },
    MuiTableCell: {
      root: {
        fontSize: typography.body?.fontSize,
        borderBottom: `1px solid ${semanticColours.border.light}`,
        textTransform: 'capitalize' as const
      },
      head: typography.bodyHeavy,
      stickyHeader: {
        backgroundColor: background.white
      }
    },
    MuiChip: {
      root: {
        backgroundColor: grey.lightest,
        color: grey.darkest
      },
      deleteIcon: {
        fill: grey.darker
      },
      deletable: {
        '&:focus': {
          border: `1px solid ${semanticColours.focus.base}`,
          backgroundColor: grey.lightest
        }
      }
    },
    MuiButton: {
      root: {
        ...typography.bodyHeavy,
        // Because of https://github.com/mui/material-ui/issues/16307
        textTransform: 'none' as const,
        minHeight: BUTTON_MIN_HEIGHT,
        '& .MuiTouchRipple-child': {
          backgroundColor: semanticColours.background.white
        },
        '&.Mui-disabled': {
          color: semanticColours.message.dark
        }
      },
      containedPrimary: {
        backgroundColor: semanticColours.primary.base,
        '&:hover': {
          backgroundColor: semanticColours.primary.alt
        },
        '&:active': {
          boxShadow:
            '0px 2px 4px rgba(0, 0, 0, 0.14), 0px 3px 4px rgba(0, 0, 0, 0.12), 0px 1px 5px rgba(0, 0, 0, 0.2)'
        },
        '&.Mui-disabled': {
          backgroundColor: semanticColours.background.canvas,
          color: semanticColours.message.dark
        }
      },
      containedSizeLarge: {
        fontSize: typography.bodyHeavy?.fontSize
      },
      outlined: {
        padding: spacing(1, 2)
      },
      text: {
        padding: spacing(1, 2)
      },
      textSecondary: {
        color: semanticColours.message.black,
        '&:hover': {
          backgroundColor: semanticColours.primary.muted
        },
        '&:active': {
          background: 'none'
        }
      }
    },
    MuiTab: {
      root: {
        textTransform: 'none' as const
      },
      textColorPrimary: {
        // Temporary fix for a Webpack issue causing different CSS rules to take precedence:
        // https://stackoverflow.com/questions/59559632/material-ui-have-different-style-result-in-production-mode
        '&.Mui-selected': {
          color: semanticColours.mission.base
        },
        color: semanticColours.message.dark
      }
    },
    MuiTabs: {
      root: {
        '& .Mui-selected': {
          color: semanticColours.mission.base
        }
      },
      indicator: {
        backgroundColor: semanticColours.mission.base
      }
    },
    MuiDivider: {
      root: {
        backgroundColor: colours.grey.lightest
      }
    },
    MuiInputBase: {
      root: {
        padding: 0,
        lineHeight: typography.body?.lineHeight
      },
      input: {
        height: 'auto',
        padding: spacing(2),
        font: 'unset',
        color: semanticColours.message.black,
        ...typography.body
      }
    },
    MuiOutlinedInput: {
      input: {
        padding: spacing(2)
      }
    },
    MuiAutocomplete: {
      inputRoot: {
        '&[class*="MuiOutlinedInput-root"]': {
          padding: 7
        }
      }
    },
    MuiPickersDay: {
      day: {
        color: semanticColours.message.dark
      },
      dayDisabled: {
        color: semanticColours.message.light
      }
    }
  }
};

export const modalFormInputOverrides = {
  MuiAutocomplete: {
    '&[class*="MuiOutlinedInput-root"] > .MuiAutocomplete-input': {
      padding: '0 !important'
    },
    hasPopupIcon: {
      '&.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
        paddingRight: 46
      }
    },
    input: {
      padding: 0
    },
    inputRoot: {
      '&[class*="MuiOutlinedInput-root"]': {
        padding: spacing(1, 2)
      },
      '&[class*="MuiOutlinedInput-root"] [class*="MuiOutlinedInput-inputAdornedEnd"][class*="MuiAutocomplete-input"]': {
        padding: spacing(0, 0, 0, 0.5)
      },
      '&[class*="MuiOutlinedInput-root"] > .MuiAutocomplete-input': {
        padding: '0 !important'
      },
      padding: spacing(1, 2)
    },
    popupIndicator: {
      padding: 0,
      marginRight: spacing(1)
    },
    root: {
      display: 'flex',
      alignItems: 'baseline',
      justifyContent: 'space-between',
      width: '100%'
    },
    tag: {
      margin: spacing(0, 0.5, 0, 0),
      '& + &': {
        margin: spacing(0.5)
      }
    }
  },
  MuiChip: {
    root: {
      backgroundColor: grey.lightest,
      color: grey.darkest,
      fontSize: typography.body?.fontSize
    },
    deleteIcon: {
      width: 20,
      height: 20
    }
  },
  MuiFormHelperText: {
    contained: {
      marginLeft: 0,
      marginRight: 0,
      margin: spacing(0.5, 0, 0, 0)
    }
  },
  MuiFormLabel: {
    root: {
      color: semanticColours.message.black
    }
  },
  MuiInputAdornment: {
    root: {
      '& button[class*="MuiIconButton-root"]': {
        padding: spacing(0.5)
      }
    }
  },
  MuiInputBase: {
    root: {
      padding: spacing(1, 2)
    }
  },
  MuiOutlinedInput: {
    root: {
      padding: 0
    },
    input: {
      padding: spacing(1, 2)
    },
    inputMarginDense: {
      paddingTop: 10.5,
      paddingBottom: 10.5
    },
    notchedOutline: {
      borderColor: semanticColours.border.dark
    }
  },
  MuiSelect: {
    icon: {
      width: 20,
      height: 20
    },
    iconOutlined: {
      top: '50%',
      right: spacing(2),
      transform: 'translateY(-50%)'
    }
  },
  MuiSvgIcon: {
    root: {
      width: 20,
      height: 20
    }
  }
};

export default theme;
