import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import rootReducer from './reducers';

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  configureScopeWithState: (scope, state: RootState) => {
    scope.setUser({ id: state.user.userProfile?.id });
    scope.setExtras({ NODE_ENV: process.env.NODE_ENV });
  }
});

const store = configureStore({
  reducer: rootReducer,
  enhancers: [sentryReduxEnhancer]
});

// @ts-ignore
if (window.Cypress) {
  // @ts-ignore
  window.store = store;
}

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof rootReducer>;
export type AppThunk<T = void> = ThunkAction<
  T,
  RootState,
  null,
  Action<string>
>;

export default store;
