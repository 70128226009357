import { Audit, AuditAction, AuditDomain, NoteDetails } from 'models/Audit';
import { getUIAppointmentStatus } from 'utils/jobStatus';
import { camelToTitleCase, capitalise } from 'utils';
import { DateTime } from 'luxon';
import { DATE_WITH_SLASH_FORMAT, scheduleTimezone } from 'constants/dates';
import { AppointmentStatus } from 'models/Appointment';
import { AUDIT_SYSTEM_USER } from 'constants/audit';
import { JobMovementEngineerInfo } from 'models/Engineers';

export interface AuditRow {
  action: string;
  actionedBy: string;
  time: string;
}

function formatFieldName(name: string): string {
  return camelToTitleCase(name);
}

function formatFieldValue(
  value: string | null,
  changedField: string | null
): string {
  if (value == null) {
    return 'null';
  }
  if (changedField === 'status') {
    return getUIAppointmentStatus((AppointmentStatus as any)[value]);
  }
  if (changedField === 'date') {
    return DateTime.fromISO(value).toFormat(DATE_WITH_SLASH_FORMAT);
  }
  return value;
}

function formattedTime(timestamp: number): string {
  return DateTime.fromMillis(timestamp, {
    zone: scheduleTimezone
  }).toFormat('ccc d LLL yyyy HH:mm');
}

function getFriendlyEntityName(domain: AuditDomain): string {
  switch (domain) {
    case AuditDomain.APPOINTMENT: {
      return 'Job';
    }
    default: {
      return formatFieldName(domain.toString());
    }
  }
}

function createActionSentence(auditEntry: Audit): string {
  if (auditEntry.changedField) {
    return `${formatFieldName(auditEntry.changedField)} created`;
  }
  return `${getFriendlyEntityName(auditEntry.domain)} created`;
}

function engineerNameOrUnassigned(
  auditValue: string | null,
  engineers?: JobMovementEngineerInfo[]
) {
  if (!auditValue) {
    return 'Unassigned';
  }

  const engineer = engineers?.find(({ id }) => id === auditValue);

  if (engineer == undefined) {
    return 'Unknown';
  }

  return `${engineer.firstName} ${engineer.lastName}`;
}

function updateActionSentence(auditEntry: Audit): string {
  if (!auditEntry.changedField) {
    return `${getFriendlyEntityName(auditEntry.domain)} updated`;
  }
  if (auditEntry.changedFrom || auditEntry.changedTo) {
    return `${formatFieldName(
      auditEntry.changedField
    )} updated from ${formatFieldValue(
      auditEntry.changedFrom,
      auditEntry.changedField
    )} to ${formatFieldValue(auditEntry.changedTo, auditEntry.changedField)}`;
  }

  return `${formatFieldName(auditEntry.changedField)} updated`;
}

function createAppointmentNoteSentence(auditEntry: Audit): string {
  switch (auditEntry.action) {
    case AuditAction.CREATE: {
      if (auditEntry.changedTo) {
        const noteDetails: NoteDetails = JSON.parse(auditEntry.changedTo);
        if (noteDetails.type && noteDetails.note) {
          return `${capitalise(noteDetails.type.toLowerCase())} note added: ${
            noteDetails.note
          }`;
        }
      }
      return 'Note added';
    }

    case AuditAction.DELETE: {
      if (auditEntry.changedFrom) {
        const noteDetails: NoteDetails = JSON.parse(auditEntry.changedFrom);
        if (noteDetails.type && noteDetails.note) {
          return `${capitalise(noteDetails.type.toLowerCase())} note deleted: ${
            noteDetails.note
          }`;
        }
      }
      return 'Note deleted';
    }
    default: {
      return 'Unknown Note Action';
    }
  }
}

function createJobMovementNoteSentence(
  auditEntry: Audit,
  engineers?: JobMovementEngineerInfo[]
) {
  return `Job assignment updated from ${engineerNameOrUnassigned(
    auditEntry.changedFrom,
    engineers
  )} to ${engineerNameOrUnassigned(auditEntry.changedTo, engineers)}`;
}

function actionSentence(
  auditEntry: Audit,
  engineers?: JobMovementEngineerInfo[],
  aegisEnableSupportJobMovementAuditRecords = false
): string {
  if (auditEntry.changedField === 'notes') {
    return createAppointmentNoteSentence(auditEntry);
  }
  if (aegisEnableSupportJobMovementAuditRecords) {
    if (auditEntry.changedField === 'scheduleId') {
      return createJobMovementNoteSentence(auditEntry, engineers);
    }
  }
  switch (auditEntry.action) {
    case AuditAction.CREATE: {
      return createActionSentence(auditEntry);
    }
    case AuditAction.DELETE: {
      return `${getFriendlyEntityName(auditEntry.domain)} deleted`;
    }
    case AuditAction.UPDATE: {
      return updateActionSentence(auditEntry);
    }
    default: {
      return 'Unknown Action';
    }
  }
}

function changedByName(auditEntry: Audit): string {
  const { username, firstName, lastName } = auditEntry.changedBy;
  if (username === AUDIT_SYSTEM_USER) {
    return 'System';
  }
  if (!firstName && !lastName) {
    return 'Unknown';
  }
  return `${firstName?.trim() || ''} ${lastName?.trim() || ''}`.trim();
}

function auditEntryToRow(
  auditEntry: Audit,
  engineers?: JobMovementEngineerInfo[],
  aegisEnableSupportJobMovementAuditRecords = false
): AuditRow {
  return {
    action: actionSentence(
      auditEntry,
      engineers,
      aegisEnableSupportJobMovementAuditRecords
    ),
    actionedBy: changedByName(auditEntry),
    time: formattedTime(auditEntry.timestamp)
  };
}

function mostRecentFirst(...auditItems: Audit[]): number {
  const [aDateTime, bDateTime] = auditItems.map(({ timestamp }) => timestamp);
  return bDateTime - aDateTime;
}

function groupBy<T, K>(arr: T[], key: (i: T) => K): Map<K, T[]> {
  const groups: Map<K, T[]> = new Map<K, T[]>();
  arr.forEach(element => {
    let list = groups.get(key(element));
    if (list === undefined) {
      list = [];
      list.push(element);
      groups.set(key(element), list);
    } else {
      list.push(element);
    }
  });
  return groups;
}

const compatibleAuditAction = new Map([
  [AuditAction.CREATE, new Set([AuditAction.UPDATE, AuditAction.DELETE])],
  [AuditAction.UPDATE, new Set([AuditAction.UPDATE, AuditAction.DELETE])],
  [AuditAction.DELETE, new Set([AuditAction.CREATE])]
]);

function checkIfCompatibleAudit(
  previousAuditAction: AuditAction,
  auditAction: AuditAction
): boolean {
  const compatibleActions = compatibleAuditAction.get(previousAuditAction);

  if (!compatibleActions) {
    return true;
  }

  return compatibleActions.has(auditAction);
}

function orderAssignmentAudits(audits: Audit[]): Audit[] {
  if (audits.length <= 1) {
    return audits;
  }

  const output: Audit[] = new Array(audits.length);
  let index = audits.length - 1;
  let previousAction: AuditAction | null = null;

  while (index >= 0) {
    const currentAudit = audits[index];
    if (currentAudit.changedField != 'scheduleId') {
      output[index] = currentAudit;
    } else if (
      !previousAction ||
      checkIfCompatibleAudit(previousAction, currentAudit.action)
    ) {
      output[index] = currentAudit;
      previousAction = currentAudit.action;
    } else {
      const nextIndex = index - 1;
      if (nextIndex < 0) {
        output[index] = currentAudit;
        previousAction = currentAudit.action;
      } else {
        [output[index], output[nextIndex]] = [audits[nextIndex], currentAudit];
        index -= 1;
        previousAction = currentAudit.action;
      }
    }

    index -= 1;
  }

  return output;
}

function updateScheduleAudits(scheduleAudits: Audit[]): Audit[] {
  const auditEntriesAssignmentResult: Audit[] = [];

  const audits = groupBy(
    orderAssignmentAudits(scheduleAudits),
    audit => audit.changedBy.username === AUDIT_SYSTEM_USER
  );

  const systemAudits = audits.get(true);

  if (systemAudits) {
    const auditsByTime = groupBy(
      systemAudits,
      auditEntry => `${Math.floor(auditEntry.timestamp / 100)}`
    );

    auditsByTime.forEach(auditList => {
      if (auditList.length < 2) {
        const audit = auditList[0];
        auditEntriesAssignmentResult.push(
          audit.action == AuditAction.CREATE
            ? { ...audit, changedTo: audit.additionalData }
            : { ...audit, changedFrom: audit.additionalData }
        );
        return;
      }

      const orderedAudits = auditList;

      const firstAudit = orderedAudits[orderedAudits.length - 1];
      const lastAudit = orderedAudits[0];

      const changedFrom = firstAudit.changedFrom
        ? firstAudit.additionalData
        : null;
      const changedTo = lastAudit.changedTo ? lastAudit.additionalData : null;

      if (changedFrom != changedTo) {
        auditEntriesAssignmentResult.push({
          ...orderedAudits[0],
          action: AuditAction.UPDATE,
          changedFrom,
          changedTo
        });
      }
    });
  }

  const userAudits = audits.get(false);

  if (userAudits) {
    userAudits.forEach(audit => {
      auditEntriesAssignmentResult.push(
        audit.action == AuditAction.CREATE
          ? { ...audit, changedTo: audit.additionalData }
          : { ...audit, changedFrom: audit.additionalData }
      );
    });
  }
  return auditEntriesAssignmentResult;
}

export function auditEntriesToRows(
  auditEntries: Audit[],
  engineers?: JobMovementEngineerInfo[],
  aegisEnableSupportJobMovementAuditRecords = false
): AuditRow[] {
  const auditsByType = groupBy(auditEntries, audit => audit.changedField);

  const scheduleAudits = auditsByType.get('scheduleId');
  if (!aegisEnableSupportJobMovementAuditRecords || !engineers) {
    auditsByType.delete('scheduleId');
  } else if (scheduleAudits !== undefined) {
    auditsByType.set('scheduleId', updateScheduleAudits(scheduleAudits));
  }

  return orderAssignmentAudits(
    [...Array.from(auditsByType.values())].flat().sort(mostRecentFirst)
  ).map(entry =>
    auditEntryToRow(entry, engineers, aegisEnableSupportJobMovementAuditRecords)
  );
}
