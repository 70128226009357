import React, { ReactElement } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core';
import Adjustment, { Pattern, AdjustmentType } from 'models/Adjustment';
import { dateFormatter, capitalise } from 'utils/utils';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import { CrudCurriedFn } from 'components/Crud';
import Typography from 'components/Typography';
import { useFlags } from 'launchdarkly-react-client-sdk';

const useStyles = makeStyles(
  theme => ({
    paper: {
      padding: theme.spacing(4)
    },
    subheader: {
      color: theme.palette.info.dark,
      marginBottom: theme.spacing(2)
    },
    container: {
      backgroundColor: '#d4e2f2', // the whole adjustment section will be redesigned, it's fine this to be hardcoded
      padding: theme.spacing(2),
      borderRadius: theme.spacing(2),
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      marginLeft: '0'
    },
    dateContainer: {
      paddingTop: theme.spacing(1)
    }
  }),
  { name: 'Adjustment' }
);

function AdjustmentComponent({
  qaId,
  adjustment,
  children,
  onDelete,
  onEdit
}: {
  qaId: string;
  adjustment: Adjustment;
  children: ReactElement;
  onDelete: CrudCurriedFn;
  onEdit?: (id: string, type: string) => void;
}) {
  const { aegisEnableRemoveEditButtonsFromOldEngineerCalendar } = useFlags();
  const classes = useStyles();
  const { startDate, endDate, pattern, reason, changes } = adjustment;

  const startPostcode = changes.find(
    ({ field }) => field === AdjustmentType.Postcode
  )?.change;

  const adjustmentField =
    adjustment.changes.find(({ field }) => field !== AdjustmentType.Postcode)
      ?.field || AdjustmentType.Patch;

  return (
    <Grid
      item
      container
      spacing={2}
      className={classes.container}
      data-testid={qaId}
      xs
    >
      <Grid xs={12} item container spacing={0}>
        <Grid item xs={10} className={classes.dateContainer}>
          <Typography data-testid={`${qaId}DateRange`}>
            {`${dateFormatter(startDate)} - ${dateFormatter(endDate)}`}
          </Typography>
        </Grid>
        <Grid item xs={2} justifyContent="flex-end" container>
          <IconButton
            data-testid="deleteAdjustmentButton"
            data-qa-id="deleteAdjustmentButton"
            onClick={onDelete(adjustment)}
            size="small"
          >
            <DeleteIcon />
          </IconButton>
          {!aegisEnableRemoveEditButtonsFromOldEngineerCalendar && (
            <IconButton
              data-testid="editAdjustmentButton"
              data-qa-id="editAdjustmentButton"
              onClick={() => onEdit && onEdit(adjustment.id, adjustmentField)}
              size="small"
            >
              <EditIcon />
            </IconButton>
          )}
        </Grid>
      </Grid>

      <Grid item xs={12}>
        {children}
      </Grid>
      <Grid item container xs={12}>
        <Grid item xs={5} md={3} lg={2}>
          <Typography variant="bodyHeavy">Recurring:</Typography>
        </Grid>
        <Grid item xs={7} md={9} lg={10}>
          <Typography>
            {`${capitalise(pattern.type.toLowerCase())}${
              pattern.type === Pattern.Weekly
                ? ` - ${pattern.weekdays
                    .map(day => capitalise(day.toLowerCase()))
                    .join(', ')}`
                : ''
            }`}
          </Typography>
        </Grid>
      </Grid>
      <Grid item container xs={12}>
        <Grid item xs={5} md={3} lg={2}>
          <Typography variant="bodyHeavy">Reason:</Typography>
        </Grid>
        <Grid item xs={7} md={9} lg={10}>
          <Typography>{reason || 'No reason provided'}</Typography>
        </Grid>
      </Grid>
      {startPostcode && (
        <Grid item container xs={12}>
          <Grid item xs={5} md={3} lg={2}>
            <Typography>Start postcode:</Typography>
          </Grid>
          <Grid item xs={7} md={9} lg={10}>
            <Typography>
              <span data-testid="adjustmentStartPostcode">{startPostcode}</span>
            </Typography>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}
export default AdjustmentComponent;
