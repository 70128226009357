import { Filter, FilterFieldType } from 'components/FilterSearch/FilterSearch';

export const capacityFilters: Filter[] = [
  {
    id: 'region',
    queryString: 'region',
    name: 'Region',
    operator: '=',
    fieldType: FilterFieldType.AUTOCOMPLETE
  },
  {
    id: 'area',
    queryString: 'area',
    name: 'Area',
    operator: '=',
    fieldType: FilterFieldType.AUTOCOMPLETE
  },
  {
    id: 'patch',
    queryString: 'patch',
    name: 'Patch',
    operator: '=',
    fieldType: FilterFieldType.AUTOCOMPLETE
  }
];
