import React from 'react';
import { useDispatch } from 'react-redux';
import {
  usePatchesData,
  useShiftPatternsData,
  useSkillsData,
  useTeamsData
} from 'store/selectors';
import { FieldInputValue, FormValues } from 'components/Forms';
import FormModal, { FormModalMode } from 'components/FormModal/FormModal';
import { engineerDomain } from 'models';
import Engineer from 'models/Engineers';
import { fetchAllSkills } from 'store/slices/skills';
import { fetchAllPatches } from 'store/slices/patches';
import { fetchAllShiftPatterns } from 'store/slices/shiftPatterns';
import { fetchAllTeams } from 'store/slices/teams';
import { addEngineer, fetchEngineers } from 'store/slices/engineers';
import {
  engineerFormFields,
  EngineersFieldNames,
  engineersFormValidation
} from '../FormConfig';

interface EngineersModalProps {
  open: boolean;
  closeModal: () => void;
  cancel: () => void;
  currentEngineer?: Engineer;
}

function mapDropdownOptionsToIds(options: FieldInputValue[]) {
  return options.map(({ value }) => value);
}

export default function EngineersModal({
  open,
  closeModal,
  cancel,
  currentEngineer
}: EngineersModalProps) {
  const dispatch = useDispatch();
  const mode = currentEngineer ? FormModalMode.EDIT : FormModalMode.ADD;
  const skills = useSkillsData();
  const patches = usePatchesData();
  const shiftPatterns = useShiftPatternsData();
  const teams = useTeamsData();

  const onLoad = () => {
    dispatch(fetchAllSkills());
    dispatch(fetchAllPatches());
    dispatch(fetchAllShiftPatterns());
    dispatch(fetchAllTeams());
  };

  const onAddEngineer = async (values: FormValues) => {
    const valuesAndMappedSkills = {
      ...values,
      [EngineersFieldNames.SKILLS]: mapDropdownOptionsToIds(
        values[EngineersFieldNames.SKILLS] as FieldInputValue[]
      )
    };

    await dispatch(
      addEngineer(valuesAndMappedSkills, {
        successMessage: `The new ${engineerDomain.singular} has been added`
      })
    );

    closeModal();
  };

  const refreshEngineerList = () => {
    dispatch(fetchEngineers());
  };

  const fields = engineerFormFields(
    mode,
    skills,
    patches,
    shiftPatterns,
    teams
  );

  return (
    <FormModal
      fields={fields}
      domain={engineerDomain}
      open={open}
      currentItem={currentEngineer}
      validationFunc={engineersFormValidation}
      onLoad={onLoad}
      onAdd={onAddEngineer}
      onEdit={onAddEngineer}
      onCancel={cancel}
      onSaved={refreshEngineerList}
    />
  );
}
