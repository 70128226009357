import React, { memo } from 'react';
import { makeStyles, createStyles, Theme, alpha } from '@material-ui/core';
import { timeToPixels } from 'components/Schedule/scheduleHelpers';
import {
  getJobCardBorder,
  getJobCardStyle,
  getReportedIconColor,
  getReportedSectionBorderColor
} from 'utils/jobStatus';
import { JobCardStyle } from '@material-ui/core/styles/createPalette';
import { getOpacity, MapElementType } from 'utils/mapElements';
import { CustomerAppointmentType } from 'models/Appointment';
import { ReportProblem } from '@material-ui/icons';
import { JobCardProps, JobCardStyleType, JobCardViewMode } from './JobCard';
import EVMarker from './EVMarker';

interface TraveTimeProps extends TravelTimeStyleProps {
  visible: boolean;
}

interface TravelTimeStyleProps {
  time: number;
  hide?: boolean;
  color: string;
}

export const assignedCardMapHeight = 32;
const assignedCardTimelineHeight = 44;

const getBoxShadowColor = (jobCardStyle: JobCardStyleType, theme: Theme) => {
  switch (jobCardStyle) {
    case JobCardStyleType.IN_PROGRESS:
      return theme.palette.aegis.colour.teal.dark;
    case JobCardStyleType.BOOKED:
      return theme.palette.aegis.colour.blue.lighter;
    case JobCardStyleType.COMPLETED:
      return theme.palette.aegis.colour.green.lighter;
    case JobCardStyleType.CANCELLED:
      return theme.palette.aegis.colour.grey.darker;
    case JobCardStyleType.ABORTED:
      return theme.palette.aegis.colour.grey.darker;
    case JobCardStyleType.AT_RISK:
      return theme.palette.aegis.colour.orange.lighter;
    case JobCardStyleType.EN_ROUTE:
      return theme.palette.aegis.colour.blue.light;
    default:
      return theme.palette.aegis.colour.grey.darker;
  }
};

const getBoxShadow = (
  theme: Theme,
  viewMode: JobCardViewMode,
  jobCardStyleType: JobCardStyleType
) => {
  return `0px 0px 0px ${
    viewMode === JobCardViewMode.TIMELINE
      ? theme.spacing(0.5)
      : theme.spacing(0.75)
  }px ${alpha(getBoxShadowColor(jobCardStyleType, theme), 0.5)}`;
};

interface BoxShadowProps {
  boxShadowEnabled: boolean;
}

const useJobCardStyles = makeStyles<
  Theme,
  JobCardProps & JobCardStyle & BoxShadowProps
>(theme => {
  return createStyles({
    warning: {
      fontSize: theme.typography.fontSize,
      color: ({ jobCardStyleType }) => getReportedIconColor(jobCardStyleType)
    },
    warningContainer: {
      width: '100%',
      height: '35%',
      display: 'flex',
      justifyContent: 'center',
      flex: 1,
      borderBottom: ({ jobCardStyleType }) =>
        `1px solid ${getReportedSectionBorderColor(jobCardStyleType)}`,
      paddingBottom: ({ viewMode }) =>
        viewMode === JobCardViewMode.MAP
          ? theme.spacing(0.7)
          : theme.spacing(0.3)
    },
    card: {
      width: ({ duration }) => timeToPixels(duration),
      height: ({ viewMode }) =>
        viewMode === JobCardViewMode.MAP
          ? assignedCardMapHeight
          : assignedCardTimelineHeight,
      backgroundColor: ({ background }) =>
        background || theme.palette.common.white,
      overflow: 'auto',
      position: 'relative',
      left: ({ minutesWaiting }) =>
        minutesWaiting ? timeToPixels(minutesWaiting) : 0,
      zIndex: 3,
      border: ({ jobCardStyleType, viewMode }) =>
        getJobCardBorder(jobCardStyleType, viewMode),
      borderRadius: '3px',
      boxSizing: 'content-box',
      opacity: ({ viewMode, selectedEngineer, engineerId }) =>
        viewMode === JobCardViewMode.TIMELINE
          ? 1
          : getOpacity(MapElementType.ASSIGNED, selectedEngineer, engineerId),
      boxShadow: ({
        boxShadowEnabled,
        appointmentId,
        engineerId,
        isStartOrEndJob,
        selectedEngineer,
        selectedJobCardDetails,
        jobCardStyleType,
        viewMode
      }) => {
        const shouldPressedStyleShow =
          boxShadowEnabled &&
          !isStartOrEndJob &&
          selectedEngineer === engineerId &&
          appointmentId === selectedJobCardDetails?.id;

        return shouldPressedStyleShow
          ? getBoxShadow(theme, viewMode, jobCardStyleType)
          : 'none';
      }
    },
    label: {
      width: ({ duration }) => timeToPixels(duration),
      color: ({ color }) => color || theme.palette.common.white,
      fontSize: 10,
      lineHeight: ({ hasComplaints }) => (hasComplaints ? undefined : '12px'),
      textAlign: 'center',
      letterSpacing: '1.5px',
      textTransform: 'uppercase',
      position: 'absolute',
      top: '50%',
      transform: ({ hasComplaints }) =>
        hasComplaints ? undefined : 'translateY(-50%)',
      overflow: 'hidden',
      zIndex: 1,
      fontWeight: 600
    },
    evMarker: {
      position: 'absolute',
      top: 3,
      right: 3,
      width: 6,
      height: 6,
      cursor: 'pointer',
      background: theme.palette.aegis.colour.grey.darkest,
      display: 'inline-block',
      borderRadius: 50
    }
  });
});

const useTravelTimeStyles = makeStyles<Theme, TravelTimeStyleProps>(() =>
  createStyles({
    travelTime: {
      backgroundColor: ({ color }) => color,
      width: ({ time }) => `${timeToPixels(time)}px`,
      height: 18,
      opacity: ({ hide }) => (hide ? 0 : 1)
    }
  })
);

const TravelTime = (props: TraveTimeProps) => {
  const { visible, time, hide, color } = props;
  const classes = useTravelTimeStyles({ time, hide, color });
  return visible ? (
    <div
      data-qa-id="engineerJobTravelTime"
      data-testid="engineerJobTravelTime"
      className={classes.travelTime}
    />
  ) : null;
};

const Assigned = (assignedProps: JobCardProps) => {
  const {
    jobCardStyleType,
    driveTimeTo = 0,
    label,
    hideTravelTime,
    hasComplaints
  } = assignedProps;

  const jobCardStyle = getJobCardStyle(jobCardStyleType);
  const classes = useJobCardStyles({
    ...assignedProps,
    ...jobCardStyle,
    boxShadowEnabled: true
  });

  return (
    <>
      <TravelTime
        visible
        color={jobCardStyle.background}
        time={driveTimeTo}
        hide={hideTravelTime}
      />
      <div
        data-qa-id="engineerJobCard"
        data-testid="engineerJobCard"
        className={classes.card}
      >
        {hasComplaints && (
          <div
            className={classes.warningContainer}
            data-testid="warningComplaints"
          >
            <ReportProblem className={classes.warning} />
          </div>
        )}
        {jobCardStyleType !== JobCardStyleType.NON_CUSTOMER && (
          <div data-testid="jobCardLabel" className={classes.label}>
            {label}
          </div>
        )}
        {assignedProps.type === CustomerAppointmentType.EV && <EVMarker />}
      </div>
    </>
  );
};
export default memo(Assigned);
