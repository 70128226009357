import React, { memo } from 'react';
import { KeyboardTimePicker } from '@material-ui/pickers';
import { createTheme, ThemeProvider } from '@material-ui/core';
import WatchLaterIcon from '@material-ui/icons/WatchLater';

import { Input } from 'components/Forms';
import { modalFormInputOverrides } from 'styles/defaultTheme';
import dateTimeTheme, { modalFormDateTimeInputOverrides } from './theme';

const Time = ({ field, meta, value, ...props }: any) => {
  return (
    <ThemeProvider
      theme={createTheme({
        ...dateTimeTheme,
        overrides: {
          ...modalFormInputOverrides,
          ...dateTimeTheme.overrides,
          ...modalFormDateTimeInputOverrides
        }
      })}
    >
      <KeyboardTimePicker
        size="small"
        {...props}
        error={Boolean(meta.touched && meta.error)}
        field={field}
        meta={meta}
        // Passing an empty string (React Final Form default for empty values)
        // does not work with this date picker, it must be `null`
        value={value || null}
        TextFieldComponent={Input}
        ampm={false}
        variant="inline"
        format="HH:mm"
        autoOk
        disabled={field.disabled}
        keyboardIcon={<WatchLaterIcon />}
      />
    </ThemeProvider>
  );
};

export default memo(Time);
