import { Domain, DomainItem } from 'models/Domain';
import { changePagination, stateSelector, StateSlice } from 'store/slices';
import {
  abortCustomerAppointment,
  addAppointment,
  createCustomerAppointment,
  deleteAppointment,
  editAppointment,
  fetchAppointment,
  fetchAppointments,
  setAppointmentAtRisk,
  setAppointmentsPagination
} from 'store/slices/appointments';
import {
  addEngineer,
  deleteEngineer,
  editEngineer,
  fetchEngineer,
  fetchEngineers,
  setEngineersPagination
} from 'store/slices/engineers';
import {
  addJobType,
  deleteJobType,
  editJobType,
  fetchAllJobTypes,
  fetchJobType,
  fetchJobTypes,
  setJobTypesPagination
} from 'store/slices/jobTypes';
import {
  addPatch,
  deletePatch,
  editPatch,
  fetchPatch,
  fetchPatches,
  setPatchesPagination
} from 'store/slices/patches';
import {
  addShiftPattern,
  deleteShiftPattern,
  editShiftPattern,
  fetchShiftPatterns,
  setShiftPatternsPagination
} from 'store/slices/shiftPatterns';
import {
  addSkillCategory,
  deleteSkillCategory,
  editSkillCategory,
  fetchAllSkillCategories,
  fetchSkillCategories,
  setSkillCategoriesPagination
} from 'store/slices/skillCategories';
import {
  addSkill,
  newAddSkill,
  deleteSkill,
  editSkill,
  newEditSkill,
  fetchSkill,
  fetchSkills,
  setSkillsPagination
} from 'store/slices/skills';
import {
  addTeam,
  deleteTeam,
  editTeam,
  fetchTeams,
  setTeamsPagination
} from 'store/slices/teams';

import {
  addTimeBand,
  deleteTimeBand,
  editTimeBand,
  fetchTimeBands,
  setTimeBandPagination
} from 'store/slices/timeBands';

import {
  ActionCreatorWithoutPayload,
  ActionCreatorWithPayload
} from '@reduxjs/toolkit';
import { updateReadiness } from 'store/slices/app';
import { clearSnackbar, setSnackbar, Snackbar } from 'store/slices/snackbar';
import { updateUserProfile } from 'store/slices/user';
import { fetchAbortCategories } from './slices/abortCategories';
import {
  addAdjustment,
  addAdjustmentGroup,
  deleteAdjustment,
  deleteAdjustmentGroup,
  editAdjustment,
  editAdjustmentGroup,
  fetchAdjustments
} from './slices/adjustments';
import { fetchCapacity } from './slices/capacity';
import {
  fetchCapacityUtilisation,
  setCapacityUtilisationPagination
} from './slices/capacityUtilisation';
import { fetchJobAlerts } from './slices/dashboard';
import {
  fetchGeographicAreas,
  setGeographicAreasPagination
} from './slices/geographicAreas';
import { fetchJobsDetails } from './slices/jobsDetails';
import { fetchRegions, setRegionsPagination } from './slices/regions';
import {
  editSchedule,
  fetchSchedule,
  publishEngineerSchedule
} from './slices/schedule';
import { AppThunk, RootState } from './store';

export * from './store';
export { default } from './store';

interface DomainSlice {
  selector: SliceSelectorFn;
  actions: {
    get: {
      list: SliceActionFn;
      listAll?: SliceActionFn;
      read?: SliceActionFn;
    };
    add: SliceActionFn;
    newAdd?: SliceActionFn;
    newEdit?: SliceActionFn;
    edit: SliceActionFn;
    delete: SliceActionFn;
    createJob?: SliceActionFn;
    changePagination?: ReturnType<typeof changePagination>;
    custom?: Record<string, SliceActionFn>;
  };
}

type SliceActionFn = (...args: any) => AppThunk | any;
type SliceSelectorFn = (state: RootState) => StateSlice;

// This needed as linter can mistake these functions as component functions and require a display name
// eslint-disable-next-line react/display-name
const emptySliceAction = () => () => null;

export function getDomainSlice<T = DomainItem>(domain: Domain): DomainSlice {
  const selector = {
    selector: stateSelector<StateSlice<T>>(domain.plural as keyof RootState)
  };

  switch (domain.type) {
    case 'skill':
      return {
        ...selector,
        actions: {
          get: { list: fetchSkills, read: fetchSkill },
          newAdd: newAddSkill,
          add: addSkill,
          newEdit: newEditSkill,
          edit: editSkill,
          delete: deleteSkill,
          changePagination: changePagination(setSkillsPagination, fetchSkills)
        }
      };
    case 'adjustmentGroup':
      return {
        ...selector,
        actions: {
          get: { list: emptySliceAction },
          delete: deleteAdjustmentGroup,
          edit: editAdjustmentGroup,
          add: addAdjustmentGroup
        }
      };
    case 'adjustment':
      return {
        ...selector,
        actions: {
          get: { list: fetchAdjustments },
          add: addAdjustment,
          edit: editAdjustment,
          delete: deleteAdjustment
        }
      };
    case 'appointment':
      return {
        ...selector,
        actions: {
          get: { list: fetchAppointments, read: fetchAppointment },
          add: addAppointment,
          edit: editAppointment,
          delete: deleteAppointment,
          changePagination: changePagination(
            setAppointmentsPagination,
            fetchAppointments
          ),
          custom: {
            createJob: createCustomerAppointment,
            abortJob: abortCustomerAppointment,
            setAtRisk: setAppointmentAtRisk
          }
        }
      };
    case 'engineer':
      return {
        ...selector,
        actions: {
          get: {
            list: fetchEngineers,
            read: fetchEngineer
          },
          add: addEngineer,
          edit: editEngineer,
          delete: deleteEngineer,
          changePagination: changePagination(
            setEngineersPagination,
            fetchEngineers
          )
        }
      };
    case 'patch':
      return {
        ...selector,
        actions: {
          get: { list: fetchPatches, read: fetchPatch },
          add: addPatch,
          edit: editPatch,
          delete: deletePatch,
          changePagination: changePagination(setPatchesPagination, fetchPatches)
        }
      };
    case 'jobType':
      return {
        ...selector,
        actions: {
          get: {
            list: fetchJobTypes,
            listAll: fetchAllJobTypes,
            read: fetchJobType
          },
          add: addJobType,
          edit: editJobType,
          delete: deleteJobType,
          changePagination: changePagination(
            setJobTypesPagination,
            fetchJobTypes
          )
        }
      };
    case 'skillCategory':
      return {
        ...selector,
        actions: {
          get: { list: fetchSkillCategories, listAll: fetchAllSkillCategories },
          add: addSkillCategory,
          edit: editSkillCategory,
          delete: deleteSkillCategory,
          changePagination: changePagination(
            setSkillCategoriesPagination,
            fetchSkillCategories
          )
        }
      };
    case 'shiftPattern':
      return {
        ...selector,
        actions: {
          get: { list: fetchShiftPatterns },
          add: addShiftPattern,
          edit: editShiftPattern,
          delete: deleteShiftPattern,
          changePagination: changePagination(
            setShiftPatternsPagination,
            fetchShiftPatterns
          )
        }
      };
    case 'schedule':
      return {
        ...selector,
        actions: {
          get: { list: fetchSchedule },
          // TODO - https://ovotech.atlassian.net/browse/FT-3887
          // We need to reconsider our types since not providing these
          // makes other CRUD component get TS errors about these functions
          // possibly being undefined
          add: emptySliceAction,
          edit: editSchedule,
          delete: emptySliceAction,
          custom: {
            publish: publishEngineerSchedule
          }
        }
      };
    case 'team':
      return {
        ...selector,
        actions: {
          get: { list: fetchTeams },
          add: addTeam,
          edit: editTeam,
          delete: deleteTeam,
          changePagination: changePagination(setTeamsPagination, fetchTeams)
        }
      };
    case 'timeBand':
      return {
        ...selector,
        actions: {
          get: { list: fetchTimeBands },
          add: addTimeBand,
          edit: editTimeBand,
          delete: deleteTimeBand,
          changePagination: changePagination(
            setTimeBandPagination,
            fetchTimeBands
          )
        }
      };
    case 'geographicArea':
      return {
        ...selector,
        actions: {
          get: { list: fetchGeographicAreas },
          changePagination: changePagination(
            setGeographicAreasPagination,
            fetchGeographicAreas
          )
          // TODO - https://ovotech.atlassian.net/browse/FT-3887
          // TODO: Fix this `any` type
        } as any
      };
    case 'regions':
      return {
        ...selector,
        actions: {
          get: { list: fetchRegions },
          changePagination: changePagination(setRegionsPagination, fetchRegions)
          // TODO - https://ovotech.atlassian.net/browse/FT-3887
          // TODO: Fix this `any` type
        } as any
      };
    case 'jobDetails':
      return {
        ...selector,
        actions: {
          get: { read: fetchJobsDetails }
          // TODO - https://ovotech.atlassian.net/browse/FT-3887
          // TODO: Fix this `any` type
        } as any
      };
    case 'capacity':
      return {
        ...selector,
        actions: {
          get: { list: fetchCapacity }
          // TODO - https://ovotech.atlassian.net/browse/FT-3887
          // TODO: Fix this `any` type
        } as any
      };
    case 'capacityUtilisation':
      return {
        ...selector,
        actions: {
          get: { list: fetchCapacityUtilisation },
          changePagination: changePagination(
            setCapacityUtilisationPagination,
            fetchCapacityUtilisation
          )
          // TODO - https://ovotech.atlassian.net/browse/FT-3887
          // TODO: Fix this `any` type
        } as any
      };
    case 'abortCategories':
      return {
        ...selector,
        actions: {
          get: { list: fetchAbortCategories }
          // TODO - https://ovotech.atlassian.net/browse/FT-3887
          // TODO: Fix this `any` type
        } as any
      };
    default:
      throw new Error('getDomainSlice requires a valid domain');
  }
}

export const getSnackbarSlice = () => {
  const selector = stateSelector<Snackbar>('snackbar');
  return {
    selector,
    actions: { setSnackbar, clearSnackbar }
  };
};

export const getDashboardStateSlice = () => {
  const selector = stateSelector('dashboard');
  return {
    selector,
    actions: {
      get: { list: fetchJobAlerts }
    } as { [key: string]: any }
  };
};

export const getSlice = (
  sliceName: keyof RootState
): {
  actions: {
    [key: string]:
      | ActionCreatorWithPayload<any>
      | ActionCreatorWithoutPayload
      | ((...args: [any, boolean]) => AppThunk<any>);
  };
  selector: (state: RootState) => any;
} => {
  const selector = stateSelector(sliceName);
  switch (sliceName) {
    case 'app': {
      return {
        selector,
        actions: { updateReadiness }
      };
    }
    case 'user': {
      return {
        selector,
        actions: { updateUserProfile }
      };
    }
    default:
      throw new Error('getSlice requires a valid slice name');
  }
};
