import React from 'react';
import ReactDOM from 'react-dom';

import { initialiseLaunchDarkly } from 'LaunchDarkly';
import { commonErrorHandling } from 'store/utils';
import * as serviceWorker from './serviceWorker';
import { WrappedApp } from './WrappedApp';

(async () => {
  try {
    const LaunchDarklyWrapper = await initialiseLaunchDarkly();

    ReactDOM.render(
      <LaunchDarklyWrapper>
        <WrappedApp />
      </LaunchDarklyWrapper>,
      document.getElementById('root')
    );
  } catch (error) {
    commonErrorHandling(error);
    ReactDOM.render(<WrappedApp />, document.getElementById('root'));
  }
})();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
