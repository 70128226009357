import { PayloadAction, ActionCreatorWithPayload } from '@reduxjs/toolkit';

import { RootState, AppThunk } from 'store';
import { QueryParams, PaginationOptions, ApiQueryParams } from 'store/utils';
import { ErrorStatus } from 'utils/utils';
import { SortByOptions } from 'components/Crud';

export interface StateSlice<T = any> extends MinimalStateSlice {
  items: T[];
  page: {
    pageIndex: number;
    pageSize: number;
    totalItems: number;
    totalPages: number;
  };
}

interface MinimalStateSlice {
  isLoading: boolean;
  error: ErrorStatus;
}

export const initialStateSlice: StateSlice = {
  items: [],
  page: {
    pageIndex: 0,
    pageSize: 25,
    totalItems: 0,
    totalPages: 0
  },
  isLoading: false,
  error: null
};

export const initialStateSliceWithoutPagination: Partial<StateSlice> = {
  items: [],
  isLoading: false,
  error: null
};

/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-vars */
// The only reason for currying here is so I can type these functions
// and keep TypeScript and Eslint happy 😔
export function startLoading<S>() {
  return {
    requestStart: (state: MinimalStateSlice) => {
      state.isLoading = true;
    }
  };
}

export function loadingFailed<S>() {
  return {
    requestFail: (state: MinimalStateSlice, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = { status: { message: action.payload } };
    }
  };
}

interface PaginationPayload {
  pageIndex: number | null;
  pageSize: number | null;
}

// TODO: Make this DRY
export function getPaginationReducers<S>() {
  return {
    setPagination: (
      state: StateSlice<S>,
      { payload: { pageIndex, pageSize } }: PayloadAction<PaginationPayload>
    ) => {
      if (pageIndex !== null) {
        state.page.pageIndex = pageIndex;
      }
      if (pageSize !== null) {
        state.page.pageSize = pageSize;
      }
    },
    setTotalItems: (
      state: StateSlice<S>,
      { payload: newItemTotal }: PayloadAction<number>
    ) => {
      state.page.totalItems = newItemTotal;
    },
    setTotalPages: (
      state: StateSlice<S>,
      { payload: newPageTotal }: PayloadAction<number>
    ) => {
      state.page.totalPages = newPageTotal;
    }
  };
}

export function stateSelector<TSliceState = StateSlice>(
  slice: keyof RootState
) {
  return (state: RootState) => state[slice] as TSliceState;
}

type FetchDomainItemsThunk =
  | ((
      query?: QueryParams,
      paginationOptions?: PaginationOptions,
      parentId?: string,
      sortBy?: SortByOptions,
      queryParams?: ApiQueryParams
    ) => AppThunk)
  | ((...args: any[]) => AppThunk);

interface ChangePaginationProps {
  pageIndex: number;
  pageSize: number;
  filter?: QueryParams;
  sortBy?: SortByOptions;
  queryParams?: ApiQueryParams;
  refetch?: boolean;
}
export const changePagination = (
  setDomainPagination: ActionCreatorWithPayload<PaginationPayload>,
  fetchDomainItems: FetchDomainItemsThunk
) => ({
  pageIndex = 0,
  pageSize = 25,
  filter = {},
  sortBy,
  queryParams,
  refetch = true
}: ChangePaginationProps): AppThunk => async dispatch => {
  dispatch(setDomainPagination({ pageIndex, pageSize }));
  if (refetch) {
    dispatch(
      fetchDomainItems(
        filter,
        { pageIndex, pageSize },
        undefined,
        sortBy,
        queryParams
      )
    );
  }
};
