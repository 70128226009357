import React from 'react';

import DashboardView from 'views/Dashboard';
import ScheduleView, { scheduleRoutes } from 'views/Schedule';
import { ListEngineersView, engineerRoutes } from 'views/Engineers';
import { ListJobTypesView, jobTypeRoutes } from 'views/JobTypes';
import { ListSkillsView, skillRoutes } from 'views/Skills';
import { ListPatchesView } from 'views/Patches';
import {
  scheduleDomain,
  engineerDomain,
  patchDomain,
  jobTypeDomain,
  skillDomain,
  jobDomain,
  capacityDomain
} from 'models';
import { ListJobsView } from 'views/Jobs';
import appointmentsRoutes from 'views/Appointments/routes';
import { ListCapacityView } from 'views/Capacity';

export interface NavigationSection {
  label: string;
  routes: NavigationRoute[];
}

export interface NavigationRoute {
  to: string;
  label?: string;
  component: (...args: any) => React.ReactElement | null;
  exact?: boolean;
  routes?: NavigationRoute[];
}

const navigationSections = (
  aegisEnableGenericModalForJobTypeListPage: boolean,
  aegisEnableNewEngineerModal: boolean,
  aegisEnableHideCapacityPage: boolean
): NavigationSection[] => [
  {
    label: '',
    routes: [
      {
        to: '/dashboard',
        label: 'Dashboard',
        component: DashboardView
      }
    ]
  },
  {
    label: 'Dispatch',
    routes: [
      {
        to: `/dashboard/${scheduleDomain.urlPath}`,
        label: scheduleDomain.label,
        component: ScheduleView,
        routes: scheduleRoutes
      },
      {
        to: `/dashboard/${jobDomain.urlPath}`,
        label: jobDomain.label,
        component: ListJobsView
      },
      ...appointmentsRoutes
    ]
  },
  {
    label: 'Planning',
    routes: [
      {
        to: `/dashboard/${engineerDomain.urlPath}`,
        label: engineerDomain.label,
        component: ListEngineersView,
        routes: engineerRoutes(aegisEnableNewEngineerModal)
      },
      {
        to: `/dashboard/${patchDomain.urlPath}`,
        label: patchDomain.label,
        component: ListPatchesView
      },

      {
        to: `/dashboard/${jobTypeDomain.urlPath}`,
        label: jobTypeDomain.label,
        component: ListJobTypesView,
        routes: jobTypeRoutes(aegisEnableGenericModalForJobTypeListPage)
      },
      {
        to: `/dashboard/${skillDomain.urlPath}`,
        label: skillDomain.label,
        component: ListSkillsView,
        routes: skillRoutes
      },
      ...(aegisEnableHideCapacityPage
        ? []
        : [
            {
              to: `/dashboard/${capacityDomain.urlPath}`,
              label: capacityDomain.label,
              component: ListCapacityView
            }
          ])
    ]
  }
];

export default navigationSections;
