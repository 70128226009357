import { AdjustmentType } from 'models/Adjustment';
import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { getPrimaryAdjustmentType } from 'utils/adjustments';
import DeleteConfirmationModal from 'views/Engineers/EngineerCalendar/DeleteConfirmationModal';
import { EngineerCalendarContext } from 'views/Engineers/EngineerCalendar/EngineerCalendar';
import { adjustmentsSlice, adjustmentGroupsSlice } from 'views/Engineers/store';
import { TemporaryAdjustmentFormContext } from '../AdjustmentFormModals';

interface DeleteTemporaryAdjustmentModalProps {
  onPrevious: () => void;
}

const friendlyTypeName = (type: AdjustmentType) => {
  switch (type) {
    case AdjustmentType.Shift: {
      return 'Shift Pattern';
    }
    case AdjustmentType.Patch: {
      return 'Patch';
    }
    case AdjustmentType.Efficiency: {
      return 'Efficiency';
    }
    case AdjustmentType.Skills: {
      return 'Skills';
    }
    default: {
      throw Error(`Unrecognised adjustment type '${type.toString()}'`);
    }
  }
};

export function DeleteTemporaryAdjustmentModal({
  onPrevious
}: DeleteTemporaryAdjustmentModalProps) {
  const { isOpen, setIsOpen, adjustment } = useContext(
    TemporaryAdjustmentFormContext
  );

  const dispatch = useDispatch();

  const { engineerData } = useContext(EngineerCalendarContext);

  if (!adjustment) {
    return null;
  }

  const adjustmentType = friendlyTypeName(getPrimaryAdjustmentType(adjustment));

  const confirmationText =
    'Are you sure you want to delete this item?\n' +
    'This action is permanent and can not be undone.\n\n' +
    `The ${adjustmentType.toLowerCase()} will revert back to their default schedule.`;

  const handleClose = () => {
    setIsOpen?.(false);
  };

  const handleDelete = () => {
    if (engineerData) {
      if (adjustment.groupId) {
        dispatch(
          adjustmentGroupsSlice.actions.delete(adjustment, engineerData.id)
        );
        handleClose();
      } else {
        dispatch(adjustmentsSlice.actions.delete(adjustment, engineerData.id));
        handleClose();
      }
    }
  };

  return (
    <DeleteConfirmationModal
      onDelete={handleDelete}
      onCancel={onPrevious}
      onClose={handleClose}
      title={`Delete ${adjustmentType} Change`}
      open={isOpen}
      testId="deleteTemporaryAdjustmentModal"
    >
      {confirmationText}
    </DeleteConfirmationModal>
  );
}
