import React, { memo } from 'react';
import { Marker } from 'react-map-gl';
import EngineerHomeIcon, {
  engineerHomeIconHeight,
  engineerHomeIconWidth
} from 'components/Schedule/EngineerHomeIcon/EngineerHomeIcon';
import EngineerIcon, {
  EngineerIconSize
} from 'components/Schedule/EngineerIcon/EngineerIcon';
import { EngineerSchedule } from 'models/Schedule';

interface EngineerHomeMarkerProps {
  schedule: EngineerSchedule;
  selectedEngineer?: string;
  engineerId: string;
}

const EngineerHomeMarker = ({
  schedule,
  selectedEngineer,
  engineerId
}: EngineerHomeMarkerProps) => {
  const topOffset = -(engineerHomeIconHeight - 6);
  const leftOffset = 2;
  const lat = schedule.assignments[0].location?.geoLocation.lat;
  const long = schedule.assignments[0].location?.geoLocation.long;

  return (
    <>
      <Marker
        latitude={lat}
        longitude={long}
        offsetLeft={-(engineerHomeIconWidth / 2)}
        offsetTop={-(engineerHomeIconHeight / 2)}
      >
        <EngineerHomeIcon
          selectedEngineer={selectedEngineer}
          engineerId={engineerId}
        />
      </Marker>
      <Marker
        longitude={long}
        latitude={lat}
        offsetTop={topOffset}
        offsetLeft={leftOffset}
      >
        <EngineerIcon
          firstName={schedule.engineer.firstName}
          lastName={schedule.engineer.lastName}
          size={EngineerIconSize.SMALLEST}
          border
          selectedEngineer={selectedEngineer}
          engineerId={engineerId}
        />
      </Marker>
    </>
  );
};

export default memo(EngineerHomeMarker);
