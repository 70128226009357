import { AxiosResponse } from 'axios';
import { Page } from 'models/Page';
import { FilterByQueryParams, paramsSerializer } from 'store/utils';
import { DomainItem, Domain } from 'models/Domain';
import http from 'api/http';

type GetDataResponse<T> = Promise<AxiosResponse<Page<T>>>;
export type GetDataFunction<T> = (
  params: Record<string, any>,
  body?: any
) => GetDataResponse<T>;

export async function iteratePagination<T>(
  filterQueryParams:
    | ({ params?: FilterByQueryParams } & Record<string, any>)
    | undefined,
  getData: GetDataFunction<T>,
  body?: any
): Promise<T[]> {
  const allItems: T[] = [];

  const params: Record<string, any> = {
    ...filterQueryParams,
    pageIndex: 0
  };

  const firstPageResponse = await getData(params, body);
  const { data: paginatedResponse } = firstPageResponse;

  allItems.push(...firstPageResponse.data.items);

  const { totalPages } = paginatedResponse.page;
  const dataCalls: GetDataResponse<T>[] = [];

  for (let i = 1; i < totalPages; i += 1) {
    dataCalls.push(
      getData(
        {
          ...params,
          pageIndex: i
        },
        body
      )
    );
  }

  const responses = await Promise.all(dataCalls);
  allItems.push(...responses.flatMap(response => response.data.items));
  return allItems;
}

/**
 * This method is a generic function for use with `iteratePagination`
 * @param {Domain} domain - The domain object
 */
export function getPaginatedApiData<T = DomainItem>(domain: Domain) {
  return (params?: Record<string, any>) =>
    http.get<Page<T>>(domain.apiPath, {
      params,
      paramsSerializer
    });
}
