import React, { memo, useEffect, DOMAttributes } from 'react';
import { useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { makeStyles, createStyles, Theme } from '@material-ui/core';

import { AppointmentSlot, CustomerAppointmentType } from 'models/Appointment';
import { JobDetailsFields } from 'models/Schedule';
import Unassigned from './Unassigned';
import Assigned from './Assigned';
import { SelectEngineerCallback } from '../Map/MapWrapper';

export enum JobCardViewMode {
  MAP = 'map',
  TIMELINE = 'timeline'
}

export enum JobCardStyleType {
  ABORTED = 'aborted',
  ASSIGNED = 'assigned',
  COMPLETED = 'completed',
  CANCELLED = 'cancelled',
  IN_PROGRESS = 'inProgress',
  BOOKED = 'booked',
  EN_ROUTE = 'enRoute',
  UNASSIGNED = 'unassigned',
  NON_CUSTOMER = 'nonCustomer',
  AT_RISK = 'atRisk'
}

export type Job = {
  type: string; // used for React DnD only
  appointmentId: string;
  slot: AppointmentSlot;
  jobType: string;
};

export interface JobCardProps
  extends Omit<DOMAttributes<HTMLDivElement>, 'onDrop'> {
  index?: number;
  appointmentId: string | null;
  engineerId?: string;
  canDrag?: boolean;
  jobCardStyleType: JobCardStyleType;
  duration: number;
  jobType?: string;
  type?: CustomerAppointmentType;
  driveTimeTo?: number;
  arrivalTime?: string;
  earliestStartTime?: string | null;
  slot?: AppointmentSlot;
  hideTravelTime?: boolean;
  minutesWaiting?: number;
  label: string;
  viewMode: JobCardViewMode;
  assigned: boolean;
  hovered?: boolean;
  selectedJobCardDetails?: JobDetailsFields;
  selectedEngineer?: string;
  isStartOrEndJob?: boolean;
  hasComplaints?: boolean;
  onDrop?: (job: Job) => void;
  onClick?: () => void;
  onUnassignedHover?: (id: string, newSelectedEngineer?: string) => void;
  handleSelectEngineer?: SelectEngineerCallback;
}

const useJobCardStyles = makeStyles<Theme, JobCardProps>(
  () =>
    createStyles({
      container: {
        position: 'relative',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: ({ canDrag }) => (canDrag ? 'grab' : 'auto'),
        userSelect: 'none',
        zIndex: ({ index }) => (index ? index + 10 : 10),
        margin: ({ assigned }) => (assigned ? 0 : '4px 2px 2px 2px')
      }
    }),
  { name: 'JobCard' }
);

const JobCard = (props: JobCardProps) => {
  const {
    onDrop,
    onMouseEnter,
    onMouseLeave,
    canDrag,
    appointmentId,
    engineerId,
    jobCardStyleType,
    jobType,
    slot,
    driveTimeTo = 0,
    label,
    duration,
    hideTravelTime,
    assigned,
    handleSelectEngineer
  } = props;

  const classes = useJobCardStyles({ ...props });

  const [{ opacity }, dragRef, preview] = useDrag({
    item: {
      type: 'Job',
      appointmentId: appointmentId as string,
      engineerId,
      slot: slot as AppointmentSlot,
      driveTimeTo,
      label,
      jobCardStyleType,
      jobType,
      duration,
      hideTravelTime,
      assigned
    },
    collect: monitor => ({
      opacity: monitor.isDragging() ? 0 : 1
    }),
    end: (item, monitor) => {
      if (props.onUnassignedHover) {
        props.onUnassignedHover('', undefined);
      }
      if (monitor.didDrop()) {
        if (onDrop) {
          onDrop(item as Job);
        }
      }
    },
    canDrag: () => canDrag || false
  });

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, []);

  return (
    <div
      ref={dragRef}
      style={{ opacity }}
      className={classes.container}
      data-qa-id="engineerJobCardContainer"
      data-testid="engineerJobCardContainer"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={() => {
        if (handleSelectEngineer != null) {
          handleSelectEngineer(engineerId != null ? engineerId : undefined);
        }
      }}
    >
      {assigned ? <Assigned {...props} /> : <Unassigned {...props} />}
    </div>
  );
};

JobCard.defaultProps = {
  canDrag: true
};

export default memo(JobCard);
