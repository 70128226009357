import React, { memo, useEffect, useState } from 'react';

import { CircularProgress, makeStyles } from '@material-ui/core';

import JobDetailsSheet from 'components/JobDetailsSheet';
import { jobDomain } from 'models';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  useJobDetailsData,
  useJobDetailsState,
  useWorkflowData
} from 'store/selectors';
import { useFlags } from 'launchdarkly-react-client-sdk';
import ErrorDisplay from 'components/ErrorDisplay';
import Snackbars from 'components/Alerts/Snackbars';
import EditJobModal from 'components/JobDetailsSheet/EditJob/EditJobModal';
import AbortJobModal from 'components/JobDetailsSheet/AbortJob/AbortJobModal';
import CancelJobModal from 'components/JobDetailsSheet/CancelJob/CancelJobModal';
import { fetchJobsDetails } from 'store/slices/jobsDetails';
import { getJobSheetData } from './JobDetailsConfig';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(9, 0, 4, 0)
  },
  loadingWrapper: {
    position: 'relative',
    minHeight: 44
  },
  spinnerWrapper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }
}));

const JobDetailsPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const jobId = history.location.pathname.split(`${jobDomain.plural}/`)[1];
  const job = useJobDetailsData(jobId);
  const workflowData = useWorkflowData(jobId);
  const { isLoading: isJobDetailsLoading, error } = useJobDetailsState();
  const { aegisDisplayMultiRateMultiMpanFields } = useFlags();
  const evForm = Boolean(job?.jobTypeData?.name?.startsWith('EV'));
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isAbortModalOpen, setIsAbortModalOpen] = useState(false);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [isUpdating, setIsUpdating] = useState(true);

  useEffect(() => {
    if (isUpdating) {
      dispatch(fetchJobsDetails(jobId));
      setIsUpdating(false);
    }
  }, [isUpdating]);

  const reloadJobDetails = () => setIsUpdating(true);

  const openAbortModal = () => {
    setIsAbortModalOpen(true);
  };

  const closeAbortModal = () => {
    setIsAbortModalOpen(false);
  };

  const openCancelModal = () => {
    setIsCancelModalOpen(true);
  };

  const closeCancelModal = () => {
    setIsCancelModalOpen(false);
  };

  const openEditModal = () => {
    setIsEditModalOpen(true);
  };

  const closeEditModal = () => {
    setIsEditModalOpen(false);
  };

  const headerData = job && {
    status: job.status,
    postcode: job.address?.postcode,
    engineerFirstName: job.assignedEngineer?.firstName,
    engineerLastName: job.assignedEngineer?.lastName,
    slot: job.slot && `${job.slot.startTime}-${job.slot.endTime}`,
    atRisk: job.atRisk
  };

  return (
    <div className={classes.container}>
      {!error ? null : <ErrorDisplay status={error.status} />}
      {!isJobDetailsLoading && !error && headerData && job?.slot.date && (
        <JobDetailsSheet
          sheetData={getJobSheetData(
            job,
            evForm,
            aegisDisplayMultiRateMultiMpanFields
          )}
          headerData={headerData}
          openEditModal={openEditModal}
          openAbortModal={openAbortModal}
          openCancelModal={openCancelModal}
          workflowData={workflowData}
          jobId={job.id}
          auditData={job.auditEntries}
          reloadJobDetails={reloadJobDetails}
          scheduleDate={job.slot.date}
          patch={job.patch}
          assignedEngineerId={job.assignedEngineer?.id}
        />
      )}
      {isJobDetailsLoading && (
        <div className={classes.loadingWrapper}>
          <CircularProgress
            size={48}
            className={classes.spinnerWrapper}
            data-qa-id="readDomainSpinner"
            data-testid="readDomainSpinner"
          />
        </div>
      )}
      <Snackbars />
      {job && (
        <EditJobModal
          job={job}
          open={isEditModalOpen}
          closeModal={closeEditModal}
          reloadJobDetails={reloadJobDetails}
        />
      )}
      {job && (
        <AbortJobModal
          job={job}
          open={isAbortModalOpen}
          closeModal={closeAbortModal}
          reloadJobDetails={reloadJobDetails}
        />
      )}
      {job && (
        <CancelJobModal
          job={job}
          open={isCancelModalOpen}
          closeModal={closeCancelModal}
          reloadJobDetails={reloadJobDetails}
        />
      )}
    </div>
  );
};

export default memo(JobDetailsPage);
