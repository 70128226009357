import React, { MutableRefObject } from 'react';

import { useDispatch } from 'react-redux';
import { getDomainSlice } from 'store';

import {
  Form,
  FormValues,
  yupSchemaToValidationFunc,
  TimetableForm,
  FormValue
} from 'components/Forms';
import { CrudViewProps, formSubmitHandler } from 'components/Crud/utils';
import { FormApiWithRestart } from 'components/Forms/Form';
import Adjustment, { AdjustmentType, DayOfWeek } from 'models/Adjustment';
import { capitaliseFirstLetter } from 'utils';
import Typography from 'components/Typography';
import { DateTime } from 'luxon';

interface EditAdjustmentProps extends CrudViewProps {
  parentId: string;
  timetable?: boolean;
  adjustment: Adjustment;
  adjustmentType: string;
  formRef: MutableRefObject<FormApiWithRestart | undefined>;
}

const EditAdjustment = ({
  domain,
  fields,
  title,
  validationSchema,
  presubmitCallback,
  parentId,
  timetable = false,
  adjustment,
  adjustmentType,
  formRef
}: EditAdjustmentProps) => {
  const dispatch = useDispatch();
  const { actions } = getDomainSlice(domain);

  if (fields == null) {
    return null;
  }

  const getWeekDays = Object.values(DayOfWeek).reduce(
    (acc, curr: DayOfWeek) => {
      const day = {
        [`${curr.toUpperCase()}`]:
          adjustment.pattern.type === 'WEEKLY' &&
          adjustment.pattern.weekdays.includes(curr)
      };

      return {
        ...acc,
        ...day
      };
    },
    {}
  );

  let initialValues: FormValues = {
    reason: adjustment.reason,
    pattern: adjustment.pattern.type,
    startDate: DateTime.fromISO(adjustment.startDate),
    endDate: DateTime.fromISO(adjustment.endDate)
  };

  if (adjustmentType === AdjustmentType.Patch) {
    initialValues = {
      ...initialValues,
      ...getWeekDays,
      patch: adjustment.changes.find(
        ({ field }) => field === AdjustmentType.Patch
      )?.change as FormValue
    };

    const postcodeAdjustment = adjustment.changes.find(
      ({ field }) => field === AdjustmentType.Postcode
    );

    if (postcodeAdjustment) {
      initialValues = {
        ...initialValues,
        differentStartLocation: 'true',
        startPostcode: postcodeAdjustment?.change.toString()
      };
    } else {
      initialValues = {
        ...initialValues,
        differentStartLocation: 'false'
      };
    }
  } else if (adjustmentType === AdjustmentType.Efficiency) {
    initialValues = {
      ...initialValues,
      ...getWeekDays,
      efficiency: adjustment.changes[0].change
    };
  } else {
    const weekDays = Object.entries(adjustment.changes[0].change).reduce(
      (acc, [key, value]) => {
        const day = capitaliseFirstLetter(key);
        const checkBoxName = `checkBox${day}`;
        const start = `startTime${day}`;
        const end = `endTime${day}`;

        if (value === null) {
          return {
            ...acc,
            [checkBoxName]: true,
            [start]: null,
            [end]: null
          };
        }

        return {
          ...acc,
          [checkBoxName]: false,
          [start]: DateTime.fromISO(`1970-01-01T${value.startTime}:00`),
          [end]: DateTime.fromISO(`1970-01-01T${value.endTime}:00`)
        };
      },
      {}
    );

    initialValues = {
      ...initialValues,
      ...weekDays
    };
  }

  const validationFunc = yupSchemaToValidationFunc(validationSchema);
  const apiCallback = (data: FormValues) =>
    dispatch(actions.edit(data, adjustment.id, parentId));

  return (
    <>
      {title ? (
        <Typography data-qa-id="createAdjustmentTitle">{title}</Typography>
      ) : null}
      <Form
        validationFunc={validationFunc}
        fields={fields}
        onSubmit={formSubmitHandler(apiCallback, presubmitCallback)}
        initialValues={initialValues}
        goBackToPreviousPage={false}
      >
        {({ form }) => {
          // eslint-disable-next-line no-param-reassign
          formRef.current = form;
          return timetable ? <TimetableForm /> : undefined;
        }}
      </Form>
    </>
  );
};

export default EditAdjustment;
