import { captureMessage, Severity } from '@sentry/react';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { commonErrorHandling } from 'store/utils';

export type LDClient = import('launchdarkly-js-client-sdk').LDClient;

export const initialiseLaunchDarkly = () => {
  const clientSideId = process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_SIDE_ID || '';
  if (!clientSideId) {
    captureMessage(
      'REACT_APP_LAUNCH_DARKLY_CLIENT_SIDE_ID is missing. LaunchDarkly will fail to initialise. ' +
        "Feature flags will be 'off' by default.",
      Severity.Warning
    );

    if (process.env.REACT_APP_ENVIRONMENT !== 'prd') {
      // eslint-disable-next-line no-console
      console.warn(
        'The environment var REACT_APP_LAUNCH_DARKLY_CLIENT_SIDE_ID needs to be set to enable feature flags'
      );
    }
  }

  return asyncWithLDProvider({
    clientSideID: clientSideId,
    user: {
      anonymous: true,
      key: 'newton-ui-anonymous'
    }
  });
};

export const identifyLaunchDarklyUser = async (
  launchDarklyClient?: LDClient,
  userId?: string
) => {
  if (!launchDarklyClient) {
    captureMessage(
      "LaunchDarkly client has failed to initialise. All feature flags will be 'off' by default.",
      Severity.Warning
    );
  }

  if (!userId) {
    captureMessage(
      "LaunchDarkly has failed to get the user key from the provided Keycloak Token. All feature flags will be 'off' by default.",
      Severity.Warning
    );
  }

  try {
    await launchDarklyClient?.waitForInitialization();
    await launchDarklyClient?.identify({ key: userId });
  } catch (error) {
    commonErrorHandling(error);
    captureMessage(
      "Error while identifying LaunchDarkly user. All feature flags will be 'off' by default.",
      Severity.Warning
    );
  }
};
