import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ListView from 'components/Crud/views/List';
import { patchDomain, engineerDomain } from 'models';
import { TableCell } from '@material-ui/core';
import { getDomainSlice } from 'store';
import { usePatchesData } from 'store/selectors';
import {
  useTableRowActionsState,
  ListTable,
  TableActions,
  useTableStyles
} from 'components/Crud/ListTable/ListTable';
import { arrayToCsvString, getFilterQueryParams } from 'utils';
import ListTableRow from 'components/Crud/ListTable/ListTableRow';
import FilterSearch, {
  FilterValue
} from 'components/FilterSearch/FilterSearch';
import useFilterSearchChips from 'utils/custom-hooks/useFilterSearchChips';
import { QueryParams } from 'store/utils';
import Patch from 'models/Patches';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { getPatchFilters } from './FormConfig';
import PatchModal from './PatchModal/PatchModal';

const PatchesView = () => {
  const classes = useTableStyles({ containerSize: 'medium' });
  const { rowHovered, setRowHovered } = useTableRowActionsState();
  const dispatch = useDispatch();

  const { aegisEnableSearchPatchByName } = useFlags();

  const patchesData = usePatchesData();
  const patchFilters = getPatchFilters(aegisEnableSearchPatchByName);

  const columnsWidth = [480, 360];
  const headers = ['Name', 'Outcodes'];

  const { actions: patchesActons, selector } = getDomainSlice(patchDomain);
  const {
    page: { pageIndex, pageSize }
  } = useSelector(selector);

  const onSearch = (params: QueryParams) =>
    dispatch(patchesActons.get.list(params, { pageIndex, pageSize }));

  const onLoad = (filterValues: FilterValue[], searchWithFilters: () => void) =>
    React.useEffect(() => {
      if (filterValues.length > 0) {
        searchWithFilters();
      }
    }, [filterValues]);

  const {
    params,
    filterValues,
    appliedFilters,
    onFilterRemove,
    onFilterValueInput
  } = useFilterSearchChips(patchFilters, onSearch, onLoad);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentPatch, setCurrentPatch] = useState<Patch>();

  const openModal = () => setIsModalOpen(true);

  const openEditModal = (patch: Patch) => {
    openModal();
    setCurrentPatch(patch);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setCurrentPatch(undefined);
  };

  const onEditTableAction = (patch: Patch) => () => () => openEditModal(patch);

  return (
    <>
      <ListView
        domain={patchDomain}
        showPageHeader={false}
        dispatchProps={[params]}
        autoLoad={filterValues.length === 0}
      >
        {({ onDelete }) => (
          <section className={classes.tableContainer}>
            <ListTable
              domain={patchDomain}
              domainItems={patchesData}
              columnsWidth={columnsWidth}
              setRowHovered={(row: number | boolean) => setRowHovered(row)}
              rowHovered={rowHovered}
              title={patchDomain.label}
              label={`A table of ${engineerDomain.label} ${patchDomain.plural}`}
              headers={headers}
              filter={getFilterQueryParams(appliedFilters)}
              headerComponent={
                <FilterSearch
                  filters={patchFilters}
                  filterValues={appliedFilters}
                  onFilterRemove={onFilterRemove}
                  onFilterValueInput={onFilterValueInput}
                />
              }
              headerButtons={[
                {
                  label: 'New patch',
                  openModal
                }
              ]}
            >
              {patchesData.map((patch, index) => (
                <ListTableRow
                  key={patch.id}
                  data-testid={`tableRow-${patch.id}`}
                >
                  <TableCell className={classes.tableCell}>
                    {patch.name}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {Array.isArray(patch.outcodes)
                      ? arrayToCsvString(patch.outcodes)
                      : patch.outcodes}
                  </TableCell>
                  {rowHovered !== index ? (
                    <TableCell className={classes.tableCell}>&nbsp;</TableCell>
                  ) : (
                    <TableActions
                      onEdit={onEditTableAction(patch)}
                      onDelete={onDelete}
                      domain={patchDomain}
                      domainItem={patch}
                    />
                  )}
                </ListTableRow>
              ))}
            </ListTable>
          </section>
        )}
      </ListView>
      <PatchModal
        open={isModalOpen}
        closeModal={closeModal}
        currentPatch={currentPatch}
      />
    </>
  );
};

export default PatchesView;
