/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import http from 'api/http';

import { abortCategoriesDomain } from 'models';
import { AbortCategory } from 'models/AbortCategory';
import { AppThunk } from 'store/store';
import { commonErrorHandling } from 'store/utils';
import {
  initialStateSliceWithoutPagination,
  startLoading,
  loadingFailed,
  StateSlice
} from '.';

import { setSnackbar, SnackbarStatus } from './snackbar';

const abortCategories = createSlice({
  name: abortCategoriesDomain.plural,
  initialState: initialStateSliceWithoutPagination as StateSlice<AbortCategory>,
  reducers: {
    ...startLoading<AbortCategory>(),
    ...loadingFailed<AbortCategory>(),
    getAbortCategorySuccess(
      state,
      { payload }: PayloadAction<AbortCategory[]>
    ) {
      state.items = payload;
      state.isLoading = false;
      state.error = null;
    }
  }
});

export const {
  requestStart,
  requestFail,
  getAbortCategorySuccess
} = abortCategories.actions;

export const fetchAbortCategories = (): AppThunk => {
  return async dispatch => {
    try {
      dispatch(requestStart());

      const { data } = await http.get<AbortCategory[]>(
        abortCategoriesDomain.apiPath
      );

      dispatch(getAbortCategorySuccess(data));

      return data;
    } catch (error) {
      const errorCode = error.response?.status || '';

      commonErrorHandling(error, abortCategoriesDomain.type);
      dispatch(requestFail(error));

      dispatch(
        setSnackbar({
          message: `Error ${errorCode} - Unable to retrieve the abort categories and reasons.`,
          show: true,
          status: SnackbarStatus.Error
        })
      );

      return null;
    }
  };
};

export default abortCategories.reducer;
