import { NavigationRoute } from 'views/Dashboard/routes';
import EditEngineerView from 'views/Engineers/EditEngineer';
import AddEngineerView from 'views/Engineers/AddEngineer';
import Engineer from 'views/Engineers/Engineer';
import { engineerDomain } from 'models';
import EngineerAppointments from 'views/Engineers/EngineerAppointments';

const engineersRoutes = (
  aegisEnableNewEngineerModal: boolean
): NavigationRoute[] => [
  {
    to: `/dashboard/${engineerDomain.urlPath}/:id/edit`,
    component: EditEngineerView
  },
  ...(aegisEnableNewEngineerModal
    ? []
    : [
        {
          to: `/dashboard/${engineerDomain.urlPath}/create`,
          component: AddEngineerView
        }
      ]),
  {
    to: `/dashboard/${engineerDomain.urlPath}/:id`,
    component: Engineer
  },
  {
    to: `/dashboard/${engineerDomain.urlPath}/:id/appointments`,
    component: EngineerAppointments
  }
];

export default engineersRoutes;
