import { useEffect, useMemo, useState } from 'react';
import { useLocation, useHistory, useRouteMatch } from 'react-router-dom';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const JSURL = require('jsurl2');

export const paramSymbol = 'p';

export const encodeParam = JSURL.stringify;

const tryDecodeParam = <T>(encodedParams: string | null, defaultState: T): T =>
  JSURL.tryParse(encodedParams, defaultState, { deURI: true });

/**
 * Generates a query string that will be parsable back into the state object passed in 'stateValue'
    Used for generating links to pages which use getQueryStringForState and pre-populating a state (e.g. applied search filters)
 * @param stateValue The state which will be encoded into the query string
 * @param currentSearchParams The current state of the URL (before the new state from'stateValue' is set).
      Empty by default, but needed to preserve any existing query paramters (i.e. ones not set by useQueryStringState)
 */
export function getQueryStringForState(
  stateValue: any,
  currentSearchParams: URLSearchParams = new URLSearchParams()
) {
  currentSearchParams.set(paramSymbol, encodeParam(stateValue));
  // Individually URI encoding params because UrlSearchParams.toString
  // uses application/x-www-form-urlencoded which is overly verbose
  const queryString = Array.from(currentSearchParams.entries())
    .map(param => `${param[0]}=${encodeURIComponent(param[1])}`)
    .join('&');
  return queryString;
}

/**
 * Custom hook that stores an object of type T representing some state in a querystring parameter in the URL.
 * Can be used in two places for two components to share the same state getters/setters, but not recommended - best
 * used in single place with getters/setters passed down the component tree as needed.
 * @param defaultState The initial state if no state found in URL
 */
export function useQueryStringState<T>(
  defaultState: T
): [T, (value: T) => void] {
  const { search } = useLocation();
  const history = useHistory();
  const { url } = useRouteMatch();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const stateParam = searchParams.get(paramSymbol);

  const latestState = tryDecodeParam(stateParam, defaultState);

  const [state, setState] = useState<T>(latestState);

  useEffect(() => {
    setState(latestState);
  }, [searchParams]);

  function setQueryState(stateValue: T) {
    const queryString = getQueryStringForState(stateValue, searchParams);
    history.replace(`${url}?${queryString.toString()}`);
  }

  return [state, setQueryState];
}
