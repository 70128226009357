import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { appointmentDomain } from 'models';
import { getDomainSlice } from 'store';
import CustomButton from 'components/Button';
import { CustomButtonProps } from 'components/Button/Button';

const useStyles = makeStyles(theme => ({
  atRiskButton: {
    color: theme.palette.aegis.semantic.warning.alt,
    '&, &:hover': {
      borderColor: theme.palette.aegis.semantic.warning.alt
    },
    '&:hover': {
      backgroundColor: theme.palette.aegis.semantic.warning.translucent
    },
    '& .MuiTouchRipple-child': {
      backgroundColor: theme.palette.aegis.semantic.warning.alt
    }
  }
}));

interface AtRiskButtonProps extends CustomButtonProps {
  appointmentId: string;
  atRisk: boolean;
  onSuccess: () => void;
}

export default function AtRiskButton({
  appointmentId,
  atRisk,
  onSuccess,
  ...props
}: AtRiskButtonProps) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { actions } = getDomainSlice(appointmentDomain);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(false);
  }, [atRisk]);

  const handleOnClick = () => {
    dispatch(actions.custom?.setAtRisk(appointmentId, !atRisk, onSuccess));
    setIsLoading(true);
  };

  return (
    <CustomButton
      disabled={isLoading}
      onClick={handleOnClick}
      {...props}
      className={`${classes.atRiskButton} ${props.className}`}
      size="medium"
    >
      {atRisk ? 'Remove at risk' : 'Mark at risk'}
    </CustomButton>
  );
}
