import React, { useEffect } from 'react';

import { Router } from 'routes';
import { useDispatch, useSelector } from 'react-redux';
import keycloak, { initConfig as keycloakInitConfig } from 'auth/keycloak';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { identifyLaunchDarklyUser } from 'LaunchDarkly';

import { commonErrorHandling } from 'store/utils';
import { getSlice, RootState } from './store';
import { ReadyState } from './store/slices/app';
import { Loading } from './layout/Loading/Loading';

const isAppReady = (ready: ReadyState) =>
  Object.values(ready).reduce(
    (prevValue, currentValue) => prevValue && currentValue
  );

const App = () => {
  const ldClient = useLDClient();
  const dispatch = useDispatch();
  const { selector: appSelector, actions: appActions } = getSlice('app');
  const appState = useSelector<RootState, ReturnType<typeof appSelector>>(
    appSelector
  );

  const { actions: userActions } = getSlice('user');

  keycloak.onAuthSuccess = async () => {
    try {
      const userProfile = await keycloak.loadUserProfile();
      dispatch(userActions.updateUserProfile(userProfile, true));
      await identifyLaunchDarklyUser(ldClient, userProfile?.id);
    } catch (profileError) {
      commonErrorHandling(profileError);
    }
  };

  useEffect(() => {
    keycloak
      .init(keycloakInitConfig)
      .then(() => {
        dispatch(appActions.updateReadiness('keycloak', true));
      })
      .catch(() => {
        commonErrorHandling(new Error('keycloak failed to initialise'));
      });
  }, [keycloak]);

  return isAppReady(appState.ready) ? <Router /> : <Loading />;
};

export default App;
