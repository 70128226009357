import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';

import Typography from 'components/Typography';
import { DATE_ISO_SHORT } from 'constants/dates';
import {
  EngineerCalendarDay,
  useEngineerCalendarStyles
} from './EngineerCalendarBody';
import { EngineerCalendarContext } from './EngineerCalendar';

const useStyles = makeStyles(
  theme => ({
    root: {
      display: 'flex'
    },
    capacityCell: {
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-start'
    },
    capacityFraction: {
      color: theme.palette.aegis.semantic.info.base
    }
  }),
  { name: 'EngineerCalendarCapacity' }
);

function EngineerCalendarCapacityCell({
  children = null,
  testId
}: {
  children?: React.ReactNode;
  testId: string;
}) {
  const classes = useStyles();
  const calendarClasses = useEngineerCalendarStyles();

  return (
    <Grid
      container
      className={`${calendarClasses.cell} ${classes.capacityCell}`}
      data-testid={testId}
    >
      {children}
    </Grid>
  );
}

function NoDataAvailable({ testId }: { testId: string }) {
  return (
    <EngineerCalendarCapacityCell testId={testId}>
      <Typography>No</Typography> <Typography>data</Typography>{' '}
      <Typography>available</Typography>
    </EngineerCalendarCapacityCell>
  );
}

function EngineerCalendarCapacity({ days }: { days: EngineerCalendarDay[] }) {
  const { calendarCapacity } = React.useContext(EngineerCalendarContext);
  const classes = useStyles();

  return (
    <div className={classes.root} data-testid="engineerCalendarCapacity_root">
      {days?.map(day => {
        const key = `${day.labels.day}-capacity`;
        const dayCapacityTestId = `engineerCalendarCapacity_${day.labels.day}`;

        if (
          (!calendarCapacity.data || calendarCapacity.isLoading) &&
          !calendarCapacity.error
        ) {
          return (
            <EngineerCalendarCapacityCell
              testId={dayCapacityTestId}
              key={key}
            />
          );
        }

        // Requests failed or there's no capacity data
        if (calendarCapacity.error || calendarCapacity.data?.length === 0) {
          return <NoDataAvailable testId={dayCapacityTestId} key={key} />;
        }

        const currentCapacity = calendarCapacity.data?.find(
          capacityDay => capacityDay.date === day.date.toFormat(DATE_ISO_SHORT)
        );
        // Engineer isn't working for that day
        if (!currentCapacity) {
          return (
            <EngineerCalendarCapacityCell testId={dayCapacityTestId} key={key}>
              <Typography>Not</Typography> <Typography>working</Typography>
            </EngineerCalendarCapacityCell>
          );
        }

        const { bookedSlots, totalCapacity, utilisation } = currentCapacity;

        // No capacity data
        if (
          bookedSlots === undefined ||
          totalCapacity === undefined ||
          utilisation === undefined
        ) {
          return <NoDataAvailable testId={dayCapacityTestId} key={key} />;
        }

        return (
          <EngineerCalendarCapacityCell testId={dayCapacityTestId} key={key}>
            <Typography
              variant="h3"
              component="div"
              className={classes.capacityFraction}
            >
              {bookedSlots}/{totalCapacity}
            </Typography>
            <Typography>{utilisation}%</Typography>
          </EngineerCalendarCapacityCell>
        );
      })}
    </div>
  );
}

export default EngineerCalendarCapacity;
