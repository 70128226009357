/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FORM_ERROR } from 'final-form';

import Engineer from 'models/Engineers';
import { DomainItem } from 'models/Domain';
import { AppThunk } from 'store';
import { FormValues } from 'components/Forms';
import {
  initialStateSlice,
  StateSlice,
  startLoading,
  loadingFailed,
  getPaginationReducers
} from 'store/slices';
import { engineerDomain } from 'models';
import {
  fetchThunk,
  fetchItemThunk,
  deleteItemThunk,
  addItemThunk,
  editItemThunk,
  QueryParams,
  createFilterQuery,
  PaginationOptions,
  sortEngineers,
  createUrl,
  handleThunkError,
  fetchAllThunk,
  AddItemThunkOptions
} from 'store/utils';
import http from 'api/http';
import SkillCategory from 'models/SkillCategory';
import { setSnackbar, SnackbarStatus } from './snackbar';

const engineers = createSlice({
  name: engineerDomain.plural,
  initialState: initialStateSlice,
  reducers: {
    ...startLoading<Engineer>(),
    ...loadingFailed<Engineer>(),
    ...getPaginationReducers<Engineer>(),
    getEngineersSuccess(
      state,
      { payload }: PayloadAction<StateSlice<Engineer>>
    ) {
      const { items, page } = payload;
      state.items = sortEngineers(items);
      state.page = page;
      state.isLoading = false;
      state.error = null;
    },
    getEngineerSuccess(
      state,
      { payload: engineerItem }: PayloadAction<Engineer>
    ) {
      if (state.items != null) {
        state.items = state.items.filter(
          engineer => engineer.id !== engineerItem.id
        );
        state.items.push(engineerItem);
        state.items = sortEngineers(state.items);
      }
      state.isLoading = false;
      state.error = null;
    },
    addEngineerSuccess(
      state,
      { payload: addedEngineer }: PayloadAction<Engineer>
    ) {
      // We could check here if we already have a engineer with the same id
      // in the store, but I'll leave that up to the API to decide
      //
      // We could also either push the new item into state or dispatch `fetchEngineers`
      // after `addEngineerSuccess` in the thunk to refresh the state
      if (state.items != null) {
        state.items.push(addedEngineer);
        state.items = sortEngineers(state.items);
      }

      state.isLoading = false;
      state.error = null;
    },
    editEngineerSuccess(state) {
      // Removing the update state from this function as the request to edit an engineer
      // does not use the same DTO as the fetch engineer request - the skills list in
      // the request is a list of uuids, rather than a list of Skill objects.
      // this was broken previously due to a separate issue with how we dispatched success
      // reducers which has now been fixed. Anywhere that the edit engineer api is called
      // will need to also refetch the engineer to get the updated state

      state.isLoading = false;
      state.error = null;
    },
    deleteEngineerSuccess(
      state,
      { payload: deletedEngineerId }: PayloadAction<string>
    ) {
      if (state.items != null) {
        state.items = state.items.filter(
          engineer => engineer.id !== deletedEngineerId
        );
      }

      state.isLoading = false;
      state.error = null;
    }
  }
});

export const {
  requestStart,
  requestFail,
  setPagination: setEngineersPagination,
  getEngineersSuccess,
  addEngineerSuccess,
  editEngineerSuccess,
  deleteEngineerSuccess,
  getEngineerSuccess
} = engineers.actions;

export default engineers.reducer;

export function fetchEngineer(id: string): AppThunk {
  return fetchItemThunk<Engineer>(
    engineerDomain,
    requestStart,
    requestFail,
    getEngineerSuccess,
    id
  );
}
export function fetchAllEngineers(): AppThunk {
  return fetchAllThunk(engineerDomain, {
    start: requestStart,
    success: getEngineersSuccess,
    fail: requestFail
  });
}
export function fetchEngineers(
  query?: QueryParams,
  paginationOptions?: PaginationOptions<Engineer>
): AppThunk {
  const queryParams = query
    ? { params: createFilterQuery(query), ...paginationOptions }
    : paginationOptions;

  return fetchThunk<StateSlice<Engineer>>({
    domain: engineerDomain,
    requestStart,
    requestFail,
    requestSuccess: getEngineersSuccess,
    queryParams
  });
}

export function addEngineer(
  item: FormValues,
  options?: Partial<AddItemThunkOptions<Engineer>>
): AppThunk {
  return addItemThunk<Engineer>({
    domain: engineerDomain,
    requestStart,
    requestFail,
    requestSuccess: addEngineerSuccess,
    item,
    ...options
  });
}

export function editEngineer(item: FormValues, itemId: string): AppThunk {
  return editItemThunk<Engineer>({
    domain: engineerDomain,
    requestStart,
    requestFail,
    requestSuccess: editEngineerSuccess,
    itemId,
    item
  });
}

interface SkillFormValue {
  category: SkillCategory;
  label: string;
  value: string;
}

const mapEngineerSkillsToApiRequestFormat = (values: FormValues) => ({
  ...values,
  skills: (values?.skills as SkillFormValue[]).map(skill => skill.value)
});

export function editEngineerWithRefresh(
  engineerValues: FormValues,
  engineerId: string
): AppThunk {
  return async dispatch => {
    try {
      dispatch(requestStart());

      const url = createUrl(engineerDomain, undefined, engineerId);

      const apiFormattedRequestData = mapEngineerSkillsToApiRequestFormat(
        engineerValues
      );

      await http.put<DomainItem>(url, apiFormattedRequestData);

      dispatch(editEngineerSuccess());

      dispatch(
        setSnackbar({
          message: 'Success: item successfully edited',
          show: true,
          status: SnackbarStatus.Success
        })
      );

      return dispatch(fetchEngineer(engineerId));
    } catch (error) {
      handleThunkError({
        error,
        requestFail,
        dispatch,
        domainType: engineerDomain.type
      });

      return { [FORM_ERROR]: error.message };
    }
  };
}

export function deleteEngineer(domainItem: DomainItem): AppThunk {
  return deleteItemThunk<Engineer>({
    domain: engineerDomain,
    requestStart,
    requestFail,
    requestSuccess: deleteEngineerSuccess,
    itemId: domainItem.id
  });
}
