import React from 'react';
import {
  Form,
  FormValues,
  Field,
  Radio,
  ConditionalField
} from 'components/Forms';
import Capacity from 'models/Capacity';
import Typography from 'components/Typography/Typography';
import { DateTime } from 'luxon';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Field as FinalFormField } from 'react-final-form';
import { FormRefProp } from 'components/Modal';
import ErrorDisplay from 'components/ErrorDisplay';
import {
  buildJobFormFields,
  vulnerabilitiesField,
  vulnerabilitiesLabelMap,
  minimalField,
  otherVulnerabilityField,
  addJobFormValidation
} from './JobFormConfig';
import { useAddJobModalStyles } from './AddJobModal';

interface JobFormProps extends FormRefProp {
  capacityData: Capacity[];
  selectedDate: DateTime;
  setSelectedDate: (date: DateTime) => void;
  postCode: string;
  initialValues: FormValues;
  jobTypeData: { id: string | null; name: string | null };
  saveJobData: (values: FormValues) => void;
  setCapacitySuccess: (success: boolean) => void;
  setIsCapacitySubmitting: (isSubmitting: boolean) => void;
}

export default function JobForm({
  capacityData,
  postCode,
  initialValues,
  jobTypeData,
  selectedDate,
  setSelectedDate,
  saveJobData,
  setCapacitySuccess,
  setIsCapacitySubmitting,
  formRef
}: JobFormProps) {
  const { aegisEnableCustomerVulnerabilitiesOnCreateJobForm } = useFlags();
  const evForm = Boolean(jobTypeData.name?.startsWith('EV'));
  const classes = useAddJobModalStyles();
  const fields = buildJobFormFields(
    capacityData,
    selectedDate,
    setSelectedDate,
    evForm
  );
  const [jobDetailsFields, cutomerDetailsFields, accountDetailsFields] = fields;

  return (
    <>
      <div className={classes.infoContainer}>
        <div className={classes.info}>
          <Typography>Postcode:</Typography>
          {postCode && <Typography>{postCode.toLocaleUpperCase()}</Typography>}
        </div>
        <div className={classes.info}>
          <Typography>Job type:</Typography>
          {jobTypeData?.name && <Typography>{jobTypeData?.name}</Typography>}
        </div>
      </div>

      <Form
        validationFunc={addJobFormValidation(
          evForm,
          aegisEnableCustomerVulnerabilitiesOnCreateJobForm
        )}
        initialValues={initialValues}
        onSubmit={(formValues: FormValues) => {
          saveJobData({
            ...formValues,
            jobType: jobTypeData,
            postcode: postCode
          });
        }}
        goBackToPreviousPage={false}
      >
        {({ form, submitting, submitSucceeded, submitErrors }) => {
          setCapacitySuccess(!submitErrors && submitSucceeded);
          setIsCapacitySubmitting(submitting);

          // eslint-disable-next-line no-param-reassign
          formRef.current = form;

          const formState = form.getState();

          return (
            <>
              {jobDetailsFields.map(field => (
                <Field key={field.id} field={field} />
              ))}
              <Typography variant="h2" className={classes.formSectionHeading}>
                Customer details
              </Typography>
              {cutomerDetailsFields.map(field => (
                <Field key={field.id} field={field} />
              ))}
              {aegisEnableCustomerVulnerabilitiesOnCreateJobForm ? (
                <>
                  <FinalFormField
                    name={vulnerabilitiesField.name}
                    render={({ meta }) => (
                      <Radio meta={meta} field={vulnerabilitiesField} />
                    )}
                  />
                  <ConditionalField when={vulnerabilitiesField.name} is="true">
                    <>
                      <Typography>
                        Choose all that apply from the list below
                      </Typography>
                      {formState.errors?.vulnerabilitiesGroup ? (
                        <ErrorDisplay
                          status={{
                            message: 'At least one of these fields is required'
                          }}
                        />
                      ) : null}
                      {Object.entries(vulnerabilitiesLabelMap).map(
                        ([fieldName, label]) => (
                          <Field
                            key={fieldName}
                            field={minimalField(fieldName, label, 'checkbox')}
                          />
                        )
                      )}
                      <ConditionalField when="hasOtherVulnerability" is>
                        <Field
                          key={otherVulnerabilityField.id}
                          field={otherVulnerabilityField}
                        />
                      </ConditionalField>
                    </>
                  </ConditionalField>
                </>
              ) : null}
              <Typography variant="h2" className={classes.formSectionHeading}>
                Account details
              </Typography>
              {accountDetailsFields.map(field =>
                field.conditional ? (
                  <ConditionalField
                    {...field.conditional}
                    key={`conditionalField-${field.id}`}
                  >
                    <Field key={field.id} field={field} />
                  </ConditionalField>
                ) : (
                  <Field key={field.id} field={field} />
                )
              )}
            </>
          );
        }}
      </Form>
    </>
  );
}
