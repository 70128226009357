import { Checkbox, Chip, Paper } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import AddAdjustment, {
  AddAdjustmentButtons
} from 'components/Adjustment/AddAdjustment';
import EditAdjustment from 'components/Adjustment/EditAdjustment';
import { processFormFields } from 'components/Adjustment/utils';
import Button from 'components/Button';
import EngineerCard from 'components/Card/EngineerCard';
import { renderErrorOrLoading } from 'components/Crud';
import ReadView from 'components/Crud/views/Read';
import { InputFieldProps } from 'components/Forms';
import { FormApiWithRestart } from 'components/Forms/Form';
import Modal from 'components/Modal';
import Section from 'components/Section';
import Timetable from 'components/Timetable';
import Typography from 'components/Typography';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { DateTime } from 'luxon';
import { engineerDomain, adjustmentDomain } from 'models';
import Adjustment, { AdjustmentType } from 'models/Adjustment';
import { Domain, DomainItem } from 'models/Domain';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { RootState } from 'store';
import { useAdjustmentsState, useEngineerData } from 'store/selectors';
import { adjustmentTypeFilter } from 'utils/adjustments';
import {
  efficiencyAdjustmentFormFields,
  efficiencyValidationSchema,
  patchAdjustmentFormFields,
  patchesValidationSchema,
  scheduleAdjustmentFormFieldsOldView,
  shiftValidationSchema
} from './Adjustments/AdjustmentFormConfig';
import {
  EfficiencyAdjustmentsContainer,
  PatchAdjustmentsContainer,
  ShiftPatternAdjustmentsContainer
} from './Adjustments/AdjustmentWrapper';
import EngineerCalendar, {
  EngineerCalendarDatesProvider
} from './EngineerCalendar/EngineerCalendar';
import { adjustmentsSlice, PatchesSelector, patchesSlice } from './store';
import { useStyles } from './styles';
import { efficiencyToLabel, EngineerAdjustments } from './utils';

const addScheduleAdjustment = 'Add schedule adjustment';
const addEfficiencyAdjustment = 'Add efficiency adjustment';
const addPatchAdjustment = 'Add patch adjustment';

function EngineerView() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { id: itemId } = useParams<{ id: string }>();
  const { aegisEnableNewEngineerProfilePageToggle } = useFlags();

  const adjustmentsState = useAdjustmentsState();

  const patchesSelector = useSelector<RootState, PatchesSelector>(
    patchesSlice.selector
  );

  const [adjustmentToEdit, setAdjustmentToEdit] = useState('');
  const [adjustmentTypeToEdit, setAdjustmentTypeToEdit] = useState('');
  const [showHistoricAdjustments, setShowHistoricAdjustments] = useState(false);
  const [showNewEngineerProfile, setShowNewEngineerProfile] = useState(true);

  const filterHistoricAdjustments = (adjustment: Adjustment): boolean => {
    const startOfDay = DateTime.now().startOf('day');
    return (
      showHistoricAdjustments ||
      DateTime.fromISO(adjustment.endDate) >= startOfDay
    );
  };

  const sortAdjustmentsByOldestToNewest = (a: Adjustment, b: Adjustment) =>
    DateTime.fromISO(a.startDate).diff(DateTime.fromISO(b.startDate))
      .milliseconds;

  const patchAdjustments: EngineerAdjustments = adjustmentsState.items
    .filter(adjustmentTypeFilter(AdjustmentType.Patch))
    .filter(filterHistoricAdjustments)
    .sort(sortAdjustmentsByOldestToNewest);

  const scheduleAdjustments: EngineerAdjustments = adjustmentsState.items
    .filter(adjustmentTypeFilter(AdjustmentType.Shift))
    .filter(filterHistoricAdjustments)
    .sort(sortAdjustmentsByOldestToNewest);

  const efficiencyAdjustments: EngineerAdjustments = adjustmentsState.items
    .filter(adjustmentTypeFilter(AdjustmentType.Efficiency))
    .filter(filterHistoricAdjustments)
    .sort(sortAdjustmentsByOldestToNewest);

  const engineerData = useEngineerData(itemId);

  useEffect(() => {
    if (itemId) {
      dispatch(adjustmentsSlice.actions.get.list(itemId));
      if (patchesSlice.actions.changePagination) {
        dispatch(
          patchesSlice.actions.changePagination({
            pageIndex: 0,
            pageSize: 10000
          })
        );
      }
      dispatch(patchesSlice.actions.get.list());
    }
  }, []);

  const [opened, setOpened] = useState<string | false>(
    patchesSelector.isLoading && ''
  );
  const handleModalClose = () => {
    setOpened(false);
    dispatch(adjustmentsSlice.actions.get.list(itemId));
  };

  const history = useHistory();
  const formRef = React.useRef<FormApiWithRestart>();

  const onViewAppointments = (domainItem: DomainItem) => () => {
    history.push(`${domainItem.id}/appointments`, domainItem);
  };
  const onAdjustmentDelete = (adjustment: DomainItem) => () => {
    dispatch(adjustmentsSlice.actions.delete(adjustment, itemId));
  };

  const handleAdjustmentEdit = (id: string, type: string) => {
    setAdjustmentToEdit(id);
    setAdjustmentTypeToEdit(type);
  };

  const handleAdjustmentEditCancel = () => {
    setAdjustmentToEdit('');
    setAdjustmentTypeToEdit('');
    dispatch(adjustmentsSlice.actions.get.list(itemId));
  };

  const handleShowHistoricAdjustments = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setShowHistoricAdjustments(event.target.checked);
  };

  const handleToggleNewEngineerProfile = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setShowNewEngineerProfile(event.target.checked);
  };

  const editAdjustment = (parentId: string, id: string, type: string) => {
    let adjustment: Adjustment | undefined;
    let validationSchema: any;
    let formFieldsFn: InputFieldProps[];
    let domain: Domain;
    let fieldsType: AdjustmentType;
    let timetable = false;
    let title: string;

    if (type === AdjustmentType.Patch) {
      domain = adjustmentDomain;
      adjustment = patchAdjustments.find(current => current.id === id);
      validationSchema = patchesValidationSchema();
      formFieldsFn = patchAdjustmentFormFields(patchesSelector.items, true);
      fieldsType = AdjustmentType.Patch;
      title = 'Edit Patch Adjustment';
    } else if (type === AdjustmentType.Shift) {
      domain = adjustmentDomain;
      formFieldsFn = scheduleAdjustmentFormFieldsOldView();
      adjustment = scheduleAdjustments.find(current => current.id === id);
      validationSchema = shiftValidationSchema;
      fieldsType = AdjustmentType.Shift;
      timetable = true;
      title = 'Edit Schedule Adjustment';
    } else {
      domain = adjustmentDomain;
      formFieldsFn = efficiencyAdjustmentFormFields();
      adjustment = efficiencyAdjustments.find(current => current.id === id);
      validationSchema = efficiencyValidationSchema;
      fieldsType = AdjustmentType.Efficiency;
      title = 'Edit Efficiency Adjustment';
    }

    return (
      <Modal
        title={title}
        testId="addJobModal"
        open={Boolean(adjustmentToEdit)}
        onClose={handleAdjustmentEditCancel}
        modalActions={
          <>
            <Button
              color="secondary"
              size="small"
              onClick={handleAdjustmentEditCancel}
              data-testid="editAdjustmentCancelBtn"
            >
              Cancel
            </Button>
            <Button
              color="primary"
              size="small"
              onClick={() => formRef.current?.submit()}
              data-testid="editAdjustmentSubmitBtn"
            >
              Save
            </Button>
          </>
        }
      >
        <EditAdjustment
          domain={domain}
          fields={formFieldsFn}
          adjustment={adjustment as Adjustment}
          adjustmentType={type}
          validationSchema={validationSchema}
          parentId={parentId}
          timetable={timetable}
          presubmitCallback={processFormFields(fieldsType)}
          formRef={formRef}
        />
      </Modal>
    );
  };

  const resetFormOnCancel = () => {
    formRef?.current?.restart();
    handleModalClose();
  };

  return (
    <ReadView domain={engineerDomain}>
      {() =>
        engineerData === undefined ? null : (
          <>
            <div className={classes.engineerCardContainer}>
              <EngineerCard engineerId={itemId} />
            </div>

            {aegisEnableNewEngineerProfilePageToggle ? (
              <Grid
                item
                container
                spacing={4}
                justifyContent="center"
                className={classes.engineerContainer}
              >
                <Paper
                  elevation={2}
                  className={classes.newEngineerProfileSection}
                  data-testid="newEngineerProfileToggle"
                >
                  <Grid item xs={12} container spacing={3} alignItems="center">
                    <Checkbox
                      id="showNewEngineerProfilePageCheckbox"
                      onChange={handleToggleNewEngineerProfile}
                      data-testid="showNewEngineerProfilePageCheckbox"
                      checked={showNewEngineerProfile}
                    />
                    <label htmlFor="showNewEngineerProfilePageCheckbox">
                      <Typography
                        className={classes.labelText}
                        data-testid="showEngineerView"
                      >
                        Show new engineer view
                      </Typography>
                    </label>
                    <Button
                      color="secondary"
                      className={classes.viewAppointmentsButton}
                      onClick={onViewAppointments(engineerData)}
                      data-testid="viewAppointments"
                    >
                      View appointments
                    </Button>
                  </Grid>
                </Paper>
              </Grid>
            ) : null}
            <Grid
              item
              container
              spacing={4}
              justifyContent="center"
              className={classes.engineerContainer}
            >
              {!showNewEngineerProfile ? (
                <>
                  <Section gridProps={{ xs: 12 }}>
                    <Grid
                      item
                      xs={12}
                      container
                      spacing={4}
                      alignItems="center"
                    >
                      <Checkbox
                        onChange={handleShowHistoricAdjustments}
                        data-testid="showHistoricAdjustmentsCheckbox"
                      />

                      <Typography>Show historic adjustments</Typography>
                    </Grid>
                  </Section>
                  <>
                    <Section
                      title="Patches"
                      gridProps={{ xs: 12 }}
                      testId="patchAdjustmentsSection"
                    >
                      <Grid item xs={12}>
                        <Grid item data-qa-id="engineerDefaultPatch">
                          <Chip
                            key={engineerData.defaultPatch.id}
                            label={engineerData.defaultPatch.name}
                          />
                        </Grid>
                      </Grid>
                      <Typography
                        component="h3"
                        className={classes.subheader}
                        display="block"
                      >
                        Adjustments
                      </Typography>
                      <Grid item xs={12} data-qa-id="patchAdjustments">
                        {renderErrorOrLoading(
                          adjustmentsState.isLoading ||
                            patchesSelector.isLoading,
                          'patchAdjustmentSpinner',
                          adjustmentsState.error || patchesSelector.error,
                          {}
                        )}
                        <PatchAdjustmentsContainer
                          adjustments={patchAdjustments}
                          patches={patchesSelector.items}
                          onDelete={onAdjustmentDelete}
                          onEdit={(id: string, type: string) =>
                            handleAdjustmentEdit(id, type)
                          }
                        />
                      </Grid>
                      <Modal
                        title="New Patch Adjustment"
                        testId="addPatchAdjustmentModal"
                        onClose={resetFormOnCancel}
                        open={opened === addPatchAdjustment}
                        modalActions={
                          <AddAdjustmentButtons
                            onSave={() => formRef.current?.submit()}
                            onCancel={resetFormOnCancel}
                          />
                        }
                      >
                        <AddAdjustment
                          domain={adjustmentDomain}
                          fields={patchAdjustmentFormFields(
                            patchesSelector.items
                          )}
                          validationSchema={patchesValidationSchema()}
                          parentId={engineerData.id}
                          presubmitCallback={processFormFields(
                            AdjustmentType.Patch
                          )}
                          formRef={formRef}
                        />
                      </Modal>
                    </Section>
                    <Section
                      title="Weekly Schedule"
                      gridProps={{ xs: 12 }}
                      testId="scheduleAdjustmentsSection"
                    >
                      <Timetable
                        qaId="engineerShiftPattern"
                        shiftPattern={engineerData.defaultShiftPattern}
                      />
                      <Typography
                        component="h3"
                        className={classes.subheader}
                        display="block"
                      >
                        Adjustments
                      </Typography>
                      <Grid item xs={12} data-qa-id="shiftPatternAdjustments">
                        {renderErrorOrLoading(
                          adjustmentsState.isLoading,
                          'shiftPatternAdjustmentSpinner',

                          adjustmentsState.error,
                          {}
                        )}
                        <ShiftPatternAdjustmentsContainer
                          adjustments={scheduleAdjustments}
                          onDelete={onAdjustmentDelete}
                          onEdit={(id: string, type: string) =>
                            handleAdjustmentEdit(id, type)
                          }
                        />
                      </Grid>
                      <Modal
                        title="New Schedule Adjustment"
                        testId="addScheduleAdjustmentModal"
                        open={opened === addScheduleAdjustment}
                        onClose={resetFormOnCancel}
                        modalActions={
                          <AddAdjustmentButtons
                            onSave={() => formRef.current?.submit()}
                            onCancel={resetFormOnCancel}
                          />
                        }
                      >
                        <AddAdjustment
                          domain={adjustmentDomain}
                          fields={scheduleAdjustmentFormFieldsOldView()}
                          validationSchema={shiftValidationSchema}
                          parentId={engineerData.id}
                          timetable
                          presubmitCallback={processFormFields(
                            AdjustmentType.Shift
                          )}
                          formRef={formRef}
                        />
                      </Modal>
                    </Section>
                    <Section
                      title="Efficiency"
                      gridProps={{ xs: 12 }}
                      testId="efficiencyAdjustmentsSection"
                    >
                      <Grid item xs={12}>
                        <Grid item data-qa-id="engineerDefaultEfficiency">
                          <Chip
                            label={efficiencyToLabel(
                              engineerData.defaultEfficiency
                            )}
                          />
                        </Grid>
                      </Grid>
                      <Typography
                        component="h3"
                        className={classes.subheader}
                        display="block"
                      >
                        Adjustments
                      </Typography>
                      <Grid item xs={12} data-qa-id="efficiencyAdjustments">
                        {renderErrorOrLoading(
                          adjustmentsState.isLoading,
                          'efficiencyAdjustmentSpinner',

                          adjustmentsState.error,
                          {}
                        )}
                        <EfficiencyAdjustmentsContainer
                          adjustments={efficiencyAdjustments}
                          onDelete={onAdjustmentDelete}
                          onEdit={(id: string, type: string) =>
                            handleAdjustmentEdit(id, type)
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Modal
                          title="New Efficiency Adjustment"
                          testId="addEfficiencyAdjustmentModal"
                          open={opened === addEfficiencyAdjustment}
                          onClose={resetFormOnCancel}
                          modalActions={
                            <AddAdjustmentButtons
                              onSave={() => formRef.current?.submit()}
                              onCancel={resetFormOnCancel}
                            />
                          }
                        >
                          <AddAdjustment
                            domain={adjustmentDomain}
                            fields={efficiencyAdjustmentFormFields()}
                            validationSchema={efficiencyValidationSchema}
                            parentId={engineerData.id}
                            presubmitCallback={processFormFields(
                              AdjustmentType.Efficiency
                            )}
                            formRef={formRef}
                          />
                        </Modal>
                      </Grid>
                    </Section>
                  </>
                </>
              ) : null}
              {adjustmentToEdit &&
                editAdjustment(
                  engineerData.id,
                  adjustmentToEdit,
                  adjustmentTypeToEdit
                )}
              {showNewEngineerProfile ? (
                <EngineerCalendarDatesProvider>
                  <EngineerCalendar />
                </EngineerCalendarDatesProvider>
              ) : null}
            </Grid>
          </>
        )
      }
    </ReadView>
  );
}

export default EngineerView;
