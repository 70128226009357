/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["formRef"] }] */

import { IconButton, makeStyles } from '@material-ui/core';
import Button from 'components/Button';
import {
  Field,
  FieldInputValue,
  Form,
  FormValues,
  yupSchemaToValidationFunc
} from 'components/Forms';
import DeleteIcon from '@material-ui/icons/Delete';
import { FormApiWithRestart } from 'components/Forms/Form';
import Modal, { useModalStyles } from 'components/Modal';
import Typography from 'components/Typography';
import { DateTime, Interval } from 'luxon';
import Adjustment, {
  AdjustmentPriority,
  AdjustmentType,
  AdjustmentChangeType,
  Pattern
} from 'models/Adjustment';
import React, { useContext, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useAdjustmentsState, useSkillsData } from 'store/selectors';
import {
  adjustmentTypeFilter,
  getAdjustmentForDay,
  getAdjustmentGroup
} from 'utils/adjustments';
import { dateToDayOfWeek, getFirstWeekAsDateArray } from 'utils/dates';
import { EngineerCalendarContext } from 'views/Engineers/EngineerCalendar/EngineerCalendar';
import { adjustmentGroupsSlice } from 'views/Engineers/store';
import { FormSpy } from 'react-final-form';
import { fetchAllSkills } from 'store/slices/skills';
import AdjustmentGroup from 'models/AdjustmentGroup';
import Skill from 'models/Skills';
import {
  AdjustmentFormButtonActions,
  TemporaryAdjustmentFormContext,
  TemporaryAdjustmentFormModalProps
} from '../AdjustmentFormModals';
import { AdjustmentFormCommonFieldName } from './adjustmentFormCommonConfig';

import {
  skillsFormCommonFields,
  skillsFormInitialValues,
  skillsFormValidationSchema,
  skillsFormWeekdayFields,
  getDayFieldName
} from './skillsChangeFormConfig';
import { useAdjustmentFormStyles } from './FormStyles';
import { formSpyRelevantDays, useFormDaysOfWeek } from './useFormDaysOfWeek';

function mapSkillsIdsToSkillsInAdjustmentGroup(
  skillsData: Skill[],
  adjustmentGroup?: AdjustmentGroup
) {
  if (!adjustmentGroup) return undefined;

  return adjustmentGroup?.adjustments.map(adj => {
    const changes = adj.changes.map(change => {
      const skillIds = change.change as string[];
      const skills = skillIds.map(skillId =>
        skillsData.find(skill => skill.id === skillId)
      );
      return {
        ...change,
        change: skills
      };
    });
    return {
      ...adj,
      changes
    };
  });
}

const useStyles = makeStyles(
  {
    previousButton: {
      marginRight: 'auto'
    }
  },
  { name: 'TemporarySkillsChangeModal' }
);

function TemporarySkillsFormModalButtons({
  onPrevious,
  onCancel,
  onSubmit,
  showPrevious
}: AdjustmentFormButtonActions) {
  const classes = useStyles();

  return (
    <>
      {showPrevious ? (
        <Button
          key="skillsChangeModalPreviousButton"
          color="secondary"
          size="small"
          onClick={onPrevious}
          data-testid="skillsChangeModalPreviousButton"
          className={classes.previousButton}
        >
          Previous
        </Button>
      ) : null}

      <Button
        key="skillsChangeModalCancelButton"
        color="secondary"
        size="small"
        onClick={onCancel}
        data-testid="skillsChangeModalCancelButton"
      >
        Cancel
      </Button>

      <Button
        key="skillsChangeModalSubmitButton"
        color="primary"
        size="small"
        onClick={onSubmit}
        data-testid="skillsChangeModalSubmitButton"
      >
        Save
      </Button>
    </>
  );
}

export default function TemporarySkillsAdjustmentModal({
  onPrevious,
  onDelete
}: TemporaryAdjustmentFormModalProps) {
  const [formDates, setValueFormSpy, formDaysOfWeek] = useFormDaysOfWeek();
  const { engineerData } = useContext(EngineerCalendarContext);
  const { isOpen, setIsOpen, adjustment } = useContext(
    TemporaryAdjustmentFormContext
  );
  const dispatch = useDispatch();
  const classes = useAdjustmentFormStyles();
  const skillsData = useSkillsData();
  const modalClasses = useModalStyles();
  const formRef = useRef<FormApiWithRestart>();

  useEffect(() => {
    dispatch(fetchAllSkills());
  }, []);

  const handleSubmitButtonClick = () => {
    formRef.current?.submit();
  };

  const handleClose = () => {
    setIsOpen?.(false);
  };

  const adjustmentsState = useAdjustmentsState();
  const skillsAdjustments = adjustmentsState.items.filter(
    adjustmentTypeFilter(AdjustmentType.Skills)
  );

  const adjustmentGroup = adjustment?.groupId
    ? getAdjustmentGroup(skillsAdjustments, adjustment.groupId)
    : undefined;

  const skillsFormSubmitHandler = (fieldValues: FormValues) => {
    const startDate = DateTime.fromISO(
      fieldValues[AdjustmentFormCommonFieldName.ValidFrom] as string
    );
    const endDate = DateTime.fromISO(
      fieldValues[AdjustmentFormCommonFieldName.ValidTo] as string
    );

    const dates = getFirstWeekAsDateArray(
      Interval.fromDateTimes(startDate, endDate)
    );

    const adjustments = dates.reduce((acc: Adjustment[], date) => {
      const day = dateToDayOfWeek(date);

      const adjustmentForDay = getAdjustmentForDay(
        day,
        adjustmentGroup?.adjustments ?? []
      );

      const fieldValuesFormat = date.weekdayLong;
      const currentValue = fieldValues[getDayFieldName(fieldValuesFormat)];

      const optionalId = adjustmentForDay ? { id: adjustmentForDay.id } : {};
      const optionalGroupId = adjustment ? { groupId: adjustment.groupId } : {};

      const mappedChanges: string[] = Array.isArray(currentValue)
        ? (currentValue as FieldInputValue[]).map(item => item.value)
        : [];

      if (Array.isArray(currentValue) && currentValue.length > 0) {
        const adjustmentObj: Adjustment = {
          ...(optionalId as { id: string }),
          ...optionalGroupId,
          reason: fieldValues[AdjustmentFormCommonFieldName.Reason] as string,
          startDate: startDate.toISODate(),
          endDate: endDate.toISODate(),
          pattern: {
            type: Pattern.Weekly,
            weekdays: [day]
          },
          changes: [
            {
              field: AdjustmentType.Skills,
              change: mappedChanges
            }
          ] as AdjustmentChangeType[],
          priority: AdjustmentPriority.Temporary
        };

        return [...acc, adjustmentObj];
      }

      return acc;
    }, [] as Adjustment[]);
    const adjustmentsGroupToSave = { adjustments };

    dispatch(
      adjustment
        ? adjustmentGroupsSlice.actions.edit(
            adjustmentsGroupToSave,
            adjustment.groupId,
            engineerData?.id
          )
        : adjustmentGroupsSlice.actions.add(
            adjustmentsGroupToSave,
            engineerData?.id
          )
    );

    handleClose();
  };

  const mapAdjustmentGroupSkills = {
    ...adjustmentGroup,
    adjustments: mapSkillsIdsToSkillsInAdjustmentGroup(
      skillsData,
      adjustmentGroup
    )
  } as AdjustmentGroup;

  return (
    <Modal
      title="Skill Change"
      testId="skillsAdjustmentFormModal"
      open={isOpen || false}
      onClose={handleClose}
      modalActions={
        <TemporarySkillsFormModalButtons
          onCancel={handleClose}
          onSubmit={handleSubmitButtonClick}
          onPrevious={onPrevious}
          showPrevious
        />
      }
      additionalButtons={
        adjustment ? (
          <IconButton
            aria-label="delete"
            onClick={onDelete}
            className={modalClasses.button}
            data-testid="deleteSkillsAdjustmentIconButton"
          >
            <DeleteIcon />
          </IconButton>
        ) : null
      }
    >
      <Form
        initialValues={skillsFormInitialValues(
          adjustmentGroup && mapAdjustmentGroupSkills
        )}
        validationFunc={yupSchemaToValidationFunc(
          skillsFormValidationSchema && skillsFormValidationSchema
        )}
        onSubmit={skillsFormSubmitHandler}
        goBackToPreviousPage={false}
        keepDirtyOnReinitialize
      >
        {({ form }) => {
          formRef.current = form;

          return (
            <>
              {skillsFormCommonFields.map(field => (
                <Field key={field.id} field={field} />
              ))}
              <Typography variant="h2" className={classes.typography}>
                New Skills
              </Typography>
              <div data-testid="skillsWeekdaySelectsWrapper">
                {formDaysOfWeek.map(day => {
                  return (
                    <div
                      className={classes.weekdaysWrapper}
                      key={`timetableWrapper-${day}`}
                    >
                      <Typography
                        data-testid={`skillsAdjustmentSelector${day}`}
                        key={day}
                        component="label"
                        htmlFor={`skillsChange${day}`}
                      >
                        {day}
                      </Typography>
                      <div className={classes.skillsWeekdaySelectWrapper}>
                        <Field
                          id={`${getDayFieldName(day)}`}
                          multiple
                          field={{
                            ...skillsFormWeekdayFields[day],
                            name: `${getDayFieldName(day)}`,
                            className: classes.skillsWeekdaySelect,
                            options: skillsData.map(
                              ({ id, name, category }) => ({
                                label: name,
                                value: id,
                                category
                              })
                            )
                          }}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
              <FormSpy
                subscription={{
                  values: true
                }}
              >
                {({ values }) => {
                  return formSpyRelevantDays(
                    values,
                    formDates,
                    setValueFormSpy
                  );
                }}
              </FormSpy>
            </>
          );
        }}
      </Form>
    </Modal>
  );
}
