import React from 'react';
import { makeStyles } from '@material-ui/core';
import Typography from 'components/Typography';

const useStyles = makeStyles(() => ({
  wrapper: {
    margin: '1em 0'
  },
  attribute: {
    fontWeight: 'bold'
  },
  value: {}
}));

export const AccountInfo = ({
  attribute,
  value
}: {
  attribute: string;
  value: string;
}) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <Typography className={classes.attribute}>{attribute}:</Typography>
      <Typography className={classes.value}>{value}</Typography>
    </div>
  );
};
