import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import ShiftPattern, { ShiftPatternDay, Weekdays } from 'models/ShiftPattern';
import { capitalise } from 'utils/utils';
import Typography from 'components/Typography';

const containerStyles = (color?: string) =>
  makeStyles(
    theme => ({
      container: {
        backgroundColor: color || theme.palette.divider,
        padding: theme.spacing(2),
        borderRadius: theme.spacing(2),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
      },
      dayScheduleMaxWidth: {
        maxWidth: theme.spacing(15)
      }
    }),
    { name: 'TimeTable' }
  );

const FromToText = ({ day }: { day: ShiftPatternDay }) => (
  <>
    <Typography component="span"> {day.startTime} </Typography>
    <Typography component="span" display="block">
      to
    </Typography>
    <Typography display="block" component="span">
      {day.endTime}
    </Typography>
  </>
);

const DaySchedule = ({
  dayAbbreviation,
  shiftPatternDay,
  qaId,
  className
}: {
  dayAbbreviation: string;
  shiftPatternDay: ShiftPatternDay | null;
  qaId?: string;
  className?: string;
}) => (
  <Grid item xs data-testid={qaId} className={className}>
    <Typography variant="body" align="center">
      <Typography variant="micro" component="strong" display="block">
        {dayAbbreviation}
      </Typography>
      {shiftPatternDay ? (
        <FromToText day={shiftPatternDay} />
      ) : (
        <Typography component="span" display="block">
          Whole day
        </Typography>
      )}
    </Typography>
  </Grid>
);

function Timetable({
  shiftPattern,
  color,
  qaId,
  nested = false
}: {
  shiftPattern: ShiftPattern;
  color?: string;
  qaId: string;
  nested?: boolean;
}) {
  const classes = containerStyles(color)();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { id, name, ...weekdays } = shiftPattern;
  return (
    <Grid
      container
      spacing={2}
      className={nested ? undefined : classes.container}
      data-testid={qaId}
    >
      {Object.keys(weekdays)
        .filter(key => key !== 'id' && key !== 'name')
        .map(weekday => {
          const day = weekday.toLowerCase();
          return (
            <DaySchedule
              key={weekday}
              qaId={`${day}Schedule`}
              dayAbbreviation={capitalise(day.substring(0, 3))}
              shiftPatternDay={shiftPattern[weekday as Weekdays]}
              className={
                Object.keys(weekdays).length < 6
                  ? classes.dayScheduleMaxWidth
                  : undefined
              }
            />
          );
        })}
    </Grid>
  );
}

export default Timetable;
