import React from 'react';

import { TextField, InputAdornment, makeStyles } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import SearchIcon from '@material-ui/icons/Search';
import { AutocompleteFilterOptions } from '../FilterSearch';

export enum AutoCompleteFieldType {
  DROPDOWN = 'DROPDOWN',
  SEARCH = 'SEARCH'
}
interface AutoCompleteFieldProps {
  type?: AutoCompleteFieldType;
  options?: AutocompleteFilterOptions[];
  onChange: (
    event: any,
    newValue: { label: string; value: string } | null
  ) => void;
  onEnterPress: (event: any) => void;
}

const useStyles = makeStyles(() => ({
  selectField: {
    '& .MuiOutlinedInput-adornedEnd': {
      paddingLeft: 14
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input': {
      paddingLeft: 0
    }
  }
}));

const AutoCompleteField = ({
  type = AutoCompleteFieldType.DROPDOWN,
  options,
  onChange,
  onEnterPress
}: AutoCompleteFieldProps) => {
  const classes = useStyles();
  return (
    <Autocomplete
      id="filter-autocomplete-input"
      className={classes.selectField}
      options={
        options?.length
          ? options
          : [{ label: 'No values to display', value: '' }]
      }
      getOptionLabel={option => option.label}
      clearOnEscape
      onChange={onChange}
      onKeyPress={onEnterPress}
      renderInput={params => (
        <TextField
          {...params}
          inputProps={{
            ...params.inputProps,
            'data-testid': 'filterValue'
          }}
          variant="outlined"
          placeholder="Search"
          // eslint-disable-next-line react/jsx-no-duplicate-props
          InputProps={{
            ...params.InputProps,
            ...(type === AutoCompleteFieldType.SEARCH
              ? {
                  endAdornment: <></>
                }
              : { endAdornment: params.InputProps.endAdornment }),
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            )
          }}
        />
      )}
    />
  );
};

export default AutoCompleteField;
