import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { EditView, renderErrorOrLoading } from 'components/Crud';
import { jobTypeDomain, skillDomain, timeBandDomain } from 'models';
import { getDomainSlice, RootState } from 'store';
import useStyles from 'components/Crud/styles';
import { DomainItem } from 'models/Domain';
import { mapResponseDataToField } from 'components/Forms/utils/dataMap';
import JobType from 'models/JobTypes';
import { getJobTypeInputFieldsProps, validationSchema } from './FormConfig';
import { processFormFields } from './utils';

const processJobTypeData = (item: DomainItem) => {
  return mapResponseDataToField(
    {
      requiredSkills: (jobType: JobType) => jobType.requiredSkills,
      timeBand: (jobType: JobType) => jobType.timeBand.id
    },
    item as JobType
  );
};

const EditJobTypeView = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { selector, actions } = getDomainSlice(skillDomain);
  const { items: skillsData, isLoading, error } = useSelector<
    RootState,
    ReturnType<typeof selector>
  >(selector);
  const {
    selector: timeBandSelector,
    actions: timeBandActions
  } = getDomainSlice(timeBandDomain);
  const {
    items: timeBandData,
    isLoading: timeBandDataIsLoading,
    error: timeBandDataError
  } = useSelector<RootState, ReturnType<typeof timeBandSelector>>(
    timeBandSelector
  );

  // TODO - temporary high page size values until FFT-612 is completed
  useEffect(() => {
    if (actions.changePagination) {
      dispatch(actions.changePagination({ pageIndex: 0, pageSize: 10000 }));
    }
    if (timeBandActions.changePagination) {
      dispatch(
        timeBandActions.changePagination({ pageIndex: 0, pageSize: 10000 })
      );
    }
  }, []);

  return (
    renderErrorOrLoading(
      isLoading || timeBandDataIsLoading,
      'editJobTypeSpinner',
      error || timeBandDataError,
      classes
    ) || (
      <EditView
        domain={jobTypeDomain}
        fields={getJobTypeInputFieldsProps('edit', skillsData, timeBandData)}
        validationSchema={validationSchema}
        title="Edit job type"
        presubmitCallback={processFormFields}
        processDomainData={processJobTypeData}
      />
    )
  );
};

export default EditJobTypeView;
