import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { TableCell, makeStyles } from '@material-ui/core';
import { DateTime } from 'luxon';

import { getDomainSlice } from 'store';
import { QueryParams } from 'store/utils';
import ListView from 'components/Crud/views/List';
import {
  useTableStyles,
  NewListTable
} from 'components/Crud/ListTable/ListTable';
import {
  geographicAreaDomain,
  capacityUtilisationDomain,
  patchDomain,
  regionsDomain
} from 'models';
import {
  useCapacityUtilisationState,
  useRegionsState,
  useGeographicAreaState,
  usePatchesState
} from 'store/selectors';
import ListTableRow from 'components/Crud/ListTable/ListTableRow';
import FilterSearch, {
  AutocompleteFilterOptions,
  FilterValue
} from 'components/FilterSearch/FilterSearch';
import { GeographicArea } from 'models/GeographicArea';
import Patch from 'models/Patches';
import { getFilterQueryParams, getNumberSuffix } from 'utils';
import Tabs, { TabsProvider } from 'components/Tabs/Tabs';
import Typography from 'components/Typography';
import useFilterSearchChips from 'utils/custom-hooks/useFilterSearchChips';
import { PaginationComponent } from 'components/Pagination';
import Region from 'models/Region';
import { capacityFilters } from './listCapacityConfig';
import { filterParentAreaQueryParams } from '../../store/slices/geographicAreas';

const columnsWidth = [175, 200, 200, 149, 160, 160];
const headers = [
  'Region',
  'Area',
  'Patch',
  'Total Capacity',
  'Booked Slots',
  'Utilisation'
];

const mapAutoCompleteValues = (
  values: IdAndValue[]
): AutocompleteFilterOptions[] =>
  values.map(({ id, value }) => ({ label: value, value: id }));

const extractIdAndValue = ({ id, name }: Region | GeographicArea | Patch) => ({
  id,
  value: name
});

enum ListCapacityUtilisationFilters {
  REGION = 'region',
  AREA = 'area',
  PATCH = 'patch'
}

function getTabStartAndEndDates(
  dates: { label: string; start: DateTime; end: DateTime }[]
) {
  return dates.map(({ label, start, end }) => ({
    label,
    start: start.toISODate(),
    end: end.toISODate()
  }));
}

function getTabDateLabels() {
  const weekInMs = 1000 * 60 * 60 * 24 * 7;
  const timePeriodLabel = 'week';

  const pastTabDates = Array.from({ length: 2 }, (_, i) => {
    const date = DateTime.now().minus((i + 1) * weekInMs);

    return {
      start: date.startOf(timePeriodLabel),
      end: date.endOf(timePeriodLabel)
    };
  }).sort((a, b) => a.start.toMillis() - b.start.toMillis());

  const currentAndFutureTabDates = Array.from({ length: 7 }, (_, i) => {
    const date = DateTime.now().plus(i * weekInMs);

    return {
      start: date.startOf(timePeriodLabel),
      end: date.endOf(timePeriodLabel)
    };
  });

  const tabDates = pastTabDates
    .concat(currentAndFutureTabDates)
    .map(({ start, end }) => {
      const ordinalSuffix = getNumberSuffix(start.day);
      const currentDateDiff = start.diff(
        DateTime.now().startOf(timePeriodLabel),
        ['weeks']
      );
      let label;

      if (currentDateDiff.weeks === 0) {
        label = `This ${timePeriodLabel}`;
      } else if (currentDateDiff.weeks === 1) {
        label = `Next ${timePeriodLabel}`;
      } else if (currentDateDiff.weeks === -1) {
        label = `Last ${timePeriodLabel}`;
      } else {
        label = `${start.toFormat('MMM d')}${ordinalSuffix}`;
      }

      return {
        label,
        start,
        end
      };
    });

  return getTabStartAndEndDates(tabDates);
}

interface IdAndValue {
  id: string;
  value: string;
}

function getAutocompleteValues(
  selectedFilter: string,
  regions: IdAndValue[],
  areas: IdAndValue[],
  patches: IdAndValue[]
) {
  if (selectedFilter === ListCapacityUtilisationFilters.REGION) {
    return mapAutoCompleteValues(regions);
  }
  if (selectedFilter === ListCapacityUtilisationFilters.AREA) {
    return mapAutoCompleteValues(areas);
  }

  return mapAutoCompleteValues(patches);
}

const useStyles = makeStyles(() => ({
  tabs: {
    '& .MuiTab-root': {
      width: 122
    }
  }
}));

const CapacityView = () => {
  const classes = useStyles();
  const tableClasses = useTableStyles();
  const dispatch = useDispatch();

  const { items: capacityTableData } = useCapacityUtilisationState();

  const {
    items: regionsData,
    isLoading: areRegionsLoading
  } = useRegionsState();

  const {
    items: areasData,
    isLoading: areAreasLoading
  } = useGeographicAreaState();

  const {
    items: patchesData,
    isLoading: arePatchesLoading
  } = usePatchesState();

  const regions = regionsData.map(extractIdAndValue);
  const areas = areasData.map(extractIdAndValue);
  const patches = patchesData.map(extractIdAndValue);

  const { actions: capacityActions } = getDomainSlice(
    capacityUtilisationDomain
  );
  const { actions: regionsActions } = getDomainSlice(regionsDomain);
  const { actions: geographicAreasActions } = getDomainSlice(
    geographicAreaDomain
  );
  const { actions: patchActions } = getDomainSlice(patchDomain);

  const [selectedFilter, setSelectedFilter] = useState<string>(
    ListCapacityUtilisationFilters.REGION
  );
  const [selectedTabIndex, setSelectedTabIndex] = useState(2);

  const [{ startDate, endDate }, setCapacityDates] = useState({
    startDate: DateTime.now()
      .startOf('week')
      .toISODate(),
    endDate: DateTime.now()
      .endOf('week')
      .toISODate()
  });

  useEffect(() => {
    type SliceActions = ReturnType<typeof getDomainSlice>['actions'];

    const autocompleteDataDependencies: SliceActions[] = [
      geographicAreasActions,
      patchActions,
      regionsActions
    ];

    autocompleteDataDependencies.forEach(({ changePagination }, index) => {
      if (changePagination) {
        dispatch(
          changePagination({
            pageIndex: 0,
            pageSize: 10000,
            filter: index == 0 ? filterParentAreaQueryParams : {}
          })
        );
      }
    });
  }, []);

  const tabDateLabels = getTabDateLabels();

  const onSearch = (params: QueryParams) =>
    dispatch(
      capacityActions.get.list(params, undefined, undefined, undefined, {
        startDate,
        endDate
      })
    );

  const onLoad = (
    filterValues: FilterValue[],
    searchWithFilters: () => void
  ) => {
    useEffect(() => {
      if (capacityActions.changePagination) {
        dispatch(
          capacityActions.changePagination({
            pageIndex: 0,
            pageSize: 25,
            refetch: false
          })
        );
      }
      searchWithFilters();
    }, [filterValues, startDate, endDate]);
  };

  const {
    appliedFilters,
    onFilterRemove,
    onFilterValueInput
  } = useFilterSearchChips(capacityFilters, onSearch, onLoad);

  function getTabsConfig(
    tabDates: { label: string; start: string; end: string }[]
  ) {
    return tabDates.map(({ label, start, end }, i) => ({
      label,
      onClick: () => {
        setCapacityDates({ startDate: start, endDate: end });
        setSelectedTabIndex(i);
      }
    }));
  }

  return (
    <ListView
      domain={capacityUtilisationDomain}
      showPageHeader={false}
      autoLoad={false}
      loadingDependency={[
        areRegionsLoading,
        areAreasLoading,
        arePatchesLoading
      ].some(Boolean)}
    >
      {() => (
        <TabsProvider
          tabs={getTabsConfig(tabDateLabels)}
          preSelectedTabIndex={selectedTabIndex}
        >
          <section className={tableClasses.tableContainer}>
            <div className={tableClasses.tableHeader}>
              <Typography variant="h1" data-testid="readDomainPageTitle">
                Capacity
              </Typography>
            </div>
            <FilterSearch
              filters={capacityFilters}
              filterValues={appliedFilters}
              onFilterRemove={onFilterRemove}
              onFilterValueInput={onFilterValueInput}
              onFilterChange={setSelectedFilter}
              options={getAutocompleteValues(
                selectedFilter,
                regions,
                areas,
                patches
              )}
            />
            <Tabs className={classes.tabs} />
            <NewListTable
              columnsWidth={columnsWidth}
              domainItems={capacityTableData}
              headers={headers}
              label={`A table view of ${capacityUtilisationDomain.label.toLocaleLowerCase()} within Zeno Aegis`}
              actionsColumn={false}
            >
              {capacityTableData.map(
                ({
                  region,
                  area,
                  patch,
                  totalCapacity,
                  bookedSlots,
                  utilisation
                }) => {
                  const key = `${region}-${area}-${patch.name}`;

                  return (
                    <ListTableRow key={key} data-testid={`tableRow-${key}`}>
                      <TableCell
                        className={tableClasses.tableCell}
                        data-testid={`tableCell-${key}-name`}
                      >
                        {region}
                      </TableCell>
                      <TableCell
                        className={tableClasses.tableCell}
                        data-testid={`tableCell-${key}-name`}
                      >
                        {area}
                      </TableCell>
                      <TableCell
                        className={tableClasses.tableCell}
                        data-testid={`tableCell-${key}-name`}
                      >
                        {patch.name}
                      </TableCell>
                      <TableCell
                        className={tableClasses.tableCell}
                        data-testid={`tableCell-${key}-name`}
                      >
                        {`${totalCapacity}`}
                      </TableCell>
                      <TableCell
                        className={tableClasses.tableCell}
                        data-testid={`tableCell-${key}-name`}
                      >
                        {`${bookedSlots}`}
                      </TableCell>
                      <TableCell
                        className={tableClasses.tableCell}
                        data-testid={`tableCell-${key}-name`}
                      >
                        {`${utilisation}%`}
                      </TableCell>
                    </ListTableRow>
                  );
                }
              )}
            </NewListTable>

            {Boolean(capacityTableData.length) && (
              <PaginationComponent
                domain={capacityUtilisationDomain}
                filter={getFilterQueryParams(appliedFilters)}
                queryParams={{ startDate, endDate }}
              />
            )}
          </section>
        </TabsProvider>
      )}
    </ListView>
  );
};

export default CapacityView;
